import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../components/_redux/notarization_redux/notarizationActions";
import { Form , Card, Button, Row, Col } from "react-bootstrap";
import ShowNotification from '../components/new_notarization/ShowNotification'

export function IdologySettings() {
  const dispatch = useDispatch();
  const { auth } = useSelector(state => state);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [notification, setNotification] = useState({
    show: false,
    msg: '',
    variant: '',
  })

  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };
  
  const handleCloseNotification = (event, reason) => {
    if (reason === 'clickaway') return
    setNotification({ show: false, msg: '', variant: '' })
  }

  const submitCredentials = ( type ) => {

    let body = {
      "userName": username,
      "newPassword": password,
      "validationType": type,
    }

    dispatch(actions.saveIdologySettings(body,headers))
    .then((response)=>{
      setNotification({
        show: true,
        msg: "Your Idology Credentials Are Updated",
        variant: "success"
      });
    })
    .catch(()=>{
      setNotification({
        show: true,
        msg: "Something Went Wrong",
        variant: "error"
      });
    })

  }

  return (
    <div className="container">
      {notification.show && (
        <ShowNotification
          show={notification.show}
          msg={notification.msg}
          variant={notification.variant}
          close={handleCloseNotification}
        />
      )}
      <Row className="justify-content-center mb-10">
        <Col xs={12} md={10}>
          <Card>
            <Card.Header as="h5">KBA Credentials</Card.Header>
            <Card.Body>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" autoComplete="off" placeholder="Enter email" onChange={(e)=>setUsername(e.target.value)}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" autoComplete="off" placeholder="Password" onChange={(e)=>setPassword(e.target.value)}/>
                </Form.Group>
                <Button 
                  variant="primary"
                  onClick={()=> {submitCredentials("kba")}}
                >
                  Submit
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="justify-content-center mb-10">
        <Col xs={12} md={10}>
          <Card>
            <Card.Header as="h5">ID Scan Credentials</Card.Header>
            <Card.Body>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" autoComplete="off" placeholder="Enter email" onChange={(e)=>setUsername(e.target.value)}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" autoComplete="off" placeholder="Password" onChange={(e)=>setPassword(e.target.value)}/>
                </Form.Group>
                <Button 
                  variant="primary"
                  onClick={()=> {submitCredentials("idScan")}}
                >
                  Submit
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="justify-content-center mb-10">
        <Col xs={12} md={10}>
          <Card>
            <Card.Header as="h5">Biometric Credentials</Card.Header>
            <Card.Body>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" autoComplete="off" placeholder="Enter email" onChange={(e)=>setUsername(e.target.value)}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" autoComplete="off" placeholder="Password" onChange={(e)=>setPassword(e.target.value)}/>
                </Form.Group>
                <Button 
                  variant="primary"
                  onClick={()=> {submitCredentials("biometric")}}
                >
                  Submit
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
