import React, { useEffect } from "react";
import { TableUIProvider } from "../components/witnesses_listing/TableUIContext";
import { TableCard } from "../components/witnesses_listing/TableCard";
import { GenericZeroState } from "../components/zero_state_pages/GenericZeroState";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../components/_redux/notarization_redux/notarizationActions";

export function WitnessingPage() {
  const { auth, notarization } = useSelector(state => state);
  const { allWinessjournals } = notarization;
  const customersUIEvents = {
    statusswitcher: async (id, status) => {
      const data = {
        accept: status == "accept" ? true : false,
        journalId: id
      };

      let witnessresponse = await dispatch(
        actions.acceptWitnessing(headers, data)
      );

      if (witnessresponse) {
        if (witnessresponse.data) {
          window.location.href = "#/witnessing";
        }
      }
    }
  };

  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      actions.witnessGetJournals(headers, {
        sortOrder: "asc",
        sortField: "id",
        pageNumber: 1,
        pageSize: 10
      })
    );
  }, []);

  return (
    <>
      {allWinessjournals !== null && allWinessjournals.count > 0 ? (
        <TableUIProvider customersUIEvents={customersUIEvents}>
          <TableCard />
        </TableUIProvider>
      ) : (
        <GenericZeroState
          status={allWinessjournals}
          imageURL="/media/own/no-witness.png"
          heading=""
          body="Once you accept a Witnessing Opportunity, it will be listed here."
          btnTxt="false"
        />
      )}
    </>
  );
}
