import React from "react";
import { Form } from "react-bootstrap";
import { Modal, Button } from "react-bootstrap";
export function WitnessInfo({ noOfWitness, setNoOfWitness, disabled }) {
  const addNoOfWitness = e => {
    const witness = e.target.value;
    if (JSON.parse(witness) >= 0)
      setNoOfWitness({ value: JSON.parse(witness), error: false });
    else if (witness === "") setNoOfWitness({ ...noOfWitness, error: false });
  };

  const increaseWitness = () => {
    setNoOfWitness({ value: noOfWitness.value + 1, error: false });
  };

  const decreaseWitness = () => {
    if (noOfWitness.value && noOfWitness.value > 0) {
      setNoOfWitness({ value: noOfWitness.value - 1, error: false });
    }
  };

  return (
    <div className={`card card-custom`}>
      <div className="card-header border-0 pt-5">
        <h2
          className="card-title align-items-start flex-column"
          style={{ float: "left", width: "100%" }}
        >
          Need Additional Witnesses?
        </h2>
        <p style={{ color: "#ccc" }}>
          If your document requires additional impartial witnesses which you
          have not named in the signer section above, you can have Cyberize It
          supply them from our available Notaries. This area is only needed if
          your document requires this. If you are unsure please reach out to
          your Primary Notary or contact us for assistance.{" "}
        </p>
      </div>

      <div className="card-header border-0 py-5 pt-0">
        <div className="align-items-start flex-column">
          <p style={{ float: "left", paddingTop: "7px", paddingRight: "20px" }}>
            Please enter the number of witnesses you require if none enter 0.
          </p>
          <Form.Control
            style={{ width: "13%" }}
            type="number"
            placeholder="e.g. 3"
            value={noOfWitness.value}
            min="0"
            onChange={addNoOfWitness}
            disabled={disabled}
          />
          <br />
          <Button
            variant="secondary"
            className={"mobilearrow"}
            onClick={increaseWitness}
          >
            +
          </Button>{" "}
          &nbsp;
          <Button
            variant="secondary"
            className={"mobilearrow"}
            onClick={decreaseWitness}
          >
            -
          </Button>
        </div>
      </div>
      {/* { noOfSigner.error && <p style={{ padding: '0 2.25rem', color: 'red' }}>You can add minimun 1 & maximum 4 signers. Please enter valid value!</p>} */}
    </div>
  );
}
