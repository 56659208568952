import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/notarization_redux/notarizationActions";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses
} from "../../../../_metronic/_helpers";
import * as uiHelpers from "../TableUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../_metronic/_partials/controls";
import { useTableUIContext } from "../TableUIContext";

export function CustomTable() {
  // Customers UI Context
  const customersUIContext = useTableUIContext();

  const dispatch = useDispatch();
  const { auth, notarization } = useSelector(state => state);
  const [isfetchjournals, setisfetchjournals] = useState(0);
  const [isfetchjournalssize, setisfetchjournalssize] = useState(0);
  const { allJobs, customizeJobs, listLoading, claimJob } = notarization;
  const [checkKBA, setcheckKBA] = useState(0);
  const [listKBA, setlistKBA] = useState("");
  const [reason, setReason] = useState("");

  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      setIds: customersUIContext.setIds,
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
      openEditCustomerDialog: customersUIContext.openEditCustomerDialog,
      openClaimPopup: customersUIContext.openClaimPopup
    };
  }, [customersUIContext]);

  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };
  let columns = [];

  if (auth.user.Role.code !== "notary" || (auth.user.Role.code === "notary" && !auth.user.notaryProfile.isCancelled)) {
    columns = [
      {
        dataField: "sno",
        text: "Action",
        formatter: columnFormatters.ClaimColumnFormatter,
        formatExtraData: {
          openClaimPopup: customersUIProps.openClaimPopup,
          claimJob: allJobs.claimJob
        },
        classes: "text-right pr-0",
        headerClasses: "text-right pr-3",
        style: {
          minWidth: "100px"
        }
      }
    ]
  }

  // Table columns
  columns = [...columns, ...[
    {
      dataField: "notary",
      text: "Title",
      formatter: columnFormatters.TitleColumnFormatter
    },
    {
      dataField: "state",
      text: "State",
      formatter: columnFormatters.StateColumnFormatter
    },
    {
      dataField: "witness_required",
      text: "Witness Required",
      formatter: columnFormatters.WitnessRequiredColumnFormatter
    },
    {
      dataField: "scheduledAt",
      text: "NOTARIZATION TIME & DATE",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: columnFormatters.DateColumnFormatter
    },
    {
      dataField: "job_type",
      text: "Type",
      formatter: columnFormatters.JobTypeColumnFormatter
    },
    {
      dataField: "status",
      text: "Status",
      formatter: columnFormatters.StatusColumnFormatter
    },
    {
      dataField: "companyName",
      text: "Company Name",
      formatter: columnFormatters.CompanyNameColumnFormatter
    },
    {
      dataField: "action",
      text: "Action",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditCustomerDialog: customersUIProps.openEditCustomerDialog,
        openClaimPopup: customersUIProps.openClaimPopup
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px"
      }
    }
  ]];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: allJobs === null ? 0 : allJobs.count,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: customersUIProps.queryParams.pageSize,
    page: customersUIProps.queryParams.pageNumber
  };

  useEffect(() => {
    if (isfetchjournals != customersUIProps.queryParams.pageNumber) {
      dispatch(
        actions.fetchJobPosts(
          customersUIProps.queryParams,
          headers,
          auth.user.Role.code
        )
      );
      setisfetchjournals(customersUIProps.queryParams.pageNumber);
    }

    if (isfetchjournalssize != customersUIProps.queryParams.pageSize) {
      dispatch(
        actions.fetchJobPosts(
          customersUIProps.queryParams,
          headers,
          auth.user.Role.code
        )
      );
      setisfetchjournalssize(customersUIProps.queryParams.pageSize);
    }
  }, [customersUIProps.queryParams]);

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="id"
                data={allJobs === null ? [] : allJobs.rows}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  customersUIProps.setQueryParams
                )}
                {...paginationTableProps}
              >
                <PleaseWaitMessage
                  entities={allJobs === null ? [] : allJobs.rows}
                />
                <NoRecordsFoundMessage
                  entities={allJobs === null ? [] : allJobs.rows}
                />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
