import React from "react";
import { GetQuestions } from "../kba/GetQuestions";

export function TableGetQuestionsCard() {
  return (
    <>
      <GetQuestions />
    </>
  );
}
