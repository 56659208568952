import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

export function Choices(props) {
  const submitAnswer = option => {
    const questionAnswer = props.Submission;

    questionAnswer["question" + props.number + "Answer"] = option;
    props.setSubmission(questionAnswer);
  };
  return (
    <FormControl component="fieldset">
      <RadioGroup row aria-label="position" name="position" defaultValue="top">
        {props.choices.length > 0
          ? props.choices.map((answer, i) => (
              <span>
                {" "}
                <FormControlLabel
                  value={answer["_text"]}
                  control={
                    <Radio
                      color="primary"
                      onClick={() => submitAnswer(answer["_text"])}
                    />
                  }
                  label={answer["_text"]}
                />
              </span>
            ))
          : ""}
      </RadioGroup>
    </FormControl>
  );
}
