import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TableUIProvider } from "../../components/banklistingtable/TableUIContext";
import { TableCard } from "../../components/banklistingtable/TableCard";
import * as actions from "../../components/_redux/notarization_redux/notarizationActions";

export function BankListing(props) {
  const { auth } = useSelector(state => state);
  const zerobankDetails = props.zerobankDetails;
  const dispatch = useDispatch();
  const customersUIEvents = {
    makePrimary: id => {
      const headers = {
        Authorization: `Bearer ${auth.authToken}`,
        "Content-Type": "application/json",
        Accept: "application/json"
      };

      dispatch(actions.makePrimary({ bankId: id }, headers));
    }
  };

  return (
    <TableUIProvider customersUIEvents={customersUIEvents}>
      <TableCard zerobankDetails={zerobankDetails} />
    </TableUIProvider>
  );
}
