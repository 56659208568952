import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Modal } from 'react-bootstrap'
import Badge from '@material-ui/core/Badge'
import { useSelector } from 'react-redux'
import { toAbsoluteUrl } from '../../../../_metronic/_helpers'

const useStyles = makeStyles((theme) => ({
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}))

const StyledBadge = withStyles((theme) => ({
    badge: {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: '$ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}))(Badge)

export default function PrimaryPSNotaryCard({
    notary,
    setpsSelection,
    revealSelection,
    isreveal,
    hidephone,
}) {
    const classes = useStyles()
    let lang = []
    notary.Languages.map((el) => lang.push(el.name))

    let formatedDate = ''
    if (notary.createdAt !== null) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' }
        formatedDate = new Date(notary.createdAt).toLocaleDateString(
            undefined,
            options
        )
    }

    const { primaryNotary, onlineNotaries, MyClients } = useSelector(
        (state) => state.notarization
    )
    let isOnline = onlineNotaries.includes(notary.id)

    return (
        <>
            <Grid item xs={4}>
                <div
                    style={{
                        border: '1px solid #E4E6EF',
                        boxShadow: '0px 0px 7px -3px #ccc',
                        borderRadius: '10px',
                        padding: '20px',
                    }}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            {isOnline ? (
                                <StyledBadge
                                    overlap='circle'
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    variant='dot'>
                                    <Avatar
                                        className={classes.large}
                                        alt='profile pic'
                                        src={
                                            notary.profile_picture !== null
                                                ? `${notary.profile_picture.baseUrl}/${notary.profile_picture.path}`
                                                : toAbsoluteUrl(
                                                      '/media/users/blank.png'
                                                  )
                                        }>
                                        {notary.firstName
                                            .charAt(0)
                                            .toUpperCase()}
                                    </Avatar>
                                </StyledBadge>
                            ) : (
                                <Avatar
                                    className={classes.large}
                                    alt='profile pic'
                                    src={
                                        notary.profile_picture !== null
                                            ? `${notary.profile_picture.baseUrl}/${notary.profile_picture.path}`
                                            : toAbsoluteUrl(
                                                  '/media/users/blank.png'
                                              )
                                    }>
                                    {notary.firstName.charAt(0).toUpperCase()}
                                </Avatar>
                            )}
                        </Grid>
                        <Grid item xs={8}>
                            <Button
                                style={{
                                    backgroundColor: 'transparent',
                                    borderColor: '#1840d2',
                                    color: '#000',
                                    minWidth: '50%',
                                    float: 'right',
                                }}
                                id={notary.id}
                                onClick={() => revealSelection(notary.id)}>
                                Reveal
                            </Button>{' '}
                            {MyClients && MyClients.indexOf(notary.id) < 0 ? (
                                <Button
                                    style={{
                                        backgroundColor: 'transparent',
                                        borderColor: '#1840d2',
                                        color: '#000',
                                        minWidth: '50%',
                                        float: 'right',
                                    }}
                                    id={notary.id}
                                    onClick={() => setpsSelection(notary.id)}>
                                    Approve
                                </Button>
                            ) : (
                                <Button
                                    style={{
                                        backgroundColor: 'blue',
                                        borderColor: '#fff',
                                        color: '#fff',
                                        minWidth: '50%',
                                        float: 'right',
                                    }}>
                                    Approved
                                </Button>
                            )}
                            {isreveal == notary.id && (
                                <Modal
                                    show={isreveal == notary.id}
                                    onHide={hidephone}
                                    aria-labelledby='contained-modal-title-vcenter'
                                    centered>
                                    <Modal.Header
                                        className='justify-content-center pb-2'
                                        closeButton>
                                        <Modal.Title id='contained-modal-title-vcenter'>
                                            Contact info
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body
                                        style={{ textAlign: 'center' }}
                                        className='pl-2 pr-2 pt-3 pb3'>
                                        <p>
                                            <strong>Phone Number</strong>
                                        </p>
                                        <p>{notary.phone}</p>
                                        <p>
                                            <strong>Email</strong>
                                        </p>
                                        <p>{notary.email}</p>
                                    </Modal.Body>
                                </Modal>
                            )}
                        </Grid>
                    </Grid>
                    <p className='pt-7'>
                        Name:{' '}
                        <span style={{ fontWeight: '600' }}>
                            {notary.firstName} {notary.lastName}
                        </span>
                    </p>
                    {/* <p>Member Since: <span style={{ fontWeight: '600' }}>{formatedDate}</span></p> */}
                    <p>
                        Type:{' '}
                        <span style={{ fontWeight: '600' }}>
                            {notary.notaryProfile.notaryTypes.map((el, i) => (
                                <span>
                                    {el.type}
                                    {i <
                                    notary.notaryProfile.notaryTypes.length - 1
                                        ? ', '
                                        : ''}{' '}
                                </span>
                            ))}
                        </span>
                    </p>
                    <p>
                        State:{' '}
                        <span style={{ fontWeight: '600' }}>
                            {notary.state}
                        </span>
                    </p>
                    <p>
                        Languages:{' '}
                        <span style={{ fontWeight: '600' }}>
                            {lang.length > 0 ? lang.join(', ') : 'N/A'}
                        </span>
                    </p>
                </div>
            </Grid>
        </>
    )
}
