import React, { useState, useEffect } from 'react'
import NewNotarizationRoot from '../components/new_notarization/NewNotarizationRoot'
import ShowNotification from '../components/new_notarization/ShowNotification'
import PaymentCardListing from '../components/new_notarization/PaymentCardListing'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../components/_redux/notarization_redux/notarizationActions'
import * as socketactions from '../socket/_redux/socket_redux/socketActions'
import { OnlineSearchNotaries } from './OnlineSearchNotaries'
import validator from 'validator'
import { useHistory, useLocation } from 'react-router-dom'
import LoadingOverlay from 'react-loading-overlay'

export function NewNotarization({
    newNot = false,
    id = null,
    from = null,
    alreadyPrimaryNotary = 0,
}) {
    const history = useHistory()
    const location = useLocation()
    const [PrimaryNotarySelected, setPrimaryNotarySelected] = useState(false)
    const [changeMethod, setChangeMethod] = useState(false)
    const [saveLaterSuccess, setSaveLaterSuccess] = useState(false)
    const [scheduleLaterSuccess, setScheduleLaterSuccess] = useState(false)
    const [scheduleLaterDateTime, setScheduleLaterDateTime] = useState('')
    const [notarizeNowSuccess, setNotarizeNowSuccess] = useState(false)
    const [schedulerModal, setSchedulerModal] = useState(false)
    const [cpostModal, setcpostModal] = useState(false)
    const [payNowModal, setPayNowModal] = useState(false)
    const [journalID, setjournalID] = useState(id === null ? 0 : id)
    const [addedSigners, setAddedSigners] = useState(null)
    const [chooseNotary, setChooseNotary] = useState(chooseNotaryProp)
    const [platformFee, setPlatformFee] = useState({ fee: 0, docs: 0 })
    const [isButtonClicked, setisButtonClicked] = useState(false)
    const [notification, setNotification] = useState({
        show: false,
        msg: '',
        variant: '',
    })
    const [isActive, setisActive] = useState(false)
    const [showKBAVerificationModal, setShowKBAVerificationModal] = useState(
        false
    )
    const [showKBASignersStatus, setshowKBASignersStatus] = useState(false)
    const [showVerificationDoneModal, setShowVerificationDoneModal] = useState(
        false
    )
    const [showNotaryWaiting, setshowNotaryWaiting] = useState(false)
    const [showNoNotary, setshowNoNotary] = useState(false)
    const [showReNotary, setshowReNotary] = useState(false)
    const [issingleKBA, setissingleKBA] = useState(false)
    const [kbatimeout, setkbatimeout] = useState(false)
    const [KBASettingscheck, setKBASettingscheck] = useState(false)
    const [KBASettingscheckdispatch, setKBASettingscheckdispatch] = useState(
        false
    )

    const [intialprimaryNotary, setintialprimaryNotary] = useState(false)
    const [kbaVerificationDone, setkbaVerificationDone] = useState(false)
    const [kbaVerificationSingle, setkbaVerificationSingle] = useState(0)

    let noticounter = 0

    // Validation State
    const dispatch = useDispatch()
    const { socket, auth, notarization } = useSelector((state) => state)
    const {
        defaultPaymentCard,
        payments,
        documents,
        primaryNotary,
        paymentDetails,
        allAdminStates,
        allJournals,
        top5Journals,
        allNotaries,
        currentkbasigners,
        currentjournalstatus,
        getAllJournal,
        KBASettings,
        notaryTypes,
    } = notarization
    const [currentkbasignersObj, setcurrentkbasignersObj] = useState(
        currentkbasigners
    )
    const [kbaflowcounter, setkbaflowcounter] = useState(0)
    const [chooseNotaryProp, setchooseNotaryProp] = useState(false)
    const [notarynotificationcounter, setnotarynotificationcounter] = useState(
        0
    )
    const [
        initnotarynotificationcounter,
        setinitnotarynotificationcounter,
    ] = useState(false)

    const [currentSubstatus, setcurrentSubstatus] = useState('')
    const chooseNotaryPropData =
        chooseNotaryProp && getAllJournal.journal
            ? [getAllJournal.journal]
            : null
    const resumeData =
        chooseNotaryPropData === null
            ? newNot
                ? (from === 'journal' ? allJournals : top5Journals).rows.filter(
                      (data) => data.id === id
                  )
                : null
            : chooseNotaryPropData
    const [cstmDisable, setCstmDisable] = useState(
        resumeData !== null && resumeData[0].Payments.length > 0
            ? 'disabled'
            : ''
    )
    const [paid, setPaid] = useState(
        resumeData !== null && resumeData[0].Payments.length > 0 ? true : false
    )
    const [willDoc, setWillDoc] = useState(
        resumeData !== null && resumeData[0].willDoc ? 'yes' : 'no'
    )
    const [electionDoc, setElectionDoc] = useState(
        resumeData !== null && resumeData[0].electionDoc ? 'yes' : 'no'
    )

    const [comments, setComments] = useState(resumeData !== null ? resumeData[0].comments : "");
    const [noOfSigner, setNoOfSigner] = useState({
        value: 1,
        added:
            resumeData !== null && resumeData[0].Signers.length > 0
                ? resumeData[0].Signers.length
                : 1,
        error: false,
    })
    const [noOfWitness, setNoOfWitness] = useState({
        value: resumeData !== null ? resumeData[0].witnessCount : 0,
        error: false,
    })
    const [showAttentionMessage, setShowAttentionMessage] = useState(false)

    let singers = [
        {
            fname: auth.user.firstName,
            middleName: auth.user.middleName,
            lname: auth.user.lastName,
            suffix: auth.user.suffix,
            email: auth.user.email,
            phone: auth.user.phone && auth.user.phone != null ? auth.user.phone : '',
            checklist: [],
            notGovtID: false,
            biometric:
                'undefined' == auth.user.biometric
                    ? false
                    : auth.user.biometric,
            nonUS: auth.user.nonUS,
            isEmailValid: validator.isEmail(auth.user.email),
            isError: false,
            address: auth.user.address,
            zip: auth.user.zip,
            city: auth.user.city,
            state: auth.user.state,
            ssn: '',
            dob: '',
            temporaryLink: '',
            byPassKBA:
                KBASettings &&
                KBASettings?.data &&
                KBASettings?.data &&
                KBASettings?.data?.settings?.bypassAllowed
                    ? KBASettings?.data?.settings?.bypassAllowed
                    : false,
            reason:
                KBASettings &&
                KBASettings?.data &&
                KBASettings.data?.settings?.bypassAllowed
                    ? KBASettings.data?.settings?.reason
                    : '',
        },
    ]

    for (let i = 1; i <= 9999; i++) {
        singers.push({
            fname: '',
            lname: '',
            email: '',
            phone: '',
            checklist: [],
            reason: '',
            notGovtID: false,
            biometric: false,
            nonUS: false,
            byPassKBA: false,
            isEmailValid: false,
            isError: false,
            address: '',
            zip: '',
            city: '',
            state: '',
            ssn: '',
            dob: '',
            temporaryLink: '',
        })
    }

    const [signerInterval, setsignerInterval] = useState([])
    const [journalInterval, setjournalInterval] = useState([])

    const [prefstate, setprefstate] = useState('')
    const [typeNotary, setTypeNotary] = useState('')

    const [dateTime, setDateTime] = React.useState(
        new Date(new Date().getTime() + 30 * 60000).toISOString()
    )
    const [lang, setLang] = useState([])
    const [typeFile, settypeFile] = useState({})

    let isPrimaryNotary = primaryNotary !== null
    let primaryNotaryObj = primaryNotary !== null ? primaryNotary : ''
    let notaryID = ''
    if (resumeData !== null) {
        if (
            isPrimaryNotary &&
            resumeData[0].notaryId === primaryNotary.notaryId
        ) {
            isPrimaryNotary = true
            notaryID = resumeData[0].notaryId
        } else {
            isPrimaryNotary = false
            primaryNotaryObj = allNotaries.filter(
                (el) => el.id === resumeData[0].notaryId
            )
            primaryNotaryObj =
                primaryNotaryObj.length > 0 ? primaryNotaryObj[0] : ''
        }

        resumeData[0].Signers.map((data, index) => {
            singers[index].fname = data.firstName
            singers[index].lname = data.lastName
            singers[index].middleName = data.middleName
            singers[index].suffix = data.suffix
            singers[index].biometric =
                'undefined' == data.biometric ? false : data.biometric
            singers[index].nonUS = data.nonUS
            singers[index].email = data.email
            singers[index].phone = data.phone && data.phone != null ? data.phone : '';
            singers[index].address = data.address
            singers[index].zip = data.zip
            singers[index].city = data.city
            singers[index].state = data.state
            singers[index].ssn = data.ssnLast4
            singers[index].dob = data.dob

            singers[index].byPassKBA =
                data.bypassKBA === null ? false : data.bypassKBA
            singers[index].reason =
                data.bypassReason === null ? '' : data.bypassReason
            singers[index].isEmailValid = validator.isEmail(data.email)
            singers[index].temporaryLink = data.temporaryLink
                ? data.temporaryLink
                : ''
        })
    }

    const [selectNotaryID, setselectNotaryID] = useState(notaryID)
    const [selectedNotary, setSelectedNotary] = useState({
        primary: isPrimaryNotary,
        primaryNotary: primaryNotaryObj,
    })
    const [singerInfo, setSingerInfo] = useState(singers)
    const [filteredSingers, setFilteredSingers] = useState(singers)
    const [JobPostDoneModal, setJobPostDoneModal] = useState(false)
    const headers = {
        Authorization: `Bearer ${auth.authToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
    }
    const handleChangeFileType = (event) => {
        let ttfile = typeFile

        if (!ttfile[event.target.name]) ttfile[event.target.name] = ''

        ttfile[event.target.name] = event.target.value

        settypeFile(ttfile)
    }

    const fetchEnabledStates = (isEnabled = '') => {
        dispatch(
            actions.fetchNotaryStates(
                headers,
                [`biometricEnabled=${isEnabled}`],
                {
                    pageNumber: 1,
                    pageSize: 52,
                    sortBy: "asc",
                    sortField: "state",
                }
            )
        )
    }

    const payNowHandler = () => {
        let error = false
        let errorMessage = ''
        let copiedArr = [...singerInfo]
        for (let i = 0; i < noOfSigner.added; i++) {
            copiedArr[i]['isError'] = false
            let checklist = []
            let email = true,
                phone = true,
                personalDocs = true

            if (!/^[a-zA-Z0-9]*$/g.test(singerInfo[i].fname)) {
                error = true
                errorMessage =
                    'Please Provide Correct Signer ' + (i + 1) + ' First Name'
                copiedArr[i]['isError'] = true
            }

            if (!/^[a-zA-Z0-9]*$/g.test(singerInfo[i].middleName)) {
                error = true
                errorMessage =
                    'Please Provide Correct Signer ' + (i + 1) + ' Middle Name'
                copiedArr[i]['isError'] = true
            }

            if (!/^[a-zA-Z0-9]*$/g.test(singerInfo[i].lname)) {
                error = true
                errorMessage =
                    'Please Provide Correct  Signer ' + (i + 1) + ' Last Name'
                copiedArr[i]['isError'] = true
            }

            if (singerInfo[i].byPassKBA && singerInfo[i].reason === '') {
                error = true
                errorMessage = 'Please fill out the mandatory fields!'
                copiedArr[i]['isError'] = true
            }

            if (
                !singerInfo[i].byPassKBA &&
                (!email || !phone || !personalDocs)
            ) {
                error = true
                errorMessage = 'Please fill out the mandatory fields!'
                copiedArr[i]['isError'] = true
            }

            if (!singerInfo[i].isEmailValid) {
                error = true
                errorMessage = 'Please enter valid email!'
                copiedArr[i]['isError'] = true
            }
            if (singerInfo[i].suffix && 5 < singerInfo[i].suffix.length) {
                error = true
                errorMessage = 'Suffix must not exceed 5 letters'
                copiedArr[i]['isError'] = true
            } else if (
                singerInfo[i].suffix &&
                !/^[A-Za-z.]+$/gi.test(singerInfo[i].suffix)
            ) {
                error = true
                errorMessage =
                    'Please Provide Correct Signer ' + (i + 1) + ' Suffix'
                copiedArr[i]['isError'] = true
            }

            if (
                singerInfo[i].fname === '' ||
                singerInfo[i].lname === '' ||
                singerInfo[i].email === '' ||                
                (singerInfo[i].phone === '' && auth.user.Role.code != "notary") ||
                singerInfo[i].address === '' ||
                singerInfo[i].zip === '' ||
                singerInfo[i].city === '' ||
                singerInfo[i].state === '' ||
                singerInfo[i].fname === null ||
                singerInfo[i].lname === null ||
                singerInfo[i].email === null ||
                singerInfo[i].address === null ||
                singerInfo[i].zip === null ||
                singerInfo[i].city === null ||
                singerInfo[i].state === null ||
                (!singerInfo[i].biometric &&
                    (singerInfo[i].dob === null || singerInfo[i].dob === ''))
            ) {
                error = true
                errorMessage = 'Please fill out the mandatory fields!'
                copiedArr[i]['isError'] = true
            }
        }
        setSingerInfo(copiedArr)

        if (documents.length < 1) {
            error = true
            errorMessage = 'Please upload minimum 1 document!'
        }

        if (defaultPaymentCard === null) {
            error = true
            errorMessage = 'Please add payment card!'
        }

        if (!error) {
            let showattention = false
            if (
                selectedNotary?.primary &&
                !selectedNotary?.primaryNotary?.biometricSettings?.biometricEnabled
            ) {
                let copiedArr = [...singerInfo]
                for (let i = 0; i < noOfSigner.added; i++) {
                    if (!i) {
                        if (auth?.user && auth?.user?.kbaSettings && auth?.user?.kbaSettings.length && !auth.user.kbaSettings[0]?.bypassAllowed) {
                            if (copiedArr[i]?.biometric) {
                                showattention = true
                                break
                            }
                        }
                    } else if (copiedArr[i]?.biometric) {
                        showattention = true
                        break
                    }
                }
            }

            if (showattention) {
                setShowAttentionMessage(true)
                return
            }

            showPaymentModal()
        } else {
            setNotification({ show: true, msg: errorMessage, variant: 'error' })
        }
    }

    const showPaymentModal = () => {
        let fee = 3;
        setPlatformFee({ fee: fee, docs: documents.length })
        setPayNowModal(true)
        setShowAttentionMessage(false)
    }

    const payPlatformFeeHandler = async (fee) => {
        let body = {
            userId: auth.user.id,
            title: 'Platform fee',
            currency: 'usd',
            amount: Math.round(fee * 100),
            cardId: defaultPaymentCard.id,
        }
        dispatch(
            actions.payPlatformFee(body, headers, {
                setPaid,
                setPayNowModal,
                setCstmDisable,
                setNotification,
            })
        ).then((data) => {
            draftHandler('payment', data)
        })
    }

    const appendFileTypeinFiles = () => {
        let documentoverride = []
        documents.map((data, i) => {
            documentoverride[i] = {}
            documentoverride[i].baseUrl = documents[i].baseUrl
            documentoverride[i].createdAt = documents[i].createdAt
            documentoverride[i].filename = documents[i].filename
            documentoverride[i].id = documents[i].id
            documentoverride[i].path = documents[i].path
            documentoverride[i].type = documents[i].type
            documentoverride[i].updatedAt = documents[i].updatedAt

            if (
                resumeData &&
                resumeData[0] &&
                resumeData[0].journalDocs &&
                checkFileexist(documents[i].id)
            )
                documentoverride[i].docType = checkFileexist(documents[i].id)
            else
                documentoverride[i].docType = typeFile['file' + documents[i].id]
        })
        return documentoverride
    }
    const checkFileexist = (fileID) => {
        let fileDetail = resumeData[0].journalDocs.filter(function(fileObj) {
            return fileObj.fileId == fileID
        })
        if (!fileDetail[0]) return false
        else return fileDetail[0].docType
    }

    const prepareTime = (dt) => {
        let date = '',
            time = '',
            timezone = ''
        const options = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }
        const dateOptions = { hour: '2-digit', minute: '2-digit' }
        date = new Date(dt).toLocaleDateString(undefined, options)
        time = new Date(dt).toLocaleTimeString('en-US', dateOptions)
        timezone = seconds_with_leading_zeros(new Date(dt))
        timezone = timezone.split(' ')
        let shortTimezone = ''
        timezone.map((el) => {
            shortTimezone += el[0]
        })
        return `${date} at ${time} (${shortTimezone})`
    }

    const seconds_with_leading_zeros = (dt) => {
        return /\((.*)\)/.exec(dt.toString())[1]
    }

    const confirmjobPostHandler = () => {
        let showattention = false
        if ( selectedNotary.primaryNotary && !selectedNotary?.primaryNotary?.biometricSettings?.biometricEnabled) {
            let copiedArr = [...singerInfo]
            for (let i = 0; i < noOfSigner.added; i++) {
                if (!i) {
                    if (
                        auth.user.kbaSettings[0] &&
                        !auth.user.kbaSettings[0].bypassAllowed
                    ) {
                        if (copiedArr[i].biometric) {
                            showattention = true
                            break
                        }
                    }
                } else if (copiedArr[i].biometric) {
                    showattention = true
                    break
                }
            }
        }
        else {
            let copiedArr = [...singerInfo]
            for (let i = 0; i < noOfSigner.added; i++) {
                if (copiedArr[i].biometric) {
                    showattention = true
                    break
                }
            }
        }

        if (showattention) {
            fetchEnabledStates(true)
        }
        setcpostModal(true)
    }

    const formatNotarizationPayload = (paymentId = null, action="draft", dateTime=null) => {

        setisButtonClicked(true)
        let body = {};

        let documentoverride = []
        let checkbypasskba = 0
        
        let error = false
        let errorMessage = ''

        if (documents.length < 1) {
            error = true
            errorMessage = 'Please upload minimum 1 document!'
        } else {
            documentoverride = appendFileTypeinFiles()
        }
        if( ! error ) {
            let signersArray = singerInfo.slice(0, noOfSigner.added)
            let signers = []
            signersArray.map((data, i) => {
                let signer = {
                    firstName: data.fname,
                    lastName: data.lname,
                    middleName: data.middleName,
                    suffix: data.suffix,
                    biometric: data.biometric,
                    nonUS: data.nonUS,
                    email: data.email,
                    phone: data.phone && data.phone != null ? data.phone : '',
                    address: data.address,
                    zip: data.zip,
                    city: data.city,
                    state: data.state,
                    bypassKBA: data.byPassKBA,
                    bypassReason: data.reason,
                    ssnLast4: data.ssn.substring(data.ssn.length - 4),
                    dob: data.dob,
                }

                if ((action === "post" || action === "now" ) && data.byPassKBA) {
                    setissingleKBA(true)
                    checkbypasskba++
                }

                if (resumeData !== null) {
                    let resumeSigners = resumeData[0].Signers.length - 1
                    if (i <= resumeSigners) signer.id = resumeData[0].Signers[i].id
                }

                if (addedSigners !== null) {
                    let resumeSigners = addedSigners.length - 1
                    if (i <= resumeSigners) signer.id = addedSigners[i].id
                }
                signers.push(signer)
            })
            body = {
                userId: auth.user.id,
                initiatedByNotary:
                    resumeData !== null ? resumeData[0].initiatedByNotary : false,
                action: action,
                willDoc: willDoc === 'no' ? false : true,
                electionDoc: electionDoc === 'no' ? false : true,
                paymentId: paymentId === null ? '' : paymentId,
                files: documentoverride,
                Signers: signers,
                comments: comments,
                type: '',
                scheduledAt: dateTime ? new Date(dateTime).toISOString() : new Date().toISOString(),
                primaryNotary: selectedNotary.primary,
                witnessCount: noOfWitness.value,
            }

            if (journalID !== 0) {
                body.journalId = journalID
            }

            if (resumeData !== null) {
                if (resumeData[0].Payments.length > 0) {
                    body.paymentId = resumeData[0].Payments[0].id
                }
                body.journalId = resumeData[0].id
            }

            if( action === "post" ) {
                if ( lang.length > 0) {
                    body.languages = lang
                }

                if (prefstate) {
                    body.state = prefstate
                }

                if (dateTime) {
                    if (
                        Date.parse(dateTime) >=
                        Date.parse(new Date(new Date().getTime() + 30 * 60000))
                    )
                        body.scheduledAt = new Date(dateTime).toISOString()
                    else
                        body.scheduledAt = new Date(
                            new Date().getTime() + 30 * 60000
                        ).toISOString()
                }

                if (typeNotary) {
                    body.notaryType = typeNotary
                }
            }

            body.checkbypasskba = false;
            if (checkbypasskba == signersArray.length) {
                body.checkbypasskba = true;
            }
        }
        else{
            setNotification({ show: true, msg: errorMessage, variant: 'error' })
            return false;
        }        
        return body;
    }

    const draftHandler = (status = null, draftPaymentID = null) => {

        setisButtonClicked(true)
        let body = formatNotarizationPayload( draftPaymentID, "draft" )
        if(body) {
            if (status === 'help') {
                body.needHelp = true
            }

            if (status === 'payment') {
                body.paymentId = draftPaymentID
            }

            if (journalID !== 0) {
                body.journalId = journalID
            }

            if (resumeData !== null) {
                if (resumeData[0].Payments.length > 0) {
                    body.paymentId = resumeData[0].Payments[0].id
                }
                body.journalId = resumeData[0].id
                body.action = resumeData[0].status

                setjournalID(body.journalId)
            }
            dispatch(
                actions.addNewNotarization(
                    body,
                    headers,
                    setSaveLaterSuccess,
                    setNotification,
                    status
                )
            ).then((data) => {
                setjournalID(data.id)
                setAddedSigners(data.journal.Signers)
            })
        }
    }

    const schedulerNotarizationHandler = (dateTime) => {

        if (Date.parse(dateTime) < Date.parse(new Date())) {
            alert(
                'Please provide date and time, the time should be at least 30 min'
            )
            return false
        }
        setisButtonClicked(true)
        if(platformFee.fee) {
            dispatch(
                actions.payPlatformFee({
                    userId: auth.user.id,
                    title: 'Platform fee',
                    currency: 'usd',
                    amount: Math.round(platformFee.fee * 100),
                    cardId: defaultPaymentCard.id,
                }, headers, {
                    setPaid,
                    setPayNowModal,
                    setCstmDisable,
                    setNotification,
                })
            ).then((paymentId) => {
                setScheduleLaterDateTime(prepareTime(dateTime))
        
                let body = formatNotarizationPayload( paymentId, "scheduled", dateTime );
                if(body) {
                    dispatch(
                        actions.addNewNotarization(
                            body,
                            headers,
                            setScheduleLaterSuccess,
                            setNotification
                        )
                    )
                    setSchedulerModal(false)
                }
            })
        }
        else if( resumeData !== null ) {
            setScheduleLaterDateTime(prepareTime(dateTime))
        
            let body = formatNotarizationPayload( null, "scheduled", dateTime );
            if(body) {
                dispatch(
                    actions.addNewNotarization(
                        body,
                        headers,
                        setScheduleLaterSuccess,
                        setNotification
                    )
                )
                setSchedulerModal(false)
            }
        }
    }

    const jobPostHandler = () => {
        let preselectNotarization = location.search
        const urlParams = new URLSearchParams(location.search)

        preselectNotarization = urlParams.get('preselectNotarization')
        setisButtonClicked(true)
        let body = {
            action: 'post',
            userId: auth.user.id,
        }

        if (preselectNotarization) {
            if (lang.length > 0) {
                body.languages = lang
            }

            if (prefstate) {
                body.state = prefstate
            }

            if (Date.parse(dateTime) < Date.parse(new Date())) {
                alert(
                    'Please provide date and time, the time should be at least 30 min'
                )
                return false
            }

            if (dateTime) {
                if (
                    Date.parse(dateTime) >=
                    Date.parse(new Date(new Date().getTime() + 30 * 60000))
                )
                    body.scheduledAt = new Date(dateTime).toISOString()
                else
                    body.scheduledAt = new Date(
                        new Date().getTime() + 30 * 60000
                    ).toISOString()
            }

            if (typeNotary) {
                body.notaryType = typeNotary
            }

            if (preselectNotarization !== 0) {
                body.journalId = preselectNotarization
            }

            dispatch(
                actions.UpdateNotarization(
                    preselectNotarization,
                    body,
                    headers,
                    setNotarizeNowSuccess,
                    setNotification
                )
            ).then((data) => {
                if (data) {
                    setJobPostDoneModal(true)
                    setcpostModal(false)
                }
            })
        } else {
            if(platformFee.fee) {
                dispatch(
                    actions.payPlatformFee({
                        userId: auth.user.id,
                        title: 'Platform fee',
                        currency: 'usd',
                        amount: Math.round(platformFee.fee * 100),
                        cardId: defaultPaymentCard.id,
                    }, headers, {
                        setPaid,
                        setPayNowModal,
                        setCstmDisable,
                        setNotification,
                    })
                ).then((paymentId) => {
                    body = formatNotarizationPayload( paymentId, "post", dateTime );
                    if(body) {
                        dispatch(
                            actions.addNewNotarization(
                                body,
                                headers,
                                setNotarizeNowSuccess,
                                setNotification
                            )
                        ).then((data) => {
                            if (data) {
                                setJobPostDoneModal(true)
                                setcpostModal(false)
                            }
                        })
                    }
                })
            }
            else if( resumeData !== null ) {
                body = formatNotarizationPayload( null, "post", dateTime );
                if(body) {
                    dispatch(
                        actions.addNewNotarization(
                            body,
                            headers,
                            setNotarizeNowSuccess,
                            setNotification
                        )
                    ).then((data) => {
                        if (data) {
                            setJobPostDoneModal(true)
                            setcpostModal(false)
                        }
                    })
                }
            }
        }
        
    }

    const notarizeNowHandler = async () => {
        setisButtonClicked(true)
        if(platformFee.fee) {
            dispatch(
                actions.payPlatformFee({
                    userId: auth.user.id,
                    title: 'Platform fee',
                    currency: 'usd',
                    amount: Math.round(platformFee.fee * 100),
                    cardId: defaultPaymentCard.id,
                }, headers, {
                    setPaid,
                    setPayNowModal,
                    setCstmDisable,
                    setNotification,
                })
            ).then((paymentId) => {
                
                let body = formatNotarizationPayload( paymentId, "now" );

                dispatch(
                    actions.addNewNotarization(
                        body,
                        headers,
                        setNotarizeNowSuccess,
                        setNotification
                    )
                ).then((data) => {
                    if (body.checkbypasskba) {
                        window.location.href = '#/new-notarization?preselectNotarization=' + data.id
                        window.location.reload()
                    } else {
                        // syncforsignersverification(data.id)
                        window.location.href = '#/dashboard'
                        window.location.reload()
                    }
                })
            })
        }
        else if( resumeData !== null )  {
            let body = formatNotarizationPayload( null, "now" );
            if(body) {
                dispatch(
                    actions.addNewNotarization(
                        body,
                        headers,
                        setNotarizeNowSuccess,
                        setNotification
                    )
                ).then((data) => {
                    if (body.checkbypasskba) {
                        window.location.href = '#/new-notarization?preselectNotarization=' + data.id
                        window.location.reload()
                    } else {
                        // syncforsignersverification(data.id)
                        window.location.href = '#/dashboard'
                    }
                })
            }
        }
    }

    const syncforsignersverification = (journalID) => {
        setShowKBAVerificationModal(true)
        setjournalID(journalID)
        dispatch(actions.checkSigner(journalID, headers))
        setsignerInterval(
            setInterval(function() {
                dispatch(actions.checkSigner(journalID, headers))
            }, 8000)
        )
    }

    const syncforjournalverification = (journalID) => {
        setjournalInterval(
            setInterval(function() {
                checknotarynotificationtimeout()
            }, 8000)
        )
    }

    const checksignersstatus = (currentkbasignersfunc) => {
        if (currentkbasignersfunc.length > 0) {
            for (
                let signerscount = 0;
                signerscount < currentkbasignersfunc.length;
                signerscount++
            ) {
                if (currentkbasignersfunc[signerscount].status == 'pending')
                    return false
                else if (
                    currentkbasignersfunc[signerscount].status == 'rejected'
                )
                    return 'rejected'
            }
            return true
        }
    }

    const saveLaterSuccessHideHandler = () => {
        setSaveLaterSuccess(false)
        window.location.href = '/#/'
    }

    const notarizeNowSuccessHideHandler = () => {
        setNotarizeNowSuccess(false)
        window.location.href = '/#/'
    }

    const scheduleLaterSuccessHideHandler = () => {
        setScheduleLaterSuccess(false)
        window.location.href = '/#/'
    }

    const schedulerCloseHandler = () => setSchedulerModal(false)
    const scheduleHandler = () => setSchedulerModal(true)
    const handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') return
        setNotification({ show: false, msg: '', variant: '' })
    }

    const selectNotary = () => {
        //setChooseNotary(true);
        confirmjobPostHandler()
    }

    const chooseNotaryHandler = (e) => {
        let notaryID = e.currentTarget.id
        let body = {
            journalId: journalID,
            notaryId: parseInt(notaryID),
            makePrimary: false,
        }

        if (
            document.querySelector(
                'input[name="make_primary_notary"][value="' + notaryID + '"]'
            )
        ) {
            if (
                document.querySelector(
                    'input[name="make_primary_notary"][value="' +
                        notaryID +
                        '"]'
                ).checked
            ) {
                body.makePrimary = true
            }
        }
        dispatch(
            actions.setSelectedNotary(body, headers, setNotification)
        ).then((data) => {
            if (parseInt(data) == 1) {
                setselectNotaryID(notaryID)
                setshowNotaryWaiting(true)
                setinitnotarynotificationcounter(true)
                syncforjournalverification(journalID)
                dispatch(actions.checkJournal(journalID, headers))
            } else {
                setshowNoNotary(true)
            }
        })
    }

    const checkkbatimeout = () => {
        setkbaflowcounter(kbaflowcounter + 1)
        if (kbaflowcounter > 220) return true
        else return false
    }

    const checknotarynotificationtimeout = () => {
        if (getAllJournal && getAllJournal.journal && getAllJournal.journal.id)
            dispatch(actions.checkJournal(getAllJournal.journal.id, headers))
        else dispatch(actions.checkJournal(journalID, headers))
    }

    const reloadNotarize = () => {
        setkbatimeout(false)
        window.location.reload()
    }

    useEffect(() => {
        if (primaryNotary === null) {
            dispatch(actions.fetchPrimaryNotary(headers)).then((data) => {
                setSelectedNotary({
                    primary: selectedNotary.primary,
                    primaryNotary: data,
                })
            })
            setintialprimaryNotary(true)
        } else if (!intialprimaryNotary) {
            setSelectedNotary({
                primary: selectedNotary.primary,
                primaryNotary: primaryNotary,
            })
            setintialprimaryNotary(true)
        } else {
            setSelectedNotary({ primary: true, primaryNotary: primaryNotary })
        }

        if (
            resumeData &&
            resumeData[0] &&
            resumeData[0].id &&
            !resumeData[0].notaryId
        ) {
            setSelectedNotary({ primary: false, primaryNotary: '' })
        }

        if (!KBASettingscheckdispatch) {
            dispatch(actions.fetchKBASettings(headers))

            setKBASettingscheckdispatch(true)
        }
        if (KBASettings && KBASettings.data && !KBASettingscheck) {
            setSingerInfo(singers)
            setFilteredSingers(singers)
            setKBASettingscheck(true)
        }

        let preselectNotarization = location.search
        const urlParams = new URLSearchParams(location.search)

        preselectNotarization = urlParams.get('preselectNotarization')
        if (preselectNotarization && !getAllJournal) {
            setisActive(true)
            dispatch(actions.checkJournal(preselectNotarization, headers))
        } else if (
            preselectNotarization &&
            getAllJournal &&
            !chooseNotaryProp
        ) {
            setchooseNotaryProp(true)
            setjournalID(preselectNotarization)

            if (getAllJournal.journal.notaryId) {
                let notaryID = getAllJournal.journal.notaryId

                for (
                    let isign = 0;
                    isign < getAllJournal.journal.Signers.length;
                    isign++
                ) {
                    if (getAllJournal.journal.Signers[isign].bypassKBA) {
                        setissingleKBA(true)
                    }
                }

                let body = {
                    journalId: preselectNotarization,
                    notaryId: parseInt(notaryID),
                }
                dispatch(
                    actions.setSelectedNotary(body, headers, setNotification)
                ).then((data) => {
                    if (parseInt(data) == 1) {
                        setisActive(false)
                        setselectNotaryID(notaryID)
                        setshowNotaryWaiting(true)
                        setinitnotarynotificationcounter(true)
                        dispatch(
                            actions.checkJournal(preselectNotarization, headers)
                        )
                        syncforjournalverification(preselectNotarization)
                        setPrimaryNotarySelected(true)
                    } else {
                        setshowNoNotary(true)
                    }
                })
            } else {
                setisActive(false)
                setChooseNotary(true)
            }
        }

        if (
            getAllJournal &&
            getAllJournal.journal &&
            getAllJournal.journal.status &&
            'Authentication Passed' == currentSubstatus &&
            issingleKBA
        ) {
            noticounter = 0
            setcurrentSubstatus('')
            setshowNotaryWaiting(false)
            setshowReNotary(true)
            setNotification({
                show: true,
                msg: 'Please Reschedule with your Notary, or post to the Job Board.',
                variant: 'error',
            })
            setinitnotarynotificationcounter(false)

            if (
                (KBASettings &&
                    KBASettings.data &&
                    KBASettings.data.settings.bypassAllowed) ||
                issingleKBA
            )
                setTimeout(function() {
                    window.location.href = '/#/journals'
                    window.location.reload()
                }, 100)
        } else if (
            getAllJournal &&
            getAllJournal.journal &&
            getAllJournal.journal.status &&
            'Authentication Passed' == currentSubstatus &&
            !issingleKBA
        ) {
            noticounter = 0
            setcurrentSubstatus('')
            setshowNotaryWaiting(false)
            setshowReNotary(true)
            setNotification({
                show: true,
                msg: 'Please Reschedule with your Notary, or post to the Job Board.',
                variant: 'error',
            })
            setinitnotarynotificationcounter(false)
        } else if (
            null != socket.notarizationRejectedID &&
            journalID == socket.notarizationRejectedID
        ) {
            noticounter = 0
            setcurrentSubstatus('')
            setshowNotaryWaiting(false)
            setshowReNotary(true)
            setNotification({
                show: true,
                msg: 'Please Reschedule with your Notary, or post to the Job Board.',
                variant: 'error',
            })
            setinitnotarynotificationcounter(false)
            dispatch(socketactions.emptynotarizationRejection())

            if (
                (KBASettings &&
                    KBASettings.data &&
                    KBASettings.data.settings.bypassAllowed) ||
                issingleKBA
            )
                setTimeout(function() {
                    window.location.href = '/#/journals'
                    window.location.reload()
                }, 3000)
        } else if (
            getAllJournal &&
            getAllJournal.journal &&
            getAllJournal.journal.subStatus
        ) {
            if (
                !currentSubstatus &&
                getAllJournal.journal.subStatus == 'Authentication Passed'
            ) {
            } else setcurrentSubstatus(getAllJournal.journal.subStatus)
        }

        if (!initnotarynotificationcounter) {
            setjournalInterval(clearInterval(journalInterval))
        }

        if (getAllJournal && getAllJournal.journal.subStatus == 'Accepted') {
            setshowNotaryWaiting(false)
            setshowNoNotary(false)
            setShowVerificationDoneModal(true)
            setjournalInterval(clearInterval(journalInterval))
        } else if (
            getAllJournal &&
            getAllJournal.journal.subStatus == 'Rejected'
        ) {
            setshowNotaryWaiting(false)
            setshowReNotary(true)
            setNotification({
                show: true,
                msg:
                    'Notary Rejected your Notarization. Kindly choose another notary',
                variant: 'error',
            })
            setjournalInterval(clearInterval(journalInterval))
        }

        dispatch(actions.getLanguages(headers))
        dispatch(actions.fetchNotaryTypes(headers))
        fetchEnabledStates()
    }, [
        dispatch,
        primaryNotary,
        currentkbasigners,
        currentjournalstatus,
        socket,
        noticounter,
        getAllJournal,
        KBASettings,
        issingleKBA,
    ])

    return (
        <>
            <div>
                <LoadingOverlay active={isActive} spinner text='Loading...'>
                    {' '}
                    {notification.show && (
                        <ShowNotification
                            show={notification.show}
                            msg={notification.msg}
                            variant={notification.variant}
                            close={handleCloseNotification}
                        />
                    )}
                    {changeMethod ? (
                        <PaymentCardListing
                            setChangeMethod={setChangeMethod}
                            setNotification={setNotification}
                            link={true}
                        />
                    ) : chooseNotary ? (
                        <OnlineSearchNotaries
                            chooseNotary={true}
                            chooseNotaryHandler={chooseNotaryHandler}
                            showNotaryWaiting={showNotaryWaiting}
                            saveLaterSuccessHideHandler={
                                saveLaterSuccessHideHandler
                            }
                            showVerificationDoneModal={
                                showVerificationDoneModal
                            }
                            noback={true}
                        />
                    ) : (
                        <>
                            {KBASettings && KBASettings.data && (
                                <NewNotarizationRoot
                                    setChangeMethod={setChangeMethod}
                                    selectedNotary={selectedNotary}
                                    setSelectedNotary={setSelectedNotary}
                                    singerInfo={singerInfo}
                                    confirmjobPostHandler={
                                        confirmjobPostHandler
                                    }
                                    jobPostHandler={jobPostHandler}
                                    setSingerInfo={setSingerInfo}
                                    noOfSigner={noOfSigner}
                                    setNoOfSigner={setNoOfSigner}
                                    comments={comments}
                                    setComments = {setComments}
                                    willDoc={willDoc}
                                    setWillDoc={setWillDoc}
                                    electionDoc={electionDoc}
                                    setElectionDoc={setElectionDoc}
                                    paid={paid}
                                    payNowModal={payNowModal}
                                    setShowAttentionMessage={
                                        setShowAttentionMessage
                                    }
                                    showAttentionMessage={showAttentionMessage}
                                    fetchEnabledStates={fetchEnabledStates}
                                    allAdminStates={allAdminStates}
                                    setPayNowModal={setPayNowModal}
                                    payNowHandler={payNowHandler}
                                    payPlatformFeeHandler={
                                        payPlatformFeeHandler
                                    }
                                    draftHandler={draftHandler}
                                    scheduleHandler={scheduleHandler}
                                    notarizeNowHandler={notarizeNowHandler}
                                    saveLaterSuccess={saveLaterSuccess}
                                    saveLaterSuccessHideHandler={
                                        saveLaterSuccessHideHandler
                                    }
                                    scheduleLaterSuccess={scheduleLaterSuccess}
                                    scheduleLaterSuccessHideHandler={
                                        scheduleLaterSuccessHideHandler
                                    }
                                    headers={headers}
                                    cstmDisable={paid}
                                    schedulerModal={schedulerModal}
                                    cpostModal={cpostModal}
                                    setcpostModal={setcpostModal}
                                    schedulerCloseHandler={
                                        schedulerCloseHandler
                                    }
                                    schedulerNotarizationHandler={
                                        schedulerNotarizationHandler
                                    }
                                    notarizeNowSuccess={notarizeNowSuccess}
                                    notarizeNowSuccessHideHandler={
                                        notarizeNowSuccessHideHandler
                                    }
                                    platformFee={platformFee}
                                    setNotification={setNotification}
                                    documents={documents}
                                    scheduleLaterDateTime={
                                        scheduleLaterDateTime
                                    }
                                    showNoNotary={showNoNotary}
                                    showNotaryWaiting={showNotaryWaiting}
                                    showReNotary={showReNotary}
                                    showKBAVerificationModal={
                                        showKBAVerificationModal
                                    }
                                    showVerificationDoneModal={
                                        showVerificationDoneModal
                                    }
                                    filteredSingers={filteredSingers}
                                    selectNotary={selectNotary}
                                    kbatimeout={kbatimeout}
                                    reloadNotarize={reloadNotarize}
                                    setShowVerificationDoneModal={
                                        setShowVerificationDoneModal
                                    }
                                    setNoOfWitness={setNoOfWitness}
                                    noOfWitness={noOfWitness}
                                    JobPostDoneModal={JobPostDoneModal}
                                    lang={lang}
                                    setLang={setLang}
                                    dateTime={dateTime}
                                    setDateTime={setDateTime}
                                    typeNotary={typeNotary}
                                    setTypeNotary={setTypeNotary}
                                    prefstate={prefstate}
                                    issingleKBA={issingleKBA}
                                    setprefstate={setprefstate}
                                    settypeFile={settypeFile}
                                    typeFile={typeFile}
                                    handleChangeFileType={handleChangeFileType}
                                    showPaymentModal={showPaymentModal}
                                    isButtonClicked={isButtonClicked}
                                    resumeNotarization={
                                        resumeData !== null
                                            ? resumeData[0]
                                            : null
                                    }
                                />
                            )}{' '}
                        </>
                    )}
                </LoadingOverlay>{' '}
            </div>
        </>
    )
}
