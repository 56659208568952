import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Modal, Button } from 'react-bootstrap'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import * as actions from '../../components/_redux/notarization_redux/notarizationActions'
import { FilePreviewTable } from './FilePreviewTable'
import { SignerInfo } from './SignerInfo'
import { NoFileView } from './NoFileView'
import { AllButtons } from './AllButtons'
import { SuccessModal } from './modals/SuccessModal'
import { DateTimePickerModal } from './modals/DateTimePickerModal'
import { AddUser } from './AddUser'
import { WitnessInfo } from './WitnessInfo'
import { AddCardModal } from '../new_card/AddCardModal'
import { PaynowModals } from './PaynowModals'
import { NoCardAvailable } from './NoCardAvailable'
import { PaymentTable } from './PaymentTable'

export default function NewNotarizationNotaryRoot(props) {
    let {
        setChangeMethod,
        setSelectedUser,
        singerInfo,
        setSingerInfo,
        setNoOfSigner,
        noOfSigner,
        disableButton,
        setDisableButton,
        draftHandler,
        payNowHandler,
        payNowModal,
        setPayNowModal,
        payPlatformFeeHandler,
        saveLaterSuccess,
        notarizeNowHandler,
        notarizeNowitHandler,
        scheduleHandler,
        schedulerModal,
        schedulerCloseHandler,
        schedulerNotarizationHandler,
        notarizeNowSuccessHideHandler,
        saveLaterSuccessHideHandler,
        platformFee,
        headers,
        iscontinue,
        setsigneralready,
        cstmDisable,
        setNotification,
        notarizeNowSuccess,
        selectedUser,
        resumeData,
        isalreadypaid,
        setNoOfWitness,
        resumeNotarization,
        noOfWitness,
        handleChangeFileType,
        typeFile,
        isButtonClicked,
    } = props
    const [addCard, setAddCard] = useState(false)
    const [deleteModal, setDeleteModal] = useState({ show: false, id: null })
    const [prepay, setprepay] = useState({ show: false, id: null })
    const { auth, notarization } = useSelector((state) => state)
    const {
        defaultPaymentCard,
        documents,
        allClients,
        scheduleLoading,
    } = notarization
    const [uploading, setUploading] = useState(false)
    const dispatch = useDispatch()
    const getFiles = (newFiles, rejectedFiles) => {
        setUploading(true)
        if (documents.length + newFiles.length > 20) {
            setNotification({
                show: true,
                msg: 'You can not upload more than 20 files',
                variant: 'error',
            })
            setUploading(false)
        } else {
            if (rejectedFiles.length > 0) {
                let errorCode = rejectedFiles[0].errors[0].code
                let errorMessage =
                    'Only PDF and Docs file format is acceptable!'
                if (errorCode === 'file-invalid-type')
                    errorMessage =
                        'Only JPG, JPEG, PNG, PDF and Docs file format is acceptable!'
                // else if(errorCode === 'file-too-small') errorMessage = 'Acceptable minimum file size is 70KB!';
                else if (errorCode === 'file-too-large')
                    errorMessage = 'Acceptable maximum file size is 10MB!'
                setNotification({
                    show: true,
                    msg: errorMessage,
                    variant: 'error',
                })
                setUploading(false)
            }

            if (newFiles.length > 0) {
                setUploading(true)
                let formData = new FormData()
                formData.append('userId', auth.user.id)
                formData.append('type', 'journalDoc')
                newFiles.map((file) => formData.append('file', file))
                dispatch(
                    actions.uploadDocuments(
                        formData,
                        headers,
                        setNotification,
                        setUploading,
                        null
                    )
                )
            }
        }
    }

    useEffect(() => {
        if (selectedUser) {
            let signerinfos = singerInfo

            if (
                selectedUser &&
                selectedUser.kbaSettings &&
                selectedUser.kbaSettings[0] &&
                selectedUser.kbaSettings[0].bypassAllowed
            ) {
                signerinfos[0].byPassKBA = true
                signerinfos[0].reason = selectedUser.kbaSettings[0].reason
                setSingerInfo(signerinfos)
            }
        }
    }, [allClients, selectedUser])

    const removeFileAction = (e) =>
        setDeleteModal({ show: true, id: e.currentTarget.id })
    const handleClose = () => setDeleteModal({ show: false, id: null })

    const handlePrepayClose = () => {
        setprepay({ show: false, id: null })
    }

    const prepayhandler = () => {
        setprepay({ show: true, id: null })
    }

    const removeFile = () => {
        handleClose()
        dispatch(
            actions.deleteDocument(deleteModal.id, headers, setNotification)
        )
    }

    const arrowStyle = {
        fontSize: '36px',
        color: '#909090',
        backgroundColor: '#fbfbfb',
        borderRadius: '50%',
        marginRight: '2px',
        padding: '7px',
    }

    return (
        <>
            <div className='card card-custom'>
                <div className='pl-6 pt-7'>
                    <Link to='/#/dashboard'>
                        <ArrowBackIcon style={arrowStyle} />{' '}
                        <span style={{ fontSize: '14px', color: '#909090' }}>
                            Back
                        </span>
                    </Link>
                </div>
                <div className='card-body d-flex align-items-center justify-content-between flex-wrap pt-3 pb-0'>
                    <div className='mr-2'>
                        <h3 className='font-weight-bolder'>New Notarization</h3>
                    </div>
                </div>
                <br />
                <AddUser
                    users={
                        allClients !== null && allClients.rows
                            ? allClients.rows
                            : []
                    }
                    setSelectedUser={setSelectedUser}
                    selectedUser={selectedUser}
                    disabled={disableButton}
                />
                <br />
                {schedulerModal && (
                    <DateTimePickerModal
                        show={schedulerModal}
                        handleClose={schedulerCloseHandler}
                        isButtonClicked={isButtonClicked}
                        schedulerNotarizationHandler={
                            schedulerNotarizationHandler
                        }
                    />
                )}

                {defaultPaymentCard !== null ? (
                    <PaymentTable
                        setChangeMethod={setChangeMethod}
                        card={defaultPaymentCard}
                        disabled={disableButton}
                    />
                ) : (
                    <NoCardAvailable addCard={setAddCard} />
                )}
                {addCard && (
                    <>
                        {' '}
                        <AddCardModal
                            openModal={addCard}
                            setModal={setAddCard}
                            setNotification={setNotification}
                        />
                    </>
                )}

                {payNowModal && (
                    <PaynowModals
                        platformFee={platformFee}
                        payNowModal={payNowModal}
                        setPayNowModal={setPayNowModal}
                        payPlatformFeeHandler={payPlatformFeeHandler}

                        auth={auth}
                        isButtonClicked={isButtonClicked}
                        paynow={payNowHandler}
                        paid={disableButton}
                        scheduleHandler={scheduleHandler}
                        draftHandler={draftHandler}
                        notarizeNowHandler={notarizeNowHandler}
                        prepayhandler={prepayhandler}
                        user={false}
                        resumeData={resumeData}
                        notarizeNowitHandler={notarizeNowitHandler}
                        resumeNotarization={resumeNotarization}
                        iscontinue={iscontinue}
                        isalreadypaid={isalreadypaid}
                    />
                )}

                <br />

                <NoFileView getFiles={getFiles} uploading={uploading} />
                <br />
                {documents.length > 0 && (
                    <FilePreviewTable
                        files={documents}
                        removeFile={removeFileAction}
                        disabled={disableButton}
                        typeFile={typeFile}
                        handleChangeFileType={handleChangeFileType}
                        resumeNotarization={resumeNotarization}
                    />
                )}
                <br />
                <SignerInfo
                    singerInfo={singerInfo}
                    setSingerInfo={setSingerInfo}
                    setNoOfSigner={setNoOfSigner}
                    noOfSigner={noOfSigner}
                    user={false}
                    selectedUser={selectedUser}
                    disabled={disableButton}
                    iscontinue={iscontinue}
                    setsigneralready={setsigneralready}
                />
                <br />
                <WitnessInfo
                    setNoOfWitness={setNoOfWitness}
                    noOfWitness={noOfWitness}
                />
                <br />
                <AllButtons
                    isButtonClicked={isButtonClicked}
                    paynow={payNowHandler}
                    paid={disableButton}
                    scheduleHandler={scheduleHandler}
                    draftHandler={draftHandler}
                    notarizeNowHandler={notarizeNowHandler}
                    prepayhandler={prepayhandler}
                    user={false}
                    resumeData={resumeData}
                    notarizeNowitHandler={notarizeNowitHandler}
                    resumeNotarization={resumeNotarization}
                    iscontinue={iscontinue}
                    isalreadypaid={isalreadypaid}
                />

                <Modal show={deleteModal.show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure?</Modal.Body>
                    <Modal.Footer>
                        <Button variant='secondary' onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant='danger' onClick={removeFile}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>

                {saveLaterSuccess && (
                    <SuccessModal
                        heading='Save for later'
                        bodyText='This notarization has been successfully saved; it is located in your journal.'
                        show={saveLaterSuccess}
                        hide={saveLaterSuccessHideHandler}
                    />
                )}
                {notarizeNowSuccess && (
                    <SuccessModal
                        heading='Successfully sent to the client'
                        bodyText='The notarization has been sent successfully to the client.'
                        show={notarizeNowSuccess}
                        hide={notarizeNowSuccessHideHandler}
                    />
                )}
            </div>
        </>
    )
}
