import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";

export function CookieEnable(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div>
        <strong>Disclaimer: </strong>When asked to remain logged into Adobe,
        always say YES. To Access this document after you hit send, login to
        your Cyberize It account at https://documentcloud.adobe.com/. Select
        Sign, Manage Agreements and the file will be available.
        <span
          onClick={handleShow}
          style={{ color: "green", cursor: "pointer" }}
        >
          {" "}
          If you get a cookie error on this screen, click here for instructions
          on how to correct this.
        </span>
        <Modal show={show} onHide={handleClose} animation={false} size="md">
          <Modal.Header closeButton>
            <Modal.Title>How to Enable Cookie in Browser</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h2> Chrome </h2>
            Enabling Cookies in Google Chrome
            <br />
            <br />
            Open a new window with Google Chrome.
            <br />
            Select the Chrome Menu from the browser’s toolbar.
            <br />
            Near the bottom of the menu, click Settings.
            <br />
            At the bottom of the Settings menu, click Show advanced settings.
            <br />
            In the Privacy section, click Content settings.
            <br />
            Select Allow local data to be set (recommended) in the Cookies
            section.
            <br />
            Click Done.
            <br />
            Refresh Chrome to enable cookies.
            <br />
            <br />
            <h2> Internet Explorer </h2>
            Enabling Cookies in Microsoft Internet Explorer
            <br />
            <br />
            Open a new window with Microsoft Internet Explorer.
            <br />
            From the menu bar, click the Tools icon and select Internet Options.
            <br />
            From the Internet Options window, select the Privacy tab.
            <br />
            From the Privacy tab, there is a vertical slider that controls the
            cookie settings, from Accept All Cookies to Block All Cookies. The
            simplest way is to click on the Default button to reset the browser
            to the default Privacy/Cookies setting.
            <br />
            Once the desired cookie setting has been set, press the OK button at
            the bottom of the window to enable the new cookies setting.
            <br />
            Refresh Internet Explorer to enable cookies.
            <br />
            <br />
            <h2>Firefox</h2>
            Enabling Cookies in Mozilla Firefox
            <br />
            <br />
            Open a new window with Mozilla Firefox.
            <br />
            From the menu bar, click the Firefox menu item, then pick
            Preferences/Options from the drop-down menu. If you are using Mac OS
            X, it will be called Preferences. If you are using Windows, it will
            be called Options.
            <br />
            Select the Privacy icon/tab in the left side navigation.
            <br />
            Under the History section, you will see Firefox with a drop-down
            menu. Select the Use custom settings for history from the drop-down
            menu.
            <br />
            Select the Accept cookies from sites checkbox to enable the cookies.
            <br />
            Close the Preferences/Options window to save your changes.
            <br />
            Refresh Firefox to enable cookies.
            <br />
            <br />
            <h2>Safari</h2>
            Enabling Cookies in Apple Safari
            <br />
            <br />
            From the Safari menu, select Preferences.
            <br />
            Click on the Privacy icon.
            <br />
            Under Cookies and Website Data:, select Always allow.
            <br />
            Close the window.
            <br />
            Refresh Safari to enable cookies.
            <br />
            <br />
            <h2>Microsoft Edge</h2>
            Enabling Cookies in Microsoft Edge
            <br />
            <br />
            Open a new window with Microsoft Edge.
            <br />
            Click on the More actions button in the top-right corner and select
            Settings.
            <br />
            Scroll down the Settings and click on View advanced settings.
            <br />
            Under Cookies, select the option Don’t block cookies.
            <br />
            Refresh Microsoft Edge to enable cookies.
            <br />
            <br />
            <h2>Opera</h2>
            Enabling Cookies in Opera
            <br />
            <br />
            Open a new window with Opera.
            <br />
            From the Opera drop-down menu, select Preferences.
            <br />
            Select the Basic submenu.
            <br />
            Look for the Cookies section and select the Allow local data to be
            set (recommended).
            <br />
            Refresh Opera to enable cookies.
            <br />
            <br />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
