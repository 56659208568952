import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/notarization_redux/notarizationActions";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses
} from "../../../../_metronic/_helpers";
import * as uiHelpers from "../TableUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../_metronic/_partials/controls";
import { useTableUIContext } from "../TableUIContext";

export function CustomTable() {
  // Customers UI Context
  const customersUIContext = useTableUIContext();

  const dispatch = useDispatch();
  const { auth, notarization } = useSelector(state => state);
  const { clients, listLoading } = notarization;
  const [checkKBA, setcheckKBA] = useState(0);
  const [listKBA, setlistKBA] = useState("");
  const [reason, setReason] = useState("");

  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      setIds: customersUIContext.setIds,
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
      setcheckKBA: customersUIContext.setcheckKBA
    };
  }, [customersUIContext]);

  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  const bypassKBAfinalize = id => {
    let body = {
      bypassAllowed: true,
      userId: checkKBA,
      reason: reason
    };

    dispatch(actions.bypassKBAAlways(body, headers)).then(response => {
      if (response && response.data && response.data.status)
        window.location.reload();
      else alert("Please Provide Short Message Body");
    });
  };

  const resetbypassKBAfinalize = id => {
    let body = {
      bypassAllowed: false,
      userId: id
    };

    dispatch(actions.bypassKBAAlways(body, headers)).then(data => {
      window.location.reload();
    });
  };
  // Table columns
  const columns = [
    {
      dataField: "firstName",
      text: "CLIENT FULL NAME",
      formatter: columnFormatters.NameColumnFormatter
    },
    {
      dataField: "email",
      text: "EMAIL ADDRESS"
    },
    {
      dataField: "phone",
      text: "PHONE NO"
    },
    {
      dataField: "createdAt",
      text: "CLIENT SINCE",
      formatter: columnFormatters.DateColumnFormatter
    },
    {
      dataField: "createdAt",
      text: "Always Override ID Proofing",
      formatter: columnFormatters.overRideKBAColumnFormatter,
      formatExtraData: {
        setcheckKBAFunc: customersUIProps.setcheckKBA,
        checkKBA: checkKBA,
        setcheckKBA: setcheckKBA,
        bypassKBAfinalize: bypassKBAfinalize,
        resetbypassKBAfinalize: resetbypassKBAfinalize,
        setReason: setReason,
        setlistKBA: setlistKBA,
        listKBA: listKBA
      },
      hidden: ( auth.user.Role.code !== "notary" || (auth.user.Role.code === "notary" && !auth.user.notaryProfile.isCancelled ) ) ? false : true,
    }
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: clients === null ? 0 : clients.count,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: customersUIProps.queryParams.pageSize,
    page: customersUIProps.queryParams.pageNumber
  };

  useEffect(() => {
    dispatch(actions.fetchClients(headers, customersUIProps.queryParams));
  }, [customersUIProps.queryParams]);

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="id"
                data={clients === null ? [] : clients.rows}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  customersUIProps.setQueryParams
                )}
                {...paginationTableProps}
              >
                <PleaseWaitMessage
                  entities={clients === null ? [] : clients.rows}
                />
                <NoRecordsFoundMessage
                  entities={clients === null ? [] : clients.rows}
                />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
