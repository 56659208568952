import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import OnlineSearchNotaryContainer from '../components/seach_notaries/OnlineSearchNotaryContainer'
import ShowNotification from '../components/new_notarization/ShowNotification'
import { useLocation } from 'react-router-dom'
import { NotaryWaiting } from '../components/modals/NotaryWaiting'
import * as actions from '../components/_redux/notarization_redux/notarizationActions'

import { GenericModal } from '../components/modals/GenericModal'

export function OnlineSearchNotaries({
    chooseNotary,
    chooseNotaryHandler,
    showNotaryWaiting = false,
    saveLaterSuccessHideHandler = [],
    backchooseNotaryhandler,
    showVerificationDoneModal,
    noback = false,
}) {
    const [notification, setNotification] = useState({
        show: false,
        msg: '',
        variant: '',
    })

    const { auth, notarization } = useSelector((state) => state)
    const headers = {
        Authorization: `Bearer ${auth.authToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
    }

    const location = useLocation()
    const dispatch = useDispatch()
    const [journalID, setjournalID] = useState('')
    const handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') return
        setNotification({ show: false, msg: '', variant: '' })
    }

    const viewinJournals = () => {
        window.location.href = '/#/journals'
        window.location.reload()
    }

    useEffect(() => {
        let getidfromlocation = location.search
        getidfromlocation = getidfromlocation.replace('?id=', '')
        setjournalID(getidfromlocation)

        if (getidfromlocation) {
            dispatch(actions.checkJournal(getidfromlocation, headers, 'setURL'))
            dispatch(
                actions.fetchWitnessesforJournal(getidfromlocation, headers)
            )
        }
    }, [dispatch])

    backchooseNotaryhandler = () => {
        window.history.back()
    }
    return (
        <>
            {notification.show && (
                <ShowNotification
                    show={notification.show}
                    msg={notification.msg}
                    variant={notification.variant}
                    close={handleCloseNotification}
                />
            )}
            {/* {(journalID)?<SearchNotaryContainer setNotification={setNotification} journalID={journalID} />:""} */}
            {auth.user.Role.code !== 'ps' && (
                <>
                    {location.pathname === '/searchWitnesses/' ? (
                        <span>
                            {journalID ? (
                                <OnlineSearchNotaryContainer
                                    setNotification={setNotification}
                                    journalID={journalID}
                                    chooseNotary={chooseNotary}
                                    chooseNotaryHandler={chooseNotaryHandler}
                                    backchooseNotaryhandler={
                                        backchooseNotaryhandler
                                    }
                                />
                            ) : (
                                ''
                            )}
                        </span>
                    ) : (
                        <OnlineSearchNotaryContainer
                            setNotification={setNotification}
                            chooseNotary={chooseNotary}
                            chooseNotaryHandler={chooseNotaryHandler}
                            backchooseNotaryhandler={backchooseNotaryhandler}
                            showVerificationDoneModal={
                                showVerificationDoneModal
                            }
                            noback={noback}
                        />
                    )}
                </>
            )}
            {showNotaryWaiting && (
                <NotaryWaiting
                    heading='Please Wait For Your Notary'
                    show={showNotaryWaiting}
                    hide={saveLaterSuccessHideHandler}
                />
            )}
            {showVerificationDoneModal && (
                <GenericModal
                    heading='Congratulations'
                    bodyText='Congrats! The notary has accepted your notarization request. A Jitsi link will be sent shortly. It will be sent to your email, or you can view it in the transaction.'
                    image='/media/own/congrats.png'
                    btnTxt='View in journals'
                    btnHandler={viewinJournals}
                    show={showVerificationDoneModal}
                    hide={saveLaterSuccessHideHandler}
                />
            )}
        </>
    )
}
