import axios from "axios";
import React, { useEffect, useState } from "react";
import requestPayoutImage from "../../media/Group 101@3x.png";
import requestPayoutIcon from "../../media/successtick.png";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "react-bootstrap";
import Alert from "@material-ui/lab/Alert";
import * as actions from "../../components/_redux/notarization_redux/notarizationActions";
import ShowNotification from "../../components/new_notarization/ShowNotification";
import { Modal, Button } from "react-bootstrap";
import { TextField } from "@material-ui/core";

export function RequestPayout({ type, heading }) {
  const [showComponent, setShowComponent] = useState(false);
  const [showSuccessMSG, setShowSuccessMSG] = useState({
    success: false,
    msg: "",
    variant: ""
  });
  const [showErrorMSG, setShowErrorMSG] = useState({
    error: false,
    msg: "",
    variant: ""
  });
  const [amount, setAmount] = useState("");
  const [showModal, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [loading, setLoading] = useState(false);
  const handleShow = () => setShow(true);
  const { auth, notarization } = useSelector(state => state);
  const {} = notarization;
  let notaryId = auth.notaryId;
  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  const dispatch = useDispatch();

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const requestPayout = () => {
    if (amount >= 25) {
      dispatch(
        actions.requestpayoutrequest(
          { amount: amount * 100, currency: "usd" },
          headers,
          setShowSuccessMSG,
          setShowErrorMSG,
          enableLoading,
          disableLoading
        )
      );
    } else {
      setShowErrorMSG({
        error: true,
        msg: "atlease $25 requrid to send",
        variant: "error"
      });
    }
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") return;
    setShowErrorMSG({ show: false, msg: "", variant: "" });
  };
  const handleCloseNotificationSuccess = (event, reason) => {
    if (reason === "clickaway") return;
    setShowSuccessMSG({ show: false, msg: "", variant: "" });
  };
  return (
    <>
      {showErrorMSG.error && (
        <ShowNotification
          show={showErrorMSG.error}
          msg={showErrorMSG.msg}
          variant={showErrorMSG.variant}
          close={handleCloseNotification}
        />
      )}
      {showSuccessMSG.success && (
        <ShowNotification
          show={showSuccessMSG.success}
          msg={showSuccessMSG.msg}
          variant={showSuccessMSG.variant}
          close={handleCloseNotificationSuccess}
        />
      )}

      {/* {showErrorMSG.error && <Alert severity="error" className="payoutError" style={{ width: '100%' }}>{showErrorMSG.msg}</Alert>} */}
      {/* {showSuccessMSG.success && <Alert severity="error" style={{ width: '100%' }}>{showSuccessMSG.msg}</Alert>} */}
      <div>
        <span className={type}>
          <h3>{heading}</h3>
          {/* <div className="main_request_PayoutIcon">
                        <input type="number" min="1" className="textfields" name="amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
                        <img src={requestPayoutIcon} className="requestPayoutIcon" alt="" onClick={requestPayout} />
                    </div>   */}
          <div className="main-request-payout">
            {/* () => setShowComponent(true) */}
            <img
              src={requestPayoutImage}
              className="requestPayoutImage"
              style={{ cursor: "pointer" }}
              alt=""
              onClick={( auth.user.Role.code !== "notary" || (auth.user.Role.code === "notary" && !auth.user.notaryProfile.isCancelled ) ) ? handleShow : false}
            />
          </div>
          <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Request For Payout</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <TextField
                id="outlined-number"
                label="Enter your payout amount"
                type="number"
                className="w-100"
                inputProps={{ min: 0 }}
                name="amount"
                value={amount}
                InputLabelProps={{
                  shrink: true
                }}
                variant="outlined"
                onChange={e => setAmount(e.target.value)}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                style={{
                  backgroundColor: "#4abd54",
                  color: "white",
                  border: "none"
                }}
                onClick={requestPayout}
              >
                Request
                {loading && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </Button>
              <Button
                variant="secondary"
                style={{
                  backgroundColor: "#f2fff3",
                  color: "#4abd54",
                  border: "none"
                }}
                onClick={handleClose}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </span>
      </div>
    </>
  );
}
