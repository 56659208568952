export const CustomerStatusCssClasses = [
  "danger",
  "success",
  "info",
  "primary",
  "warning",
  "success",
  "primary",
  "danger",
  "success"
];
export const CustomerStatusTitles = [
  "pending",
  "inprogress",
  "completed",
  "draft",
  "scheduled",
  "initiated",
  "paid",
  "failed",
  "rejected",
  "rescheduled",
  "scheduled-unclaimed"
];
export const CustomerTypeCssClasses = ["success", "primary", ""];
export const CustomerTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "3", value: 3 },
  { text: "5", value: 5 },
  { text: "10", value: 10 }
];
export const initialFilter = {
  sortOrder: "desc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 10
};
