import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { Container, Row, Col } from 'react-bootstrap'
import { CircularProgress, TextField } from '@material-ui/core'
import * as actions from '../_redux/notarization_redux/notarizationActions'
import ShowNotification from '../new_notarization/ShowNotification'

export function AgreementNotificationModal({
    heading,
    show,
    c,
    detail,
    btnHandler,
    cancel,
    hide,
    sealCount,
    notaryType,
    fee = 0,
    documentCount = 0,
    seal,
    handleCloseNotification,
    notification,
    isdone,
    sethideModal,
    setNotification,
    setpaymentThanks,
    setisdonewitness,
}) {
    const dispatch = useDispatch()
    const [errMSG, setErrMSG] = useState()
    const { auth, notarization } = useSelector((state) => state)
    const { getAllJournal, allAdminStates } = notarization

    const [popupDetail, setPopupDetail] = useState()
    const [submit, setSubmit] = useState(true)

    useEffect(() => {
        const data = {
            title: 'Final Payment',
            currency: 'usd',
            journalId: null,
            sealCount: 0,
            state: null,
            county: null,
            signatureType: 'Electronic',
            docs: [],
            signers: [],
            fee: null,
        }

        if (getAllJournal?.journal) {
            const journal = getAllJournal.journal
            data.journalId = journal.id
            data.docs = journal.journalDocs.map((document) => {
                return {
                    id: document.id,
                    docName: document.File?.filename
                        ? document.File.filename
                        : null,
                    docType: document.File?.type ? document.File.type : null,
                    sealCount: null,
                }
            })

            data.witnesses = journal?.Witnesses.map((witness) => {
                return {
                    id: witness.id,
                    name: `${witness?.User?.firstName &&
                        witness.User.firstName} ${witness?.User?.lastName &&
                        witness.User.lastName} `,
                    location: null,
                }
            })
            data.signers = journal.Signers.map((signer) => {
                return {
                    id: signer.id,
                    name: `${signer.firstName &&
                        signer.firstName} ${signer.lastName &&
                        signer.lastName} `,
                    location: null,
                }
            })
            setPopupDetail(data)
        }
    }, [getAllJournal])

    const onSubmit = async () => {

        setSubmit(false);
        
        let errorMessage = checkValidation(popupDetail)

        if (errorMessage) {
            setNotification({ show: true, msg: errorMessage, variant: 'error' })
            setSubmit(true)
            return false
        }
        let response = await dispatch(
            actions.finalizePayment(popupDetail, headers, setErrMSG)
        )

        if (response) {
            if (response.data.status) {
                setpaymentThanks(true)
            } else {
                setisdonewitness(false)
            }
            setSubmit(true)
        }
    }

    const headers = {
        Authorization: `Bearer ${auth.authToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
    }

    useEffect(() => {
        dispatch(
            actions.fetchNotaryStates(headers, [], {
                pageNumber: 1,
                pageSize: 52,
                sortBy: "asc",
                sortField: "state",
            })
        )
    }, [])

    const handleChange = (event, field, index) => {
        let data = popupDetail
        const value = event.target.value

        if (['state', 'signatureType'].includes(field) && value) {
            data[field] = value
        }
        if (['docName', 'notaryType', 'seal'].includes(field)) {
            data.docs[index][field] = value
        }

        /*
         To calculate Total Charged by Cyberize It (sealCount)
         */
        if (field === 'seal') {
            let seal = data.docs.reduce(
                (previousValue, currentObj) =>
                    previousValue + Number(currentObj.seal),
                0
            )
            sealCount(seal)
            data.sealCount = seal
        }
        if (field === 'WitnessLocation') {
            data.witnesses[index].location = value
        }
        if (field === 'location') {
            data.signers[index][field] = value
        }
        setPopupDetail((item) => ({
            ...item,
            ...data,
        }))
    }

    /* 
    To add or remove Document detail from the list
    */
    const addOrRemoveDocument = (index, type) => {
        let data = popupDetail
        if (type === 'add') {
            data.docs.splice(data.docs.length, 0, {
                docName: null,
                notaryType: null,
                seal: null,
            })
        }
        if (type === 'remove') {
            data.docs.splice(index, 1)
        }
        setPopupDetail((item) => ({
            ...item,
            ...data,
        }))
    }
    return (
        <Modal
            size='lg'
            aria-labelledby='example-modal-sizes-title-lg'
            show={show}
            // onHide={hide}
            className='modal-size'
            dialogClassName='my-modal my-modalagreement'>
            <Modal.Header>
                <Modal.Title>Complete this Notarization</Modal.Title>
            </Modal.Header>
            <Modal.Body className='show-grid'>
                <Container>
                    <Row className='mainRow'>
                        <Col xs={2} md={2}>
                            <strong>Notary Venue: </strong>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}>
                        <Col sm={4}>
                            <select
                                onChange={(e) => handleChange(e, 'state')}
                                className='form-control'>
                                <option
                                    value={
                                        popupDetail?.state
                                            ? {
                                                  label: popupDetail.state,
                                                  value: popupDetail.state,
                                              }
                                            : ''
                                    }>
                                    {popupDetail?.state
                                        ? popupDetail.state
                                        : 'Select State'}
                                </option>
                                {allAdminStates?.rows.map((item) => (
                                    <option value={item.state}>
                                        {item.state}
                                    </option>
                                ))}
                            </select>
                        </Col>
                        <Col sm={4}>
                            <TextField
                                required
                                label='County'
                                value={popupDetail?.county}
                                onChange={(event) =>
                                    setPopupDetail({
                                        ...popupDetail,
                                        county: event.target.value,
                                    })
                                }
                                margin='dense'
                                variant='outlined'
                                inputProps={{ style: { height: '18px' } }}
                                color='secondary'
                            />
                        </Col>
                        <Col sm={4}></Col>
                    </Row>
                    <br />
                    <Row>
                        <p className='pb-0 mb-0'>
                            <span className='p-2'>
                                <strong>Type of Signature Applied: </strong>
                            </span>
                            <span className='p-2'>
                                <input
                                    type='radio'
                                    id='Electronic'
                                    checked={
                                        popupDetail?.signatureType ===
                                        'Electronic'
                                    }
                                    onChange={(e) =>
                                        handleChange(e, 'signatureType')
                                    }
                                    value='Electronic'
                                    className='mr-1'
                                />
                                Electronic
                            </span>
                            <span className='p-2'>
                                <input
                                    type='radio'
                                    id='Ink'
                                    checked={
                                        popupDetail?.signatureType === 'Ink'
                                    }
                                    onChange={(e) =>
                                        handleChange(e, 'signatureType')
                                    }
                                    value='Ink'
                                    className='mr-1'
                                />
                                Ink
                            </span>
                        </p>
                    </Row>
                    <br />
                    <Row>
                        <strong className='p-2'>
                            Type of Notary Acts Performed:
                        </strong>
                        <br />
                    </Row>
                    {popupDetail?.docs.map((document, index) => {
                        return (
                            <Row
                                className='mainRow'
                                key={index}
                                style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}>
                                <Col sm={3}>
                                    <TextField
                                        required
                                        label='Document Name'
                                        color='secondary'
                                        margin='dense'
                                        variant='outlined'
                                        inputProps={{
                                            style: { height: '18px' },
                                        }}
                                        disabled={document.id}
                                        value={
                                            document.docName
                                                ? document.docName
                                                : null
                                        }
                                        onChange={(e) =>
                                            handleChange(e, 'docName', index)
                                        }
                                    />
                                </Col>
                                <Col sm={4}>
                                    <select
                                        onChange={(e) =>
                                            handleChange(e, 'notaryType', index)
                                        }
                                        className='form-control'>
                                        <option
                                            value={
                                                document?.notaryType
                                                    ? document.notaryType
                                                    : ''
                                            }>
                                            {document?.notaryType
                                                ? document.notaryType
                                                : 'Select Type of Notary Act'}
                                        </option>
                                        {TypeOfNotarization.map((type) => (
                                            <option value={type}>{type}</option>
                                        ))}
                                    </select>
                                </Col>

                                <Col sm={2}>
                                    <div>
                                        <TextField
                                            className='form-control'
                                            label='Qty'
                                            type='number'
                                            color='secondary'
                                            margin='dense'
                                            variant='outlined'
                                            inputProps={{
                                                style: { height: '18px' },
                                                min: 0
                                            }}
                                            value={document.seal}
                                            onChange={(e) =>
                                                handleChange(e, 'seal', index)
                                            }
                                        />
                                    </div>
                                </Col>
                                <Col sm={3}>
                                    <Button
                                        variant='contained'
                                        style={{
                                            backgroundColor: '#900',
                                            color: 'white',
                                            marginLeft: '10px',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                        onClick={() =>
                                            addOrRemoveDocument(index, 'add')
                                        }>
                                        Add
                                    </Button>
                                    <Button
                                        variant='contained'
                                        disabled={document.id}
                                        style={{
                                            backgroundColor: '#900',
                                            color: 'white',
                                            marginLeft: '10px',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                        onClick={() =>
                                            addOrRemoveDocument(index, 'remove')
                                        }>
                                        remove
                                    </Button>
                                </Col>
                            </Row>
                        )
                    })}
                    <Row className='mainRow'>
                        <Col xs={4} md={4}>
                            <b>Total Charged by Cyberize It</b>
                        </Col>
                        <Col xs={4} md={4}>
                            <b>Total Amount Charged to Client</b>
                        </Col>
                    </Row>
                    <Row className='mainRow'>
                        <Col
                            xs={4}
                            md={4}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                            <b>
                                <span>${seal}</span>
                            </b>
                        </Col>
                        <Col xs={4} md={4}>
                            <TextField
                                required
                                label='Total Amount'
                                type="number"
                                value={popupDetail?.fee ? popupDetail.fee : ''}
                                onChange={(event) =>
                                    setPopupDetail({
                                        ...popupDetail,
                                        fee: event.target.value,
                                    })
                                }
                                margin='dense'
                                variant='outlined'
                                inputProps={{ style: { height: '18px' } }}
                                color='secondary'
                            />
                        </Col>
                    </Row>
                    <Row className='mainRow'>
                        <Col xs={4} md={4}>
                            <b>Signers were physically located:</b>
                        </Col>
                    </Row>
                    {popupDetail?.signers?.map((detail, index) => {
                        return (
                            <Row className='mainRow' key={index}>
                                <Col
                                    xs={4}
                                    md={4}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}>
                                    <b>{detail?.name}</b>
                                </Col>
                                <Col xs={4} md={4}>
                                    <TextField
                                        required
                                        label='Location'
                                        value={
                                            detail?.location
                                                ? detail.location
                                                : ''
                                        }
                                        onChange={(e) =>
                                            handleChange(e, 'location', index)
                                        }
                                        autoComplete='new-password'
                                        margin='dense'
                                        variant='outlined'
                                        inputProps={{
                                            style: { height: '18px' },
                                        }}
                                        color='secondary'
                                    />
                                </Col>
                            </Row>
                        )
                    })}

                    {popupDetail?.witnesses?.map((witness, index) => {
                        return (
                            <Row className='mainRow' key={index}>
                                <Col
                                    xs={4}
                                    md={4}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}>
                                    <b>{witness?.name}</b>
                                </Col>
                                <Col xs={4} md={4}>
                                    <TextField
                                        required
                                        label='Location'
                                        value={
                                            witness?.location
                                                ? witness.location
                                                : ''
                                        }
                                        onChange={(e) =>
                                            handleChange(
                                                e,
                                                'WitnessLocation',
                                                index
                                            )
                                        }
                                        autoComplete='new-password'
                                        margin='dense'
                                        variant='outlined'
                                        inputProps={{
                                            style: { height: '18px' },
                                        }}
                                        color='secondary'
                                    />
                                </Col>
                            </Row>
                        )
                    })}
                    <Row className='mainRow'>
                        <Col xs={12} md={12}>
                            <div className='confirm_and_chargepay'>
                                <Button
                                    variant='contained'
                                    disabled={!submit}
                                    style={{
                                        backgroundColor: '#4abd54',
                                        color: 'white',
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                    }}
                                    onClick={onSubmit}>
                                    {submit ? (
                                        <>Submit</>
                                    ) : (
                                        <CircularProgress
                                            classes={'colorPrimary'}
                                            size={15}
                                        />
                                    )}
                                </Button>

                                <Button
                                    variant='contained'
                                    style={{
                                        backgroundColor: '#900',
                                        color: 'white',
                                        marginLeft: '10px',
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                    }}
                                    onClick={() => window.location.reload()}>
                                    Close
                                </Button>
                            </div>
                        </Col>
                        <div className='text-muted font-weight-bold'>
                            Only hit this Submit one time, it will take a moment
                            to process. If you click it multiple times it will
                            result in multiple charges applied to the clients
                            credit card. If the screen does not show
                            confirmation after 60 seconds, the client's card is
                            not working. Advise them to update their payment
                            method and try again.
                        </div>
                    </Row>
                </Container>
            </Modal.Body>
            {notification.show && (
                <ShowNotification
                    show={notification.show}
                    msg={notification.msg}
                    variant={notification.variant}
                    close={handleCloseNotification}
                />
            )}
        </Modal>
    )
}

/* 
options of notarization drop down
 */
const TypeOfNotarization = [
    'Acknowledgement',
    'Jurat',
    'Oath or Affirmation',
    'Attest Copy',
    'Attesting signature',
    'Representative acknowledgement',
    'Verification',
    'Signature witnessing',
    'Copy certification',
    'Verification of fact',
    'Deposition',
    'Certified copy of national record',
    'Multiple Seals - Acknowledgements & Jurats',
]
/* 
Function that validate All field
and return error message
*/
const checkValidation = (data) => {
    if (!data.state) {
        return 'State field is required.'
    }
    if (!data.county) {
        return 'County field is required.'
    }
    if (!/^[a-zA-Z ]*$/g.test(data.county)) {
        return 'Please Provide Correct County Name'
    }

    if (!data.signatureType) {
        return 'Type of Signature Field is required.'
    }

    for (let index = 0; index < data.docs.length; index++) {
        const document = data.docs[index]

        if (!document.docName || document.docName === '') {
            return 'Document Name Field is Required'
        }
        if (!document.notaryType || document.notaryType === '') {
            return 'Document Type Field is Required'
        }
        if (!document.seal || document.seal === '') {
            return 'Seal Field is Required'
        }

        if ( document.seal < 0) {
            return 'Seal should be greater than zero'
        }
    }
    if (!data.fee) {
        return 'Total Amount Field is Required'
    }
    for (let index = 0; index < data.signers.length; index++) {
        const detail = data.signers[index]
        if (!detail.location) {
            return 'Location of Signer Field is Required'
        }
    }
    for (let index = 0; index < data.witnesses.length; index++) {
        const detail = data.witnesses[index]
        if (!detail.location) {
            return 'Location of Witness Field is Required'
        }
    }
}
