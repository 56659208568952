import React from "react";
import {
  Card,
  CardBody,
  CardHeader
} from "../../../_metronic/_partials/controls";
import { CustomTable } from "./table/CustomTable";
import { Button, Modal, Table, Form } from "react-bootstrap";

export function TableCard({setSearch, handleKeypress, search, applyFilterHandler}) {
  const addNotary = () => {
    window.location.href = "#/chooseMyNotaries";
  };

  return (
    <Card>
      <CardHeader title="My Notaries" className="py-6">
        <div className="d-flex" style={{height: "100px", alignItems: "center"}}>
          <Form.Control
            type="text"
            className="mr-5"
            placeholder="Search notary by name"
            value={search}
            onKeyPress={handleKeypress}
            onChange={e => setSearch(e.target.value)}
          />
          <Button
            className="mr-5 btn btn-primary"
            onClick={e => applyFilterHandler("search")}
          >
            Search
          </Button>
          <Button
            style={{
              backgroundColor: "transparent",
              borderColor: "#1840d2",
              color: "#000",
              float: "right",
              width: "200px"
            }}
            onClick={() => addNotary()}
          >
            Add Notary
          </Button>
        </div>
      </CardHeader>
      <CardBody>
        <CustomTable />
      </CardBody>
    </Card>
  );
}
