import React from "react";
import { Modal } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
export function SuccessModal({ heading, bodyText, show, hide }) {
  return (
    <Modal
      show={show}
      onHide={hide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="justify-content-center pb-2" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ textAlign: "center" }}
        className="pl-2 pr-2 pt-3 pb3"
      >
        <img
          src={toAbsoluteUrl("/media/own/success.png")}
          alt="success"
          style={{ width: "80px" }}
          className="pb-3"
        />
        <p>{bodyText}</p>
      </Modal.Body>
    </Modal>
  );
}
