import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toAbsoluteUrl } from '../../../_metronic/_helpers'
import { Button } from 'react-bootstrap'
import TextField from '@material-ui/core/TextField'

export function SelectNotaryModal({
    show,
    heading,
    bodyText,
    btnTxt,
    onHide,
    MyNotaries,
    btnHandler,
    selectedApprovedNotary,
    setSelectedApprovedNotary
}) {
    return (
        <Modal
            show={show}
            onHide={() => onHide(false)}
            aria-labelledby='contained-modal-title-vcenter'
            centered>
            <Modal.Header className='justify-content-center pb-2'>
                <Modal.Title id='contained-modal-title-vcenter'>
                    {heading}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body
                style={{ textAlign: 'center' }}
                className='pl-2 pr-2 pt-3 pb3'>
                <p>{bodyText}</p>
                <div className='form-group row'>
                    <label className='col-xl-3 col-lg-3 col-form-label'>
                        Notary
                    </label>
                    <div className='col-lg-9 col-xl-6'>
                        <select
                            placeholder='Select Notary'
                            className='form-control form-control-lg form-control-solid owais'
                            name='notary'
                            // value={""}
                            onChange={ (event) => setSelectedApprovedNotary(event.target.value)}
                        >
                            <option value='' selected>
                                Select Notary
                            </option>
                            {
                              MyNotaries && MyNotaries.rows.map((el) => (
                                <option value={el.id}>{el.client.firstName + " " + el.client.lastName}</option>
                              ))
                            }
                        </select>
                    </div>
                </div>
                <Button
                    style={{
                        backgroundColor: '#1840d2',
                        borderColor: '#1840d2',
                        minWidth: '25%',
                        marginTop: '20px',
                    }}
                    onClick={() => btnHandler(true)}
                >
                    {btnTxt}
                </Button>
            </Modal.Body>
        </Modal>
    )
}
