import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { customersSlice } from "../app/modules/ECommerce/_redux/customers/customersSlice";
import { notarizationSlice } from "../app/components/_redux/notarization_redux/notarizationSlice";
import { socketSlice } from "../app/socket/_redux/socket_redux/socketSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  notarization: notarizationSlice.reducer,
  socket: socketSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
