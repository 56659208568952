import React, { useEffect } from "react";
import { TableUIProvider } from "../components/transaction_history_listing/TableUIContext";
import { TableCard } from "../components/transaction_history_listing/TableCard";
import { GenericZeroState } from "../components/zero_state_pages/GenericZeroState";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../components/_redux/notarization_redux/notarizationActions";

export function TransactionHistory() {
  const { auth, notarization } = useSelector(state => state);
  const { transactionHistory } = notarization;

  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      actions.fetchMyPayments(headers, {
        sortOrder: "desc",
        sortField: "id",
        pageNumber: 1,
        pageSize: 10
      })
    );
  }, []);

  return (
    <>
      {transactionHistory !== null && transactionHistory.count > 0 ? (
        <TableUIProvider>
          <TableCard />
        </TableUIProvider>
      ) : (
        <GenericZeroState
          status={transactionHistory}
          imageURL="/media/own/no-witness.png"
          heading=""
          body="Once you perform a notarization you will see the journal entries here."
          btnTxt="false"
        />
      )}
    </>
  );
}
