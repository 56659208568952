import React from "react";
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Grid from "@material-ui/core/Grid";
import SuccessImage from "../media/check@2x.webp";
import CrossImage from "../media/Close icon@2x.png";
export function SuccessPopup() {
  const [showModal, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button variant="secondary" onClick={handleShow}>
        Accept
      </Button>
      <Modal show={showModal} onHide={handleClose} dialogClassName="my-modal">
        <Modal.Header className="success-text" closeButton>
          <Modal.Title>Success</Modal.Title>
          <img
            src={CrossImage}
            className="crossImage"
            alt=""
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          <Grid container spacing={1}>
            <Grid item xs>
              <div>
                <div className="SuccessImage">
                  <img src={SuccessImage} className="SuccessImagetext" alt="" />
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs>
              <div>
                <div className="textlongtext">
                  The payment has been charged and a receipt has been send to
                  client. The client can now view the signed doc. in their
                  journals.
                </div>
              </div>
            </Grid>
          </Grid>
        </Modal.Body>
      </Modal>
    </>
  );
}
