// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from "react";
import {
  CustomerStatusCssClasses,
  AdminSelectType
} from "../../TableUIHelpers";

export function NotaryDateColumnFormatterAdmin(cellContent, row) {
  const getLabelCssClasses = () => {
    return `label label-lg label-light-${
      CustomerStatusCssClasses[row.status]
    } label-inline`;
  };

  const prepareTime = dt => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    let date = new Date(dt);
    let time = formatAMPM(date);
    let mon = months[date.getMonth()];
    let curDate = date.getDate();
    let year = date.getFullYear();
    let timezone = seconds_with_leading_zeros(date);
    return `${mon} ${curDate}, ${year} at ${time} (${timezone})`;
  };

  const formatAMPM = date => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  const seconds_with_leading_zeros = dt => {
    return /\((.*)\)/.exec(dt.toString())[1];
  };

  let date = new Date(row.createdAt);

  return <span>{row.scheduledAt ? prepareTime(row.scheduledAt) : "-"}</span>;
}
