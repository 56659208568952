/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

export function PaymentTable({ card, setChangeMethod, disabled }) {
  return (
    <div className={`card card-custom`}>
      <div className="card-header border-0 py-5">
        <h2 className="card-title align-items-start flex-column">
          Payment Info
        </h2>
      </div>

      <div className="card-body pt-0 pb-3">
        <div className="tab-content">
          <div className="table-responsive">
            <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
              <tbody>
                <tr>
                  <td className="pl-0 py-0">
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-50 symbol-light mr-4">
                        <span className="svg-icon h-75 align-self-end">
                          <img
                            src={toAbsoluteUrl("/media/own/card-icon.png")}
                            alt="Credit Card Image"
                            style={{ width: "75px" }}
                          />
                        </span>
                      </div>
                      <div>
                        <span className="text-muted font-weight-bold d-block">
                          Card Number
                        </span>
                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                          xxxx-xxxx-xxxx-{card.lastFour}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td style={{ maxWidth: "100% !important", width: "50%" }}>
                    <span className="text-muted font-weight-bold">
                      Card Holder Name
                    </span>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      {card.name}
                    </span>
                  </td>
                  <td>
                    <span className="text-muted font-weight-bold">
                      Expiry Date
                    </span>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      {card.expiry}
                    </span>
                  </td>
                  <td>
                    <span className="text-muted font-weight-bold">CVV</span>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      ***
                    </span>
                  </td>
                  <td className="pr-0 text-right">
                    <button
                      className="btn btn-primary font-weight-bolder font-size-sm"
                      onClick={() => setChangeMethod(true)}
                      disabled={disabled}
                    >
                      Change Method
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
