import React, { useEffect, useState } from 'react'
import { TableUIProvider } from '../components/jobboard_listing/TableUIContext'
import { TableCard } from '../components/jobboard_listing/TableCard'
import { GenericZeroState } from '../components/zero_state_pages/GenericZeroState'
import ShowNotification from '../components/new_notarization/ShowNotification'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Modal, Table } from 'react-bootstrap'
import * as actions from '../components/_redux/notarization_redux/notarizationActions'
import { CustomerEditDialog } from '../components/jobboard_listing/edit-dialog/CustomerEditDialog'
import { RefreshJobBoard } from '../components/jobboard_listing/RefreshJobBoard'

export function Myjobboard() {
    const { auth, notarization } = useSelector((state) => state)
    const { allJobs } = notarization
    const [isload, setisload] = useState(false)
    const [comefrom, setcomefrom] = useState('')
    const [showDialog, setshowDialog] = useState(false)
    const [claimID, setclaimID] = useState(0)
    const [jobType, setJobType] = useState('notarization')
    const [actionModal, setActionModal] = useState(false)
    const [notarizeNowSuccess, setNotarizeNowSuccess] = useState(false)
    const [notification, setNotification] = useState({
        show: false,
        msg: '',
        variant: '',
    })

    const [selectedRowData, setSelectedRowData] = useState(null)
    const customersUIEvents = {
        openClaimPopup: (journalID, type = 'notarization') => {
            setJobType(type) //setting the job type...

            setclaimID(journalID)
            setshowDialog(true)
            setcomefrom('')
        },

        openEditCustomerDialog: (id) => {
            setisload(true)

            dispatch(actions.getJournalbyID(id, headers)).then((data) => {
                setisload(false)
                //	setSelectedRowData(data.journal);
                setActionModal(true)
            })
            dispatch(actions.getJitsiMeetingLinks(id, headers))
        },
    }
    const handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') return
        setNotification({ show: false, msg: '', variant: '' })
    }

    const claimJob = () => {
        if ('witness' == jobType) {
            dispatch(
                actions.claimWitnessJob({ journalId: claimID }, headers)
            ).then((response) => {
                if (response.data && response.data.status == 'ok') {
                    setNotification({
                        show: true,
                        msg: 'Witness Job Claimed Successfully',
                        variant: 'success',
                    })
                    setTimeout(function() {
                        window.location.reload()
                    }, 3000)
                }
            })
            .catch((error) => {
                setNotification({
                    show: true,
                    msg: 'Unfortunately, this request has already been claimed',
                    variant: 'error',
                })
                setTimeout(function() {
                    window.location.reload()
                }, 3000)
            })
        } else {
            dispatch(
                actions.claimNow(
                    claimID,
                    headers,
                    setNotarizeNowSuccess,
                    setNotification
                )
            ).then((data) => {
                if (data && data.id) {
                    setNotification({
                        show: true,
                        msg: 'Notarization Claimed Successfully',
                        variant: 'success',
                    })
                    setTimeout(function() {
                        window.location.reload()
                    }, 3000)
                }
            }).catch((error) => {
                setNotification({
                    show: true,
                    msg: 'Sorry! You Cannot Claim This Job',
                    variant: 'error',
                })
                setTimeout(function() {
                    window.location.reload()
                }, 3000)
            })
        }
    }

    const openClaimPopup = (journalID, type = 'notarization') => {
        setJobType(type) //setting the job type...

        setclaimID(journalID)
        setActionModal(false)
        setshowDialog(true)
        setcomefrom('journal')
    }

    const hideJob = (journalID) => {
        setclaimID(0)

        if (comefrom) setActionModal(true)
        else setActionModal(false)

        setshowDialog(false)
    }

    const dispatch = useDispatch()

    const headers = {
        Authorization: `Bearer ${auth.authToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
    }

    useEffect(() => {
        dispatch(
            actions.fetchJobPosts(
                {
                    sortOrder: 'desc', // asc||desc
                    sortField: 'id',
                    pageNumber: 1,
                    pageSize: 10,
                },
                headers,
                auth.user.Role.code
            )
        )
    }, [])

    const claimWitnessJob = (journalID) => {
        if (window.confirm('Are you sure you want to Claim this Job?')) {
            dispatch(actions.claimWitnessJob({ journalId: journalID }, headers))
        }
    }

    return (
        <>        
            <RefreshJobBoard />
            {notification.show && (
                <ShowNotification
                    show={notification.show}
                    msg={notification.msg}
                    variant={notification.variant}
                    close={handleCloseNotification}
                />
            )}

            {allJobs !== null && !allJobs.claimJob  ? (
                <div
                    style={{paddingBottom: "25px",paddingTop: "25px",border: "5px solid black",borderRadius: "19px",fontSize: "16px",textAlign: "center",color: "red",fontWeight:"bold"}}
                    
                >
                    We regret to inform you that access to the job board is not available within your subscription level. To explore this feature,
                    we kindly <br/>recommend upgrading your subscription or reaching out to the administrator via email if you believe this restriction is in error. 
                </div>            
            ) : (
                <div>

                </div>
                
            )}

            {allJobs !== null && allJobs.count > 0 ? (
                <TableUIProvider customersUIEvents={customersUIEvents}>
                    <CustomerEditDialog
                        show={actionModal}
                        openClaimPopup={openClaimPopup}
                        claimWitnessJob={claimWitnessJob}
                        onHide={() => setActionModal(false)}
                        data={selectedRowData}
                    />
                    <TableCard />
                </TableUIProvider>
            ) : (
                
                <GenericZeroState
                    status={allJobs}
                    imageURL='/media/own/no-clients.png'
                    heading=''
                    body='Once a client submits a Notarization Request it will appear here.'
                    btnTxt='false'
                />
            )}

            <Modal
                size='lg'
                show={showDialog}
                aria-labelledby='example-modal-sizes-title-lg'>
                <Modal.Body className='overlay overlay-block cursor-default'>
                    <h1>Confirmation</h1>
                    <div className='modal-body-content'>
                        Are you sure you want to claim this assignment?{' '}
                    </div>
                    <div className='modal-body'>
                        <Button
                            variant='secondary'
                            className='acceptBtn'
                            style={{ marginTop: '20px', float: 'right' }}
                            onClick={() => claimJob()}>
                            Confirm
                        </Button>

                        <Button
                            variant='secondary'
                            className='cancelBtn'
                            style={{
                                marginTop: '20px',
                                marginRight: '10px',
                                float: 'right',
                            }}
                            onClick={() => hideJob()}>
                            Cancel
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
