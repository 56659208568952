import axios from "axios";
import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

export function PayoutInfo({ type }) {
  var d = new Date();
  d.setDate(d.getDate() + ((1 + 7 - d.getDay()) % 7));
  let payoutDate = new Date(d).toDateString();
  return <div className="nextPayoutDate">Next Payout Date {payoutDate}</div>;
}
