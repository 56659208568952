import React from "react";
import { Modal } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Button } from "react-bootstrap";
export function GenericModal({
  heading,
  bodyText,
  image,
  btnTxt,
  btnHandler,
  show,
  hide,
  disclaimer = ""
}) {
  return (
    <Modal
      show={show}
      onHide={hide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="justify-content-center pb-2">
        <Modal.Title id="contained-modal-title-vcenter">{heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ textAlign: "center" }}
        className="pl-2 pr-2 pt-3 pb3"
      >
        {image !== null ? (
        <img
          src={toAbsoluteUrl(image)}
          alt="success"
          style={{ width: "55px" }}
          className="mt-5 mb-5"
        />
        ):""}
        <p>{bodyText}</p>
        <p><strong>{disclaimer}</strong></p>
        <Button
          style={{
            backgroundColor: "#1840d2",
            borderColor: "#1840d2",
            minWidth: "25%",
            marginTop: "20px"
          }}
          onClick={btnHandler}
        >
          {btnTxt}
        </Button>
      </Modal.Body>
    </Modal>
  );
}
