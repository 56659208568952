import { Divider, makeStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import Avatar from "@material-ui/core/Avatar";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/notarization_redux/notarizationActions";


const useStyles = makeStyles(theme => ({
  draft: {
    minWidth: "110px",
    backgroundColor: "#524440",
    color: "#fff",
    textAlign: "center",
    borderRadius: "5px",
    padding: "9px 5px"
  },
  progress: {
    minWidth: "110px",
    backgroundColor: "#eee",
    color: "#000",
    textAlign: "center",
    borderRadius: "5px",
    padding: "9px 5px"
  },
  completed: {
    minWidth: "110px",
    backgroundColor: "#bef2de",
    color: "#000",
    textAlign: "center",
    borderRadius: "5px",
    padding: "9px 5px"
  },
  scheduled: {
    minWidth: "110px",
    backgroundColor: "#ff9a00",
    color: "#fff",
    textAlign: "center",
    borderRadius: "5px",
    padding: "9px 5px"
  },
  initiated: {
    minWidth: "110px",
    backgroundColor: "#ffff83",
    color: "#000",
    textAlign: "center",
    borderRadius: "5px",
    padding: "9px 5px"
  },
  pending: {
    minWidth: "110px",
    backgroundColor: "#4ABD54",
    color: "#fff",
    textAlign: "center",
    borderRadius: "5px",
    padding: "9px 5px"
  },
  paid: {
    minWidth: "110px",
    backgroundColor: "#4ABD54",
    color: "#fff",
    textAlign: "center",
    borderRadius: "5px",
    padding: "9px 5px"
  },
  alignCenter: {
    textAlign: "center"
  },
  buttonStyle: {
    backgroundColor: "#1840d2",
    borderColor: "#1840d2",
    minWidth: "165px",
    float: "right",
    marginBottom: "10px"
  },
  avatar_pic: {
    marginRight: "10px",
    float: "left",
    width: theme.spacing(4),
    height: theme.spacing(4)
  }
}));

export function CustomerEditForm({ onHide, data, openSelectPrimaryNotaryScreen, changeNet30Status }) {
  const classes = useStyles();
  
  const [overrideKBA, setoverrideKBA] = useState(true);
  const [getNet30, setGetNet30] = useState(false);

  let primaryNotary = (data?.userProfile && data?.userProfile?.notaryId) ? data?.userProfile?.User?.firstName + " " + data?.userProfile?.User?.lastName : null;

  const dispatch = useDispatch();
  const { auth } = useSelector(state => state);
  
  const headers = {
    'Authorization': `Bearer ${auth.authToken}`,
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }

  useEffect(() => {
    dispatch(actions.getNet30( data.id, headers))
    .then((response) => {
      if( response.data && response?.data?.net30 ) {
        setGetNet30(response.data.net30);
      }
    });

  },[])

  return (
    <>
      <Modal.Body className="overlay overlay-block cursor-default">
        {
          // data !== null && data !== undefined &&
          <>
            <Table responsive borderless={true}>
              <thead>
                <tr>
                  <th>USER&nbsp;NAME</th>
                  <th>EMAIL</th>
                  <th>PHONE</th>
                  <th>CITY</th>
                  <th>STATE</th>
                  <th>ZIP</th>
                  <th>ADDRESS</th>
                  <th>PN</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Avatar
                      className={classes.avatar_pic}
                      variant="rounded"
                      alt="profile pic"
                      src={
                        data.profile_picture !== null
                          ? `${data.profile_picture.baseUrl}/${data.profile_picture.path}`
                          : toAbsoluteUrl("/media/users/blank.png")
                      }
                    >
                      OR
                    </Avatar>
                    {`${data.firstName} ${data.lastName}`}
                  </td>
                  <td>{(data.email) ? data.email : "-"}</td>
                  <td>{(data.phone) ? data.phone : "-"}</td>
                  <td>{(data.city) ? data.city : "-"}</td>
                  <td>{(data.state) ? data.state : "-"}</td>
                  <td>{(data.zip) ? data.zip : "-"}</td>
                  <td>{(data.address) ? data.address : "-"}</td>
                  <td>{(primaryNotary) ? primaryNotary : "-"}</td>
                </tr>
              </tbody>
            </Table>
            { (data.roleId !== 4) &&
              <Button
                className={`mr-0 ml-0 pl-0 pr-0 ${classes.buttonStyle}`}
                onClick={() => openSelectPrimaryNotaryScreen(data.id)}
              >
                {primaryNotary ? "Change PN" : "Assign PN"}
              </Button>
            }
            {
              (data.roleId === 4 ) && (
                <>
                {
                  getNet30 && !getNet30.active ? (
                    <a
                      title="Make Inactive"
                      className="mr-0 ml-0 btn btn-light btn-active btn-hover-primary m-1"
                      onClick={() => changeNet30Status(data.id, "active")}
                    >
                      Activate Net30
                    </a>
                  ) : (
                    <a
                      title="Make Active"
                      className={"mr-0 ml-0 btn btn-light btn-inactive btn-hover-warning m-1"}
                      onClick={() => changeNet30Status(data.id, "inactive")}
                    >
                      Deactivate Net30
                    </a>
                  )
                }
                </>
              )
            }
          </>
        }
      </Modal.Body>
    </>
  );
}
