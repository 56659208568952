import React, { useState, useEffect } from 'react'
import NewNotarizationNotaryRoot from '../components/new_notarization/NewNotarizationNotaryRoot'
import ShowNotification from '../components/new_notarization/ShowNotification'
import { useDispatch, useSelector } from 'react-redux'
import PaymentCardListing from '../components/new_notarization/PaymentCardListing'
import * as actions from '../components/_redux/notarization_redux/notarizationActions'
import validator from 'validator'

export function NewNotarizationNotary({
    newNot = false,
    id = null,
    from = null,
    resumeNotarization = null,
}) {
    const [notification, setNotification] = useState({
        show: false,
        msg: '',
        variant: '',
    })
    const [saveLaterSuccess, setSaveLaterSuccess] = useState(false)
    const [isButtonClicked, setisButtonClicked] = useState(false)
    const [scheduleLaterDateTime, setScheduleLaterDateTime] = useState('')
    const [notarizeNowSuccess, setNotarizeNowSuccess] = useState(false)
    const [schedulerModal, setSchedulerModal] = useState(false)
    const [addedSigners, setAddedSigners] = useState(null)
    const [changeMethod, setChangeMethod] = useState(false)
    const [signeralready, setsigneralready] = useState([])
    const [scheduleLaterSuccess, setScheduleLaterSuccess] = useState(false)
    const dispatch = useDispatch()
    const { auth, notarization } = useSelector((state) => state)
    const [typeFile, settypeFile] = useState({})
    const {
        defaultPaymentCard,
        documents,
        allJournals,
        top5Journals,
        allClients,
        paymentDetails,
        payments,
    } = notarization
    const [payNowModal, setPayNowModal] = useState(false)
    const [journalID, setjournalID] = useState(id === null ? 0 : id)
    const [temppaid, settemppaid] = useState(false)
    const resumeData = newNot
        ? (from === 'journal' ? allJournals : top5Journals).rows.filter(
              (data) => data.id === id
          )
        : null
    const [iscontinue, setiscontinue] = useState(
        resumeData && resumeData[0] ? true : false
    )
    const [isalreadypaid, setisalreadypaid] = useState(
        resumeData &&
            resumeData[0] &&
            resumeData[0].id &&
            resumeData[0].Payments.length > 0
            ? true
            : false
    )
    const [iscontinuecompleted, setiscontinuecompleted] = useState(false)
    const [cstmDisable, setCstmDisable] = useState(
        resumeData !== null && resumeData[0].Payments.length > 0
            ? 'disabled'
            : ''
    )
    const [paid, setPaid] = useState(
        resumeData !== null && resumeData[0].Payments.length > 0 ? true : false
    )
    let user = []
    const [disableButton, setDisableButton] = useState(
        resumeData !== null && resumeData[0].documents.length > 0 ? true : false
    )
    const [noOfSigner, setNoOfSigner] = useState({
        value: 1,
        added:
            resumeData !== null && resumeData[0].Signers.length > 0
                ? resumeData[0].Signers.length
                : 1,
        error: false,
    })
    const [noOfWitness, setNoOfWitness] = useState({
        value: resumeData !== null ? resumeData[0].witnessCount : 0,
        error: false,
    })
    if (resumeData !== null && allClients !== null) {
        user = allClients.rows.filter(
            (el) =>
                el.firstName === resumeData[0].customer.firstName &&
                el.lastName === resumeData[0].customer.lastName
        )
    }

    const [selectedUser, setSelectedUser] = useState('')
    let singers = []

    for (let i = 1; i <= 9999; i++) {
        singers.push({
            fname: '',
            middleName: '',
            lname: '',
            suffix: '',
            email: '',
            phone: '',
            checklist: [],
            reason: '',
            notGovtID: false,
            biometric: false,
            nonUS: false,
            byPassKBA: false,
            isEmailValid: false,
            isError: false,
            address: '',
            zip: '',
            city: '',
            state: '',
            ssn: '',
            dob: '',
            temporaryLink: '',
        })
    }

    let feePaid = {fee: 0, docs: 0};
    if (resumeData !== null) {
        if(resumeData[0].Payments.length > 0) {
            if((resumeData[0].Payments[0].amount/100) == 3){
                feePaid = { fee: 3, docs: resumeData[0].documents.length};
            }
        }
        resumeData[0].Signers.map((data, index) => {
            singers[index].fname = data.firstName
            singers[index].lname = data.lastName
            singers[index].middleName = data.middleName
            singers[index].suffix = data.suffix
            singers[index].biometric = data.biometric
            singers[index].nonUS = data.nonUS
            singers[index].email = data.email            
            singers[index].phone = data.phone && data.phone != null ? data.phone : '';
            singers[index].state = data.state
            singers[index].zip = data.zip
            singers[index].city = data.city
            singers[index].address = data.address
            singers[index].byPassKBA =
                data.bypassKBA === null ? false : data.bypassKBA
            singers[index].ssn = data.ssnLast4
            singers[index].dob = data.dob
            singers[index].isEmailValid = validator.isEmail(data.email)
            singers[index].reason =
                data.bypassReason === null ? '' : data.bypassReason
        })
    }

    const [platformFee, setPlatformFee] = useState(feePaid)

    const [singerInfo, setSingerInfo] = useState(singers)

    const headers = {
        Authorization: `Bearer ${auth.authToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
    }

    const handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') return
        setNotification({ show: false, msg: '', variant: '' })
    }

    const seconds_with_leading_zeros = (dt) => {
        return /\((.*)\)/.exec(dt.toString())[1]
    }

    const prepareTime = (dt) => {
        let date = '',
            time = '',
            timezone = ''
        const options = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }
        const dateOptions = { hour: '2-digit', minute: '2-digit' }
        date = new Date(dt).toLocaleDateString(undefined, options)
        time = new Date(dt).toLocaleTimeString('en-US', dateOptions)
        timezone = seconds_with_leading_zeros(new Date(dt))
        timezone = timezone.split(' ')
        let shortTimezone = ''
        timezone.map((el) => {
            shortTimezone += el[0]
        })
        return `${date} at ${time} (${shortTimezone})`
    }

    const saveLaterSuccessHideHandler = () => {
        setSaveLaterSuccess(false)
        window.location.href = '/#/'
    }

    const notarizeNowSuccessHideHandler = () => {
        setNotarizeNowSuccess(false)
        window.location.href = '/#/'
    }
    const schedulerCloseHandler = () => setSchedulerModal(false)
    const scheduleHandler = () => setSchedulerModal(true)
    const fieldsValidator = () => {
        let error = false,
            errorMessage = ''
        let copiedArr = [...singerInfo]
        for (let i = 0; i < noOfSigner.added; i++) {
            copiedArr[i]['isError'] = false
            let checklist = []
            let email = true,
                phone = true,
                personalDocs = true

            if (!/^[a-zA-Z0-9]*$/g.test(singerInfo[i].fname)) {
                error = true
                errorMessage =
                    'Please Provide Correct Signer ' + (i + 1) + ' First Name'
                copiedArr[i]['isError'] = true
            }

            if (!/^[a-zA-Z0-9]*$/g.test(singerInfo[i].lname)) {
                error = true
                errorMessage =
                    'Please Provide Correct  Signer ' + (i + 1) + ' Last Name'
                copiedArr[i]['isError'] = true
            }

            if (!/^[a-zA-Z0-9]*$/g.test(singerInfo[i].middleName)) {
                error = true
                errorMessage =
                    'Please Provide Correct  Signer ' + (i + 1) + ' Midlde Name'
                copiedArr[i]['isError'] = true
            }

            if (singerInfo[i].byPassKBA && singerInfo[i].reason === '') {
                error = true
                errorMessage = 'Please fill out the mandatory fields!'
                copiedArr[i]['isError'] = true
            }

            if (
                !singerInfo[i].byPassKBA &&
                (!email || !phone || !personalDocs)
            ) {
                error = true
                errorMessage = 'Please fill out the mandatory fields!'
                copiedArr[i]['isError'] = true
            }

            if (!singerInfo[i].isEmailValid) {
                error = true
                errorMessage = 'Please enter valid email!'
                copiedArr[i]['isError'] = true
            }

            if (
                singerInfo[i].fname === '' ||
                singerInfo[i].lname === '' ||
                singerInfo[i].email === '' ||
                (singerInfo[i].phone === '' && auth.user.Role.code != "notary") ||
                singerInfo[i].zip === '' ||
                singerInfo[i].city === '' ||
                singerInfo[i].state === '' ||
                singerInfo[i].address === '' ||
                (!singerInfo[i].biometric &&
                    (singerInfo[i].dob === null || singerInfo[i].dob === ''))
            ) {
                error = true
                errorMessage = 'Please fill out the mandatory fields!'
                copiedArr[i]['isError'] = true
            }
        }
        setSingerInfo(copiedArr)
        return { error, errorMessage }
    }

    const cstm_validator = (action) => {
        let error = false,
            errorMessage = ''

        for (let i = 0; i < noOfSigner.added; i++) {
            if (singerInfo[i].byPassKBA && singerInfo[i].reason === '') {
                error = true
                errorMessage = 'Please fill out the mandatory fields!'
            }
        }

        if (action === 'send') {
            let fieldError = fieldsValidator()
            error = fieldError.error
            errorMessage = fieldError.errorMessage
        }

        if (selectedUser === '') {
            error = true
            errorMessage = 'Please select user!'
        }

        return { error, errorMessage }
    }

    const payPlatformFeeHandler = (fee) => {
        dispatch(
            actions.payPlatformFee(
                {
                    userId: auth.user.id,
                    title: 'Platform fee',
                    currency: 'usd',
                    amount: Math.round(fee * 100),
                    cardId: defaultPaymentCard.id,
                }, headers, {
                setPaid,
                setPayNowModal,
                setCstmDisable,
                setNotification,
            })
        ).then((data) => {
            setisalreadypaid(true)
            setDisableButton(true)
            draftHandler('payment', data)
        })
    }

    const payNowHandler = () => {
        let error = false
        let errorMessage = ''
        let copiedArr = [...singerInfo]
        for (let i = 0; i < noOfSigner.added; i++) {
            copiedArr[i]['isError'] = false
            let checklist = []
            let email = true,
                phone = true,
                personalDocs = true

            if (!/^[a-zA-Z0-9]*$/g.test(singerInfo[i].fname)) {
                error = true
                errorMessage =
                    'Please Provide Correct Signer ' + (i + 1) + ' First Name'
                copiedArr[i]['isError'] = true
            }

            if (!/^[a-zA-Z0-9]*$/g.test(singerInfo[i].lname)) {
                error = true
                errorMessage =
                    'Please Provide Correct  Signer ' + (i + 1) + ' Last Name'
                copiedArr[i]['isError'] = true
            }

            if (!/^[a-zA-Z0-9]*$/g.test(singerInfo[i].middleName)) {
                error = true
                errorMessage =
                    'Please Provide Correct  Signer ' + (i + 1) + ' Middle Name'
                copiedArr[i]['isError'] = true
            }

            if (singerInfo[i].byPassKBA && singerInfo[i].reason === '') {
                error = true
                errorMessage = 'Please fill out the mandatory fields!'
                copiedArr[i]['isError'] = true
            }

            if (
                !singerInfo[i].byPassKBA &&
                (!email || !phone || !personalDocs)
            ) {
                error = true
                errorMessage = 'Please fill out the mandatory fields!'
                copiedArr[i]['isError'] = true
            }

            if (!singerInfo[i].isEmailValid) {
                error = true
                errorMessage = 'Please enter valid email!'
                copiedArr[i]['isError'] = true
            }
            if (singerInfo[i].suffix && 5 < singerInfo[i].suffix.length) {
                error = true
                errorMessage = 'Suffix must not exceed 5 letters'
                copiedArr[i]['isError'] = true
            } else if (
                singerInfo[i].suffix &&
                !/^[A-Za-z.]+$/gi.test(singerInfo[i].suffix)
            ) {
                error = true
                errorMessage =
                    'Please Provide Correct Signer ' + (i + 1) + ' Suffix'
                copiedArr[i]['isError'] = true
            }
            if (
                singerInfo[i].fname === '' ||
                singerInfo[i].lname === '' ||
                singerInfo[i].email === '' ||                
                (singerInfo[i].phone === '' && auth.user.Role.code != "notary") ||
                singerInfo[i].address === '' ||
                singerInfo[i].zip === '' ||
                singerInfo[i].city === '' ||
                singerInfo[i].state === '' ||
                singerInfo[i].fname === null ||
                singerInfo[i].lname === null ||
                singerInfo[i].email === null ||
                singerInfo[i].address === null ||
                singerInfo[i].zip === null ||
                singerInfo[i].city === null ||
                singerInfo[i].state === null ||
                (!singerInfo[i].biometric &&
                    (singerInfo[i].dob === null || singerInfo[i].dob === ''))
            ) {
                error = true
                errorMessage = 'Please fill out the mandatory fields!'
                copiedArr[i]['isError'] = true
            }
        }
        setSingerInfo(copiedArr)

        if (documents.length < 1) {
            error = true
            errorMessage = 'Please upload minimum 1 document!'
        }

        if (defaultPaymentCard === null) {
            error = true
            errorMessage = 'Please add payment card!'
        }

        if (!error) {
            let fee = 3;
            setPlatformFee({ fee: fee, docs: documents.length })
            setPayNowModal(true)
        } else {
            setNotification({ show: true, msg: errorMessage, variant: 'error' })
        }
    }

    const handleChangeFileType = (event) => {
        let ttfile = typeFile
        if (!ttfile[event.target.name]) ttfile[event.target.name] = ''

        ttfile[event.target.name] = event.target.value

        settypeFile(ttfile)
    }

    const appendFileTypeinFiles = () => {
        let documentoverride = []
        documents.map((data, i) => {
            documentoverride[i] = {}
            documentoverride[i].baseUrl = documents[i].baseUrl
            documentoverride[i].createdAt = documents[i].createdAt
            documentoverride[i].filename = documents[i].filename
            documentoverride[i].id = documents[i].id
            documentoverride[i].path = documents[i].path
            documentoverride[i].type = documents[i].type
            documentoverride[i].updatedAt = documents[i].updatedAt

            if (
                resumeData &&
                resumeData[0] &&
                resumeData[0].journalDocs &&
                checkFileexist(documents[i].id)
            )
                documentoverride[i].docType = checkFileexist(documents[i].id)
            else
                documentoverride[i].docType = typeFile['file' + documents[i].id]
        })
        return documentoverride
    }

    const checkFileexist = (fileID) => {
        let fileDetail = resumeData[0].journalDocs.filter(function(fileObj) {
            return fileObj.fileId == fileID
        })
        if (!fileDetail[0]) return false
        else return fileDetail[0].docType
    }

    const draftHandler = (
        status = null,
        draftPaymentID = null,
        iscont = false
    ) => {
        if (!iscont && status !== 'payment') {
            setisButtonClicked(true)
        }
        let documentoverride = []
        let { error, errorMessage } = cstm_validator('draft')
        let fieldError = fieldsValidator()
        error = fieldError.error
        errorMessage = fieldError.errorMessage

        if (documents.length < 1) {
            error = true
            errorMessage = 'Please upload minimum 1 document!'
        } else {
            documentoverride = appendFileTypeinFiles()
        }

        if (!error) {
            let signers = []
            if (signeralready.length < 1) {
                let signersArray = singerInfo.slice(0, noOfSigner.added)

                signersArray.map((data, i) => {
                    let checklist = []
                    //    data.checklist.map(data => checklist.push(data.value));
                    let signer = {
                        firstName: data.fname,
                        lastName: data.lname,
                        middleName: data.middleName,
                        suffix: data.suffix,
                        biometric: data.biometric,
                        nonUS: data.nonUS,
                        email: data.email,
                        phone: data.phone && data.phone != null ? data.phone : '',
                        zip: data.zip,
                        state: data.state,
                        city: data.city,
                        address: data.address,
                        /*     "emailExists": checklist.includes('email'),
                    "passportExists": checklist.includes('passport'),
                    "drivingLicenseExists": checklist.includes('license'),
                    "govtIdExists": checklist.includes('govtID'),
                    "smartphoneExists": checklist.includes('smartphone'),*/
                        bypassKBA: data.byPassKBA,
                        bypassReason: data.reason,
                        ssnLast4: data.ssn
                            ? data.ssn.substring(data.ssn.length - 4)
                            : '',
                        dob: data.dob,
                    }
                    if (resumeData !== null) {
                        let resumeSigners = resumeData[0].Signers.length - 1
                        if (i <= resumeSigners) {
                            signer.id = resumeData[0].Signers[i].id
                        }
                    }
                    signers.push(signer)
                })
            } else {
                signers = signeralready
            }
            let body = {
                userId: selectedUser.id,
                notaryId: auth.user.id,
                initiatedByNotary: true,
                action: 'draft',
                willDoc: false,
                electionDoc: false,
                paymentId: payments === null ? '' : payments.id,
                files: documentoverride,
                Signers: signers,
                type: '',
                scheduledAt: null,
                witnessCount: noOfWitness.value,
            }

            if (signeralready.length > 0) body.Signers = signeralready

            if (resumeData !== null) {
                body.journalId = resumeData[0].id
            }

            if (journalID) {
                body.journalId = journalID
            }

            if (status === 'payment') {
                body.paymentId = draftPaymentID
            }

            dispatch(
                actions.addNewNotarization(
                    body,
                    headers,
                    !iscont ? setSaveLaterSuccess : setiscontinue,
                    setNotification,
                    !iscont ? status : 'payment'
                )
            ).then((data) => {
                setjournalID(data.id)
                if (iscont) {
                    if (data && data.journal && data.journal.Signers)
                        setsigneralready(data.journal.Signers)

                    setiscontinue(true)
                }
                if (status === 'payment' && resumeData !== null) {
                    if (from === 'journal') {
                        dispatch(
                            actions.fetchJournals(
                                {
                                    sortOrder: 'desc', // asc||desc
                                    sortField: 'id',
                                    pageNumber: 1,
                                    pageSize: 10,
                                },
                                headers,
                                auth.user.Role.code
                            )
                        ).then((data) => {
                            setiscontinuecompleted(true)
                            if (resumeData !== null) {
                                resumeNotarization(resumeData[0].id)
                            }
                        })
                    } else {
                        dispatch(
                            actions.fetchTop5Journals(
                                headers,
                                auth.user.Role.code
                            )
                        ).then((data) => {
                            /*	if(iscont)
							resumeData = (from === 'journal' ? allJournals : top5Journals).rows.filter(data => data.id === id);
							*/
                            setiscontinuecompleted(true)
                            if (resumeData !== null) {
                                resumeNotarization(resumeData[0].id)
                            }
                        })
                    }
                }
            })
        } else
            setNotification({ show: true, msg: errorMessage, variant: 'error' })
    }
    const schedulerNotarizationHandler = (dateTime) => {
        setisButtonClicked(true)

        if(platformFee.fee) {
            dispatch(
                actions.payPlatformFee(
                    {
                        userId: auth.user.id,
                        title: 'Platform fee',
                        currency: 'usd',
                        amount: Math.round(platformFee.fee * 100),
                        cardId: defaultPaymentCard.id,
                    }, headers, {
                    setPaid,
                    setPayNowModal,
                    setCstmDisable,
                    setNotification,
                })
            ).then((data) => {
                setisalreadypaid(true)
                setDisableButton(true)
                setScheduleLaterDateTime(prepareTime(dateTime))
                let documentoverride = []
                if (documents.length < 1) {
                } else {
                    documentoverride = appendFileTypeinFiles()
                }
                let signersArray = singerInfo.slice(0, noOfSigner.added)
                let signers = []

                if (signeralready.length < 1) {
                    signersArray.map((data, i) => {
                        let checklist = []
                        //data.checklist.map(data => checklist.push(data.value));

                        let signer = {
                            firstName: data.fname,
                            lastName: data.lname,
                            middleName: data.middleName,
                            suffix: data.suffix,
                            biometric: data.biometric,
                            nonUS: data.nonUS,
                            email: data.email,
                            phone: data.phone && data.phone != null ? data.phone : '',
                            /*      "emailExists": checklist.includes('email'),
                        "passportExists": checklist.includes('passport'),
                        "drivingLicenseExists": checklist.includes('license'),
                        "govtIdExists": checklist.includes('govtID'),
                        "smartphoneExists": checklist.includes('smartphone'),*/
                            address: data.address,
                            zip: data.zip,
                            city: data.city,
                            state: data.state,
                            bypassKBA: data.byPassKBA,
                            bypassReason: data.reason,
                            ssnLast4: data.ssn.substring(data.ssn.length - 4),
                            dob: data.dob,
                        }
                        if (resumeData !== null) {
                            let resumeSigners = resumeData[0].Signers.length - 1
                            if (i <= resumeSigners)
                                signer.id = resumeData[0].Signers[i].id
                        }

                        if (addedSigners !== null) {
                            let resumeSigners = addedSigners.length - 1
                            if (i <= resumeSigners) signer.id = addedSigners[i].id
                        }
                        signers.push(signer)
                    })

                    setsigneralready(signers)
                } else {
                    let signers = []
                    signers = signeralready
                }

                let body = {
                    userId: selectedUser.id,
                    notaryId: auth.user.id,
                    initiatedByNotary: true,
                    action: 'scheduled',
                    willDoc: false,
                    electionDoc: false,
                    paymentId: data === null ? '' : data,
                    files: documentoverride,
                    Signers: signers,
                    type: '',
                    scheduledAt: new Date(dateTime).toISOString(),
                    witnessCount: noOfWitness.value,
                }

                if (signeralready.length > 0) body.Signers = signeralready

                if (resumeData !== null) {
                    if (
                        resumeData[0].Payments &&
                        resumeData[0].Payments[0] &&
                        resumeData[0].Payments[0].status == 'succeeded'
                    ) {
                        body.paymentId = resumeData[0].Payments[0].id
                    }
                }

                if (journalID) {
                    body.journalId = journalID
                }

                if (resumeData !== null) {
                    body.journalId = resumeData[0].id
                }

                dispatch(
                    actions.addNewNotarization(
                        body,
                        headers,
                        setScheduleLaterSuccess,
                        setNotification
                    )
                )

                setNotification({
                    show: true,
                    msg: 'Schedule Notification Set Successfully.',
                    variant: 'success',
                })
                setTimeout(function() {
                    window.location.href = '/#/'
                }, 4000)
                setSchedulerModal(false)
            })
        }
    }

    const notarizeNowHandler = () => {
        let { error, errorMessage } = cstm_validator('send')
        setisButtonClicked(true)
        let documentoverride = []
        if (documents.length < 1) {
            error = true
            errorMessage = 'Please upload minimum 1 document!'
        } else {
            documentoverride = appendFileTypeinFiles()
        }
        if (!error) {
            let signersArray = singerInfo.slice(0, noOfSigner.added)
            let signers = []

            if (signeralready.length < 1) {
                signersArray.map((data, i) => {
                    let checklist = []
                    //  data.checklist.map(data => checklist.push(data.value));
                    let signer = {
                        firstName: data.fname,
                        lastName: data.lname,
                        middleName: data.middleName,
                        suffix: data.suffix,
                        biometric: data.biometric,
                        nonUS: data.nonUS,
                        email: data.email,
                        phone: data.phone && data.phone != null ? data.phone : '',
                        zip: data.zip,
                        state: data.state,
                        city: data.city,
                        address: data.address,
                        /*     "emailExists": checklist.includes('email'),
                    "passportExists": checklist.includes('passport'),
                    "drivingLicenseExists": checklist.includes('license'),
                    "govtIdExists": checklist.includes('govtID'),
                    "smartphoneExists": checklist.includes('smartphone'),*/
                        bypassKBA: data.byPassKBA,
                        bypassReason: data.reason,
                        ssnLast4: data.ssn
                            ? data.ssn.substring(data.ssn.length - 4)
                            : '',
                        dob: data.dob,
                    }
                    if (resumeData !== null) {
                        let resumeSigners = resumeData[0].Signers.length - 1
                        if (i <= resumeSigners) {
                            signer.id = resumeData[0].Signers[i].id
                        }
                    }
                    signers.push(signer)
                })

                setsigneralready(signers)
            } else {
                let signers = []
                signers = signeralready
            }

            let body = {
                userId: selectedUser.id,
                notaryId: auth.user.id,
                initiatedByNotary: true,
                action: 'pending',
                willDoc: false,
                electionDoc: false,
                paymentId: null,
                files: documentoverride,
                Signers: signers,
                type: '',
                scheduledAt: null,
                witnessCount: noOfWitness.value,
            }

            if (signeralready.length > 0) body.Signers = signeralready

            if (resumeData !== null) {
                body.journalId = resumeData[0].id
            }

            if (journalID) {
                body.journalId = journalID
            }

            dispatch(
                actions.addNewNotarization(
                    body,
                    headers,
                    setNotarizeNowSuccess,
                    setNotification
                )
            )
        } else {
            setNotification({ show: true, msg: errorMessage, variant: 'error' })
        }
    }

    const notarizeNowitHandler = () => {
        let { error, errorMessage } = cstm_validator('send')
        setisButtonClicked(true)
        let documentoverride = []

        if(platformFee.fee) {
            dispatch(
                actions.payPlatformFee(
                    {
                        userId: auth.user.id,
                        title: 'Platform fee',
                        currency: 'usd',
                        amount: Math.round(platformFee.fee * 100),
                        cardId: defaultPaymentCard.id,
                    }, headers, {
                    setPaid,
                    setPayNowModal,
                    setCstmDisable,
                    setNotification,
                })
            ).then((data) => {
                setisalreadypaid(true)
                setDisableButton(true)
            
                if (documents.length < 1) {
                    error = true
                    errorMessage = 'Please upload minimum 1 document!'
                } else {
                    documentoverride = appendFileTypeinFiles()
                }
                if (!error) {
                    let signersArray = singerInfo.slice(0, noOfSigner.added)
                    let signers = []

                    if (signeralready.length < 1) {
                        signersArray.map((data, i) => {
                            let checklist = []
                            //  data.checklist.map(data => checklist.push(data.value));
                            let signer = {
                                firstName: data.fname,
                                lastName: data.lname,
                                middleName: data.middleName,
                                suffix: data.suffix,
                                biometric: data.biometric,
                                nonUS: data.nonUS,
                                email: data.email,
                                phone: data.phone && data.phone != null ? data.phone : '',
                                zip: data.zip,
                                state: data.state,
                                city: data.city,
                                address: data.address,
                                /*     "emailExists": checklist.includes('email'),
                            "passportExists": checklist.includes('passport'),
                            "drivingLicenseExists": checklist.includes('license'),
                            "govtIdExists": checklist.includes('govtID'),
                            "smartphoneExists": checklist.includes('smartphone'),*/
                                bypassKBA: data.byPassKBA,
                                bypassReason: data.reason,
                                ssnLast4: data.ssn
                                    ? data.ssn.substring(data.ssn.length - 4)
                                    : '',
                                dob: data.dob,
                            }
                            if (resumeData !== null) {
                                let resumeSigners = resumeData[0].Signers.length - 1
                                if (i <= resumeSigners) {
                                    signer.id = resumeData[0].Signers[i].id
                                }
                            }
                            signers.push(signer)
                        })
                        setsigneralready(signers)
                    }

                    let body = {
                        userId: selectedUser.id,
                        notaryId: auth.user.id,
                        initiatedByNotary: true,
                        action: 'now',
                        willDoc: false,
                        electionDoc: false,
                        paymentId: data === null ? '' : data,
                        files: documentoverride,
                        Signers: signers,
                        type: '',
                        scheduledAt: null,
                        witnessCount: noOfWitness.value,
                    }

                    if (signeralready.length > 0) body.Signers = signeralready

                    if (resumeData !== null) {
                        if (
                            resumeData[0].Payments &&
                            resumeData[0].Payments[0] &&
                            resumeData[0].Payments[0].status == 'succeeded'
                        ) {
                            body.paymentId = resumeData[0].Payments[0].id
                        }
                    }

                    if (resumeData !== null) {
                        body.journalId = resumeData[0].id
                    }
                    if (journalID) {
                        body.journalId = journalID
                    }

                    dispatch(
                        actions.addNewNotarization(
                            body,
                            headers,
                            setNotarizeNowSuccess,
                            setNotification
                        )
                    )
                } else {
                    setNotification({ show: true, msg: errorMessage, variant: 'error' })
                }
            })
        }
    }

    useEffect(() => {
        if (resumeData === null && selectedUser !== '') {
            let copiedSigner = [...singerInfo]
            copiedSigner[0].fname = selectedUser.firstName
            copiedSigner[0].lname = selectedUser.lastName
            copiedSigner[0].middleName = selectedUser.middleName
            copiedSigner[0].suffix = selectedUser.suffix
            copiedSigner[0].biometric = selectedUser.biometric
            copiedSigner[0].nonUS = selectedUser.nonUS
            copiedSigner[0].email = selectedUser.email
            copiedSigner[0].phone = selectedUser.phone ? selectedUser.phone : '';
            copiedSigner[0].state = selectedUser.state
            copiedSigner[0].zip = selectedUser.zip
            copiedSigner[0].city = selectedUser.city
            copiedSigner[0].address = selectedUser.address
            copiedSigner[0].isEmailValid = validator.isEmail(selectedUser.email)
            setSingerInfo(copiedSigner)
        }

        if (resumeData !== null && selectedUser === '') {
            user.map((data, index) => {
                if (user[index].id == resumeData[0].userId)
                    setSelectedUser(user[index])
            })
        }
        if (allClients === null) {
            dispatch(actions.fetchAllClients(headers))
        }
    }, [selectedUser, allClients, allJournals, top5Journals])

    return (
        <>
            {notification.show && (
                <ShowNotification
                    show={notification.show}
                    msg={notification.msg}
                    variant={notification.variant}
                    close={handleCloseNotification}
                />
            )}

            {changeMethod ? (
                <PaymentCardListing
                    setChangeMethod={setChangeMethod}
                    setNotification={setNotification}
                    link={true}
                />
            ) : (
                <NewNotarizationNotaryRoot
                    setChangeMethod={setChangeMethod}
                    setSelectedUser={setSelectedUser}
                    isButtonClicked={isButtonClicked}
                    singerInfo={singerInfo}
                    iscontinue={iscontinue}
                    setsigneralready={setsigneralready}
                    setSingerInfo={setSingerInfo}
                    isalreadypaid={isalreadypaid}
                    noOfSigner={noOfSigner}
                    notarizeNowitHandler={notarizeNowitHandler}
                    payNowModal={payNowModal}
                    setPayNowModal={setPayNowModal}
                    payNowHandler={payNowHandler}
                    payPlatformFeeHandler={payPlatformFeeHandler}
                    platformFee={platformFee}
                    setNoOfSigner={setNoOfSigner}
                    disableButton={paid}
                    setDisableButton={setDisableButton}
                    draftHandler={draftHandler}
                    scheduleHandler={scheduleHandler}
                    saveLaterSuccess={saveLaterSuccess}
                    notarizeNowHandler={notarizeNowHandler}
                    saveLaterSuccessHideHandler={saveLaterSuccessHideHandler}
                    schedulerModal={schedulerModal}
                    schedulerCloseHandler={schedulerCloseHandler}
                    schedulerNotarizationHandler={schedulerNotarizationHandler}
                    notarizeNowSuccessHideHandler={
                        notarizeNowSuccessHideHandler
                    }
                    notarizeNowSuccess={notarizeNowSuccess}
                    headers={headers}
                    setNotification={setNotification}
                    selectedUser={selectedUser}
                    resumeData={resumeData}
                    setNoOfWitness={setNoOfWitness}
                    noOfWitness={noOfWitness}
                    resumeNotarization={
                        resumeData !== null ? resumeData[0] : null
                    }
                    settypeFile={settypeFile}
                    typeFile={typeFile}
                    handleChangeFileType={handleChangeFileType}

                    // platformFee={platformFee}
                />
            )}
        </>
    )
}
