import React from "react";
import { Modal } from "react-bootstrap";
import { CustomerEditForm } from "./CustomerEditForm";

export function CustomerEditDialog({ data, show, onHide }) {
  return (
    <Modal
      size="xl"
      show={show}
      onHide={onHide}
      dialogClassName="modal-width"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <CustomerEditForm onHide={onHide} data={data} />
    </Modal>
  );
}
