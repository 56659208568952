import React from "react";
import { Modal } from "react-bootstrap";
import AddClientForm from "./AddClientForm";

export function AddClientDialog({ data, show, onHide, completed, schedule }) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <AddClientForm
        onHide={onHide}
        data={data}
        schedule={schedule}
        completed={completed}
      />
    </Modal>
  );
}
