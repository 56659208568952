import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { toAbsoluteUrl } from '../../../_metronic/_helpers'
import CircularProgress from '@material-ui/core/CircularProgress'

export function NotificationModal({
    heading,
    show,
    hide,
    detail,
    btnHandler,
    cancel,
    isdone,
}) {
    const [timer, setTimer] = useState(false)
    const [notificationStart, setnotificationStart] = useState(false)

    const poptime = (filename) => {
        let filenamearr = filename.split('-')
        filenamearr.splice(filenamearr, 1)

        return filenamearr.join('-')
    }

    const playSound = () => {
        const audio = new Audio(toAbsoluteUrl('/media/notification/sound.mp3'))
        audio.play()
    }

    useEffect(() => {
        if (!notificationStart) {
            playSound()
            setnotificationStart(true)
        }
    }, [])

    return (
        <>
            <Modal
                show={show}
                onHide={() => hide(false)}
                className='modal-size'
                dialogClassName='my-modal-Notification'>
                <Modal.Header>
                    <Modal.Title>New {heading} Call</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-body-main'>
                    <div className='modal-body-one'>
                        You have a new {heading} request
                        {/* <p style={{fontSize: '11px', color:'red'}}><strong>Disclaimer</strong>. Don't refresh/close your page. Might you will loss the further verification process. </p> */}
                        <div class='add_new'>
                            <div className='main-category'>
                                <span className='category'>Additional Details about this Notarization:</span>
                            </div>
                            <div class='flex_class'>
                                <span>
                                    Election Doc:{' '}
                                    {detail.journal &&
                                    detail.journal.electionDoc
                                        ? 'Yes'
                                        : 'No'}{' '}
                                </span>
                                <span>
                                    {' '}
                                    Carries Will:{' '}
                                    {detail.journal && detail.journal.willDoc
                                        ? 'Yes'
                                        : 'No'}{' '}
                                </span>
                                <span>
                                    No. of signers:{' '}
                                    {detail.journal && detail.journal.Signers
                                        ? detail.journal.Signers.length
                                        : 0}
                                </span>
                                <span className='documentWidth'>
                                    Document:{' '}
                                    {detail.journal &&
                                        detail.journal.journalDocs &&
                                        detail.journal.journalDocs.map((el) => (
                                            <span className='wrapWord'>
                                                {poptime(el.File.filename)}
                                                <br />
                                            </span>
                                        ))}
                                </span>
                                <span>
                                    No. of witness allow:{' '}
                                    {detail.journal.witnessCount
                                        ? detail.journal.witnessCount
                                        : 0}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='modal-body-two'>
                        <Button
                            variant='secondary'
                            disabled={isdone}
                            className='acceptBtn'
                            onClick={btnHandler}>
                            Accept
                        </Button>

                        {heading != "Notarization" ? (
                            <Button
                            variant='outlined'
                            disabled={isdone}
                            color='secondary'
                            className='rejectBtn'
                            onClick={cancel}>
                            Reject
                        </Button>
                        ) :(
                            ''
                        )}
                        
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
