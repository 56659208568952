import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { requestCode } from "../_redux/authCrud";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";

const initialValues = {
  code: "",
  user_id: ""
};

function CodePage(props) {
  const { intl } = props;
  const [ID, setID] = useState(
    props.location.state && props.location.state.user_id
      ? props.location.state.user_id
      : ""
  );

  const [isRequested, setIsRequested] = useState(false);
  const [loading, setLoading] = useState(false);
  const [iscode, setCode] = useState(false);
  const CodeSchema = Yup.object().shape({
    code: Yup.string().required("Enter Your Code")
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };
  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: CodeSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        requestCode(values.code, ID)
          .then(res => {
            disableLoading();
            setIsRequested(true);
            setCode(true);
            // window.location.href = "/auth/code-page"
            setID(res.data.Success.user_id);
          })
          .catch(err => {
            setIsRequested(false);
            // setSubmitting(false);
            setStatus(
              intl.formatMessage({
                id: "Invalid code"
              })
            );
            disableLoading();
          });
      }, 1000);
    }
  });

  return (
    <>
      {!ID && <Redirect to="/auth/forgot-password" />}
      {isRequested && ID ? (
        <Redirect
          to={{
            pathname: "/auth/change-password",
            state: { user_id: ID }
          }}
        />
      ) : (
        ""
      )}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          {iscode ? (
            <Alert severity="success">Code Send Successfully</Alert>
          ) : (
            ""
          )}
          <div className="text-center mb-3 mb-lg-10">
            <div className="forget_password_text">
              <h4 className="text_forget">Password Reset Code</h4>
            </div>
            <div className="text-muted font-weight-bold password-text">
              Please enter the code that was sent to your email below. The email
              would have been sent from noreply@cyberizeit.com.
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <TextField
                className={`w-100 ${getInputClasses("code")}`}
                label="Password Reset Code"
                variant="outlined"
                type="text"
                name="code"
                color={"secondary"}
                {...formik.getFieldProps("code")}
              />
              {formik.touched.code && formik.errors.code ? (
                <div
                  className="fv-plugins-message-container"
                  style={{ color: "red" }}
                >
                  <div className="fv-help-block">{formik.errors.code}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                // disabled={formik.isSubmitting}
              >
                Submit
                {loading && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
              <Link to="/auth/forgot-password">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(CodePage));
