import React, { createContext, useContext, useState, useCallback } from 'react'
import { isEqual, isFunction } from 'lodash'
import { initialFilter } from './TableUIHelpers'

const TableUIContext = createContext()

export function useTableUIContext() {
    return useContext(TableUIContext)
}

export const TableUIConsumer = TableUIContext.Consumer

export function TableUIProvider({ customersUIEvents, children }) {
    //export function TableUIProvider(props) {

    //  let {customersUIEvents, children} = props;
    const [queryParams, setQueryParamsBase] = useState({
        sortOrder: 'asc', // asc||desc
        sortField: 'id',
        pageNumber: 1,
        pageSize: 10,
    })
    const [ids, setIds] = useState([])
    const setQueryParams = useCallback((nextQueryParams) => {
        setQueryParamsBase((prevQueryParams) => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams)
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams
            }

            return nextQueryParams
        })
    }, [])

    const initCustomer = {
        id: undefined,
        title: '',
        type: '',
        date_time: '',
        noOfSigners: 0,
        status: 1,
    }

    const value = {
        queryParams,
        setQueryParamsBase,
        ids,
        setIds,
        setQueryParams,
        initCustomer,
        removeFromNotary: customersUIEvents.removeFromNotary,
        openClaimPopup: customersUIEvents.openClaimPopup,
        // newCustomerButtonClick: TableUIEvents.newCustomerButtonClick,
        // openEditCustomerDialog: TableUIEvents.openEditCustomerDialog,
        // openDeleteCustomerDialog: TableUIEvents.openDeleteCustomerDialog,
        // openDeleteTableDialog: TableUIEvents.openDeleteTableDialog,
        // openFetchTableDialog: TableUIEvents.openFetchTableDialog,
        // openUpdateTableStatusDialog: TableUIEvents.openUpdateTableStatusDialog
    }

    return (
        <TableUIContext.Provider value={value}>
            {children}
        </TableUIContext.Provider>
    )
}
