import React from "react";
import AddCardImage from "../../../media/zeroState/AddCard.webp";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

export function CustomAddCardTable(props) {
  return (
    <>
      <div>
        <div>
          <img src={AddCardImage} className="addcardImage" alt="" />
        </div>
        <div className="Nocard">{/* <h3>No Card Added!</h3> */}</div>
        <div className="cardAdded">
          <span>Please add your credit card to get started.</span>
          <div>
            <Link to="#">
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#2d3fd3",
                  color: "#ffffff",
                  fontSize: "15px",
                  marginTop: "15px"
                }} /*openModal={true} openaddcard={true}*/
              >
                {" "}
                Add Card{" "}
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
