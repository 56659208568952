import React from "react";
import NewNotarizationImage from "../../../media/zeroState/NewNotarization.webp";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

export function CustomNewNotarizationTable() {
  return (
    <>
      <div style={{ textAlign: "center" }}>
        <img
          src={NewNotarizationImage}
          className="newNotarizationImage"
          alt="no activity"
          style={{ width: "25%" }}
        />
        {/* <h3 className="mt-10">No Activity!</h3> */}
        <p>Once you initiate a Notarization, it will be listed here.</p>
        <Link to="/new-notarization">
          <Button
            variant="contained"
            style={{
              backgroundColor: "#2d3fd3",
              color: "#ffffff",
              fontSize: "13px",
              marginTop: "25px"
            }}
          >
            {" "}
            + New Notarization{" "}
          </Button>
        </Link>
      </div>
    </>
  );
}
