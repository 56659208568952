import React, { useState } from "react";
import PaymentCardListing from "../components/new_notarization/PaymentCardListing";
import ShowNotification from "../components/new_notarization/ShowNotification";

export function PaymentPage() {
  const [notification, setNotification] = useState({
    show: false,
    msg: "",
    variant: ""
  });
  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") return;
    setNotification({ show: false, msg: "", variant: "" });
  };

  return (
    <>
      {notification.show && (
        <ShowNotification
          show={notification.show}
          msg={notification.msg}
          variant={notification.variant}
          close={handleCloseNotification}
        />
      )}
      <PaymentCardListing setNotification={setNotification} link={false} />
    </>
  );
}
