import * as requestFromServer from "./kbaCrud";
import { kbaSlice, callTypes } from "./kbaSlice";

const { actions } = kbaSlice;

export const fetchQuestions = (param, headers) => dispatch => {
  return requestFromServer
    .getQuestions(param, headers)
    .then(response => {
      return actions.questionsFetched(response.data.questions);
    })
    .catch(error => {
      return error.response.data;
    });
};

export const SubmitQuestions = (param, headers) => dispatch => {
  return requestFromServer
    .SubmitQuestions(param, headers)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
};
