// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Switch from "@material-ui/core/Switch";

export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  { EnableStateBiometricDialog }
) {
  return (
    <>
      <Switch
        checked={row.biometricEnabled}
        id={"check" + row.id}
        onChange={() => {
          EnableStateBiometricDialog(row.id, row.biometricEnabled);
        }}
        name={"check" + row.id}
        value={row.id}
      />
    </>
  );
}
