import React, { useState, useEffect } from "react";
import { Divider } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Row, Col, Button } from "react-bootstrap";

const IDQuiz = ({ data, buttonColour, buttonText, buttonDisabled, signerLinkVerification, link }) => {

  return (
    <>
      <div
        style={{
          display: "grid",
          justifyItems: "center"
        }}
      >
        <>
          <Row>
            <Col
              md={6}
              style={{
                display: "grid",
                justifyItems: "center"
              }}
            >
              <h1
                style={{
                  color: "#0400F0",
                  fontSize: "50px"
                }}
              >
                ID Quiz
              </h1>
              <div style={{ fontSize: "16px" }}>
                This is a five-question quiz about your life. It is multiple
                choice, and you ONLY have two minutes to complete it. You must
                answer four out of five questions correctly to pass this part.
                Please click on this link to begin, your time starts as soon
                as the page has loaded.
              </div>
              <div style={{ fontSize: "16px" }}>
                NOTE: If the button is not available to you, please move to
                the next step in this process.{" "}
              </div>
            </Col>
            <Col md={6}>
              <img
                className="logo-sticky max-h-250px"
                alt="Exclamation Mark"
                src={toAbsoluteUrl("media/id-proofing/id-proofing-quiz.webp")}
              />
            </Col>
          </Row>
          {
            data && (data?.status === "failed" || (data?.signer?.type && data?.signer?.type !== "kba")) ? (
              <Button
                disabled={true}
                variant="contained"
                style={{ minWidth: 250, width: "300px", height: "65px", fontSize: "16px", background: "red", color: "white" }}
              >
                Please Scroll Down For Your Verification
              </Button>
            ) : (
              <>
                <Button
                  disabled={buttonDisabled}
                  variant="contained"
                  style={{ minWidth: 250, width: "300px", height: "50px", fontSize: "16px", background: buttonColour, color: "white" }}
                  onClick={signerLinkVerification}
                >
                  {buttonText}
                </Button>
                <a href={link} target="_blank" style={{cursor: "pointer"}} id="kbaLinkHref"></a>
              </>
            )
          }

          <br />
          <Row>
            <Col sm={2}></Col>
            <Col sm={8}>
              <b
                style={{
                  color: "#E02B20",
                  fontSize: "25px"
                }}
              >
                DO NOT EXIT THIS BROWSER WINDOW UNTIL ALL STEPS HAVE BEEN
                COMPLETED SUCESSFULLY!
              </b>
            </Col>
          </Row>
        </>
      </div>
      <Divider
        style={{
          height: "3px",
          backgroundColor: "blue",
          marginTop: 30,
          marginBottom: 30,
        }}
      />
    </>
  );
};

export default IDQuiz;
