import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../_metronic/_partials/controls/WitnessingCard";
import { CustomAuditReportTable } from "../../components/audit_report/table/CustomAuditReportTable";
import DownlaodIcon from "../../media/zeroState/downloadicon.webp";
export function TableAuditReportCard() {
  return (
    <Card>
      <CardHeader title="Audit Report" className="py-6">
        <CardHeaderToolbar>
          <Link to="#">
            <button
              type="button"
              className="btn"
              style={{ backgroundColor: "#f7f7f7", color: "#4abd54" }}
            >
              <img src={DownlaodIcon} className="downlaodIcon" alt="" />
              Download as CSV
            </button>
          </Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <CustomAuditReportTable />
        <Link to="#" style={{ float: "right", textDecoration: "underline" }}>
          View All
        </Link>
      </CardBody>
    </Card>
  );
}
