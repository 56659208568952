import React, { useEffect, useState } from "react";
import requestPayoutTransfer from "../../media/Group 73@2x.png";

import { useSelector } from "react-redux";

export function PaymentTransferCard({ type, heading, transferred }) {
  const { auth } = useSelector(state => state);

  return (
    <div>
      <span className={type}>
        <h3>{heading}</h3>
        <p style={{ fontSize: "20px" }}>${transferred}</p>
        <div className="main-request-total">
          <img
            src={requestPayoutTransfer}
            className="requestPayoutTotal"
            alt=""
          />
        </div>
      </span>
    </div>
  );
}
