import React, { useRef, useEffect, useState } from "react";
import { shallowEqual, useSelector, connect, useDispatch } from "react-redux";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import * as auth from "./authRedux";
import {
  getUserByToken,
  deleteCommisions,
  updateProfileCall,
  updateUserProfileCall,
  updatePassportImageCall,
  uploadInsurancePolicyImageCall,
  myPrimaryNotaryCall,
  notaryProfileAvatarCall,
  userProfileAvatarCall,
  deleteDocumentByID,
  deleteUserDocumentByID,
  getStripVerificationCall
} from "./authCrud";

function AuthInit(props) {
  const didRequest = useRef(false);
  const dispatch = useDispatch();
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const { authToken } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken
    }),
    shallowEqual
  );

  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const { data: user } = await getUserByToken();
          dispatch(props.fulfillUser(user));
        }
      } catch (error) {
        console.error(error);
        if (!didRequest.current) {
          dispatch(props.logout());
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => (didRequest.current = true);
    };

    if (authToken) {
      requestUser();
    } else {
      dispatch(props.fulfillUser(undefined));
      setShowSplashScreen(false);
    }
    // eslint-disable-next-line
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>;
}

export default connect(null, auth.actions)(AuthInit);

export const updateProfile = (
  profile,
  authToken,
  setFormUpdateMSG
) => dispatch => {
  return updateProfileCall(profile, authToken)
    .then(res => {
      dispatch({ type: "UPDATE_PROFILE", payload: res.data.user });
      setFormUpdateMSG(true);

      setTimeout(() => {
        setFormUpdateMSG(false);
      }, 4000);
    })
    .catch(error => {});
};

export const deleteCommisionsCall = (
  deleteCommission,
  authToken,
  setFormUpdateMSG
) => dispatch => {
  return deleteCommisions(deleteCommission, authToken)
    .then(res => {
      setFormUpdateMSG(true);

      setTimeout(() => {
        setFormUpdateMSG(false);
      }, 4000);
    })
    .catch(error => {});
};

export const updateUserProfile = (
  userProfile,
  authToken,
  setFormUpdateMSG
) => dispatch => {
  return updateUserProfileCall(userProfile, authToken)
    .then(res => {
      dispatch({ type: "UPDATE_USER_PROFILE", payload: res.data.user });
      setFormUpdateMSG(true);

      setTimeout(() => {
        setFormUpdateMSG(false);
      }, 4000);
    })
    .catch(error => {});
};

export const updatePassportProfile = (
  passportProfile,
  authToken
) => dispatch => {
  return updatePassportImageCall(passportProfile, authToken)
    .then(response => {
      dispatch({ type: "UPDATE_PASSPORT_IMAGE", payload: response.data.files });
    })
    .catch(error => {
      error.clientMessage = "Can't update Passport Image ";
    });
};

export const uploadInsurancePolicy = (
  InsurancePolicyProfile,
  authToken
) => dispatch => {
  return uploadInsurancePolicyImageCall(InsurancePolicyProfile, authToken)
    .then(response => {
      dispatch({
        type: "Insurance_Policy_Image_Upload",
        payload: response.data.files
      });
    })
    .catch(error => {
      error.clientMessage = "Can't update Insurance Policy Image";
    });
};

export const updateNotaryProfileAvatar = (
  notaryProfile,
  authToken,
  setProfileUpdate
) => dispatch => {
  dispatch({ type: "NOTARY_PROFILE_UPLOADING", payload: true });
  return notaryProfileAvatarCall(notaryProfile, authToken)
    .then(response => {
      if (response.data.files[0].id) {
        const updatedUser = {
          user: {
            profile_pic: response.data.files[0].id
              ? response.data.files[0].id
              : null
          }
        };
        dispatch({ type: "profile_Avatar", payload: response.data.files });
        setProfileUpdate(true);

        setTimeout(() => {
          setProfileUpdate(false);
        }, 3000);
        return updateProfileCall(updatedUser, authToken)
          .then(res => {
            dispatch({ type: "UPDATE_PROFILE", payload: res.data.user });
            dispatch({ type: "NOTARY_PROFILE_UPLOADING", payload: false });
          })
          .catch(error => {});
      }
    })
    .catch(error => {
      dispatch({ type: "NOTARY_PROFILE_UPLOADING", payload: false });
      error.clientMessage = "Can't update Notary Profile Image ";
    });
};
export const updateUserProfileAvatar = (
  userProfile,
  authToken,
  setProfileUpdate
) => dispatch => {
  dispatch({ type: "USER_PROFILE_UPLOADING", payload: true });
  return userProfileAvatarCall(userProfile, authToken)
    .then(response => {
      dispatch({ type: "user_Profile_Avatar", payload: response.data.files });
      if (response.data.files[0].id) {
        const updatedUser = {
          user: {
            profile_pic: response.data.files[0].id
          }
        };
        setProfileUpdate(true);

        setTimeout(() => {
          setProfileUpdate(false);
        }, 3000);

        return updateUserProfileCall(updatedUser, authToken)
          .then(res => {
            dispatch({ type: "UPDATE_USER_PROFILE", payload: res.data.user });
            dispatch({ type: "USER_PROFILE_UPLOADING", payload: false });
          })
          .catch(error => {});
      }
    })
    .catch(error => {
      dispatch({ type: "USER_PROFILE_UPLOADING", payload: false });
      error.clientMessage = "Can't update Notary Profile Image ";
    });
};

export const deleteProfile = (profileAvatarID, authToken) => dispatch => {
  return deleteDocumentByID(profileAvatarID, authToken)
    .then(response => {
      dispatch({ type: "delete_Profile", payload: profileAvatarID });
      const updatedUser = {
        user: {
          profile_pic: null
        }
      };
      dispatch({ type: "profile_Avatar", payload: response.data.files });
      return updateProfileCall(updatedUser, authToken)
        .then(res => {
          dispatch({ type: "UPDATE_PROFILE", payload: res.data.user });
        })
        .catch(error => {
          // error.clientMessage = "Can't update customer";
        });
    })
    .catch(error => {});
};

export const deleteUserProfile = (
  userProfileAvatarID,
  authToken
) => dispatch => {
  return deleteUserDocumentByID(userProfileAvatarID, authToken)
    .then(response => {
      dispatch({ type: "delete_User_Profile", payload: userProfileAvatarID });

      const updatedUser = {
        user: {
          profile_pic: null
        }
      };
      return updateUserProfileCall(updatedUser, authToken)
        .then(res => {
          dispatch({ type: "UPDATE_USER_PROFILE", payload: res.data.user });
        })
        .catch(error => {
          // error.clientMessage = "Can't update customer";
        });
    })
    .catch(error => {});
};

export const getStripVerification = authToken => dispatch => {
  return getStripVerificationCall(authToken)
    .then(response => {
      dispatch({ type: "STRIPE_VERIFICATION", payload: response.data });
    })
    .catch(error => {
      error.clientMessage = "Can't find document";
    });
};
