import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { requestPassword } from "../_redux/authCrud";
import TextField from "@material-ui/core/TextField";
import logo from "./../media/LOGO.jpg";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const initialValues = {
  user_id: "",
  email: ""
};

function ForgotPassword(props) {
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);
  const [ID, setID] = useState("");
  const [loading, setLoading] = useState(false);
  const [isforgot, setForgot] = useState(false);
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .required("Enter Your Email")
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        requestPassword(values.email)
          .then(res => {
            disableLoading();
            setIsRequested(true);
            setForgot(true);
            setID(res.data.userId);
          })
          .catch(err => {
            setIsRequested(false);
            setSubmitting(false);
            setStatus(
              intl.formatMessage(
                { id: "AUTH.VALIDATION.NOT_FOUND" },
                { name: values.email }
              )
            );
            disableLoading();
          });
      }, 1000);
    }
  });

  const arrowStyle = {
    fontSize: "36px",
    color: "#909090",
    backgroundColor: "#fbfbfb",
    borderRadius: "50%",
    marginRight: "2px",
    padding: "7px"
  };

  return (
    <>
      {isRequested && ID ? (
        <Redirect
          to={{
            pathname: "/auth/code-page",
            state: { user_id: ID }
          }}
        />
      ) : (
        ""
      )}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <img
            src={logo}
            alt="Logo"
            className="w-25 "
            style={{ marginTop: -20 }}
          />
          <div className="text-center mb-5 mb-lg-15">
            <Link to="/auth/login" style={{ marginRight: "320px" }}>
              <ArrowBackIcon style={arrowStyle} />{" "}
              <span style={{ fontSize: "14px", color: "#909090" }}>Back</span>
            </Link>
            <div className="forget_password_text">
              <h4 className="text_forget">
                Please enter your registered email address and you will receive
                a code to create a new password.
              </h4>
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <TextField
                className={`w-100 ${getInputClasses("email")}`}
                label="Email Address"
                variant="outlined"
                type="email"
                name="email"
                color={"secondary"}
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div
                  className="fv-plugins-message-container"
                  style={{ color: "red" }}
                >
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            <div className="iwillremember">
              <p className="" style={{ color: "#5c5c5c", float: "left" }}>
                Cancel Password Reset
              </p>
              <Link
                className="px-9"
                to="/#/auth/login"
                id="kt_login_forgot"
                style={{ color: "#2d3fd3", marginLeft: "-20px" }}
              >
                Login Now
              </Link>
            </div>
            <div className="submitBtnForget">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                // disabled={formik.isSubmitting}
              >
                Submit
                {loading && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
