import axios from "axios";
import React, { useState } from "react";
import { Modal, Button, Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import StripeInput from "./StripeInput";
import Alert from "@material-ui/lab/Alert";
import * as actions from "../_redux/notarization_redux/notarizationActions";

export default function ReadyCardView({
  stripeToken,
  openModal,
  userID,
  headers,
  setModal,
  setNotification
}) {
  const [cardName, setCardName] = useState("");
  const [makeDefault, setMakeDefault] = useState(false);
  const [error, setError] = useState({ isError: false, message: "" });

  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const { auth } = useSelector(state => state);
  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    if (cardName === "") {
      setError({
        isError: true,
        message: "Your card holder name is incomplete."
      });
      setTimeout(() => setError({ isError: false, message: "" }), 2000);
      return;
    }

    const result = await stripe.confirmCardSetup(stripeToken, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: cardName
        }
      }
    });

    if (result.error) {
      setError({ isError: true, message: result.error.message });
      setTimeout(() => setError({ isError: false, message: "" }), 2000);
    } else {
      const body = {
        userId: userID,
        paymentId: result.setupIntent.payment_method,
        makeDefault: makeDefault
      };

      dispatch(
        actions.addPaymentCard(body, headers, setModal, setNotification)
      ).then(data => {
        dispatch(
          actions.fetchDefaultPaymentCard({ userID: auth.user.id }, headers)
        );
      });
    }
  };

  return (
    <Modal show={openModal} size="lg" centered className="addCardModal">
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Add New Card
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container style={{ padding: 0 }}>
          {error.isError && (
            <Row className="mb-6">
              <Alert severity="error" style={{ width: "100%" }}>
                {error.message}
              </Alert>
            </Row>
          )}
          <Row className="mb-6">
            <Col xs={12} md={12}>
              <TextField
                required
                label="Card Holder Name"
                value={cardName}
                onChange={e => setCardName(e.target.value)}
                margin="dense"
                variant="outlined"
                placeholder="John Doe"
                InputLabelProps={{ shrink: true }}
                inputProps={{ style: { height: "15px" } }}
                style={{ width: "100%" }}
              />
            </Col>
          </Row>

          <Row className="mb-6">
            <Col xs={12} md={12}>
              <TextField
                required
                label="Credit Card Number"
                margin="dense"
                variant="outlined"
                style={{ width: "100%" }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    component: CardNumberElement
                  }
                }}
              />
            </Col>
          </Row>

          <Row className="mb-6">
            <Col xs={6} md={6}>
              <TextField
                required
                label="Expiry Date"
                margin="dense"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    component: CardExpiryElement
                  }
                }}
                style={{ width: "100%" }}
              />
            </Col>
            <Col xs={6} md={6}>
              <TextField
                required
                label="CVV"
                margin="dense"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    component: CardCvcElement
                  }
                }}
                style={{ width: "100%" }}
              />
            </Col>
          </Row>
          <p className="mb-0" style={{ float: "left" }}>
            <Checkbox
              checked={makeDefault}
              onChange={e => setMakeDefault(e.target.checked)}
              value="make_default"
              color="primary"
              inputProps={{
                "aria-label": "secondary checkbox"
              }}
            />
            Make default
          </p>
        </Container>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button
          style={{
            backgroundColor: "#effff4",
            borderColor: "#effff4",
            color: "#00c05d",
            minWidth: "135px"
          }}
          onClick={() => setModal(false)}
          className="mr-8"
        >
          Cancel
        </Button>
        <Button
          style={{
            backgroundColor: "#00c05d",
            borderColor: "#00c05d",
            minWidth: "135px"
          }}
          onClick={handleSubmit}
        >
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
