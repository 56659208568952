import React, { useState, useEffect } from 'react'
import NewNotarizationNotaryRootPS from '../components/new_notarization/NewNotarizationNotaryRootPS'
import ShowNotification from '../components/new_notarization/ShowNotification'
import { useDispatch, useSelector } from 'react-redux'
import PaymentCardListing from '../components/new_notarization/PaymentCardListing'
import * as actions from '../components/_redux/notarization_redux/notarizationActions'
import * as socketactions from '../socket/_redux/socket_redux/socketActions'
import LoadingOverlay from 'react-loading-overlay'
import { useHistory, useLocation } from 'react-router-dom'
import validator from 'validator'
import { OnlineSearchNotaries } from './OnlineSearchNotaries'

export function NewNotarizationPS({
    newNot = false,
    id = null,
    from = null,
    resumeNotarization = null,
}) {
    const history = useHistory()
    const location = useLocation()
    const [PrimaryNotarySelected, setPrimaryNotarySelected] = useState(false)

    const [notification, setNotification] = useState({
        show: false,
        msg: '',
        variant: '',
    })
    const [saveLaterSuccess, setSaveLaterSuccess] = useState(false)
    const [scheduleLaterDateTime, setScheduleLaterDateTime] = useState('')
    const [chooseNotaryProp, setchooseNotaryProp] = useState(false)
    const [notarynotificationcounter, setnotarynotificationcounter] = useState(
        0
    )
    const [
        initnotarynotificationcounter,
        setinitnotarynotificationcounter,
    ] = useState(false)
    let isPrimaryNotary = primaryNotary !== null
    let primaryNotaryObj = primaryNotary !== null ? primaryNotary : ''
    let notaryID = ''
    const [selectNotaryID, setselectNotaryID] = useState(notaryID)
    const [selectedNotary, setSelectedNotary] = useState({
        primary: isPrimaryNotary,
        primaryNotary: primaryNotaryObj,
    })
    const [currentSubstatus, setcurrentSubstatus] = useState('')

    const [notarizeNowSuccess, setNotarizeNowSuccess] = useState(false)
    const [schedulerModal, setSchedulerModal] = useState(false)
    const [addedSigners, setAddedSigners] = useState(null)
    const [cpostModal, setcpostModal] = useState(false)
    const [jobPostDoneMode, setjobPostDoneMode] = useState('All')
    const [chooseNotary, setChooseNotary] = useState(chooseNotaryProp)
    const [changeMethod, setChangeMethod] = useState(false)
    const [signeralready, setsigneralready] = useState([])
    const [scheduleLaterSuccess, setScheduleLaterSuccess] = useState(false)
    const [selectNotaryModal, setSelectNotaryModal] = useState(false);
    const dispatch = useDispatch()
    const { auth, notarization, socket } = useSelector((state) => state)
    const {
        defaultPaymentCard,
        defaultPaymentCard2,
        documents,
        allJournals,
        allAdminStates,
        top5Journals,
        primaryNotary,
        allClients,
        paymentDetails,
        payments,
        getAllJournal,
        currentkbasigners,
        KBASettings,
        currentjournalstatus,
        MyNotaries,
        notaryTypes
    } = notarization
    const [payNowModal, setPayNowModal] = useState(false)
    const [journalID, setjournalID] = useState(id === null ? 0 : id)
    const [temppaid, settemppaid] = useState(false)
    const [platformFee, setPlatformFee] = useState({ fee: 0, docs: 0 })
    const resumeData = newNot
        ? (from === 'journal' ? allJournals : top5Journals).rows.filter(
              (data) => data.id === id
          )
        : null
    const [iscontinue, setiscontinue] = useState(
        resumeData && resumeData[0] ? true : false
    )
    const [isalreadypaid, setisalreadypaid] = useState(
        resumeData &&
            resumeData[0] &&
            resumeData[0].id &&
            resumeData[0].Payments.length > 0 &&
            resumeData[0].status == 'draft'
            ? true
            : false
    )
    const [iscontinuecompleted, setiscontinuecompleted] = useState(false)
    const [cstmDisable, setCstmDisable] = useState(
        resumeData !== null && resumeData[0].Payments.length > 0
            ? 'disabled'
            : ''
    )
    const [paid, setPaid] = useState(
        resumeData !== null && resumeData[0].Payments.length > 0 ? true : false
    )
    let user = []

    const [disableButton, setDisableButton] = useState(
        resumeData !== null && resumeData[0].Payments.length > 0
            ? true
            : false
    )
    const [noOfSigner, setNoOfSigner] = useState({
        value: 1,
        added:
            resumeData !== null && resumeData[0].Signers.length > 0
                ? resumeData[0].Signers.length
                : 1,
        error: false,
    })
    const [comments, setComments] = useState(resumeData !== null ? resumeData[0].comments : "")
    const [noOfWitness, setNoOfWitness] = useState({
        value: resumeData !== null ? resumeData[0].witnessCount : 0,
        error: false,
    })
    if (resumeData !== null && allClients !== null) {
        user = allClients.rows.filter(
            (el) =>
                el.firstName === resumeData[0].customer.firstName &&
                el.lastName === resumeData[0].customer.lastName
        )
    }
    const [datetimeerr, setdatetimeerr] = useState('')
    const [prefstate, setprefstate] = useState('')
    const [typeNotary, setTypeNotary] = useState('')
    let noticounter = 0
    const [dateTime, setDateTime] = React.useState(
        new Date(new Date().getTime() + 30 * 60000).toISOString()
    )
    const [lang, setLang] = useState([])
    const [selectedApprovedNotary, setSelectedApprovedNotary] = useState()
    const [typeFile, settypeFile] = useState({})
    const [JobPostDoneModal, setJobPostDoneModal] = useState(false)
    const [selectedUser, setSelectedUser] = useState('')
    const [isActive, setisActive] = useState(false)
    const [showKBAVerificationModal, setShowKBAVerificationModal] = useState(
        false
    )
    const [showKBASignersStatus, setshowKBASignersStatus] = useState(false)
    const [showVerificationDoneModal, setShowVerificationDoneModal] = useState(
        false
    )
    const [showNotaryWaiting, setshowNotaryWaiting] = useState(false)
    const [showNoNotary, setshowNoNotary] = useState(false)
    const [showReNotary, setshowReNotary] = useState(false)
    const [issingleKBA, setissingleKBA] = useState(false)
    const [kbatimeout, setkbatimeout] = useState(false)
    const [KBASettingscheck, setKBASettingscheck] = useState(false)
    const [KBASettingscheckdispatch, setKBASettingscheckdispatch] = useState(
        false
    )
    const [signerInterval, setsignerInterval] = useState([])
    const [journalInterval, setjournalInterval] = useState([])
    // const [kbaflow, setkbaflow] = useState(false);
    // const [notarynotificationtimeout, setnotarynotificationtimeout] = useState(false);
    const [intialprimaryNotary, setintialprimaryNotary] = useState(false)
    const [kbaVerificationDone, setkbaVerificationDone] = useState(false)
    const [kbaVerificationSingle, setkbaVerificationSingle] = useState(0)
    let singers = [
        {
            fname: auth.user.firstName,
            middleName: auth.user.middleName,
            lname: auth.user.lastName,
            suffix: auth.user.suffix,
            biometric: auth.user.biometric,
            nonUS: auth.user.nonUS,
            email: auth.user.email,
            phone: auth.user.phone,
            checklist: [],
            notGovtID: false,
            isEmailValid: validator.isEmail(auth.user.email),
            isError: false,
            address: auth.user.address,
            zip: auth.user.zip,
            city: auth.user.city,
            state: auth.user.state,
            ssn: '',
            dob: '',
            temporaryLink: '',
            byPassKBA: false,
            reason: '',
        },
    ]

    for (let i = 1; i <= 9999; i++) {
        singers.push({
            fname: '',
            middleName: '',
            suffix: '',
            lname: '',
            email: '',
            phone: '',
            checklist: [],
            reason: '',
            notGovtID: false,
            biometric: false,
            nonUS: false,
            byPassKBA: false,
            isEmailValid: false,
            isError: false,
            address: '',
            zip: '',
            city: '',
            state: '',
            ssn: '',
            dob: '',
            temporaryLink: '',
        })
    }

    if (resumeData !== null) {
        resumeData[0].Signers.map((data, index) => {
            singers[index].fname = data.firstName
            singers[index].lname = data.lastName
            singers[index].middleName = data.middleName
            singers[index].suffix = data.suffix
            singers[index].biometric = data.biometric
            singers[index].nonUS = data.nonUS
            singers[index].email = data.email
            singers[index].phone = data.phone && data.phone != null ? data.phone : ''
            singers[index].state = data.state
            singers[index].zip = data.zip
            singers[index].city = data.city
            singers[index].address = data.address
            singers[index].byPassKBA =
                data.bypassKBA === null ? false : data.bypassKBA
            singers[index].ssn = data.ssnLast4
            singers[index].dob = data.dob
            singers[index].isEmailValid = validator.isEmail(data.email)
            singers[index].reason =
                data.bypassReason === null ? '' : data.bypassReason
        })
    }
    const [singerInfo, setSingerInfo] = useState(singers)

    const headers = {
        Authorization: `Bearer ${auth.authToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
    }

    const handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') return
        setNotification({ show: false, msg: '', variant: '' })
    }

    const seconds_with_leading_zeros = (dt) => {
        return /\((.*)\)/.exec(dt.toString())[1]
    }

    const prepareTime = (dt) => {
        let date = '',
            time = '',
            timezone = ''
        const options = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }
        const dateOptions = { hour: '2-digit', minute: '2-digit' }
        date = new Date(dt).toLocaleDateString(undefined, options)
        time = new Date(dt).toLocaleTimeString('en-US', dateOptions)
        timezone = seconds_with_leading_zeros(new Date(dt))
        timezone = timezone.split(' ')
        let shortTimezone = ''
        timezone.map((el) => {
            shortTimezone += el[0]
        })
        return `${date} at ${time} (${shortTimezone})`
    }

    const saveLaterSuccessHideHandler = () => {
        setSaveLaterSuccess(false)
        window.location.href = '/#/'
    }

    const notarizeNowSuccessHideHandler = () => {
        setNotarizeNowSuccess(false)
        window.location.href = '/#/'
    }

    const datetimeerrHandler = () => {
        setdatetimeerr('')
    }

    const checknotarynotificationtimeout = () => {
        if (getAllJournal && getAllJournal.journal && getAllJournal.journal.id)
            dispatch(actions.checkJournal(getAllJournal.journal.id, headers))
        else dispatch(actions.checkJournal(journalID, headers))
    }

    const schedulerCloseHandler = () => setSchedulerModal(false)
    const scheduleHandler = () => setSchedulerModal(true)
    const fieldsValidator = () => {
        let error = false,
            errorMessage = ''
        let copiedArr = [...singerInfo]
        for (let i = 0; i < noOfSigner.added; i++) {
            copiedArr[i]['isError'] = false
            let checklist = []
            let email = true,
                phone = true,
                personalDocs = true

            if (!/^[a-zA-Z0-9]*$/g.test(singerInfo[i].fname)) {
                error = true
                errorMessage =
                    'Please Provide Correct Signer ' + (i + 1) + ' First Name'
                copiedArr[i]['isError'] = true
            }

            if (!/^[a-zA-Z0-9]*$/g.test(singerInfo[i].middleName)) {
                error = true
                errorMessage =
                    'Please Provide Correct  Signer ' + (i + 1) + ' Middle Name'
                copiedArr[i]['isError'] = true
            }

            if (!/^[a-zA-Z0-9]*$/g.test(singerInfo[i].lname)) {
                error = true
                errorMessage =
                    'Please Provide Correct  Signer ' + (i + 1) + ' Last Name'
                copiedArr[i]['isError'] = true
            }

            if (singerInfo[i].byPassKBA && singerInfo[i].reason === '') {
                error = true
                errorMessage = 'Please fill out the mandatory fields!'
                copiedArr[i]['isError'] = true
            }

            if (
                !singerInfo[i].byPassKBA &&
                (!email || !phone || !personalDocs)
            ) {
                error = true
                errorMessage = 'Please fill out the mandatory fields!'
                copiedArr[i]['isError'] = true
            }

            if (!singerInfo[i].isEmailValid) {
                error = true
                errorMessage = 'Please enter valid email!'
                copiedArr[i]['isError'] = true
            }
            if (singerInfo[i].suffix && 5 < singerInfo[i].suffix.length) {
                error = true
                errorMessage = 'Suffix must not exceed 5 letters'
                copiedArr[i]['isError'] = true
            } else if (
                singerInfo[i].suffix &&
                !/^[A-Za-z.]+$/gi.test(singerInfo[i].suffix)
            ) {
                error = true
                errorMessage =
                    'Please Provide Correct Signer ' + (i + 1) + ' Suffix'
                copiedArr[i]['isError'] = true
            }
            if (
                singerInfo[i].fname === '' ||
                singerInfo[i].lname === '' ||
                singerInfo[i].email === '' ||                
                (singerInfo[i].phone === '' && auth.user.Role.code != "notary") ||
                singerInfo[i].zip === '' ||
                singerInfo[i].city === '' ||
                singerInfo[i].state === '' ||
                singerInfo[i].address === ''
            ) {
                error = true
                errorMessage = 'Please fill out the mandatory fields!'
                copiedArr[i]['isError'] = true
            }
        }
        setSingerInfo(copiedArr)
        return { error, errorMessage }
    }

    const cstm_validator = (action) => {
        let error = false,
            errorMessage = ''

        if (action === 'send') {
            let fieldError = fieldsValidator()
            error = fieldError.error
            errorMessage = fieldError.errorMessage
        }

        if (documents.length < 1) {
            error = true
            errorMessage = 'Please upload minimum 1 document!'
        }

        return { error, errorMessage }
    }

    const confirmjobPostHandler = () => {
        let showattention = false
        let copiedArr = [...singerInfo]
        for (let i = 0; i < noOfSigner.added; i++) {
            if (copiedArr[i].biometric) {
                showattention = true
                break
            }
        }
        if (showattention) {
            fetchEnabledStates(true)
        }
        setcpostModal(true)
    }

    const syncforsignersverification = (journalID) => {
        setShowKBAVerificationModal(true)
        setjournalID(journalID)
        dispatch(actions.checkSigner(journalID, headers))
        setsignerInterval(
            setInterval(function() {
                dispatch(actions.checkSigner(journalID, headers))
            }, 8000)
        )
    }

    const syncforjournalverification = (journalID) => {
        setjournalInterval(
            setInterval(function() {
                checknotarynotificationtimeout()
            }, 8000)
        )
    }

    const checksignersstatus = (currentkbasignersfunc) => {
        if (currentkbasignersfunc.length > 0) {
            for (
                let signerscount = 0;
                signerscount < currentkbasignersfunc.length;
                signerscount++
            ) {
                if (currentkbasignersfunc[signerscount].status == 'pending')
                    return false
                else if (
                    currentkbasignersfunc[signerscount].status == 'rejected'
                )
                    return 'rejected'
            }
            return true
        }
    }

    const handleChangeFileType = (event) => {
        let ttfile = typeFile
        if (!ttfile[event.target.name]) ttfile[event.target.name] = ''

        ttfile[event.target.name] = event.target.value
        settypeFile(ttfile)
    }

    const appendFileTypeinFiles = () => {
        let documentoverride = []
        documents.map((data, i) => {
            documentoverride[i] = {}
            documentoverride[i].baseUrl = documents[i].baseUrl
            documentoverride[i].createdAt = documents[i].createdAt
            documentoverride[i].filename = documents[i].filename
            documentoverride[i].id = documents[i].id
            documentoverride[i].path = documents[i].path
            documentoverride[i].type = documents[i].type
            documentoverride[i].updatedAt = documents[i].updatedAt

            if (
                resumeData &&
                resumeData[0] &&
                resumeData[0].journalDocs &&
                checkFileexist(documents[i].id)
            ) {
                documentoverride[i].docType = checkFileexist(documents[i].id)
            } else {
                documentoverride[i].docType = typeFile['file' + documents[i].id]
            }
        })

        return documentoverride
    }
    const checkFileexist = (fileID) => {
        let fileDetail = resumeData[0].journalDocs.filter(function(fileObj) {
            return fileObj.fileId == fileID
        })
        if (!fileDetail[0]) return false
        else return fileDetail[0].docType
    }

    const jobPostHandler = (privatePool = false) => {
        let preselectNotarization = location.search
        const urlParams = new URLSearchParams(location.search)

        preselectNotarization = urlParams.get('preselectNotarization')

        let body = {
            action: 'post',
            userId: auth.user.id,
            privatePool: privatePool,
        }

        if (preselectNotarization) {
            if (lang.length > 0) {
                body.languages = lang
            }

            if (prefstate) {
                body.state = prefstate
            }

            if (Date.parse(dateTime) < Date.parse(new Date())) {
                setdatetimeerr(
                    'Please provide date and time, the time should be at least 30 min'
                )

                return false
            }

            if (dateTime) {
                if (
                    Date.parse(dateTime) >=
                    Date.parse(new Date(new Date().getTime() + 30 * 60000))
                )
                    body.scheduledAt = new Date(dateTime).toISOString()
                else
                    body.scheduledAt = new Date(
                        new Date().getTime() + 30 * 60000
                    ).toISOString()
            }

            if (typeNotary) {
                body.notaryType = typeNotary
            }

            if (preselectNotarization !== 0) {
                body.journalId = preselectNotarization
            }

            dispatch(
                actions.UpdateNotarization(
                    preselectNotarization,
                    body,
                    headers,
                    setNotarizeNowSuccess,
                    setNotification
                )
            ).then((data) => {
                if (data) {
                    setJobPostDoneModal(true)
                    setcpostModal(false)
                }
            })
        } else {
            let checkbypasskba = 0

            let documentoverride = []
            if (documents.length < 1) {
            } else {
                documentoverride = appendFileTypeinFiles()
            }

            let signersArray = singerInfo.slice(0, noOfSigner.added)
            let signers = []
            //if(signeralready.length < 1){

            signersArray.map((data, i) => {
                let checklist = []
                //  data.checklist.map(data => checklist.push(data.value));

                let signer = {
                    firstName: data.fname,
                    lastName: data.lname,
                    middleName: data.middleName,
                    suffix: data.suffix,
                    biometric: data.biometric,
                    nonUS: data.nonUS,
                    email: data.email,
                    phone: data.phone && data.phone != null ? data.phone : '',
                    address: data.address,
                    zip: data.zip,
                    city: data.city,
                    state: data.state,
                    bypassKBA: data.byPassKBA,
                    bypassReason: data.reason,
                    ssnLast4: data.ssn.substring(data.ssn.length - 4),
                    dob: data.dob,
                }

                if (resumeData !== null) {
                    let resumeSigners = resumeData[0].Signers.length - 1
                    if (i <= resumeSigners)
                        signer.id = resumeData[0].Signers[i].id
                }

                if (addedSigners !== null) {
                    let resumeSigners = addedSigners.length - 1
                    if (i <= resumeSigners) signer.id = addedSigners[i].id
                }
                signers.push(signer)
            })

            //}

            body = {
                userId: auth.user.id,
                notaryId: null !== selectedApprovedNotary ? selectedApprovedNotary : null,
                initiatedByNotary: false,
                action: 'post',
                privatePool: privatePool,

                paymentId: payments === null ? '' : payments.id,
                files: documentoverride,
                Signers: signers,
                comments: comments,
                type: '',
                scheduledAt: new Date().toISOString(),
                witnessCount: noOfWitness.value,
            }

            if( selectedApprovedNotary ) {
                body.selectedApprovedNotary = selectedApprovedNotary;
            }

            if (signeralready.length > 0) body.Signers = signeralready /**/

            if (journalID !== 0) {
                body.journalId = journalID
            }

            if (resumeData !== null) {
                if (resumeData[0].Payments.length > 0) {
                    body.paymentId = resumeData[0].Payments[0].id
                }
                body.journalId = resumeData[0].id
            }

            if (lang.length > 0) {
                body.languages = lang
            }

            if (prefstate) {
                body.state = prefstate
            }

            if (Date.parse(dateTime) < Date.parse(new Date())) {
                setdatetimeerr(
                    'Please provide date and time, the time should be at least 30 min'
                )

                return false
            }

            if (dateTime) {
                if (
                    Date.parse(dateTime) >=
                    Date.parse(new Date(new Date().getTime() + 30 * 60000))
                )
                    body.scheduledAt = new Date(dateTime).toISOString()
                else
                    body.scheduledAt = new Date(
                        new Date().getTime() + 30 * 60000
                    ).toISOString()
            }

            if (typeNotary) {
                body.notaryType = typeNotary
            }

            dispatch(
                actions.addNewNotarization(
                    body,
                    headers,
                    setNotarizeNowSuccess,
                    setNotification,
                    'job'
                )
            ).then((data) => {
                if (data) {
                    setJobPostDoneModal(true)
                    setcpostModal(false)
                    setjobPostDoneMode(
                        privatePool
                            ? 'This Notarization has been successfully posted.'
                            : 'This Notarization has been successfully posted.'
                    )
                }
            })
        }
    }

    const payPlatformFeeHandler = (fee) => {
        let body = {
            userId: auth.user.id,
            title: 'Platform fee',
            currency: 'usd',
            amount: Math.round(fee * 100),
            cardId:
                defaultPaymentCard && defaultPaymentCard.id
                    ? defaultPaymentCard.id
                    : '',
        }

        if (
            'ps' == auth.user.Role.code &&
            defaultPaymentCard2 &&
            defaultPaymentCard2.net30Setting &&
            defaultPaymentCard2.net30Setting.default
        )
            body.cardId = defaultPaymentCard2.net30Setting.cardId

        dispatch(
            actions.payPlatformFee(body, headers, {
                setPaid,
                setPayNowModal,
                setCstmDisable,
                setNotification,
            })
        ).then((data) => {
            setisalreadypaid(true)
            setDisableButton(true)
            draftHandler('payment', data)
        })
    }

    const fetchEnabledStates = (isEnabled = '') => {
        dispatch(
            actions.fetchNotaryStates(
                headers,
                [`biometricEnabled=${isEnabled}`],
                {
                    pageNumber: 1,
                    pageSize: 52,
                    sortBy: "asc",
                    sortField: "state",
                }
            )
        )
    }

    const payNowHandler = () => {
        let error = false
        let errorMessage = ''
        let copiedArr = [...singerInfo]
        for (let i = 0; i < noOfSigner.added; i++) {
            copiedArr[i]['isError'] = false
            let checklist = []
            let email = true,
                phone = true,
                personalDocs = true

            if (!/^[a-zA-Z0-9]*$/g.test(singerInfo[i].fname)) {
                error = true
                errorMessage =
                    'Please Provide Correct Signer ' + (i + 1) + ' First Name'
                copiedArr[i]['isError'] = true
            }

            if (!/^[a-zA-Z0-9]*$/g.test(singerInfo[i].middleName)) {
                error = true
                errorMessage =
                    'Please Provide Correct Signer ' + (i + 1) + ' Middle Name'
                copiedArr[i]['isError'] = true
            }

            if (!/^[a-zA-Z0-9]*$/g.test(singerInfo[i].lname)) {
                error = true
                errorMessage =
                    'Please Provide Correct  Signer ' + (i + 1) + ' Last Name'
                copiedArr[i]['isError'] = true
            }

            if (singerInfo[i].byPassKBA && singerInfo[i].reason === '') {
                error = true
                errorMessage = 'Please fill out the mandatory fields!'
                copiedArr[i]['isError'] = true
            }

            if (
                !singerInfo[i].byPassKBA &&
                (!email || !phone || !personalDocs)
            ) {
                error = true
                errorMessage = 'Please fill out the mandatory fields!'
                copiedArr[i]['isError'] = true
            }

            if (!singerInfo[i].isEmailValid) {
                error = true
                errorMessage = 'Please enter valid email!'
                copiedArr[i]['isError'] = true
            }

            if (
                singerInfo[i].fname === '' ||
                singerInfo[i].lname === '' ||
                singerInfo[i].email === '' ||                
                (singerInfo[i].phone === '' && auth.user.Role.code != "notary") ||
                singerInfo[i].address === '' ||
                singerInfo[i].zip === '' ||
                singerInfo[i].city === '' ||
                singerInfo[i].state === '' ||
                singerInfo[i].fname === null ||
                singerInfo[i].lname === null ||
                singerInfo[i].email === null ||
                singerInfo[i].address === null ||
                singerInfo[i].zip === null ||
                singerInfo[i].city === null ||
                singerInfo[i].state === null ||
                (!singerInfo[i].biometric &&
                    (singerInfo[i].dob === null || singerInfo[i].dob === ''))
            ) {
                error = true
                errorMessage = 'Please fill out the mandatory fields!'
                copiedArr[i]['isError'] = true
            }
        }
        setSingerInfo(copiedArr)

        if (documents.length < 1) {
            error = true
            errorMessage = 'Please upload minimum 1 document!'
        }

        if (
            'ps' == auth.user.Role.code &&
            defaultPaymentCard2 &&
            defaultPaymentCard2.net30Setting &&
            defaultPaymentCard2.net30Setting.default
        ) {
            // do Nothing
        } else if (defaultPaymentCard === null) {
            error = true
            errorMessage = 'Please add payment card!'
        }

        if (!error) {
            let fee = 3;
            setPlatformFee({ fee: fee, docs: documents.length })
            setPayNowModal(true)
        } else {
            setNotification({ show: true, msg: errorMessage, variant: 'error' })
        }
    }

    const draftHandler = (
        status = null,
        draftPaymentID = null,
        iscont = false
    ) => {
        let { error, errorMessage } = cstm_validator('draft')

        let documentoverride = []
        if (documents.length < 1) {
            error = true
            errorMessage = 'Please upload minimum 1 document!'
        } else {
            documentoverride = appendFileTypeinFiles()
        }

        if (!error) {
            let signers = []
            if (signeralready.length < 1) {
                let signersArray = singerInfo.slice(0, noOfSigner.added)

                signersArray.map((data, i) => {
                    let checklist = []
                    //    data.checklist.map(data => checklist.push(data.value));
                    let signer = {
                        firstName: data.fname,
                        lastName: data.lname,
                        middleName: data.middleName,
                        suffix: data.suffix,
                        biometric: data.biometric,
                        nonUS: data.nonUS,
                        email: data.email,
                        phone: data.phone && data.phone != null ? data.phone : '',
                        zip: data.zip,
                        state: data.state,
                        city: data.city,
                        address: data.address,
                        bypassKBA: data.byPassKBA,
                        bypassReason: data.reason,
                        ssnLast4: data.ssn
                            ? data.ssn.substring(data.ssn.length - 4)
                            : '',
                        dob: data.dob,
                    }
                    if (resumeData !== null) {
                        let resumeSigners = resumeData[0].Signers.length - 1
                        if (i <= resumeSigners) {
                            signer.id = resumeData[0].Signers[i].id
                        }
                    }
                    signers.push(signer)
                })
                //setsigneralready(signers);
            } else {
                signers = signeralready
            }
            let body = {
                userId: auth.user.id,
                notaryId: null,
                initiatedByNotary: false,
                action: 'draft',
                willDoc: false,
                electionDoc: false,
                paymentId: payments === null ? '' : payments.id,
                files: documentoverride,
                Signers: signers,
                comments: comments,
                type: '',
                scheduledAt: null,
                witnessCount: noOfWitness.value,
            }

            if (signeralready.length > 0) body.Signers = signeralready

            if (resumeData !== null) {
                body.journalId = resumeData[0].id
            }

            if (journalID) {
                body.journalId = journalID
            }

            if (status === 'payment') {
                body.paymentId = draftPaymentID
            }

            dispatch(
                actions.addNewNotarization(
                    body,
                    headers,
                    !iscont ? setSaveLaterSuccess : setiscontinue,
                    setNotification,
                    !iscont ? status : 'payment'
                )
            ).then((data) => {
                setjournalID(data.id)

                if (status === 'payment') setsigneralready(data.journal.Signers)

                if (status === 'payment' && resumeData !== null) {
                    setsigneralready(data.journal.Signers)
                    if (from === 'journal') {
                        dispatch(
                            actions.fetchJournals(
                                {
                                    sortOrder: 'desc', // asc||desc
                                    sortField: 'id',
                                    pageNumber: 1,
                                    pageSize: 10,
                                },
                                headers,
                                auth.user.Role.code
                            )
                        ).then((data) => {
                            setiscontinuecompleted(true)
                            if (resumeData !== null) {
                                resumeNotarization(resumeData[0].id)
                            }
                        })
                    } else {
                        dispatch(
                            actions.fetchTop5Journals(
                                headers,
                                auth.user.Role.code
                            )
                        ).then((data) => {
                            /*	if(iscont)
							resumeData = (from === 'journal' ? allJournals : top5Journals).rows.filter(data => data.id === id);
							*/
                            setiscontinuecompleted(true)
                            if (resumeData !== null) {
                                resumeNotarization(resumeData[0].id)
                            }
                        })
                    }
                }
            })
        } else
            setNotification({ show: true, msg: errorMessage, variant: 'error' })
    }
    const schedulerNotarizationHandler = (dateTime) => {
        setScheduleLaterDateTime(prepareTime(dateTime))

        let signersArray = singerInfo.slice(0, noOfSigner.added)
        let signers = []

        let documentoverride = []
        if (documents.length < 1) {
        } else {
            documentoverride = appendFileTypeinFiles()
        }

        if (signeralready.length < 1) {
            signersArray.map((data, i) => {
                let checklist = []
                let signer = {
                    firstName: data.fname,
                    lastName: data.lname,
                    middleName: data.middleName,
                    suffix: data.suffix,
                    biometric: data.biometric,
                    nonUS: data.nonUS,
                    email: data.email,
                    phone: data.phone && data.phone != null ? data.phone : '',
                    address: data.address,
                    zip: data.zip,
                    city: data.city,
                    state: data.state,
                    bypassKBA: data.byPassKBA,
                    bypassReason: data.reason,
                    ssnLast4: data.ssn.substring(data.ssn.length - 4),
                    dob: data.dob,
                }
                if (resumeData !== null) {
                    let resumeSigners = resumeData[0].Signers.length - 1
                    if (i <= resumeSigners)
                        signer.id = resumeData[0].Signers[i].id
                }

                if (addedSigners !== null) {
                    let resumeSigners = addedSigners.length - 1
                    if (i <= resumeSigners) signer.id = addedSigners[i].id
                }
                signers.push(signer)
            })

            //setsigneralready(signers);
        } else {
            let signers = []
            signers = signeralready
        }

        let body = {
            userId: auth.user.id,
            notaryId: null,
            initiatedByNotary: false,
            action: 'scheduled',
            willDoc: false,
            electionDoc: false,
            paymentId: payments === null ? '' : payments.id,
            files: documentoverride,
            Signers: signers,
            comments: comments,
            type: '',
            scheduledAt: new Date(dateTime).toISOString(),
            witnessCount: noOfWitness.value,
        }

        if (signeralready.length > 0) body.Signers = []

        if (resumeData !== null) {
            if (
                resumeData[0].Payments &&
                resumeData[0].Payments[0] &&
                resumeData[0].Payments[0].status == 'succeeded'
            ) {
                body.paymentId = resumeData[0].Payments[0].id
            }
        }

        if (journalID) {
            body.journalId = journalID
        }

        if (resumeData !== null) {
            body.journalId = resumeData[0].id
        }

        dispatch(
            actions.addNewNotarization(
                body,
                headers,
                setScheduleLaterSuccess,
                setNotification
            )
        )

        setNotification({
            show: true,
            msg: 'Schedule Notification Set Successfully.',
            variant: 'success',
        })
        setTimeout(function() {
            window.location.reload()
        }, 4000)
        setSchedulerModal(false)
    }

    const notarizeNowHandler = () => {
        let { error, errorMessage } = cstm_validator('send')

        let documentoverride = []
        if (documents.length < 1) {
            error = true
            errorMessage = 'Please upload minimum 1 document!'
        } else {
            documentoverride = appendFileTypeinFiles()
        }

        if (!error) {
            let signersArray = singerInfo.slice(0, noOfSigner.added)
            let signers = []

            if (signeralready.length < 1) {
                signersArray.map((data, i) => {
                    let checklist = []
                    let signer = {
                        firstName: data.fname,
                        lastName: data.lname,
                        middleName: data.middleName,
                        suffix: data.suffix,
                        biometric: data.biometric,
                        nonUS: data.nonUS,
                        email: data.email,
                        phone: data.phone && data.phone != null ? data.phone : '',
                        zip: data.zip,
                        state: data.state,
                        city: data.city,
                        address: data.address,
                        bypassKBA: data.byPassKBA,
                        bypassReason: data.reason,
                        ssnLast4: data.ssn
                            ? data.ssn.substring(data.ssn.length - 4)
                            : '',
                        dob: data.dob,
                    }
                    if (resumeData !== null) {
                        let resumeSigners = resumeData[0].Signers.length - 1
                        if (i <= resumeSigners) {
                            signer.id = resumeData[0].Signers[i].id
                        }
                    }
                    signers.push(signer)
                })

                setsigneralready(signers)
            } else {
                let signers = []
                signers = signeralready
            }
            let body = {
                userId: auth.user.id,
                notaryId: null,
                initiatedByNotary: false,
                action: 'pending',
                willDoc: false,
                electionDoc: false,
                paymentId: null,
                files: documentoverride,
                Signers: signers,
                comments: comments,
                type: '',
                scheduledAt: null,
                witnessCount: noOfWitness.value,
            }

            if (signeralready.length > 0) body.Signers = signeralready

            if (resumeData !== null) {
                body.journalId = resumeData[0].id
            }

            if (journalID) {
                body.journalId = journalID
            }

            dispatch(
                actions.addNewNotarization(
                    body,
                    headers,
                    setNotarizeNowSuccess,
                    setNotification
                )
            )
        } else
            setNotification({ show: true, msg: errorMessage, variant: 'error' })
    }

    const notarizeNowitHandler = () => {
        let { error, errorMessage } = cstm_validator('send')

        let documentoverride = []
        if (documents.length < 1) {
            error = true
            errorMessage = 'Please upload minimum 1 document!'
        } else {
            documentoverride = appendFileTypeinFiles()
        }

        if (!error) {
            let signersArray = singerInfo.slice(0, noOfSigner.added)
            let signers = []

            if (signeralready.length < 1) {
                signersArray.map((data, i) => {
                    let checklist = []
                    let signer = {
                        firstName: data.fname,
                        lastName: data.lname,
                        middleName: data.middleName,
                        suffix: data.suffix,
                        biometric: data.biometric,
                        nonUS: data.nonUS,
                        email: data.email,
                        phone: data.phone && data.phone != null ? data.phone : '',
                        zip: data.zip,
                        state: data.state,
                        city: data.city,
                        address: data.address,
                        bypassKBA: data.byPassKBA,
                        bypassReason: data.reason,
                        ssnLast4: data.ssn
                            ? data.ssn.substring(data.ssn.length - 4)
                            : '',
                        dob: data.dob,
                    }
                    if (resumeData !== null) {
                        let resumeSigners = resumeData[0].Signers.length - 1
                        if (i <= resumeSigners) {
                            signer.id = resumeData[0].Signers[i].id
                        }
                    }
                    signers.push(signer)
                })
                setsigneralready(signers)
            }

            let body = {
                userId: auth.user.id,
                notaryId: null,
                initiatedByNotary: false,
                action: 'now',
                willDoc: false,
                electionDoc: false,
                paymentId: payments === null ? '' : payments.id,
                files: documentoverride,
                Signers: signers,
                comments: comments,
                type: '',
                scheduledAt: null,
                witnessCount: noOfWitness.value,
            }

            if (signeralready.length > 0) body.Signers = signeralready

            if (resumeData !== null) {
                if (
                    resumeData[0].Payments &&
                    resumeData[0].Payments[0] &&
                    resumeData[0].Payments[0].status == 'succeeded'
                ) {
                    body.paymentId = resumeData[0].Payments[0].id
                }
            }

            if (resumeData !== null) {
                body.journalId = resumeData[0].id
            }
            if (journalID) {
                body.journalId = journalID
            }

            dispatch(
                actions.addNewNotarization(
                    body,
                    headers,
                    setNotarizeNowSuccess,
                    setNotification
                )
            )
        } else
            setNotification({ show: true, msg: errorMessage, variant: 'error' })
    }

    useEffect(() => {
        if (resumeData === null && selectedUser !== '') {
            let copiedSigner = [...singerInfo]
            copiedSigner[0].fname = selectedUser.firstName
            copiedSigner[0].lname = selectedUser.lastName
            copiedSigner[0].middleName = selectedUser.middleName
            copiedSigner[0].suffix = selectedUser.suffix
            copiedSigner[0].biometric = selectedUser.biometric
            copiedSigner[0].nonUS = selectedUser.nonUS
            copiedSigner[0].email = selectedUser.email
            copiedSigner[0].phone = selectedUser.phone ? selectedUser.phone : ''
            copiedSigner[0].state = selectedUser.state
            copiedSigner[0].zip = selectedUser.zip
            copiedSigner[0].city = selectedUser.city
            copiedSigner[0].address = selectedUser.address
            copiedSigner[0].isEmailValid = validator.isEmail(selectedUser.email)
            setSingerInfo(copiedSigner)
        }

        if (resumeData !== null && selectedUser === '') {
            user.map((data, index) => {
                if (user[index].id == resumeData[0].userId)
                    setSelectedUser(user[index])
            })
        }
        if (allClients === null) {
            dispatch(actions.fetchAllClients(headers))
        }

        let preselectNotarization = location.search
        const urlParams = new URLSearchParams(location.search)

        preselectNotarization = urlParams.get('preselectNotarization')

        if (preselectNotarization && !getAllJournal) {
            setisActive(true)
            dispatch(actions.checkJournal(preselectNotarization, headers))
        } else if (
            preselectNotarization &&
            getAllJournal &&
            !chooseNotaryProp
        ) {
            setchooseNotaryProp(true)

            setjournalID(preselectNotarization)

            if (getAllJournal.journal.notaryId) {
                let notaryID = getAllJournal.journal.notaryId

                for (
                    let isign = 0;
                    isign < getAllJournal.journal.Signers.length;
                    isign++
                ) {
                    if (getAllJournal.journal.Signers[isign].bypassKBA) {
                        setissingleKBA(true)
                    }
                }

                let body = {
                    journalId: preselectNotarization,
                    notaryId: parseInt(notaryID),
                }
                dispatch(
                    actions.setSelectedNotary(body, headers, setNotification)
                ).then((data) => {
                    if (parseInt(data) == 1) {
                        setisActive(false)
                        setselectNotaryID(notaryID)
                        setshowNotaryWaiting(true)
                        setinitnotarynotificationcounter(true)
                        dispatch(
                            actions.checkJournal(preselectNotarization, headers)
                        )
                        syncforjournalverification(preselectNotarization)
                        setPrimaryNotarySelected(true)
                    } else {
                        setshowNoNotary(true)
                    }
                })
            } else {
                setisActive(false)
                setChooseNotary(true)
            }
        }

        if (
            getAllJournal &&
            getAllJournal.journal &&
            getAllJournal.journal.status &&
            'Authentication Passed' == currentSubstatus &&
            issingleKBA
        ) {
            noticounter = 0
            setcurrentSubstatus('')
            setshowNotaryWaiting(false)
            setshowReNotary(true)
            setNotification({
                show: true,
                msg: 'Please Reschedule with your Notary, or post to the Job Board.',
                variant: 'error',
            })
            setinitnotarynotificationcounter(false)

            if (
                (KBASettings &&
                    KBASettings.data &&
                    KBASettings.data.settings.bypassAllowed) ||
                issingleKBA
            )
                setTimeout(function() {
                    window.location.href = '/#/journals'
                    window.location.reload()
                }, 100)
        } else if (
            getAllJournal &&
            getAllJournal.journal &&
            getAllJournal.journal.status &&
            'Authentication Passed' == currentSubstatus &&
            !issingleKBA
        ) {
            noticounter = 0
            setcurrentSubstatus('')
            setshowNotaryWaiting(false)
            setshowReNotary(true)
            setNotification({
                show: true,
                msg: 'Please Reschedule with your Notary, or post to the Job Board.',
                variant: 'error',
            })
            setinitnotarynotificationcounter(false)
        } else if (
            null != socket.notarizationRejectedID &&
            journalID == socket.notarizationRejectedID
        ) {
            noticounter = 0
            setcurrentSubstatus('')
            setshowNotaryWaiting(false)
            setshowReNotary(true)
            setNotification({
                show: true,
                msg: 'Please Reschedule with your Notary, or post to the Job Board.',
                variant: 'error',
            })
            setinitnotarynotificationcounter(false)
            dispatch(socketactions.emptynotarizationRejection())

            if (
                (KBASettings &&
                    KBASettings.data &&
                    KBASettings.data.settings.bypassAllowed) ||
                issingleKBA
            )
                setTimeout(function() {
                    window.location.href = '/#/journals'
                    window.location.reload()
                }, 3000)
        } else if (
            getAllJournal &&
            getAllJournal.journal &&
            getAllJournal.journal.subStatus
        ) {
            if (
                !currentSubstatus &&
                getAllJournal.journal.subStatus == 'Authentication Passed'
            ) {
            } else setcurrentSubstatus(getAllJournal.journal.subStatus)
        }

        if (!initnotarynotificationcounter) {
            setjournalInterval(clearInterval(journalInterval))
        }

        if (getAllJournal && getAllJournal.journal.subStatus == 'Accepted') {
            setshowNotaryWaiting(false)
            setshowNoNotary(false)
            setShowVerificationDoneModal(true)
            setjournalInterval(clearInterval(journalInterval))
        } else if (
            getAllJournal &&
            getAllJournal.journal.subStatus == 'Rejected'
        ) {
            setshowNotaryWaiting(false)
            setshowReNotary(true)
            setNotification({
                show: true,
                msg:
                    'Notary Rejected your Notarization. Kindly choose another notary',
                variant: 'error',
            })
            setjournalInterval(clearInterval(journalInterval))
        }

        dispatch(actions.getLanguages(headers))
        dispatch(actions.fetchNotaryTypes(headers))
        fetchEnabledStates();
        
        if (resumeData && resumeData[0] && resumeData[0].Payments.length > 0) {
            dispatch(
                actions.getPaymentforExistingJournal(
                    resumeData[0].Payments[0].cardId,
                    headers
                )
            )
        }

        dispatch(
            actions.fetchMyNotaries(
                {
                    q: "",
                    lang: "",
                    type: "",
                    state: "",
                    sortOrder: 'desc', // asc||desc
                    sortField: 'id',
                    pageNumber: 1,
                    pageSize: 10,
                },
                headers,
                auth.user.Role.code
            )
        )
    }, [
        selectedUser,
        allClients,
        allJournals,
        top5Journals,
        primaryNotary,
        currentkbasigners,
        currentjournalstatus,
        socket,
        noticounter,
        getAllJournal,
        KBASettings,
        issingleKBA,
    ])

    const chooseNotaryHandler = (e) => {
        // let notaryID = e.currentTarget.id;
        // let body = {
        //   journalId: journalID,
        //   notaryId: parseInt(notaryID),
        //   makePrimary: false,
        // };
        // if (
        //   document.querySelector(
        //     'input[name="make_primary_notary"][value="' + notaryID + '"]'
        //   )
        // ) {
        //   if (
        //     document.querySelector(
        //       'input[name="make_primary_notary"][value="' + notaryID + '"]'
        //     ).checked
        //   ) {
        //     body.makePrimary = true;
        //   }
        // }
        // dispatch(actions.setSelectedNotary(body, headers, setNotification)).then(
        //   (data) => {
        //     if (parseInt(data) == 1) {
        //       setselectNotaryID(notaryID);
        //       setshowNotaryWaiting(true);
        //       setinitnotarynotificationcounter(true);
        //       syncforjournalverification(journalID);
        //       dispatch(actions.checkJournal(journalID, headers));
        //     } else {
        //       setshowNoNotary(true);
        //     }
        //   }
        // );
    }

    const setSelectNotary = () => {
        let notaryType = notaryTypes.find(element => element.value == typeNotary);
        dispatch(
              actions.fetchMyNotaries(
                {
                  lang: ( lang[0] ) ? lang[0].label : "",
                  type: ( notaryType ) ? notaryType.label : "",
                  state: prefstate,
                  sortOrder: "desc", // asc||desc
                  sortField: "id",
                  pageNumber: 1,
                  pageSize: 1000
                },
                headers,
                "auth.user.Role.code"
              )
          ).then(()=>{
            setSelectNotaryModal(true);
          });
    }
    return (
        <>
            {notification.show && (
                <ShowNotification
                    show={notification.show}
                    msg={notification.msg}
                    variant={notification.variant}
                    close={handleCloseNotification}
                />
            )}
            <LoadingOverlay active={isActive} spinner text='Loading...'>
                {changeMethod ? (
                    <PaymentCardListing
                        setChangeMethod={setChangeMethod}
                        setNotification={setNotification}
                        link={true}
                    />
                ) : chooseNotaryProp ? (
                    <OnlineSearchNotaries
                        chooseNotary={true}
                        chooseNotaryHandler={chooseNotaryHandler}
                        showNotaryWaiting={showNotaryWaiting}
                        saveLaterSuccessHideHandler={
                            saveLaterSuccessHideHandler
                        }
                        showVerificationDoneModal={showVerificationDoneModal}
                        noback={true}
                    />
                ) : (
                    <>
                        <NewNotarizationNotaryRootPS
                            setChangeMethod={setChangeMethod}
                            setSelectedUser={setSelectedUser}
                            singerInfo={singerInfo}
                            iscontinue={iscontinue}
                            setSingerInfo={setSingerInfo}
                            isalreadypaid={isalreadypaid}
                            noOfSigner={noOfSigner}
                            notarizeNowitHandler={notarizeNowitHandler}
                            payNowModal={payNowModal}
                            setPayNowModal={setPayNowModal}
                            payNowHandler={payNowHandler}
                            payPlatformFeeHandler={payPlatformFeeHandler}
                            platformFee={platformFee}
                            setNoOfSigner={setNoOfSigner}
                            disableButton={disableButton}
                            paid={paid}
                            setDisableButton={setDisableButton}
                            draftHandler={draftHandler}
                            scheduleHandler={scheduleHandler}
                            saveLaterSuccess={saveLaterSuccess}
                            notarizeNowHandler={notarizeNowHandler}
                            saveLaterSuccessHideHandler={
                                saveLaterSuccessHideHandler
                            }
                            schedulerModal={schedulerModal}
                            schedulerCloseHandler={schedulerCloseHandler}
                            schedulerNotarizationHandler={
                                schedulerNotarizationHandler
                            }
                            notarizeNowSuccessHideHandler={
                                notarizeNowSuccessHideHandler
                            }
                            notarizeNowSuccess={notarizeNowSuccess}
                            allAdminStates={allAdminStates}
                            headers={headers}
                            setNotification={setNotification}
                            selectedUser={selectedUser}
                            resumeData={resumeData}
                            setNoOfWitness={setNoOfWitness}
                            noOfWitness={noOfWitness}
                            comments={comments}
                            setComments = {setComments}
                            resumeNotarization={
                                resumeData !== null ? resumeData[0] : null
                            }
                            cpostModal={cpostModal}
                            setcpostModal={setcpostModal}
                            confirmjobPostHandler={confirmjobPostHandler}
                            jobPostHandler={jobPostHandler}
                            jobPostDoneMode={jobPostDoneMode}
                            JobPostDoneModal={JobPostDoneModal}
                            setTypeNotary={setTypeNotary}
                            setLang={setLang}
                            setprefstate={setprefstate}
                            datetimeerr={datetimeerr}
                            dateTime={dateTime}
                            setDateTime={setDateTime}
                            datetimeerrHandler={datetimeerrHandler}
                            handleChangeFileType={handleChangeFileType}
                            typeFile={typeFile}
                            setSelectNotary={setSelectNotary}
                            setSelectNotaryModal={setSelectNotaryModal}
                            selectNotaryModal={selectNotaryModal}
                            selectedApprovedNotary={selectedApprovedNotary}
                            setSelectedApprovedNotary={setSelectedApprovedNotary} 
                        />
                    </>
                )}
            </LoadingOverlay>
        </>
    )
}
