import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
    Card,
    CardActions,
    CardContent,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    TextareaAutosize,
    CircularProgress,
} from '@material-ui/core'
import * as actions from '../../_redux/notarization_redux/notarizationActions'
import { Button, Modal } from 'react-bootstrap'
import ShowNotification from '../../../components/new_notarization/ShowNotification'

export function CancelNotarization(props) {
    const [show, setShow] = useState(false)

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const [textArea, setTextArea] = useState(false)
    const [notification, setNotification] = useState({
        show: false,
        msg: '',
        variant: '',
    })
    const [loading, setLoading] = useState(false)
    const { auth } = useSelector((state) => state)
    const [isdone, setisdone] = useState(false)
    let journalId = props.journalID

    const headers = {
        Authorization: `Bearer ${auth.authToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
    }

    const dispatch = useDispatch()

    const [value, setValue] = React.useState('')

    const enableLoading = () => {
        setLoading(true)
    }

    const disableLoading = () => {
        setLoading(false)
    }
    const handleChange = (event) => {
        setValue(event.target.value)
        if (event.target.value === 'other') {
            setTextArea(true)
        } else {
            setTextArea(false)
        }
    }
    const handlerCheckTextArea = (event) => {
        setValue(event.target.value)
    }

    const cancelNotarizationDispatch = () => {
        setisdone(true)
        dispatch(
            actions.cancelNotarizationAction(
                headers,
                value,
                journalId,
                setNotification,
                enableLoading,
                disableLoading,
                props.alreadyOnDashboard
            )
        )
    }

    const handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') return
        setNotification({ show: false, msg: '', variant: '' })
    }
    return (
        <>
            {notification.show && (
                <ShowNotification
                    show={notification.show}
                    msg={notification.msg}
                    variant={notification.variant}
                    close={handleCloseNotification}
                />
            )}

            {props.showCard ? (
                <Card
                    style={{ width: '100%', height: '108px' }}
                    variant='outlined'>
                    <CardContent>
                        <h5>Cancel Notarization</h5>
                    </CardContent>
                    <CardActions>
                        <div className='text-center'>
                            <Button
                                variant='contained'
                                style={{
                                    backgroundColor: 'red',
                                    color: 'white',
                                    marginTop: -4,
                                }}
                                onClick={handleShow}>
                                Cancel Notarization
                            </Button>
                        </div>
                    </CardActions>
                </Card>
            ) : (
                <Button
                    variant='contained'
                    className='btn'
                    style={{
                        backgroundColor: 'red',
                        color: 'white',
                        fontSize: "1rem"
                        // width: '100%',
                    }}
                    onClick={handleShow}>
                    Cancel Notarization
                </Button>
            )}
            <Modal show={show} onHide={handleClose} animation={false} size='md'>
                <Modal.Header closeButton>
                    <Modal.Title>Cancel Notarization</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6>
                        We request this information to ensure all parties are on
                        the same page should the client call our client support
                        line. You are not penalized to perform this action.
                        Please cancel this transaction if it is not legally
                        being completed.
                    </h6>
                    <div>
                        <FormControl component='fieldset'>
                            <RadioGroup
                                aria-label='notarization'
                                name='notarization1'
                                value={value}
                                onChange={handleChange}>
                                <FormControlLabel
                                    value='The Principal was not the person who was on the document'
                                    control={<Radio />}
                                    label='The Principal was not the person who was on the document'
                                />
                                <FormControlLabel
                                    value='The Principal was not doing this act freely'
                                    control={<Radio />}
                                    label='The Principal was not doing this act freely'
                                />
                                <FormControlLabel
                                    value='The Principal was rude and unprofessional'
                                    control={<Radio />}
                                    label='The Principal was rude and unprofessional'
                                />
                                <FormControlLabel
                                    value='The document cannot be done via a RON Notarization'
                                    control={<Radio />}
                                    label='The document cannot be done via a RON Notarization'
                                />
                                <FormControlLabel
                                    value='There were technical problems during this which limited the ability to
perform the notarization.'
                                    control={<Radio />}
                                    label='There were technical problems during this which limited the ability to
perform the notarization.'
                                />
                                <FormControlLabel
                                    value='other'
                                    control={<Radio />}
                                    label='Other'
                                    onClick={() => setTextArea(true)}
                                />
                                {textArea ? (
                                    <TextareaAutosize
                                        aria-label='minimum height'
                                        rowsMin={3}
                                        placeholder='Please explain (description box with 300 words max)'
                                        onChange={handlerCheckTextArea}
                                    />
                                ) : (
                                    ''
                                )}
                            </RadioGroup>
                        </FormControl>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant='danger'
                        disabled={isdone}
                        onClick={cancelNotarizationDispatch}>
                        {!isdone ? (
                            <>Yes Cancel Notarization</>
                        ) : (
                            <CircularProgress
                                classes={'colorPrimary'}
                                size={15}
                            />
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
