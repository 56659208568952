import React from "react";
import Grid from "@material-ui/core/Grid";
import NotaryCard from "./NotaryCard";
import WitnessNotaryCard from "./WitnessNotaryCard";
import PrimaryNotaryCard from "./PrimaryNotaryCard";
import { useLocation } from "react-router";

export function OnlineNotaryListing({
  notaries,
  makePrimaryHandler,
  chooseNotary,
  chooseNotaryHandler,
  onlineNotaries1,
  getOnlineNotaries
}) {
  const location = useLocation();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <Grid container spacing={8}>
            {chooseNotary === true &&
              notaries.map(el => (
                <NotaryCard
                  key={el.id}
                  notary={el}
                  chooseNotaryHandler={chooseNotaryHandler}
                />
              ))}
            {/* {location.pathname === '/searchWitnesses/' && } */}
            {!chooseNotary ? (
              <>
                {location.pathname === "/chooseNotaries"
                  ? notaries.map(el => (
                      <NotaryCard
                        key={el.id}
                        notary={el}
                        chooseNotaryHandler={chooseNotaryHandler}
                      />
                    ))
                  : notaries.map(el => (
                      <WitnessNotaryCard
                        key={el.id}
                        notary={el}
                        mode="witness"
                        onlineNotaries1={onlineNotaries1}
                      />
                    ))}
              </>
            ) : (
              ""
            )}
            {/* {location.pathname === '/searchPrimaryNotary' && notaries.map(el => <PrimaryNotaryCard key={el.id} notary={el} makePrimaryHandler={makePrimaryHandler} />)} */}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
