import { createSlice } from '@reduxjs/toolkit'

const initialSocketState = {
    object: [],
    notaryNotification: [],
    notaryManualNotification: [],
    witnessNotification: [],
    scheduleNotification: null,
    scheduleNotificationResponse: null,
    // agreementCompleted:{"journalId":857,documentCount:1},
    agreementCompleted: [],
    agreementsigned: [],
    kbaVerificationDone: [],
    kbaVerificationSingle: null,
    kbaVerificationRejected: null,
    generalNotification: [],
    notarizationRejectedID: null,
    idologyLink: null,
    kbaVerificationRejectedNotary: null,
    adobeCredentials: null,
    notificationByNotary: null,
    witnessJobNotification: null,
    notarizationJobNotification: null,
    WitnessReadyNotification: null,
    linkUpdated: null,
    idProofing: null,
    kbaSignerStatus: null,
    idScanSignerStatus: null,
    biometricSignerStatus: null,
    signerPageAttemptsExceeded: null,
    primaryNotaryChanged: null,
    statusChangedNotification: null,
    newJobPostedNotification: null,
}

export const callTypes = {
    list: 'list',
    action: 'action',
}

export const socketSlice = createSlice({
    name: 'socket',
    initialState: initialSocketState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`
        },
        startCall: (state, action) => {
            state.error = null
        },
        notarizationRejected: (state, action) => {
            state.notarizationRejectedID = action.payload.journalId
        },

        socketSet: (state, action) => {
            state.object = action.payload
        },

        emptyNotarization: (state, action) => {
            state.notaryNotification = []
        },

        emptynotarizationRejection: (state, action) => {
            state.notarizationRejectedID = null
        },

        notarizeItNotification: (state, action) => {
            state.notificationByNotary = action.payload
        },

        notaryNotification: (state, action) => {
            state.notaryNotification = action.payload
        },

        witnessJobNotification: (state, action) => {
            state.witnessJobNotification = action.payload
        },

        notarizationJobNotification: (state, action) => {
            state.notarizationJobNotification = action.payload
        },

        WitnessReadyNotification: (state, action) => {
            state.WitnessReadyNotification = action.payload
        },

        witnessNotification: (state, action) => {
            state.witnessNotification = action.payload
        },

        adobeCredentialsUpdated: (state, action) => {
            state.adobeCredentials = action.payload
        },

        agreementCompleted: (state, action) => {
            state.agreementCompleted = action.payload
        },

        completeNotarization: (state, action) => {
            state.agreementCompleted = {
                journalId: action.payload.journalId,
                documentCount: action.payload.documentCount,
            }
        },

        callidologyLink: (state, action) => {
            state.idScanLink = null
            state.biometricLink = null
            state.idologyLink = action.payload
        },

        agreementSigned: (state, action) => {
            state.agreementsigned = {
                id: action.payload.id,
                type: action.payload.type,
            }
        },

        kbaVerificationCompleted: (state, action) => {
            state.kbaVerificationDone = { 
                journalId: action.payload.journalId,
                signerId: action.payload.signerId
            }
        },

        scheduleNotarizationNotification: (state, action) => {
            if (action.payload.notarization)
                state.scheduleNotification = {
                    journal: action.payload.notarization,
                    initiator: action.payload.initiator,
                }
            else
                state.scheduleNotification = { journal: action.payload.journal }
        },

        clearscheduleNotification: (state, action) => {
            state.scheduleNotification = null
        },

        scheduledNotaryCompleted: (state, action) => {
            state.scheduleNotificationResponse = action.payload
        },

        kbaVerificationSingleCompleted: (state, action) => {
            state.kbaVerificationSingle = {
                journalId: action.payload.journalId,
                signerId: action.payload.signerId,
            }
        },

        kbaVerificationRejected: (state, action) => {
            state.kbaVerificationRejected = {
                journalId: action.payload.journalId,
                signerId: action.payload.signerId,
            }
        },

        biometricLink: (state, action) => {
            state.idologyLink = null
            state.idScanLink = null
            state.biometricLink = action.payload
        },

        idScanLink: (state, action) => {
            state.idologyLink = null
            state.biometricLink = null
            state.idScanLink = action.payload
        },

        primaryNotaryChanged: (state, action) => {
            state.primaryNotaryChanged = action.payload
        },

        linkUpdated: (state, action) => {
            state.linkUpdated = action.payload
        },
        idProofing: (state, action) => {
            state.idProofing = action.payload
        },

        kbaSignerStatus: (state, action) => {
            state.kbaSignerStatus = action.payload
        },
        idScanSignerStatus: (state, action) => {
            state.idScanSignerStatus = action.payload
        },
        biometricSignerStatus: (state, action) => {
            state.biometricSignerStatus = action.payload
        },
        signerPageAttemptsExceeded: (state, action) => {
            state.signerPageAttemptsExceeded = action.payload
        },
        signerPageAttempts: (state, action) => {
            state.signerPageAttempts = action.payload
        },
        kbaVerificationRejectedNotary: (state, action) => {
            state.kbaVerificationRejectedNotary = {
                journalId: action.payload.journalId,
                signerId: action.payload.signerId,
            }
        },

        getandcheckNotifications: (state, action) => {
            state.generalNotification = action.payload
        },

        statusChangedNotification: (state, action) => {
            state.statusChangedNotification = action.payload
        },

        newJobPostedNotification: (state, action) => {
            state.newJobPostedNotification = action.payload
        },

        clearAllState: (state, action) => {
            state.object = []
        },
    },
})
