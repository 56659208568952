import React, { useEffect, useState } from "react";
import { TableUIProvider } from "../components/adminnoterylisting/TableUIContext";
import { TableCard } from "../components/adminnoterylisting/TableCard";
import { GenericZeroState } from "../components/zero_state_pages/GenericZeroState";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../components/_redux/notarization_redux/notarizationActions";
import { CustomerEditDialog } from "../components/adminnoterylisting/edit-dialog/CustomerEditDialog";
import { Filters } from "../components/seach_notaries/card/Filters";

export function MyAdmin() {
  const dispatch = useDispatch();
  const { auth, notarization } = useSelector(state => state);
  const { allAdminNotaries, allAdminNotariesQuery, languages } = notarization;
  const [actionModal, setActionModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);

  // States related to filters mentioned below...
  const [showFilter, setShowFilter] = useState(false);
  const [filterValues, setFilterValues] = useState({
    language: "",
    notaryType: "",
    state: "",
    status: "",
    mode: "",
  });
  const [queries, setQueries] = useState({
    lang: "",
    type: "",
    state: "",
    sortOrder: "desc", // asc||desc
    sortField: "id",
    pageNumber: 1,
    pageSize: 10
  });

  const applyFilterHandler = name => {
    if (name === "filters") {
      setQueries({
        ...queries,
        lang: filterValues.language,
        type: filterValues.notaryType,
        state: filterValues.state,
        status: filterValues.status,
        mode: filterValues.mode,
      });
    }
    else if (name === "clear") {
      setQueries({  lang: "", type: "", state: "", status: "", mode: "", sortOrder: "desc", sortField: "id", pageNumber: 1, pageSize: 10 });
      // setSearch("");
      setFilterValues({ language: "", notaryType: "", state: "",  status: "",  mode: ""});
      setShowFilter(false);
    } else setQueries({ ...queries, lang: "", type: "", state: "", status: "", mode: "" });
  };

  const customersUIEvents = {
    openEditCustomerDialog: id => {
      setActionModal(true);
      let row = allAdminNotaries.rows.filter(el => el.id === id);
      setSelectedRowData(row[0]);
    }
  };
  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  useEffect(() => {
    dispatch( actions.fetchAdminNotaries(headers, [], queries) );
  }, [queries]);

  return (
    <>
      {allAdminNotaries !== null &&
      (allAdminNotaries.rows.length > 0 || allAdminNotariesQuery) ? (
        <>
          <Filters
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            filterValues={filterValues}
            setFilterValues={setFilterValues}
            applyFilterHandler={applyFilterHandler}
            languages={languages}
            isAdmin={true}
          />
          <TableUIProvider customersUIEvents={customersUIEvents}>
            <CustomerEditDialog
              show={actionModal}
              onHide={() => setActionModal(false)}
              data={selectedRowData}
            />
            <TableCard 
              adminNotaryFilterHandler={applyFilterHandler}
              filterValues={filterValues}
              setQueries={setQueries}
            />
          </TableUIProvider>
        </>
      ) : (
        <GenericZeroState
          status={allAdminNotaries}
          imageURL="/media/own/no-notary.png"
          heading="No Notary User Found!"
          body="It seems that no notary is applied for registration."
          sub_body="Once you accept the notary profile you can see details here!"
          btnTxt="false"
        />
      )}
    </>
  );
}
