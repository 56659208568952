import React, { Suspense, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, Switch, Route, useLocation } from 'react-router-dom'
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout'
import * as actions from './components/_redux/notarization_redux/notarizationActions'
import { DashboardPage } from './pages/DashboardPage'
import { TemplatePage } from './pages/TemplatePage'
import { JournalsPage } from './pages/JournalsPage'
import { MyClientPage } from './pages/MyClientPage'
import { NewNotarization } from './pages/NewNotarization'
import { NewNotarizationNotary } from './pages/NewNotarizationNotary'
import { NewNotarizationPS } from './pages/NewNotarizationPS'
import { PaymentPage } from './pages/PaymentPage'
import { SearchNotaries } from './pages/SearchNotaries'
import { MySearchNotaries } from './pages/MySearchNotaries'

import { OnlineSearchNotaries } from './pages/OnlineSearchNotaries'
import NotaryProfile from './modules/UserProfile/NotaryProfile'
import PSProfile from './modules/UserProfile/PSProfile'
import { CardListing } from './pages/CardListing'
import { WitnessingPage } from './pages/WitnessingPage'
import { TransactionHistory } from './pages/TransactionHistory'
import { MyChooseWitness } from './pages/MyChooseWitness'
import { AdobeSignIframe } from './pages/AdobeSignIframe'
import { BankAccountListing } from './pages/BankAccountListing'
import { NewWitnessRequest } from './components/WitnessRequest/NewWitnessRequest'
import { MyQuestion } from './pages/MyQuestion'
import { ZeroNewNotarization } from './zero_state_page/ZeroNewNotarization'
import { AddCard } from './zero_state_page/AddCard'
import { NoNotaryUserFound } from './zero_state_page/NoNotaryUserFound'
import { NoNotaryIsOnline } from './zero_state_page/NoNotaryIsOnline'
import { NoClients } from './zero_state_page/NoClients'
import { NoBankAdded } from './zero_state_page/NoBankAdded'
import { NoWitness } from './zero_state_page/NoWitness'
import { AuditReport } from './AuditReport/AuditReport'
import { NewNotaryUserRequest } from './components/New Notary User Request/NewNotaryUserRequest'
import { CustomModalForJoinZoomLink } from './pages/CustomModalForJoinZoomLink'
import { SuccessPopup } from './pages/SuccessPopup'
import { Myjobboard } from './pages/Myjobboard'
import { MyNotaries } from './pages/MyNotaries'
import { DeletedJournals } from './pages/DeletedJournals'

import {IDProofingPage} from './pages/IDProofingPage'

import { MyAdmin } from './pages/MyAdmin'
import { AdminStateSetting } from './pages/AdminStateSetting'
import { IdologySettings } from './pages/IdologySettings'
import { MyAdminUser } from './pages/MyAdminUser'
import { MyAdminNotarization } from './pages/MyAdminNotarization'

import { checkAuth } from './modules/Auth/_redux/authCrud'
import { ChatBot } from './components/chatBot/chatBot'

export default function BasePage() {
    const dispatch = useDispatch()
    const location = useLocation()
    const { auth } = useSelector((state) => state)
    const headers = {
        Authorization: `Bearer ${auth.authToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
    }

    useEffect(() => {
        const script = document.createElement("script");
        script.id = "cyberizeitChat"
        script.src = "https://rumbletalk.com/client/?mncE9riW";
        script.async = true;

        document.body.appendChild(script);
    }, [])

    useEffect(() => {
        // if not auth verified than logout user....
        if( location.pathname != "/id-proofing/" ) {
            checkAuth(auth.authToken)
                .then((res) => { })
                .catch((error) => {
                    window.location.href = '#/logout'
            })
        }

        if (auth.user.Role.code === 'user') {
            dispatch(actions.fetchNotaries(headers))
            dispatch(actions.fetchPrimaryNotary(headers))
        }

        if (auth.user.Role.code !== 'admin') {
            // dispatch(actions.fetchDefaultPaymentCard({ userID: auth.user.id }, headers));
            dispatch(
                actions.fetchPaymentCards(
                    { userID: auth.user.id, limit: -1 },
                    headers
                )
            )
        }
        dispatch(actions.fetchPaymentDetails(headers))
    }, [dispatch])

    return (
        <>
            <ChatBot showDiv={(["/jobboard", "/myadmin"].includes(location.pathname))} />
            <Suspense fallback={<LayoutSplashScreen />}>
                <Switch>
                    {auth.user.Role.code === 'admin' &&
                        '/dashboard' === location.pathname ? (
                        <Redirect exact from='/dashboard' to='/myadmin' />
                    ) : (
                        <Redirect exact from='/' to='/dashboard' />
                    )}

                    {
                        auth.user.Role.code === "notary" && 
                        auth.user.notaryProfile.isCancelled &&
                        ('/journals' !== location.pathname && '/user-profile' !== location.pathname) && (
                            <Redirect exact to='/journals' />
                        )
                    }

                    <ContentRoute path='/dashboard' component={DashboardPage} />
                    <ContentRoute path='/journals' component={JournalsPage} />
                    <ContentRoute path='/myclients' component={MyClientPage} />
                    <ContentRoute path='/witnessing' component={WitnessingPage} />
                    <ContentRoute
                        path='/choosewitness'
                        component={MyChooseWitness}
                    />
                    <ContentRoute path='/signwitness' component={AdobeSignIframe} />
                    <ContentRoute
                        path='/new-Witness-call'
                        component={NewWitnessRequest}
                    />
                    <ContentRoute
                        path='/new-notary-user-request'
                        component={NewNotaryUserRequest}
                    />

                    <ContentRoute path='/myadmin' component={MyAdmin} />
                    <ContentRoute path='/myadminuser' component={MyAdminUser} />
                    <ContentRoute
                        path='/MyAdminNotarization'
                        component={MyAdminNotarization}
                    />
                    <ContentRoute
                        path='/AdminStateSetting'
                        component={AdminStateSetting}
                    />
                    <ContentRoute
                        path='/IdologySettings'
                        component={IdologySettings}
                    />

                    <ContentRoute path='/jobboard' component={Myjobboard} />
                    <ContentRoute path='/mynotaries' component={MyNotaries} />
                    <ContentRoute
                        path='/deletedjournals'
                        component={DeletedJournals}
                    />

                    <ContentRoute path='/new-template' component={TemplatePage} />
                    <ContentRoute
                        path='/mypayments'
                        component={BankAccountListing}
                    />
                    <ContentRoute
                        path='/payments'
                        component={
                            auth.user.Role.code === 'user'
                                ? PaymentPage
                                : DashboardPage
                        }
                    />
                    <ContentRoute
                        path='/new-notarization'
                        component={
                            auth.user.Role.code === 'notary'
                                ? NewNotarizationNotary
                                : NewNotarization
                        }
                    />
                    <ContentRoute
                        path='/new-notarizationPS'
                        component={NewNotarizationPS}
                    />
                    <ContentRoute
                        path='/edit-notarization'
                        component={
                            auth.user.Role.code === 'notary'
                                ? NewNotarizationNotary
                                : NewNotarization
                        }
                    />
                    <ContentRoute path='/my-cards' component={CardListing} />
                    <ContentRoute
                        path='/my-backaccounts'
                        component={BankAccountListing}
                    />
                    <ContentRoute
                        path='/my-signer-question'
                        component={MyQuestion}
                    />
                    <ContentRoute
                        path='/new-notarization-zero-state'
                        component={ZeroNewNotarization}
                    />
                    <ContentRoute path='/add-card-zero-state' component={AddCard} />
                    <ContentRoute
                        path='/no-notary-user-found-zero-state'
                        component={NoNotaryUserFound}
                    />
                    <ContentRoute
                        path='/no-notary-is-online-zero-state'
                        component={NoNotaryIsOnline}
                    />
                    <ContentRoute
                        path='/no-clients-zero-state'
                        component={NoClients}
                    />
                    <ContentRoute
                        path='/no-bank-added-zero-state'
                        component={NoBankAdded}
                    />
                    <ContentRoute
                        path='/no-witness-zero-state'
                        component={NoWitness}
                    />
                    <ContentRoute
                        path='/searchPrimaryNotary'
                        component={SearchNotaries}
                    />
                    <ContentRoute
                        path='/chooseNotaries'
                        component={OnlineSearchNotaries}
                    />
                    <ContentRoute
                        path='/chooseMyNotaries'
                        component={MySearchNotaries}
                    />
                    <ContentRoute
                        path='/searchWitnesses'
                        component={OnlineSearchNotaries}
                    />
                    <ContentRoute path='/audit-report' component={AuditReport} />
                    <ContentRoute
                        path='/payment-details-popup'
                        component={CustomModalForJoinZoomLink}
                    />
                    <ContentRoute path='/success-popup' component={SuccessPopup} />
                    <ContentRoute
                        path='/transactionHistory'
                        component={TransactionHistory}
                    />

                    <ContentRoute path='/id-proofing' component={IDProofingPage} />
                    <ContentRoute path='/ps-profile' component={PSProfile} />
                    <Route path='/user-profile' component={NotaryProfile} />
                    <Redirect to='error/error-v1' />
                </Switch>
            </Suspense>
        </>
    )
}
