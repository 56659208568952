import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import * as actions from "../../_redux/notarization_redux/notarizationActions";
import DeleteIcon from "../../../media/Delete Icon@3x.png";
import { Modal } from "react-bootstrap";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Tooltip } from "@material-ui/core";

export function ContactSupport(props) {
  const { auth, notarization } = useSelector(state => state);
  let journalId = props.journalID;

  return (
    <>
      <Card style={{ width: "100%", height: "108px" }} variant="outlined">
        <CardContent>Contact Support</CardContent>
        <CardActions>
          <div className="text-center">
            <a href="tel:6147415515">614-741-5515</a> <br />
            <a href="mailto:support@cyberizeit.com">support@cyberizeit.com</a>
          </div>
        </CardActions>
      </Card>
    </>
  );
}
