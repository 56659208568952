import axios from "axios";
import React, { useEffect, useState } from "react";
import { PaymentStatisticsCard } from "../../components/bankaccount_listing/PaymentStatisticsCard";
import { RequestPayout } from "../../components/bankaccount_listing/RequestPayout";
import { PaymentTransferCard } from "../../components/bankaccount_listing/PaymentTransferCard";
import { PaymentAmountCard } from "../../components/bankaccount_listing/PaymentAmountCard";
import { PayoutInfo } from "../../components/bankaccount_listing/PayoutInfo";
import { PaymentPending } from "./PaymentPending";
import { useSelector } from "react-redux";
export function PaymentStatistics(props) {
  const bankdetails = props.bankDetails;
  const { auth } = useSelector(state => state);

  return (
    <>
      <div class="flex-container">
        <div className="card_blue">
          <PaymentStatisticsCard
            type="totalEarned"
            heading="Total Earned"
            totalEarned={bankdetails.total / 100}
          />
        </div>
        <div className="card_orange">
          <PaymentTransferCard
            type="totalTransfer"
            heading="Transferred"
            transferred={bankdetails.transferred / 100}
          />
        </div>
        <div className="card_green">
          <PaymentAmountCard
            type="balance"
            heading="Balance"
            amount={bankdetails.available[0].amount / 100}
          />
        </div>
        <div className="card_red">
          <PaymentPending
            type="pending"
            heading="Pending"
            amount={bankdetails.pending[0].amount / 100}
          />
        </div>
        <div className="card_request_payout">
          <RequestPayout type="Request_Payout" heading="Request Payout" />
        </div>
      </div>
      {bankdetails.available && bankdetails.available[0].amount > 0 ? (
        <div>
          <PayoutInfo type="date" />
        </div>
      ) : (
        ""
      )}

      {/* // <div className="card-body pt-0 pb-3">
		// 	<div className="tab-content">
		// 		<div className="table-responsive">
		// 			<table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
		// 				<tbody>
		// 					<tr>
		// 						<td className="card_blue">
		// 							<PaymentStatisticsCard type="totalEarned" heading="Total Earned" />
		// 						</td>
		// 						<td className="card_orange">
		// 							<PaymentStatisticsCard type="totalTransfer" heading="Transferred" />
		// 						</td>
		// 						<td className="card_green">
		// 							<PaymentStatisticsCard type="balance" heading="Balance" />
		// 						</td>
		// 						<td>
		// 							<PayoutInfo type="date" />
		// 						</td>
		// 					</tr>

		// 				</tbody>
		// 			</table>
		// 		</div>
		// 	</div>
		// </div> */}
    </>
  );
}
