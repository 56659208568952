import React from "react";
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";

export function NewWitnessRequest() {
  const [showModal, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button variant="secondary" onClick={handleShow}>
        Accept
      </Button>
      <Modal show={showModal} onHide={handleClose} dialogClassName="my-modal">
        <Modal.Header closeButton>
          <Modal.Title>New Witness Request</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-main">
          <div className="modal-body-one">
            You have a new witness request from the notary Alex Show
            <div class="add_new">
              <div className="main-category">
                <span className="category">Additional Details about this Notarization:</span>
              </div>
              <div class="flex_class">
                <span>Document: Bank state handling </span>
                <span> Notarization Type: Now</span>
                <span>No. of signers: 05</span>
              </div>
            </div>
            <div class="flex_class flex_class-alt">
              <h6>Accept the Notarization in</h6>
              <h5>1 minute and 59 sec.</h5>
            </div>
          </div>
          <div className="modal-body-two">
            <Button
              variant="secondary"
              className="acceptBtn"
              onClick={handleClose}
            >
              Accept
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              className="rejectBtn"
              onClick={handleClose}
            >
              Reject
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
