import { Divider, TextField, makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Button, Modal, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../components/_redux/notarization_redux/notarizationActions'
import ShowNotification from '../../components/new_notarization/ShowNotification'
import { CancelNotarization } from '../signwitnesss/table/CancelNotarization'
import { Link } from 'react-router-dom'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment';

export const useStyles = makeStyles(() => ({
    draft: {
        minWidth: '110px',
        backgroundColor: '#524440',
        color: '#fff',
        textAlign: 'center',
        borderRadius: '5px',
        padding: '9px 5px',
    },
    progress: {
        minWidth: '110px',
        backgroundColor: '#eee',
        color: '#000',
        textAlign: 'center',
        borderRadius: '5px',
        padding: '9px 5px',
    },
    completed: {
        minWidth: '110px',
        backgroundColor: '#bef2de',
        color: '#000',
        textAlign: 'center',
        borderRadius: '5px',
        padding: '9px 5px',
    },
    scheduled: {
        minWidth: '110px',
        backgroundColor: '#ff9a00',
        color: '#fff',
        textAlign: 'center',
        borderRadius: '5px',
        padding: '9px 5px',
    },
    initiated: {
        minWidth: '110px',
        backgroundColor: '#ffff83',
        color: '#000',
        textAlign: 'center',
        borderRadius: '5px',
        padding: '9px 5px',
    },
    pending: {
        minWidth: '110px',
        backgroundColor: '#4ABD54',
        color: '#fff',
        textAlign: 'center',
        borderRadius: '5px',
        padding: '9px 5px',
    },
    paid: {
        minWidth: '110px',
        backgroundColor: '#4ABD54',
        color: '#fff',
        textAlign: 'center',
        borderRadius: '5px',
        padding: '9px 5px',
    },
    failed: {
        minWidth: '110px',
        backgroundColor: '#EA001B',
        color: '#fff',
        textAlign: 'center',
        borderRadius: '5px',
        padding: '9px 5px',
    },
    alignCenter: {
        textAlign: 'center',
    },
    buttonStyle: {
        backgroundColor: '#1840d2',
        borderColor: '#1840d2',
        minWidth: '165px',
        float: 'right',
        marginBottom: '10px',
    },
}))
export default function JournalSignerModal({
    onHide,
    id,
    resumeNotarization,
    addWitness,
    downloadDocument,
    auditReport,
    downloadJournal
}) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { socket, auth, notarization } = useSelector((state) => state)
    const [signerLinks, setsignerLinks] = useState({})
    const [copyLinkURL, setcopyLinkURL] = useState('')
    const [bypassReason, setbypassReason] = useState('')
    const [rescehduleDate, setrescehduleDate] = useState(new Date())
    const [dateTime, setDateTime] = React.useState(new Date().toISOString())
    const [RescheduleMode, setRescheduleMode] = useState(false)
    const [isbypasskba, setisbypasskba] = useState(0)
    const [KBASettingscheck, setKBASettingscheck] = useState(false)
    const [KBASettingscheckdispatch, setKBASettingscheckdispatch] = useState(
        false
    )
    const [issingleKBA, setissingleKBA] = useState(false)
    const {
        allJournals,
        paymentDetails,
        SingleJournalObj,
        KBASettings,
        clients,
        jitsiMeetingLinks,
        top5Journals,
    } = notarization
    const [data, setdata] = useState(null)
    const [notification, setNotification] = useState({
        show: false,
        msg: '',
        variant: '',
    })
    const [singleByPass, setsingleByPass] = useState(false)
    const [
        openTransferConfirmationDialog,
        setOpenTransferConfirmationDialog,
    ] = useState(false)
    const [
        closeTransferConfirmationDialog,
        setCloseTransferConfirmationDialog,
    ] = useState(false)

    const [updatedLinkInfo, setUpdatedLinkInfo] = useState(null)

    const headers = {
        Authorization: `Bearer ${auth.authToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
    }

    const rescheduleModeStart = () => {
        setRescheduleMode(true)
    }

    const dateTimeHandler = (e) => {
        setrescehduleDate(new Date(e))
    }

    useEffect(() => {
        // const journals = allJournals ? allJournals : top5Journals;
        // let datas = journals !== null
        //     ? journals.rows.filter((data) => data.id === id)
        //     : null
        // datas = datas[0]
        // setdata(datas)
        let data = SingleJournalObj
        setdata(data)

        if (!KBASettingscheckdispatch) {
            dispatch(actions.fetchKBASettings(headers))

            setKBASettingscheckdispatch(true)
        }

        if (KBASettings && KBASettings.data && !KBASettingscheck) {
            setKBASettingscheck(true)
        }
        if (data && data.Signers) {
            for (let isign = 0; isign < data.Signers.length; isign++) {
                if (data.Signers[isign].bypassKBA) setissingleKBA(true)
            }
        }
        //setissingleKBA
    }, [SingleJournalObj, KBASettings])

    useEffect(() => {
        dispatch(actions.getJournalbyID(id, headers))
        dispatch(actions.getJitsiMeetingLinks(id, headers))
    }, [
        socket.kbaVerificationSingle,
        socket.kbaVerificationRejectedNotary,
        socket.kbaVerificationRejected,
        socket.signerPageAttemptsExceeded
    ])

    const checkinPrimary = (userId) => {
        let isclient = false
        clients.rows.map((client, index) => {
            if (userId == client.id) isclient = true
        })

        return isclient
    }

    const prepareTime = (dt) => {
        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ]
        let date = new Date(dt)
        let time = formatAMPM(date)
        let mon = months[date.getMonth()]
        let curDate = date.getDate()
        let year = date.getFullYear()
        let timezone = seconds_with_leading_zeros(date)
        return `${mon} ${curDate}, ${year} at ${time} (${timezone})`
    }

    const formatAMPM = (date) => {
        var hours = date.getHours()
        var minutes = date.getMinutes()
        var ampm = hours >= 12 ? 'PM' : 'AM'
        hours = hours % 12
        hours = hours ? hours : 12
        minutes = minutes < 10 ? '0' + minutes : minutes
        var strTime = hours + ':' + minutes + ' ' + ampm
        return strTime
    }

    const seconds_with_leading_zeros = (dt) => {
        return /\((.*)\)/.exec(dt.toString())[1]
    }

    const getPricingStructure = () => {
        // Seals Calculation
        let objcalc = []

        if (data) {
            let sealPayment = 0
            if (
                data &&
                data.Payments &&
                data.Payments[0] &&
                data.Payments[0].userId == data.notaryId
            )
                sealPayment = data.sealCount ? data.sealCount - 1 : 0
            else sealPayment = data.sealCount ? (data.sealCount - 1)*10+25 : 0

            objcalc.push(<p>Seal Payment: ${sealPayment}</p>)

            let withnessamount = data.Witnesses.length * 5
            objcalc.push(<p>Witness Amount: ${withnessamount}</p>)

            let signeramount =
                data.Signers.length < 5 ? 0 : (data.Signers.length - 4) * 3
            objcalc.push(<p>Signers Amount: ${signeramount}</p>)
        }

        return objcalc
    }

    const bypassKBAMode = (id) => {
        setisbypasskba(id)
    }

    const bypassKBAfinalize = () => {
        if (!bypassReason) {
            setNotification({
                show: true,
                msg: 'Please provide some reason !',
                variant: 'error',
            })
            return
        }
        let body = {
            signerId: isbypasskba,
            bypassReason: bypassReason,
            journalId: data.id,
        }

        const headers = {
            Authorization: `Bearer ${auth.authToken}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }

        let journalID = data.id

        dispatch(actions.bypassKBA(body, headers)).then((data) => {
            setisbypasskba(0)

            dispatch(actions.reloadcall(journalID))
        })
    }
    const tranferNotarization = () => {
        const headers = {
            Authorization: `Bearer ${auth.authToken}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }

        let notarizationId = data.id

        dispatch(actions.tranferNotarization(notarizationId, headers)).then(
            (data) => {
                window.location.reload()
            }
        )
    }
    const setReason = (e) => {
        setbypassReason(e.target.value)
    }

    const getCurrentLink = (obj) => {
        let temporaryLink = obj.temporaryLink
        if (
            socket.idologyLink != null &&
            socket.idologyLink['signerId'] == obj.id
        ) {
            if (!signerLinks[obj.id]) {
                let currentsigners = signerLinks
                currentsigners[obj.id] = socket.idologyLink['link']
                setsignerLinks(currentsigners)
            }

            temporaryLink = socket.idologyLink['link']
        } else if (
            socket.linkUpdated != null &&
            socket.linkUpdated['signerId'] == obj.id
        ) {
            if (!signerLinks[obj.id]) {
                let currentsigners = signerLinks
                currentsigners[obj.id] = socket.linkUpdated['link']
                setsignerLinks(currentsigners)
            }

            temporaryLink = socket.linkUpdated['link']
        } else if (signerLinks[obj.id]) temporaryLink = signerLinks[obj.id]

        return temporaryLink
    }

    const proceedNotarization = (journalID) => {
        window.location.href = '#/signwitness/?id=' + journalID
    }

    function GetFinalPayment(seals, witnessCount) {
        let total = 0
        let notaryFee = 0
        let platformFee = 0
        let witnessFee = 0
        total += witnessFee

        if (paymentDetails) {
            total =
                seals === 0
                    ? 0
                    : paymentDetails.sealFirstFee +
                    (seals - 1) * paymentDetails.sealSecFee
            notaryFee =
                seals === 0
                    ? 0
                    : paymentDetails.notaryFeePerFirstSeal +
                    (seals - 1) * paymentDetails.notaryFeePerSecSeal
            platformFee =
                seals === 0
                    ? 0
                    : paymentDetails.platformPerFirstSeal +
                    (seals - 1) * paymentDetails.platformPerSecSeal
            witnessFee =
                witnessCount === 0
                    ? 0
                    : witnessCount * paymentDetails.witnessFee
            total += witnessFee
        }

        return {
            total: total,
            notaryFee: notaryFee,
            platformFee: platformFee,
            witnessFee: witnessFee,
        }
    }

    let paymentBreakDown = null
    let filteredWitnesses = []
    if (data !== undefined && data != null) {
        filteredWitnesses =
            data.Witnesses.length > 0
                ? data.Witnesses.filter((el) => el.status !== 'rejected')
                : []
        paymentBreakDown = GetFinalPayment(
            data.sealCount !== null ? data.sealCount : 0,
            filteredWitnesses.length
        )
    }

    const joinMeeting = (meetingURL) => {
        window.open(meetingURL, '_blank', 'noopener,noreferrer');
    }

    let rejectedDate = null
    let nowDate = Date.parse(new Date())
    if (data && data.rejectedUntil !== null) {
        rejectedDate = Date.parse(data.rejectedUntil)
    }

    let totalPrice = '--'

    if (data !== undefined && data != null && data.Payments.length > 0) {
        totalPrice = 0
        for (var i = 0; i < data.Payments.length; i++) {
            totalPrice += parseFloat(data.Payments[i].amount) / 100
        }
        totalPrice = '$' + totalPrice.toFixed(2)

        //   totalPrice += paymentBreakDown.total;
        //  totalPrice = '$' + totalPrice.toFixed(2);
    }

    const getRecordingName = (recordingType) => {
        let recordingTypestr = recordingType.replace(/_/g, ' ')
        return recordingTypestr
            .toLowerCase()
            .replace(/\b[a-z]/g, function (letter) {
                return letter.toUpperCase()
            })
    }

    const isBiometricEnabled = () => {
        return auth?.user?.biometricSettings && auth?.user?.biometricSettings?.biometricEnabled ? auth?.user?.biometricSettings?.biometricEnabled : false
    }

    const isFailedSigner = () => {
        let isFailedSigner = false
        data.Signers.map((signer) => {
            if ('rejected' == signer.status) {
                isFailedSigner = true
            }
        })
        return isFailedSigner
    }

    const handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') return
        setNotification({ show: false, msg: '', variant: '' })
    }

    const resumeNotarizationselectNotary = (id) => {
        window.location.href = '/#/new-notarization?preselectNotarization=' + id
    }

    const acceptscheduleNotification = () => {
        callscheduleNotification('accepted')
    }

    const reschedulescheduleNotification = () => {
        callscheduleNotification('rescheduled')
    }

    const cancelscheduleNotification = () => {
        callscheduleNotification('rejected')
    }

    const callscheduleNotification = async (acceptbool) => {
        const datastr = {
            notaryResponse: acceptbool,
        }

        if ('rescheduled' == acceptbool)
            datastr['scheduleDate'] = new Date(rescehduleDate).toISOString()

        if (socket.scheduleNotificationResponse) {
            dispatch(actions.acceptSchedule(headers, datastr, data.id)).then(
                (scheduleresponse) => {
                    if (
                        scheduleresponse &&
                        scheduleresponse.data &&
                        scheduleresponse.data.status
                    ) {
                        setTimeout(function() {
                            window.location.reload()
                        }, 3000)
                    }
                }
            )
        } else {
            dispatch(actions.acceptSchedule(headers, datastr, data.id)).then(
                (scheduleresponse) => {
                    if (
                        scheduleresponse &&
                        scheduleresponse.data &&
                        scheduleresponse.data.status
                    ) {
                        setTimeout(function() {
                            window.location.reload()
                        }, 3000)
                    }
                }
            )
        }
    }

    return (
        <>
            {notification.show && (
                <ShowNotification
                    show={notification.show}
                    msg={notification.msg}
                    variant={notification.variant}
                    close={handleCloseNotification}
                />
            )}

            <Modal.Body className='overlay overlay-block cursor-default'>
                {data !== null && data !== undefined && (
                    <>
                        <Table responsive borderless={true}>
                            <thead>
                                <tr>
                                    <th>DOCUMENT&nbsp;TITLE</th>
                                    <th className='text-center'>
                                        START&nbsp;DATE&nbsp;&&nbsp;TIME
                                    </th>
                                    <th className='text-center'>
                                        END&nbsp;DATE&nbsp;&&nbsp;TIME
                                    </th>
                                    <th className='text-center'>STATUS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {(data.status == 'rescheduled' ||
                                        data.status == 'scheduled') &&
                                        auth.user.Role.code === 'notary' ? (
                                        <>
                                            {data.journalDocs ? (
                                                <td>
                                                    {data.journalDocs.map(
                                                        (el) => (
                                                            <p
                                                                className='wrapWord'
                                                                key={el.id}>
                                                                <a
                                                                    href={
                                                                        el.File
                                                                            .baseUrl +
                                                                        '/' +
                                                                        el.File
                                                                            .path
                                                                    }
                                                                    target='_blank'>
                                                                    {el.File.filename
                                                                        .substring(
                                                                            14
                                                                        )
                                                                        .split(
                                                                            ' '
                                                                        )
                                                                        .map(
                                                                            (
                                                                                el
                                                                            ) => (
                                                                                <>
                                                                                    {
                                                                                        el
                                                                                    }
                                                                                    &nbsp;
                                                                                </>
                                                                            )
                                                                        )}
                                                                </a>{' '}
                                                                <span>
                                                                    {el.docType ? (
                                                                        <>
                                                                            (
                                                                            {
                                                                                el.docType
                                                                            }
                                                                            ){' '}
                                                                        </>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </span>
                                                            </p>
                                                        )
                                                    )}
                                                </td>
                                            ) : (
                                                <td>
                                                    {data.documents.map(
                                                        (el) => (
                                                            <p
                                                                className='wrapWord'
                                                                key={el.id}>
                                                                {el.filename
                                                                    .substring(
                                                                        14
                                                                    )
                                                                    .split(' ')
                                                                    .map(
                                                                        (
                                                                            el
                                                                        ) => (
                                                                            <>
                                                                                {
                                                                                    el
                                                                                }
                                                                                &nbsp;
                                                                            </>
                                                                        )
                                                                    )}
                                                            </p>
                                                        )
                                                    )}
                                                </td>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {data.journalDocs ? (
                                                <td>
                                                    {data.journalDocs.map(
                                                        (el) => (
                                                            <p
                                                                className='wrapWord'
                                                                key={el.id}>
                                                                {el.File.filename
                                                                    .substring(
                                                                        14
                                                                    )
                                                                    .split(' ')
                                                                    .map(
                                                                        (
                                                                            el
                                                                        ) => (
                                                                            <>
                                                                                {
                                                                                    el
                                                                                }
                                                                                &nbsp;
                                                                            </>
                                                                        )
                                                                    )}{' '}
                                                                <span>
                                                                    {el.docType ? (
                                                                        <>
                                                                            (
                                                                            {
                                                                                el.docType
                                                                            }
                                                                            )
                                                                        </>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </span>
                                                            </p>
                                                        )
                                                    )}
                                                </td>
                                            ) : (
                                                <td>
                                                    {data.documents.map(
                                                        (el) => (
                                                            <p
                                                                className='wrapWord'
                                                                key={el.id}>
                                                                {el.filename
                                                                    .substring(
                                                                        14
                                                                    )
                                                                    .split(' ')
                                                                    .map(
                                                                        (
                                                                            el
                                                                        ) => (
                                                                            <>
                                                                                {
                                                                                    el
                                                                                }
                                                                                &nbsp;
                                                                            </>
                                                                        )
                                                                    )}
                                                            </p>
                                                        )
                                                    )}
                                                </td>
                                            )}
                                        </>
                                    )}
                                    <td className='text-center'>
                                        {data.scheduledAt !== null ? (
                                            <p className='mb-0'>
                                                {prepareTime(data.scheduledAt)}
                                            </p>
                                        ) : (
                                            '-'
                                        )}
                                    </td>
                                    <td className='text-center'>
                                        {data.endDate !== null ? (
                                            <p className='mb-0'>
                                                {prepareTime(data.endDate)}
                                            </p>
                                        ) : (
                                            '-'
                                        )}
                                    </td>
                                    <td>
                                        <p className={classes[data.status]}>
                                            {data.status === 'progress' ||
                                                data.status === 'draft'
                                                ? data.subStatus === null || data.subStatus === ""
                                                    ? data.status
                                                    : data.subStatus ===
                                                        'rejected'
                                                        ? 'Failed'
                                                        : data.subStatus
                                                : data.status
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                data.status.slice(1)}
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <Divider />
                        <div>
                            <div className="comments-div-padding">
                                <span className="comments-heading">
                                    COMMENTS
                                </span>
                            </div>
                            <div className="comments-div-padding">
                                <span className="word-break">
                                    {data.comments}
                                </span>
                            </div>

                        </div>
                        <Divider />
                        
                        <Table responsive borderless={true}>
                            <thead>
                                <tr>
                                    <th>SIGNER&nbsp;NAME</th>
                                    {auth &&
                                        auth.user &&
                                        auth.user.Role.code === 'notary' && (
                                            <th>SIGNER&nbsp;IP</th>
                                        )}
                                    <th>REASON</th>
                                    <th>STATUS</th>

                                </tr>
                            </thead>
                            {/* closing this temporarily to check if its really necessary or not... */}
                            {1 === 2 && SingleJournalObj != null ? (
                                <tbody>
                                    {SingleJournalObj.Signers.map((el) => {
                                        return (
                                            <>
                                                {' '}
                                                <tr key={el.id}>
                                                    <td>
                                                        {el.firstName
                                                            .split(' ')
                                                            .map((el) => (
                                                                <>{el}&nbsp;</>
                                                            ))}
                                                        {el.lastName
                                                            .split(' ')
                                                            .map((el) => (
                                                                <>{el}&nbsp;</>
                                                            ))}
                                                    </td>
                                                    {auth && auth.user && (
                                                        <td>
                                                            {el.IP !== null
                                                                ? el.IP
                                                                : '-'}
                                                        </td>
                                                    )}
                                                    <td>
                                                        {el.bypassKBA
                                                            ? el.bypassReason
                                                            : '-'}
                                                    </td>
                                                    <td>
                                                        {el.status
                                                            ? 'rejected' ==
                                                                el.status
                                                                ? 'failed'
                                                                : el.status
                                                            : '-'}
                                                    </td>
                                                </tr>
                                                {isbypasskba == el.id &&
                                                    !el.bypassKBA ? (
                                                    <tr>
                                                        <td colspan='4'>
                                                            <div>
                                                                <textarea
                                                                    name='reason'
                                                                    onChange={
                                                                        setReason
                                                                    }
                                                                    style={{
                                                                        width:
                                                                            '100%',
                                                                        height:
                                                                            '200px',
                                                                    }}></textarea>
                                                                <Button
                                                                    variant='secondary'
                                                                    onClick={() =>
                                                                        bypassKBAfinalize(
                                                                            el.id
                                                                        )
                                                                    }>
                                                                    Submit
                                                                </Button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    ''
                                                )}
                                            </>
                                        )
                                    })}
                                </tbody>
                            ) : (
                                <tbody>
                                    {data.Signers.map((el, index) => {
                                        return (
                                            <>
                                                {' '}
                                                <tr key={el.id}>
                                                    <td>
                                                        {el.firstName
                                                            .split(' ')
                                                            .map((el) => (
                                                                <>{el}&nbsp;</>
                                                            ))}
                                                        {el.lastName
                                                            .split(' ')
                                                            .map((el) => (
                                                                <>{el}&nbsp;</>
                                                            ))}
                                                    </td>
                                                    {auth &&
                                                        auth.user &&
                                                        auth.user.Role.code ===
                                                        'notary' && (
                                                            <td>
                                                                {el.IP !== null
                                                                    ? el.IP
                                                                    : '-'}
                                                            </td>
                                                        )}
                                                    <td>
                                                        {el.bypassKBA ? (
                                                            <p className='wrapWord'>
                                                                {
                                                                    el.bypassReason
                                                                }
                                                            </p>
                                                        ) : (
                                                            '-'
                                                        )}
                                                    </td>
                                                    <td>
                                                        {el.status
                                                            ? 'rejected' ==
                                                                el.status
                                                                ? 'failed'
                                                                : el.status
                                                            : '-'}
                                                    </td>
                                                    {
                                                        ! index &&
                                                        data.status === "progress" &&
                                                        data.subStatus === 'Authenticating Participants' &&
                                                        el.status === "pending" &&
                                                        <td>
                                                            <div className="d-flex justify-content-end">
                                                                <Link
                                                                    target={'_blank'}
                                                                    to={{
                                                                        pathname: `/id-proofing/?id=${el.id}`,
                                                                    }}>
                                                                    <Button variant='success'>
                                                                        Start ID Proofing
                                                                    </Button>
                                                                </Link>
                                                            </div>
                                                        </td>
                                                    }
                                                </tr>
                                            </>
                                        )
                                    })}
                                </tbody>
                            )}
                        </Table>
                        {auth &&
                            auth.user &&
                            auth.user.Role.code === 'notary' &&
                            filteredWitnesses.length > 0 && (
                                <>
                                    <Divider />
                                    <Table responsive borderless={true}>
                                        <thead>
                                            <tr>
                                                <th>WITNESS&nbsp;NAME</th>
                                                <th>WITNESS&nbsp;EMAIL</th>
                                                {/* <th>WITNESS&nbsp;IP</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredWitnesses.map((el) => {
                                                return (
                                                    <tr key={el.id}>
                                                        <td>
                                                            {el.User.firstName
                                                                .split(' ')
                                                                .map((el) => (
                                                                    <>
                                                                        {el}
                                                                        &nbsp;
                                                                    </>
                                                                ))}
                                                            {el.User.lastName
                                                                .split(' ')
                                                                .map((el) => (
                                                                    <>
                                                                        {el}
                                                                        &nbsp;
                                                                    </>
                                                                ))}
                                                        </td>
                                                        <td>{el.User.email}</td>
                                                        {/* <td>{el.IP === "" ? "-" : el.IP}</td> */}
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </>
                            )}
                        <Divider />
                        <Table responsive borderless={true}>
                            <thead>
                                <tr>
                                    <th>PRICE</th>
                                    <th>NOTARY&nbsp;</th>
                                    <th>SESSION&nbsp;VIDEO</th>
                                    {data.status === 'scheduled' && (
                                        <th>SCHEDULED</th>
                                    )}
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {data.Payments.length > 0
                                            ? data.Payments.map((el, i) => (
                                                <>
                                                    {data &&
                                                        data.Payments &&
                                                        data.Payments[0] &&
                                                        data.Payments[0].userId ==
                                                        auth.user.id ? (
                                                        el.title ==
                                                            'Final Payment' ? (
                                                            getPricingStructure()
                                                        ) : (
                                                            <p>
                                                                {el.title}: $
                                                                {el.amount /
                                                                    100}
                                                            </p>
                                                        )
                                                    ) : (
                                                        '-'
                                                    )}
                                                </>
                                            ))
                                            : '-'}
                                    </td>
                                    <td>
                                        <div>
                                            {data.notary !== null
                                                ? data.notary.firstName
                                                    .split(' ')
                                                    .map((el) => (
                                                        <>{el}&nbsp;</>
                                                    ))
                                                : ''}
                                            {data.notary !== null
                                                ? data.notary.lastName
                                                    .split(' ')
                                                    .map((el) => (
                                                        <>{el}&nbsp;</>
                                                    ))
                                                : ''}

                                            {auth &&
                                                auth.user &&
                                                auth.user.Role &&
                                                data.notary !== null ? (
                                                <span>
                                                    <br />{' '}
                                                    <strong>Email: </strong>
                                                    {data.notary.email}
                                                    <br />{' '}
                                                    <strong>Phone: </strong>
                                                    {data.notary.phone}
                                                </span>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                        <div>
                                            <br />
                                            <span style={{ fontSize: '1rem' }}>
                                                <strong>CLIENT</strong>
                                            </span>
                                            <br />
                                            {auth &&
                                                auth.user &&
                                                auth.user.Role && (
                                                    <span>
                                                        <br />
                                                        <strong>Name: </strong>
                                                        <br />
                                                        {data.customer.firstName
                                                            ? data.customer.firstName
                                                                .split(' ')
                                                                .map((el) => (
                                                                    <>
                                                                        {el}
                                                                        &nbsp;
                                                                    </>
                                                                ))
                                                            : ''}
                                                        {data.customer.lastName
                                                            ? data.customer.lastName
                                                                .split(' ')
                                                                .map((el) => (
                                                                    <>
                                                                        {el}
                                                                        &nbsp;
                                                                    </>
                                                                ))
                                                            : ''}
                                                        <br />{' '}
                                                        <strong>Email: </strong>
                                                        {data.customer.email}
                                                        <br />{' '}
                                                        <strong>Phone: </strong>
                                                        {data.customer.phone}
                                                    </span>
                                                )}
                                        </div>
                                    </td>
                                    <td>{jitsiMeetingLinks?.length > 0
                                        ? jitsiMeetingLinks.map(
                                            (el, i) => (
                                                
                                                <>
                                                    <a
                                                        href={
                                                            el.s3link
                                                        }
                                                        target='_blank'
                                                        >
                                                        Part - {++i}
                                                    </a>
                                                    <br />
                                                </>
                                            )
                                        )
                                        : '-'}
                                    </td>
                                    {data.status === 'scheduled' && (
                                        <td>
                                            {data.scheduledAt !== null
                                                ? prepareTime(data.scheduledAt)
                                                : '-'}
                                        </td>
                                    )}

                                    <td
                                        className='pl-0 pr-0'
                                        style={{ float: 'right' }}>
                                        {auth &&
                                            auth.user &&
                                            auth.user.Role &&
                                            auth.user.Role.code === 'notary' ? (
                                            ''
                                        ) : (
                                            <span>
                                                {(((data.status === 'draft' ||
                                                    data.status === 'paid' ||
                                                    data.status ===
                                                    'pending') &&
                                                    data.rejectedUntil ===
                                                    null) ||
                                                    (rejectedDate !== null &&
                                                        nowDate >
                                                        rejectedDate)) && (
                                                        <>
                                                            <Button
                                                                className={`mr-0 ml-0 pl-0 pr-0 ${classes.buttonStyle}`}
                                                                onClick={() =>
                                                                    resumeNotarization(
                                                                        data.id
                                                                    )
                                                                }>
                                                                Start Notarization
                                                            </Button>
                                                            <br />
                                                        </>
                                                    )}
                                            </span>
                                        )}

                                        {((auth &&
                                            auth.user &&
                                            auth.user.Role.code &&
                                            data.status === 'scheduled') ||
                                            data.status === 'rescheduled') && (
                                                <>
                                                    <Button
                                                        className={`mr-0 ml-0 pl-0 pr-0  mr-3 ${classes.buttonStyle}`}
                                                        onClick={() =>
                                                            setRescheduleMode(true)
                                                        }>
                                                        Reschedule
                                                    </Button>
                                                    <br />
                                                </>
                                            )}
                                        {auth &&
                                        auth.user.Role &&
                                        auth.user.Role.code !== 'notary' &&
                                        data.subStatus === 'by notary' &&
                                        (data.status === 'scheduled' ||
                                            data.status ===
                                                'rescheduled') && (
                                            <>
                                                <Button
                                                    className={`mr-0 ml-0 pl-0 pr-0  mr-3 ${classes.buttonStyle}`}
                                                    onClick={() =>
                                                        acceptscheduleNotification()
                                                    }>
                                                    Accept
                                                </Button>
                                                <Button
                                                    className={`mr-0 ml-0 pl-0 pr-0  mr-3 ${classes.buttonStyle}`}
                                                    onClick={() =>
                                                        cancelscheduleNotification()
                                                    }>
                                                    Reject
                                                </Button>
                                            </>
                                        )}
                                        {auth &&
                                            auth.user &&
                                            auth.user.Role &&
                                            auth.user.Role.code === 'notary' ? (
                                            <span>
                                                {data.status === 'draft' &&
                                                    (data.rejectedUntil ===
                                                        null ||
                                                        (rejectedDate !==
                                                            null &&
                                                            nowDate >
                                                            rejectedDate)) && (
                                                        <>
                                                            <Button
                                                                className={`mr-0 ml-0 pl-0 pr-0 ${classes.buttonStyle}`}
                                                                onClick={() =>
                                                                    resumeNotarization(
                                                                        data.id
                                                                    )
                                                                }>
                                                                Start Notarization
                                                            </Button>
                                                            <br />
                                                        </>
                                                    )}
                                            </span>
                                        ) : (
                                            ''
                                        )}

                                        {auth &&
                                            auth.user &&
                                            auth.user.Role &&
                                            data.status === 'progress' &&
                                            data.Meeting &&
                                            data?.Meeting.status === 'started' &&
                                            data.Meeting.jitsiUrl ? (
                                            <>
                                                <Button
                                                    className={`mr-0 ml-0 pl-0 pr-0 ${classes.buttonStyle}`}
                                                    onClick={() =>
                                                        joinMeeting(
                                                            data.Meeting.jitsiUrl
                                                        )
                                                    }>
                                                    Join Jitsi Meeting
                                                </Button>
                                                <br />
                                            </>
                                        ) : (
                                            ''
                                        )}

                                        {auth &&
                                            auth.user &&
                                            auth.user.Role &&
                                            data.status === 'progress' &&
                                            (!data.Meeting  || (data.Meeting && data.Meeting.status != "started"))? (
                                            <span>
                                                <strong>
                                                    The Notary will send a link to join them in meeting shortly, please watch here or access the link in your email. You will need to have your camera and microphone on during this meeting.
                                                </strong>
                                                <br />
                                            </span>
                                        ) : (
                                            ''
                                        )}

                                        {auth &&
                                            auth.user &&
                                            auth.user.Role.code &&
                                            data.status === 'completed' && (
                                                <>
                                                    <Button
                                                        className={`mr-0 ml-0 pl-0 pr-0  mr-3 ${classes.buttonStyle}`}
                                                        onClick={() =>
                                                            downloadDocument(
                                                                data.id
                                                            )
                                                        }>
                                                        Download Document
                                                    </Button>
                                                    <br />
                                                </>
                                            )}

                                        {auth &&
                                            auth.user &&
                                            auth.user.Role.code === 'notary' &&
                                            data.status === 'progress' &&
                                            [
                                                'Accepted',
                                                'Awaiting Witnesses',
                                                'Witnesses Ready',
                                            ].indexOf(data.subStatus) !==
                                            -1 && (
                                                <>
                                                    <Button
                                                        className={`mr-0 ml-0 pl-0 pr-0  mr-3 ${classes.buttonStyle}`}
                                                        onClick={() =>
                                                            proceedNotarization(
                                                                data.id
                                                            )
                                                        }>
                                                        Proceed Notarization
                                                    </Button>
                                                    <br />
                                                </>
                                            )}
                                        {auth &&
                                            auth.user &&
                                            data.agreementId &&
                                            data.status === 'completed' && (
                                                <>
                                                    <Button
                                                        className={`mr-0 ml-0 pl-0 pr-0 mr-3 ${classes.buttonStyle}`}
                                                        onClick={() =>
                                                            auditReport(data.id)
                                                        }>
                                                        Audit Report
                                                    </Button>
                                                    <br />
                                                </>
                                            )}

                                         {auth &&
                                            auth.user &&
                                            auth.user.Role.code === 'notary' &&
                                            data.agreementId &&
                                            data.status === 'completed' && (
                                                <>
                                                    <Button
                                                        className={`mr-0 ml-0 pl-0 pr-0 mr-3 ${classes.buttonStyle}`}
                                                        onClick={() =>
                                                            downloadJournal(data.id)
                                                        }>
                                                        Download Journal
                                                    </Button>
                                                    <br />
                                                </>
                                            )}

                                        {auth &&
                                            auth.user &&
                                            auth.user.Role &&
                                            auth.user.Role.code !== 'notary' &&
                                            (data.subStatus ===
                                                'Authentication Passed' ||
                                                data.subStatus ===
                                                'Notary Rejected' ||
                                                data.subStatus ===
                                                'Awaiting Notary Response') &&
                                            data.status !== 'scheduled' &&
                                            data.status !== 'scheduled-unclaimed' &&
                                            data.status !== 'rescheduled' ? (
                                            <Button
                                                className={`mr-0 ml-0 pl-0 pr-0  mr-3 ${classes.buttonStyle}`}
                                                onClick={() =>
                                                    setRescheduleMode(true)
                                                }>
                                                Reschedule
                                            </Button>
                                        ) : (
                                            ''
                                        )}

                                        {auth &&
                                            auth.user &&
                                            auth.user.Role &&
                                            auth.user.Role.code !== 'notary' &&
                                            (data.subStatus ===
                                                'Authentication Passed' ||
                                                data.subStatus ===
                                                'Awaiting Notary Response') &&
                                            KBASettings != null &&
                                            KBASettings.data &&
                                            KBASettings.data.settings &&
                                            !KBASettings.data.settings
                                                .bypassAllowed &&
                                            !issingleKBA ? (
                                            <Button
                                                className={`mr-0 ml-0 pl-0 pr-0 mr-3 ${classes.buttonStyle}`}
                                                onClick={() =>
                                                    resumeNotarizationselectNotary(
                                                        data.id
                                                    )
                                                }>
                                                Proceed Notarization
                                            </Button>
                                        ) : (
                                            ''
                                        )}
                                    </td>
                                </tr>

                                {RescheduleMode ? (
                                    <tr>
                                        <td colspan='4'>
                                            {' '}
                                            <div class='flex_class flex_class-alt'>
                                                <MuiPickersUtilsProvider utils={MomentUtils }>
                                                    <DateTimePicker 
                                                        label="Date and Time"
                                                        initialFocusedDate={rescehduleDate}
                                                        value={rescehduleDate}
                                                        onChange={dateTimeHandler}
                                                        showTodayButton={true}
                                                        disablePast={true}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                            <div className='modal-body-two'>
                                                <Button
                                                    variant='secondary'
                                                    className='acceptBtn'
                                                    onClick={
                                                        reschedulescheduleNotification
                                                    }>
                                                    Submit
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                ) : (
                                    ''
                                )}
                            </tbody>
                        </Table>
                        <br />
                        <div className='d-flex justify-content-between align-items-center'>
                            {data &&
                                data.Payments &&
                                data.Payments[0] &&
                                data.Payments[0].userId == auth.user.id ? (
                                <h5 className='m-0'>
                                    TOTAL PRICE: {totalPrice}
                                </h5>
                            ) : (
                                <></>
                            )}
                            <div className='d-block'>
                                {auth.user.Role.code === 'notary' &&
                                    'progress' == data.status &&
                                    isFailedSigner() &&
                                    !isBiometricEnabled() && (
                                        <>
                                            <Button
                                                variant='contained'
                                                className='acceptBtn'
                                                style={{
                                                    backgroundColor: '#eeb120',
                                                    color: 'white',
                                                    marginBottom: '5px',
                                                }}
                                                onClick={
                                                    setOpenTransferConfirmationDialog
                                                }>
                                                Transfer to Job Board
                                            </Button>
                                            <Modal
                                                size='md'
                                                show={
                                                    openTransferConfirmationDialog
                                                }
                                                onHide={() =>
                                                    setOpenTransferConfirmationDialog(
                                                        false
                                                    )
                                                }
                                                aria-labelledby='example-modal-sizes-title-lg'>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>
                                                        Transfer to Job Board
                                                    </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    Are you sure you want to
                                                    transfer?
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button
                                                        variant='secondary'
                                                        onClick={() =>
                                                            setOpenTransferConfirmationDialog(
                                                                false
                                                            )
                                                        }>
                                                        No
                                                    </Button>
                                                    <Button
                                                        className='acceptBtn btn btn-secondary'
                                                        onClick={
                                                            tranferNotarization
                                                        }>
                                                        Yes
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>
                                        </>
                                    )}
                                <br />
                                {auth.user.Role.code === 'notary' &&
                                    data.status == 'progress' &&
                                    data.subStatus ==
                                    'Authenticating Participants' && (
                                        <CancelNotarization
                                            journalID={data.id}
                                            alreadyOnDashboard={true}
                                            showCard={false}
                                        />
                                    )}
                            </div>
                        </div>
                    </>
                )}
            </Modal.Body>
        </>
    )
}
