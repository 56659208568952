import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import * as actions from "../../_redux/notarization_redux/notarizationActions";
import { AllWitnesses } from "../../signwitnesss/AllWitnesses";
import { Modal } from "react-bootstrap";
const MIDDLEWARE_URL = process.env.REACT_APP_MIDDLEWARE_URL;

export function SignStatus(props) {
  const dispatch = useDispatch();
  const { socket, auth, notarization } = useSelector(state => state);
  const { witnesses, adobeURL, getAllJournal } = notarization;
  const [journalID, setjournalID] = useState(
    props.journalID ? props.journalID : 0
  );
  const [showModal, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const openDocument = url => {
    window.open(url);
  };



  return (
    <>
      <Card style={{ width: "100%", height: "108px" }} variant="outlined">
        <CardContent>
          <h5>Check Signer ID</h5>
          <Button
            className="myy-btn"
            to="#"
            color="secondary"
            style={{
              float: "right",
              textDecoration: "underline",
              marginRight: "-20px",
              marginTop: "-32px"
            }}
            onClick={handleShow}
          >
            View All
          </Button>
          <Modal show={showModal} onHide={handleClose}>
            <Modal.Header
              style={{ display: "block", padding: "10px" }}
              closeButton
            >
              <Modal.Title style={{ width: "100%" }}>
                Adobe Sign Status
                <span
                  aria-hidden="true"
                  style={{
                    float: "right",
                    fontSize: "30px",
                    cursor: "pointer",
                    marginTop: "-12px"
                  }}
                  onClick={handleClose}
                >
                  ×
                </span>
                <div className="main_class">
                  <div className="inner_class">
                    Please wait for all signers to sign the document.{" "}
                  </div>
                </div>
              </Modal.Title>
              <br />
            </Modal.Header>
            <Modal.Body>
              <div>
                {witnesses.length > 0 ? (
                  <div className="witness_main_container">
                    {witnesses.map((witness, i) => (
                      <div className="witnessprofile">
                        <span class="witnesspic">
                          <img
                            src="/media/users/blank.png"
                            class="img-witness"
                          />
                        </span>
                        <span class="witnessname">
                          {witness.User
                            ? witness.User.firstName +
                              " " +
                              witness.User.lastName
                            : ""}
                        </span>
                        <span class="witnessstatus">
                          {witness.status != "signed" ? (
                            <img
                              src="/media/misc/inprogress.gif"
                              class="img-witness"
                            />
                          ) : (
                            <img
                              src="/media/misc/successtick.png"
                              class="img-witness"
                            />
                          )}
                        </span>
                      </div>
                    ))}
                  </div>
                ) : (
                  ""
                )}

                {getAllJournal.journal &&
                getAllJournal.journal.Signers &&
                getAllJournal.journal.Signers.length > 0 ? (
                  <div className="witness_main_container">
                    {getAllJournal.journal.Signers.map((signer, i) => (
                      <div className="witnessprofile">
                        <span class="witnesspic">
                          <img
                            src="/media/users/blank.png"
                            class="img-witness"
                          />
                        </span>
                        <span class="witnessname">
                          {signer
                            ? signer.firstName + " " + signer.lastName
                            : ""}
                        </span>

                        <span class="witnessname">
                          {" "}
                          {!signer.bypassKBA && signer.frontUrl != null ? (
                            <Button
                              variant="secondary"
                              style={{ backgroundColor: "#ccc" }}
                              onClick={() => openDocument(signer.frontUrl)}
                            >
                              {" "}
                              Front{" "}
                            </Button>
                          ) : (
                            ""
                          )}{" "}
                          {!signer.bypassKBA && signer.backUrl != null ? (
                            <Button
                              variant="secondary"
                              style={{ backgroundColor: "#ccc" }}
                              onClick={() => openDocument(signer.backUrl)}
                            >
                              {" "}
                              Back{" "}
                            </Button>
                          ) : (
                            ""
                          )}
                        </span>

                        <span class="witnessstatus">
                          {signer.status == "signed" ||
                          (socket.agreementsigned &&
                            socket.agreementsigned.type == "signer" &&
                            socket.agreementsigned.id == signer.id) ? (
                            <img
                              src="/media/misc/successtick.png"
                              class="img-witness"
                            />
                          ) : (
                            <img
                              src="/media/misc/inprogress.gif"
                              class="img-witness"
                            />
                          )}
                        </span>
                      </div>
                    ))}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Modal.Body>
          </Modal>
        </CardContent>
        <CardActions>
          <div>
            {getAllJournal.journal &&
            getAllJournal.journal.Signers &&
            getAllJournal.journal.Signers.length > 0 ? (
              <div className="witness_main_container">
                <div className="witnessprofile">
                  <span class="witnesspic">
                    <img src="/media/users/blank.png" class="img-witness" />
                  </span>
                  <span class="witnessname">
                    {getAllJournal.journal.Signers[0]
                      ? getAllJournal.journal.Signers[0].firstName +
                        " " +
                        getAllJournal.journal.Signers[0].lastName
                      : ""}
                  </span>
                  <span class="witnessstatus">
                    {getAllJournal.journal.Signers[0].status == "signed" ||
                    (socket.agreementsigned &&
                      socket.agreementsigned.type == "signer" &&
                      socket.agreementsigned.id ==
                        getAllJournal.journal.Signers[0].id) ? (
                      <img
                        src="/media/misc/successtick.png"
                        class="img-witness"
                      />
                    ) : (
                      <img
                        src="/media/misc/inprogress.gif"
                        class="img-witness"
                      />
                    )}
                  </span>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </CardActions>
      </Card>
    </>
  );
}
