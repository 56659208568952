import React, { useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

export function AddUser({ users, setSelectedUser, selectedUser, disabled }) {
  return (
    <div className={`card card-custom`}>
      <div className="card-header border-0 pt-5">
        <h2 className="card-title align-items-start flex-column">Client:</h2>
      </div>

      <div className="card-body pt-0 pb-3">
        <TextField
          select
          label="Select your client’s email"
          onChange={e => setSelectedUser(e.target.value)}
          value={selectedUser}
          helperText="Please select your client's email for this notarization"
          margin="normal"
          variant="outlined"
          color="secondary"
          disabled={disabled}
          style={{ width: "35%" }}
        >
          {users !== null &&
            users.map(option => (
              <MenuItem key={option.id} value={option}>
                {option.email}
              </MenuItem>
            ))}
        </TextField>
      </div>
    </div>
  );
}
