import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../components/_redux/notarization_redux/notarizationActions";
import { AddBankModal } from "../../components/new_bank/AddBankModal";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
// import { Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { PaymentStatistics } from "../../components/bankaccount_listing/PaymentStatistics";
import PaymentCardListing from "../../components/new_notarization/PaymentCardListing";

import { BankListing } from "../../components/bankaccount_listing/BankListing";
import ShowNotification from "../../components/new_notarization/ShowNotification";
import Button from "@material-ui/core/Button";

export function BankAccountListingTable() {
  const [addBank, setAddBank] = useState(false);
  //const [zerobankDetails, setzerobankDetails] = useState(0);

  const { auth, notarization } = useSelector(state => state);
  const { paymentCards, banksAdd, bankDetails, zerobankDetails } = notarization;
  const [notification, setNotification] = useState({
    show: false,
    msg: "",
    variant: ""
  });
  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  const dispatch = useDispatch();
  const makeDefaultHandler = e =>
    dispatch(
      actions.setDefaultPaymentCard(
        { userId: auth.user.id, cardId: e.target.id },
        headers
      )
    );
  //const getbankDetails = e => dispatch(actions.getBankDetails(headers));

  useEffect(() => {
    dispatch(actions.fetchBankAaccounts(headers));

    dispatch(actions.getBankDetails(headers));
  }, [dispatch]);

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") return;
    setNotification({ show: false, msg: "", variant: "" });
  };

  const arrowStyle = {
    fontSize: "36px",
    color: "#909090",
    backgroundColor: "#fbfbfb",
    borderRadius: "50%",
    marginRight: "2px",
    padding: "7px"
  };

  return (
    <>
      {auth.user.Role.code === "ps" ? (
        <div className="card-body pt-0 pb-3">
          <div className="tab-content">
            <div className="table-responsive">
              <PaymentCardListing
                setNotification={setNotification}
                link={false}
              />
            </div>
          </div>
        </div>
      ) : (
        <>
          {notification.show && (
            <ShowNotification
              show={notification.show}
              msg={notification.msg}
              variant={notification.variant}
              close={handleCloseNotification}
            />
          )}
          {addBank && (
            <AddBankModal
              openModal={addBank}
              setModal={setAddBank}
              setNotification={setNotification}
            />
          )}
          <div className={`card card-custom`}>
            <Link className="pl-6 pt-7" onClick={() => window.history.go(-1)}>
              <ArrowBackIcon style={arrowStyle} />{" "}
              <span style={{ fontSize: "14px", color: "#909090" }}>Back</span>
            </Link>
            <div className="card-body d-flex align-items-center justify-content-between flex-wrap pt-3">
              <h3 className="font-weight-bolder">Payments</h3>
              {
                ( auth.user.Role.code !== "notary" || (auth.user.Role.code === "notary" && !auth.user.notaryProfile.isCancelled ) ) && 
                <button
                  type="button"
                  className="btn"
                  style={{ backgroundColor: "#2d3fd3", color: "white" }}
                  onClick={() => setAddBank(true)}
                >
                  Add Bank Information
                </button>
              }
            </div>

            {bankDetails.available ? (
              <PaymentStatistics bankDetails={bankDetails} />
            ) : (
              ""
            )}

            <div className="pt-3">
              <Link to="./transactionHistory">
                <Button
                  variant="outlined"
                  className="transaction"
                  color="secondary"
                >
                  Transaction History
                </Button>
              </Link>
            </div>

            {<BankListing zerobankDetails={zerobankDetails} />}
            <div className="card-body pt-0 pb-3">
              <div className="tab-content">
                <div className="table-responsive">
                  <PaymentCardListing
                    setNotification={setNotification}
                    link={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
