import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../_metronic/_partials/controls";
import { CustomeAdobeSignIframTable } from "../signwitnesss/table/CustomeAdobeSignIframTable";
import TextField from "@material-ui/core/TextField";

export function TableAdobeSignIframeCard(props) {
  return (
    <Card className="signinwitnesspage">
      <CardBody>
        <CustomeAdobeSignIframTable journalID={props.journalID} />
      </CardBody>
    </Card>
  );
}
