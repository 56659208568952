import React, { useEffect, useState } from "react";
import { Divider } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Container, Row, Col, Button } from "react-bootstrap";
import QRCode from "react-qr-code";

const IDScan = ({ data, buttonColour, buttonText, buttonDisabled, signerLinkVerification, sendEmail }) => {
  return (
    <div style={{ display: "grid", justifyItems: "center" }}>
      <>
        <Row>
          <Col
            md={6}
            style={{
              display: "grid",
            }}
          >
            <h1
              style={{
                color: "#0400F0",
                fontSize: "50px"
              }}
            >
              Upload the Image(s) of Your Valid Government Issued ID
            </h1>
            <div style={{ fontSize: "16px" }}>
              You must have your physical ID with you to perform this part of
              the process.
            </div>
            <div style={{ fontSize: "16px" }}>
              You MUST use a smart phone to take photos in real time, DO NOT
              upload a saved image from your device.
            </div>
          </Col>
          <Col md={6}>
            <img
              className="logo-sticky max-h-250px"
              alt="ID Card"
              src={toAbsoluteUrl("media/id-proofing/id-card.webp")}
            />
          </Col>
        </Row>
        <br />
        <br />
        <Divider
          style={{
            height: "3px",
            backgroundColor: "blue"
          }}
        />
        <Row>
          <b style={{ color: "#0400F0", fontSize: "16px" }}>
            Review our helpful tips to get a great image, the first time!
          </b>
        </Row>
        <br />
        <br />
        <Row>
          <Col md={6}>
            <div>
              <img
                className="logo-sticky max-h-250px"
                alt="ID Card"
                src={toAbsoluteUrl("media/id-proofing/dl1.webp")}
              />
            </div>
            <b>Is Your Picture Blurry?</b>
            <p>
              If your picture has glare or is very blurry it will not pass the
              ID verification process. Please continue to retake this image
              until you can read the writing and see the security features
              clearly.
            </p>
          </Col>
          <Col md={6}>
            <div>
              <img
                className="logo-sticky max-h-250px"
                alt="ID Card"
                src={toAbsoluteUrl("media/id-proofing/dl2.webp")}
              />
            </div>
            <b>Is Your Picture Cut Off?</b>
            <p>
              If the picture of your ID does not show all four edges, it will
              not pass the ID verification process. Please continue to retake
              this image until you can see all the edges within the picture.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div>
              <img
                className="logo-sticky max-h-250px"
                alt="ID Card"
                src={toAbsoluteUrl("media/id-proofing/dl3.webp")}
              />
            </div>
            <b>Do you have a Dark Background?</b>
            <p>
              If your ID is placed on a similar colored background, where the
              system cannot see the defined edges of your ID, it will not pass
              the ID verification process. Place your Image on a flat dark
              surface to take the picture.
            </p>
          </Col>
          <br />
          <br />
          <Col md={6}>
            <div>
              <img
                className="logo-sticky max-h-250px"
                alt="ID Card"
                src={toAbsoluteUrl("media/id-proofing/dl4.webp")}
              />
            </div>
            <b>Are you all set to Verify?</b>
            <p>
              <ul>
                <li>You are using your Smart Phone to take the pictures.</li>

                <li>
                  Your picture is clear and you can read and see all the
                  security features.
                </li>
                <li>
                  Your ID is placed on a flat surface which is darker than the
                  color of the ID.
                </li>

                <li>You can see all 4 edges around your ID clearly.</li>
              </ul>
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm={2}></Col>
          <Col sm={8}>
            <b
              style={{
                color: "#E02B20",
                fontSize: "25px"
              }}
            >
              DO NOT EXIT THIS BROWSER WINDOW UNTIL ALL STEPS HAVE BEEN
              COMPLETED SUCESSFULLY!
            </b>
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={3}></Col>
          {
            data && data?.status === "failed" ? (
              <>
                <Button
                  disabled={true}
                  variant="contained"
                  style={{ minWidth: 250, width: "300px", height: "50px", fontSize: "16px", background: "red", color: "white" }}
                >
                  Currently not available
                </Button>
                
              </>
            ) : data && data.completeVerification ? (
              <Button
                disabled={true}
                variant="contained"
                style={{ minWidth: 250, width: "610px", height: "50px", fontSize: "16px", background: "blue", color: "white" }}
              >
                Your verification was successful, your Notary will send a video link shortly
              </Button> 
            ) : (
              <>
                <Col md={6}>
                  <div>                   
                    <div style={{marginBottom: "25px", display: data && data.idologyLink ? "block": "none"}}>
                      <QRCode size={150} value={data && data.idologyLink ? data.idologyLink :  ""} />
                    </div>
                     
                    
                    <Button
                      disabled={buttonDisabled}
                      variant="contained"
                      style={{ marginBottom: "10px", height: "50px", fontSize: "16px", background: buttonDisabled ? "grey" : buttonColour, color: "white" }}
                      onClick={() => sendEmail("idScan")}
                      className="idProofingActionButtons"
                    >
                      Click here to have the link sent to your Email
                    </Button>
                  </div>
                  
                  <div>
                    Our system will email this link to you from{" "}
                    <a href={"mailto: noreply@cyberizeit.com"}>
                      noreply@cyberizeit.com
                    </a>
                    . Should you not see it in your inbox, please check your spam or
                    junk folders. If it is still missing, contact the notary assisting
                    you. Their information is at the top of this page.{" "}
                    <b>REMINDER </b>
                    <u>do not close this window out</u> until you have fully uploaded
                    your ID Information from your Smart Phone.
                  </div>
                </Col>
                <Col md={3}></Col>
              </>
            )
          }
        </Row>
      </>
    </div>
  );
};

export default IDScan;
