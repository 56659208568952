import React, { useState } from "react";
// import { TableUIWitnessingContext } from "../components/clients_listing/TableUIWitnessingContext";
import { TableAddCard } from "../components/zero_state/TableAddCard";
// import { NewNotarization } from "../pages/NewNotarization";
// import { useDispatch, useSelector } from "react-redux";
// import * as actions from "../components/_redux/notarization_redux/notarizationActions";

export function AddCard(props) {
  const modalProps = props;

  return <TableAddCard modalProps={modalProps} />;
}
