import React from "react";
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Grid from "@material-ui/core/Grid";
import NewNotaryImage from "../../media/zeroState/NoNotaryuserfound.jpg";
export function NewNotaryUserRequest() {
  const [showModal, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button variant="secondary" onClick={handleShow}>
        Accept
      </Button>
      <Modal show={showModal} onHide={handleClose} dialogClassName="my-modal">
        <Modal.Header className="modal_header_new_notarization" closeButton>
          <Modal.Title>New Notarization Call</Modal.Title>
          <div className="new-notary_user_call_text">
            <span>New notary user sign up request from Steve King</span>
          </div>
          <div className="modal-body-two">
            <Button
              variant="secondary"
              className="acceptBtn"
              onClick={handleClose}
            >
              Accept
            </Button>
            
          </div>
        </Modal.Header>
        <Modal.Body className="modal-body-main">
          <Grid container spacing={3}>
            <Grid item xs>
              <img src={NewNotaryImage} className="newNotaryImage" alt="" />
            </Grid>
            <Grid item xs>
              <div>
                <div>Name: Steve King</div>
                <div>Email: alex@deo.com</div>
              </div>
            </Grid>
            <Grid item xs>
              <div>
                <div>Phone: Steve King</div>
                <div>Type of notary: alex@deo.com</div>
              </div>
            </Grid>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Grid container spacing={2}>
            <Grid item xs>
              <div>
                <div>Address: Steve King</div>
                <div>city: alex@deo.com</div>
                <div>state: alex@deo.com</div>
                <div>Language Spoken: alex@deo.com</div>
              </div>
            </Grid>
            <Grid item xs>
              <div>
                <div>Notary CM.number: Steve King</div>
                <div>Notary expire date: alex@deo.com</div>
                <div>zip: alex@deo.com</div>
              </div>
            </Grid>
          </Grid>
        </Modal.Footer>
      </Modal>
    </>
  );
}
