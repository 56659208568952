import React from "react";
import NoNotaryUserFoundImage from "../../../media/zeroState/NoNotaryuserfound.jpg";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

export function CustomNoNotaryUserFoundTable() {
  return (
    <>
      <div>
        <div>
          <img
            src={NoNotaryUserFoundImage}
            className="NoNotaryUserFoundImage"
            alt=""
          />
        </div>
        <div className="NoNotary">
          <h3>No Notary user found!</h3>
        </div>
        <div className="no-notary_text">
          <span>
            It seems that no notary is applied for registration. Once you accept
            the notary profile you can see details here!
          </span>
        </div>
      </div>
    </>
  );
}
