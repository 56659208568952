import React, { useEffect, useState } from "react";
import { getDocEncrypted } from "./_redux/notarization_redux/notarizationCrud";
import { useSelector } from "react-redux";

export function ViewImage({ index, signer }) {
  const { auth } = useSelector(state => state);
  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  const [image, setImage] = useState();

  const getImage = async () => {
    const { data } = await getDocEncrypted(signer.signatureImage, headers);

    setImage(data.data);
  };
  useEffect(() => {
    getImage();
  }, []);
  return (
    <>
      {image ? (
        <img
          style={{
            width: "70px",
            height: "70px"
          }}
          src={`data:image/jpeg;base64,${image}`}
          alt={"Signature"}
        />
      ) :
        ""
      }
    </>
  );
}
