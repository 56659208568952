import React, { useState } from "react";
import { useFormik } from "formik";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import ShowNotification from "../new_notarization/ShowNotification";
import queryString from "query-string";
import Alert from "@material-ui/lab/Alert";
import { register } from "../../modules/Auth/_redux/authCrud";
import * as authentication from "../../modules/Auth/_redux/authRedux";
import * as Yup from "yup";
import { injectIntl } from "react-intl";

const initialValues = {
  firstName: "",
  lastName: "",
  middleName: "",
  suffix: "",
  email: "",
  role: "user"
};

const AddClientForm = props => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { auth } = useSelector(state => state);

  const [notification, setNotification] = useState({
    show: false,
    msg: "",
    variant: ""
  });
  const [loading, setLoading] = useState(false);
  const { intl } = props;

  const [values, setValues] = React.useState({
    firstName: "",
    lastName: "",
    middleName: "",
    suffix: "",
    email: "",
    role: "user",
    errorMSG: false,
    isUserRegister: false,
    isFormNotSubmit: false,
    signUpFailedMessage: ""
  });

  const RegistrationSchema = Yup.object().shape({
    firstName: Yup.string()
      .trim("Required Field")
      .strict(true)
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      ),
    lastName: Yup.string()
      .trim("Required Field")
      .strict(true)
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      ),
    email: Yup.string()
      .trim("Required Field")
      .strict(true)
      .email("Wrong email format")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      ),
    suffix: Yup.string()
      .trim("cannot include leading and trailing spaces")
      .strict(true)
      .nullable()
      .matches(/^[A-Za-z.]+$/gi, "Must be only characters")
      .max(5, "Must not exceed 5 letters")
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") return;
    setNotification({ show: false, msg: "", variant: "" });
  };

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      if (!/^[a-zA-Z0-9]*$/g.test(values.firstName)) {
        setNotification({
          show: true,
          msg: "Please Provide Correct First Name",
          variant: "error"
        });
        return false;
      }

      if (!/^[a-zA-Z0-9]*$/g.test(values.lastName)) {
        setNotification({
          show: true,
          msg: "Please Provide Correct Last Name",
          variant: "error"
        });
        return false;
      }

      if (!/^[a-zA-Z0-9]*$/g.test(values.middleName)) {
        setNotification({
          show: true,
          msg: "Please Provide Correct Middle Name",
          variant: "error"
        });
        return false;
      }

      let notaryId = "notary" == auth.user.Role.code ? auth.user.id : "";
      let createdByNotary = "notary" == auth.user.Role.code ? true : false;
      let refcode =
        "notary" == auth.user.Role.code ? auth.user.notaryProfile.refCode : "";
      enableLoading();
      register(
        values.firstName,
        values.lastName,
        values.email,
        "",
        "",
        values.role,
        refcode,
        values.middleName,
        values.suffix,
        createdByNotary,
        notaryId
      )
        .then(({ data }) => {
          // props.register(data);
          disableLoading();
          setValues({
            isUserRegister: true
          });
          // dispatch({ type: 'user_registered', payload: true });
          window.location.reload();
          // setTimeout(() => dispatch({ type: 'user_registered', payload: false }), 5000);
        })
        .catch(err => {
          setSubmitting(false);
          setValues({
            isFormNotSubmit: true,
            signUpFailedMessage: err.response.data.message
          });
          disableLoading();
        });
    }
  });

  return (
    <>
      {notification.show && (
        <ShowNotification
          show={notification.show}
          msg={notification.msg}
          variant={notification.variant}
          close={handleCloseNotification}
        />
      )}
      {values.isFormNotSubmit === true ? (
        <Alert severity="error" className="mb-3">
          {values.signUpFailedMessage}
        </Alert>
      ) : (
        ""
      )}
      {values.isUserRegister === true ? (
        <Alert severity="success" className="mb-3">
          The Client's Profile has been Completed Successfully!
        </Alert>
      ) : (
        ""
      )}
      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        <div className="container pt-5">
          <div className="form-group fv-plugins-icon-container">
            <h4>Add Client</h4>
            <hr />
          </div>
          <div className="form-group fv-plugins-icon-container">
            <TextField
              className={`w-100 ${getInputClasses("firstName")}`}
              label="First Name *"
              id="mui-theme-provider-standard-input"
              variant="outlined"
              type="text"
              color={"secondary"}
              name="firstName"
              value={values.firstName}
              {...formik.getFieldProps("firstName")}
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <div
                className="fv-plugins-message-container"
                style={{ color: "red" }}
              >
                <div className="fv-help-block">{formik.errors.firstName}</div>
              </div>
            ) : null}
          </div>
          <div className="form-group fv-plugins-icon-container">
            <TextField
              className={`w-100 ${getInputClasses("middleName")}`}
              label="Middle Name"
              variant="outlined"
              id="mui-theme-provider-standard-input"
              type="text"
              color={"secondary"}
              name="middleName"
              value={values.middleName}
              {...formik.getFieldProps("middleName")}
            />
            {formik.touched.middleName && formik.errors.middleName ? (
              <div
                className="fv-plugins-message-container"
                style={{ color: "red" }}
              >
                <div className="fv-help-block">{formik.errors.middleName}</div>
              </div>
            ) : null}
          </div>
          <div className="form-group fv-plugins-icon-container">
            <TextField
              className={`w-100 ${getInputClasses("lastName")}`}
              label="Last Name *"
              variant="outlined"
              id="mui-theme-provider-standard-input"
              type="text"
              color={"secondary"}
              name="lastName"
              value={values.lastName}
              {...formik.getFieldProps("lastName")}
            />
            {formik.touched.lastName && formik.errors.lastName ? (
              <div
                className="fv-plugins-message-container"
                style={{ color: "red" }}
              >
                <div className="fv-help-block">{formik.errors.lastName}</div>
              </div>
            ) : null}
          </div>
          <div className="form-group fv-plugins-icon-container">
            <TextField
              className={`w-100 ${getInputClasses("suffix")}`}
              label="Suffix"
              variant="outlined"
              id="mui-theme-provider-standard-input"
              type="text"
              color={"secondary"}
              name="suffix"
              value={values.suffix}
              inputProps={{ maxLength: 5 }}
              {...formik.getFieldProps("suffix")}
            />
            {formik.touched.suffix && formik.errors.suffix ? (
              <div
                className="fv-plugins-message-container"
                style={{ color: "red" }}
              >
                <div className="fv-help-block">{formik.errors.suffix}</div>
              </div>
            ) : null}
          </div>
          <div className="form-group fv-plugins-icon-container">
            <TextField
              className={`w-100 ${getInputClasses("email")}`}
              label="Email Address *"
              variant="outlined"
              type="email"
              name="email"
              value={values.email}
              color={"secondary"}
              {...formik.getFieldProps("email")}
            />
            {formik.touched.email && formik.errors.email ? (
              <div
                className="fv-plugins-message-container"
                style={{ color: "red" }}
              >
                <div className="fv-help-block">{formik.errors.email}</div>
              </div>
            ) : null}
          </div>
          <div className="form-group d-flex flex-wrap flex-center">
            <button
              type="submit"
              className="btn btn-primary font-weight-bold  w-100 mb-2 mt-3"
            >
              <span>Create Client</span>
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>
            <p style={{ color: "#6c6c6c" }}>
              By submitting these information, you are agreeing to Cyberize It,
              LLC's Terms & Conditions and Privacy Policy.
            </p>
          </div>
        </div>
      </form>
    </>
  );
};
export default injectIntl(connect(null, authentication.actions)(AddClientForm));
