import React from "react";
import { Modal, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import moment from "moment";

export function AttentionModal({
  show,
  handleClose,
  draftHandler,
  showPaymentModal
}) {
  return (
    <Modal
      show={show}
      onHide={() => {
        handleClose(false);
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="justify-content-center pb-2">
        <Modal.Title id="contained-modal-title-vcenter">ATTENTION:</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-3">
        <p className="p-3">
          You have indicated that one of your signers is not a US Citizen. It is
          recommended that you reach out to your Primary Notary before starting
          the automated process. If you would prefer to continue with the
          automated process, with a different Notary, please click continue.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={showPaymentModal}>
          Continue
        </Button>
        <Button variant="secondary" onClick={() => draftHandler("help")}>
          Save Notarization
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
