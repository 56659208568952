import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../components/_redux/notarization_redux/notarizationActions";
import { AddCardModal } from "../../components/new_card/AddCardModal";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

export function CardListingTable() {
  const [addCard, setAddCard] = useState(false);
  const { auth, notarization } = useSelector(state => state);
  const { paymentCards } = notarization;
  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  const dispatch = useDispatch();
  const makeDefaultHandler = e =>
    dispatch(
      actions.setDefaultPaymentCard(
        { userId: auth.user.id, cardId: e.target.id },
        headers
      )
    );

  useEffect(() => {
    dispatch(
      actions.fetchPaymentCards({ userID: auth.user.id, limit: 10 }, headers)
    );
  }, [dispatch]);

  const arrowStyle = {
    fontSize: "36px",
    color: "#909090",
    backgroundColor: "#fbfbfb",
    borderRadius: "50%",
    marginRight: "2px",
    padding: "7px"
  };

  return (
    <>
      {addCard && <AddCardModal openModal={addCard} setModal={setAddCard} />}
      <div className={`card card-custom`}>
        <Link to="/new-notarization" className="pl-6 pt-7">
          <ArrowBackIcon style={arrowStyle} />{" "}
          <span style={{ fontSize: "14px", color: "#909090" }}>Back</span>
        </Link>
        <div className="card-body d-flex align-items-center justify-content-between flex-wrap pt-3">
          <h3 className="font-weight-bolder">My Cards</h3>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => setAddCard(true)}
          >
            Add New Card
          </button>
        </div>

        <div className="card-body pt-0 pb-3">
          <div className="tab-content">
            <div className="table-responsive">
              <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                <tbody>
                  {paymentCards !== null &&
                    paymentCards.map(data => {
                      return (
                        <tr key={data.id}>
                          <td className="pl-0 py-0">
                            <div className="d-flex align-items-center">
                              <div className="symbol symbol-50 symbol-light mr-4">
                                <span className="svg-icon h-75 align-self-end">
                                  <img
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/Shopping/Credit-card.svg"
                                    )}
                                    alt="Credit Card"
                                    style={{ width: "60px" }}
                                  />
                                </span>
                              </div>
                              <div>
                                <span className="text-muted font-weight-bold mb-3 d-block">
                                  Card Number
                                </span>
                                <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                  xxxx-xxxx-xxxx-{data.lastFour}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className="text-muted font-weight-bold mb-3 d-block">
                              Card Holder Name
                            </span>
                            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                              {data.name}
                            </span>
                          </td>
                          <td>
                            <span className="text-muted font-weight-bold mb-3 d-block">
                              Expiry Date
                            </span>
                            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                              {data.expiry}
                            </span>
                          </td>
                          <td>
                            <span className="text-muted font-weight-bold mb-3 d-block">
                              CVV
                            </span>
                            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                              ***
                            </span>
                          </td>
                          <td className="pr-0 text-right">
                            {data.default ? (
                              <Button
                                style={{
                                  backgroundColor: "#1840d2",
                                  borderColor: "#1840d2",
                                  minWidth: "135px"
                                }}
                                disabled
                              >
                                Default
                              </Button>
                            ) : (
                              <Button
                                id={data.id}
                                style={{
                                  backgroundColor: "transparent",
                                  borderColor: "#1840d2",
                                  color: "#1840d2",
                                  minWidth: "135px"
                                }}
                                onClick={makeDefaultHandler}
                              >
                                Make Default
                              </Button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
