import React, { useCallback, useState } from "react";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { useDropzone } from "react-dropzone";
import { uploadDocEncrypted } from "./_redux/notarization_redux/notarizationCrud";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

export function ImageUpload({
  setNotification,
  setUploading,
  index,
  auth,
  additionalDetail,
  setAdditionalDetail,
  headers
}) {
  const [image, setImage] = useState();
  const onDrop = useCallback(
    acceptedFiles => {
      if (acceptedFiles?.length > 1) {
        setNotification({
          show: true,
          msg: "You can not upload more than 1 image",
          variant: "error"
        });
        setUploading(false);
      }
      const file = acceptedFiles?.length && acceptedFiles[0];

      if (file?.type === "image/jpeg") {
        const reader = new FileReader();

        reader.onload = () => {
          // Do whatever you want with the file contents
          const binaryStr = reader.result;
          let formData = new FormData();
          formData.append("file", file);
          const base64String = btoa(
            String.fromCharCode(...new Uint8Array(binaryStr))
          );
          setImage(base64String);

          uploadDocEncrypted(formData, headers)
            .then(({ data }) => {
              const updateAdditionalDetail = JSON.parse(
                JSON.stringify({ ...additionalDetail })
              );
              updateAdditionalDetail.Signers[index].signatureImage =
                data.filename;
              setAdditionalDetail(updateAdditionalDetail);
            })
            .catch(error => {
              console.log(error);
            });
        };
        reader.readAsArrayBuffer(file);
      } else {
        setNotification({
          show: true,
          msg: "Please upload a JPEG formatted signature",
          variant: "error"
        });
        setUploading(false);
      }
    },
    [additionalDetail]
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <>
      <div {...getRootProps()}>
        <input {...getInputProps()} />

        <div
          style={{
            width: "100px"
          }}
        >
          <div
            style={{
              width: "80px",
              float: "left",
              paddingBottom: "20px"
            }}
            className="pr-30"
          >
            <img
              src={toAbsoluteUrl("/media/own/file.png")}
              alt="file"
              style={{
                width: "80px"
              }}
              className="pb-3"
            />
          </div>
        </div>
      </div>
      {image && (
        <div>
          <CheckCircleIcon
            style={{
              color: "blue"
            }}
          />
          <img
            style={{
              width: "70px",
              height: "70px"
            }}
            src={`data:image/jpeg;base64,${image}`}
            alt={"Signature"}
          />
        </div>
      )}
    </>
  );
}
