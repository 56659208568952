/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect } from "react";
import { Redirect, Switch, Route, useLocation } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import Socketinit from "./socket/socketinit";
import Notifications from "./components/notifications/Notifications";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import * as actions from "../app/components/_redux/notarization_redux/notarizationActions";
import { MyQuestion } from "./pages/MyQuestion";
import {IDProofingPage} from './pages/IDProofingPage'

export function Routes() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null
    }),
    shallowEqual
  );

  const location = useLocation();
  const { isProfileCompleted } = useSelector(
    ({ auth }) => ({
      isProfileCompleted:
        auth.user && auth.user.notaryProfile
          ? auth.user.notaryProfile.profile_completed
            ? auth.user.notaryProfile.profile_completed
            : false
          : true
    }),
    shallowEqual
  );

  const { isApproved, isCancelled } = useSelector(
    ({ auth }) => ({
      isApproved:
        auth.user && auth.user.notaryProfile
          ? auth.user.notaryProfile.isApproved
            ? auth.user.notaryProfile.isApproved
            : false
          : true,
      isCancelled:
        auth.user && auth.user.notaryProfile
          ? auth.user.notaryProfile.isCancelled
            ? auth.user.notaryProfile.isCancelled
            : false
          : true    
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const { auth, notarization } = useSelector(state => state);

  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  useEffect(() => {
    if (auth.authToken !== undefined && auth.authToken.hasOwnProperty("id")) {
      dispatch(actions.fetchNotaries(headers));
      dispatch(
        actions.fetchDefaultPaymentCard({ userID: auth.user.id }, headers)
      );
    }
  }, [dispatch]);

  return (
    <Switch>
      {(!isAuthorized && (("/my-signer-question" != location.pathname) && ("/id-proofing/" != location.pathname) )) ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}
      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />
      {(!isProfileCompleted || ( !isApproved && !isCancelled)) &&
      "/user-profile" != location.pathname &&
      isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <span>
          {!isProfileCompleted ? (
            <Redirect to="user-profile#profile-not-completed" />
          ) : (
            <Redirect to="user-profile#under-review" />
          )}
        </span>
      ) : isAuthorized ? (
        <Layout>
          <Notifications />
          <Socketinit />
          <BasePage />
        </Layout>
      ) : (
        ""
      )}
      {(!isAuthorized && ( "/my-signer-question" != location.pathname && "/id-proofing/" != location.pathname )) ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : "/id-proofing/" === location.pathname ? (
        <Route>
          <IDProofingPage />
          <Socketinit />
        </Route>
      ) : "/my-signer-question" === location.pathname ? (
        <Route>
          <MyQuestion />
        </Route>
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}
