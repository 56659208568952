import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../_redux/notarization_redux/notarizationActions'

export function RefreshJobBoard() {
    const { auth, notarization } = useSelector((state) => state)
    const { allJobs } = notarization
    const dispatch = useDispatch()
    const headers = {
        Authorization: `Bearer ${auth.authToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
    }

    const refreshJobs = () => {
        dispatch(
            actions.fetchJobPosts(
                {
                    sortOrder: 'asc', // asc||desc
                    sortField: 'id',
                    pageNumber: 1,
                    pageSize: 10,
                },
                headers,
                auth.user.Role.code
            )
        )
    }

    return (
        <div className='card-title py-6 d-flex justify-content-end'>
            <Button variant='primary' onClick={() => refreshJobs()}>
                Refresh
            </Button>
        </div>
    )
}
