import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { useSelector, connect, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import * as auth from '../_redux/authRedux'
import * as Yup from 'yup'
import { psregister } from '../_redux/authCrud'
import {
    TextField,
    InputAdornment,
    IconButton,
    MenuItem,
    Button,
} from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Alert from '@material-ui/lab/Alert'
import ShowNotification from '../../../components/new_notarization/ShowNotification'
import * as notarizeactions from '../../../components/_redux/notarization_redux/notarizationActions'

import { TwoFactorAuth } from './TwoFactorAuth'

const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    currentpPassword: '',
    role: 'ps',
    commissionNo: '',
    changepassword: '',
}

function Signupprofessional(props) {
    const [hasCode, setHasCode] = useState(false)
    const [code, setCode] = useState('')
    const [values, setValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        changepassword: '',
        role: 'ps',
        businessName: '',
        commissionNo: '',
        showPassword: false,
        showConfirmPassword: false,
        errorMSG: false,
        isUserRegister: false,
        isFormNotSubmit: false,
        signUpFailedMessage: '',
    })
    
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword })
    }
    const handleClickConfrimShowPassword = () => {
        setValues({
            ...values,
            showConfirmPassword: !values.showConfirmPassword,
        })
    }

    const goToUserRegistration = () =>
        (window.location.href = '/#/auth/registration')

    // const { intl } = props;
    const { intl } = props
    const [loading, setLoading] = useState(false)
    const NotaryRegistrationSchema = Yup.object().shape({
        firstName: Yup.string()
            .trim('Required Field')
            .strict(true)
            .required(
                intl.formatMessage({
                    id: 'AUTH.VALIDATION.REQUIRED_FIELD',
                })
            ),
        lastName: Yup.string()
            .trim('Required Field')
            .strict(true)
            .required(
                intl.formatMessage({
                    id: 'AUTH.VALIDATION.REQUIRED_FIELD',
                })
            ),
        email: Yup.string()
            .trim('Required Field')
            .strict(true)
            .email('Wrong email format')
            .required(
                intl.formatMessage({
                    id: 'AUTH.VALIDATION.REQUIRED_FIELD',
                })
            ),

        businessName: Yup.string()
            .trim('Required Field')
            .strict(true)
            .required(
                intl.formatMessage({
                    id: 'AUTH.VALIDATION.REQUIRED_FIELD',
                })
            ),
        password: Yup.string()
            .trim('Required Field')
            .strict(true)
            .required(
                intl.formatMessage({
                    id: 'AUTH.VALIDATION.REQUIRED_FIELD',
                })
            ),
        confirmPassword: Yup.string()
            .trim('Required Field')
            .strict(true)
            .required(
                intl.formatMessage({
                    id: 'AUTH.VALIDATION.REQUIRED_FIELD',
                })
            )
            .when('password', {
                is: (val) => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref('password')],
                    'Please make sure your passwords match!'
                ),
            }),
    })

    const handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') return
        setNotification({ show: false, msg: '', variant: '' })
    }

    const enableLoading = () => {
        setLoading(true)
    }

    const disableLoading = () => {
        setLoading(false)
    }

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return 'is-invalid'
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return 'is-valid'
        }

        return ''
    }
    const [notification, setNotification] = useState({
        show: false,
        msg: '',
        variant: '',
    })
    const dispatch = useDispatch()
    const formik = useFormik({
        initialValues,
        validationSchema: NotaryRegistrationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            submit(
                values.firstName,
                values.lastName,
                values.email,
                values.password,
                values.role,
                values.commissionNo,
                values.businessName,
                setStatus,
                setSubmitting
            )

            setValues({ ...values }, () => {})
        },
    })

    const submit = (
        firstName = values.firstName,
        lastName = values.lastName,
        email = values.email,
        password = values.password,
        role = values.role,
        commissionNo = values.commissionNo,
        businessName = values.businessName,
        setStatus,
        setSubmitting
    ) => {
        if (!hasCode) {
            const validationMessages = checkValidation(values)
            if (validationMessages) {
                setNotification({
                    show: true,
                    msg: validationMessages,
                    variant: 'error',
                })
                return false
            }

            if (
                values.password === values.confirmPassword &&
                values.password.length < 7
            ) {
                setValues({
                    errorMSG: true,
                })
                return false
            }
        }
        enableLoading()
        psregister(
            firstName,
            lastName,
            email,
            password,
            role,
            commissionNo,
            businessName,
            code
        )
            .then(({ data }) => {
                if (data?.message === 'check your email') {
                    setHasCode(true)
                } else {
                    props.notaryregistration(data)
                    disableLoading()
                    setValues({
                        isUserRegister: true,
                    })
                    dispatch({ type: 'user_registered', payload: true })
                    window.location.href = '/#/auth/login'
                    setTimeout(
                        () =>
                            dispatch({
                                type: 'user_registered',
                                payload: false,
                            }),
                        5000
                    )
                }
            })
            .catch((err) => {
                setSubmitting(false)
                setValues({
                    isFormNotSubmit: true,
                    signUpFailedMessage: err.response.data.message,
                })
                disableLoading()
            })
    }
    const { auth, notarization } = useSelector((state) => state)
    const { allAdminStates } = notarization

    const headers = {
        Authorization: `Bearer ${auth.authToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
    }

    useEffect(() => {
        dispatch(
            notarizeactions.fetchNotaryStates(headers, [], {
                pageNumber: 1,
                pageSize: 52,
                sortBy: "asc",
                sortField: "state",
            })
        )
    }, [])

    return (
        <>
            {notification.show && (
                <ShowNotification
                    show={notification.show}
                    msg={notification.msg}
                    variant={notification.variant}
                    close={handleCloseNotification}
                />
            )}
            {hasCode ? (
                <TwoFactorAuth code={code} setCode={setCode} submit={submit} />
            ) : (
                <div
                    className='login-form login-signin'
                    style={{ display: 'block' }}>
                    <div className='text-start'>
                        <h3 className='font-size-h1'>
                            Create a New Professional Services Account
                        </h3>
                        {/* <p className="text-muted font-weight-bold">Live Enotary Services</p> */}
                        <p style={{ color: '#6c6c6c' }}>
                            <b>Disclaimer:</b> Once you submit this form you will NOT have the ability to change your email address.
                        </p>
                    </div>

                    {values.errorMSG === true ? (
                        <Alert severity='error' className='mb-3'>
                            Password must contain minimum 8 characters
                        </Alert>
                    ) : (
                        ''
                    )}
                    {values.isFormNotSubmit === true ? (
                        <Alert severity='error' className='mb-3'>
                            {values.signUpFailedMessage}
                        </Alert>
                    ) : (
                        ''
                    )}
                    {values.isUserRegister === true ? (
                        <Alert severity='success' className='mb-3'>
                            Notary Register Successfully!
                        </Alert>
                    ) : (
                        ''
                    )}
                    <form
                        id='kt_login_signin_form'
                        className='form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp'
                        onSubmit={formik.handleSubmit}>
                        {/* begin: Alert */}
                        {/* {formik.status && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )} */}
                        {/* end: Alert */}

                        {/* begin: Fullname */}
                        <div className='form-group fv-plugins-icon-container'>
                            <TextField
                                className={`w-100 ${getInputClasses(
                                    'firstName'
                                )}`}
                                label='First Name *'
                                id='mui-theme-provider-standard-input'
                                variant='outlined'
                                type='text'
                                color={'secondary'}
                                name='firstName'
                                value={values.firstName}
                                {...formik.getFieldProps('firstName')}
                            />
                            {formik.touched.firstName &&
                            formik.errors.firstName ? (
                                <div
                                    className='fv-plugins-message-container'
                                    style={{ color: 'red' }}>
                                    <div className='fv-help-block'>
                                        {formik.errors.firstName}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div className='form-group fv-plugins-icon-container'>
                            <TextField
                                className={`w-100 ${getInputClasses(
                                    'lastName'
                                )}`}
                                label='Last Name *'
                                variant='outlined'
                                id='mui-theme-provider-standard-input'
                                type='text'
                                color={'secondary'}
                                name='lastName'
                                value={values.lastName}
                                {...formik.getFieldProps('lastName')}
                            />
                            {formik.touched.lastName &&
                            formik.errors.lastName ? (
                                <div
                                    className='fv-plugins-message-container'
                                    style={{ color: 'red' }}>
                                    <div className='fv-help-block'>
                                        {formik.errors.lastName}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        {/* end: Fullname */}

                        {/* begin: Business Name  */}
                        <div className='form-group fv-plugins-icon-container'>
                            <TextField
                                className={`w-100 ${getInputClasses(
                                    'businessName'
                                )}`}
                                label='Business Name *'
                                variant='outlined'
                                type='businessName'
                                name='businessName'
                                value={values.businessName}
                                color={'secondary'}
                                {...formik.getFieldProps('businessName')}
                            />
                            {formik.touched.businessName &&
                            formik.errors.businessName ? (
                                <div
                                    className='fv-plugins-message-container'
                                    style={{ color: 'red' }}>
                                    <div className='fv-help-block'>
                                        {formik.errors.businessName}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        {/* end: Business Name  */}

                        {/* begin: Email */}
                        <div className='form-group fv-plugins-icon-container'>
                            <TextField
                                className={`w-100 ${getInputClasses('email')}`}
                                label='Email Address *'
                                variant='outlined'
                                type='email'
                                name='email'
                                value={values.email}
                                color={'secondary'}
                                {...formik.getFieldProps('email')}
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <div
                                    className='fv-plugins-message-container'
                                    style={{ color: 'red' }}>
                                    <div className='fv-help-block'>
                                        {formik.errors.email}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        {/* end: Email */}

                        {/* begin: Password */}
                        <div className='form-group fv-plugins-icon-container pw-fields m-0'>
                            <div>
                                <TextField
                                    className={`mb-3 ${getInputClasses(
                                        'password'
                                    )}`}
                                    label='Password *'
                                    id='outlined-adornment-password'
                                    style={{ width: '100%' }}
                                    variant='outlined'
                                    value={values.currentpPassword}
                                    name='password'
                                    color={'secondary'}
                                    type={
                                        values.showPassword
                                            ? 'text'
                                            : 'password'
                                    }
                                    {...formik.getFieldProps('password')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    edge='end'
                                                    aria-label='Toggle password visibility'
                                                    onClick={
                                                        handleClickShowPassword
                                                    }>
                                                    {values.showPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {formik.touched.password &&
                                formik.errors.password ? (
                                    <div
                                        className='fv-plugins-message-container'
                                        style={{ color: 'red' }}>
                                        <div className='fv-help-block'>
                                            {formik.errors.password}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            <div>
                                <TextField
                                    className={` ${getInputClasses(
                                        'confirmPassword'
                                    )}`}
                                    label='Confirm Password *'
                                    id='outlined-adornment-password'
                                    style={{ width: '100%' }}
                                    variant='outlined'
                                    value={values.confirmPassword}
                                    name='confirmPassword'
                                    color={'secondary'}
                                    type={
                                        values.showConfirmPassword
                                            ? 'text'
                                            : 'password'
                                    }
                                    {...formik.getFieldProps('confirmPassword')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    edge='end'
                                                    aria-label='Toggle password visibility'
                                                    onClick={
                                                        handleClickConfrimShowPassword
                                                    }>
                                                    {values.showConfirmPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {formik.touched.confirmPassword &&
                                formik.errors.confirmPassword ? (
                                    <div
                                        className='fv-plugins-message-container'
                                        style={{ color: 'red' }}>
                                        <div className='fv-help-block'>
                                            {formik.errors.confirmPassword}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className='mb-6' style={{ color: 'grey' }}>
                            {
                                'Note: Password must be 8 characters long, and must contain one capital letter and one symbol. '
                            }
                        </div>
                        {/* end: Password */}
                        <span style={{ color: '#4c4c4c' }}>
                            Already have an account?
                            <Link
                                className='px-3'
                                to='/auth/login'
                                // id="kt_login_notary"
                                style={{ color: '#2d3fd3' }}>
                                Login Here
                            </Link>
                        </span>

                        <div className='form-group d-flex flex-wrap flex-center'>
                            <button
                                type='submit'
                                className='btn btn-primary font-weight-bold  w-100 mb-2 mt-3'>
                                <span>Sign Up</span>
                                {loading && (
                                    <span className='ml-3 spinner spinner-white'></span>
                                )}
                            </button>
                            <p style={{ color: '#6c6c6c' }}>
                                By submitting your information, you are agreeing
                                to Cyberize It, LLC's Independent Contractor
                                Agreement and Privacy Policy.
                            </p>
                            <Button
                                variant='outlined'
                                color='secondary'
                                className='w-100 p-5'
                                onClick={goToUserRegistration}>
                                Sign Up For User
                            </Button>
                        </div>
                    </form>
                </div>
            )}
        </>
    )
}

const checkValidation = (values) => {
    if (!/^[a-zA-Z0-9]*$/g.test(values.firstName)) {
        return 'Please Provide Correct First Name'
    }

    if (!/^[a-zA-Z0-9]*$/g.test(values.lastName)) {
        return 'Please Provide Correct Last Name'
    }
}
export default injectIntl(connect(null, auth.actions)(Signupprofessional))
