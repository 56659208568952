import React, { useState } from "react";
import SearchNotaryContainer from "../components/seach_notaries/SearchNotaryContainer";
import ShowNotification from "../components/new_notarization/ShowNotification";
import { NotaryWaiting } from "../components/modals/NotaryWaiting";

export function SearchNotaries({
  setSelectPrimaryNotary,
  showNotaryWaiting = false,
  selectedUser,
  selectedNotaryId=null,
  saveLaterSuccessHideHandler = []
}) {
  const [notification, setNotification] = useState({
    show: false,
    msg: "",
    variant: ""
  });
  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") return;
    setNotification({ show: false, msg: "", variant: "" });
  };

  return (
    <>
      {notification.show && (
        <ShowNotification
          show={notification.show}
          msg={notification.msg}
          variant={notification.variant}
          close={handleCloseNotification}
        />
      )}
      <SearchNotaryContainer
        setNotification={setNotification}
        setSelectPrimaryNotary={setSelectPrimaryNotary}
        selectedUser={selectedUser}
        selectedNotaryId={selectedNotaryId}
      />
      {showNotaryWaiting && (
        <NotaryWaiting
          heading="Please Wait For Your Notary"
          show={showNotaryWaiting}
          hide={saveLaterSuccessHideHandler}
        />
      )}
    </>
  );
}
