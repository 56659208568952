import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../_metronic/_partials/controls/AdminCard";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { CustomTable } from "./table/CustomTable";
import DownlaodIcon from "../../media/zeroState/downloadicon.webp";
import { Form } from "react-bootstrap";
import { CSVLink } from "react-csv";

export function TableCardschedule(props) {
  const { auth, notarization } = useSelector(state => state);
  const { allJournals, allCSVJournals, paymentDetails } = notarization;
  const [csvData, setCsvData] = useState([
    [
      "Customer Name",
      "Notary",
      "startDate",
      "endDate",
      "Type of Notarization",
      "Price of Notarization",
      "Number of Seals (Stamps)",
      "scheduledAt",
      "Number of Signers",
      "status",
      "Documents",
      "Signer 1",
      "Signer 2",
      "Signer 3",
      "Signer 4",
      "Witnesses"
    ]
  ]);

  function GetFinalPayment(seals, witnessCount) {
    let total =
      seals === 0
        ? 0
        : paymentDetails.sealFirstFee + (seals - 1) * paymentDetails.sealSecFee;
    let notaryFee =
      seals === 0
        ? 0
        : paymentDetails.notaryFeePerFirstSeal +
          (seals - 1) * paymentDetails.notaryFeePerSecSeal;
    let platformFee =
      seals === 0
        ? 0
        : paymentDetails.platformPerFirstSeal +
          (seals - 1) * paymentDetails.platformPerSecSeal;
    let witnessFee =
      witnessCount === 0 ? 0 : witnessCount * paymentDetails.witnessFee;
    total += witnessFee;

    return {
      total: total,
      notaryFee: notaryFee,
      platformFee: platformFee,
      witnessFee: witnessFee
    };
  }

  useEffect(() => {
    let tmpdata = [];
    let journalinfo;
  });

  const { allAdminNotaries, allAdminNotariesQuery } = notarization;

  const useStyles = makeStyles(() => ({
    cstm_padding: {
      paddingLeft: "30px",
      paddingRight: "30px",
      marginTop: "30px",
      marginBottom: "1rem"
    },
    cstm_field: {
      width: "100%",
      marginTop: 0
    }
  }));
  const classes = useStyles();
  const [notaryName, setnotaryName] = useState("");
  const [notaryEmail, setnotaryEmail] = useState("");
  const [statusfilter, setstatusfilter] = useState("");
  const [fromDate, setfromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [search, setSearch] = useState(false);
  const [searchClicked, setSearchClicked] = useState(false);

  // Handler Functions

  const handleChangeFromDate = e => {
    let mainfilter = [];
    mainfilter = props.mainfilter;

    if (e.target.value) {
      let datechange = new Date(e.target.value);
      let datestring = datechange.toISOString();

      if (!mainfilter.Main.createdAt) {
        mainfilter.Main.createdAt = { from: datestring, matchType: "range" };
      } else {
        mainfilter.Main.createdAt["from"] = e.target.value;
      }
    } else {
      checkanddeletedate("from");
    }

    props.setfromDate(e.target.value);

    props.setmainfilter(mainfilter);
  };

  const handleChangeToDate = e => {
    let mainfilter = [];
    mainfilter = props.mainfilter;

    if (e.target.value) {
      let datechange = new Date(e.target.value);
      let datestring = datechange.toISOString();

      if (!mainfilter.Main.createdAt) {
        mainfilter.Main.createdAt = { to: datestring, matchType: "range" };
      } else {
        mainfilter.Main.createdAt["to"] = e.target.value;
      }
    } else {
      checkanddeletedate("to");
    }

    props.settoDate(e.target.value);
    props.setmainfilter(mainfilter);
  };

  const checkanddeletedate = mode => {
    let mainfilter = [];
    mainfilter = props.mainfilter;

    if (mainfilter.Main.createdAt) {
      if (
        mainfilter.Main.createdAt["from"] &&
        mainfilter.Main.createdAt["to"]
      ) {
        delete mainfilter.Main.createdAt[mode];
      } else delete mainfilter.Main.createdAt;
    }
  };

  const setingclientfilter = text => {
    let mainfilter = [];
    let mainexcelfilter = [];
    mainfilter = props.mainfilter;
    if (text) {
      mainfilter.include[1].id = { value: text };

      props.setclient(text);
    } else {
      if (
        mainfilter.include &&
        mainfilter.include[1] &&
        mainfilter.include[1].id
      )
        delete mainfilter.include[1];

      props.setclient(text);
    }

    props.setmainfilter(mainfilter);
  };

  const settingstatusfilter = text => {
    let mainfilter = [];
    mainfilter = props.mainfilter;

    if (text) {
      mainfilter.Main.status = { value: [text], matchType: "in" };
      props.setjournalstatus(text);
    } else {
      if (mainfilter.Main.status) delete mainfilter.Main.status;

      props.setjournalstatus(text);
    }

    props.setmainfilter(mainfilter);
  };

  const settingaFilter = (text, mode) => {
    let mainfilter = [];

    if (text) {
      if ("firstName" == mode) props.setnotaryName(text);

      mainfilter = props.mainfilter;
      mainfilter.include[0].or = {
        values: [
          { value: text, name: "firstName" },
          { value: text, name: "email" },
          { value: text, name: "lastName" }
        ]
      };
    } else {
      mainfilter = props.mainfilter;

      if (mainfilter.include[0].or["values"]) delete mainfilter.include[0].or;

      props.setnotaryName("");
    }
    props.setmainfilter(mainfilter);
  };

  const applyFilterHandler = name => {
    if (name === "search") {
      setSearch(!search);
      setSearchClicked(true);
      props.applyFilter();
    } else if (name === "clear") {
      setstatusfilter("");
      setSearch(!search);
    }
  };

  return (
    <Card>
      {}
      <CardHeader className="py-6">
        <CardHeaderToolbar style={{ minWidth: "570px" }}>
          <div style={{ width: "80%", marginRight: "10px" }}>
            <Form.Control
              type="text"
              className="mr-2"
              placeholder="Search notary by name/email"
              value={props.notaryName}
              onChange={e => settingaFilter(e.target.value, "firstName")}
              style={{ width: "100%" }}
            />
          </div>

          <div style={{ width: "80%", marginRight: "10px" }}>
            <TextField
              type="date"
              required
              label="From Date"
              value={props.fromDate}
              onChange={handleChangeFromDate}
              margin="dense"
              variant="outlined"
              className={classes.cstm_field}
              inputProps={{
                max: new Date().toISOString().split("T")[0]
              }}
              color="secondary"
              InputLabelProps={{
                shrink: true
              }}
              style={{ width: "100%", marginBottom: "10px" }}
            />
            <TextField
              type="date"
              required
              label="To Date"
              value={props.toDate}
              onChange={handleChangeToDate}
              margin="dense"
              variant="outlined"
              className={classes.cstm_field}
              inputProps={{
                max: new Date().toISOString().split("T")[0]
              }}
              color="secondary"
              InputLabelProps={{
                shrink: true
              }}
              style={{ width: "100%" }}
            />
          </div>

          <div style={{ width: "80%", marginRight: "10px" }}>
            <Form.Group
              className="mb-0 mr-2"
              style={{ width: "100%", marginBottom: "10px" }}
            >
              <Form.Control
                as="select"
                name="status"
                onChange={e => setingclientfilter(e.target.value)}
                value={props.client}
                style={{ width: "100%", marginBottom: "10px" }}
              >
                <option value="">All PS</option>
                {allAdminNotaries &&
                  allAdminNotaries.rows &&
                  allAdminNotaries.rows.map(el => (
                    <>
                      {el.roleId == 4 ? (
                        <option value={el.id}>
                          {el.firstName} {el.lastName}
                        </option>
                      ) : (
                        ""
                      )}
                    </>
                  ))}
              </Form.Control>
            </Form.Group>

            <Form.Group
              className="mb-0 mr-2"
              style={{ width: "100%", marginBottom: "10px" }}
            >
              <Form.Control
                as="select"
                name="status"
                onChange={e => setingclientfilter(e.target.value)}
                value={props.client}
                style={{ width: "100%", marginBottom: "10px" }}
              >
                <option value="">All Users</option>
                {allAdminNotaries &&
                  allAdminNotaries.rows &&
                  allAdminNotaries.rows.map(el => (
                    <>
                      {el.roleId != 4 ? (
                        <option value={el.id}>
                          {el.firstName} {el.lastName}
                        </option>
                      ) : (
                        ""
                      )}
                    </>
                  ))}
              </Form.Control>
            </Form.Group>

            {!props.schedule && !props.completed && (
              <Form.Group
                className="mb-0 mr-2"
                style={{ width: "100%", marginBottom: "10px" }}
              >
                <Form.Control
                  as="select"
                  name="status"
                  onChange={e => settingstatusfilter(e.target.value)}
                  value={props.journalstatus}
                >
                  <option value="">All Statuses</option>
                  <option value="draft">Draft</option>
                  <option value="Progress">Progress</option>
                  <option value="Scheduled">Scheduled</option>
                  <option value="failed">failed</option>
                  <option value="completed">Completed</option>
                </Form.Control>
              </Form.Group>
            )}
          </div>

          <button
            type="button"
            className="btn btn-primary mr-3"
            onClick={e => applyFilterHandler("search")}
          >
            Search
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <CustomTable
          setmainfilter={props.setmainfilter}
          mainfilter={props.mainfilter}
          statusfilter={statusfilter}
          search={search}
          applyFilterHandler={applyFilterHandler}
          searchClicked={searchClicked}
          setSearchClicked={setSearchClicked}
          schedule={props.schedule ? true : false}
          completed={props.completed ? true : false}
        />
      </CardBody>
    </Card>
  );
}
