import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { useSelector } from 'react-redux'

// import { useDispatch, useSelector } from "react-redux";
// import * as actions from "../../../components/_redux/notarization_redux/notarizationActions";

export function Paynow2({
    close,
    payPlatformFeeHandler,
    platformFee,
    paynow,
    paid,
    noOfSigner,
    auth,
    notarizeNowitHandler,
    scheduleHandler,
    notarizeNowHandler,
    user,
    selectedNotary,
    confirmjobPostHandler,
    showReNotary,
    KBASettings,
    isBiometric,
    isButtonClicked,
}) {
    const { paynowLoading, draftLoading, scheduleLoading } = useSelector(
        (state) => state.notarization
    )

    return (
        <>
            <Modal.Header>
                <Modal.Title
                    id='contained-modal-title-vcenter'
                    style={{ width: '97%', float: 'left' }}>
                    Payment Breakdown
                </Modal.Title>
                <p style={{ marginBottom: '-30px' }}>2/2</p>
            </Modal.Header>
            <Modal.Body>
                <h6 className='mb-6 mt-0'>
                    By clicking submit you agree to the following charges:
                </h6>
                <p>
                    Service Fee{' '}
                    <span style={{ float: 'right' }}>$ {platformFee.fee}</span>
                </p>
                
                <h3 className='mb-6 mt-4'>
                    Total Amount
                    <span style={{ float: 'right' }}>$ {platformFee.fee}</span>
                </h3>
                <h6>Note:</h6>
                <p className='py-0 mb-2'>
                    - Notary Seals are charged IN ADDITION to this fee.
                </p>
                <p className='py-0 mb-2'>
                    - Witnesses Fees (if requested) are charged in addition to
                    this fee.
                </p>
                <p className='py-0 mb-2'>
                    - At the end of the notarization, your next payment will be
                    charged automatically to your saved payment method, a second
                    receipt will be sent to your email.
                </p>
            </Modal.Body>
            <Modal.Footer className='justify-content-center'>
                <Button
                    style={{
                        backgroundColor: '#effff4',
                        borderColor: '#effff4',
                        color: '#00c05d',
                        minWidth: '135px',
                    }}
                    onClick={() => close(false)}>
                    Cancel
                </Button>
                {auth.user.Role.code === 'ps' && (
                    <Button
                        style={{
                            backgroundColor: '#00c05d',
                            borderColor: '#00c05d',
                            minWidth: '165px',
                        }}
                        disabled={paynowLoading}
                        onClick={() => payPlatformFeeHandler(platformFee.fee)}>
                        <span>Confirm & Pay</span>
                        {paynowLoading && (
                            <span className='ml-3 spinner spinner-white'></span>
                        )}
                    </Button>
                )}
                {auth.user.Role.code !== 'ps' &&
                    user &&
                    selectedNotary &&
                    selectedNotary.primary &&
                    !isBiometric() && (
                        <Button
                            className='mr-4 ml-3'
                            style={{
                                backgroundColor: 'transparent',
                                borderColor: '#1840d2',
                                color: '#1840d2',
                                minWidth: '150px',
                            }}
                            disabled={isButtonClicked ? 'disabled' : ''}
                            onClick={scheduleHandler}>
                            Schedule It
                            {scheduleLoading && (
                                <span className='ml-3 spinner spinner-white'></span>
                            )}
                        </Button>
                    )}

                {auth.user.Role.code !== 'ps' &&
                    user &&
                    selectedNotary &&
                    selectedNotary.primary &&
                    !isBiometric() && (
                        <Button
                            className='mr-4 ml-3'
                            style={{
                                backgroundColor: '#1840d2',
                                borderColor: '#1840d2',
                                minWidth: '150px',
                            }}
                            disabled={isButtonClicked ? 'disabled' : ''}
                            onClick={notarizeNowHandler}>
                            {user ? 'Notarize It' : 'Send To Client'}
                        </Button>
                    )}

                {auth.user.Role.code !== 'ps' &&
                    user &&
                    ((selectedNotary &&
                        selectedNotary.primary &&
                        isBiometric()) ||
                        (selectedNotary &&
                            !selectedNotary.primary &&
                            !showReNotary &&
                            KBASettings &&
                            KBASettings.data &&
                            KBASettings.data &&
                            !KBASettings.data.settings.bypassAllowed)) && (
                        <Button
                            className='mr-4 ml-3'
                            style={{
                                backgroundColor: '#1840d2',
                                borderColor: '#1840d2',
                                minWidth: '150px',
                            }}
                            onClick={confirmjobPostHandler}>
                            Connect with Notary
                        </Button>
                    )}

                {/* Notary buttons bellow */}
                {auth.user.Role.code !== 'ps' && !user && (
                    <>
                        {' '}
                        <Button
                            className='mr-4 ml-3'
                            style={{
                                backgroundColor: '#1840d2',
                                borderColor: '#1840d2',
                                minWidth: '150px',
                            }}
                            disabled={isButtonClicked ? 'disabled' : ''}
                            onClick={scheduleHandler}>
                            Schedule it
                        </Button>{' '}
                        <Button
                            className='mr-4 ml-3'
                            style={{
                                backgroundColor: '#1840d2',
                                borderColor: '#1840d2',
                                minWidth: '150px',
                            }}
                            disabled={isButtonClicked ? 'disabled' : ''}
                            onClick={notarizeNowitHandler}>
                            Notarize it
                        </Button>
                    </>
                )}
            </Modal.Footer>
        </>
    )
}
