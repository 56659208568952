import React from "react";
import NoBankAddedImage from "../../../media/zeroState/NobankAdded.webp";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

export function CustomNoBankAddedtable() {
  return (
    <>
      <div>
        <div>
          <img src={NoBankAddedImage} className="NoBankAddedImage" alt="" />
        </div>
        <div className="NoBankAdded">
          <h3>No Bank Added!</h3>
        </div>
        <div className="no-bank_added-text">
          <span>
            You haven't added any bank for your payout. Please add your desired
            bank for a impeccable transaction.
          </span>
          <div>
            <Link to="#">
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#2d3fd3",
                  color: "#ffffff",
                  fontSize: "13px",
                  marginTop: "25px"
                }}
              >
                {" "}
                Add Bank{" "}
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
