import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Modal } from 'react-bootstrap'
import { CustomerEditForm } from './CustomerEditForm'
import JournalSignerModal from '../../../modals/JournalSignerModal'
import CompletedNotarization from '../../../modals/CompletedNotarization'
export function CustomerEditDialog({
    id,
    show,
    onHide,
    resumeNotarization,
    addWitness,
    downloadDocument,
    auditReport,
    downloadJournal
}) {
    const {
        auth,
        notarization: { allJournals, SingleJournalObj, top5Journals, jitsiMeetingLinks},
    } = useSelector((state) => state)
    const [data, setData] = useState()
    useEffect(() => {
        const jounrals = allJournals ? allJournals : top5Journals
        const SignerData =
            jounrals !== null
                ? jounrals.rows.find((data) => data.id === id)
                : null

        // if (SingleJournalObj != null) {
        //     setData(SingleJournalObj)
        // } else {
            setData(SignerData)
        // }

        //setissingleKBA
    }, [allJournals, id, top5Journals])
    return (
        <Modal
            size={auth.user.Role.code === 'user' ? 'lg' : 'xl'}
            show={show}
            onHide={onHide}
            dialogClassName="modal-width"
            aria-labelledby={
                auth.user.Role.code === 'user'
                    ? 'example-modal-sizes-title-lg'
                    : 'example-modal-sizes-title-xl'
            }>
            {data?.status === 'completed' ? (
                <CompletedNotarization
                    onHide={onHide}
                    id={id}
                    resumeNotarization={resumeNotarization}
                    addWitness={addWitness}
                    downloadDocument={downloadDocument}
                    auditReport={auditReport}
                    downloadJournal={downloadJournal}
                />
            ) : auth.user.Role.code === 'user' ? (
                <JournalSignerModal
                    onHide={onHide}
                    id={id}
                    resumeNotarization={resumeNotarization}
                    addWitness={addWitness}
                    downloadDocument={downloadDocument}
                    auditReport={auditReport}                    
                    downloadJournal={downloadJournal}
                />
            ) : (
                <CustomerEditForm
                    onHide={onHide}
                    id={id}
                    resumeNotarization={resumeNotarization}
                    addWitness={addWitness}
                    downloadDocument={downloadDocument}
                    auditReport={auditReport}                    
                    downloadJournal={downloadJournal}
                />
            )}
        </Modal>
    )
}
