import React, { Suspense, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import { useSelector } from "react-redux";
// import * as actions from "../../_redux/notarization_redux/notarizationActions";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

const useStyles = makeStyles(theme => ({
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7)
  }
}));

const StyledBadge = withStyles(theme => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""'
    }
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0
    }
  }
}))(Badge);

export default function NotaryCard({
  notary,
  mode = "notary",
  chooseNotaryHandler,
  onlineNotaries1
}) {
  const classes = useStyles();
  const [witnessopt, setwitnessopt] = useState({ witnesses: "" });

  const { auth, notarization } = useSelector(state => state);

  const { witnesses, onlineNotaries } = notarization;
  const [existingwitnessopt, setexistingwitnessopt] = useState([]);
  let lang = [];
  notary.Languages.map(el => lang.push(el.name));

  let formatedDate = "";
  if (notary.createdAt !== null) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    formatedDate = new Date(notary.createdAt).toLocaleDateString(
      undefined,
      options
    );
  }

  useEffect(() => {
    if (
      mode == "witness" &&
      existingwitnessopt.length < 1 &&
      witnesses.length > 0
    ) {
      let witnessobj = [];
      for (let w = 0; w < witnesses.length; w++)
        witnessobj.push(witnesses[w].userId);

      setexistingwitnessopt(witnessobj);
    }
  });

  return (
    // <>
    //   {isOnline &&

    <Grid item xs={4} id={notary.id}>
      <div
        style={{
          border: "1px solid #E4E6EF",
          boxShadow: "0px 0px 7px -3px #ccc",
          borderRadius: "10px",
          padding: "20px"
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <StyledBadge
              overlap="circle"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              variant="dot"
            >
              <Avatar
                className={classes.large}
                alt="profile pic"
                src={
                  notary.profile_picture !== null
                    ? `${notary.profile_picture.baseUrl}/${notary.profile_picture.path}`
                    : toAbsoluteUrl("/media/users/blank.png")
                }
              >
                {notary.firstName.charAt(0).toUpperCase()}
              </Avatar>
            </StyledBadge>
          </Grid>
          <Grid item xs={8}>
            {auth.user.Role.code === "user" && (
              <Button
                style={{
                  backgroundColor: "transparent",
                  borderColor: "#1840d2",
                  color: "#000",
                  minWidth: "50%",
                  float: "right"
                }}
                id={notary.id}
                onClick={chooseNotaryHandler}
              >
                Choose Notary
              </Button>
            )}
          </Grid>
        </Grid>
        <p className="pt-7">
          Name:{" "}
          <span style={{ fontWeight: "600" }}>
            {notary.firstName} {notary.lastName}
          </span>
        </p>
        {/* <p>Member Since: <span style={{ fontWeight: '600' }}>{formatedDate}</span></p> */}
        <p>
          Type:{" "}
          <span style={{ fontWeight: "600" }}>
            {notary.notaryProfile.notaryTypes.map((el, i) => (
              <span>
                {el.type}
                {i < notary.notaryProfile.notaryTypes.length - 1
                  ? ", "
                  : ""}{" "}
              </span>
            ))}
          </span>
        </p>
        <p>
          State: <span style={{ fontWeight: "600" }}>{notary.state}</span>
        </p>
        <p>
          Languages:{" "}
          <span style={{ fontWeight: "600" }}>
            {lang.length > 0 ? lang.join(", ") : "N/A"}
          </span>
        </p>
        {auth.user.Role.code === "user" && (
          <p className="mb-0">
            {auth.user.primaryNotaryId &&
            auth.user.primaryNotaryId == notary.id ? (
              ""
            ) : (
              <>
                <input
                  type="checkbox"
                  name="make_primary_notary"
                  className={"pl-0 make_primary_notary_" + notary.id}
                  value={notary.id}
                  className="pl-0"
                />
                &nbsp;
                <span style={{ color: "#ccc" }}>Make primary notary</span>
              </>
            )}
          </p>
        )}

        {auth.user.Role.code === "notary" && (
          <p className="mb-0">
            {witnesses.length > 0 &&
            existingwitnessopt.indexOf(notary.id) > -1 ? (
              <span>Already Witnessed</span>
            ) : (
              <span>
                <input
                  type="checkbox"
                  name="make_witness[]"
                  value={notary.id}
                  className="pl-0"
                />{" "}
                &nbsp;
                <span style={{ color: "#ccc" }}>Make Witness</span>
              </span>
            )}
          </p>
        )}
      </div>
    </Grid>
    //   }
    // </>
  );
}
