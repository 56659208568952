import axios from "axios";
import React, { useState } from "react";
import { Modal, Button, Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import qs from "query-string-object";

import Alert from "@material-ui/lab/Alert";
import * as actions from "../_redux/notarization_redux/notarizationActions";

export default function ReadyBankView({
  openModal,
  userID,
  headers,
  setModal,
  setNotification
}) {
  const [AccountName, setAccountName] = useState("");
  const [loading, setLoading] = useState(false);
  const [AccountNumber, setAccountNumber] = useState("");
  const [RoutingNumber, setRoutingNumber] = useState("");
  const [makeDefault, setMakeDefault] = useState(false);
  const [error, setError] = useState({ isError: false, message: "" });
  const [success, setSuccess] = useState({ isSuccess: false, message: "" });
  const { auth } = useSelector(state => state);
  const [tokenID, settokenID] = useState("");
  const [bankTokenID, setbankTokenID] = useState("");

  const dispatch = useDispatch();

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };
  const handleSubmit = async event => {
    if (!AccountName || !RoutingNumber || !AccountNumber) {
      setError({
        isError: true,
        message: "Please fill out the mandatory fields"
      });
    } else {
      event.preventDefault();

      enableLoading();
      const headers = {
        Authorization: `Bearer ${auth.authToken}`,
        Accept: "application/json"
      };

      let bank_account = {
        country: "US",
        currency: "usd",
        account_holder_name: AccountName,
        account_holder_type: "individual",
        routing_number: RoutingNumber,
        account_number: AccountNumber
      };

      let token = "";
      dispatch(actions.fetchBankAccountTokens(bank_account, headers)).then(
        response => {
          token = response;

          if (response) {
            if (response.data && "error" == response.data.status) {
              setError({ isError: true, message: response.data.message });
              disableLoading();
            } else if (
              response.data &&
              "ok" == response.data.status &&
              response.data.token.id &&
              response.data.token.bank_account
            ) {
              settokenID(response.data.token.id);
              setbankTokenID(response.data.token.bank_account.id);

              const headers = {
                Authorization: `Bearer ${auth.authToken}`,
                "Content-Type": "application/json",
                Accept: "application/json"
              };

              dispatch(
                actions.fetchAddBankAccount(
                  {
                    token: response.data.token.id,
                    accountId: response.data.token.bank_account.id,
                    default: makeDefault
                  },
                  headers,
                  setNotification
                )
              ).then(bankObject => {
                disableLoading();
                if (bankObject.data && bankObject.data.bank) setModal(false);
                else {
                  setError({
                    isError: false,
                    message: bankObject.data.message
                  });
                  disableLoading();
                }
              });
            }
          }
        }
      );
    }
  };

  const accountNameValidationHandler = e => {
    let name = e.target.value;
    let isnum = /^[\d|\s|\W]+$/.test(name);
    if (!isnum) {
      setAccountName(name);
    }
  };

  return (
    <Modal show={openModal} size="lg" centered className="addBankModal">
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Bank Information
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container style={{ padding: 0 }}>
          {error.isError && (
            <Row className="mb-6">
              <Alert severity="error" style={{ width: "100%" }}>
                {error.message}
              </Alert>
            </Row>
          )}

          <Row className="mb-6">
            <Col xs={12} md={12}>
              <TextField
                required
                label="Account Holder Name"
                value={AccountName}
                onChange={accountNameValidationHandler}
                margin="dense"
                variant="outlined"
                placeholder="John Doe"
                InputLabelProps={{ shrink: true }}
                inputProps={{ style: { height: "15px" } }}
                style={{ width: "100%" }}
              />
            </Col>
          </Row>

          <Row className="mb-6">
            <Col xs={12} md={12}>
              <TextField
                required
                label="Routing Number"
                value={RoutingNumber}
                onChange={e => setRoutingNumber(e.target.value)}
                margin="dense"
                variant="outlined"
                style={{ width: "100%" }}
                InputLabelProps={{ shrink: true }}
              />
            </Col>
          </Row>

          <Row className="mb-6">
            <Col xs={12} md={12}>
              <TextField
                required
                label="Account Number"
                value={AccountNumber}
                onChange={e => setAccountNumber(e.target.value)}
                margin="dense"
                variant="outlined"
                style={{ width: "100%" }}
                InputLabelProps={{ shrink: true }}
              />
            </Col>
          </Row>

          <p className="mb-0" style={{ float: "left" }}>
            <Checkbox
              checked={makeDefault}
              onChange={e => setMakeDefault(e.target.checked)}
              value="make_default"
              color="primary"
              inputProps={{
                "aria-label": "secondary checkbox"
              }}
            />
            Make Primary
          </p>
        </Container>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button
          style={{
            backgroundColor: "#effff4",
            borderColor: "#effff4",
            color: "#00c05d",
            minWidth: "135px"
          }}
          onClick={() => setModal(false)}
          className="mr-8"
        >
          Cancel
        </Button>
        <Button
          style={{
            backgroundColor: "#00c05d",
            borderColor: "#00c05d",
            minWidth: "135px"
          }}
          onClick={handleSubmit}
        >
          Add {loading && <span className="ml-3 spinner spinner-white"></span>}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
