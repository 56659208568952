import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/notarization_redux/notarizationActions";
// import * as columnFormatters from "../table/column-formatters";
import { useTableUIContext } from "../../notarization_listing/dashboard/TableUIContext";

export function CustomAuditReportTable() {
  // Customers UI Context
  const customersUIContext = useTableUIContext();
  //   const customersUIProps = useMemo(() => {
  //     return {
  //       openEditCustomerDialog: customersUIContext.openEditCustomerDialog
  //     };
  //   }, [customersUIContext]);

  const { auth, notarization } = useSelector(state => state);
  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };
  const { customizeTop5Journals } = notarization;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.fetchTop5Journals(headers, auth.user.Role.code));
  }, [dispatch]);

  // Table columns
  const columns = [
    {
      dataField: "sno",
      text: "#"
    },
    {
      dataField: "Created_at",
      text: "CREATED AT"
    },
    {
      dataField: "user",
      text: "USER"
      //   formatter: columnFormatters.TypeColumnFormatter,
    },
    {
      dataField: "role",
      text: "ROLE"
      // formatter: columnFormatters.WitnessingStatusTypeFormatter,
    },
    {
      dataField: "action",
      text: "ACTION"
      //   formatter: columnFormatters.WitnessingTypeFormatterAction,
    }
  ];

  return (
    <>
      <BootstrapTable
        wrapperClasses="table-responsive"
        bordered={false}
        classes="table table-head-custom table-vertical-center overflow-hidden"
        bootstrap4
        remote
        keyField="id"
        data={customizeTop5Journals === null ? [] : customizeTop5Journals}
        columns={columns}
      >
        {/* <PleaseWaitMessage entities={customizeTop5Journals === null ? [] : customizeTop5Journals} />
        <NoRecordsFoundMessage entities={customizeTop5Journals === null ? [] : customizeTop5Journals} /> */}
      </BootstrapTable>
    </>
  );
}
