import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../_metronic/_partials/controls/AdminCard";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { CustomTable } from "./table/CustomTable";
import DownlaodIcon from "../../media/zeroState/downloadicon.webp";
import * as actions from "../../../app/components/_redux/notarization_redux/notarizationActions";
import { CSVLink } from "react-csv";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Form, Button, Modal } from "react-bootstrap";
import axios from 'axios';

export function TableCard(props) {
  const MIDDLEWARE_URL = process.env.REACT_APP_MIDDLEWARE_URL;

  const { auth, notarization } = useSelector(state => state);
  const { allAdminNotarization, allCSVJournals, paymentDetails } = notarization;
  const dispatch = useDispatch();
  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };
  const [csvProgress, setcsvProgress] = useState(false);
  const [pdfModal, setPdfModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [csvData, setCsvData] = useState([
    [
      "Customer Name",
      "Notary",
      "startDate",
      "endDate",
      "Type of Notarization",
      "Price of Notarization",
      "Number of Seals (Stamps)",
      "scheduledAt",
      "Number of Signers",
      "status",
      "Documents",
      "Signer 1",
      "Signer 2",
      "Signer 3",
      "Signer 4",
      "Witnesses"
    ]
  ]);

  const getPagedNotaries = (pageNumber) => {
    return new Promise((resolve, reject) => {
      dispatch(
        actions.fetchAdminNotarizationForCSV(headers, props.mainexcelfilter, {
          sortOrder: "desc",
          sortField: "id",
          pageNumber: pageNumber,
          pageSize: 1000
        }, true)
      ).then(data => {
        resolve(data.rows)
      });
    })
  }

  const exporttocsvready = () => {
    if (allAdminNotarization && allAdminNotarization.count !== 0) {
      setIsLoading(true);
      let count = Math.ceil(allAdminNotarization.count / 1000)
      const promises = []
      for (let index = 1; index <= count; index++) {
        promises.push(getPagedNotaries(index))
      }
      Promise.all(promises).then((data) => {
        const csvData = formatCsvData(data.flat(1));
        setCsvData(csvData)
        setIsLoading(false);
      })
    }
  };

  const formatCsvData = (csvData) => {

    let formatedCsvData = [[ "Customer Name", "Notary", "startDate", "endDate", "Type of Notarization", "Price of Notarization", "Number of Seals (Stamps)", "scheduledAt", "Number of Signers", "status", "Documents", "Signer 1", "Signer 2", "Signer 3", "Signer 4", "Witnesses" ]];
    const options = { year: "numeric", month: "long", day: "numeric" };

    if (csvData.length > 2) {

      csvData.forEach(journals => {

        let startdate = (journals.scheduledAt) ? new Date(journals.scheduledAt).toLocaleDateString(undefined, options) : "";
        let endDate = (journals.endDate) ? new Date(journals.endDate).toLocaleDateString(undefined, options) : "";
        let typeofnotarization = journals.type ? journals.type : "";

        let paymentBreakDown = 0;
        let filteredWitnesses = journals.Witnesses.length > 0 ? journals.Witnesses.filter(el => el.status !== "rejected"): [];

        let totalPrice = "--";
        if (journals?.Payments?.length > 0) {
          totalPrice = 0;
          totalPrice += (journals?.Payments[0]?.amount/100);
          totalPrice += paymentBreakDown;
          totalPrice = "$" + totalPrice.toFixed(2);
        }
        
        let journalinfo = [];
        for (let icount = 0; icount < journals.journalDocs.length; icount++ ) {
          if (journals.journalDocs[icount].File.filename) {
            journalinfo.push(journals.journalDocs[icount].File.baseUrl + "/" + journals.journalDocs[icount].File.path);
          }
        }

        let notaryDetails = journals?.notary ? journals.notary.firstName + " " + journals.notary.lastName + "  " + journals.notary.email + "  " + journals.notary.phone:  ""
        let tmpdata = [
          journals?.customer?.firstName + " " + journals?.customer?.lastName,
          notaryDetails,
          startdate,
          endDate,
          typeofnotarization,
          totalPrice,
          journals.sealCount,
          startdate,
          journals.Signers.length,
          journals.status,
          journalinfo.join(" - "),
        ];

        if(journals?.Signers) {
          for (let icount = 0; icount < 4; icount++) {
            let signerinfo = "-";
            if (journals.Signers[icount]) {
              signerinfo = journals.Signers[icount].firstName + " " + journals.Signers[icount].lastName + " " + journals.Signers[icount].email +
                " \r\n" + journals.Signers[icount].address + " " + journals.Signers[icount].city + " " +
                journals.Signers[icount].state + " - " + journals.Signers[icount].zip;

              if (journals.Signers[icount].IP)
                signerinfo += " - " + journals.Signers[icount].IP;

              if (journals.Signers[icount].bypassReason)
                signerinfo += " - " + journals.Signers[icount].bypassReason;

              if (!journals.Signers[icount].bypassKBA)
                signerinfo += " - " + MIDDLEWARE_URL;
            }
            tmpdata.push(signerinfo);
          }
        }

        let witnessinfo = [];
        if(journals?.Witnesses && journals?.Witnesses.length) {
          for ( let icount = 0; icount < journals.Witnesses.length; icount++ ) {
            if (journals.Witnesses[icount].User) {
              let signerinfo = journals.Witnesses[icount].User.firstName + " " + journals.Witnesses[icount].User.lastName + " " + journals.Witnesses[icount].User.email;
              if (journals.Witnesses[icount].IP)
                signerinfo += " " + journals.Witnesses[icount].IP;

              witnessinfo.push(signerinfo);
            }
          }
        }
        tmpdata.push(witnessinfo.join(" "));

        formatedCsvData.push(tmpdata);
      })
    }
    return formatedCsvData;
  }


  const { allAdminNotaries, allAdminNotariesQuery } = notarization;

  const useStyles = makeStyles(() => ({
    cstm_padding: {
      paddingLeft: "30px",
      paddingRight: "30px",
      marginTop: "30px",
      marginBottom: "1rem"
    },
    cstm_field: {
      width: "100%",
      marginTop: 0
    }
  }));
  const classes = useStyles();
  const [notaryName, setnotaryName] = useState("");
  const [notaryEmail, setnotaryEmail] = useState("");
  const [statusfilter, setstatusfilter] = useState("");
  const [fromDate, setfromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [search, setSearch] = useState(false);
  const [searchClicked, setSearchClicked] = useState(false);

  // Handler Functions

  const handleChangeFromDate = e => {
    let mainfilter = [];
    mainfilter = props.mainfilter;

    if (e.target.value) {
      let datechange = new Date(e.target.value);
      let datestring = datechange.toISOString();

      if (!mainfilter.createdAt) {
        mainfilter.createdAt = { from: datestring, matchType: "range" };
      } else {
        mainfilter.createdAt["from"] = datestring;
      }
    } else {
      checkanddeletedate("from");
    }

    props.setfromDate(e.target.value);

    props.setmainfilter(mainfilter);
  };

  const handleChangeToDate = e => {
    let mainfilter = [];
    mainfilter = props.mainfilter;

    if (e.target.value) {
      let datechange = new Date(e.target.value);
      let datestring = datechange.toISOString();

      if (!mainfilter.createdAt) {
        mainfilter.createdAt = { to: datestring, matchType: "range" };
      } else {
        datestring = datestring.replace("T00:00:00.000Z", "T23:59:59.000Z");
        mainfilter.createdAt["to"] = datestring;
      }
    } else {
      checkanddeletedate("to");
    }

    props.settoDate(e.target.value);
    props.setmainfilter(mainfilter);
  };

  const checkanddeletedate = mode => {
    let mainfilter = [];
    mainfilter = props.mainfilter;

    if (mainfilter.createdAt) {
      if (mainfilter.createdAt["from"] && mainfilter.Main.createdAt["to"]) {
        delete mainfilter.createdAt[mode];
      } else delete mainfilter.createdAt;
    }
  };

  const setingclientfilter = text => {
    let mainfilter = [];
    let mainexcelfilter = [];
    mainfilter = props.mainfilter;
    mainexcelfilter = props.mainexcelfilter;
    if (text) {
      mainfilter.include[1].id = { value: text };
      mainexcelfilter.include[6].id = { value: text };

      props.setclient(text);
    } else {
      if (
        mainfilter.include &&
        mainfilter.include[1] &&
        mainfilter.include[1].id
      )
        delete mainfilter.include[1];

      props.setclient(text);
    }

    props.setmainfilter(mainfilter);
  };

  const settingstatusfilter = text => {
    let mainfilter = [];
    let mainexcelfilter = [];
    mainfilter = props.mainfilter;
    mainexcelfilter = props.mainexcelfilter;
    if (text) {
      mainfilter.status = { value: [text], matchType: "in" };
      mainexcelfilter.status = { value: [text], matchType: "in" };
      props.setjournalstatus(text);
    } else {
      if (mainfilter.status) {
        delete mainfilter.status;
        delete mainexcelfilter.status;
      }
      props.setjournalstatus(text);
    }

    props.setmainfilter(mainfilter);
  };

  const settingaFilter = (text, mode) => {
    let mainfilter = [];
    let mainexcelfilter = [];
    if (text) {
      if ("firstName" == mode) props.setnotaryName(text);

      mainfilter = props.mainfilter;
      mainexcelfilter = props.mainexcelfilter;

      mainfilter.include[0].or = {
        values: [
          { value: text, name: "firstName" },
          { value: text, name: "email" },
          { value: text, name: "lastName" }
        ]
      };
      mainexcelfilter.include[4].or = {
        values: [
          { value: text, name: "firstName" },
          { value: text, name: "email" },
          { value: text, name: "lastName" }
        ]
      };
    } else {
      mainfilter = props.mainfilter;
      mainexcelfilter = props.mainexcelfilter;

      if (
        mainfilter.include &&
        mainfilter.include[0] &&
        mainfilter.include[0].or &&
        mainfilter.include[0].or["values"]
      )
        delete mainfilter.include[0].or;

      if (
        mainexcelfilter.include &&
        mainexcelfilter.include[4] &&
        mainexcelfilter.include[4].or &&
        mainexcelfilter.include[4].or["values"]
      )
        delete mainexcelfilter.include[4].or;

      props.setnotaryName("");
    }
    props.setmainfilter(mainfilter);
  };

  const applyFilterHandler = name => {
    setCsvData([
      [
        "Customer Name",
        "Notary",
        "startDate",
        "endDate",
        "Type of Notarization",
        "Price of Notarization",
        "Number of Seals (Stamps)",
        "scheduledAt",
        "Number of Signers",
        "status",
        "Documents",
        "Signer 1",
        "Signer 2",
        "Signer 3",
        "Signer 4",
        "Witnesses"
      ]
    ]);
    setcsvProgress(false);

    if (name === "search") {
      setSearch(!search);
      setSearchClicked(true);
      props.applyFilter();
    } else if (name === "clear") {
      setCsvData([
        [
          "Customer Name",
          "Notary",
          "startDate",
          "endDate",
          "Type of Notarization",
          "Price of Notarization",
          "Number of Seals (Stamps)",
          "scheduledAt",
          "Number of Signers",
          "status",
          "Documents",
          "Signer 1",
          "Signer 2",
          "Signer 3",
          "Signer 4",
          "Witnesses"
        ]
      ]);
      settingstatusfilter("");
      setingclientfilter("");
      settingaFilter("", "firstName");
      props.setfromDate("");
      props.settoDate("");
      setstatusfilter("");
      setSearch(!search);
      //props.applyFilter();
    }
  };

  const getTimeZone = ( date ) => {
    if( !date ) {
      date = new Date();
    }

    let timeString = date.toTimeString();
    let [time , ...timeZone] = timeString.split(" ")
    
    timeZone = timeZone.join(" ")
    
    return timeZone;
  }

  const sendAdminNotarizationPdf = () => {

    let currentTimeZone = getTimeZone();

    let body = {
      timeZone: currentTimeZone,
      filters: props.mainfilter
    }

    dispatch(actions.sendAdminNotarizationPdf( body, headers))
    .then(()=>{
      setPdfModal(true);  
    })
  }
  const postJobNotifcation = () =>{
    axios.post(
      `${MIDDLEWARE_URL}/api/admin/send-new-job-notification`,
      {},
      { headers: headers }
    )
      .then((response) => {
        console.log(response);
      })
  }

  return (
    <>
      {/* <Card>
        <CardHeader> */}
      <div className="postJobButton">
        <button type="button" className="btn btn-primary" onClick={postJobNotifcation}>
            Post notification
          </button>
      </div>
        {/* </CardHeader>
      </Card> */}
      <Card>
        
        <CardHeader className="py-6">
          <CardHeaderToolbar style={{ minWidth: "570px" }}>
            {
              isLoading ? (
                <Button
                  variant={"success"}
                  className="mr-3"
                  disabled
                  style={{width:300}}
                >
                  <CircularProgress size={15} />
                </Button>
              ) : (
                csvData.length < 2 && allAdminNotarization ?
                  (
                    <Button
                      variant={"success"}
                      onClick={exporttocsvready}
                      className="mr-3"
                      style={{width:300}}
                    >
                      Generate CSV
                    </Button>
                  )
                  :
                  (
                    <CSVLink 
                      data={csvData}
                      filename="Notaries.csv"
                      style={{width:300}}
                      >
                      <Button
                        className="mr-3"
                        variant={"success"}
                        style={{width: "max-content"}}
                      >
                        {/* <img src={DownlaodIcon} className="downlaodIcon" alt="" /> */}
                        Download CSV
                      </Button>
                    </CSVLink>
                  )
              )
            }
            <>
              <button
                type='button'
                className='btn downloadcsvbtn'
                onClick={() => sendAdminNotarizationPdf()}
                style={{
                    backgroundColor: '#f7f7f7',
                    color: '#4abd54',
                    marginRight: '10px',
                }}
              >
                Generate PDF
              </button>
              <Modal
                show={pdfModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                    Attention
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <span style={{fontSize:15}}>
                    Your journal is being processed.  Once completed a link will be sent to your email
                  </span>
                </Modal.Body>
                <Modal.Footer>
                  <Button 
                    onClick={() => setPdfModal(false)}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </>

            <div style={{ width: "80%", marginRight: "10px" }}>
              <Form.Control
                type="text"
                className="mr-2"
                placeholder="Search notary by name/email"
                value={props.notaryName}
                onChange={e => settingaFilter(e.target.value, "firstName")}
                style={{ width: "100%" }}
              />
            </div>

            <div style={{ width: "80%", marginRight: "10px" }}>
              <TextField
                type="date"
                required
                label="From Date"
                value={props.fromDate}
                onChange={handleChangeFromDate}
                margin="dense"
                variant="outlined"
                className={classes.cstm_field}
                inputProps={{
                  max: new Date().toISOString().split("T")[0]
                }}
                color="secondary"
                InputLabelProps={{
                  shrink: true
                }}
                style={{ width: "100%", marginBottom: "10px" }}
              />
              <TextField
                type="date"
                required
                label="To Date"
                value={props.toDate}
                onChange={handleChangeToDate}
                margin="dense"
                variant="outlined"
                className={classes.cstm_field}
                inputProps={{
                  max: new Date().toISOString().split("T")[0]
                }}
                color="secondary"
                InputLabelProps={{
                  shrink: true
                }}
                style={{ width: "100%" }}
              />
            </div>

            <div style={{ width: "80%", marginRight: "10px" }}>
              <Form.Group
                className="mb-0 mr-2"
                style={{ width: "100%", marginBottom: "10px" }}
              >
                <Form.Control
                  as="select"
                  name="status"
                  onChange={e => setingclientfilter(e.target.value)}
                  value={props.client}
                  style={{ width: "100%", marginBottom: "10px" }}
                >
                  <option value="">All PS</option>
                  {allAdminNotaries &&
                    allAdminNotaries.rows &&
                    allAdminNotaries.rows.map(el => (
                      <>
                        {el.roleId == 4 ? (
                          <option value={el.id}>
                            {el.firstName} {el.lastName}
                          </option>
                        ) : (
                          ""
                        )}
                      </>
                    ))}
                </Form.Control>
              </Form.Group>

              <Form.Group
                className="mb-0 mr-2"
                style={{ width: "100%", marginBottom: "10px" }}
              >
                <Form.Control
                  as="select"
                  name="status"
                  onChange={e => setingclientfilter(e.target.value)}
                  value={props.client}
                  style={{ width: "100%", marginBottom: "10px" }}
                >
                  <option value="">All Users</option>
                  {allAdminNotaries &&
                    allAdminNotaries.rows &&
                    allAdminNotaries.rows.map(el => (
                      <>
                        {el.roleId != 4 ? (
                          <option value={el.id}>
                            {el.firstName} {el.lastName}
                          </option>
                        ) : (
                          ""
                        )}
                      </>
                    ))}
                </Form.Control>
              </Form.Group>

              {!props.schedule && !props.completed && (
                <Form.Group
                  className="mb-0 mr-2"
                  style={{ width: "100%", marginBottom: "10px" }}
                >
                  <Form.Control
                    as="select"
                    name="status"
                    onChange={e => settingstatusfilter(e.target.value)}
                    value={props.journalstatus}
                  >
                    <option value="">All Statuses</option>
                    <option value="draft">Draft</option>
                    <option value="Progress">In Progress</option>
                    <option value="Scheduled">Scheduled</option>
                    <option value="rescheduled">Re Scheduled</option>
                    <option value="scheduled-unclaimed">
                      Scheduled Unclaimed
                    </option>
                    <option value="failed">Failed</option>
                    <option value="completed">Completed</option>
                  </Form.Control>
                </Form.Group>
              )}
            </div>

            <button
              type="button"
              className="btn btn-primary mr-3"
              onClick={e => applyFilterHandler("search")}
            >
              Search
            </button>
            <button
              type="button"
              className="btn btn-primary mr-3"
              onClick={e => applyFilterHandler("clear")}
            >
              Clear
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <CustomTable
            setmainfilter={props.setmainfilter}
            mainfilter={props.mainfilter}
            statusfilter={statusfilter}
            search={search}
            applyFilterHandler={applyFilterHandler}
            searchClicked={searchClicked}
            setSearchClicked={setSearchClicked}
            schedule={props.schedule ? true : false}
            completed={props.completed ? true : false}
          />
        </CardBody>
      </Card>
    </>
  );
}
