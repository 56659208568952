/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/_helpers'
import { ContentRoute } from '../../../../_metronic/layout'
import Login from './Login'
import Signupprofessional from './Signupprofessional'
import Registration from './Registration'
import NotaryRegistration from './NotaryRegistration'
import ForgotPassword from './ForgotPassword'
import '../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss'
import CodePage from './CodePage'
import ChangePassword from './ChangePassword'

export function AuthPage() {
    return (
        <>
            <div className='d-flex flex-column flex-root'>
                {/*begin::Login*/}
                <div
                    className='login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white'
                    id='kt_login'>
                    {/*begin::Aside*/}

                    {/*begin::Content*/}
                    <div className='flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden'>
                        {/* begin::Content body */}
                        <div className='d-flex flex-column-fluid flex-center mt-30 mt-lg-0'>
                            <Switch>
                                <ContentRoute
                                    path='/auth/login'
                                    component={Login}
                                />
                                <ContentRoute
                                    path='/auth/Signupprofessional'
                                    component={Signupprofessional}
                                />
                                <ContentRoute
                                    path='/auth/registration'
                                    component={Registration}
                                />
                                <ContentRoute
                                    path='/auth/notaryregistration'
                                    component={NotaryRegistration}
                                />
                                <ContentRoute
                                    path='/auth/forgot-password'
                                    component={ForgotPassword}
                                />
                                <ContentRoute
                                    path='/auth/code-page'
                                    component={CodePage}
                                />
                                <ContentRoute
                                    path='/auth/change-password'
                                    component={ChangePassword}
                                />
                                <Redirect
                                    from='/auth'
                                    exact={true}
                                    to='/auth/login'
                                />
                                <Redirect to='/auth/login' />
                            </Switch>
                        </div>
                    </div>

                    <div
                        className='order-1 order-lg-2 flex-column-auto flex-lg-row-fluid d-flex flex-column p-7 w-25 mainImageDiv'
                        style={{
                            backgroundImage: `url(${toAbsoluteUrl(
                                '/media/bg/Group1.jpg'
                            )})`,
                        }}>
                        {/*begin: Aside Container*/}
                        <div className='d-flex flex-row-fluid flex-column justify-content-between'>
                            {/* start:: Aside content */}
                            <div className='flex-column-fluid d-flex flex-column justify-content-center'>
                                <h3 className='font-size-h1 mb-5 text-white'>
                                    Welcome to Cyberize It, LLC
                                </h3>
                                <p className='font-weight-lighter text-white opacity-80'>
                                    Using innovation, technology, and electronic
                                    signatures Cyberize It, LLC can legally
                                    notarize your document, anytime – anywhere!
                                    The online process can be done from your
                                    cell phone, tablet, or computer.
                                    <br />
                                    <br />
                                    Every Notary on our team has been personally
                                    validated to ensure they are legal to
                                    perform the Remote Online Notary Act.
                                </p>
                            </div>
                            {/* end:: Aside content */}
                        </div>
                        {/*end: Aside Container*/}
                    </div>
                    {/*end::Content*/}
                </div>
                {/*end::Login*/}
            </div>
        </>
    )
}
