import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, Switch, Route, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useCookies } from "react-cookie";
import * as actions from "../socket/_redux/socket_redux/socketActions";
//import "./Chat.css";

const Socketinit = () => {
  const { auth, socket } = useSelector(state => state);
  const [isprocess, setisprocess] = useState(false);
  const [isprocesslocation, setisprocesslocation] = useState("");
  const location = useLocation();
  const dispatch = useDispatch();
  let generalnotificationbit = 0;
  useEffect(() => {
    // && (!localStorage.getItem("socketID"))

    //("notary" == auth.user.Role.code) &&
    if (socket.object.length < 1 && !isprocess) {

      let signerId = null;
      if("/id-proofing/" === location.pathname) {
        const searchParams = new URLSearchParams(location.search);
        signerId = searchParams.get("id");
      }

      dispatch(actions.connect(signerId ? { user: {id: signerId} } : auth, location));
      setisprocess(true);
    }

    if (isprocesslocation != location.pathname) {
      setisprocesslocation(location.pathname);

      if (
        auth &&
        auth.user &&
        auth.user.notaryProfile &&
        auth.user.notaryProfile &&
        auth.user.notaryProfile.profile_completed &&
        auth.user.notaryProfile.isApproved
      )
        dispatch(actions.getandcheckNotifications(auth));
    }
  });

  return <div></div>;
};

export default Socketinit;
