import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/notarization_redux/notarizationActions";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses
} from "../../../../_metronic/_helpers";
import * as uiHelpers from "../TableUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../_metronic/_partials/controls";
import { useTableUIContext } from "../TableUIContext";

export function CustomTable() {
  // Customers UI Context
  const customersUIContext = useTableUIContext();
  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      setIds: customersUIContext.setIds,
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams
    };
  }, [customersUIContext]);

  const { auth, notarization } = useSelector(state => state);
  const { custommizeTransactionHistory, transactionHistory } = notarization;
  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };
  // Customers Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.fetchMyPayments(headers, customersUIProps.queryParams));
  }, [customersUIProps.queryParams, dispatch]);
  // Table columns
  const columns = [
    {
      dataField: "sno",
      text: "#"
    },
    {
      dataField: "date",
      text: "DATE",
      // sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: "bankName",
      text: "BANK NAME",
      sort: true,
      sortCaret: sortCaret
      // formatter: columnFormatters.TypeColumnFormatter,
    },
    {
      dataField: "accNo",
      text: "ACCOUNT NUMBER",
      sort: true,
      sortCaret: sortCaret,
      // formatter: columnFormatters.StatusColumnFormatter,
      headerSortingClasses
    },
    {
      dataField: "amount",
      text: "AMOUNT",
      sort: true,
      sortCaret: sortCaret,
      // formatter: columnFormatters.StatusColumnFormatter,
      // formatter: columnFormatters.PaymentsTypeFormatterAction,
      headerSortingClasses
    }
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: transactionHistory === null ? 0 : transactionHistory.count,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: customersUIProps.queryParams.pageSize,
    page: customersUIProps.queryParams.pageNumber
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              // isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="id"
                data={
                  custommizeTransactionHistory === null
                    ? []
                    : custommizeTransactionHistory
                }
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  customersUIProps.setQueryParams
                )}
                {...paginationTableProps}
              ></BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
