import React, { useState } from "react";
import { TableUIWitnessingContext } from "../components/signwitnesss/TableUIWitnessingContext";
import { TableAuditReportCard } from "../components/audit_report/TableAuditReportCard";
import { NewNotarization } from "../pages/NewNotarization";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../components/_redux/notarization_redux/notarizationActions";

export function AuditReport() {
  const [actionModal, setActionModal] = useState(false);
  const [notaryID, setNotaryID] = useState(null);
  const customersUIEvents = {
    openEditCustomerDialog: id => {
      setActionModal(true);
      setNotaryID(id);
    }
  };
  const dispatch = useDispatch();
  const { Clients } = useSelector(state => state.notarization);

  return (
    <TableUIWitnessingContext customersUIEvents={customersUIEvents}>
      <TableAuditReportCard />
    </TableUIWitnessingContext>
  );
}
