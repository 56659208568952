import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./TableUIHelpers";

const TableUIContext = createContext();

export function useTableUIContext() {
  return useContext(TableUIContext);
}

export const TableUIConsumer = TableUIContext.Consumer;

export function TableUIProvider(props) {
  let { customersUIEvents, children } = props;

  // export function TableUIProvider({children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initCustomer = {
    id: undefined,
    title: "",
    type: "",
    date_time: "",
    noOfSigners: 0,
    status: 1
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initCustomer,
    // newCustomerButtonClick: TableUIEvents.newCustomerButtonClick,
    openEditCustomerDialog: customersUIEvents.openEditCustomerDialog,
    downloadDocument: customersUIEvents.downloadDocument
    // openDeleteCustomerDialog: TableUIEvents.openDeleteCustomerDialog,
    // openDeleteTableDialog: TableUIEvents.openDeleteTableDialog,
    // openFetchTableDialog: TableUIEvents.openFetchTableDialog,
    // openUpdateTableStatusDialog: TableUIEvents.openUpdateTableStatusDialog
  };

  return (
    <TableUIContext.Provider value={value}>{children}</TableUIContext.Provider>
  );
}
