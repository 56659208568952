export const CustomerStatusCssClasses = [
  "danger",
  "success",
  "info",
  "primary",
  "warning",
  "success"
];
export const CustomerStatusTitles = [
  "Pending",
  "In Progress",
  "Completed",
  "Draft",
  "Scheduled",
  "initiated"
];
export const CustomerTypeCssClasses = ["success", "primary", ""];
export const CustomerTypeTitles = ["Business", "Individual", ""];
export const CustomerTypeStatus = ["In Progress", "Completed", ""];
export const CustomerTypeAction = ["Join Session", "Session Completed", ""];
export const CustomerTypePaymentsAction = ["Paid", ""];
export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "3", value: 3 },
  { text: "5", value: 5 },
  { text: "10", value: 10 }
];
export const initialFilter = {
  sortOrder: "asc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 10
};
