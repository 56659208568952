import React from "react";
import Dropzone from "react-dropzone";
import { makeStyles } from "@material-ui/core/styles";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import LinearProgress from "@material-ui/core/LinearProgress";
const useStyles = makeStyles(() => ({
  cstm_border: {
    border: "2px solid #ccc",
    borderStyle: "dashed",
    padding: "10px 0",
    margin: "20px auto",
    width: "37%",
    backgroundColor: "#fafbff",
    cursor: "pointer"
  }
}));

export default function DropZoneUploader({ getFiles, disabled, uploading }) {
  const classes = useStyles();

  return (
    <Dropzone
      onDrop={getFiles}
      accept="application/pdf, application/docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/jpeg, image/png, image/jpg"
      disabled={disabled}
      // minSize={70000}
      maxSize={10485760}
    >
      {({ getRootProps, getInputProps }) => (
        <>
          <section className={classes.cstm_border}>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div style={{ width: "100%", padding: "20px" }}>
                <div
                  style={{ width: "20%", float: "left", paddingBottom: "50px" }}
                  className="pr-30"
                >
                  <img
                    src={toAbsoluteUrl("/media/own/file.png")}
                    alt="file"
                    style={{ width: "80px" }}
                    className="pb-3"
                  />
                </div>
                <div style={{ textAlign: "left" }}>
                  <h5 className="pb-3" style={{ color: "#757576" }}>
                    Upload your files
                  </h5>
                  <p style={{ color: "#757576" }}>
                    Accepted file types: .jpg, .jpeg, .doc & .pdf <br />
                    <span style={{ color: "#2f4fd6", fontWeight: "bold" }}>
                      Drag and drop your files here or click here to upload.
                    </span>
                  </p>
                  <p
                    style={{ fontStyle: "italic", color: "#757576" }}
                    className="pb-0 mb-0"
                  >
                    MAX FILE SIZE IS 10MB
                  </p>
                </div>
              </div>
            </div>
          </section>
          {uploading && (
            <LinearProgress
              color="secondary"
              style={{ width: "37%", margin: "0 auto" }}
            />
          )}
        </>
      )}
    </Dropzone>
  );
}
