import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import * as actions from "../../components/_redux/notarization_redux/notarizationActions";
import { FilePreviewTable } from "./FilePreviewTable";
import { SignerInfo } from "./SignerInfo";
import { NoFileView } from "./NoFileView";
import { AllButtonsPS } from "./AllButtonsPS";
import { SuccessModal } from "./modals/SuccessModal";
import { PrefenceModal } from "../modals/PrefenceModal";
import { DateTimePickerModal } from "./modals/DateTimePickerModal";
import { AddUser } from "./AddUser";
import { WitnessInfo } from "./WitnessInfo";
import { Comment } from "./Comment";
import { AddCardModal } from "../new_card/AddCardModal";
import { PaynowModals } from "./PaynowModals";
import { PSCard } from "./PSCard";
import { NoCardAvailable } from "./NoCardAvailable";
import { PaymentTable } from "./PaymentTable";

export default function NewNotarizationNotaryRootPS(props) {
  let {
    setChangeMethod,
    setSelectedUser,
    singerInfo,
    setSingerInfo,
    setNoOfSigner,
    noOfSigner,
    disableButton,
    paid,
    setDisableButton,
    draftHandler,
    payNowHandler,
    payNowModal,
    setPayNowModal,
    payPlatformFeeHandler,
    saveLaterSuccess,
    notarizeNowHandler,
    notarizeNowitHandler,
    scheduleHandler,
    datetimeerr,
    schedulerModal,
    setPreferrenceState,
    allAdminStates,
    schedulerCloseHandler,
    schedulerNotarizationHandler,
    notarizeNowSuccessHideHandler,
    saveLaterSuccessHideHandler,
    platformFee,
    headers,
    iscontinue,
    cstmDisable,
    setNotification,
    notarizeNowSuccess,
    selectedUser,
    resumeData,
    isalreadypaid,
    setNoOfWitness,
    resumeNotarization,
    noOfWitness,
    JobPostDoneModal,
    typeFile,
    handleChangeFileType,
    prefstate,
    setprefstate,
    typeNotary,
    setTypeNotary,
    dateTime,
    setDateTime,
    lang,
    setLang,
    jobPostHandler,
    confirmjobPostHandler,
    jobPostDoneMode,
    cpostModal,
    setcpostModal,
    datetimeerrHandler,
    setSelectNotary,
    setSelectNotaryModal,
    selectNotaryModal,
    selectedApprovedNotary,
    setSelectedApprovedNotary,
    comments,
    setComments,
  } = props;
  const [addCard, setAddCard] = useState(false);
  const [deleteModal, setDeleteModal] = useState({ show: false, id: null });
  const [prepay, setprepay] = useState({ show: false, id: null });
  const { auth, notarization } = useSelector(state => state);
  const {
    defaultPaymentCard,
    documents,
    allClients,
    scheduleLoading,
    languages,
    notaryTypes,
    defaultPaymentCard2,
    paymentCards,
    MyNotaries
  } = notarization;
  const [uploading, setUploading] = useState(false);
  const dispatch = useDispatch();
  const getFiles = (newFiles, rejectedFiles) => {
    setUploading(true);
    if (documents.length + newFiles.length > 20) {
      setNotification({
        show: true,
        msg: "You can not upload more than 20 files",
        variant: "error"
      });
      setUploading(false);
    } else {
      if (rejectedFiles.length > 0) {
        let errorCode = rejectedFiles[0].errors[0].code;
        let errorMessage = "Only PDF and Docs file format is acceptable!";
        if (errorCode === "file-invalid-type")
          errorMessage =
            "Only JPG, JPEG, PNG, PDF and Docs file format is acceptable!";
        // else if(errorCode === 'file-too-small') errorMessage = 'Acceptable minimum file size is 70KB!';
        else if (errorCode === "file-too-large")
          errorMessage = "Acceptable maximum file size is 10MB!";
        setNotification({ show: true, msg: errorMessage, variant: "error" });
        setUploading(false);
      }

      if (newFiles.length > 0) {
        setUploading(true);
        let formData = new FormData();
        formData.append("userId", auth.user.id);
        formData.append("type", "journalDoc");
        newFiles.map(file => formData.append("file", file));
        dispatch(
          actions.uploadDocuments(
            formData,
            headers,
            setNotification,
            setUploading,
            null
          )
        );
      }
    }
  };

  useEffect(() => {
    if (selectedUser) {
      let signerinfos = singerInfo;

      if (
        selectedUser &&
        selectedUser.kbaSettings &&
        selectedUser.kbaSettings[0] &&
        selectedUser.kbaSettings[0].bypassAllowed
      ) {
        signerinfos[0].byPassKBA = true;
        signerinfos[0].reason = selectedUser.kbaSettings[0].reason;
        setSingerInfo(signerinfos);
      }
    }
  }, [allClients, selectedUser]);

  const removeFileAction = e =>
    setDeleteModal({ show: true, id: e.currentTarget.id });
  const handleClose = () => setDeleteModal({ show: false, id: null });

  const handlePrepayClose = () => {
    setprepay({ show: false, id: null });
  };

  const prepayhandler = () => {
    setprepay({ show: true, id: null });
  };

  const removeFile = () => {
    handleClose();
    dispatch(actions.deleteDocument(deleteModal.id, headers, setNotification));
  };

  const arrowStyle = {
    fontSize: "36px",
    color: "#909090",
    backgroundColor: "#fbfbfb",
    borderRadius: "50%",
    marginRight: "2px",
    padding: "7px"
  };

  return (
    <>
      <div className="card card-custom">
        <div className="pl-6 pt-7">
          <Link to="/#/dashboard">
            <ArrowBackIcon style={arrowStyle} />{" "}
            <span style={{ fontSize: "14px", color: "#909090" }}>Back</span>
          </Link>
        </div>
        <div className="card-body d-flex align-items-center justify-content-between flex-wrap pt-3 pb-0">
          <div className="mr-2">
            <h3 className="font-weight-bolder">New Notarization</h3>
          </div>
        </div>
        <br />

        {schedulerModal && (
          <DateTimePickerModal
            show={schedulerModal}
            handleClose={schedulerCloseHandler}
            schedulerNotarizationHandler={schedulerNotarizationHandler}
          />
        )}

        {defaultPaymentCard !== null ? (
          <PaymentTable
            setChangeMethod={setChangeMethod}
            card={defaultPaymentCard}
            disabled={disableButton}
          />
        ) : (
          <>
            {"ps" == auth.user.Role.code && 
            ( 
              defaultPaymentCard2 &&
              defaultPaymentCard2.net30Setting
            ) ? (
              <PSCard
                addCard={setAddCard}
                defaultPaymentCard2={defaultPaymentCard2}
                setChangeMethod={setChangeMethod}
                card={defaultPaymentCard}
                disabled={disableButton}
                paymentCards={paymentCards}
              />
            ) : (
              <NoCardAvailable addCard={setAddCard} />
            )}
          </>
        )}
        {addCard && (
          <>
            {" "}
            <AddCardModal
              openModal={addCard}
              setModal={setAddCard}
              setNotification={setNotification}
            />
          </>
        )}

        {payNowModal && (
          <PaynowModals
            platformFee={platformFee}
            payNowModal={payNowModal}
            setPayNowModal={setPayNowModal}
            payPlatformFeeHandler={payPlatformFeeHandler}
            auth={auth}
          />
        )}

        <br />

        <NoFileView
          getFiles={getFiles}
          disabled={disableButton}
          uploading={uploading}
        />
        <br />
        {documents.length > 0 && (
          <FilePreviewTable
            files={documents}
            removeFile={removeFileAction}
            disabled={disableButton}
            typeFile={typeFile}
            handleChangeFileType={handleChangeFileType}
            resumeNotarization={resumeNotarization}
          />
        )}
        <br />
        <SignerInfo
          disabled={disableButton}
          singerInfo={singerInfo}
          setSingerInfo={setSingerInfo}
          setNoOfSigner={setNoOfSigner}
          noOfSigner={noOfSigner}
          user={false}
          selectedUser={selectedUser}
          ps={true}
        />
        <br />
        {cpostModal && (
          <PrefenceModal
            heading="Adding requirements will limit the notarization and could cause delays. Only complete these sections if you specifically require a restriction on the Notary who performs this session."
            allAdminStates={allAdminStates}
            show={setcpostModal}
            lang={lang}
            setLang={setLang}
            typeNotary={typeNotary}
            setTypeNotary={setTypeNotary}
            prefstate={prefstate}
            setprefstate={setprefstate}
            btnHandler={jobPostHandler}
            dateTime={dateTime}
            setDateTime={setDateTime}
            languages={languages}
            notaryTypes={notaryTypes}
            MyNotaries={MyNotaries}
            setSelectNotary={setSelectNotary}
            setSelectNotaryModal={setSelectNotaryModal}
            selectNotaryModal={selectNotaryModal}
            selectedApprovedNotary={selectedApprovedNotary}
            setSelectedApprovedNotary={setSelectedApprovedNotary}
          />
        )}

        {JobPostDoneModal && (
          <SuccessModal
            heading="Success"
            bodyText={jobPostDoneMode}
            show={JobPostDoneModal}
            hide={saveLaterSuccessHideHandler}
          />
        )}

        <Comment
          comments={comments}
          setComments={setComments}
        />
        <br />
        <WitnessInfo
          setNoOfWitness={setNoOfWitness}
          noOfWitness={noOfWitness}
          disabled={disableButton}
        />
        <br />
        <AllButtonsPS
          paynow={payNowHandler}
          paid={paid}
          draftHandler={draftHandler}
          notarizeNowHandler={notarizeNowHandler}
          prepayhandler={prepayhandler}
          user={false}
          resumeData={resumeData}
          notarizeNowitHandler={notarizeNowitHandler}
          resumeNotarization={resumeNotarization}
          confirmjobPostHandler={confirmjobPostHandler}
        />

        <Modal show={deleteModal.show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="danger" onClick={removeFile}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {saveLaterSuccess && (
          <SuccessModal
            heading="Save for later"
            bodyText="This notarization has been successfully saved; it is located in your journal."
            show={saveLaterSuccess}
            hide={saveLaterSuccessHideHandler}
          />
        )}

        {datetimeerr && (
          <SuccessModal
            heading="Error"
            bodyText={datetimeerr}
            show={datetimeerr}
            hide={datetimeerrHandler}
          />
        )}

        {notarizeNowSuccess && (
          <SuccessModal
            heading="Successfully sent to the client"
            bodyText="The notarization has been sent successfully to the client."
            show={notarizeNowSuccess}
            hide={notarizeNowSuccessHideHandler}
          />
        )}
      </div>
    </>
  );
}
