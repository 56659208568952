import React, { useEffect, useState } from "react";
import {useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../_metronic/_partials/controls/AdminCard";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CustomTable } from "./table/CustomTable";
import { Form, Button } from "react-bootstrap";
import axios from 'axios';
import { CSVLink } from "react-csv";

export function TableCard({ adminNotaryFilterHandler = null, filterValues=null, setQueries }) {

  const { auth, notarization } = useSelector(state => state);
  const { allAdminNotaries } = notarization;

  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  const MIDDLEWARE_URL = process.env.REACT_APP_MIDDLEWARE_URL

  const [keyword, setkeyword] = useState("");
  const [search, setSearch] = useState(false);
  const [searchClicked, setSearchClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);

  // Handler Functions
  const handleKeypress = e => {
    if (e.which === 13) applyFilterHandler("search");
  };



  const applyFilterHandler = name => {
    if (name === "search") {
      setSearch(!search);
      setSearchClicked(true);
    } else if (name === "clear") {
      setkeyword("");
      setSearch(!search);
      adminNotaryFilterHandler(name);
    }
  };

  const getPagedNotaries = (pageNumber) => {
    return new Promise((resolve, reject) => {
      let query = {
        lang: "",
        type: "",
        state: "",
        status: "",
        mode: "",
        pageNumber: pageNumber,
        pageSize: 500,
        sortField: 'id',
        sortOrder: 'desc',
      }

      let queryParamStr = "";
      if(filterValues) {
        if(filterValues.language) {
          queryParamStr += `&lang=${filterValues.language}`
        }
        if(filterValues.notaryType) {
          queryParamStr += `&type=${filterValues.notaryType}`
        }
        if(filterValues.state) {
          queryParamStr += `&state=${filterValues.state}`
        }
        if (filterValues.status) {
          queryParamStr += `&status=${filterValues.status}`
        }
        if (filterValues.mode) {
            queryParamStr += `&mode=${filterValues.mode}`
        }
      }

      axios.get(
        `${MIDDLEWARE_URL}/api/admin/all-notary?page=${query.pageNumber}&limit=${query.pageSize}&sortField=${query.sortField}&sortBy=${query.sortOrder}${queryParamStr}`,
        { headers: headers }
      )
        .then((response) => {
          resolve(response.data.AllNotary.rows)
        })
    })
  }

  const generateCsvData = () => {
    if (allAdminNotaries && allAdminNotaries.count !== 0) {
      setIsLoading(true);
      let count = Math.ceil(allAdminNotaries.count / 500)
      const promises = []
      for (let index = 1; index <= count; index++) {
        promises.push(getPagedNotaries(index))
      }
      Promise.all(promises).then((data) => {
        const csvData = formatCsvData(data.flat(1));
        setCsvData(csvData)
        setIsLoading(false);
      })
    }
  }

  const formatCsvData = (csvData) => {

    let formatedCsvData = [["Notary Name", "Sign Up Data", "Email Address", "Phone Number", "City", "State", "Zip", "Address", "Commission #", "Commission State", "Notary Type", "Notary Expiry", "Languages", "Status", "Notarization", "Notary Adobe Email Address", "Job Board" ]];

    const options = { year: "numeric", month: "long", day: "numeric" };

    if (csvData.length > 2) {

      csvData.forEach(notary => {

        let commissionNo = []
        let commissionState = []

        let name = notary.firstName + " " + notary.lastName;
        let formatedDate = new Date(notary.createdAt).toLocaleDateString(undefined, options);
        let email = notary.email;
        let phone = notary.phone;
        let city = notary.city;
        let state = notary.state;
        let zip = notary.zip;
        let address = notary.address;

        let temp = notary?.notaryCommissions.forEach( (notrayCommission) => {
          commissionNo.push(notrayCommission?.commissionNo)
          commissionState.push(notrayCommission?.commissionState)
        })

        let notaryExpiry = notary.notaryProfile.notaryExpiry;
        let status = notary.notaryProfile.isCancelled ? "Cancelled" : ( notary.notaryProfile.isApproved ) ? "Active" : "InActive";
        let totalNotarizations = (notary?.notaryJournals) ? notary?.notaryJournals : 0;
        let adobeEmail = (notary?.notaryEmail && notary?.notaryEmail?.email) ? notary?.notaryEmail?.email : "";
        let jobBoard = notary?.notaryProfile?.claimJob ? "Enabled" : "Disabled";

        let notaryTypes = notary?.notaryProfile && notary?.notaryProfile?.notaryTypes && notary?.notaryProfile?.notaryTypes.map((notaryType) => {
          return notaryType.type
        }).join(", ");

        let languages = notary?.Languages && notary?.Languages.map((language) => {
          return language.name
        }).join(", ");

        formatedCsvData.push([name, formatedDate, email, phone, city, state, zip, address, commissionNo.join(', '), commissionState.join(', '), notaryTypes, notaryExpiry, languages, status, totalNotarizations, adobeEmail, jobBoard])

      });
    }
    return formatedCsvData;
  }
  
  return (
    <Card>
      <CardHeader title="Notary Users" className="py-6">
        <CardHeaderToolbar style={{ minWidth: "570px" }}>
        
          {
            isLoading ? (
              <Button
                variant={"success"}
                className="mr-3"
                disabled
                style={{width:300}}
              >
                <CircularProgress size={15} />
              </Button>
            ) : (
              csvData.length < 2 && allAdminNotaries ?
                (
                  <Button
                    variant={"success"}
                    onClick={generateCsvData}
                    className="mr-3"
                    style={{width:300}}
                  >
                    Generate CSV
                  </Button>
                )
                :
                (
                  <CSVLink 
                    data={csvData}
                    filename="Notaries.csv"
                    style={{width:300}}
                    >
                    <Button
                      className="mr-3"
                      variant={"success"}
                      style={{width: "max-content"}}
                    >
                      {/* <img src={DownlaodIcon} className="downlaodIcon" alt="" /> */}
                      Download CSV
                    </Button>
                  </CSVLink>
                )
            )
          }
          <Form.Control
            type="text"
            className="mr-3"
            placeholder="Search notary by name or email"
            value={keyword}
            onKeyPress={handleKeypress}
            onChange={e => setkeyword(e.target.value)}
          />

          <button
            type="button"
            className="btn btn-primary mr-3"
            onClick={e => applyFilterHandler("search")}
          >
            Search
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={e => applyFilterHandler("clear")}
            disabled={keyword === ""}
          >
            Clear
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <CustomTable
          keyword={keyword}
          search={search}
          applyFilterHandler={applyFilterHandler}
          searchClicked={searchClicked}
          setSearchClicked={setSearchClicked}
          setQueries={setQueries}
        />
      </CardBody>
    </Card>
  );
}
