import React, { useEffect, useState } from "react";
import { TableUIProvider } from "../components/adminuserlisting/TableUIContext";
import { TableCard } from "../components/adminuserlisting/TableCard";
import { GenericZeroState } from "../components/zero_state_pages/GenericZeroState";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../components/_redux/notarization_redux/notarizationActions";
import { CustomerEditDialog } from "../components/adminuserlisting/edit-dialog/CustomerEditDialog";
import { CustomDeleteDialog } from "../components/adminuserlisting/edit-dialog/CustomDeleteDialog";
import { SearchNotaries } from '../pages/SearchNotaries'

export function MyAdminUser() {
  const dispatch = useDispatch();
  const { auth, notarization } = useSelector(state => state);
  const { allAdminNotaries, allAdminNotariesQuery } = notarization;
  const [actionModal, setActionModal] = useState(false);
  const [actionDeleteModal, setActionDeleteModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [selectedRowDeleteData, setSelectedRowDeleteData] = useState(null);
  const [selectPrimaryNotary, setSelectPrimaryNotary] = useState(false)
  const [selectedUser, setSelectUser] = useState(null)

  const customersUIEvents = {
    openEditCustomerDialog: id => {
      setActionModal(true);
      let row = allAdminNotaries.rows.filter(el => el.id === id);
      setSelectedRowData(row[0]);
    }
  };

  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  const customersDeleteUIEvents = {
    DeleteCustomerDialog: id => {
      setActionDeleteModal(true);
      let row = allAdminNotaries.rows.filter(el => el.id === id);
      let userID = row[0].id;
      setSelectedRowDeleteData(userID);
    }
  };

  const openSelectPrimaryNotaryScreen = id => {
    setSelectPrimaryNotary(true);
    setActionModal(false);
    setSelectUser(id);
  }

  const changeNet30Status = (id, status) => {
    dispatch(actions.changeNet30Status({
      userId: id,
      status: status
    }, headers));
    setActionModal(false)
  }

  const deleteUser = id => {
    let row = allAdminNotaries.rows.filter(el => el.id === id);
    let userID = row[0].id;
    setSelectedRowDeleteData(userID);
    dispatch(actions.deleteAdminUser(userID, headers));
  };

  const CancelDeleteCustomerDialog = () => {
    setActionDeleteModal(false);
  };

  useEffect(() => {
    dispatch(
      actions.fetchAdminUser(headers, [], {
        sortOrder: "desc",
        sortField: "id",
        pageNumber: 1,
        pageSize: 10
      })
    );
  }, []);

  // console.log("{selectedRowData}", );

  return (
    <>
      {allAdminNotaries !== null &&
      (allAdminNotaries.rows.length > 0 || allAdminNotariesQuery) ? (
        <TableUIProvider
          customersUIEvents={customersUIEvents}
          customersDeleteUIEvents={customersDeleteUIEvents}
        >
          <CustomerEditDialog
            show={actionModal}
            onHide={() => setActionModal(false)}
            data={selectedRowData}
            openSelectPrimaryNotaryScreen={openSelectPrimaryNotaryScreen}
            changeNet30Status={changeNet30Status}
          />
          <CustomDeleteDialog
            show={actionDeleteModal}
            onHide={() => setActionDeleteModal(false)}
            data={selectedRowDeleteData}
            deleteUser={deleteUser}
            CancelDeleteCustomerDialog={CancelDeleteCustomerDialog}
          />
          {
            selectPrimaryNotary ? (
              <SearchNotaries
                setSelectPrimaryNotary={setSelectPrimaryNotary}
                selectedUser={selectedUser}
                selectedNotaryId={selectedRowData?.userProfile ? selectedRowData?.userProfile?.notaryId : null }
              /> 
            ) 
            :
            <TableCard />
          }
        </TableUIProvider>
      ) : (
        <GenericZeroState
          status={allAdminNotaries}
          imageURL="/media/own/no-notary.png"
          heading="No Notary User Found!"
          body="It seems that no notary is applied for registration."
          sub_body="Once you accept the notary profile you can see details here!"
          btnTxt="false"
        />
      )}
    </>
  );
}
