import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/notarization_redux/notarizationActions";
import { getHandlerTableChange } from "../../../../_metronic/_helpers";
import * as uiHelpers from "../TableUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../_metronic/_partials/controls";
import { useTableUIContext } from "../TableUIContext";
import { GenericZeroState } from "../../zero_state_pages/GenericZeroState";

export function CustomTable(props) {
  // Customers UI Context
  const [isfetchjournals, setisfetchjournals] = useState(false);
  const [currentpagesize, setcurrentpagesize] = useState(0);
  const customersUIContext = useTableUIContext();
  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      setIds: customersUIContext.setIds,
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
      openEditCustomerDialog: customersUIContext.openEditCustomerDialog
    };
  }, [customersUIContext]);

  const { auth, notarization, socket } = useSelector(state => state);
  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  // Customers Redux state
  const dispatch = useDispatch();
  const queryParams = [];

  if (props.keyword) queryParams.push("q=" + props.keyword);

  if (props.statusfilter) queryParams.push("status=" + props.statusfilter);

  if (props.schedule) queryParams.push("status=Scheduled");

  const {
    allAdminNotarization,
    allAdminNotarizationschedule,
    allAdminNotarizationcompleted,
    allAdminNotarizationinprogress,
    listLoading,
    customizeAllAdminNotaries,
    changeActiveStatusLoading,
    reloadcallData
  } = notarization;

  const updateStatus = (id, status) => {
    dispatch(
      actions.updateNotaryStatus(
        { userId: id, status: status },
        headers,
        queryParams,
        customersUIProps.queryParams
      )
    );
  };

  useEffect(() => {
    if (props.searchClicked) {
      customersUIProps.queryParams.pageNumber = 1;
    }

    if (currentpagesize != customersUIProps.queryParams.pageSize) {
      setcurrentpagesize(customersUIProps.queryParams.pageSize);
      customersUIProps.queryParams.pageNumber = 1;
    }

    if (!isfetchjournals) {
      dispatch(
        actions.fetchAdminNotarization(
          headers,
          props.mainfilter,
          {
            sortOrder: "desc",
            sortField: "id",
            pageNumber: customersUIProps.queryParams.pageNumber,
            pageSize: customersUIProps.queryParams.pageSize
          },
          props.schedule,
          props.completed,
          props.progress
        )
      );
    }

    recallloadfunc();
  }, [customersUIProps.queryParams, dispatch, props.search, reloadcallData]);

  const recallloadfunc = () => {
    if (reloadcallData > 0) {
      dispatch(actions.getJournalbyID(reloadcallData, headers));
      dispatch(actions.getJitsiMeetingLinks(reloadcallData, headers))
    }
  };

  // Table columns
  const columns = [
    {
      dataField: "active_Inactive",
      text: "ACTION",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        updateStatus: updateStatus,
        openEditCustomerDialog: customersUIProps.openEditCustomerDialog,
        changeActiveStatusLoading: changeActiveStatusLoading
      }
    },
    {
      dataField: "notaryId",
      text: "NOTARY NAME",
      formatter: columnFormatters.NotaryNameColumnFormatterAdmin
    },
    {
      dataField: "email",
      text: "NOTARY EMAIL",
      formatter: columnFormatters.NotaryEmailColumnFormatterAdmin
    },
    {
      dataField: "userId",
      text: "CLIENT NAME",
      formatter: columnFormatters.ClientNameColumnFormatterAdmin
    },
    {
      dataField: "createdAt",
      text: "Notarization Date",
      formatter: columnFormatters.NotaryDateColumnFormatterAdmin
    },
    {
      dataField: "status",
      text: "Status",
      formatter: columnFormatters.NotaryStatusColumnFormatterAdmin
      // classes: "text-center",
      // headerClasses: "text-center",
    },
    {
      dataField: "type",
      text: "Notarization Type"
      // formatter: columnFormatters.checkNotarizationFormatter,
    }
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: allAdminNotarization === null ? 0 : allAdminNotarization.count,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: customersUIProps.queryParams.pageSize,
    page: customersUIProps.queryParams.pageNumber
  };

  const paginationschedhuleOptions = {
    custom: true,
    totalSize:
      allAdminNotarizationschedule === null
        ? 0
        : allAdminNotarizationschedule.count,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: customersUIProps.queryParams.pageSize,
    page: customersUIProps.queryParams.pageNumber
  };

  const paginationcompletedOptions = {
    custom: true,
    totalSize:
      allAdminNotarizationcompleted === null
        ? 0
        : allAdminNotarizationcompleted.count,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: customersUIProps.queryParams.pageSize,
    page: customersUIProps.queryParams.pageNumber
  };

  const paginationinprogressOptions = {
    custom: true,
    totalSize:
      allAdminNotarizationinprogress === null
        ? 0
        : allAdminNotarizationinprogress.count,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: customersUIProps.queryParams.pageSize,
    page: customersUIProps.queryParams.pageNumber
  };
  return (
    <>
      {!props.schedule && !props.completed && !props.progress ? (
        <>
        
          {allAdminNotarization !== null &&
          allAdminNotarization.rows.length > 0 ? (
            <PaginationProvider
              pagination={paginationFactory(paginationOptions)}
            >
              {({ paginationProps, paginationTableProps }) => {
                return (
                  <Pagination
                    isLoading={listLoading}
                    paginationProps={paginationProps}
                  >
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      bordered={false}
                      classes="table table-head-custom table-vertical-center overflow-hidden"
                      bootstrap4
                      remote
                      keyField="id"
                      data={
                        allAdminNotarization === null
                          ? []
                          : allAdminNotarization.rows
                      }
                      columns={columns}
                      defaultSorted={uiHelpers.defaultSorted}
                      onTableChange={getHandlerTableChange(
                        customersUIProps.setQueryParams,
                        props.setSearchClicked
                      )}
                      {...paginationTableProps}
                    ></BootstrapTable>
                  </Pagination>
                );
              }}
            </PaginationProvider>
          ) : (
            <GenericZeroState
              status={allAdminNotarization}
              imageURL="/media/own/no-notary-online.png"
              heading=""
              body="Unfortunately, there are no Notaries which match the requirements you have selected."
              sub_body=""
              btnTxt="Start a New Search"
              btnHandler={e => props.applyFilterHandler("clear")}
            />
          )}
        </>
      ) : (
        ""
      )}
      {props.schedule &&
      !props.completed &&
      !props.progress &&
      allAdminNotarizationschedule ? (
        <>
          {allAdminNotarizationschedule !== null &&
          allAdminNotarizationschedule.rows.length > 0 ? (
            <PaginationProvider
              pagination={paginationFactory(paginationschedhuleOptions)}
            >
              {({ paginationProps, paginationTableProps }) => {
                return (
                  <Pagination
                    isLoading={listLoading}
                    paginationProps={paginationProps}
                  >
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      bordered={false}
                      classes="table table-head-custom table-vertical-center overflow-hidden"
                      bootstrap4
                      remote
                      keyField="id"
                      data={
                        allAdminNotarizationschedule === null
                          ? []
                          : allAdminNotarizationschedule.rows
                      }
                      columns={columns}
                      defaultSorted={uiHelpers.defaultSorted}
                      onTableChange={getHandlerTableChange(
                        customersUIProps.setQueryParams,
                        props.setSearchClicked
                      )}
                      {...paginationTableProps}
                    ></BootstrapTable>
                  </Pagination>
                );
              }}
            </PaginationProvider>
          ) : (
            <GenericZeroState
              status={allAdminNotarization}
              imageURL="/media/own/no-notary-online.png"
              heading=""
              body="Unfortunately, there are no Notaries which match the requirements you have selected."
              sub_body=""
              btnTxt="Start a New Search"
              btnHandler={e => props.applyFilterHandler("clear")}
            />
          )}
        </>
      ) : (
        ""
      )}

      {!props.schedule &&
      props.completed &&
      !props.progress &&
      allAdminNotarizationcompleted ? (
        <>
          {allAdminNotarizationcompleted !== null &&
          allAdminNotarizationcompleted.rows.length > 0 ? (
            <PaginationProvider
              pagination={paginationFactory(paginationcompletedOptions)}
            >
              {({ paginationProps, paginationTableProps }) => {
                return (
                  <Pagination
                    isLoading={listLoading}
                    paginationProps={paginationProps}
                  >
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      bordered={false}
                      classes="table table-head-custom table-vertical-center overflow-hidden"
                      bootstrap4
                      remote
                      keyField="id"
                      data={
                        allAdminNotarizationcompleted === null
                          ? []
                          : allAdminNotarizationcompleted.rows
                      }
                      columns={columns}
                      defaultSorted={uiHelpers.defaultSorted}
                      onTableChange={getHandlerTableChange(
                        customersUIProps.setQueryParams,
                        props.setSearchClicked
                      )}
                      {...paginationTableProps}
                    ></BootstrapTable>
                  </Pagination>
                );
              }}
            </PaginationProvider>
          ) : (
            <GenericZeroState
              status={allAdminNotarization}
              imageURL="/media/own/no-notary-online.png"
              heading=""
              body="Unfortunately, there are no Notaries which match the requirements you have selected."
              sub_body=""
              btnTxt="Start a New Search"
              btnHandler={e => props.applyFilterHandler("clear")}
            />
          )}
        </>
      ) : (
        ""
      )}

      {!props.schedule &&
      !props.completed &&
      props.progress &&
      allAdminNotarizationinprogress ? (
        <>
          {allAdminNotarizationinprogress !== null &&
          allAdminNotarizationinprogress.rows.length > 0 ? (
            <PaginationProvider
              pagination={paginationFactory(paginationinprogressOptions)}
            >
              {({ paginationProps, paginationTableProps }) => {
                return (
                  <Pagination
                    isLoading={listLoading}
                    paginationProps={paginationProps}
                  >
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      bordered={false}
                      classes="table table-head-custom table-vertical-center overflow-hidden"
                      bootstrap4
                      remote
                      keyField="id"
                      data={
                        allAdminNotarizationinprogress === null
                          ? []
                          : allAdminNotarizationinprogress.rows
                      }
                      columns={columns}
                      defaultSorted={uiHelpers.defaultSorted}
                      onTableChange={getHandlerTableChange(
                        customersUIProps.setQueryParams,
                        props.setSearchClicked
                      )}
                      {...paginationTableProps}
                    ></BootstrapTable>
                  </Pagination>
                );
              }}
            </PaginationProvider>
          ) : (
            <GenericZeroState
              status={allAdminNotarization}
              imageURL="/media/own/no-notary-online.png"
              heading=""
              body="Unfortunately, there are no Notaries which match the requirements you have selected."
              sub_body=""
              btnTxt="Start a New Search"
              btnHandler={e => props.applyFilterHandler("clear")}
            />
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
}
