import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody } from "../../../_metronic/_partials/controls";
import { CustomNoNotaryUserFoundTable } from "../zero_state/table/CustomNoNotaryUserFoundTable";
import TextField from "@material-ui/core/TextField";

export function TableNoNotaryUserFoundCard() {
  return (
    <Card>
      <CardBody>
        <CustomNoNotaryUserFoundTable />
      </CardBody>
    </Card>
  );
}
