import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useCookies } from 'react-cookie'
import { useLocation } from 'react-router'
import { NotificationModal } from '../../components/modals/NotificationModal'
import { ScheduleNotificationModal } from '../../components/modals/ScheduleNotificationModal'

import { AgreementNotificationModal } from '../../components/modals/AgreementNotificationModal'
import { GenericModal } from '../../components/modals/GenericModal'
import { RescheduleModal } from '../../components/modals/RescheduleModal'

import * as actions from '../../components/_redux/notarization_redux/notarizationActions'
import * as socketactions from '../../socket/_redux/socket_redux/socketActions'
import * as authactions from '../../modules/Auth/_redux/AuthInit'
import ShowNotification from '../../components/new_notarization/ShowNotification'
import { NewNotarization } from '../../pages/NewNotarization'
import { NotaryWaiting } from '../../components/modals/NotaryWaiting'
import { toAbsoluteUrl } from '../../../_metronic/_helpers'

const Notifications = () => {
    const publicIp = require('public-ip')

    const { socket, auth, notarization } = useSelector((state) => state)
    const { paymentDetails, allJournals, getAllJournal } = notarization
    const location = useLocation()
    const headers = {
        Authorization: `Bearer ${auth.authToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
    }
    const [hideModal, sethideModal] = useState(false)
    const dispatch = useDispatch()
    const [errMSG, setErrMSG] = useState('')
    const [paymentThanks, setpaymentThanks] = useState(false)
    const [
        scheduleNotificationResponseBit,
        setscheduleNotificationResponseBit,
    ] = useState(false)
    const [rescehduleDate, setrescehduleDate] = useState(new Date())
    const [agreementData, setagreementData] = useState({
        title: 'Final Payment',
        currency: 'usd',
        journalId: 410,
        sealCount: 0,
        notaryType: '',
    })
    const [fee, setfee] = useState(0)
    const [seal, setSeal] = useState(0)
    const [hidepopup, sethidepopup] = useState(false)
    const [notification, setNotification] = useState({
        show: false,
        msg: '',
        variant: '',
    })
    const [resume, setResume] = useState({ status: false, id: null })
    const [kbaVerificationDone, setkbaVerificationDone] = useState(false)
    const [scheduleNotificationDone, setscheduleNotificationDone] = useState(
        false
    )
    const [adobeCredentialsBit, setadobeCredentialsBit] = useState(false)
    const [notarizeItNotificationBit, setnotarizeItNotificationBit] = useState(
        false
    )
    const [witnessJobNotification, setWitnessJobNotification] = useState(false)
    const [
        scheduleNotificationDoneBit,
        setscheduleNotificationDoneBit,
    ] = useState(false)
    const [primaryNotary, setprimaryNotary] = useState(false)
    const [isdonewitness, setisdonewitness] = useState(false)
    const [notifyset, setnotifyset] = useState(false)
    const [loading, setLoading] = useState(false)
    const enableLoading = () => {
        setLoading(true)
    }

    const disableLoading = () => {
        setLoading(false)
    }
    const prepareTime = (dt) => {
        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ]
        let date = new Date(dt)
        let time = formatAMPM(date)
        let mon = months[date.getMonth()]
        let curDate = date.getDate()
        let year = date.getFullYear()
        let timezone = seconds_with_leading_zeros(date)
        return `${mon} ${curDate}, ${year} at ${time} (${timezone})`
    }

    const formatAMPM = (date) => {
        var hours = date.getHours()
        var minutes = date.getMinutes()
        var ampm = hours >= 12 ? 'PM' : 'AM'
        hours = hours % 12
        hours = hours ? hours : 12
        minutes = minutes < 10 ? '0' + minutes : minutes
        var strTime = hours + ':' + minutes + ' ' + ampm
        return strTime
    }

    const seconds_with_leading_zeros = (dt) => {
        return /\((.*)\)/.exec(dt.toString())[1]
    }

    const playSound = () => {
        const audio = new Audio(toAbsoluteUrl('/media/notification/sound.mp3'))
        audio.play()
    }

    useEffect(()=>{
        if(null !== socket.statusChangedNotification && socket.statusChangedNotification.userId === auth.user.id ) {
            setTimeout(() => {
                window.location.href = '#/logout'
            }, 3000);
        }
    },[socket.statusChangedNotification])

    useEffect(() => {
        // Whenever a Witness job is posted this notification is triggered...
        if (socket.witnessJobNotification) {
            playSound()
            setNotification({
                show: true,
                msg: 'Witness Job Posted',
                variant: 'success',
            })
        }
    }, [socket.witnessJobNotification])

    useEffect(() => {
        sethidepopup(false);
        if (socket.newJobPostedNotification !== null) {
            playSound()
        }
    }, [socket.newJobPostedNotification])

    useEffect(() => {
        // Whenever a Witness job is posted this notification is triggered...
        if (
            socket.notarizationJobNotification &&
            socket.notarizationJobNotification.notarization
        ) {
            dispatch(
                actions.fetchJobPosts(
                    {
                        sortOrder: 'asc', // asc||desc
                        sortField: 'id',
                        pageNumber: 1,
                        pageSize: 10,
                    },
                    headers,
                    auth.user.Role.code,
                    true
                )
            ).then((response)=> {
                if( response && response.length ) {
                    let jobIds = response.map((job) => job.id)
                    let currentJobId = socket?.notarizationJobNotification?.notarization?.id; 
                    if( jobIds && jobIds.length && jobIds.includes(currentJobId) ) {
                        playSound()
                        setNotification({
                            show: true,
                            msg: 'Notarization Job Posted',
                            variant: 'success',
                        })
                    }
                }
            })
        }
    }, [socket.notarizationJobNotification])

    useEffect(() => {
        if (socket.WitnessReadyNotification) {
            playSound()
            setNotification({
                show: true,
                msg:
                    'Your Witness Request has been Claimed, you are ready to proceed',
                variant: 'success',
            })
            setTimeout(() => {
                window.location.reload()
            }, 2000)
        }
    }, [socket.WitnessReadyNotification])

    useEffect(() => {
        if (socket.notificationByNotary != null && !notarizeItNotificationBit) {
            if (socket.notificationByNotary.initiator != auth.user.id) {
                setnotarizeItNotificationBit(true)
            }
        }

        if (socket.scheduleNotificationResponse != null) {
            setscheduleNotificationResponseBit(true)
        }

        if (
            socket.scheduleNotification != null &&
            !scheduleNotificationDoneBit
        ) {
            if (socket.scheduleNotification.initiator) {
                if (socket.scheduleNotification.initiator != auth.user.id)
                    setscheduleNotificationDone(true)
            } else {
                if (
                    socket.scheduleNotification.journal.initiatedByNotary &&
                    auth.user.Role.code === 'user'
                )
                    setscheduleNotificationDone(true)
                else if (
                    !socket.scheduleNotification.journal.initiatedByNotary &&
                    auth.user.Role.code === 'notary'
                )
                    setscheduleNotificationDone(true)
            }
        }

        if (socket.adobeCredentials != null && !adobeCredentialsBit) {
            //authactions

            let usercont = auth.user
            usercont.notaryEmail.refreshToken =
                socket.adobeCredentials.refreshToken

            setadobeCredentialsBit(true)
            localStorage.setItem(
                'adobeCredentials',
                auth.user.id + '|' + usercont.notaryEmail.refreshToken
            )

            if ('/signwitness/' != location.pathname) {
                setTimeout(function() {
                    window.location.reload()
                }, 3000)
            }
        }

        if (
            socket.kbaVerificationDone &&
            socket.kbaVerificationDone.journalId &&
            !resume.status &&
            !kbaVerificationDone
        ) {
            dispatch(
                actions.checkJournal(
                    socket.kbaVerificationDone.journalId,
                    headers
                )
            )
            setkbaVerificationDone(true)
        } else if (
            socket.kbaVerificationDone &&
            socket.kbaVerificationDone.journalId &&
            getAllJournal &&
            getAllJournal.journal &&
            getAllJournal.journal.notaryId &&
            !primaryNotary
        ) {
            setprimaryNotary(true)
            setTimeout(function() {
                if (auth.user.Role.code !== 'ps')
                    window.location.href =
                        '#/new-notarization?preselectNotarization=' +
                        socket.kbaVerificationDone.journalId

                window.location.reload()
            }, 8000)
        } else if (
            ('/id-proofing/' != location.pathname) && 
            socket.kbaVerificationRejected &&
            socket.kbaVerificationRejected.journalId &&
            !kbaVerificationDone
        ) {
            setkbaVerificationDone(true)
            window.location.href =
                '#/journals?cancelNotarization=' +
                socket.kbaVerificationRejected.journalId
            window.location.reload()
        }

        if (
            socket.generalNotification &&
            !notifyset &&
            socket.generalNotification.data &&
            socket.generalNotification.data.response &&
            (socket.generalNotification.data.response.notarizationRequest
                .length > 0 ||
                socket.generalNotification.data.response.witnessRequest.length >
                    0)
        ) {
            let notifynoti = 0

            if (
                socket.generalNotification.data.response.notarizationRequest
                    .length > 0
            ) {
                let notarizationRequest =
                    socket.generalNotification.data.response
                        .notarizationRequest[0]

                if (notarizationRequest.status == 'scheduled') {
                    let lastTime = notarizationRequest.updatedAt
                    const date1 = new Date()
                    const date2 = new Date(lastTime)
                    let diffMs = date1 - date2

                    var diffDays = Math.floor(diffMs / 86400000) // days
                    var diffHrs = Math.floor((diffMs % 86400000) / 3600000) // hours
                    var diffMins = Math.round(
                        ((diffMs % 86400000) % 3600000) / 60000
                    ) // minutes

                    if (diffDays < 1 && diffHrs < 1 && diffMins <= 3) {
                        notifynoti = 1
                        let notificationObj = { journal: notarizationRequest }

                        dispatch(
                            socketactions.manualScheduledNotification(
                                notificationObj
                            )
                        )
                    }

                    setnotifyset(true)
                } else if (notarizationRequest.status == 'progress') {
                    let lastTime = notarizationRequest.updatedAt
                    const date1 = new Date()
                    const date2 = new Date(lastTime)
                    let diffMs = date1 - date2

                    var diffDays = Math.floor(diffMs / 86400000) // days
                    var diffHrs = Math.floor((diffMs % 86400000) / 3600000) // hours
                    var diffMins = Math.round(
                        ((diffMs % 86400000) % 3600000) / 60000
                    ) // minutes

                    if (diffDays < 1 && diffHrs < 1 && diffMins <= 3) {
                        notifynoti = 1
                        let notificationObj = { journal: notarizationRequest }

                        dispatch(
                            socketactions.manualNotaryNotification(
                                notificationObj
                            )
                        )
                    }

                    setnotifyset(true)
                }
            }

            if (
                notifynoti < 1 &&
                socket.generalNotification.data.response.witnessRequest.length >
                    0
            ) {
                let notarizationRequest =
                    socket.generalNotification.data.response.witnessRequest[0]
                if (notarizationRequest.status == 'progress') {
                    let lastTime = notarizationRequest.updatedAt
                    const date1 = new Date()
                    const date2 = new Date(lastTime)
                    let diffMs = date1 - date2

                    var diffDays = Math.floor(diffMs / 86400000) // days
                    var diffHrs = Math.floor((diffMs % 86400000) / 3600000) // hours
                    var diffMins = Math.round(
                        ((diffMs % 86400000) % 3600000) / 60000
                    ) // minutes

                    if (diffDays < 1 && diffHrs < 1 && diffMins <= 3) {
                        notifynoti = 1
                        let notificationObj = { journal: notarizationRequest }
                        dispatch(
                            socketactions.manualWitnessNotification(
                                notificationObj
                            )
                        )
                    }

                    setnotifyset(true)
                }
            }
        }

        if (null == paymentDetails && socket.agreementCompleted.documentCount) {
            dispatch(actions.fetchPaymentDetails(headers))
        } else if (socket.agreementCompleted.documentCount) {
            let aggdata = agreementData
            aggdata.journalId = socket.agreementCompleted.journalId
            setagreementData(aggdata)
            let fees = 3;
            setfee(fees)
        }
    })

    const sealCount = (sealValue) => {
        if (
            (getAllJournal &&
                getAllJournal.journal &&
                getAllJournal.journal.Payments &&
                getAllJournal.journal.Payments[0] &&
                getAllJournal.journal.Payments[0].userId) ||
            getAllJournal.journal.userId
        ) {
            let payeeID = getAllJournal.journal.Payments.length
                ? getAllJournal.journal.Payments[0].userId
                : getAllJournal.journal.userId
            let sealcost = 25
            let anothercost = 10

            if (payeeID == auth.user.id) {
                sealcost = 0
                anothercost = 1
            }

            let seals = sealValue - 1
            let showSeals = sealcost
            let calSeals = seals * anothercost
            if (sealValue == 1) {
                setSeal(sealcost)
            } else if (sealValue > 1) {
                // for (var i = 0; i < seals; i++) {
                // 	showSeals += ` + $10 `;
                // }
                showSeals += ` + (${seals} * ${anothercost})`

                setSeal(`${showSeals} =  $${calSeals + sealcost}`)
            } else {
                setSeal(0)
            }
            let aggdata = agreementData
            aggdata.sealCount = sealValue
            setagreementData(aggdata)
        }
    }

    const notaryType = (event) => {
        let aggdata = agreementData
        aggdata.notaryType = event.target.value
        setagreementData(aggdata)
    }

    const acceptscheduleNotification = () => {
        callscheduleNotification('accepted')
    }

    const reschedulescheduleNotification = () => {
        callscheduleNotification('rescheduled')
    }

    const cancelscheduleNotification = () => {
        callscheduleNotification('rejected')
    }

    const callscheduleNotification = async (acceptbool) => {

        const data = {
            notaryResponse: acceptbool,
        }

        if ('rescheduled' == acceptbool)
            data['scheduleDate'] = new Date(rescehduleDate).toISOString()

        if (socket.scheduleNotificationResponse) {
            dispatch(
                actions.acceptSchedule(
                    headers,
                    data,
                    socket.scheduleNotificationResponse.journalId
                )
            ).then((scheduleresponse) => {
                if (
                    scheduleresponse &&
                    scheduleresponse.data &&
                    scheduleresponse.data.status
                ) {
                    dispatch(socketactions.clearscheduleNotification())
                    setscheduleNotificationDoneBit(true)
                    setscheduleNotificationDone(false)
                    setTimeout(function() {
                        if( location.pathname === "/new-notarization" ) {
                            window.location.href = '/#/dashboard'
                            window.location.reload()
                        }
                        else{
                            window.location.reload()
                        }
                    }, 3000)
                }
            })
        } else {
            dispatch(
                actions.acceptSchedule(
                    headers,
                    data,
                    socket.scheduleNotification.journal.id
                )
            ).then((scheduleresponse) => {
                if (
                    scheduleresponse &&
                    scheduleresponse.data &&
                    scheduleresponse.data.status
                ) {
                    dispatch(socketactions.clearscheduleNotification())
                    setscheduleNotificationDoneBit(true)
                    setscheduleNotificationDone(false)
                    setTimeout(function() {
                        if( location.pathname === "/new-notarization" ) {
                            window.location.href = '/#/dashboard'
                            window.location.reload()
                        }
                        else{
                            window.location.reload()
                        }
                    }, 3000)
                }
            })
        }
    }

    const cancelWitnessing = () => {
        callWitnessing(false)
        //window.location.reload();
    }

    const callWitnessing = async (acceptbool) => {
        setisdonewitness(true)
        publicIp.v4().then((ipaddr) => {
            const data = {
                accept: acceptbool,
                journalId: socket.witnessNotification.journal.id,
                IP: ipaddr,
            }

            dispatch(actions.acceptWitnessing(headers, data)).then(
                (witnessresponse) => {
                    if (witnessresponse) {
                        setisdonewitness(false)
                        if (witnessresponse.data) {
                            sethideModal(true)

                            if (acceptbool)
                                window.location.href = '#/witnessing'

                            window.location.reload()
                        }
                    }
                }
            )
        })
    }
    const acceptNotarization = async () => {
        setisdonewitness(true)
        publicIp.v4().then((ipaddr) => {
            dispatch(
                actions.acceptNotarization(
                    headers,
                    socket.notaryNotification.journal.id,
                    ipaddr
                )
            ).then((notarizationresponse) => {
                if (notarizationresponse) {
                    setisdonewitness(false)
                    if (notarizationresponse.data.success) {
                        sethideModal(true)
                        dispatch(socketactions.emptyNotarization())
                        setTimeout(function() {
                            window.location.href =
                                '#/signwitness/?id=' +
                                socket.notaryNotification.journal.id
                            window.location.reload()
                        }, 3000)
                    }
                }
            })
        })
    }

    const rejectNotarization = async () => {
        setisdonewitness(true)
        let notarizationresponse = await dispatch(
            actions.rejectNotarization(
                headers,
                socket.notaryNotification.journal.id
            )
        )

        if (notarizationresponse) {
            setisdonewitness(false)
            if (notarizationresponse.data.success) {
                sethideModal(true)
                dispatch(socketactions.emptyNotarization())
                //setTimeout(function () { window.location.reload(); }, 3000);
            }
        }
    }

    const acceptWitnessing = () => {
        callWitnessing(true)
    }

    const FinalizePaymentCall = async () => {
        if (!agreementData.sealCount || parseInt(agreementData.sealCount) < 1) {
            setNotification({
                show: true,
                msg: 'atleast 1 seal add',
                variant: 'error',
            })
            return false
        }
        if (!agreementData.notaryType) {
            setNotification({
                show: true,
                msg: 'Please Select any notary Type',
                variant: 'error',
            })
            return false
        }
        setisdonewitness(true)
        let response = await dispatch(
            actions.finalizePayment(
                agreementData,
                headers,
                setErrMSG,
                enableLoading,
                disableLoading
            )
        )

        if (response) {
            if (response.data.status) {
                setpaymentThanks(true)
            } else {
                setisdonewitness(false)
            }
        }
    }

    const thanksforpayment = () => {
        window.location.href = '#/dashboard'
        window.location.reload()
    }

    const thanksforpaymentnotary = () => {
        window.location.href = '#/dashboard'
        setnotarizeItNotificationBit(false)
        sethidepopup(true)
    }

    const setJobPostedNotification = () => {
        sethidepopup(true);
    }

    const selectNotary = () => {
        if (!getAllJournal.journal.notaryId) {
            window.location.href =
                '#/new-notarization?preselectNotarization=' +
                socket.kbaVerificationDone.journalId
            window.location.reload()
        }
    }

    const handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') return
        setNotification({ show: false, msg: '', variant: '' })
    }

    return (
        <span>
            {notification.show && (
                <ShowNotification
                    show={notification.show}
                    msg={notification.msg}
                    variant={notification.variant}
                    close={handleCloseNotification}
                />
            )}

            {socket.notaryNotification.journal && (
                <NotificationModal
                    heading='Notarization'
                    show={
                        socket.notaryNotification.journal && !hideModal
                            ? true
                            : false
                    }
                    hide={sethideModal}
                    cancel={rejectNotarization}
                    detail={socket.notaryNotification}
                    btnHandler={acceptNotarization}
                    isdone={isdonewitness}
                    reschedule={reschedulescheduleNotification}
                    setrescehduleDate={setrescehduleDate}
                />
            )}

            {socket.witnessNotification.journal && (
                <NotificationModal
                    heading='Witness'
                    show={
                        socket.witnessNotification.journal && !hideModal
                            ? true
                            : false
                    }
                    cancel={cancelWitnessing}
                    detail={socket.witnessNotification}
                    btnHandler={acceptWitnessing}
                    isdone={isdonewitness}
                />
            )}

            {socket.scheduleNotification != null &&
                scheduleNotificationDone && (
                    <ScheduleNotificationModal
                        heading='Schedule Notarization'
                        show={scheduleNotificationDone ? true : false}
                        cancel={cancelscheduleNotification}
                        detail={socket.scheduleNotification}
                        btnHandler={acceptscheduleNotification}
                        reschedule={reschedulescheduleNotification}
                        setrescehduleDate={setrescehduleDate}
                    />
                )}

            {socket.agreementCompleted.documentCount &&
                fee > 0 &&
                !paymentThanks && (
                    <AgreementNotificationModal
                        sethideModal={sethideModal}
                        heading=''
                        show={
                            socket.agreementCompleted.documentCount &&
                            !hideModal
                                ? true
                                : false
                        }
                        cancel={cancelWitnessing}
                        detail={socket.witnessNotification}
                        isdone={isdonewitness}
                        btnHandler={FinalizePaymentCall}
                        errMSG={errMSG}
                        fee={fee}
                        sealCount={sealCount}
                        notaryType={notaryType}
                        documentCount={socket.agreementCompleted.documentCount}
                        seal={seal}
                        handleCloseNotification={handleCloseNotification}
                        notification={notification}
                        allJournals={allJournals}
                        setNotification={setNotification}
                        setpaymentThanks={setpaymentThanks}
                        setisdonewitness={setisdonewitness}
                    />
                )}

            {notarizeItNotificationBit &&
                !hidepopup &&
                socket.notificationByNotary != null &&
                !socket.notificationByNotary.scheduled && (
                    <GenericModal
                        heading='Success'
                        bodyText='A new notarization has been created for you. Please review your dashboard to proceed.'
                        image='/media/own/success.png'
                        btnTxt='Close'
                        btnHandler={thanksforpaymentnotary}
                        show={notarizeItNotificationBit}
                        hide={thanksforpaymentnotary}
                    />
                )}

            {null !== socket.newJobPostedNotification && 
                !hidepopup &&
            (
                    <GenericModal
                        heading='Please see an opportunity on the job board.'
                        image={null}
                        bodyText='Please note, this alert was sent to all currently logged-in notaries. However, you may not have the required qualifications to see a notarization request on the board, as some customers may need a notary from a specific state or one who speaks a particular language. Please check to see if you are able to assist the client. Thank you - Cyberize It Admin'                        
                        btnTxt='Close'
                        btnHandler={setJobPostedNotification}
                        show={true}
                        hide={setJobPostedNotification}
                    />
            )}
            
            {
                (null !== socket.statusChangedNotification && socket.statusChangedNotification.userId === auth.user.id ) && (
                    <GenericModal
                        heading='Warning'
                        bodyText={'Admin has changed your status to ' + socket.statusChangedNotification?.status + ' you\'ll be logged out in a while.'}
                        image='/media/own/alarm.png'
                        btnTxt='Close'
                        btnHandler={()=> { window.location.href = '#/logout'}}
                        show={true}
                        hide={()=> { window.location.href = '#/logout'}}
                    />
                )
            }

            {paymentThanks && (
                <GenericModal
                    heading='Success'
                    bodyText='The payment has been successfully charged and the receipt has been sent to the client. The client can now view the signed document in their journals. Simply have them click the Action Button (blue) and select Download Document.'
                    image='/media/own/success.png'
                    btnTxt='Close'
                    btnHandler={thanksforpayment}
                    show={paymentThanks}
                    hide={thanksforpayment}
                />
            )}

            {socket.scheduleNotificationResponse != null && (
                <RescheduleModal
                    heading='Success'
                    bodyText={
                        socket.scheduleNotificationResponse.status ==
                        'rescheduled'
                            ? 'Your Scheduled Notarization has been ' +
                              socket.scheduleNotificationResponse.status +
                              ' at ' +
                              prepareTime(
                                  socket.scheduleNotificationResponse
                                      .rescheduledDate
                              )
                            : 'Your Scheduled Notarization has been ' +
                              socket.scheduleNotificationResponse.status
                    }
                    status={socket.scheduleNotificationResponse.status}
                    image='/media/own/success.png'
                    btnTxt='Close'
                    show={scheduleNotificationResponseBit}
                    hide={thanksforpayment}
                    btnHandler={acceptscheduleNotification}
                    closebtnHandler={thanksforpayment}
                    reschedule={reschedulescheduleNotification}
                    setrescehduleDate={setrescehduleDate}
                />
            )}

            {socket.kbaVerificationDone &&
                socket.kbaVerificationDone.journalId &&
                !resume.status &&
                getAllJournal &&
                getAllJournal.journal &&
                !getAllJournal.journal.notaryId && (
                    <GenericModal
                        heading='No Notary Selected'
                        bodyText='Opps! you are almost done only you have to select notary to complete your notarization.'
                        image='/media/own/alarm.png'
                        btnTxt='Select Notary'
                        btnHandler={selectNotary}
                        show={socket.kbaVerificationDone.journalId}
                    />
                )}

            {/*((socket.kbaVerificationDone) && (socket.kbaVerificationDone.journalId) && (resume.id)) &&<NewNotarization newNot={resume.status} id={resume.id} chooseNotaryProp={true} from="journal" alreadyPrimaryNotary={((getAllJournal) &&  (getAllJournal.journal) && (getAllJournal.journal.notaryId))?getAllJournal.journal.notaryId:0} /> }

 			{((socket.kbaVerificationDone) && (socket.kbaVerificationDone.journalId) && (getAllJournal) &&  (getAllJournal.journal) && (getAllJournal.journal.notaryId)) &&<NewNotarization newNot={true} id={socket.kbaVerificationDone.journalId} chooseNotaryProp={true} from="journal" alreadyPrimaryNotary={((getAllJournal) &&  (getAllJournal.journal) && (getAllJournal.journal.notaryId))?getAllJournal.journal.notaryId:0} /> */}
        </span>
    )
}

export default Notifications
