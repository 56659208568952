import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../components/_redux/kba_redux/kbaActions";
import * as notactions from "../../components/_redux/notarization_redux/notarizationActions";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../_metronic/_partials/controls/WitnessingCard";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
// import { Button } from 'react-bootstrap';
import { useLocation, Link } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ShowNotification from "../../components/new_notarization/ShowNotification";
import { TableMyQusrionCard } from "../../components/clients_listing/TableMyQusrionCard";
import Button from "@material-ui/core/Button";
import { Choices } from "./Choices";
import { SubmitAnswers } from "./SubmitAnswers";
import { OnlineSearchNotaries } from "../../pages/OnlineSearchNotaries";
import HeaderImage from "../../media/cyberizeit-logo-letter.png";
export function GetQuestions() {
  const publicIp = require("public-ip");
  const { auth, notarization } = useSelector(state => state);
  const [Questions, setQuestions] = useState([]);
  const [ip, setip] = useState("");
  const [journalInterval, setjournalInterval] = useState([]);
  const [journalID, setjournalID] = useState(0);
  const [Submission, setSubmission] = useState([]);
  const [Error, setError] = useState("");
  const [primaryNotary, setprimaryNotary] = useState(0);
  const [Success, setSuccess] = useState("");
  const [Submitted, setSubmitted] = useState(false);
  const [notarynotificationcounter, setnotarynotificationcounter] = useState(0);
  const [showNotaryWaiting, setshowNotaryWaiting] = useState(false);
  const [showReNotary, setshowReNotary] = useState(false);
  const [showNoNotary, setshowNoNotary] = useState(false);
  const [successQues, setsuccessQues] = useState(false);
  const [showVerificationDoneModal, setShowVerificationDoneModal] = useState(
    false
  );
  const [isdone, setisdone] = useState(false);
  const { allQuestions, currentjournalstatus } = notarization;
  const [notification, setNotification] = useState({
    show: false,
    msg: "",
    variant: ""
  });
  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  let currentDate = new Date();

  const dispatch = useDispatch();
  const location = useLocation();

  const saveLaterSuccessHideHandler = () => {
    window.location.href = "/#/dashboard";
  };

  useEffect(() => {
    let getidfromlocation = location.search;
    const urlParams = new URLSearchParams(location.search);

    getidfromlocation = urlParams.get("code");

    if (urlParams.get("journalId") && !auth.user) {
      window.location.href =
        "#/auth/login?code=" +
        getidfromlocation +
        "&journalId=" +
        urlParams.get("journalId") +
        "&isUser=true";
      window.location.reload();
    } else if (urlParams.get("journalId") && !journalID) {
      setjournalID(urlParams.get("journalId"));
    }

    publicIp.v4().then(ipaddr => {
      if (ipaddr && !successQues) {
        setip(ipaddr);

        let payload = { signerId: getidfromlocation, IP: ipaddr };

        if (urlParams.get("journalId")) {
          payload.journalId = parseInt(urlParams.get("journalId"));
          payload.isUser = true;
        }

        if (urlParams.get("journalId")) {
          dispatch(
            notactions.getJournal(urlParams.get("journalId"), headers)
          ).then(response => {
            if (response.data.journal.notaryId)
              setprimaryNotary(response.data.journal.notaryId);
          });
        }
        dispatch(actions.fetchQuestions(payload, headers)).then(response => {
          if (response) {
            if (response.err) {
              setError(response.err);
              setsuccessQues(true);
            } else if (
              response.payload.result &&
              response.payload.result.response &&
              response.payload.result.response.questions
            ) {
              setQuestions(response.payload.result.response.questions.question);
              setSubmission(response.payload.body);
            }
          }
        });
      }
    });

    if (currentjournalstatus == "Accepted") {
      setshowNotaryWaiting(false);
      setshowNoNotary(false);
      setShowVerificationDoneModal(true);
      setjournalInterval(clearInterval(journalInterval));
    } else if (currentjournalstatus == "Rejected") {
      setshowNotaryWaiting(false);
      setshowReNotary(true);
      setNotification({
        show: true,
        msg: "Notary Rejected your Notarization. Kindly choose another notary",
        variant: "error"
      });
      setjournalInterval(clearInterval(journalInterval));
    }
  }, [dispatch, currentjournalstatus]);

  const submitAnswer = () => {
    setisdone(true);
    dispatch(actions.SubmitQuestions(Submission, headers)).then(response => {
      setsuccessQues(true);
      setError("");
      setisdone(false);
      if (response.questions) {
        setsuccessQues(false);

        setError(
          response.message
            ? dividenewline(response.message)
            : "You have Failed your 1st KBA Verification Attempt. Kindly Answers your Question(s) Again"
        );
        setQuestions(response.questions.result.response.questions.question);
        setSubmission(response.questions.body);
      } else if (response.error) {

        setError(
          "If you have a Primary Notary, please reach out to them for further assistance. If you are new to our platform, please check your email for a new link."
        );
        setTimeout(() => {
          window.close();
        },3000)
      }
      else if (response.err) {
        setError(response.err);
        setTimeout(() => {
          window.close();
        },3000)
      }
      else {
        setSuccess(
          "Congratulations, you have successfully passed your ID Quiz. Now you will need to upload your ID. Please return to the previous window and continue the ID Proofing Process."
        );
        setTimeout(() => {
          window.close();
        },3000)
      }
    });
  };

  const dividenewline = msg => {
    return msg.replace("/n", "\n");
  };
  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") return;
    setNotification({ show: false, msg: "", variant: "" });
  };

  const syncforjournalverification = journalID => {
    setjournalInterval(
      setInterval(function() {
        checknotarynotificationtimeout();
      }, 8000)
    );
  };

  const checknotarynotificationtimeout = () => {
    setnotarynotificationcounter(notarynotificationcounter + 1);

    if (notarynotificationcounter > 10) {
      setnotarynotificationcounter(0);
      setshowNotaryWaiting(false);
      setshowReNotary(true);
      setNotification({
        show: true,
        msg: "Oops Timeout. Choose Another Notary",
        variant: "error"
      });
      setjournalInterval(clearInterval(journalInterval));
    } else {
      dispatch(notactions.checkJournal(journalID, headers));
    }
  };

  const choosePrimaryNotaryHandler = () => {
    setError("");
    let notaryID = primaryNotary;
    let body = {
      journalId: journalID,
      notaryId: parseInt(notaryID)
    };

    dispatch(notactions.setSelectedNotary(body, headers, setNotification)).then(
      data => {
        if (parseInt(data) == 1) {
          setprimaryNotary(0);
          setshowNotaryWaiting(true);
          syncforjournalverification(journalID);
        } else {
          setError(
            "Kindly Complete the KBA Process First then you can chose Notary"
          );
          setSuccess("");
          setshowNoNotary(true);
        }
      }
    );

    // setChooseNotary(false);
  };

  const chooseNotaryHandler = e => {
    setError("");
    let notaryID = e.currentTarget.id;
    let body = {
      journalId: journalID,
      notaryId: parseInt(notaryID)
    };

    dispatch(notactions.setSelectedNotary(body, headers, setNotification)).then(
      data => {
        if (parseInt(data) == 1) {
          setshowNotaryWaiting(true);
          syncforjournalverification(journalID);
        } else {
          setError(
            "Kindly Complete the KBA Process First then you can chose Notary"
          );
          setSuccess("");
          setshowNoNotary(true);
        }
      }
    );

    // setChooseNotary(false);
  };

  const arrowStyle = {
    fontSize: "36px",
    color: "#909090",
    backgroundColor: "#fbfbfb",
    borderRadius: "50%",
    marginRight: "2px",
    padding: "7px"
  };

  const messageBlockSuccess = {
    width: "40%",
    display: "block",
    margin: "auto",
    borderRadius: "10px",
    padding: "75px 20px",
    border: "5px solid #d4edda"
  };

  const messageBlockError = {
    width: "40%",
    display: "block",
    margin: "auto",
    borderRadius: "10px",
    padding: "75px 20px",
    border: "5px solid #f8d7da"
  };

  return (
    <>
      <div className="errorOrSuccess">
        <Card>
          {auth.user ? (
            ""
          ) : (
            <CardHeader className="py-6">
              <CardHeaderToolbar>
                <img src={HeaderImage} className="HeaderImage" alt="" />
              </CardHeaderToolbar>
            </CardHeader>
          )}
        </Card>

        {Success !== "" && (
          <div style={messageBlockSuccess}>
            <span class="success">{Success}</span>
          </div>
        )}

        {Error !== "" && (
          <div style={messageBlockError}>
            <span class="error">{Error}</span>
          </div>
        )}
        {notification.show && (
          <ShowNotification
            show={notification.show}
            msg={notification.msg}
            variant={notification.variant}
            close={handleCloseNotification}
          />
        )}

        {journalID < 0 && successQues && primaryNotary < 1 ? (
          <OnlineSearchNotaries
            chooseNotary={true}
            chooseNotaryHandler={chooseNotaryHandler}
            showNotaryWaiting={showNotaryWaiting}
            saveLaterSuccessHideHandler={saveLaterSuccessHideHandler}
            showVerificationDoneModal={showVerificationDoneModal}
            noback={true}
          />
        ) : (
          ""
        )}

        {journalID < 0 && successQues && primaryNotary > 0 ? (
          <div
            style={{ width: "100%", marginTop: "50px", textAlign: "center" }}
          >
            <button
              type="button"
              variant="contained"
              className="fileUpload change"
              style={{
                backgroundColor: "#2d3fd3",
                color: "white",
                padding: "10px"
              }}
              onClick={choosePrimaryNotaryHandler}
            >
              Send Notification to Primary Notary
            </button>
          </div>
        ) : (
          ""
        )}

        {!successQues ? (
          <div>
            <Card className="main-signer-verification">
              <CardHeader
                title="Signer Verification"
                className="py-6"
              ></CardHeader>
              <CardBody>
                {Questions.length > 0 ? (
                  <div>
                    {Questions.map((question, i) => (
                      <div className="heading-one">
                        <h6>{question["prompt"]["_text"]}</h6>
                        <Choices
                          choices={question["answer"]}
                          number={i + 1}
                          Submission={Submission}
                          setSubmission={setSubmission}
                        />
                      </div>
                    ))}{" "}
                    <SubmitAnswers
                      submitAnswer={submitAnswer}
                      isdone={isdone}
                    />{" "}
                  </div>
                ) : (
                  ""
                )}{" "}
              </CardBody>
              <div
                class="footer bg-white py-4 d-flex flex-lg-column  "
                id="kt_footer"
              >
                <div class="container d-flex flex-column flex-md-row align-items-center justify-content-between">
                  <div class="text-dark order-2 order-md-1">
                    <span class="text-muted font-weight-bold mr-2">
                      Copyright {currentDate.getFullYear()} &copy; Cyberize It, LLC
                    </span>
                  </div>
                </div>
              </div>
            </Card>{" "}
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
