import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { addSigner } from "../_redux/notarization_redux/notarizationCrud";
import { Grid } from "@material-ui/core";
import ShowNotification from "../new_notarization/ShowNotification";
import { SignerListing } from "../new_notarization/SignerListing";
import * as actions from "../../components/_redux/notarization_redux/notarizationActions";

import moment from "moment";
export const AddSigner = ({ openModal, onHide, journalId, draftHandler }) => {
  const [singerInfo, setSingerInfo] = useState([{}]);
  const [notification, setNotification] = useState({
    show: false,
    msg: "",
    variant: ""
  });
  const dispatch = useDispatch();
  const { auth } = useSelector(state => state);
  const onSubmit = async () => {
    let Signer = singerInfo[0];

    let errorMessage;

    if (!Signer?.dob) {
      errorMessage = "Date of Birth field is required";
    }
    if (moment(Signer.dob).isAfter(moment())) {
      errorMessage = "Please enter a valid Date of Birth";
    }
    if (!Signer.fname || !/^[a-zA-Z0-9]*$/g.test(Signer.fname)) {
      errorMessage = "Please Provide Correct Signer First Name";
    }

    if (!Signer.lname || !/^[a-zA-Z0-9]*$/g.test(Signer.lname)) {
      errorMessage = "Please Provide Correct Signer Last Name";
    }

    if (Signer.byPassKBA && Signer.reason === "") {
      errorMessage = "Please fill out the mandatory fields!";
    }

    if (!Signer.isEmailValid) {
      errorMessage = "Please enter valid email!";
    }

    if (5 < Signer?.suffix?.length) {
      errorMessage = "Suffix must not exceed 5 letters";
    } else if (Signer.suffix && !/^[A-Za-z.]+$/gi.test(Signer.suffix)) {
      errorMessage = "Please Provide Correct Signer Suffix";
    }

    if (
      !Signer.fname ||
      !Signer.lname ||
      !Signer.email ||      
      (!Signer.phone && auth.user.Role.code != "notary")||
      !Signer.address ||
      !Signer.zip ||
      !Signer.city ||
      !Signer.state
    ) {
      errorMessage = "Please fill out the mandatory fields!";
    }

    setSingerInfo([Signer]);

    if (errorMessage) {
      setNotification({ show: true, msg: errorMessage, variant: "error" });
      return false;
    }

    Signer.firstName = Signer.fname;
    Signer.lastName = Signer.lname;

    const headers = {
      Authorization: `Bearer ${auth.authToken}`,
      "Content-Type": "application/json",
      Accept: "application/json"
    };

    const { data } = await addSigner({ Signer, journalId }, headers);
    if (data.status === "success") {
      setNotification({
        show: true,
        msg: "Signer has been added successfully.",
        variant: "success"
      });
      dispatch(
        actions.fetchJournals(
          {
            sortOrder: "desc", // asc||desc
            sortField: "id",
            pageNumber: 1,
            pageSize: 10
          },
          headers,
          auth.user.Role.code
        )
      );
      onHide();
    }
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") return;
    setNotification({ show: false, msg: "", variant: "" });
  };

  return (
    <Modal
      size="lg"
      show={openModal}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <SignerListing
        noOfSigner={1}
        draftHandler={draftHandler}
        singerInfo={singerInfo}
        setSingerInfo={setSingerInfo}
        disabled={false}
        user={false}
        selectedUser={{}}
        isPop={true}
      />
      {notification.show && (
        <ShowNotification
          show={notification.show}
          msg={notification.msg}
          variant={notification.variant}
          close={handleCloseNotification}
        />
      )}
      <div className="container">
        <Grid container spacing={2} className="p-2">
          <Grid item sm={5} spacing={5}>
            <button
              type="button"
              className="btn"
              style={{
                backgroundColor: "#2d3fd3",
                color: "white",
                marginButtom: "10px"
              }}
              onClick={onSubmit}
            >
              Add signer
            </button>
          </Grid>
          <Grid item sm={5}>
            <button
              type="button"
              className="btn"
              style={{
                backgroundColor: "red",
                color: "white"
              }}
              onClick={() => {
                onHide();
              }}
            >
              Close
            </button>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};
