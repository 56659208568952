/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { NavLink } from "react-router-dom";

export function UserProfileDropdown() {
  const { user } = useSelector(state => state.auth);

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light: objectPath.get(uiService.config, "extras.user.dropdown.style") === "light",
    };
  }, [uiService]);

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div className={"btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto"}>
          <span className="text-white opacity-70 font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span>
          <span className="text-white opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-4">
            {user.firstName} {user.lastName}
          </span>
          <span className="symbol symbol-35">
            <span className="symbol-label text-white font-size-h5 font-weight-bold bg-white-o-30">{user.firstName[0]}</span>
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg p-0">
        <>
          {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          {layoutProps.light && (<>
            <div className="d-flex align-items-center p-8 rounded-top">

              <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                <img src={user.profile_picture ? user.profile_picture.baseUrl + "/" + user.profile_picture.path : toAbsoluteUrl("/media/users/blank.png")} alt="" />
              </div>
              <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">{user.firstName} {user.lastName}</div>
              {/* <span className="label label-light-success label-lg font-weight-bold label-inline">3 messages</span> */}
            </div>
            <div className="separator separator-solid w-100"></div>
          </>)}

        </>

        <div className="navi navi-spacer-x-0 pt-5">
          {user.Role.code === "admin" ? "" :
          <>
          
            <a className="navi-item px-8">
              <div className="navi-link">
                <div className="navi-icon mr-2">
                  <i className="flaticon2-calendar-3 text-success" />
                </div>
                <div className="navi-text font-weight-bold">
                  <NavLink className="menu-link" to="/user-profile">
                    <span className="menu-text">My Profile</span>
                  </NavLink>

                </div>
              </div>
            </a>
           
          }
          </>
          }
          {user.Role.code === "admin" ? "" :
            <div className="navi-separator mt-3"></div>
          }

          <div className="navi-footer  px-8 py-5">
   
            <Link to="/logout" className="btn btn-light-primary font-weight-bold">
              Sign Out
            </Link>
            {/* <a href="#" className="btn btn-clean font-weight-bold">
                Upgrade Plan
              </a> */}
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
