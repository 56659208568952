import React, { useEffect, useState } from "react";
import { TableUIProvider } from "../components/notarization_listing/journals/TableUIContext";
import { TableCard } from "../components/notarization_listing/journals/TableCard";
import { CustomerEditDialog } from "../components/notarization_listing/journals/edit-dialog/CustomerEditDialog";
import { NewNotarization } from "../pages/NewNotarization";
import { NewNotarizationNotary } from "../pages/NewNotarizationNotary";
import { NewNotarizationPS } from "../pages/NewNotarizationPS";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../components/_redux/notarization_redux/notarizationActions";
import { GenericZeroState } from "../components/zero_state_pages/GenericZeroState";
import { useHistory, useLocation } from "react-router-dom";
import { GenericModal } from "../components/modals/GenericModal";
const publicIp = require("public-ip");


export function JournalsPage() {
  const MIDDLEWARE_URL = process.env.REACT_APP_MIDDLEWARE_URL;
  const [actionModal, setActionModal] = useState(false);
  const [notaryID, setNotaryID] = useState(null);
  const [resume, setResume] = useState({ status: false, id: null });
  const [notaryName, setnotaryName] = useState("");
  const [notaryEmail, setnotaryEmail] = useState("");
  const [client, setclient] = useState("");
  const [fromDate, setfromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [journalstatus, setjournalstatus] = useState();
  const [isload, setisload] = useState(false);

  const customersUIEvents = {
    openEditCustomerDialog: id => {
      setActionModal(true);
      setNotaryID(id);
    }
  };
  const dispatch = useDispatch();
  const location = useLocation();
  const { auth, notarization } = useSelector(state => state);
  const { allJournals } = notarization;


  const [mainfilter, setmainfilter] = useState({
    from: '',
    to: '',
    name: '',
    customer: '',
    status: '',
  });


  // Filter for Excel Export
  const [mainexcelfilter, setmainexcelfilter] = useState({
    include: [
      {
        model: "Witness",
        attributes: [],
        include: [
          {
            model: "User"
          }
        ]
      },
      {
        model: "Payment",
        attributes: [],
        include: []
      },
      {
        model: "Signer",
        attributes: [],
        include: []
      },
      {
        model: "journalDoc",
        attributes: [],
        include: [
          {
            model: "File"
          }
        ]
      },
      {
        model: "User",
        include: [],
        attributes: [],
        alias: "notary"
      },
      {
        model: "Recording",
        include: [],
        attributes: []
      },
      {
        model: "User",
        include: [],
        attributes: [],
        alias: "customer"
      }
    ],
    attributes: [],
    alias: ""
  });

  const applyFilter = () => {
    setisload(true);
    if(mainfilter) {
  
      dispatch(
        actions.fetchJournals(
          {
          sortOrder: "desc", // asc||desc
          sortField: "id",
          pageNumber: 1,
          pageSize: 10
        },
        headers,
        auth.user.Role.code,
        false,
        mainfilter
        )
        ).then(data => {
          setisload(false);
        });
    }
  };

  const resumeNotarization = id => {
    setResume({ status: true, id: id });
    const resumeData = allJournals.rows.filter(data => data.id === id);
    if (resumeData !== null)
      dispatch(actions.updateDocuments(resumeData[0].documents));
  };

  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  const addWitness = id =>
    (window.location.href = "#/searchWitnesses/?id=" + id);
  const addNewHandler = () => {
    "ps" == auth.user.Role.code
      ? (window.location.href = "/#/new-notarizationPS")
      : (window.location.href = "/#/new-notarization");
  };
  const downloadDocument = journalId => {
      publicIp.v4().then(ipaddr => {
          if (ipaddr){
            let xhr = new XMLHttpRequest();
            xhr.open("POST", MIDDLEWARE_URL + "/api/journal/" + journalId + "/pdf");
            xhr.setRequestHeader("content-type", "application/json");
            xhr.setRequestHeader("authorization", "Bearer " + auth.authToken);
            xhr.responseType = "arraybuffer";
            xhr.onreadystatechange = e => {
              if (xhr.status == "200" && xhr.readyState == 4) {
                let data = xhr.response;
                let file = new Blob([data], { type: "application/pdf" });
                let a = document.createElement("a");
                a.href = URL.createObjectURL(file);
                a.download = journalId + "-document.pdf";
                a.click();
              }
            };
            xhr.send(JSON.stringify({ip:ipaddr}));
          }
      });
    
    
  };

  const auditReport = journalId => {
    let xhr = new XMLHttpRequest();
    xhr.open(
      "GET",
      MIDDLEWARE_URL + "/api/journal/" + journalId + "/auditTrail"
    );
    xhr.setRequestHeader("content-type", "application/json");
    xhr.setRequestHeader("authorization", "Bearer " + auth.authToken);
    xhr.responseType = "arraybuffer";
    xhr.onreadystatechange = e => {
      if (xhr.status == "200" && xhr.readyState == 4) {
        let data = xhr.response;
        let file = new Blob([data], { type: "application/pdf" });
        let a = document.createElement("a");
        a.href = URL.createObjectURL(file);
        a.download = journalId + "-auditTrail.pdf";
        a.click();
      }
    };
    xhr.send(JSON.stringify());
  };

  const downloadJournal = journalId => {
    let xhr = new XMLHttpRequest();
    let date = new Date();
    console.log(date);
    let timeString = date.toTimeString();
    console.log(timeString);
    let [time , ...timeZone] = timeString.split(" ")

    timeZone = timeZone.join(" ")
    xhr.open(
      "POST",
      MIDDLEWARE_URL + "/api/journal/" + journalId + "/create-pdf"
    );
    xhr.setRequestHeader("content-type", "application/json");
    xhr.setRequestHeader("authorization", "Bearer " + auth.authToken);
    xhr.responseType = "arraybuffer";
    xhr.onreadystatechange = e => {
      if (xhr.status == "200" && xhr.readyState == 4) {
        let data = xhr.response;
        let file = new Blob([data], { type: "application/pdf" });
        let a = document.createElement("a");
        a.href = URL.createObjectURL(file);
        a.download = journalId + "-journal.pdf";
        a.click();
      }
    };
    xhr.send(JSON.stringify({timeZone}));
  };

  const closeCancel = () => {
    window.location.href = "#/journals";
    window.location.reload();
  };

  useEffect(() => {
    console.log("here")
    dispatch(
      actions.fetchJournals(
        {
          sortOrder: "desc", // asc||desc
          sortField: "id",
          pageNumber: 1,
          pageSize: 10
        },
        headers,
        auth.user.Role.code
      )
    );

    if (auth.user.Role.code === "notary") {
      dispatch(
        actions.fetchCSVJournals(
          {
            sortOrder: "desc", // asc||desc
            sortField: "id",
            pageNumber: 1,
            pageSize: 1000
          },
          headers,
          auth.user.Role.code
        )
      );

      dispatch(
        actions.fetchClients(headers, {
          sortOrder: "asc",
          sortField: "firstName",
          pageNumber: 1,
          pageSize: 100000
        })
      );
    }
    else{
      dispatch(
        actions.fetchMyNotaries(
          {
            q: "",
            lang: "",
            type: "",
            state: "",
            sortOrder: "asc", // asc||desc
            sortField: "id",
            pageNumber: 1,
            pageSize: 10000
          },
          headers,
          auth.user.Role.code
        )
      );
    }
  }, []);

  return (
    <div>
      {resume.status ? (
        auth.user.Role.code === "notary" ? (
          <NewNotarizationNotary
            newNot={resume.status}
            id={resume.id}
            from="journal"
            resumeNotarization={resumeNotarization}
          />
        ) : (
          <>
            {" "}
            {auth.user.Role.code === "ps" ? (
              <NewNotarizationPS
                newNot={resume.status}
                id={resume.id}
                from="journal"
                resumeNotarization={resumeNotarization}
              />
            ) : (
              <NewNotarization
                newNot={resume.status}
                id={resume.id}
                from="journal"
                resumeNotarization={resumeNotarization}
              />
            )}
          </>
        )
      ) : (
        <TableUIProvider customersUIEvents={customersUIEvents}>
          <CustomerEditDialog
            show={actionModal}
            id={notaryID}
            resumeNotarization={resumeNotarization}
            addWitness={addWitness}
            downloadDocument={downloadDocument}
            auditReport={auditReport}
            downloadJournal={downloadJournal}
            onHide={() => {
              setActionModal(false);
              setNotaryID(null);
            }}
          />
          <TableCard 
            setmainfilter={setmainfilter}
            mainfilter={mainfilter}
            mainexcelfilter={mainexcelfilter}
            setmainexcelfilter={setmainexcelfilter}
            setnotaryName={setnotaryName}
            notaryName={notaryName}
            setnotaryEmail={setnotaryEmail}
            notaryEmail={notaryEmail}
            setclient={setclient}
            client={client}
            fromDate={fromDate}
            toDate={toDate}
            settoDate={settoDate}
            setfromDate={setfromDate}
            journalstatus={journalstatus}
            setjournalstatus={setjournalstatus}
            applyFilter={applyFilter}
            addNewHandler={addNewHandler}
            
          />
        </TableUIProvider>
      )}
      {/* {iscancelNotarization && (
        <GenericModal
          heading="ID Validation Failed"
          bodyText="Opps! One of your Signers failed their Identity verification. You can try again in 24 hours. "
          image="/media/own/alarm.png"
          btnTxt="Close"
          btnHandler={closeCancel}
          show={true}
        />
      )} */}
    </div>
  );
}
