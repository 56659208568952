import { Divider, makeStyles } from "@material-ui/core";
import React from "react";
import { Button, Modal, Table } from "react-bootstrap";
import Avatar from "@material-ui/core/Avatar";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

const useStyles = makeStyles(theme => ({
  draft: {
    minWidth: "110px",
    backgroundColor: "#524440",
    color: "#fff",
    textAlign: "center",
    borderRadius: "5px",
    padding: "9px 5px"
  },
  progress: {
    minWidth: "110px",
    backgroundColor: "#eee",
    color: "#000",
    textAlign: "center",
    borderRadius: "5px",
    padding: "9px 5px"
  },
  completed: {
    minWidth: "110px",
    backgroundColor: "#bef2de",
    color: "#000",
    textAlign: "center",
    borderRadius: "5px",
    padding: "9px 5px"
  },
  scheduled: {
    minWidth: "110px",
    backgroundColor: "#ff9a00",
    color: "#fff",
    textAlign: "center",
    borderRadius: "5px",
    padding: "9px 5px"
  },
  initiated: {
    minWidth: "110px",
    backgroundColor: "#ffff83",
    color: "#000",
    textAlign: "center",
    borderRadius: "5px",
    padding: "9px 5px"
  },
  pending: {
    minWidth: "110px",
    backgroundColor: "#4ABD54",
    color: "#fff",
    textAlign: "center",
    borderRadius: "5px",
    padding: "9px 5px"
  },
  paid: {
    minWidth: "110px",
    backgroundColor: "#4ABD54",
    color: "#fff",
    textAlign: "center",
    borderRadius: "5px",
    padding: "9px 5px"
  },
  alignCenter: {
    textAlign: "center"
  },
  buttonStyle: {
    backgroundColor: "#1840d2",
    borderColor: "#1840d2",
    minWidth: "165px",
    float: "right",
    marginBottom: "10px"
  },
  avatar_pic: {
    marginRight: "10px",
    float: "left",
    width: theme.spacing(4),
    height: theme.spacing(4)
  }
}));

export function CustomerEditForm({ onHide, data }) {
  const classes = useStyles();
  let expiryDatestr = data.notaryProfile.notaryExpiry;

  if (!expiryDatestr) expiryDatestr = "09/09/9999";

  let expiryDate = expiryDatestr.split("-");
  expiryDate = expiryDate.reverse();

  let adobeEmail = (data?.notaryEmail && data?.notaryEmail?.email) ? data?.notaryEmail?.email : null;

  const getCurrentStatus = ( notaryProfile ) => {
    
    let status = "";
    if( notaryProfile.isApproved ) {
      status = "Active"
    }
    else if( notaryProfile.isCancelled ) {
      status = "Cancelled"
    }
    else if( notaryProfile.isPending ) {
      status = "Pending"
    }
    else if( !notaryProfile.isApproved && !notaryProfile.isCancelled && !notaryProfile.isPending ) {
      status = "InActive"
    }
    return status;
  }


  return (
    <>
      <Modal.Body className="overlay overlay-block cursor-default">
        {
          // data !== null && data !== undefined &&
          <>
            <Table responsive borderless={true}>
              <thead>
                <tr>
                  <th>NOTARY&nbsp;NAME</th>
                  <th>EMAIL</th>
                  <th>PHONE</th>
                  <th>CITY</th>
                  <th>STATE</th>
                  <th>ZIP</th>
                  <th>ADDRESS</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Avatar
                      className={classes.avatar_pic}
                      variant="rounded"
                      alt="profile pic"
                      src={
                        data.profile_picture !== null
                          ? `${data.profile_picture.baseUrl}/${data.profile_picture.path}`
                          : toAbsoluteUrl("/media/users/blank.png")
                      }
                    >
                      OR
                    </Avatar>
                    {`${data.firstName} ${data.lastName}`}
                  </td>
                  <td>{(data.email) ? data.email : '-'}</td>
                  <td>{(data.phone) ? data.phone : '-'}</td>
                  <td>{(data.city) ? data.city : '-'}</td>
                  <td>{(data.state) ? data.state : '-'}</td>
                  <td>{(data.zip) ? data.zip : '-'}</td>
                  <td>{(data.address) ? data.address : '-'}</td>
                </tr>
              </tbody>
            </Table>
            <Divider />
            <Table className="mt-5" responsive borderless={true}>
              <tbody>
                <tr>
                  <td><strong>ADOBE EMAIL: </strong></td>
                  <td><span>{(adobeEmail) ? adobeEmail : '-'}</span></td>
                  <td><span className="p-3"></span></td>
                  <td><span className="p-3"></span></td>
                  <td><span className="p-3"></span></td>
                  <td><span className="p-3"></span></td>
                  <td><span className="p-3"></span></td>
                </tr>
              </tbody>
            </Table>
            <Divider />
            <Table responsive borderless={true}>
              <thead>
                <tr>
                  <th>COMMISSION&nbsp;#</th>
                  <th>COMMISSION&nbsp;STATE</th>
                  <th>NOTARY&nbsp;TYPE</th>
                  <th>NOTARY&nbsp;EXPIRATION</th>
                  <th>LANGUAGES</th>
                  <th>FILES</th>
                  <th className="text-center">STATUS</th>
                </tr>
              </thead>
              <tbody>
                <td>{data.notaryProfile.commissionNo != null ? data.notaryProfile.commissionNo : "-"}</td>
                <td>{data.notaryProfile.commisionState != null ? data.notaryProfile.commisionState : "-"}</td>
                <td>
                  {data.notaryProfile.notaryTypes.map(el => (
                    <span>
                      {el.type}
                      <br />
                    </span>
                  ))}
                </td>
                <td>{expiryDate.join("/")}</td>
                <td>
                  {data.Languages.map(el => (
                    <span>
                      {el.name}
                      <br />
                    </span>
                  ))}
                </td>
                <td>
                  {data.notaryProfile.passport_file ? (
                    <a
                      className={`mr-0 ml-0 pl-0 pr-0`}
                      href={`${data?.notaryProfile?.passport_file?.baseUrl}${(data?.notaryProfile?.passport_file?.path[0] == "/" ? "": "/")}${data?.notaryProfile?.passport_file?.path}`}
                      download
                      target="_blank"
                    >
                      Passport/DL
                    </a>
                  ) : (
                    ""
                  )}
                  <br />
                  {data.notaryProfile.insurance_policy ? (
                    <a
                      className={`mr-0 ml-0 pl-0 pr-0`}
                      href={`${data.notaryProfile.insurance_policy.baseUrl}${(data?.notaryProfile?.passport_file?.path[0] == "/" ? "": "/")}${data.notaryProfile.insurance_policy.path}`}
                      download
                      target="_blank"
                    >
                      E&O Insurance Policy
                    </a>
                  ) : (
                    ""
                  )}
                </td>
                <td>
                  {getCurrentStatus( data.notaryProfile )}
                </td>
              </tbody>
            </Table>
          </>
        }
      </Modal.Body>
    </>
  );
}
