import React from "react";
import { Modal } from "react-bootstrap";
import { CustomerEditForm } from "./CustomerEditForm";

export function CustomerEditDialog({
  data,
  show,
  onHide,
  completed,
  schedule,
  openClaimPopup,
  claimWitnessJob
}) {
  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      dialogClassName="modal-width"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <CustomerEditForm
        onHide={onHide}
        data={data}
        schedule={schedule}
        completed={completed}
        openClaimPopup={openClaimPopup}
        claimWitnessJob={claimWitnessJob}
      />
    </Modal>
  );
}
