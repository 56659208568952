// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { Button, Modal, Table } from "react-bootstrap";
export function overRideKBAColumnFormatter(
  cellContent,
  row,
  checkKBA,
  setcheckKBA
) {
  let allkbaslink =
    row.kbaSettings && row.kbaSettings[0] && row.kbaSettings[0].bypassAllowed;
  let currentkbaclick = false;

  const resetoverride = id => {
    setcheckKBA.setcheckKBA(0);
    setcheckKBA.resetbypassKBAfinalize(id);
  };

  const checkinKBA = e => {
    setcheckKBA.setcheckKBA(e.target.value);
  };

  const showreason = id => {
    setcheckKBA.setcheckKBA(id);
  };

  const setReason = e => {
    //setcheckKBA
    setcheckKBA.setReason(e.target.value);
  };

  const revertKBA = () => {
    setcheckKBA.setcheckKBA(0);
  };

  return (
    <span>
      {row.kbaSettings &&
      row.kbaSettings[0] &&
      row.kbaSettings[0].bypassAllowed ? (
        <>
          <FormControlLabel
            control={
              <Switch
                checked={true}
                onChange={checkinKBA}
                value={row.id}
                disabled={
                  row.kbaSettings &&
                  row.kbaSettings[0] &&
                  row.kbaSettings[0].bypassAllowed
                }
              />
            }
            // label="Select your primary notary for this notarization?"
            labelPlacement="start"
          />{" "}
          <Button
            className={`mr-0 ml-0 pl-0 pr-0 resetoverride`}
            onClick={() => resetoverride(row.id)}
          >
            Reset Override
          </Button>
          <Button
            className={`mr-0 ml-0 pl-0 pr-0 showreason`}
            onClick={() => showreason(row.id)}
          >
            Show Reason
          </Button>
        </>
      ) : (
        <FormControlLabel
          control={
            <Switch
              checked={setcheckKBA.checkKBA == row.id}
              onChange={checkinKBA}
              value={row.id}
            />
          }
          // label="Select your primary notary for this notarization?"
          labelPlacement="start"
        />
      )}

      {row.kbaSettings &&
      row.kbaSettings[0] &&
      row.kbaSettings[0].bypassAllowed ? (
        <Modal
          show={setcheckKBA.checkKBA == row.id}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Reason</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{row.kbaSettings[0].reason}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={revertKBA}>Close</Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <Modal
          show={setcheckKBA.checkKBA == row.id}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Set Override KBA Reason
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Reason</h4>
            <div>
              <textarea
                name="reason"
                onChange={setReason}
                style={{ width: "100%", height: "200px" }}
              ></textarea>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setcheckKBA.bypassKBAfinalize(row.id)}
            >
              Submit
            </Button>
            <Button onClick={revertKBA}>Close</Button>
          </Modal.Footer>
        </Modal>
      )}
    </span>
  );
}
