import { Divider, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Modal, Table, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../../components/_redux/notarization_redux/notarizationActions'
import ShowNotification from '../../../../components/new_notarization/ShowNotification'
import { CancelNotarization } from '../../../signwitnesss/table/CancelNotarization'
import { AddSigner } from '../../../modals/AddSigner'
import CheckIcon from '@material-ui/icons/Check'
import { useStyles } from '../../../modals/JournalSignerModal'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment';
import moment from 'moment';

export function CustomerEditForm({
    onHide,
    id,
    resumeNotarization,
    addWitness,
    downloadDocument,
    auditReport,
}) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { socket, auth, notarization } = useSelector((state) => state)
    const [signerLinks, setsignerLinks] = useState({})
    const [copyLinkURL, setcopyLinkURL] = useState('')
    const [bypassReason, setbypassReason] = useState('')
    const [rescehduleDate, setrescehduleDate] = useState(new Date())
    const [dateTime, setDateTime] = React.useState(new Date().toISOString())
    const [RescheduleMode, setRescheduleMode] = useState(false)
    const [isbypasskba, setisbypasskba] = useState(0)
    const [KBASettingscheck, setKBASettingscheck] = useState(false)
    const [KBASettingscheckdispatch, setKBASettingscheckdispatch] = useState(
        false
    )
    const [addSigner, setAddSigner] = useState()
    const [issingleKBA, setissingleKBA] = useState(false)
    const {
        allJournals,
        paymentDetails,
        SingleJournalObj,
        jitsiMeetingLinks,
        KBASettings,
        clients,
        top5Journals,
    } = notarization
    const [data, setdata] = useState(null)
    const [notification, setNotification] = useState({
        show: false,
        msg: '',
        variant: '',
    })
    const [singleByPass, setsingleByPass] = useState(false)
    const [
        openTransferConfirmationDialog,
        setOpenTransferConfirmationDialog,
    ] = useState(false)
    const [
        closeTransferConfirmationDialog,
        setCloseTransferConfirmationDialog,
    ] = useState(false)

    const [updatedLinkInfo, setUpdatedLinkInfo] = useState(null)
    const [socketCallBackResponse, setSocketCallBackResponse] = useState(null)
    const headers = {
        Authorization: `Bearer ${auth.authToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
    }

    const publicIp = require('public-ip')

    const rescheduleModeStart = () => {
        setRescheduleMode(true)
    }

    const dateTimeHandler = (e) => {
        setrescehduleDate(new Date(e))
    }
    
    useEffect(() => {
        dispatch(actions.getJournalbyID(id, headers))
        dispatch(actions.getJitsiMeetingLinks(id, headers))
    }, 
    [
        socket.kbaSignerStatus,
        socket.idScanSignerStatus,
        socket.biometricSignerStatus,
        socket.kbaVerificationSingle,
        socket.kbaVerificationRejectedNotary,
        socket.kbaVerificationRejected,
        socket.signerPageAttemptsExceeded
    ])

    useEffect(() => {
        let data = SingleJournalObj
        setdata(data)

        if (!KBASettingscheckdispatch) {
            dispatch(actions.fetchKBASettings(headers))

            setKBASettingscheckdispatch(true)
        }

        if (KBASettings && KBASettings.data && !KBASettingscheck) {
            setKBASettingscheck(true)
        }
        if (data && data.Signers) {
            for (let isign = 0; isign < data.Signers.length; isign++) {
                if (data.Signers[isign].bypassKBA) setissingleKBA(true)
            }
        }
    }, [SingleJournalObj, KBASettings])

    const checkinPrimary = (userId) => {
        let isclient = false
        clients.rows.map((client, index) => {
            if (userId == client.id) isclient = true
        })

        return isclient
    }

    const prepareTime = (dt) => {
        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ]
        let date = new Date(dt)
        let time = formatAMPM(date)
        let mon = months[date.getMonth()]
        let curDate = date.getDate()
        let year = date.getFullYear()
        let timezone = seconds_with_leading_zeros(date)
        return `${mon} ${curDate}, ${year} at ${time} (${timezone})`
    }

    const formatAMPM = (date) => {
        var hours = date.getHours()
        var minutes = date.getMinutes()
        var ampm = hours >= 12 ? 'PM' : 'AM'
        hours = hours % 12
        hours = hours ? hours : 12
        minutes = minutes < 10 ? '0' + minutes : minutes
        var strTime = hours + ':' + minutes + ' ' + ampm
        return strTime
    }

    const seconds_with_leading_zeros = (dt) => {
        return /\((.*)\)/.exec(dt.toString())[1]
    }

    const acceptscheduleNotification = () => {
        callscheduleNotification('accepted')
    }

    const reschedulescheduleNotification = () => {
        callscheduleNotification('rescheduled')
    }

    const cancelscheduleNotification = () => {
        callscheduleNotification('rejected')
    }

    const callscheduleNotification = async (acceptbool) => {
        const datastr = {
            notaryResponse: acceptbool,
        }
        if ('rescheduled' == acceptbool)
            datastr['scheduleDate'] = new Date(rescehduleDate).toISOString()

        if (socket.scheduleNotificationResponse) {
            dispatch(actions.acceptSchedule(headers, datastr, data.id)).then(
                (scheduleresponse) => {
                    if (
                        scheduleresponse &&
                        scheduleresponse.data &&
                        scheduleresponse.data.status
                    ) {
                        setTimeout(function() {
                            window.location.reload()
                        }, 3000)
                    }
                }
            )
        } else {
            dispatch(actions.acceptSchedule(headers, datastr, data.id)).then(
                (scheduleresponse) => {
                    if (
                        scheduleresponse &&
                        scheduleresponse.data &&
                        scheduleresponse.data.status
                    ) {
                        setTimeout(function() {
                            window.location.reload()
                        }, 3000)
                    }
                }
            )
        }
    }

    function copyToClipboard(textToCopy) {
        // navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            // navigator clipboard api method'
            return navigator.clipboard.writeText(textToCopy);
        } else {
            // text area method
            let textArea = document.createElement("textarea");
            textArea.value = textToCopy;
            // make the textarea out of viewport
            textArea.style.position = "fixed";
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            
            var oldContentEditable =textArea.contentEditable,
            oldReadOnly = textArea.readOnly,
            range = document.createRange();

            textArea.contentEditable = true;
            textArea.readOnly = false;
            range.selectNodeContents(textArea);

            var s = window.getSelection();
            s.removeAllRanges();
            s.addRange(range);

            textArea.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.

            textArea.contentEditable = oldContentEditable;
            textArea.readOnly = oldReadOnly;

            document.execCommand('copy');

        }
    }

    const copyLink = (event, id) => {
        
        let x = event.target;
        let link = document.location.origin + `/#/id-proofing/?id=${id}`;
        
        copyToClipboard(link);
        
        x.innerHTML = "Copied !";
        setTimeout(() => {
            x.innerHTML = "ID proofing"
        }, 2000, x);
    }

    const bypassKBAMode = (id) => {
        setisbypasskba(id)
    }

    const bypassKBAfinalize = () => {
        if (!bypassReason) {
            setNotification({
                show: true,
                msg: 'Please provide some reason !',
                variant: 'error',
            })
            return
        }
        let body = {
            signerId: isbypasskba,
            bypassReason: bypassReason,
            journalId: data.id,
        }

        const headers = {
            Authorization: `Bearer ${auth.authToken}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }

        let journalID = data.id

        dispatch(actions.bypassKBA(body, headers)).then((data) => {
            setisbypasskba(0)

            dispatch(actions.reloadcall(journalID))
        })
    }
    const tranferNotarization = () => {
        const headers = {
            Authorization: `Bearer ${auth.authToken}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }

        let notarizationId = data.id

        dispatch(actions.tranferNotarization(notarizationId, headers)).then(
            (data) => {
                window.location.reload()
            }
        )
    }
    const setReason = (e) => {
        setbypassReason(e.target.value)
    }

    const proceedNotarization = (journalID) => {
        window.location.href = '#/signwitness/?id=' + journalID
    }

    function GetFinalPayment(seals, witnessCount) {
        let total = 0
        let notaryFee = 0
        let platformFee = 0
        let witnessFee = 0
        total += witnessFee

        if (paymentDetails) {
            total =
                seals === 0
                    ? 0
                    : paymentDetails.sealFirstFee +
                      (seals - 1) * paymentDetails.sealSecFee
            notaryFee =
                seals === 0
                    ? 0
                    : paymentDetails.notaryFeePerFirstSeal +
                      (seals - 1) * paymentDetails.notaryFeePerSecSeal
            platformFee =
                seals === 0
                    ? 0
                    : paymentDetails.platformPerFirstSeal +
                      (seals - 1) * paymentDetails.platformPerSecSeal
            witnessFee =
                witnessCount === 0
                    ? 0
                    : witnessCount * paymentDetails.witnessFee
            total += witnessFee
        }

        return {
            total: total,
            notaryFee: notaryFee,
            platformFee: platformFee,
            witnessFee: witnessFee,
        }
    }

    let paymentBreakDown = null
    let filteredWitnesses = []
    if (data !== undefined && data != null) {
        filteredWitnesses =
            data.Witnesses.length > 0
                ? data.Witnesses.filter((el) => el.status !== 'rejected')
                : []
        paymentBreakDown = GetFinalPayment(
            data.sealCount !== null ? data.sealCount : 0,
            filteredWitnesses.length
        )
    }

    const joinMeeting = (meetingURL) => {
        window.top.location.href = meetingURL
    }

    let rejectedDate = null
    let nowDate = Date.parse(new Date())
    if (data && data.rejectedUntil !== null) {
        rejectedDate = Date.parse(data.rejectedUntil)
    }

    let totalPrice = '--'

    if (data !== undefined && data != null && data.Payments.length > 0) {
        totalPrice = 0
        for (var i = 0; i < data.Payments.length; i++) {
            totalPrice += parseFloat(data.Payments[i].amount) / 100
        }
        totalPrice = '$' + totalPrice.toFixed(2)

        //   totalPrice += paymentBreakDown.total;
        //  totalPrice = '$' + totalPrice.toFixed(2);
    }

    const getRecordingName = (recordingType) => {
        let recordingTypestr = recordingType.replace(/_/g, ' ')
        return recordingTypestr
            .toLowerCase()
            .replace(/\b[a-z]/g, function(letter) {
                return letter.toUpperCase()
            })
    }

    const isBiometricEnabled = () => {
        return auth?.user?.biometricSettings?.biometricEnabled
    }

    const isFailedSigner = () => {
        let isFailedSigner = false
        data.Signers.map((signer) => {
            if ('rejected' === signer.status) {
                isFailedSigner = true
            }
        })
        return isFailedSigner
    }

    const handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') return
        setNotification({ show: false, msg: '', variant: '' })
    }

    const resumeNotarizationselectNotary = (id) => {
        window.location.href = '/#/new-notarization?preselectNotarization=' + id
    }

    const idology = (data, type, signerID = '') => {
        const filtered = data?.SignerAuths?.filter((item) => ( item.type === type && item.signerId === signerID && item.callbackResponse !== null ) )
        if (
            filtered?.length &&
            filtered[filtered.length - 1].callbackResponse &&
            ['biometric', 'idScan', 'kba'].includes(type) 
        ) {
            return callBackResult(
                filtered[filtered.length - 1].callbackResponse,
                type
            )
        }
        return '-'
    }

    const acceptNotarization = (journalId) => {
        publicIp.v4().then((ipaddr) => {
            dispatch(
                actions.acceptNotarization(headers, journalId, ipaddr)
            ).then((notarizationresponse) => {
                if (notarizationresponse) {
                    if (notarizationresponse.data.success) {
                        setTimeout(function() {
                            window.location.href =
                                '#/signwitness/?id=' + journalId
                            window.location.reload()
                        }, 3000)
                    }
                }
            })
        })
    }

    const rejectNotarization = async (journalId) => {
        let notarizationresponse = await dispatch(
            actions.rejectNotarization(headers, journalId)
        )
        if (notarizationresponse) {
            if (notarizationresponse.data.success) {
                setTimeout(function() {
                    window.location.reload()
                }, 2000)
            }
        }
    }

    return (
        <>
            {notification.show && (
                <ShowNotification
                    show={notification.show}
                    msg={notification.msg}
                    variant={notification.variant}
                    close={handleCloseNotification}
                />
            )}

            <Modal.Body className='overlay overlay-block cursor-default'>
                {data !== null && data !== undefined && (
                    <>
                        { 
                            data.status === "rescheduled" && (
                                <div class="alert alert-warning text-center" role="alert">
                                    Reschedule Requested This notarization will not start until the other party accepts the appointment time.
                                </div>
                            )
                        }
                        {
                            data.status === "pending" && (
                                <div class="alert alert-warning text-center" role="alert">
                                    The notarization is saved and will begin once the party starts the process.
                                </div>
                            )
                        }
                        <div className='container text-center'>
                            <h4><strong>Notarization: </strong> {data.id}</h4>
                            <hr />
                        </div>
                        <Table responsive borderless={true}>
                            
                            <tr>
                                <th className='text-center'>
                                    START&nbsp;DATE&nbsp;&&nbsp;TIME
                                </th>
                                <th className='text-center'>
                                    END&nbsp;DATE&nbsp;&&nbsp;TIME
                                </th>
                                <th className='text-center'>STATUS</th>
                            </tr>
                            <tr>
                                <td className='text-center'>
                                    {data.scheduledAt !== null ? (
                                        <p className='mb-0'>
                                            {prepareTime(data.scheduledAt)}
                                        </p>
                                    ) : (
                                        '-'
                                    )}
                                </td>
                                <td className='text-center'>
                                    {data.endDate !== null ? (
                                        <p className='mb-0'>
                                            {prepareTime(data.endDate)}
                                        </p>
                                    ) : (
                                        '-'
                                    )}
                                </td>
                                <td>
                                    <p className={classes[data.status]}>
                                        {
                                            (
                                                data.status === 'progress' ||
                                                data.status === 'draft'
                                            )
                                            ? (data.subStatus === null || data.subStatus === "")
                                                ? data.status
                                                : data.subStatus ===
                                                    'rejected'
                                                ? 'Failed'
                                                : data.subStatus
                                            : data.status
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                data.status.slice(1)}
                                    </p>
                                </td>
                            </tr>

                            <tr>
                                <th>DOCUMENT&nbsp;TITLE</th>
                                <th>TYPE</th>
                            </tr>
                            <tr>
                                {['rescheduled', 'scheduled'].includes(
                                    data.status
                                ) && auth?.user?.Role?.code === 'notary' ? (
                                    <>
                                        {data.journalDocs ? (
                                            <td>
                                                {data.journalDocs.map((el) => (
                                                    <p
                                                        className='wrapWord'
                                                        key={el.id}>
                                                        <a
                                                            href={
                                                                el.File
                                                                    .baseUrl +
                                                                '/' +
                                                                el.File.path
                                                            }
                                                            target='_blank'>
                                                            {el.File.filename
                                                                .substring(14)
                                                                .split(' ')
                                                                .map((el) => (
                                                                    <>
                                                                        {el}
                                                                        &nbsp;
                                                                    </>
                                                                ))}
                                                        </a>
                                                        <span>
                                                            {el.docType ? (
                                                                <>
                                                                    (
                                                                    {el.docType}
                                                                    ){' '}
                                                                </>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </span>
                                                    </p>
                                                ))}
                                            </td>
                                        ) : (
                                            <td>
                                                {data.documents.map((el) => (
                                                    <p
                                                        className='wrapWord'
                                                        key={el.id}>
                                                        {el.filename
                                                            .substring(14)
                                                            .split(' ')
                                                            .map((el) => (
                                                                <>
                                                                    {el}
                                                                    &nbsp;
                                                                </>
                                                            ))}
                                                    </p>
                                                ))}
                                            </td>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {data.journalDocs ? (
                                            <td>
                                                {data.journalDocs.map((el) => (
                                                    <p
                                                        className='wrapWord'
                                                        key={el.id}>
                                                        {el.File.filename
                                                            .substring(14)
                                                            .split(' ')
                                                            .map((el) => (
                                                                <>
                                                                    {el}
                                                                    &nbsp;
                                                                </>
                                                            ))}{' '}
                                                        <span>
                                                            {el.docType ? (
                                                                <>
                                                                    (
                                                                    {el.docType}
                                                                    )
                                                                </>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </span>
                                                    </p>
                                                ))}
                                            </td>
                                        ) : (
                                            <td>
                                                {data.documents.map((el) => (
                                                    <p
                                                        className='wrapWord'
                                                        key={el.id}>
                                                        {el.filename
                                                            .substring(14)
                                                            .split(' ')
                                                            .map((el) => (
                                                                <>
                                                                    {el}
                                                                    &nbsp;
                                                                </>
                                                            ))}
                                                    </p>
                                                ))}
                                            </td>
                                        )}
                                    </>
                                )}

                                <td>{data.type === '' ? '-' : data.type}</td>
                            </tr>
                        </Table>
                        <Divider />
                        <div>
                            <div className="comments-div-padding">
                                <span className="comments-heading">
                                    COMMENTS
                                </span>
                            </div>
                            <div className="comments-div-padding">
                                <span className="word-break">
                                    {data.comments}
                                </span>
                            </div>

                        </div>
                        <Divider />
                        
                        <Table responsive borderless={true}>
                            <thead>
                                <tr>
                                    <th>SIGNER&nbsp;NAME</th>
                                    <th>EMAIL/PHONE</th>
                                    {data.status === 'progress' && (data.subStatus == 'Authenticating Participants' || data.subStatus == 'Awaiting Notary Response') ? (
                                        <>
                                            
                                            <th>ADDRESS</th>
                                            <th>DOB</th>
                                            <th>LAST 4 of SSN</th>
                                        </>
                                    ) : (
                                        ''
                                    )}
                                    
                                    <th>OVERRIDE ID PROOFING</th>
                                    <th>REASON</th>
                                    <th>KBA</th>
                                    <th>ID UPLOAD</th>
                                    <th>BIOMETRICS</th>
                                    <th>LINKS:</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.Signers.map((el) => {
                                    return (
                                        <>
                                            {' '}
                                            <tr key={el.id}>
                                                <td>
                                                    {el.firstName}{' '}
                                                    {el.lastName}
                                                </td>
                                                <td>{el.email}<br/>{el.phone}</td>

                                                {data.status === 'progress' && (data.subStatus == 'Authenticating Participants' || data.subStatus == 'Awaiting Notary Response') ? (
                                                    <>                                                        
                                                        <td>{el.address}{', '}{el.city}{', '}{el.state}{', '}{el.zip}</td>                                                
                                                        <td>{moment(el.dob).format('MM/DD/YYYY')}</td>                                               
                                                        <td>{el.ssnLast4}</td>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                               
                                                <td>
                                                    {el.bypassKBA
                                                        ? 'Yes'
                                                        : 'No'}
                                                </td>
                                                <td>
                                                    {el.bypassKBA
                                                        ? el.bypassReason
                                                        : '-'}
                                                </td>
                                                <td>
                                                    {
                                                        idology(
                                                            el,
                                                            'kba',
                                                            el.id
                                                        )
                                                    }
                                                </td>
                                                <td>
                                                    {idology(
                                                        el,
                                                        'idScan',
                                                        el.id
                                                    )}
                                                </td>
                                                <td>
                                                    {idology(
                                                        el,
                                                        'biometric',
                                                        el.id
                                                    )}
                                                </td>

                                                {auth?.user?.Role?.code === 'notary' &&
                                                !auth.user.notaryProfile.isCancelled &&
                                                data?.notaryId === auth.user.id &&
                                                data.subStatus === 'Authenticating Participants' ? (
                                                    <td>
                                                        {
                                                            (el.pageAttempts > 3) && (
                                                                <Button 
                                                                    variant='warning'
                                                                    disabled={true}
                                                                    style={{
                                                                        marginRight:'5px',
                                                                    }}
                                                                >
                                                                    Limit Exceeded
                                                                </Button>
                                                            )
                                                        }
                                                        {
                                                            el.status === 'pending' &&
                                                            !el.bypassKBA && (
                                                                <Button 
                                                                        variant='success'
                                                                        onClick={(event) => copyLink(event, el.id)}
                                                                        style={{
                                                                            marginRight:'5px',
                                                                        }}
                                                                    >
                                                                        ID proofing
                                                                </Button>
                                                            )
                                                        }
                                                        <span>
                                                            {el.status !==
                                                                'verified' &&
                                                            !el.bypassKBA ? (
                                                                <Button
                                                                    variant='success'
                                                                    id={
                                                                        'byPassKBA' +
                                                                        el.id
                                                                    }
                                                                    onClick={() =>
                                                                        bypassKBAMode(
                                                                            el.id
                                                                        )
                                                                    }>
                                                                    Override
                                                                </Button>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </span>
                                                    </td>
                                                ) : (
                                                    <td>&nbsp; -</td>
                                                )}
                                            </tr>
                                            {isbypasskba === el.id &&
                                                !el.bypassKBA && (
                                                    <tr>
                                                        <td colspan='4'>
                                                            <div>
                                                                <textarea
                                                                    name='reason'
                                                                    onChange={
                                                                        setReason
                                                                    }
                                                                    style={{
                                                                        width:
                                                                            '100%',
                                                                        height:
                                                                            '200px',
                                                                    }}></textarea>
                                                                <Button
                                                                    variant='success'
                                                                    onClick={() =>
                                                                        bypassKBAfinalize(
                                                                            el.id
                                                                        )
                                                                    }>
                                                                    Submit
                                                                </Button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                        </>
                                    )
                                })}
                            </tbody>
                        </Table>
                        {auth?.user?.Role?.code === 'notary' &&
                            filteredWitnesses.length > 0 && (
                                <>
                                    <Divider />
                                    <Table responsive borderless={true}>
                                        <thead>
                                            <tr>
                                                <th>WITNESS&nbsp;NAME</th>
                                                <th>WITNESS&nbsp;EMAIL</th>
                                                {/* <th>WITNESS&nbsp;IP</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredWitnesses.map((el) => {
                                                return (
                                                    <tr key={el.id}>
                                                        <td>
                                                            {el.User.firstName
                                                                .split(' ')
                                                                .map((el) => (
                                                                    <>
                                                                        {el}
                                                                        &nbsp;
                                                                    </>
                                                                ))}
                                                            {el.User.lastName
                                                                .split(' ')
                                                                .map((el) => (
                                                                    <>
                                                                        {el}
                                                                        &nbsp;
                                                                    </>
                                                                ))}
                                                        </td>
                                                        <td>{el.User.email}</td>
                                                        {/* <td>{el.IP === "" ? "-" : el.IP}</td> */}
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </>
                            )}

                        {
                        addSigner &&
                        auth.user.Role.code === "notary" &&
                        !auth.user.notaryProfile.isCancelled &&
                        data.subStatus === 'Authenticating Participants' && (
                            <AddSigner
                                openModal={addSigner}
                                onHide={onHide}
                                journalId={data.id}
                            />
                        )}
                        <Divider />

                        <br />
                        <div className='d-flex justify-content-between align-items-flex-start'>
                            <div className='d-block'>
                                <div className='container p-0'>
                                    {(auth &&
                                        auth.user &&
                                        auth.user.Role.code === 'notary' &&
                                        !auth.user.notaryProfile.isCancelled &&
                                        data?.notaryId === auth.user.id &&
                                        (data.status === 'scheduled' ||
                                        data.status === 'rescheduled')) && (
                                        <>
                                            <Button
                                                className={`mr-0 ml-0 pl-0 pr-0  mr-3 ${classes.buttonStyle}`}
                                                onClick={() =>
                                                    setRescheduleMode(true)
                                                }>
                                                Reschedule
                                            </Button>
                                        </>
                                    )}

                                        {auth &&
                                            auth.user &&
                                            auth.user.Role.code !== 'notary' &&
                                            (data.status === 'scheduled' || data.status === 'rescheduled') && (
                                                <>
                                                    <Button
                                                        className={`mr-0 ml-0 pl-0 pr-0  mr-3 ${classes.buttonStyle}`}
                                                        onClick={() =>
                                                            setRescheduleMode(
                                                                true
                                                            )
                                                        }>
                                                        Reschedule
                                                    </Button>
                                                </>
                                            )}

                                    
                                        {auth &&
                                        auth.user.Role &&
                                        auth.user.Role.code !== 'notary' &&
                                        data.subStatus === 'by notary' &&
                                        (data.status === 'scheduled' ||
                                            data.status ===
                                                'rescheduled') && (
                                            <>
                                                <Button
                                                    className={`mr-0 ml-0 pl-0 pr-0  mr-3 ${classes.buttonStyle}`}
                                                    onClick={() =>
                                                        acceptscheduleNotification()
                                                    }>
                                                    Accept
                                                </Button>
                                                <Button
                                                    className={`mr-0 ml-0 pl-0 pr-0  mr-3 ${classes.buttonStyle}`}
                                                    onClick={() =>
                                                        cancelscheduleNotification()
                                                    }>
                                                    Reject
                                                </Button>
                                            </>
                                        )}

                                        {auth &&
                                        auth.user.Role &&
                                        auth.user.Role.code === 'notary' &&
                                        data.subStatus === 'by customer' &&
                                        !auth.user.notaryProfile.isCancelled &&
                                        data?.notaryId === auth.user.id &&
                                        (data.status === 'scheduled' ||
                                            data.status ===
                                                'rescheduled') && (
                                            <>
                                                <Button
                                                    className={`mr-0 ml-0 pl-0 pr-0  mr-3 ${classes.buttonStyle}`}
                                                    onClick={() =>
                                                        acceptscheduleNotification()
                                                    }>
                                                    Accept
                                                </Button>
                                                <Button
                                                    className={`mr-0 ml-0 pl-0 pr-0  mr-3 ${classes.buttonStyle}`}
                                                    onClick={() =>
                                                        cancelscheduleNotification()
                                                    }>
                                                    Reject
                                                </Button>
                                            </>
                                        )}
                                        {auth?.user?.Role?.code === 'notary' &&
                                            !auth.user.notaryProfile.isCancelled &&
                                            data?.notaryId === auth.user.id &&
                                            'progress' === data.status &&
                                            isFailedSigner() &&
                                            !isBiometricEnabled() && (
                                            <>
                                                <Button
                                                    variant='contained'
                                                    className='btn'
                                                    style={{
                                                        backgroundColor: '#eeb120',
                                                        color: 'white',
                                                        marginRight: '10px',
                                                    }}
                                                    onClick={
                                                        setOpenTransferConfirmationDialog
                                                    }>
                                                    Transfer to Job Board
                                                </Button>
                                                <Modal
                                                    size='md'
                                                    show={
                                                        openTransferConfirmationDialog
                                                    }
                                                    onHide={() =>
                                                        setOpenTransferConfirmationDialog(
                                                            false
                                                        )
                                                    }
                                                    aria-labelledby='example-modal-sizes-title-lg'>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>
                                                            Transfer to Job Board
                                                        </Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        Are you sure you want to
                                                        transfer?
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button
                                                            variant='secondary'
                                                            onClick={() =>
                                                                setOpenTransferConfirmationDialog(
                                                                    false
                                                                )
                                                            }>
                                                            No
                                                        </Button>
                                                        <Button
                                                            className='acceptBtn btn btn-secondary'
                                                            onClick={
                                                                tranferNotarization
                                                            }>
                                                            Yes
                                                        </Button>
                                                    </Modal.Footer>
                                                </Modal>
                                            </>
                                        )}
                                        {
                                        auth?.user?.Role?.code === 'notary' &&
                                        !auth.user.notaryProfile.isCancelled &&
                                        data?.notaryId === auth.user.id &&
                                        data?.Signers.length < 10 &&
                                            data.subStatus ===
                                                'Authenticating Participants' && (
                                                <Button
                                                    variant='contained'
                                                    style={{
                                                        backgroundColor:
                                                            '#2d3fd3',
                                                        color: 'white',
                                                        marginRight: '10px',
                                                    }}
                                                    onClick={() =>
                                                        setAddSigner(true)
                                                    }>
                                                    Add an Additional Signer
                                                </Button>
                                            )}
                                        {
                                            auth.user.Role.code === 'notary' &&
                                            !auth.user.notaryProfile.isCancelled &&
                                            data.status === 'progress' &&
                                            data.subStatus ===
                                                'Authenticating Participants' && (
                                                <CancelNotarization
                                                    journalID={data.id}
                                                    alreadyOnDashboard={true}
                                                    showCard={false}
                                                />
                                            )
                                        }
                                    
                                    {auth?.user?.Role?.code === 'notary' &&
                                        !auth.user.notaryProfile.isCancelled &&
                                        data?.notaryId === auth.user.id &&
                                        data.status === 'progress' &&
                                        [
                                            'Accepted',
                                            'Awaiting Witnesses',
                                            'Witnesses Ready',
                                        ].indexOf(data.subStatus) !== -1 && (
                                            <>
                                                <Button
                                                    variant='contained'
                                                    style={{
                                                        backgroundColor:
                                                            '#2d3fd3',
                                                        color: 'white',
                                                    }}
                                                    onClick={() =>
                                                        proceedNotarization(
                                                            data.id
                                                        )
                                                    }>
                                                    Proceed Notarization
                                                </Button>
                                                <br />
                                            </>
                                        )
                                    }
                                    {auth &&
                                    auth.user &&
                                    auth.user.Role &&
                                    auth.user.Role.code === 'notary' &&
                                    data?.notaryId === auth.user.id ? (
                                        ''
                                    ) : (
                                        <span>
                                            {(
                                                ! data.deleted &&
                                                (
                                                    data.status === 'draft' || 
                                                    data.status === 'paid' || 
                                                    data.status === 'pending'
                                                ) &&
                                                (
                                                    (data.rejectedUntil === null) || 
                                                    (
                                                        rejectedDate !== null && 
                                                        nowDate > rejectedDate 
                                                    ) 
                                                ) 
                                            ) && (
                                                <>
                                                    <Button
                                                        className={`mr-0 ml-0 pl-0 pr-0 ${classes.buttonStyle}`}
                                                        onClick={() =>
                                                            resumeNotarization(
                                                                data.id
                                                            )
                                                        }>
                                                        Start Notarization
                                                    </Button>
                                                    <br />
                                                </>
                                            )}
                                        </span>
                                    )}

                                    {auth &&
                                    auth.user &&
                                    auth.user.Role &&
                                    auth.user.Role.code === 'notary' &&
                                    !auth.user.notaryProfile.isCancelled &&
                                    data?.notaryId === auth.user.id ? (
                                        <span>
                                            {   
                                                !data.deleted &&
                                                data.status === 'draft' &&
                                                (data.rejectedUntil ===
                                                    null ||
                                                    (rejectedDate !==
                                                        null &&
                                                        nowDate >
                                                            rejectedDate)) && (
                                                    <>
                                                        <Button
                                                            className={`mr-0 ml-0 pl-0 pr-0 ${classes.buttonStyle}`}
                                                            onClick={() =>
                                                                resumeNotarization(
                                                                    data.id
                                                                )
                                                            }>
                                                            Start
                                                            Notarization
                                                        </Button>
                                                        <br />
                                                    </>
                                                )}
                                        </span>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </div>
                        {auth &&
                        auth.user &&
                        auth.user.Role.code === 'notary' &&
                        data.subStatus === 'Awaiting Notary Response' &&
                        data?.notaryId === auth.user.id && (
                            <div className='d-flex align-items-center justify-content-between mb-5'>
                                <span>
                                    You have a new Notarization request:{' '}
                                </span>
                                <div>
                                    <Button
                                        variant='secondary'
                                        className='acceptBtn mr-5'
                                        onClick={() =>
                                            acceptNotarization(data.id)
                                        }>
                                        Accept
                                    </Button>
                                    
                                </div>
                            </div>
                        )}
                        {RescheduleMode ? (
                            <>
                                <div class='flex_class flex_class-alt'>
                                    <MuiPickersUtilsProvider utils={MomentUtils }>
                                        <DateTimePicker 
                                            label="Schedule Notarization"
                                            initialFocusedDate={rescehduleDate}
                                            value={rescehduleDate}
                                            onChange={dateTimeHandler}
                                            showTodayButton={true}
                                            disablePast={true}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className='modal-body-two'>
                                    <Button
                                        variant='secondary'
                                        className='acceptBtn'
                                        onClick={reschedulescheduleNotification}>
                                        Submit
                                    </Button>
                                </div>
                            </>
                        ) : (
                            ''
                        )}
                    </>
                )}
            </Modal.Body>
        </>
    )
}

const callBackResult = (callbackResponse, type) => {
    let parsed = "" 
    if("string" == typeof(callbackResponse)) {
        try {
            parsed = JSON.parse(callbackResponse) 
        } catch (e) {
            parsed = callbackResponse;
        }
    }
    else {
        parsed = JSON.parse(JSON.stringify(callbackResponse));
    }
    if (type === 'kba' && parsed) {
        if (parsed === 'Ineligible') {
            return <p style={{ color: 'red' }}>{parsed}</p>
        }
        if( parsed['idliveq-result']?.key && parsed['idliveq-result']?.key?._text == "result.timeout" ) {
            return (
                <p style={{ color: 'red'}}>
                    -
                </p>
            )
        }

        parsed = parsed['idliveq-result']?.key?._text.split('.')[2] //result.timeout
        if (parsed === 0) {
            return <CheckIcon />
        }
        return (
            <p style={{ color: `${5 - parsed <= 3 ? 'red' : 'green'}` }}>
                {5 - parsed} out of 5
            </p>
        )
    }

    if ( type === 'biometric' && parsed ) {

        if( parsed['located-id-scan-record'] && parsed['located-id-scan-record']['id-scan-facial-match-score']?._text ) {
            let score = parsed['located-id-scan-record']['id-scan-facial-match-score']?._text;
            let status = "";
            if ( parsed['id-scan-verification-result'] && parsed['id-scan-verification-result']?.message?._text ) {
                status = parsed['id-scan-verification-result']?.message?._text;
            }
            return (
                <>
                    <p style={{ color: `${score < 80 ? 'red' : 'green'}` }}>
                        {score}%
                    </p>
                    {
                        status && (
                            <p style={{ color: `${score < 80 ? 'red' : 'green'}` }}>
                                {status}
                            </p>
                        )
                    }
                </>
            )
        }
        else if( parsed['id-scan-result'] && parsed['id-scan-result']['message'] ) {
            let score = parsed['id-scan-result']['message']?._text;
            return (
                <p style={{ color: 'red' }}>
                    {score}
                </p>
            )
        }

        //for persona based notarizations
        else if(parsed.attributes) {
            let status = "";
            switch(parsed.attributes.status){
                case "needs_review":
                case "failed": 
                    status = "Failed";
                    break;
                case "expired":
                    status = "Expired";
                    break;
                case "approved":
                    status = "Approved";
                    break;
                default: 

            }
            return (
                <p style={{ color: `${parsed.attributes.status != 'approved' ? 'red' : 'green'}` }}>
                    {status}
                </p>
            )
        }

    }

    if ( type === 'idScan' && parsed ) {
        if ( parsed['id-scan-verification-result'] && parsed['id-scan-verification-result']?.message?._text ) {
            return (
                <p style={{ color: `${parsed['id-scan-summary-result'].message._text == "FAIL" ? 'red' : 'green'}` }}>
                    {parsed['id-scan-verification-result'].message._text}
                </p>
            )
        }
        else if( parsed['id-scan-result'] && parsed['id-scan-result']['message'] ) {
            let score = parsed['id-scan-result']['message']?._text;
            return (
                <p style={{ color: 'red' }}>
                    {score}
                </p>
            )
        }
        //for persona based notarizations
        else if(parsed.attributes) {
            let status = "";
            switch(parsed.attributes.status){
                case "needs_review":
                case "failed": 
                    status = "Failed";
                    break;
                case "expired":
                    status = "Expired";
                    break;
                case "approved":
                    status = "Approved";
                    break;
                default: 

            }
            return (
                <p style={{ color: `${parsed.attributes.status != 'approved' ? 'red' : 'green'}` }}>
                    {status}
                </p>
            )
        }
    }
    return '-'
}
