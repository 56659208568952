import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { toAbsoluteUrl } from '../../../_metronic/_helpers'
import { MultiSelect } from 'react-multi-select-component'
import TextField from '@material-ui/core/TextField'
import { Button } from 'react-bootstrap'
import {SelectNotaryModal} from './selectNotaryModal';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment';

export function PrefenceModal({
    heading,
    show,
    hide,
    lang,
    setLang,
    typeNotary,
    setTypeNotary,
    setprefstate,
    allAdminStates,
    prefstate,
    btnHandler,
    dateTime,
    setDateTime,
    languages,
    notaryTypes,
    btnHandlerMyNotaries,
    MyNotaries,
    setSelectNotary,
    selectNotaryModal,
    setSelectNotaryModal,
    selectedApprovedNotary,
    setSelectedApprovedNotary,
    isButtonClicked=false
}) {
    const handleChangeState = (event) => {
        setprefstate(event.target.value)
    }
    const handleChangeCheckBox = (event) => {
        let index = event.nativeEvent.target.selectedIndex;
        let label = event.nativeEvent.target[index].text;
        setLang([{ id: event.target.value, label: label }])
    }
    const handleChangeCheckBoxTypeNotary = (event) => {
        setTypeNotary(event.target.value)
    }
    const { auth, notarization } = useSelector((state) => state)
    const dateTimeHandler = (e) => {
        if (Date.parse(new Date(e)) < Date.parse(new Date())) {
            setDateTime(new Date())
        } else setDateTime(new Date(e))
    }

    return (
        <>
            <Modal
                show={true}
                onHide={() => {show(false)}}
                aria-labelledby='contained-modal-title-vcenter'
                centered>
                <Modal.Header className='justify-content-center pb-2'>
                    <Modal.Title id='contained-modal-title-vcenter'>
                        {heading}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body
                    style={{ textAlign: 'center' }}
                    className='pl-2 pr-2 pt-3 pb3'>
                    <div className='form-group row'>
                        <label className='col-xl-3 col-lg-3 col-form-label'>
                            Date and Time
                        </label>
                        <div className='col-lg-9 col-xl-6'>
                            <MuiPickersUtilsProvider utils={MomentUtils }>
                                <DateTimePicker 
                                    label="Date and Time"
                                    initialFocusedDate={dateTime}
                                    onChange={dateTimeHandler}
                                    value={dateTime}
                                    showTodayButton={true}
                                    disablePast={true}
                                />
                            </MuiPickersUtilsProvider>
                            {' '}
                        </div>
                    </div>
                    <div className='form-group row'>
                        <label className='col-xl-3 col-lg-3 col-form-label'>
                            Preferred Language
                        </label>
                        <div className='col-lg-9 col-xl-6'>
                            <select
                                placeholder='Select state'
                                className='form-control form-control-lg form-control-solid owais'
                                name='state'
                                value={lang && lang[0] && lang[0].id}
                                onChange={handleChangeCheckBox}>
                                <option value='' selected>
                                    Select Languages
                                </option>   
                                {languages &&
                                    languages.map((el) => (
                                        <option value={el.value} label={el.label}>
                                            {el.label}
                                        </option>
                                    ))}
                            </select>
                        </div>
                    </div>
                    <div className='form-group row'>
                        <label className='col-xl-3 col-lg-3 col-form-label'>
                            Type Of Notary
                        </label>
                        <div className='col-lg-9 col-xl-6'>
                            <select
                                placeholder='Select state'
                                className='form-control form-control-lg form-control-solid owais'
                                name='state'
                                value={typeNotary}
                                onChange={handleChangeCheckBoxTypeNotary}>
                                <option value='' selected>
                                    Select Type of Notary
                                </option>

                                {notaryTypes &&
                                    notaryTypes.map((el) => (
                                        <option value={el.value}>
                                            {el.label}
                                        </option>
                                    ))}
                            </select>
                        </div>
                    </div>
                    <div className='form-group row'>
                        <label className='col-xl-3 col-lg-3 col-form-label'>
                            State
                        </label>
                        <div className='col-lg-9 col-xl-6'>
                            <select
                                placeholder='Select state'
                                className='form-control form-control-lg form-control-solid owais'
                                name='state'
                                value={prefstate}
                                onChange={handleChangeState}>
                                <option value='' selected>
                                    Select State
                                </option>
                                {allAdminStates &&
                                    allAdminStates.rows.map((el) => (
                                        <option value={el.state}>
                                            {el.state}
                                        </option>
                                    ))}
                            </select>
                        </div>
                    </div>
                    <span
                        className='description'
                        style={{
                            marginBottom: '30px',
                            display: 'inline-block',
                            fontSize: '12px',
                        }}>
                        Some forms may require state specific notaries, if your
                        form does please select the state from the list above.
                        Otherwise any notary on our platform can assist you no
                        matter their state.
                    </span>
                    {MyNotaries && MyNotaries.count < 1 && (
                        <span className='description' style={{ color: 'red', display: 'inline-block' }}>
                            At this time, you do not have any pre-approved notaries within your account. 
                            If you would like to validate notaries prior to processing your notarizations, return to your dashboard and add them to your My Notaries Area. Otherwise select All CyberizeIt Notaries to move forward now.{' '}
                        </span>
                    )}
                    {auth.user.Role.code == 'ps' ? (
                        <>
                            <Button
                                style={{
                                    backgroundColor: '#1840d2',
                                    borderColor: '#1840d2',
                                    minWidth: '25%',
                                    marginTop: '20px',
                                }}
                                onClick={() => btnHandler(false)}>
                                All CyberizeIt Notaries
                            </Button>{' '}
                            <Button
                                style={{
                                    backgroundColor: '#1840d2',
                                    borderColor: '#1840d2',
                                    minWidth: '25%',
                                    marginTop: '20px',
                                }}
                                onClick={() => btnHandler(true)}>
                                All Approved Notaries
                            </Button>{' '}
                            <Button
                                style={{
                                    backgroundColor: '#1840d2',
                                    borderColor: '#1840d2',
                                    minWidth: '25%',
                                    marginTop: '20px',
                                }}
                                onClick={() => setSelectNotary()}>
                                Select a Notary
                            </Button>
                        </>
                    ) : (
                        <Button
                            style={{
                                backgroundColor: '#1840d2',
                                borderColor: '#1840d2',
                                minWidth: '25%',
                                marginTop: '20px',
                            }}
                            disabled={isButtonClicked ? 'disabled' : ''}
                            onClick={() => btnHandler(false)}>
                            Connect with a Notary
                        </Button>
                    )}
                </Modal.Body>
            </Modal>
            <SelectNotaryModal
              show={selectNotaryModal}
              onHide={setSelectNotaryModal}
              MyNotaries={MyNotaries}
              heading={'Select Notary'}
              bodyText={'Please select a notary from your pre-approved list:'}
              btnTxt={'Notarize It'}
              selectedApprovedNotary={selectedApprovedNotary}
              setSelectedApprovedNotary={setSelectedApprovedNotary}
              btnHandler={btnHandler}
            />
        </>
    )
}
