import React from "react";
import { Modal, Button, Container, Row, Col } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export function Paynow1({ confirm, close }) {
  const heading = "Confirm Your Information & Documentation";
  const body =
    "Once you process this request, the information contained cannot be updated. If you have questions about any of the data or document(s) you have entered. Please cancel and save it until you are sure it is all correct and ready to go to a Notary. The Notaries cannot provide legal advice and cannot Notarize any document that has blank lines. Your document(s) should be completed but UNSIGNED at this point. All document(s) must have every page uploaded. If you fail to upload the entire document package, it is possible the Notary will cancel this transaction.";
  const btnText = "Confirm";
  return (
    <>
      <Modal.Header>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ width: "97%", float: "left", textAlign: "center" }}
        >
          {heading}
        </Modal.Title>
        <p style={{ marginBottom: "-30px" }}>1/2</p>
      </Modal.Header>
      <Modal.Body
        className="justify-content-center"
        style={{ textAlign: "center" }}
      >
        <Container style={{ padding: 0 }}>
          <Row className="pb-2">
            <Col xs={12} md={12}>
              <img
                src={toAbsoluteUrl("/media/own/positive-vote.png")}
                alt="positive vote"
                style={{ width: "100px" }}
              />
            </Col>
          </Row>

          <Row className="pb-2">
            <Col xs={12} md={12}>
              {body}
            </Col>
            <Col xs={12} md={12}>
              <p className="mt-3">
                <span style={{ fontWeight: "500" }}>
                  Refunds will not be provided if the information is entered
                  incorrectly or if the document is not fully submitted.
                </span>
              </p>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button
          style={{
            backgroundColor: "#00c05d",
            borderColor: "#00c05d",
            minWidth: "135px"
          }}
          onClick={() => confirm(true)}
        >
          {btnText}
        </Button>
        <Button
          style={{
            backgroundColor: "#effff4",
            borderColor: "#effff4",
            color: "#00c05d",
            minWidth: "135px"
          }}
          onClick={() => close(false)}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </>
  );
}
