import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { AddClientDialog } from "./AddClientDialog";

export function AddClient() {
  const [isShowDialog, setIsShowDialog] = useState(false);

  return (
    <div className="card-title py-6 text-right">
      <Button variant="primary" onClick={() => setIsShowDialog(true)}>
        Add Client
      </Button>
      <AddClientDialog
        show={isShowDialog}
        onHide={() => setIsShowDialog(false)}
      />
    </div>
  );
}
