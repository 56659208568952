import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useDispatch, useSelector } from "react-redux";
import * as columnFormatters from "./column-formatters";
import { useTableUIContext } from "../TableUIContext";
import * as actions from "../../../_redux/notarization_redux/notarizationActions";
import {
  sortCaret,
  headerSortingClasses
} from "../../../../../_metronic/_helpers";

export function CustomTable() {
  // Customers UI Context
  const customersUIContext = useTableUIContext();
  const customersUIProps = useMemo(() => {
    return {
      openEditCustomerDialog: customersUIContext.openEditCustomerDialog
    };
  }, [customersUIContext]);

  const { auth, notarization, socket } = useSelector(state => state);
  const { customizeTop5Journals, reloadcallData } = notarization;

  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };
  // Customers Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    recallloadfunc();
  }, [dispatch, reloadcallData, socket]);

  const recallloadfunc = () => {
    if (reloadcallData > 0) {
      dispatch(actions.getJournalbyID(reloadcallData, headers));
      dispatch(actions.getJitsiMeetingLinks(reloadcallData, headers))
      
    }

    if (socket.kbaVerificationRejectedNotary != null) {
      dispatch(
        actions.getJournalbyID(
          socket.kbaVerificationRejectedNotary.journalId,
          headers
        )
      );
    }
  };

  // Table columns
  const columns = [
    {
      dataField: "action",
      text: "Action",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditCustomerDialog: customersUIProps.openEditCustomerDialog
      },
      classes: "text-center pr-0",
      headerClasses: "text-center pr-3",
      style: {
        minWidth: "100px"
      }
    },

    {
      dataField: "sno",
      text: "#"
    },
    {
      dataField: "notary_name",
      text: auth.user.Role.code === "notary" ? "CUSTOMER NAME" : "NOTARY NAME"
    },
    {
      dataField: "type",
      text: "TYPE"
    },
    {
      dataField: "scheduledAt",
      text: "START TIME & DATE",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: "endDate",
      text: "END TIME & DATE",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: "noOfSigners",
      text: "NUMBER OF SIGNERS",
      classes: "text-center",
      headerClasses: "text-center"
    },
    {
      dataField: "status",
      text: "Status",
      formatter: columnFormatters.StatusColumnFormatter
    }
  ];

  return (
    <>
      <BootstrapTable
        wrapperClasses="table-responsive"
        bordered={false}
        classes="table table-head-custom table-vertical-center overflow-hidden"
        bootstrap4
        remote
        keyField="id"
        data={customizeTop5Journals === null ? [] : customizeTop5Journals}
        columns={columns}
      >
        {/* <PleaseWaitMessage entities={customizeTop5Journals === null ? [] : customizeTop5Journals} />
        <NoRecordsFoundMessage entities={customizeTop5Journals === null ? [] : customizeTop5Journals} /> */}
      </BootstrapTable>
    </>
  );
}
