import React, { useState } from "react";
import SearchPSNotaryContainer from "../components/seach_notaries/SearchPSNotaryContainer";
import ShowNotification from "../components/new_notarization/ShowNotification";
import { NotaryWaiting } from "../components/modals/NotaryWaiting";

export function MySearchNotaries() {
  const [notification, setNotification] = useState({
    show: false,
    msg: "",
    variant: ""
  });
  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") return;
    setNotification({ show: false, msg: "", variant: "" });
  };

  const setpsSelection = () => {};

  const revealSelection = () => {};

  return (
    <>
      {notification.show && (
        <ShowNotification
          show={notification.show}
          msg={notification.msg}
          variant={notification.variant}
          close={handleCloseNotification}
        />
      )}
      <SearchPSNotaryContainer
        setNotification={setNotification}
        psSelection={setpsSelection}
        revealSelection={revealSelection}
      />
    </>
  );
}
