import React from "react";
import {
  Card,
  CardBody,
  CardHeader
} from "../../../_metronic/_partials/controls";
import { CustomTable } from "./table/CustomTable";
import { NoBankAdded } from "../../zero_state_page/NoBankAdded";
export function TableCard(props) {
  const zerobankDetails = props.zerobankDetails;
  return (
    <>
      {parseInt(zerobankDetails) < 1 ? (
        <NoBankAdded />
      ) : (
        <Card>
          <CardHeader title="Bank Details" className="py-6"></CardHeader>
          <CardBody>
            <CustomTable />
          </CardBody>
        </Card>
      )}
    </>
  );
}
