// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import CircularProgress from "@material-ui/core/CircularProgress";

export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  { updateStatus, openEditCustomerDialog, changeActiveStatusLoading }
) {
  const buttonStyle = {
    minWidth: "90px"
  };

  return (
    <>
      <a
        className="btn btn-light btn-inactive btn-hover-primary m-1"
        onClick={() => openEditCustomerDialog(row.id)}
        style={buttonStyle}
      >
        View
        {/* <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG
            title=" "
            src={toAbsoluteUrl("/media/svg/icons/General/Visible.svg")}
          />
        </span> */}
      </a>
      {row.notaryProfile && row.notaryProfile.isApproved ? (
        <a
          title="Make Inactive"
          className="btn btn-light btn-active btn-hover-primary m-1"
          style={buttonStyle}
          onClick={() => updateStatus(row.id, "inactive")}
        >
          {changeActiveStatusLoading.id === row.id &&
          changeActiveStatusLoading.loading ? (
            <CircularProgress size={15} />
          ) : (
            "Active"
          )}
        </a>
      ) : (
        <a
          title="Make Active"
          className={(row.notaryProfile && !row.notaryProfile.isApproved && !row.notaryProfile.isPending && !row.notaryProfile.isCancelled) ? "btn btn-warning m-1 " : "btn btn-light btn-inactive "+"btn-hover-warning m-1"}
          style={buttonStyle}
          onClick={() => updateStatus(row.id, "active")}
        >
          {changeActiveStatusLoading.id === row.id &&
          changeActiveStatusLoading.loading ? (
            <CircularProgress size={15} />
          ) : (
            "InActive"
          )}
        </a>
      )}
        <a
          title=""
          className={(row.notaryProfile && row.notaryProfile.isPending) ? "btn btn-warning m-1 " : "btn btn-light "+"btn-hover-warning m-1"}
          style={buttonStyle}
          onClick={() => updateStatus(row.id, "pending")}
        >
          {changeActiveStatusLoading.id === row.id &&
          changeActiveStatusLoading.loading ? (
            <CircularProgress size={15} />
          ) : (
            "Pending"
          )}
        </a>
        <a
          title="Cancel"
          className={(row.notaryProfile && row.notaryProfile.isCancelled) ? "btn btn-danger m-1 " : "btn btn-light "+"btn-hover-danger m-1"}
          style={buttonStyle}
          onClick={() => updateStatus(row.id, "cancel")}
        >
          {changeActiveStatusLoading.id === row.id &&
          changeActiveStatusLoading.loading ? (
            <CircularProgress size={15} />
          ) : (
            (row.notaryProfile && row.notaryProfile.isCancelled) ? "Cancelled" : "Cancel"
          )}
        </a>
    </>
  );
}
