import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Paynow1 } from "./modals/Paynow1";
import { Paynow2 } from "./modals/Paynow2";

export function PaynowModals({
  payNowModal,
  setPayNowModal,
  payPlatformFeeHandler,
  platformFee,
  paynow,
  paid,
  noOfSigner,
  auth,
  draftHandler,
  scheduleHandler,
  notarizeNowHandler,
  user,
  selectedNotary,
  confirmjobPostHandler,
  showReNotary,
  singerInfo,
  notarizeNowitHandler,
  KBASettings, 
  isBiometric, 
  isButtonClicked
}) {
  const [confirm, setConfirm] = useState(false);
  return (
    <Modal
      show={payNowModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="payNowModal"
    >
      {confirm ? (
        <Paynow2
          close={setPayNowModal}
          payPlatformFeeHandler={payPlatformFeeHandler}
          platformFee={platformFee}
          payNowModal={true}
          paynow={paynow}
          paid={paid}
          noOfSigner={noOfSigner}
          auth={auth}
          draftHandler={draftHandler}
          scheduleHandler={scheduleHandler}
          notarizeNowHandler={notarizeNowHandler}
          user={user}
          selectedNotary={selectedNotary}
          confirmjobPostHandler={confirmjobPostHandler}
          showReNotary={showReNotary}
          singerInfo={singerInfo}
          KBASettings={KBASettings}
          isBiometric={isBiometric}
          isButtonClicked={isButtonClicked}
          notarizeNowitHandler={notarizeNowitHandler}
        />
      ) : (
        <Paynow1 confirm={setConfirm} close={setPayNowModal} />
      )}
    </Modal>
  );
}
