import React, { useEffect, useState } from "react";
import { TableUIProvider } from "../components/mynotaries_listing/TableUIContext";
import { TableCard } from "../components/mynotaries_listing/TableCard";
import { GenericZeroState } from "../components/zero_state_pages/GenericZeroState";
import ShowNotification from "../components/new_notarization/ShowNotification";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Table } from "react-bootstrap";
import * as actions from "../components/_redux/notarization_redux/notarizationActions";
import { CustomerEditDialog } from "../components/jobboard_listing/edit-dialog/CustomerEditDialog";
import { Filters } from "../components/seach_notaries/card/Filters";

export function MyNotaries() {
  const { auth, notarization } = useSelector(state => state);
  const { filterNotaries, MyNotaries, languages } = notarization;

  const [isload, setisload] = useState(false);
  const [comefrom, setcomefrom] = useState("");
  const [showDialog, setshowDialog] = useState(false);
  const [claimID, setclaimID] = useState(0);
  const [actionModal, setActionModal] = useState(false);
  const [notarizeNowSuccess, setNotarizeNowSuccess] = useState(false);
  const [notification, setNotification] = useState({
    show: false,
    msg: "",
    variant: ""
  });

  const [search, setSearch] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [filterValues, setFilterValues] = useState({
    language: "",
    notaryType: "",
    state: ""
  });
  const [queries, setQueries] = useState({
    q: "",
    lang: "",
    type: "",
    state: "",
    sortOrder: "desc", // asc||desc
    sortField: "id",
    pageNumber: 1,
    pageSize: 10
  });
  const [selectedRowData, setSelectedRowData] = useState(null);

  const dispatch = useDispatch();

  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  const customersUIEvents = {
    openClaimPopup: journalID => {
      setclaimID(journalID);
      setshowDialog(true);
      setcomefrom("");
    },

    removeFromNotary: id => {
      setisload(true);

      dispatch(actions.removeFromNotary(id, headers)).then(data => {
        dispatch(
          actions.fetchMyNotaries(
            {
              q: "",
              lang: "",
              type: "",
              state: "",
              sortOrder: "desc", // asc||desc
              sortField: "id",
              pageNumber: 1,
              pageSize: 10
            },
            headers,
            auth.user.Role.code
          )
        );
      });
    }
  };
  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") return;
    setNotification({ show: false, msg: "", variant: "" });
  };

  const addNotary = () => {
    window.location.href = "#/chooseMyNotaries";
  };

  // Handler Functions
  const handleKeypress = e => {
    if (e.which === 13) applyFilterHandler("search");
  };

  const openClaimPopup = journalID => {
    setclaimID(journalID);
    setActionModal(false);
    setshowDialog(true);
    setcomefrom("journal");
  };

  const hideJob = journalID => {
    setclaimID(0);

    if (comefrom) setActionModal(true);
    else setActionModal(false);

    setshowDialog(false);
  };

  const applyFilterHandler = name => {
    if (name === "search") setQueries({ ...queries, q: search });
    else if (name === "filters") {
      setQueries({
        ...queries,
        lang: filterValues.language,
        type: filterValues.notaryType,
        state: filterValues.state,
      });
    }
    else if (name === "clear") {
      setQueries({ q: "", lang: "", type: "", state: "", sortOrder: "desc", sortField: "id", pageNumber: 1, pageSize: 10 });
      setSearch("");
      setFilterValues({ language: "", notaryType: "", state: "" });
      setShowFilter(false);
    } else setQueries({ ...queries, lang: "", type: "", state: "" });
  };

  useEffect(() => {
    dispatch(
      actions.fetchMyNotaries(
        queries,
        headers,
        auth.user.Role.code
      )
    );
  }, [queries]);

  return (
    <>
      {notification.show && (
        <ShowNotification
          show={notification.show}
          msg={notification.msg}
          variant={notification.variant}
          close={handleCloseNotification}
        />
      )}
      {MyNotaries !== null && MyNotaries.count > 0 ? (
        <>
          <Filters
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            filterValues={filterValues}
            setFilterValues={setFilterValues}
            applyFilterHandler={applyFilterHandler}
            languages={languages}
          />
          <TableUIProvider customersUIEvents={customersUIEvents}>
            <CustomerEditDialog
              show={actionModal}
              openClaimPopup={openClaimPopup}
              onHide={() => setActionModal(false)}
              data={selectedRowData}
            />
            <TableCard
              setSearch={setSearch}
              handleKeypress={handleKeypress}
              search={search}
              applyFilterHandler={applyFilterHandler}
            />
          </TableUIProvider>
        </>
      ) : (
        <>
          <Filters
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            filterValues={filterValues}
            setFilterValues={setFilterValues}
            applyFilterHandler={applyFilterHandler}
          />
          <GenericZeroState
            status={MyNotaries}
            imageURL="/media/own/no-clients.png"
            heading=""
            body="Once you add a Notary, It will be listed here."
            btnTxt="Add Notary"
            btnHandler={addNotary}
          />
        </>
      )}
    </>
  );
}
