import React, { useState } from "react";
// import { TableUIWitnessingContext } from "../components/clients_listing/TableUIWitnessingContext";
import { TableNoNotaryUserFoundCard } from "../components/zero_state/TableNoNotaryUserFoundCard";
// import { NewNotarization } from "../pages/NewNotarization";
// import { useDispatch, useSelector } from "react-redux";
// import * as actions from "../components/_redux/notarization_redux/notarizationActions";

export function NoNotaryUserFound() {
  return <TableNoNotaryUserFoundCard />;
}
