import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../../_metronic/_partials/controls";
import { CustomTable } from "./table/CustomTable";
import { CSVLink } from "react-csv";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/notarization_redux/notarizationActions";
import DownlaodIcon from '../../../media/zeroState/downloadicon.webp'
import CircularProgress from "@material-ui/core/CircularProgress";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { GenericZeroState } from "../../zero_state_pages/GenericZeroState";

export function TableCard(props) {
  const MIDDLEWARE_URL = process.env.REACT_APP_MIDDLEWARE_URL;
  const { auth, notarization } = useSelector(state => state);
  const { allJournals, allCSVJournals, paymentDetails, clients, MyNotaries} = notarization;
 
  const useStyles = makeStyles(() => ({
    cstm_padding: {
      paddingLeft: "30px",
      paddingRight: "30px",
      marginTop: "30px",
      marginBottom: "1rem"
    },
    cstm_field: {
      width: "100%",
      marginTop: 0
    }
  }));
  const classes = useStyles();

  const [statusfilter, setstatusfilter] = useState("");
  const [search, setSearch] = useState(false);
  const [searchClicked, setSearchClicked] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [checkerID, setcheckerID] = useState([]);
  const [pdfModal, setPdfModal] = useState(false);
  const [showDialog, setshowDialog] = useState(false);
  const [csvData, setCsvData] = useState([
    [
      "Customer Name",
      "Notary",
      "startDate",
      "endDate",
      "Type of Notarization",
      "Price of Notarization",
      "Number of Seals (Stamps)",
      "scheduledAt",
      "Number of Signers",
      "status",
      "Documents",
      "Signer 1",
      "Signer 2",
      "Signer 3",
      "Signer 4",
      "Witnesses"
    ]
  ]);

  function GetFinalPayment(seals, witnessCount) {
    let total =
      seals === 0
        ? 0
        : paymentDetails?.sealFirstFee +
          (seals - 1) * paymentDetails?.sealSecFee;
    let notaryFee =
      seals === 0
        ? 0
        : paymentDetails?.notaryFeePerFirstSeal +
          (seals - 1) * paymentDetails?.notaryFeePerSecSeal;
    let platformFee =
      seals === 0
        ? 0
        : paymentDetails?.platformPerFirstSeal +
          (seals - 1) * paymentDetails?.platformPerSecSeal;
    let witnessFee =
      witnessCount === 0 ? 0 : witnessCount * paymentDetails?.witnessFee;
    total += witnessFee;

    return {
      total: total,
      notaryFee: notaryFee,
      platformFee: platformFee,
      witnessFee: witnessFee
    };
  }

  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };
  const dispatch = useDispatch();
  const deleteNotarization = () => {
    let checkerarr = [];
    var x = document.querySelectorAll(".statuschecker:checked:not(:disabled)");
    var i;
    for (i = 0; i < x.length; i++) {
      checkerarr.push(x[i].value);
    }

    setcheckerID(checkerarr);

    dispatch(actions.deleteSoftNotarization(checkerarr, headers)).then(
      response => {
        console.log("response", response);
      }
    );
  };

  const showNotarizationPopup = (isdeleteID = 0) => {
    if (isdeleteID > 0) {
      document.getElementById("check" + isdeleteID).checked = true;
      setshowDialog(true);
    }

    if ( document.querySelectorAll(".statuschecker:checked:not(:disabled)").length > 0 ) {
      setshowDialog(true);
    }
  }
  
  const hideNotarizationPopup = () => {
    setshowDialog(false);
  };

  const getTimeZone = ( date ) => {
    if( !date ) {
      date = new Date();
    }

    let timeString = date.toTimeString();
    let [time , ...timeZone] = timeString.split(" ")
    
    timeZone = timeZone.join(" ")
    
    return timeZone;
  }

  const sendNotarizationPdf = () => {

    let currentTimeZone = getTimeZone();

    let body = {
      notaryId: auth.user.id,
      timeZone: currentTimeZone
    }

    dispatch(actions.sendNotarizationPdf(body, headers))
    .then(()=>{
      setPdfModal(true);  
    })
  }

  const getPagedNotaries = (pageNumber) => {
    let filters = props.mainfilter;
    
    // let queryParams = {};
 
    // if(filters.to) queryParams.toDate = filters.to;

    // if(filters.from) queryParams.fromDate = filters.from;

    // if(filters.name)  queryParams.name = filters.search;

    // if(filters.customer) queryParams.customer = filters.customer;

    // if(filters.status) queryParams.status = filters.status;

    return new Promise((resolve, reject) => {
      dispatch(
        actions.fetchJournals(
          {
            sortOrder: "desc",
            sortField: "id",
            pageNumber: 1,
            pageSize: 1000
          },
          headers,
          auth.user.Role.code,
          true,
          filters
        )
      ).then(data => {
        resolve(data.rows)
      });
    })
  }

  const exporttocsvready = () => {
    if (allJournals && allJournals.count !== 0) {
      setIsLoading(true);
      let count = Math.ceil(allJournals.count / 1000)
      const promises = []
      for (let index = 1; index <= count; index++) {
        promises.push(getPagedNotaries(index))
      }
      Promise.all(promises).then((data) => {
        const csvData = formatCsvData(data.flat(1));
        setCsvData(csvData)
        setIsLoading(false);
      })
    }
  };

  const formatCsvData = (csvData) => {

    let formatedCsvData = [[ "Customer Name", "Notary", "startDate", "endDate", "Type of Notarization", "Price of Notarization", "Number of Seals (Stamps)", "scheduledAt", "Number of Signers", "status", "Documents", "Signer 1", "Signer 2", "Signer 3", "Signer 4", "Witnesses" ]];
    const options = { year: "numeric", month: "long", day: "numeric" };

    if (csvData.length >= 1) {

      csvData.forEach(journals => {

        let startdate = (journals.scheduledAt) ? new Date(journals.scheduledAt).toLocaleDateString(undefined, options) : "";
        let endDate = (journals.endDate) ? new Date(journals.endDate).toLocaleDateString(undefined, options) : "";
        let typeofnotarization = journals.type ? journals.type : "";

        let paymentBreakDown = 0;
        let filteredWitnesses = journals.Witnesses.length > 0 ? journals.Witnesses.filter(el => el.status !== "rejected"): [];

        let totalPrice = "--";
        if (journals?.Payments?.length > 0) {
          totalPrice = 0;
          totalPrice += (journals?.Payments[0]?.amount/100);
          totalPrice += paymentBreakDown;
          totalPrice = "$" + totalPrice.toFixed(2);
        }
        
        let journalinfo = [];
        for (let icount = 0; icount < journals.journalDocs.length; icount++ ) {
          if (journals.journalDocs[icount].File.filename) {
            journalinfo.push(journals.journalDocs[icount].File.baseUrl + "/" + journals.journalDocs[icount].File.path);
          }
        }

        let notaryDetails = journals?.notary ? journals.notary.firstName + " " + journals.notary.lastName + "  " + journals.notary.email + "  " + journals.notary.phone:  ""
        let tmpdata = [
          journals?.customer?.firstName + " " + journals?.customer?.lastName,
          notaryDetails,
          startdate,
          endDate,
          typeofnotarization,
          totalPrice,
          journals.sealCount,
          startdate,
          journals.Signers.length,
          journals.status,
          journalinfo.join(" - "),
        ];

        if(journals?.Signers) {
          for (let icount = 0; icount < 4; icount++) {
            let signerinfo = "-";
            if (journals.Signers[icount]) {
              signerinfo = journals.Signers[icount].firstName + " " + journals.Signers[icount].lastName + " " + journals.Signers[icount].email +
                " \r\n" + journals.Signers[icount].address + " " + journals.Signers[icount].city + " " +
                journals.Signers[icount].state + " - " + journals.Signers[icount].zip;

              if (journals.Signers[icount].IP)
                signerinfo += " - " + journals.Signers[icount].IP;

              if (journals.Signers[icount].bypassReason)
                signerinfo += " - " + journals.Signers[icount].bypassReason;

              if (!journals.Signers[icount].bypassKBA)
                signerinfo += " - " + MIDDLEWARE_URL;
            }
            tmpdata.push(signerinfo);
          }
        }

        let witnessinfo = [];
        if(journals?.Witnesses && journals?.Witnesses.length) {
          for ( let icount = 0; icount < journals.Witnesses.length; icount++ ) {
            if (journals.Witnesses[icount].User) {
              let signerinfo = journals.Witnesses[icount].User.firstName + " " + journals.Witnesses[icount].User.lastName + " " + journals.Witnesses[icount].User.email;
              if (journals.Witnesses[icount].IP)
                signerinfo += " " + journals.Witnesses[icount].IP;

              witnessinfo.push(signerinfo);
            }
          }
        }
        tmpdata.push(witnessinfo.join(" "));

        formatedCsvData.push(tmpdata);
      })
    }
    return formatedCsvData;
  }

  const setingclientfilter = text => {
    let mainfilter = [];
    let mainexcelfilter = [];
    mainfilter = props.mainfilter;
    mainexcelfilter = props.mainexcelfilter;
    if (text) {
      mainfilter['customer'] = text
      mainexcelfilter.include[6].id = { value: text };

      props.setclient(text);
    } else {
      if (mainfilter['customer']){
        delete mainfilter['customer'];
      }

      props.setclient(text);
    }

    props.setmainfilter(mainfilter);
  };

  const settingstatusfilter = text => {
    let mainfilter = [];
    let mainexcelfilter = [];
    mainfilter = props.mainfilter;
    mainexcelfilter = props.mainexcelfilter;
    if (text) {
      mainfilter['status'] = text
      mainexcelfilter.status = { value: [text], matchType: "in" };
      props.setjournalstatus(text);
    } else {
      if (mainfilter['status']) {
        delete mainfilter['status'];
        delete mainexcelfilter.status;
      }
      props.setjournalstatus(text);
    }

    props.setmainfilter(mainfilter);
  };

  const settingaFilter = (text, mode) => {
    let mainfilter = [];
    let mainexcelfilter = [];
    if (text) {
      if ("firstName" == mode) props.setnotaryName(text);

      mainfilter = props.mainfilter;
      mainexcelfilter = props.mainexcelfilter;

      mainfilter['name'] = text
      mainexcelfilter.include[4].or = {
        values: [
          { value: text, name: "firstName" },
          { value: text, name: "email" },
          { value: text, name: "lastName" }
        ]
      };
    } else {
      mainfilter = props.mainfilter;
      mainexcelfilter = props.mainexcelfilter;

      if (
        mainfilter['name']
      )
        delete mainfilter['name']

      if (
        mainexcelfilter.include &&
        mainexcelfilter.include[4] &&
        mainexcelfilter.include[4].or &&
        mainexcelfilter.include[4].or["values"]
      )
        delete mainexcelfilter.include[4].or;

      props.setnotaryName("");
    }
    props.setmainfilter(mainfilter);
  };


  const applyFilterHandler = name => {
    // setCsvData([
    //   [
    //     "Customer Name",
    //     "Notary",
    //     "startDate",
    //     "endDate",
    //     "Type of Notarization",
    //     "Price of Notarization",
    //     "Number of Seals (Stamps)",
    //     "scheduledAt",
    //     "Number of Signers",
    //     "status",
    //     "Documents",
    //     "Signer 1",
    //     "Signer 2",
    //     "Signer 3",
    //     "Signer 4",
    //     "Witnesses"
    //   ]
    // ]);

    if (name === "search") {
      setSearch(!search);
      setSearchClicked(true);
      props.applyFilter();
    } else if (name === "clear") {
      setCsvData([
        [
          "Customer Name",
          "Notary",
          "startDate",
          "endDate",
          "Type of Notarization",
          "Price of Notarization",
          "Number of Seals (Stamps)",
          "scheduledAt",
          "Number of Signers",
          "status",
          "Documents",
          "Signer 1",
          "Signer 2",
          "Signer 3",
          "Signer 4",
          "Witnesses"
        ]
      ]);
      settingstatusfilter("");
      setingclientfilter("");
      settingaFilter("", "firstName");
      props.setfromDate("");
      props.settoDate("");
      setstatusfilter("");
      setSearch(!search);

      props.mainfilter['from'] = "";
      props.mainfilter['to'] = "";

      props.applyFilter();

      

    }
  };


  const handleChangeFromDate = e => {
    let mainfilter = [];
    mainfilter = props.mainfilter;

    if (e.target.value) {
      let datechange = new Date(e.target.value);
      let datestring = datechange.toISOString();

        mainfilter['from'] = datestring;
    } else {
      checkanddeletedate("from");
    }

    props.setfromDate(e.target.value);

    props.setmainfilter(mainfilter);
  };

  const handleChangeToDate = e => {
    let mainfilter = [];
    mainfilter = props.mainfilter;

    if (e.target.value) {
      let datechange = new Date(e.target.value);
      datechange.setHours(23, 59, 59);
      let datestring = datechange.toISOString();
      
      mainfilter['to'] = datestring;
      
    } else {
      checkanddeletedate("to");
    }

    props.settoDate(e.target.value);
    props.setmainfilter(mainfilter);
  };

  const checkanddeletedate = mode => {
    let mainfilter = [];
    mainfilter = props.mainfilter;

    if (mainfilter) {
      if (mainfilter[mode]) {
        delete mainfilter[mode];
      } else {
        delete mainfilter["from"];
        delete mainfilter["to"];
      }
    }
  };

  return (
    <>
       <Card>
        <CardHeader className="py-6">
          <CardHeaderToolbar style={{ minWidth: "570px" }}>

          {(auth.user.Role.code === "ps" || auth.user.Role.code === "user") && (   
            
              <div style={{ width: "80%", marginRight: "10px" }}>
                <Form.Control
                  type="text"
                  className="mr-2"
                  placeholder="Search notary by name"
                  value={props.notaryName}
                  onChange={e => settingaFilter(e.target.value, "firstName")}
                  style={{ width: "100%" }}
                />
              </div>
            )}
            {auth.user.Role.code === "notary" && (
              <div style={{ width: "80%", marginRight: "10px" }}>
                <Form.Control
                  type="text"
                  className="mr-2"
                  placeholder="Search client by name"
                  value={props.notaryName}
                  onChange={e => settingaFilter(e.target.value, "firstName")}
                  style={{ width: "100%" }}
                />
              </div>

            )}
              

            <div style={{ width: "80%", marginRight: "10px" }}>
              <TextField
                type="date"
                required
                label="From Date"
                value={props.fromDate}
                onChange={handleChangeFromDate}
                margin="dense"
                variant="outlined"
                className={classes.cstm_field}
                inputProps={{
                  max: new Date().toISOString().split("T")[0]
                }}
                color="secondary"
                InputLabelProps={{
                  shrink: true
                }}
                style={{ width: "100%", marginBottom: "10px" }}
              />
              <TextField
                type="date"
                required
                label="To Date"
                value={props.toDate}
                onChange={handleChangeToDate}
                margin="dense"
                variant="outlined"
                className={classes.cstm_field}
                inputProps={{
                  max: new Date().toISOString().split("T")[0]
                }}
                color="secondary"
                InputLabelProps={{
                  shrink: true
                }}
                style={{ width: "100%" }}
              />
            </div>

            
             

              <div style={{ width: "80%", marginRight: "10px" }}>
                {(auth.user.Role.code === "notary" || auth.user.Role.code === "ps") && (
                <Form.Group
                  className="mb-0 mr-2"
                  style={{ width: "100%", marginBottom: "10px" }}
                >
                  <Form.Control
                    as="select"
                    name="client"
                    onChange={e => setingclientfilter(e.target.value)}
                    value={props.client}
                    style={{ width: "100%", marginBottom: "10px" }}
                  >
                    {auth.user.Role.code === "notary" && (
                      <>
                        <option value="">All Clients</option>
                        {clients &&
                        clients.rows &&
                        clients.rows.map(el => (
                          <>
                            {el.roleId != 4 ? (
                              <option value={el.id}>
                                {el.firstName} {el.lastName}
                              </option>
                            ) : (
                              ""
                            )}
                          </>
                        ))}
                      </>
                      
                    )}

                    {auth.user.Role.code === "ps" && (
                      <>
                        <option value="">All Notaries</option>
                        {MyNotaries &&
                        MyNotaries.rows &&
                        MyNotaries.rows.map(el => (
                          <>
                            {el.roleId != 4 ? (
                              <option value={el.id}>
                                {el.firstName} {el.lastName}
                              </option>
                            ) : (
                              ""
                            )}
                          </>
                        ))}
                      </>
                      
                    )}
                  </Form.Control>
                </Form.Group>
                )}

                <Form.Group
                  className="mb-0 mr-2"
                  style={{ width: "100%", marginBottom: "10px" }}
                >
                  <Form.Control
                    as="select"
                    name="status"
                    onChange={e => settingstatusfilter(e.target.value)}
                    value={props.journalstatus}
                  >
                    <option value="">All Statuses</option>
                    <option value="draft">Draft</option>
                    <option value="Progress">In Progress</option>
                    <option value="Scheduled">Scheduled</option>
                    <option value="rescheduled">Re Scheduled</option>
                    <option value="scheduled-unclaimed">
                      Scheduled Unclaimed
                    </option>
                    <option value="failed">Failed</option>
                    <option value="completed">Completed</option>
                  </Form.Control>
                </Form.Group>
              </div>
            
            

            <button
              type="button"
              className="btn btn-primary mr-3"
              onClick={e => applyFilterHandler("search")}
            >
              Search
            </button>
            <button
              type="button"
              className="btn btn-primary mr-3"
              onClick={e => applyFilterHandler("clear")}
            >
              Clear
            </button>
          </CardHeaderToolbar>
        </CardHeader>
      </Card>

      {
      allJournals !== null && allJournals.rows.length > 0 ?
        <Card>
          <CardHeader title="Journals" className="py-6">
            <CardHeaderToolbar>
              {
                (auth.user.Role.code === "notary") &&
                ( 
                  <>
                    <button
                      type='button'
                      className='btn downloadcsvbtn'
                      onClick={() => sendNotarizationPdf()}
                      style={{
                          backgroundColor: '#f7f7f7',
                          color: '#4abd54',
                          marginRight: '10px',
                      }}
                    >
                      <img src={DownlaodIcon} className='downlaodIcon' alt='' />
                      Generate PDF
                    </button>
                    <Modal
                      show={pdfModal}
                      size="lg"
                      aria-labelledby="contained-modal-title-vcenter"
                    >
                      <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                          Attention
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <span style={{fontSize:15}}>
                          Your journal is being processed.  Once completed a link will be sent to your email
                        </span>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button 
                          onClick={() => setPdfModal(false)}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </>
                )
              }
              {(auth.user.Role.code === "ps" || auth.user.Role.code === "notary") && (
                <>
                  {
                    isLoading ? (
                      <Button
                        variant={"success"}
                        className="mr-3"
                        disabled
                        style={{width:150}}
                      >
                        <CircularProgress size={15} />
                      </Button>
                    ) : (
                      csvData.length < 2 && allJournals ?
                        (
                          <Button
                            variant={"success"}
                            onClick={exporttocsvready}
                            className="mr-3"
                            style={{width:150}}
                          >
                            Generate CSV
                          </Button>
                        )
                        :
                        (
                          <CSVLink 
                            data={csvData}
                            filename="Notaries.csv"
                            style={{width:150}}
                            >
                            <Button
                              className="mr-3"
                              variant={"success"}
                              style={{width: "max-content"}}
                            >
                              {/* <img src={DownlaodIcon} className="downlaodIcon" alt="" /> */}
                              Download CSV
                            </Button>
                          </CSVLink>
                        )
                    )
                  }
                </>
              )}
              {
                ( auth.user.Role.code !== "notary" || (auth.user.Role.code === "notary" && !auth.user.notaryProfile.isCancelled ) ) &&
                <button
                  type="button"
                  className="btn btn-danger delnotarizationbtn"
                  style={{ marginRight: "10px" }}
                  onClick={showNotarizationPopup}
                >
                  Delete
                </button>
              }
              {auth.user.Role.code === "ps" ? (
                <Link to="/new-notarizationPS" style={{ color: "white" }}>
                  <button
                    type="button"
                    className="btn btn-primary newnotarizationbtn"
                  >
                    New Notarization
                  </button>
                </Link>
              ) : (
                ( auth.user.Role.code !== "notary" || (auth.user.Role.code === "notary" && !auth.user.notaryProfile.isCancelled ) ) &&
                <Link to="/new-notarization" style={{ color: "white" }}>
                  <button
                    type="button"
                    className="btn btn-primary newnotarizationbtn"
                  >
                    New Notarization
                  </button>
                </Link>
              )}
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
            <CustomTable 
              setmainfilter={props.setmainfilter}
              mainfilter={props.mainfilter}
              statusfilter={statusfilter}
              search={search}
              applyFilterHandler={applyFilterHandler}
              searchClicked={searchClicked}
              setSearchClicked={setSearchClicked}
              showNotarizationPopup={showNotarizationPopup} 
            />
            <Modal
              size="lg"
              show={showDialog}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Body className="overlay overlay-block cursor-default">
                <h1>Confirmation</h1>
                <div className="modal-body-content">
                  Are you sure you want to delete this notarization?{" "}
                </div>
                <div className="modal-body">
                  <Button
                    variant="secondary"
                    className="acceptBtn"
                    style={{ marginTop: "20px", float: "right" }}
                    onClick={() => deleteNotarization()}
                  >
                    Submit
                  </Button>

                  <Button
                    variant="secondary"
                    className="cancelBtn"
                    style={{
                      marginTop: "20px",
                      marginRight: "10px",
                      float: "right"
                    }}
                    onClick={() => hideNotarizationPopup()}
                  >
                    Cancel
                  </Button>
                </div>
              </Modal.Body>
            </Modal>
          </CardBody>
        </Card>
      : (
        <GenericZeroState
          status={allJournals}
          imageURL="/media/own/notarization.png"
          heading=""
          body="Once you initiate a Notarization, it will be listed here."
          btnTxt="+ New Notarization"
          btnHandler={props.addNewHandler}
        />
      )}
    </>
  );
}
