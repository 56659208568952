import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CustomCardForAdobeSign } from "./CustomCardForAdobeSign";
import { NotaryNotices } from "./NotaryNotices";
import { CompleteNotarizationFlow } from "./CompleteNotarizationFlow";
import { ViewTemplateAgreement } from "./ViewTemplateAgreement";
import { ContactSupport } from "./ContactSupport";
import { JaaSMeeting } from "@jitsi/react-sdk";
import { CookieEnable } from "./CookieEnable";
import { SignStatus } from "./SignStatus";
import Button from "@material-ui/core/Button";
import { CancelNotarization } from "./CancelNotarization";
import * as actions from "../../_redux/notarization_redux/notarizationActions";
import * as socketactions from "../../../socket/_redux/socket_redux/socketActions";
import ShowNotification from "../../../components/new_notarization/ShowNotification";
import CircularProgress from "@material-ui/core/CircularProgress";
// import  PdfViewer  from "../../pdfView/PdfViewer";
export function CustomeAdobeSignIframTable(props) {
  const dispatch = useDispatch();
  const { auth, notarization, socket } = useSelector(state => state);
  const [istempurl, setistempurl] = useState(false);
  const [isMeetingStarted, setisMeetingStarted] = useState(false);
  const [currtempurl, setcurrtempurl] = useState("adobe");
  const {
    witnesses,
    adobeURL,
    jitsiMeetingJWT,
    jitsiAppID, 
    jitsiRoomName,
    allDocuments,
    templateURLinsecure,
    templateURL,
    templateAgreementURL,
    getAllJournal,
    templateURLAgreeURL
  } = notarization;
  const [count, setCount] = useState(0);
  const [adobeCredentialsBit, setadobeCredentialsBit] = useState(false);
  const [donecount, setdonecount] = useState(false);
  const [doneagreement, setdoneagreement] = useState(false);

  const [isdonewitness, setisdonewitness] = useState(false);
  const [isShowNextButton, setisShowNextButton] = useState(false);
  

  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };
  const [journalID, setjournalID] = useState(
    props.journalID ? props.journalID : 0
  );

  const [rejoinMeeting, setRejoinMeeting] = useState(false);
  //    const files = ((allDocuments[count]) && (allDocuments[count].File)) ? (allDocuments[count].File.baseUrl + allDocuments[count].File.path) : ((allDocuments[0]) ? (allDocuments[0].File.baseUrl + allDocuments[0].File.path) : "");
  const [loading, setLoading] = useState(false);
  const [iscomplete, setiscomplete] = useState(false);
  const [notification, setNotification] = useState({
    show: false,
    msg: "",
    variant: ""
  });
  const allextensions = ["jpg", "jpeg", "png", "pdf"];
  const [files, setfiles] = useState(
    allDocuments[count] && allDocuments[count].File
      ? allDocuments[count].File.baseUrl + "/" + allDocuments[count].File.path
      : allDocuments[0]
      ? allDocuments[0].File.baseUrl + "/" + allDocuments[0].File.path
      : ""
  );

  const renderSpinner = () => (
    <div style = {{
        fontFamily: 'sans-serif',
        textAlign: 'center'
    }}>
      <img
        src="/media/misc/inprogress.gif"
        class="img-witness"
      />
    </div>
);
  
  const tweetersIframe = adobeURL
    ? '<iframe  src="' +
      adobeURL +
      '" style="width:100%; height:800px" frameborder="0"  class="handlerIframe" />'
    : "";
  const tweetersIframe2 =
    adobeURL && templateURLAgreeURL
      ? '<iframe  src="' +
        templateURLAgreeURL +
        '" style="width:100%; height:800px" frameborder="0"  class="handlerIframe" />'
      : "";
  const [pdurls, setpdurls] = useState(
    !adobeURL
      ? '<iframe src="https://view.officeapps.live.com/op/embed.aspx?src=' +
          encodeURI(files) +
          '"   onload="iframeloaded(1)" style="width:80%; height:600px; " frameborder="0" />'
      : ""
  );
  //const pdurls = (!adobeURL) ?  <object data={files}  width="100%" height="600px"><p>Loading File...</p></object>:"";

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };
  const addCountHandler = () => {
    setCount(count + 1);

    if (!allDocuments[count + 1]) setCount(0);
  };
  const removeCountHandler = () => {
    if (count === 0) {
      return;
    }
    setCount(count - 1);
  };

  const getfileextension = filename => {
    let filebreak = filename.split(".");
    let extension = filebreak[filebreak.length - 1];
    return extension.toLowerCase();
  };

  const doneWitness = async () => {
   
    setisdonewitness(true);
    await dispatch(
      actions.doneWitness(
        headers,
        journalID,
        setNotification,
        enableLoading,
        disableLoading
      )
    ).then(response => {
      dispatch(actions.checkJournal(journalID, headers, true, true));

    });
    
  };

  

  const doneWitnesssure = async () => {
    setisdonewitness(true);
    let checkadobe = await dispatch(
      actions.doneWitness(
        headers,
        journalID,
        setNotification,
        enableLoading,
        disableLoading
      )
    ).then(response => {
      setTimeout(function() {
        window.location.reload();
      }, 3000);
    });
   
  };

 

  const completeNotarization = () => {
    dispatch(
      socketactions.completeNotarization(journalID, allDocuments.length)
    );
  };

  const changeMeetingStatus = (status) => {
    if(status === "end"){
      setisMeetingStarted(false);
    }
    if(status === "start"){
      sendEmailInvite();
      setisMeetingStarted(true);
      setRejoinMeeting(true);
    }
  }

  const sendEmailInvite = async () => {
    if(!rejoinMeeting){
      await dispatch(actions.emailMeetingLink(headers, journalID));
    }
    

  };
  useEffect(() => {
    if (!donecount && allDocuments.length) {
      let filecall =
        allDocuments[count].File.baseUrl + "/" + allDocuments[count].File.path;
      let getextension = getfileextension(filecall);

      if (allextensions.indexOf(getextension) > -1)
        setpdurls(
          !adobeURL ? (
            <div className="iframeHandler">
              <object data={filecall} width="100%" height="600px">
                <p>Loading File...</p>
              </object>
            </div>
          ) : (
            ""
          )
        );
      else
        setpdurls(
          !adobeURL ? (
            <div
              className="iframedocHandler"
              dangerouslySetInnerHTML={{
                __html:
                  '<iframe src="https://view.officeapps.live.com/op/embed.aspx?src=' +
                  encodeURIComponent(filecall) +
                  '"   onload="iframeloaded(1)" style="width:80%; height:600px; " frameborder="0" />'
              }}
            ></div>
          ) : (
            ""
          )
        );

      setdonecount(true);
    }

    if (templateURLinsecure) {
      setTimeout(() => {
        window.open(templateURLinsecure)
      })
    };

    if (socket.adobeCredentials != null && !adobeCredentialsBit) {
      //authactions

      let usercont = auth.user;
      usercont.notaryEmail.refreshToken = socket.adobeCredentials.refreshToken;

      doneWitnesssure();
    }

    if (!adobeURL) {
      if (
        auth &&
        auth.user &&
        auth.user.notaryEmail &&
        auth.user.notaryEmail.refreshToken &&
        !istempurl
      ) {
        dispatch(actions.getTemplateURL(auth.user.id, headers));
        setistempurl(true);
      } else if (localStorage.getItem("adobeCredentials") && !istempurl) {
        let adobeCredentials = localStorage.getItem("adobeCredentials");
        let adobeCredentialsarr = adobeCredentials.split("|");

        if (auth.user.id == adobeCredentialsarr[0]) {
          dispatch(actions.getTemplateURL(auth.user.id, headers));
          setistempurl(true);
        }
      }
    }
  }, [
    count,
    templateURLinsecure,
    templateURL,
    socket,
    templateAgreementURL,
    adobeURL,
    getAllJournal
  ]);

  const viewAgreement = () => {
    if (
      adobeURL &&
      currtempurl != "template" &&
      !doneagreement &&
      getAllJournal &&
      getAllJournal.journal
    ) {
      dispatch(
        actions.getTemplateAgreemURL(
          auth.user.id,
          headers,
          getAllJournal.journal.agreementId
        )
      );
      //setdoneagreement(true);
      setcurrtempurl("template");
    } else if (
      adobeURL &&
      currtempurl != "adobe" &&
      getAllJournal &&
      getAllJournal.journal
    ) {
      setcurrtempurl("adobe");
    } else if (
      adobeURL &&
      currtempurl != "template" &&
      doneagreement &&
      getAllJournal &&
      getAllJournal.journal
    ) {
      setcurrtempurl("template");
    }
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") return;
    setNotification({ show: false, msg: "", variant: "" });
  };
  //<div dangerouslySetInnerHTML={{ __html: pdurls }}></div>
  return (
    <>
      {notification.show && (
        <ShowNotification
          show={notification.show}
          msg={notification.msg}
          variant={notification.variant}
          close={handleCloseNotification}
        />
      )}
      <div style={adobeURL ? {padding: '0px 0px'} : {}}  class={adobeURL ? "container" : "container siginwitnessright"}>
        <div class="row">
          {adobeURL ? (
            ""
          ) : (
          <div class="col-sm adobep-1" style={{ display: "inline-block" }}>
           
              <NotaryNotices />
          
          </div>
          )}
          
          {adobeURL ? (
            <div class="col-sm adobep-1" style={{ display: "inline-block" }}>
              <CancelNotarization
                journalID={props.journalID}
                alreadyOnDashboard={false}
                showCard={true}
              />
            </div>
          ) : (
            ""
          )}
          {/*(!adobeURL) ? <div class="col-sm">
						{(allDocuments.length > 0) ? <UplaodDocuments journalID={props.journalID} allDocuments={allDocuments} /> : ""}
					</div>:""*/}
          {!adobeURL ? (
            <div class="col-sm adobep-1" style={{ display: "inline-block" }}>
              <CustomCardForAdobeSign journalID={props.journalID} />
            </div>
          ) : (
            ""
          )}

          {adobeURL ? (
            <div class="col-sm adobep-1" style={{ display: "inline-block" }}>
              <SignStatus journalID={props.journalID} />
            </div>
          ) : (
            ""
          )}
          {loading && <span className="ml-3 spinner spinner-white"></span>}

          {!adobeURL ? (
            <div class="pb-2">
              <span class="siginwitnessrightbuttn">
                <CancelNotarization
                  journalID={props.journalID}
                  alreadyOnDashboard={false}
                  showCard={false}
                />
              </span>
            </div>
          ) : (
            ""
          )}

          {!getAllJournal.journal.witnessCount && !adobeURL ? (
            <span class="siginwitnessrightbuttn">
              <Button
                disabled={isdonewitness}
                variant="contained"
                style={{ backgroundColor: "#2d3fd3", color: "white" }}
                onClick={doneWitness}
              >
                {!isdonewitness ? (
                  "Next"
                ) : (
                  <CircularProgress classes={"colorPrimary"} size={15} />
                )}
                {loading && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </Button>
            </span>
          ) : (
            ""
          )}
          {adobeURL ? (
            <div class="col-sm  adobep-1" style={{ display: "inline-block" }}>
              {" "}
              <ViewTemplateAgreement
                currtempurl={currtempurl}
                journalID={props.journalID}
                viewAgreement={viewAgreement}
              />{" "}
            </div>
          ) : (
            ""
          )}
          {adobeURL ? (
            <div class="col-sm adobep-1" style={{ display: "inline-block" }}>
              {" "}
              <ContactSupport />{" "}
            </div>
          ) : (
            ""
          )}
          {adobeURL ? (
            <div class="col-sm adobep-1" style={{ display: "inline-block" }}>
              {" "}
              <CompleteNotarizationFlow
                journalID={props.journalID}
                completeNotarization={completeNotarization}
              />{" "}
            </div>
          ) : (
            ""
          )}
        </div>

        {adobeURL ? (
        
        <div class="row" id="jitsiMeetingDiv">
          <div class="" style={{top: "212px", width: "100%", height: "100%" }} >
            
            <>
              {isMeetingStarted ? (
                <JaaSMeeting
                  appId = {jitsiAppID}
                  roomName = {jitsiRoomName}
                  jwt ={jitsiMeetingJWT}
                  spinner = { renderSpinner }
                  getIFrameRef = { node => node.style.height = '410px' }
                  onApiReady = { (externalApi) => {
                    var self = externalApi;
                    externalApi.executeCommand('setFollowMe', true);

                    externalApi.addListener('tileViewChanged', function(tileview){
                      if(!tileview.enabled){
                        self.executeCommand('setTileView',
                        {enabled: true}
                      )}
                      
                    })

                    externalApi.addListener('readyToClose', function(){
                      changeMeetingStatus("end");
                    })
                    externalApi.addListener('moderationStatusChanged', function(){
                      self.executeCommand('setTileView',
                        {enabled: true}
                      );
                      self.executeCommand('startRecording', {
                        mode: 'file',
                        shouldShare: true
                      });
                    });
                    externalApi.addListener('videoConferenceJoined', function(test){
                      
                      self.executeCommand('startRecording', {
                        mode: 'file',
                        shouldShare: true
                      });
                      
                    });
                    
                    }}
                    configOverwrite = {{
                    recordingService: {
                      enabled: true
                    },
                    transcription: {
                      enabled: false
                    },
                    localRecording : {
                      disable: true
                    },  
                    // mainToolbarButtons: [
                    //   [ 'microphone', 'camera', 'chat', 'raisehand', 'reactions', 'participants-pane', 'tileview' ],
                    // ],
                    startWithVideoMuted: false,
                    toolbarButtons: [
                      'camera',
                      'chat',
                      'desktop',
                      'download',
                      'desktop',
                      'embedmeeting',
                      'etherpad',
                      'feedback',
                      'filmstrip',
                      'fullscreen',
                      'hangup',
                      'help',
                      'highlight',
                      'invite',
                      'linktosalesforce',
                      'livestreaming',
                      'microphone',
                      'noisesuppression',
                      'participants-pane',
                      'profile',
                      'raisehand',
                      'recording',
                      'security',
                      'select-background',
                      'settings',
                      'shareaudio',
                      'sharedvideo',
                      'shortcuts',
                      'stats',
                      'tileview',
                      'toggle-camera',
                      'videoquality',
                      'whiteboard',
                    ],
                    hideSelfView: true,
                    screenshotCapture:{
                      enabled: false
                    },
                    disableThirdPartyRequests: true,
                    disableLocalVideoFlip: true,
                    backgroundAlpha: 0.5,
                    startRecording: true,
                    }}
                    
                    interfaceConfigOverwrite = {{
                    DISABLE_TRANSCRIPTION_SUBTITLES: true, 
                    VIDEO_LAYOUT_FIT: 'nocrop',
                    MOBILE_APP_PROMO: false,
                    TILE_VIEW_MAX_COLUMNS: 4
                    }}
                
              />
              ) : (
                <div style={{ textAlign: 'center'}}> 
                
                    <p>{rejoinMeeting ? "You left the meeting" : ""}</p>
                  
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#2d3fd3", color: "white"}}
                    onClick={() => changeMeetingStatus("start")}
                  >
                  { rejoinMeeting ? "Rejoin Meeting" : "Join Meeting" }
                  </Button>
                </div> 
              )
              
            } 
              
              


            </>
          </div>
        </div>
      ) : (
        null
      )}
      </div>


      <div class="" style= {adobeURL ? {marginTop: "44px", width: "100%"} : {width: "68%"} } >
        {adobeURL ? <CookieEnable /> : ""}
        {adobeURL ? (
          <>
            {" "}
            {currtempurl == "template" ? (
              <div
                className="iframeHandler"
                dangerouslySetInnerHTML={{ __html: tweetersIframe2 }}
              ></div>
            ) : (
              <div
                className="iframeHandler"
                dangerouslySetInnerHTML={{ __html: tweetersIframe }}
              ></div>
            )}{" "}
          </>
        ) : (
          <div>
            {pdurls}
            <div class="btn-floatingiconforPdf-left">
              <button
                type="button"
                class="btn btn-floatingiconforPdf-left-text"
                onClick={removeCountHandler}
              >{`<`}</button>
            </div>
            <div class="btn-floatingiconforPdf-right">
              <button
                type="button"
                class="btn btn-floatingiconforPdf-right-text"
                onClick={addCountHandler}
              >{`>`}</button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
