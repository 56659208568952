import React from "react";
import { Modal } from "react-bootstrap";
import LinearProgress from "@material-ui/core/LinearProgress";

export function NotaryWaiting({ heading, show, hide }) {
  return (
    <Modal
      show={show}
      // onHide={hide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="justify-content-center pb-2">
        <Modal.Title id="contained-modal-title-vcenter">
          <h2>{heading}</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <LinearProgress color="secondary" className="my-7" />
        <h5>Please Stay with us</h5>
        <p>We are contacting your Notary.</p>
        {/* <p style={{fontSize: '11px', color:'red'}}><strong>Disclaimer</strong>. Don't refresh/close your page. Might you will loss the further verification process. </p> */}
      </Modal.Body>
    </Modal>
  );
}
