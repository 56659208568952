import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import * as actions from "../../_redux/notarization_redux/notarizationActions";

export function NotaryNotices() {
  const dispatch = useDispatch();
  const { auth, notarization } = useSelector(state => state);
  const mystyle = {
    width: "100%",
    height: "auto !important",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    color: "rgba(0, 0, 0, 0.87)",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundColor: "#fff",
    marginBottom: 44
  };

  return (
    <>
      <div
        className="signerNotaryObjectiveWrapper"
        style={mystyle}
        variant="outlined"
      >
        <CardContent>
          <h5>Notary Objectives:</h5>
          <span>
            1. Count the number of Notary Signatures belong on all combined
            documents (use the right arrow to see additional documents)
          </span>
          <br />
          <span>
            2. Confirm the notary blocks are legal for you, if not make notes as
            to what you need to add (loose certificates)
          </span>
          <br />
          <span>
            3. Confirm the entire packet is present for signature, if not make a
            note to consult with the client to correct this.
          </span>
          <br />
          <span>
            4. Add any required witnesses. You will not be able to hit “next”
            until all witnesses have accepted this request.
          </span>
          <br />
        </CardContent>
      </div>
    </>
  );
}
