/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
// import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";

export function NoCardAvailable({ addCard }) {
  return (
    <div className={`card card-custom`}>
      <div className="card-header border-0 py-5">
        <h2 className="card-title align-items-start flex-column">
          Payment Info
        </h2>
      </div>

      <div className="card-body pt-0 pb-3">
        <div className="tab-content">
          <div className="table-responsive">
            <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
              <tbody>
                <tr>
                  {/* {
                                        status === null ?
                                            <td className="text-center">
                                                <CircularProgress color="secondary" />
                                            </td>
                                            :
                                            <> */}
                  <td></td>
                  <td className="pl-0 py-0">
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-50 symbol-light mr-15">
                        <span className="svg-icon h-75 align-self-end">
                          <img
                            src={toAbsoluteUrl("/media/own/no-card-icon.png")}
                            alt="Credit Card"
                            style={{ width: "100px" }}
                          />
                        </span>
                      </div>
                      <div>
                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                          No Payment Method
                        </span>
                        <p className="pt-3">
                          There is no payment method selected. In order to
                          continue, you must choose one.
                        </p>
                      </div>
                    </div>
                  </td>

                  <td className="pr-0 text-right">
                    <Button
                      style={{
                        backgroundColor: "#1840d2",
                        borderColor: "#1840d2",
                        minWidth: "100px"
                      }}
                      onClick={() => addCard(true)}
                    >
                      Add Card
                    </Button>
                  </td>
                  <td></td>
                  {/* </>
                                    } */}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
