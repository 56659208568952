import React from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
export function AllButtonsPS({
  paynow,
  paid,
  notarizeNowHandler,
  scheduleHandler,
  draftHandler,
  user,
  prepayhandler,
  resumeData,
  notarizeNowitHandler,
  selectedNotary,
  jobPostHandler,
  confirmjobPostHandler,
  showReNotary,
  iscontinue,
  isalreadypaid
}) {
  const {
    paynowLoading,
    draftLoading,
    scheduleLoading,
    KBASettings
  } = useSelector(state => state.notarization);
  // const { allJournals, listLoading, customizeJournals } = notarization;
  return (
    <div className={`card card-custom`}>
      <div className="card-body pt-5 pb-3">
        <div className="tab-content">
          <div className="table-responsive">
            <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
              <tbody>
                <tr>
                  <td className="text-center">
                    {
                      <Button
                        className="mr-4 ml-3"
                        style={{
                          backgroundColor: "#1840d2",
                          borderColor: "#1840d2",
                          minWidth: "150px"
                        }}
                        onClick={paynow}
                        disabled={paid ? "disabled" : ""}
                      >
                        {paid ? <span>PAID</span> : <span>Start Here</span>}
                      </Button>
                    }
                    {
                      <Button
                        className="mr-4 ml-3"
                        style={{
                          backgroundColor: "transparent",
                          borderColor: "#1840d2",
                          color: "#1840d2",
                          minWidth: "150px"
                        }}
                        onClick={() => draftHandler("draft")}
                      >
                        Save It
                        {draftLoading && (
                          <span className="ml-3 spinner spinner-white"></span>
                        )}
                      </Button>
                    }

                    <Button
                      className="mr-4 ml-3"
                      style={{
                        backgroundColor: "#1840d2",
                        borderColor: "#1840d2",
                        minWidth: "150px"
                      }}
                      onClick={confirmjobPostHandler}
                      disabled={!paid ? "disabled" : ""}
                    >
                      Connect with Notary
                    </Button>

                    {/* <Button className="mr-4 ml-3" style={{ backgroundColor: '#1840d2', borderColor: '#1840d2', minWidth: '150px' }} onClick={notarizeNowHandler} >{user ? 'Send Email & Notarize Now' : 'Send To Client'}</Button> */}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
