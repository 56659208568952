import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/notarization_redux/notarizationActions";
import { getHandlerTableChange } from "../../../../_metronic/_helpers";
import * as uiHelpers from "../TableUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../_metronic/_partials/controls";
import { useTableUIContext } from "../TableUIContext";
import { GenericZeroState } from "../../zero_state_pages/GenericZeroState";

export function CustomTable(props) {
  // Customers UI Context
  const customersUIContext = useTableUIContext();
  const [isAllowed, setIsAllowed] = useState();

  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      setIds: customersUIContext.setIds,
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
      EnableStateBiometricDialog: customersUIContext.EnableStateBiometricDialog
    };
  }, [customersUIContext]);

  const { auth, notarization } = useSelector(state => state);
  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  // Customers Redux state
  const dispatch = useDispatch();
  const queryParams = [];

  if (props.keyword) queryParams.push("q=" + props.keyword);

  if (props.statusfilter) queryParams.push("status=" + props.statusfilter);

  const {
    allAdminStates,
    listLoading,
    customizeAllAdminStates,
    changeActiveStatusLoading
  } = notarization;

  useEffect(() => {
    if (props.searchClicked) {
      customersUIProps.queryParams.pageNumber = 1;
    }
    dispatch(
      actions.fetchNotaryStates(
        headers,
        queryParams,
        customersUIProps.queryParams
      )
    );
  }, [customersUIProps.queryParams, dispatch]);

  // Table columns
  const columns = [
    {
      dataField: "sno",
      text: "#"
    },
    {
      dataField: "name",
      text: "States",
      formatter: columnFormatters.FullNameColumnFormatterAdmin
    },
    {
      dataField: "active_Inactive",
      text: "Biometric Allowed?",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        setIsAllowed: setIsAllowed,
        isAllowed: isAllowed,
        EnableStateBiometricDialog: customersUIProps.EnableStateBiometricDialog
      }
    }
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: allAdminStates === null ? 0 : allAdminStates.count,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: customersUIProps.queryParams.pageSize,
    page: customersUIProps.queryParams.pageNumber,
    sortBy: "asc",
    sortField: "state"
  };

  return (
    <>
      {allAdminStates !== null && allAdminStates.rows.length > 0 ? (
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => {
            return (
              <Pagination
                isLoading={listLoading}
                paginationProps={paginationProps}
              >
                <BootstrapTable
                  wrapperClasses="table-responsive"
                  bordered={false}
                  classes="table table-head-custom table-vertical-center overflow-hidden"
                  bootstrap4
                  remote
                  keyField="id"
                  data={
                    customizeAllAdminStates === null
                      ? []
                      : customizeAllAdminStates
                  }
                  columns={columns}
                  defaultSorted={uiHelpers.defaultSorted}
                  onTableChange={getHandlerTableChange(
                    customersUIProps.setQueryParams,
                    props.setSearchClicked
                  )}
                  {...paginationTableProps}
                ></BootstrapTable>
              </Pagination>
            );
          }}
        </PaginationProvider>
      ) : (
        <GenericZeroState
          status={allAdminStates}
          imageURL="/media/own/no-notary-online.png"
          heading=""
          body="Unfortunately, there are no Users which match the requirements you have selected"
          sub_body=""
          btnTxt="Start a New Search"
          btnHandler={e => props.applyFilterHandler("clear")}
        />
      )}
    </>
  );
}
