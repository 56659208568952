import React, { useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { TableUIProvider } from '../components/notarization_listing/dashboard/TableUIContext'
import { TableCard } from '../components/notarization_listing/dashboard/TableCard'
import { CustomerEditDialog } from '../components/notarization_listing/journals/edit-dialog/CustomerEditDialog'
import { NewNotarization } from '../pages/NewNotarization'
import { NewNotarizationNotary } from '../pages/NewNotarizationNotary'
import { NewNotarizationPS } from '../pages/NewNotarizationPS'
import { useDispatch, useSelector } from 'react-redux'
import { GenericZeroState } from '../components/zero_state_pages/GenericZeroState'
import * as actions from '../components/_redux/notarization_redux/notarizationActions'
const publicIp = require("public-ip");


export function DashboardPage() {
    const MIDDLEWARE_URL = process.env.REACT_APP_MIDDLEWARE_URL
    const [actionModal, setActionModal] = useState(false)
    const [notaryID, setNotaryID] = useState(null)
    const [resume, setResume] = useState({ status: false, id: null })
    const customersUIEvents = {
        openEditCustomerDialog: (id) => {
            setActionModal(true)
            setNotaryID(id)
        },
    }

    const dispatch = useDispatch()
    const location = useLocation()
    const { auth, notarization } = useSelector((state) => state)
    const { top5Journals } = notarization

    const resumeNotarization = (id) => {
        setResume({ status: true, id: id })
        const resumeData = top5Journals.rows.filter((data) => data.id === id)
        if (resumeData !== null)
            dispatch(actions.updateDocuments(resumeData[0].documents))
    }

    const addWitness = (id) =>
        (window.location.href = '#/searchWitnesses/?id=' + id)
    const addNewHandler = () => {
        'ps' == auth.user.Role.code
            ? (window.location.href = '/#/new-notarizationPS')
            : (window.location.href = '/#/new-notarization')
    }

    const headers = {
        Authorization: `Bearer ${auth.authToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
    }

    const downloadDocument = (journalId) => {
        publicIp.v4().then(ipaddr => {
            if (ipaddr){
                let xhr = new XMLHttpRequest()
                xhr.open('POST', MIDDLEWARE_URL + '/api/journal/' + journalId + '/pdf')
                xhr.setRequestHeader('content-type', 'application/json')
                xhr.setRequestHeader('authorization', 'Bearer ' + auth.authToken)
               
                xhr.responseType = 'arraybuffer'
                xhr.onreadystatechange = (e) => {
                    if (xhr.status == '200' && xhr.readyState == 4) {
                        let data = xhr.response
                        let file = new Blob([data], { type: 'application/pdf' })
                        let a = document.createElement('a')
                        a.href = URL.createObjectURL(file)
                        a.download = journalId + '-document.pdf'
                        a.click()
                    }
                }
                xhr.send(JSON.stringify({ip:ipaddr}))
            }
        });
        
    }

    const auditReport = (journalId) => {
        let xhr = new XMLHttpRequest()
        xhr.open(
            'GET',
            MIDDLEWARE_URL + '/api/journal/' + journalId + '/auditTrail'
        )
        xhr.setRequestHeader('content-type', 'application/json')
        xhr.setRequestHeader('authorization', 'Bearer ' + auth.authToken)
        xhr.responseType = 'arraybuffer'
        xhr.onreadystatechange = (e) => {
            if (xhr.status == '200' && xhr.readyState == 4) {
                let data = xhr.response
                let file = new Blob([data], { type: 'application/pdf' })
                let a = document.createElement('a')
                a.href = URL.createObjectURL(file)
                a.download = journalId + '-auditTrail.pdf'
                a.click()
            }
        }
        xhr.send(JSON.stringify())
    }

    const downloadJournal = (journalId) => {
        let xhr = new XMLHttpRequest()
        let date = new Date();

        let timeString = date.toTimeString();
        let [time , ...timeZone] = timeString.split(" ")
    
        timeZone = timeZone.join(" ")
        xhr.open(
            'POST',
            MIDDLEWARE_URL + '/api/journal/' + journalId + '/create-pdf'
        )
        xhr.setRequestHeader('content-type', 'application/json')
        xhr.setRequestHeader('authorization', 'Bearer ' + auth.authToken)
        xhr.responseType = 'arraybuffer'
        xhr.onreadystatechange = (e) => {
            if (xhr.status == '200' && xhr.readyState == 4) {
                let data = xhr.response
                let file = new Blob([data], { type: 'application/pdf' })
                let a = document.createElement('a')
                a.href = URL.createObjectURL(file)
                a.download = journalId + '-journal.pdf'
                a.click()
            }
        }
        xhr.send(JSON.stringify({timeZone}))
    }


    useEffect(() => {
        const urlParams = new URLSearchParams(location.search)
        if (urlParams.get('journalId')) {
            window.location.href =
                '#/my-signer-question?code=' +
                urlParams.get('code') +
                '&journalId=' +
                urlParams.get('journalId') +
                '&isUser=true'
            window.location.reload()
        }
        dispatch(actions.fetchTop5Journals(headers, auth.user.Role.code))

        dispatch(
            actions.fetchClients(headers, {
                sortOrder: 'asc',
                sortField: 'firstName',
                pageNumber: 1,
                pageSize: 100000,
            })
        )
    }, [])

    return resume.status ? (
        auth.user.Role.code === 'notary' ? (
            <NewNotarizationNotary
                newNot={resume.status}
                id={resume.id}
                from='dashboard'
                resumeNotarization={resumeNotarization}
            />
        ) : (
            <>
                {auth.user.Role.code === 'ps' ? (
                    <NewNotarizationPS
                        newNot={resume.status}
                        id={resume.id}
                        from='dashboard'
                        resumeNotarization={resumeNotarization}
                    />
                ) : (
                    <NewNotarization
                        newNot={resume.status}
                        id={resume.id}
                        from='dashboard'
                        resumeNotarization={resumeNotarization}
                    />
                )}
            </>
        )
    ) : top5Journals !== null && top5Journals.rows.length > 0 ? (
        <TableUIProvider customersUIEvents={customersUIEvents}>
            <CustomerEditDialog
                show={actionModal}
                id={notaryID}
                resumeNotarization={resumeNotarization}
                addWitness={addWitness}
                downloadDocument={downloadDocument}
                auditReport={auditReport}
                downloadJournal={downloadJournal}
                onHide={() => {
                    setActionModal(false)
                    setNotaryID(null)
                }}
            />
            <TableCard />
        </TableUIProvider>
    ) : (
        <GenericZeroState
            status={top5Journals}
            imageURL='/media/own/notarization.png'
            heading=''
            body='Once you initiate a Notarization, it will be listed here.'
            btnTxt={( auth.user.Role.code !== "notary" || (auth.user.Role.code === "notary" && !auth.user.notaryProfile.isCancelled ) ) ? '+ New Notarization' : "false"}
            btnHandler={addNewHandler}
        />
    )
}
