import React from "react";
import DropZoneUploader from "./DropZoneUploader";

export function NoFileView({ getFiles, disabled, uploading }) {
  return (
    <div className="card card-custom">
      <div className="card-body pb-3 pt-3">
        <div className="tab-content align-items-center text-center filepreviewsection">
          <DropZoneUploader
            getFiles={getFiles}
            disabled={disabled}
            uploading={uploading}
          />
        </div>
      </div>
    </div>
  );
}
