import React from "react";
import Grid from "@material-ui/core/Grid";
import PrimaryNotaryCard from "./PrimaryNotaryCard";

export function NotaryListing({ notaries, makePrimaryHandler, selectedNotaryId=null }) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <Grid container spacing={8}>
            {notaries.map(el => (
              <PrimaryNotaryCard
                key={el.id}
                notary={el}
                selectedNotaryId={selectedNotaryId}
                makePrimaryHandler={makePrimaryHandler}
              />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
