import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/notarization_redux/notarizationActions";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses
} from "../../../../../_metronic/_helpers";
import * as uiHelpers from "../TableUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../_metronic/_partials/controls";
import { useTableUIContext } from "../TableUIContext";

export function CustomTable({ showNotarizationPopup, restore }) {
  // Customers UI Context
  const customersUIContext = useTableUIContext();
  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      setIds: customersUIContext.setIds,
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
      openEditCustomerDialog: customersUIContext.openEditCustomerDialog,
      downloadDocument: customersUIContext.downloadDocument
    };
  }, [customersUIContext]);

  const [isfetchjournals, setisfetchjournals] = useState(0);
  const [ischeckall, setischeckall] = useState(false);
  const [isfetchjournalssize, setisfetchjournalssize] = useState(0);
  const { auth, notarization, socket } = useSelector(state => state);
  const {
    allJournals,
    listLoading,
    customizedeleteJournals,
    reloadcallData,
    deletedJournals
  } = notarization;
  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };
  // Customers Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    if (isfetchjournals != customersUIProps.queryParams.pageNumber) {
      dispatch(
        actions.fetchJournals(
          customersUIProps.queryParams,
          headers,
          auth.user.Role.code
        )
      );
      setisfetchjournals(customersUIProps.queryParams.pageNumber);
    }

    if (isfetchjournalssize != customersUIProps.queryParams.pageSize) {
      dispatch(
        actions.fetchJournals(
          customersUIProps.queryParams,
          headers,
          auth.user.Role.code
        )
      );
      setisfetchjournalssize(customersUIProps.queryParams.pageSize);
    }

    recallloadfunc();
  }, [customersUIProps.queryParams, dispatch, reloadcallData, socket]);

  const recallloadfunc = () => {
    if (reloadcallData > 0) {
      dispatch(actions.getJournalbyID(reloadcallData, headers));
      dispatch(actions.getJitsiMeetingLinks(reloadcallData, headers))
    }

    if (socket.kbaVerificationRejectedNotary != null) {
      dispatch(
        actions.getJournalbyID(
          socket.kbaVerificationRejectedNotary.journalId,
          headers
        )
      );
    }
  };

  // Table columns
  const columns = [
    {
      dataField: "action",
      text: "Action",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditCustomerDialog: customersUIProps.openEditCustomerDialog,
        firstColumn: true,
        auth: auth
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px"
      },
      hidden: ( auth.user.Role.code !== "notary" || (auth.user.Role.code === "notary" && !auth.user.notaryProfile.isCancelled ) ) ? false : true,
    },
    {
      dataField: "sno",
      text: (
        <>
          <input
            type="checkbox"
            value="1"
            name="checkall"
            onClick={e => setischeckall(e.target.checked)}
          />
        </>
      ),
      formatter: columnFormatters.CheckallFormatter,
      formatExtraData: {
        ischeckall: ischeckall
      },
      hidden: ( auth.user.Role.code !== "notary" || (auth.user.Role.code === "notary" && !auth.user.notaryProfile.isCancelled ) ) ? false : true,
    },

    {
      dataField: "sno",
      text: "#"
    },
    {
      dataField: "notary",
      text: auth.user.Role.code === "ps" ? "CUSTOMER NAME" : "NOTARY NAME"
    },
    {
      dataField: "type",
      text: "TYPE"
    },
    {
      dataField: "scheduledAt",
      text: "START TIME & DATE",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: "endDate",
      text: "END TIME & DATE",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: "noOfSigners",
      text: "NUMBER OF SIGNERS",
      classes: "text-center",
      headerClasses: "text-center"
    },
    {
      dataField: "status",
      text: "Status",
      formatter: columnFormatters.StatusColumnFormatter
    },

    {
      dataField: "action",
      text: "Action",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditCustomerDialog: customersUIProps.openEditCustomerDialog,
        downloadDocument: customersUIProps.downloadDocument,
        showNotarizationPopup: showNotarizationPopup,
        restore: restore,
        firstColumn: false,
        auth: auth
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px"
      },
      hidden: ( auth.user.Role.code !== "notary" || (auth.user.Role.code === "notary" && !auth.user.notaryProfile.isCancelled ) ) ? false : true,
    }
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: deletedJournals === null ? 0 : deletedJournals.count,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: customersUIProps.queryParams.pageSize,
    page: customersUIProps.queryParams.pageNumber
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="id"
                data={
                  customizedeleteJournals === null
                    ? []
                    : customizedeleteJournals
                }
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  customersUIProps.setQueryParams
                )}
                {...paginationTableProps}
              >
                <PleaseWaitMessage
                  entities={
                    customizedeleteJournals === null
                      ? []
                      : customizedeleteJournals
                  }
                />
                <NoRecordsFoundMessage
                  entities={
                    customizedeleteJournals === null
                      ? []
                      : customizedeleteJournals
                  }
                />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
