import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from "react-bootstrap";
import { Card, CardActions, CardContent, Button } from '@material-ui/core'
import * as notarizationActions from '../../_redux/notarization_redux/notarizationActions'
import { AllWitnesses } from '../../signwitnesss/AllWitnesses'

import { notarizationSlice } from '../../_redux/notarization_redux/notarizationSlice.js'
const { actions } = notarizationSlice

export function CustomCardForAdobeSign(props) {
    const dispatch = useDispatch()
    const { auth, notarization } = useSelector((state) => state)
    const {
        isWitnessAdded,
        currentjournalstatus,
        witnesses,
        adobeURL,
        getAllJournal,
    } = notarization
    const [journalID, setjournalID] = useState(
        props.journalID ? props.journalID : 0
    )

    // const {removeNextButton} = props;
  

    const [initialwitnesscount, setinitialwitnesscount] = useState(getAllJournal.journal.witnessCount);

    const [witnessAdded, setWitnessAdded] = useState(getAllJournal.journal.witnessCount);

    const headers = {
        Authorization: `Bearer ${auth.authToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
    }

    const mystyle = {
        width: "100%",
        height: "auto !important",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        borderRadius: "4px",
        color: "rgba(0, 0, 0, 0.87)",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        backgroundColor: "#fff",
        marginBottom: 44
      };

    const PostWitnessJob = () => {
    
        setinitialwitnesscount(witnessAdded);
        dispatch(
            notarizationActions.PostWitnessJob(
                getAllJournal.journal,
                { journalId: journalID, witnessCount: witnessAdded },
                headers
            )
        )
    }

    const addNoOfWitness = (e) => {        
        setWitnessAdded(e.target.value)
    }

    useEffect(() => {
        
        if ('Awaiting Witnesses' === getAllJournal.journal.subStatus) {
            dispatch(actions.witnessAdded(true))
        }
    }, [notarization])

    return (
        <>
            <div>
                {witnesses.length > 0 ? (
                    <AllWitnesses journalID={journalID} />
                ) : (
                    <>
                    {journalID &&
                    !adobeURL &&
                    getAllJournal &&
                    getAllJournal.journal &&
                    initialwitnesscount &&
                    initialwitnesscount > 0 ? (
                        <div
                            className="signerNotaryObjectiveWrapper"
                            style={mystyle}
                            variant="outlined"
                        >
                            <CardContent>
                                <h5>Witnesses</h5>
                                {!adobeURL ? (
                                    <span>
                                        {getAllJournal &&
                                        getAllJournal.journal &&
                                        initialwitnesscount
                                            ? initialwitnesscount
                                            : ''}{' '}
                                        {getAllJournal &&
                                        getAllJournal.journal &&
                                        initialwitnesscount &&
                                        initialwitnesscount >
                                            1
                                            ? 'witnesses have '
                                            : 'witness has '}{' '}
                                        been selected by the client, please
                                        add them to continue this
                                        transaction.
                                    </span>
                                ) : (
                                    <span>No Witness Selected</span>
                                )}
                            </CardContent>
                            <CardContent>
                                {journalID && !adobeURL && (
                                    <span>
                                        {/* <Link to={"/searchWitnesses/?id="+journalID}> */}
                                        {!isWitnessAdded &&
                                        ('Awaiting Witnesses' !==
                                            currentjournalstatus ||
                                            'Awaiting Witnesses' !==
                                                getAllJournal.journal
                                                    .subStatus) ? (
                                            <>    
                                                <span style={{color: 'red'}}><strong>THIS ACTION CANNOT BE CHANGED AFTER YOU HIT SUBMIT OR NEXT.</strong></span>

                                                <Form.Control
                                                    style={{ width: "30%" }}
                                                    type="number"
                                                    min="0"
                                                    onChange={addNoOfWitness}
                                                    value={witnessAdded}
                                                    
                                                />
                                                
                                                <Button
                                                    onClick={() =>
                                                        PostWitnessJob()
                                                    }
                                                    variant='contained'
                                                    style={{
                                                        backgroundColor:
                                                            '#2d3fd3',
                                                        color: 'white',
                                                    }}>
                                                    Submit
                                                </Button>
                                            </>
                                        ) : (
                                            <p>
                                                {
                                                    witnessAdded > 1 ? 'The witness request(s) have been posted to the job board.' : ''
                                                }
                                            </p>                                           
                                            
                                        )}
                                    </span>
                                )}
                            </CardContent>
                        </div>
                    ) : (
                        <>
                            <div
                                style={{
                                    width: '100%',
                                    border: '1px solid rgba(0, 0, 0, 0.12)',
                                    borderRadius: '4px',
                                    color: 'rgba(0, 0, 0, 0.87)',
                                    transition:
                                        'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                                    backgroundColor: 'rgb(255, 255, 255)',
                                    marginBottom: '44px',
                                }}>
                                <CardContent>
                                    <h5>Witnesses</h5>
                                    <span>
                                        {' '}
                                        No Witness(es) have been requested
                                        by the client for this notarization.
                                    </span>
                                </CardContent>

                                <CardContent>
                                {journalID && !adobeURL && (
                                    <span>
                                        {/* <Link to={"/searchWitnesses/?id="+journalID}> */}
                                        {!isWitnessAdded &&
                                        ('Awaiting Witnesses' !==
                                            currentjournalstatus ||
                                            'Awaiting Witnesses' !==
                                                getAllJournal.journal
                                                    .subStatus) ? (
                                            
                                            <>    
                                                <span style={{color: 'red'}}><strong>THIS ACTION CANNOT BE CHANGED AFTER YOU HIT SUBMIT OR NEXT.</strong></span>

                                                <Form.Control
                                                    style={{ width: "30%" }}
                                                    type="number"
                                                    min="0"
                                                    onChange={addNoOfWitness}
                                                    value={witnessAdded}
                                                    
                                                />

    
                                                
                                                <Button
                                                    onClick={() =>
                                                        PostWitnessJob()
                                                    }
                                                    variant='contained'
                                                    style={{
                                                        backgroundColor:
                                                            '#2d3fd3',
                                                        color: 'white',
                                                    }}>
                                                    Submit
                                                </Button>
                                            </>
                                        ) : (
                                            <p>
                                                {
                                                    witnessAdded > 1 ? 'The witness request(s) have been posted to the job board.' : ''
                                                }
                                            </p>
                                        )}
                                    </span>
                                )}
                                </CardContent>
                            </div>
                        </>
                    )}
                </>
                )}
            </div>
        </>
    )
}
