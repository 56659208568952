export const CustomerStatusCssClasses = [
  "danger",
  "success",
  "info",
  "primary",
  "warning",
  "success"
];
export const CustomerStatusTitles = [
  "Pending",
  "In Progress",
  "Completed",
  "Draft",
  "Scheduled",
  "initiated"
];
export const CustomerTypeCssClasses = ["success", "primary", ""];
export const CustomerTypeTitles = ["Business", "Individual", ""];
export const CustomerTypeStatus = ["In Progress", "Completed", ""];
export const CustomerTypeAction = ["Join Session", "Session Completed", ""];
export const CustomerTypePaymentsAction = ["Paid", ""];
export const defaultSorted = [{ dataField: "firstName", order: "asc" }];
export const sizePerPageList = [
  { text: "1", value: 1 },
  { text: "3", value: 3 },
  { text: "5", value: 5 },
  { text: "10", value: 10 },
  { text: "25", value: 25 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
  { text: "500", value: 500 }
];
export const initialFilter = {
  sortOrder: "asc", // asc||desc
  sortField: "firstName",
  pageNumber: 1,
  pageSize: 10
};
