import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../_metronic/_partials/controls";
import { OnlineNotaryListing } from "./card/OnlineNotaryListing";
import { Filters } from "./card/Filters";
import { Form } from "react-bootstrap";
import * as actions from "../_redux/notarization_redux/notarizationActions";
import ShowNotification from "../../components/new_notarization/ShowNotification";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link } from "react-router-dom";
import { GenericZeroState } from "../zero_state_pages/GenericZeroState";
import { useLocation } from "react-router";

export default function OnlineSearchNotaryContainer(props) {
  const location = useLocation();
  // States
  const [journalID, setjournalID] = useState(
    props.journalID ? props.journalID : 0
  );
  const [notification, setNotification] = useState({
    show: false,
    msg: "",
    variant: ""
  });
  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") return;
    setNotification({ show: false, msg: "", variant: "" });
  };
  const [search, setSearch] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const { auth, notarization } = useSelector(state => state);
  const [filterValues, setFilterValues] = useState({
    language: "",
    notaryType: "",
    state: ""
  });

  const [queries, setQueries] = useState({
    q: "",
    lang: "",
    type: "",
    state: "",
    page: 1,
    limit: 9
  });

  const {
    witnesses,
    filterNotaries1,
    getAllJournal,
    onlineNotaries1
  } = notarization;
  // Handler Functions
  const handleKeypress = e => {
    if (e.which === 13) applyFilterHandler("search");
  };

  const applyFilterHandler = name => {
    if (name === "search") setQueries({ ...queries, q: search });
    else if (name === "filters")
      setQueries({
        ...queries,
        lang: filterValues.language,
        type: filterValues.notaryType,
        state: filterValues.state
      });
    else if (name === "clear") {
      setQueries({ q: "", lang: "", type: "", state: "", page: 1, limit: 9 });
      setSearch("");
      setFilterValues({ language: "", notaryType: "", state: "" });
      setShowFilter(false);
    } else setQueries({ ...queries, lang: "", type: "", state: "" });
  };

  const paginationHandleChange = (event, value) =>
    setQueries({ ...queries, page: value });

  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  const dispatch = useDispatch();

  const makePrimaryHandler = e => {
    dispatch(
      actions.setPrimaryNotary(
        { notaryId: e.currentTarget.id },
        headers,
        props.setNotification,
        null,
        auth.user.Role.code
      )
    );
  };

  const getOnlineNotaries = () => {
    return onlineNotaries1;
  };

  useEffect(() => {
    dispatch(actions.fetchFilteredOnlineNotaries(queries, headers));

    if (journalID) {
      if (parseInt(journalID))
        dispatch(actions.fetchWitnessesforJournal(journalID, headers));
      else window.location.href = "#/journals";
    } else if (location.pathname === "/searchWitnesses/")
      window.location.href = "#/journals";
  }, [dispatch, queries]);

  const arrowStyle = {
    fontSize: "36px",
    color: "#909090",
    backgroundColor: "#fbfbfb",
    borderRadius: "50%",
    marginRight: "2px",
    padding: "7px"
  };

  const submitWitnesses = async () => {
    let make_witness = document.querySelectorAll(
      'input[name="make_witness[]"]:checked'
    );

    let witnessess = [];
    for (var i = 0; i < make_witness.length; i++) {
      witnessess.push(parseInt(make_witness[i].value));
    }

    if (getAllJournal.journal && getAllJournal.journal.witnessCount > -1) {
      if (
        getAllJournal.journal.witnessCount <
        witnessess.length + witnesses.length
      ) {
        setNotification({
          show: true,
          msg:
            "You can't add Witness more than " +
            getAllJournal.journal.witnessCount,
          variant: "error"
        });
        return false;
      }
    }

    if (witnessess.length > 0) {
      let response = await dispatch(
        actions.AddWitness(
          { userId: witnessess, journalId: parseInt(journalID) },
          headers
        )
      );
      if (
        response &&
        response.data &&
        response.data.status &&
        "ok" == response.data.status
      )
        window.location.href = "#/signwitness/?id=" + journalID;
    } else window.location.href = "#/signwitness/?id=" + journalID;
  };

  return (
    <Card>
      {notification.show && (
        <ShowNotification
          show={notification.show}
          msg={notification.msg}
          variant={notification.variant}
          close={handleCloseNotification}
        />
      )}
      <div className="pl-6 pt-7">
        {props.noback ? (
          ""
        ) : (
          <span>
            {props.backchooseNotaryhandler ? (
              <button
                type="button"
                style={{ border: "none", backgroundColor: "white" }}
                onClick={props.backchooseNotaryhandler}
              >
                <ArrowBackIcon style={arrowStyle} />{" "}
                <span style={{ fontSize: "14px", color: "#909090" }}>Back</span>
              </button>
            ) : (
              <Link to={"/user-profile"}>
                <ArrowBackIcon style={arrowStyle} />{" "}
                <span style={{ fontSize: "14px", color: "#909090" }}>Back</span>
              </Link>
            )}
          </span>
        )}
      </div>
      <CardHeader
        title={
          auth.user.Role.code === "user"
            ? "Search Notaries"
            : "Search Witnesses"
        }
        className="py-6"
      >
        <CardHeaderToolbar>
          <Form.Control
            type="text"
            className="mr-5"
            placeholder="Search notary by name"
            value={search}
            onKeyPress={handleKeypress}
            onChange={e => setSearch(e.target.value)}
          />

          <button
            type="button"
            className="btn btn-primary"
            onClick={e => applyFilterHandler("search")}
          >
            Search
          </button>

          {journalID > 0 ? (
            <div className="btn-floatingicon">
              <button
                type="button"
                className="btn btn-floatingicon-text"
                onClick={e => submitWitnesses()}
              >{`>`}</button>
            </div>
          ) : (
            ""
          )}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody style={{ minHeight: "55vh" }}>
        {filterNotaries1 !== null && filterNotaries1.rows.length > 0 ? (
          <>
            <Filters
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              filterValues={filterValues}
              setFilterValues={setFilterValues}
              applyFilterHandler={applyFilterHandler}
            />
            <OnlineNotaryListing
              notaries={filterNotaries1.rows}
              getOnlineNotaries={getOnlineNotaries}
              makePrimaryHandler={makePrimaryHandler}
              chooseNotaryHandler={props.chooseNotaryHandler}
              chooseNotary={props.chooseNotary}
              onlineNotaries1={onlineNotaries1}
            />
            <Pagination
              count={Math.ceil(onlineNotaries1.length / 9)}
              page={queries.page}
              onChange={paginationHandleChange}
              showFirstButton
              showLastButton
              className="mt-10"
              color="secondary"
              size="large"
            />
          </>
        ) : (
          <>
            <Filters
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              filterValues={filterValues}
              setFilterValues={setFilterValues}
              applyFilterHandler={applyFilterHandler}
            />
            <GenericZeroState
              status={filterNotaries1}
              imageURL="/media/own/no-notary-online.png"
              heading=""
              body="Unfortunately, there are no notaries online that match your requirements. Please clear your filters and try again. If searching for a notary by name, ensure you have spelled it correctly."
              sub_body="If you need further assistance, contact support at 614-368-1106 or at support@cyberizeit.com"
              btnTxt="Start a New Search"
              btnHandler={e => applyFilterHandler("clear")}
            />
          </>
        )}
      </CardBody>
    </Card>
  );
}
