import axios from "axios";
import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useSelector } from "react-redux";
import ReadyCardView from "./ReadyCardView";

export function AddCardModal({ openModal, setModal, setNotification }) {
  const [CardToken, setCardToken] = useState(null);
  const [stripePromise, setstripePromise] = useState(null);
  // Getting curret state from store (Redux)
  const { auth } = useSelector(state => state);

  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json"
  };

  useEffect(() => {
    setstripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY));

    axios
      .post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/api/payment/getSetupIntent`,
        { userId: auth.user.id },
        { headers }
      )
      .then(res => {
        setCardToken(res.data.client_secret);
      })
      .catch(e => {
        setCardToken(null);
      });
  }, []);

  return (
    <Elements stripe={stripePromise}>
      {CardToken !== null ? (
        <ReadyCardView
          stripeToken={CardToken}
          openModal={openModal}
          setModal={setModal}
          userID={auth.user.id}
          headers={headers}
          setNotification={setNotification}
        />
      ) : null}
    </Elements>
  );
}
