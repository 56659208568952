// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

export function JobBoardColumnFormatterAdmin(cellContent, row, rowIndex, props) {
  let {updateClaimJobBoardStatus} = props;
  let claimJob = row.notaryProfile && row.notaryProfile?.claimJob ? row.notaryProfile?.claimJob : false
  
  return (
    <span className>
       <Switch
            checked={claimJob}
            onChange={() => updateClaimJobBoardStatus(row.id, !claimJob)
            }
        />
     
    </span>
    
  );
}
