import React from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

export function SubmitAnswers(props) {
  return (
    <div>
      <Button
        className="submitBtn"
        disabled={props.isdone}
        onClick={() => props.submitAnswer()}
      >
        {!props.isdone ? (
          <>Submit</>
        ) : (
          <CircularProgress classes={"colorPrimary"} size={15} />
        )}
      </Button>
    </div>
  );
}
