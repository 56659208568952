import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { requestChangePassword } from "../_redux/authCrud";
import TextField from "@material-ui/core/TextField";

import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Alert from "@material-ui/lab/Alert";

const initialValues = {
  password: "",
  changepassword: ""
};

function ChangePassword(props) {
  const [values, setValues] = React.useState({
    password: "",
    changepassword: "",
    errorMSG: false
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleClickConfrimShowPassword = () => {
    setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
  };

  const { intl } = props;
  const [ID, setID] = useState(
    props.location.state && props.location.state.user_id
      ? props.location.state.user_id
      : ""
  );

  const [isRequested, setIsRequested] = useState(false);
  const [loading, setLoading] = useState(false);
  const ForgotPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .trim("Required Field")
      .strict(true)
      .required("Enter Your Password"),
    changepassword: Yup.string()
      .trim("Required Field")
      .strict(true)
      .required("Enter Your Confirm Password")
      .when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Please make sure your passwords match!"
        )
      })
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };
  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      if (
        values.password === values.changepassword &&
        values.password.length > 7
      ) {
        enableLoading();
        setTimeout(() => {
          requestChangePassword(values.password, values.changepassword, ID)
            .then(res => {
              disableLoading();
              setIsRequested(true);
              // window.location.href = "/auth/code-page"
            })
            .catch(err => {
              setIsRequested(false);
              setSubmitting(false);
              // window.location.href = "/#/CodePage"
              setStatus(
                intl.formatMessage(
                  { id: "AUTH.VALIDATION.NOT_FOUND" },
                  { name: values.password }
                )
              );
              disableLoading();
            });
        }, 1000);
      } else {
        setValues({
          errorMSG: true
        });
      }
    }
  });
  return (
    <>
      {!ID && <Redirect to="/auth/forgot-password" />}
      {isRequested ? <Redirect to="/auth" /> : ""}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Change Password ?</h3>
            <div className="text-muted font-weight-bold">
              Enter your Password and Confirm-Password
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {values.errorMSG === true ? (
              <Alert severity="error" className="mb-3">
                Password must contain minimum 8 characters
              </Alert>
            ) : (
              ""
            )}
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}

            <div className="form-group fv-plugins-icon-container">
              <TextField
                className={`mb-3 ${getInputClasses("password")}`}
                label="Password"
                id="outlined-adornment-password"
                style={{ width: "100%" }}
                variant="outlined"
                value={values.currentpPassword}
                name="password"
                type={values.showPassword ? "text" : "password"}
                color={"secondary"}
                {...formik.getFieldProps("password")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="Toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {values.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              {formik.touched.password && formik.errors.password ? (
                <div
                  className="fv-plugins-message-container"
                  style={{ color: "red" }}
                >
                  <div className="fv-help-block">{formik.errors.password}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group fv-plugins-icon-container">
              <TextField
                className={` ${getInputClasses("changepassword")}`}
                label="Confirm Password"
                id="outlined-adornment-password"
                style={{ width: "100%" }}
                variant="outlined"
                value={values.changepassword}
                name="changepassword"
                color={"secondary"}
                type={values.showConfirmPassword ? "text" : "password"}
                {...formik.getFieldProps("changepassword")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="Toggle password visibility"
                        onClick={handleClickConfrimShowPassword}
                      >
                        {values.showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              {formik.touched.changepassword && formik.errors.changepassword ? (
                <div
                  className="fv-plugins-message-container"
                  style={{ color: "red" }}
                >
                  <div className="fv-help-block">
                    {formik.errors.changepassword}
                  </div>
                </div>
              ) : null}
            </div>

            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                // disabled={formik.isSubmitting}
              >
                Submit
                {loading && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ChangePassword));
