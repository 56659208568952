import * as requestFromServer from './notarizationCrud'
import { notarizationSlice, callTypes } from './notarizationSlice'

const { actions } = notarizationSlice
const publicIp = require("public-ip");

export const uploadDocuments = (
    documentsForUpload,
    headers,
    setNotification,
    setUploading,
    setDisableButton = null
) => (dispatch) => {
    return requestFromServer
        .uploadDocuments(documentsForUpload, headers)
        .then((response) => {
            dispatch(actions.documentUploaded(response.data.files))
            setNotification({
                show: true,
                msg: 'Document(s) successfully uploaded.',
                variant: 'success',
            })
            if (setDisableButton !== null) setDisableButton(true)
            setUploading(false)
        })
        .catch((error) => {
            setNotification({
                show: true,
                msg: 'Unexpected error occurs while uploading document(s).',
                variant: 'error',
            })
            setUploading(false)
            // if(setDisableButton !== null) setDisableButton(false);
        })
}

export const reloadcall = (journalID) => (dispatch) => {
    dispatch(actions.reloadcall(journalID))
}

export const resetrecalldata = () => (dispatch) => {
    dispatch(actions.resetrecalldata())
}

export const copyLink = (id, headers) => (dispatch) => {
    return requestFromServer
        .copylink(id, headers)
        .then((response) => {
            return response
        })
        .catch((error) => {
            let erroMessage = error.response.data
            return erroMessage
        })
}

export const fetchKBASettings = (headers) => (dispatch) => {
    return requestFromServer
        .fetchKBASettings(headers)
        .then((response) => {
            dispatch(actions.fetchedKBASettings(response))
        })
        .catch((error) => {
            error.clientMessage = "Can't find journals"
            dispatch(actions.catchError({ error, callType: callTypes.list }))
        })
}

export const downloadDocument = (headers, journalId) => (dispatch) => {
    publicIp.v4().then(ipaddr => {
        console.log(ipaddr);
        if (ipaddr){
            return requestFromServer
            .downloadDocument(headers, journalId, ipaddr)
            .then((response) => {
                return response
            })
            .catch((error) => {
                error.clientMessage = "Can't download"
            })
        }
    });
    
}

export const claimWitnessJob = (params, headers) => (dispatch) => {
    return requestFromServer
        .claimWitnessJob(params, headers)
        .then((response) => {
            return response
        })
        .catch((error) => {
            error.clientMessage = "Can't Claim Job"
        })
}

export const PostWitnessJob = (journal, params, headers) => (dispatch) => {
    return requestFromServer
        .PostWitnessJob(params, headers)
        .then((response) => {
            dispatch(actions.witnessAdded(true))
            let obj = JSON.parse(JSON.stringify(journal))
            obj.witnessCount = parseInt(params.witnessCount);
            if(obj.witnessCount > 0){
                obj.subStatus = 'Awaiting Witnesses'
            }
            else{
                obj.subStatus = 'Accepted'
            }
            dispatch(actions.getAllJournalDetails({journal: obj}))
            dispatch(actions.getcurrentjournalstatus(obj))
            return response
        })
        .catch((error) => {
            error.clientMessage = "Can't Post Job"
        })
}

export const AddWitness = (params, headers) => (dispatch) => {
    return requestFromServer
        .AddWitness(params, headers)
        .then((response) => {
            return response
        })
        .catch((error) => {
            error.clientMessage = "Can't switch Primary"
        })
}

export const makePrimary = (params, headers) => (dispatch) => {
    return requestFromServer
        .makePrimary(params, headers)
        .then((response) => {
            return requestFromServer
                .getbankAccount(headers)
                .then((response) => {
                    dispatch(actions.bankAccountFetched(response.data))
                })
                .catch((error) => {
                    error.clientMessage = "Can't find banks"
                })
        })
        .catch((error) => {
            error.clientMessage = "Can't switch Primary"
        })
}

export const fetchWitnessesforJournal = (journalID, headers) => (dispatch) => {
    return requestFromServer
        .getWitnessesforJournal(journalID, headers)
        .then((response) => {
            dispatch(actions.witnessessFetched(response.data.rows))
        })
        .catch((error) => {
            error.clientMessage = "Can't find witness"
        })
}

export const fetchMyPayments = (headers, queries) => (dispatch) => {
    return requestFromServer
        .getMyPayments(headers, queries)
        .then((response) => {
            dispatch(
                actions.MyPaymentsFetched({
                    data: response.data,
                    queries: queries,
                })
            )
        })
        .catch((error) => {
            error.clientMessage = "Can't find notaries"
        })
}

export const fetchBankAaccounts = (headers) => (dispatch) => {
    return requestFromServer
        .getbankAccount(headers)
        .then((response) => {
            dispatch(actions.bankAccountFetched(response.data))
        })
        .catch((error) => {
            error.clientMessage = "Can't find notaries"
        })
}

export const fetchNotaries = (headers) => (dispatch) => {
    return requestFromServer
        .findNotaries(headers)
        .then((response) => {
            dispatch(actions.notariesFetched(response.data.savedNotaries.rows))
        })
        .catch((error) => {
            error.clientMessage = "Can't find notaries"
        })
}

export const fetchFilteredNotaries = (queries, headers) => (dispatch) => {
    dispatch(actions.startFilteredNotariesCall())
    return requestFromServer
        .findFilteredNotaries(queries, headers)
        .then((response) => {
            dispatch(
                actions.filteredNotariesFetched({
                    notaries: response.data.savedNotaries,
                    onlineNotaries: response.data.activeNotary,
                })
            )
        })
        .catch((error) => {
            error.clientMessage = "Can't find notaries"
        })
}

export const fetchFilteredOnlineNotaries = (queries, headers) => (dispatch) => {
    dispatch(actions.startOnlineNotariesCall())
    return requestFromServer
        .findFilteredOnlineNotaries(queries, headers)
        .then((response) => {
            dispatch(
                actions.filteredOnlineNotariesFetched({
                    notaries: response.data.savedNotaries,
                    onlineNotaries: response.data.activeNotary,
                })
            )
        })
        .catch((error) => {
            error.clientMessage = "Can't find notaries"
        })
}

export const fetchAllClients = (headers) => (dispatch) => {
    return requestFromServer
        .findAllClients(headers)
        .then((response) => {
            dispatch(actions.clientsAllFetched(response.data))
        })
        .catch((error) => {
            error.clientMessage = "Can't find notaries"
        })
}

export const fetchClients = (headers, query) => (dispatch) => {
    return requestFromServer
        .findClients(headers, query)
        .then((response) => {
            dispatch(actions.clientsFetched(response.data))
        })
        .catch((error) => {
            error.clientMessage = "Can't find notaries"
        })
}

export const fetchAddBankAccount = (queries, headers, setNotification) => (
    dispatch
) => {
    return requestFromServer
        .AddBankAccount(queries, headers)
        .then((response) => {
            setNotification({
                show: true,
                msg: response.data.message,
                variant: 'success',
            })
            let bankdaccounts = response
            return requestFromServer
                .getbankAccount(headers)
                .then((response) => {
                    dispatch(actions.bankAccountFetched(response.data))
                    return bankdaccounts
                })
                .catch((error) => {
                    error.clientMessage = "Can't find notaries"
                })
        })
        .catch((error) => {
            error.clientMessage = "Can't add Bank Account"

            return error
        })
}

export const deleteDocument = (docID, headers, setNotification) => (
    dispatch
) => {
    return requestFromServer
        .deleteDocumentByID(docID, headers)
        .then(() => {
            dispatch(actions.deleteDocument(docID))
            setNotification({
                show: true,
                msg: 'Document successfully deleted.',
                variant: 'success',
            })
        })
        .catch((error) => {
            setNotification({
                show: true,
                msg: 'Unexpected error occurs while deleting document!',
                variant: 'error',
            })
            // error.clientMessage = "Can't find document";
        })
}

export const removeFromNotary = (id, headers, setNotification) => (
    dispatch
) => {
    return requestFromServer
        .removeFromNotary(id, headers)
        .then((response) => {
            return response
        })
        .catch((error) => {
            setNotification({
                show: true,
                msg: 'Unexpected error occurs while deleting document!',
                variant: 'error',
            })
            // error.clientMessage = "Can't find document";
        })
}

export const fetchDeletedJournals = (queries, headers, user) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list }))
    return requestFromServer
        .getAllDeletedJournals(queries, headers)
        .then((response) => {
            dispatch(
                actions.journalsDeletedFetched({
                    user: user,
                    data: response.data,
                    queries: queries,
                })
            )
            dispatch(actions.clearDocuments())
        })
        .catch((error) => {
            error.clientMessage = "Can't find journals"
            dispatch(actions.catchError({ error, callType: callTypes.list }))
            dispatch(actions.clearDocuments())
        })
}

export const fetchJournals = (
    defaultQuery,
    headers, 
    user, 
    isExport = false,
    queryParams = null, 
) => (dispatch) => {
    
    if( !isExport ) {
        dispatch(actions.startCall({ callType: callTypes.list }))
    }
    return requestFromServer
        .getAllJournals(defaultQuery, headers, queryParams)
        .then((response) => {
            if( !isExport ) {
                dispatch(
                    actions.journalsFetched({
                        user: user,
                        data: response.data,
                        queries: defaultQuery,
                        queryParams: queryParams,
                    })
                )
                dispatch(actions.clearDocuments())
            }
            else {
                return response.data.Journals
            }
        })
        .catch((error) => {
            error.clientMessage = "Can't find journals"
            dispatch(actions.catchError({ error, callType: callTypes.list }))
            dispatch(actions.clearDocuments())
        })
}

export const fetchJobPosts = (queries, headers, user, getResponse=false) => (dispatch) => {
    return requestFromServer
        .getAllJobpost(queries, headers)
        .then((response) => {
            // if( ! getResponse ) {
                dispatch(
                    actions.JobPostsFetched({
                        user: user,
                        data: response.data,
                        queries: queries,
                    })
                )
            // }
            // else {
                return response?.data?.jobs?.rows;
            // }
        })
        .catch((error) => {
            error.clientMessage = "Can't find journals"
            dispatch(actions.catchError({ error, callType: callTypes.list }))
        })
}

export const fetchMyNotaries = (queries, headers, user) => (dispatch) => {
    return requestFromServer
        .getAllMyNotaries(queries, headers)
        .then((response) => {
            dispatch(
                actions.MyNotariesFetched({
                    user: user,
                    data: response.data,
                    queries: queries,
                })
            )
        })
        .catch((error) => {
            error.clientMessage = "Can't find journals"
            dispatch(actions.catchError({ error, callType: callTypes.list }))
        })
}

export const getPaymentforExistingJournal = (queries, headers) => (
    dispatch
) => {
    return requestFromServer
        .getPaymentforExistingJournal(queries, headers)
        .then((response) => {
            //dispatch(actions.myPaymentforExistingJournal({  data: response.data, queries: queries }));

            if (response.data && !response.data.net30)
                dispatch(actions.defaultPaymentCardsFetched(response.data.card))
            else if (response.data && response.data.net30)
                dispatch(actions.defaultPaymentCardsFetched(null))

            dispatch(actions.defaultPaymentCardsFetched2(response.data))
        })
        .catch((error) => {
            error.clientMessage = "Can't find journals"
            dispatch(actions.catchError({ error, callType: callTypes.list }))
        })
}

export const fetchNotaryTypes = (headers) => (dispatch) => {
    return requestFromServer
        .getNotaryTypes(headers)
        .then((response) => {
            dispatch(actions.NotaryTypesFetched({ data: response.data }))
        })
        .catch((error) => {
            error.clientMessage = "Can't find journals"
            dispatch(actions.catchError({ error, callType: callTypes.list }))
        })
}

export const fetchCSVJournals = (queries, headers, user) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list }))

    return requestFromServer
        .getAllJournals(queries, headers)
        .then((response) => {
            dispatch(
                actions.journalsCSVFetched({
                    user: user,
                    data: response.data,
                    queries: queries,
                })
            )
            dispatch(actions.clearDocuments())
        })
        .catch((error) => {
            error.clientMessage = "Can't find journals"
            dispatch(actions.catchError({ error, callType: callTypes.list }))
            dispatch(actions.clearDocuments())
        })
}

export const fetchTop5Journals = (headers, user) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list }))
    return requestFromServer
        .getTop5Journals(headers)
        .then((response) => {
            dispatch(
                actions.top5JournalsFetched({ user: user, data: response.data })
            )
            dispatch(actions.clearDocuments())
        })
        .catch((error) => {
            error.clientMessage = "Can't find journals"
            dispatch(actions.catchError({ error, callType: callTypes.list }))
            dispatch(actions.clearDocuments())
        })
}

export const fetchPaymentCards = (queries, headers) => (dispatch) => {
    return requestFromServer
        .getAllPaymentCards(queries, headers)
        .then((response) => {
            dispatch(actions.paymentCardsFetched(response.data.rows))
        })
        .catch((error) => {
            error.clientMessage = "Can't find journals"
            dispatch(actions.catchError({ error, callType: callTypes.list }))
        })
}

export const finalizePayment = (
    params,
    headers,
    setErrMSG,
    enableLoading,
    disableLoading
) => (dispatch) => {
    //enableLoading();
    return requestFromServer
        .finalizePayment(params, headers)
        .then((response) => {
            //  disableLoading();
            return response
        })
        .catch((error) => {
            let erroMessage = error.response.data.message
            setErrMSG(erroMessage)
            //  disableLoading();
        })
}

export const fetchBankAccountTokens = (body, headers) => (dispatch) => {
    return requestFromServer
        .BankAccountTokens(body, headers)
        .then((response) => {
            return response
        })
        .catch((error) => {
            return error.response
        })
}

export const fetchPaymentBanks = (queries, headers) => (dispatch) => {
    return requestFromServer
        .getAllPaymentCards(queries, headers)
        .then((response) => {
            dispatch(actions.paymentCardsFetched(response.data.paymentMethods))
        })
        .catch((error) => {
            error.clientMessage = "Can't find journals"
            dispatch(actions.catchError({ error, callType: callTypes.list }))
        })
}

export const fetchDefaultPaymentCard = (queries, headers) => (dispatch) => {
    return requestFromServer
        .getDefaultPaymentCard(queries, headers)
        .then((response) => {
            dispatch(actions.defaultPaymentCardsFetched(response.data.card))
            dispatch(actions.defaultPaymentCardsFetched2(response.data))
        })
        .catch((error) => {
            error.clientMessage = "Can't find journals"
            dispatch(actions.catchError({ error, callType: callTypes.list }))
        })
}

export const fetchAdminNotaries = (headers, queryParams, defaultQuery) => (
    dispatch
) => {
    return requestFromServer
        .findAdminNotaries(headers, queryParams, defaultQuery)
        .then((response) => {
            dispatch(
                actions.notariesAdminFetched({
                    notaries: response.data.AllNotary,
                    queries: defaultQuery,
                    queryParams: queryParams,
                })
            )
        })
        .catch((error) => {
            error.clientMessage = "Can't find notaries"
        })
}

export const fetchAdminNotarizationForCSV = (
    headers,
    queryParams,
    defaultQuery,
    isExport = false
) => (dispatch) => {
    return requestFromServer
        .findAdminNotarization(headers, queryParams, defaultQuery, true)
        .then((response) => {
            if( !isExport ) {
                dispatch(
                    actions.notarizationAdminFetchedForCSV({
                        journals: response.data.journals,
                        queries: defaultQuery,
                        queryParams: queryParams,
                    })
                )
            }
            else {
                return response.data.journals;
            }
        })
        .catch((error) => {
            error.clientMessage = "Can't find notaries"
        })
}

export const fetchAdminNotarization = (
    headers,
    queryParams,
    defaultQuery,
    schedule = false,
    completed = false,
    progress = false
) => (dispatch) => {
    return requestFromServer
        .findAdminNotarization(headers, queryParams, defaultQuery)
        .then((response) => {
            if (schedule) {
                dispatch(
                    actions.notarizationAdminFetchedSchedule({
                        journals: response.data.journals,
                        queries: defaultQuery,
                        queryParams: queryParams,
                    })
                )
            } else if (completed)
                dispatch(
                    actions.notarizationAdminFetchedCompleted({
                        journals: response.data.journals,
                        queries: defaultQuery,
                        queryParams: queryParams,
                    })
                )
            else if (progress)
                dispatch(
                    actions.notarizationAdminFetchedProgress({
                        journals: response.data.journals,
                        queries: defaultQuery,
                        queryParams: queryParams,
                    })
                )
            else
                dispatch(
                    actions.notarizationAdminFetched({
                        journals: response.data.journals,
                        queries: defaultQuery,
                        queryParams: queryParams,
                    })
                )
        })
        .catch((error) => {
            error.clientMessage = "Can't find notaries"
        })
}

export const fetchAdminUser = (headers, queryParams, defaultQuery) => (
    dispatch
) => {
    return requestFromServer
        .fetchAdminUser(headers, queryParams, defaultQuery)
        .then((response) => {
            dispatch(
                actions.fetchAdminUser({
                    notaries: response.data.AllNotary,
                    queries: defaultQuery,
                    queryParams: queryParams,
                })
            )
        })
        .catch((error) => {
            error.clientMessage = "Can't find notaries"
        })
}

export const fetchNotaryStates = (headers, queryParams, defaultQuery) => (
    dispatch
) => {
    return requestFromServer
        .fetchNotaryStates(headers, queryParams, defaultQuery)
        .then((response) => {
            dispatch(
                actions.fetchNotaryStates({
                    states: response.data.states,
                    queries: defaultQuery,
                    queryParams: queryParams,
                })
            )
        })
        .catch((error) => {
            error.clientMessage = "Can't find states"
        })
}

export const fetchAllStates = (headers) => (dispatch) => {
    return requestFromServer
        .fetchAllStates(headers)
        .then((response) => {
            dispatch(actions.fetchAllStates({ states: response.data.states }))
        })
        .catch((error) => {
            error.clientMessage = "Can't find states"
        })
}

export const witnessGetJournals = (headers, queries) => (dispatch) => {
    return requestFromServer
        .witnessGetJournals(headers, queries)
        .then((response) => {
            dispatch(
                actions.getWitnessJournals({
                    witnesses: response.data,
                    queries: queries,
                })
            )
        })
        .catch((error) => {
            error.clientMessage = "Can't find notaries"
        })
}

export const acceptWitnessing = (headers, param) => (dispatch) => {
    return requestFromServer
        .acceptWitnessing(headers, param)
        .then((response) => {
            return response
        })
        .catch((error) => { })
}

export const acceptSchedule = (headers, param, journalID) => (dispatch) => {
    return requestFromServer
        .acceptSchedule(headers, param, journalID)
        .then((response) => {
            return response
        })
        .catch((error) => { })
}

export const bypassKBA = (params, headers) => (dispatch) => {
    return requestFromServer
        .bypassKBA(params, headers)
        .then((response) => {
            return response
        })
        .catch((error) => { })
}

export const bypassKBAAlways = (params, headers) => (dispatch) => {
    return requestFromServer
        .bypassKBAAlways(params, headers)
        .then((response) => {
            return response
        })
        .catch((error) => { })
}

export const requestpayoutrequest = (
    params,
    headers,
    setShowSuccessMSG,
    setShowErrorMSG,
    enableLoading,
    disableLoading
) => (dispatch) => {
    enableLoading()
    return requestFromServer
        .requestPayoutCall(params, headers)
        .then((response) => {
            disableLoading()
            dispatch(actions.requestPayout(response))
            let successMsg = response.data.payout.status
            setShowSuccessMSG({
                success: true,
                msg: successMsg,
                variant: 'success',
            })
            window.location.reload()
        })
        .catch((err) => {
            disableLoading()
            let erroMessage = err.response.data.message
            setShowErrorMSG({ error: true, msg: erroMessage, variant: 'error' })
        })
}

export const updateNotaryStatus = (
    params,
    headers,
    queryParams,
    defaultQuery
) => (dispatch) => {
    dispatch(actions.changeActiveStatus({ id: params.userId, laoding: true }))
    return requestFromServer
        .updateNotaryStatus(params, headers)
        .then(() => {
            dispatch(actions.changeActiveStatus({ id: '', loading: false }))
            return requestFromServer
                .findAdminNotaries(headers, queryParams, defaultQuery)
                .then((response) => {
                    dispatch(
                        actions.notariesAdminFetched({
                            notaries: response.data.AllNotary,
                            queries: defaultQuery,
                            queryParams: queryParams,
                        })
                    )
                })
                .catch((error) => {
                    error.clientMessage = "Can't find notaries"
                })
            // dispatch(actions.statusNotaryUpdated(response));
        })
        .catch((error) => {
            error.clientMessage = "Can't find notaries"
        })
}
export const deleteCard = (
    body,
    headers,
    setNotification,
    setChangeMethod = null
) => (dispatch) => {
    return requestFromServer
        .deleteCard(body, headers)
        .then((response) => {
            if (response.data.status == 'ok')
                setTimeout(function () {
                    window.location.reload()
                }, 3000)

            setNotification({
                show: true,
                msg: 'Card deleted successfully',
                variant: 'success',
            })
        })
        .catch((error) => {
            setNotification({
                show: true,
                msg: 'Unexpected error occurs while setting card to default!',
                variant: 'error',
            })
        })
}

export const setNetDefaultPaymentCard = (
    body,
    headers,
    setNotification,
    setChangeMethod = null
) => (dispatch) => {
    return requestFromServer
        .makeNetDefaultPaymentCard(body, headers)
        .then((response) => {
            setNotification({
                show: true,
                msg: 'Card set to default successfully',
                variant: 'success',
            })
            //dispatch(actions.cardDefaulted(response.data));
            if (setChangeMethod !== null) {
                setChangeMethod(false)
            }
        })
        .catch((error) => {
            setNotification({
                show: true,
                msg: 'Unexpected error occurs while setting card to default!',
                variant: 'error',
            })
        })
}

export const setDefaultPaymentCard = (
    body,
    headers,
    setNotification,
    setChangeMethod = null
) => (dispatch) => {
    return requestFromServer
        .makeDefaultPaymentCard(body, headers)
        .then((response) => {
            setNotification({
                show: true,
                msg: 'Card set to default successfully',
                variant: 'success',
            })
            dispatch(actions.cardDefaulted(response.data))
            if (setChangeMethod !== null) {
                setChangeMethod(false)
            }
        })
        .catch((error) => {
            setNotification({
                show: true,
                msg: 'Unexpected error occurs while setting card to default!',
                variant: 'error',
            })
        })
}

export const setPrimaryNotary = (
    body,
    headers,
    setNotification,
    setSelectPrimaryNotary,
    role,
) => (dispatch) => {
    return requestFromServer
        .makePrimaryNotary(body, headers)
        .then((response) => {
            if( role !== 'admin' ) {
                dispatch(actions.primaryNotarySelected(response.data.primaryNotary))
                window.location.href = '/#/user-profile'
            }
            else{
                window.location.href = '/#/myadminuser'
            }

            setNotification({
                show: true,
                msg: 'successfully maked primary notary',
                variant: 'success',
            })
            setSelectPrimaryNotary(false)
        })
        .catch((error) => {
            setNotification({
                show: true,
                msg: 'Unexpected error occurs while making primary notary!',
                variant: 'error',
            })
        })
}

export const setSelectedNotary = (body, headers, setNotification) => (
    dispatch
) => {
    return requestFromServer
        .selectNotary(body, headers)
        .then((response) => {
            setNotification({
                show: true,
                msg: 'successfully selected notary',
                variant: 'success',
            })
            return 1
        })
        .catch((error) => {
            console.table({ error })
            setNotification({
                show: true,
                msg: error.response.data.message,
                variant: 'error',
            })
            return error.response.data.message
        })
}

export const getBankDetails = (headers, setChangeMethod = null) => (
    dispatch
) => {
    return requestFromServer
        .getBankDetails(headers)
        .then((response) => {
            dispatch(actions.BankDetailsFetched(response.data.balance))
        })
        .catch((error) => { })
}

export const payPlatformFee = (
    body,
    headers,
    { setPaid, setPayNowModal, setCstmDisable, setNotification }
) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.paynow, action: true }))
    return requestFromServer
        .makePaymentOfPlatformFee(body, headers)
        .then((response) => {
            dispatch(actions.PlatformFeePaid(response.data))
            setPaid(true)
            setPayNowModal(false)
            setCstmDisable('disabled')
            setNotification({
                show: true,
                msg: 'Platform fee successfully paid.',
                variant: 'success',
            })
            return response.data.id
        })
        .catch((error) => {
            setNotification({
                show: true,
                msg: error.response.data.message,
                variant: 'error',
            })
            setPaid(false)
            setCstmDisable('')
            dispatch(
                actions.startCall({ callType: callTypes.paynow, action: false })
            )
        })
}

export const approvedClients = (headers) => (dispatch) => {
    // dispatch(actions.startCall({ callType: callTypes[status] }));
    return requestFromServer
        .getAllMyClients(headers)
        .then((response) => {
            dispatch(actions.clientsApproved(response))
        })
        .catch((error) => {
            //setNotification({ show: true, msg: 'Unexpected error occurs while adding notarization!', variant: 'error' });
        })
}

export const approvedMyNotaries = (
    body,
    headers,
    success,
    setNotification,
    status = null
) => (dispatch) => {
    // dispatch(actions.startCall({ callType: callTypes[status] }));
    return requestFromServer
        .approvedMyNotaries(body, headers)
        .then((response) => {
            //dispatch(actions.claimNowAdded(journalID));

            return response
        })
        .catch((error) => {
            setNotification({
                show: true,
                msg: 'Unexpected error occurs while adding notarization!',
                variant: 'error',
            })
        })
}

export const claimNow = (
    journalID,
    headers,
    success,
    setNotification,
    status = null
) => (dispatch) => {
    // dispatch(actions.startCall({ callType: callTypes[status] }));
    return requestFromServer
        .claimNow(journalID, headers)
        .then((response) => {
            //dispatch(actions.claimNowAdded(journalID));
            success(true)
            return { id: journalID }
        })
        .catch((error) => {
            setNotification({
                show: true,
                msg: 'This Job is already Claimed.',
                variant: 'error',
            })
        })
}

export const UpdateNotarization = (
    journalID,
    body,
    headers,
    success,
    setNotification,
    status = null
) => (dispatch) => {
    // dispatch(actions.startCall({ callType: callTypes[status] }));
    return requestFromServer
        .updateNotarization(journalID, body, headers)
        .then((response) => {
            dispatch(actions.notarizationAdded(response.data.id))
            if (status === 'payment') {
                success(false)
            } else {
                dispatch(actions.clearDocuments())
                dispatch(actions.clearPayment())
                success(true)
            }
            return { id: response.data.id, journal: response.data.journal }
        })
        .catch((error) => {
            setNotification({
                show: true,
                msg: 'Unexpected error occurs while adding notarization!',
                variant: 'error',
            })
        })
}

export const addNewNotarization = (
    body,
    headers,
    success,
    setNotification,
    status = null
) => (dispatch) => {
    // dispatch(actions.startCall({ callType: callTypes[status] }));
    return requestFromServer
        .createNotarization(body, headers)
        .then((response) => {
            dispatch(actions.notarizationAdded(response.data.id))
            if (status === 'payment') {
                success(false)
            } else if (status === 'job') {
                success(false)
            } else {
                dispatch(actions.clearDocuments())
                dispatch(actions.clearPayment())
                success(true)
            }
            return { id: response.data.id, journal: response.data.journal }
        })
        .catch((error) => {
            setNotification({
                show: true,
                msg: error.response.data.message,
                variant: 'error',
            })
        })
}

export const fetchPrimaryNotary = (headers) => (dispatch) => {
    return requestFromServer
        .getPrimaryNotary(headers)
        .then((response) => {
            let biometricSettings = response.data.biometricSettings
            let notaryObj = Object.assign(response.data.notary, {
                biometricSettings,
            })
            dispatch(actions.primaryNotaryFetched(notaryObj))
            return response.data.notary
        })
        .catch((error) => { })
}

export const addPaymentCard = (body, headers, setModal, setNotification) => (
    dispatch
) => {
    dispatch(actions.startCall({ callType: callTypes.payment, action: true }))
    return requestFromServer
        .addPaymentCardCall(body, headers)
        .then((response) => {
            setModal(false)
            dispatch(actions.paymentCardAdded(response.data.card))
            setNotification({
                show: true,
                msg: 'Card successfully added.',
                variant: 'success',
            })
        })
        .catch((error) => {
            setModal(false)
            dispatch(
                actions.startCall({
                    callType: callTypes.payment,
                    action: false,
                })
            )
            setNotification({
                show: true,
                msg: 'Unexpected error occurs while adding card!',
                variant: 'error',
            })
        })
}

export const fetchPaymentDetails = (headers) => (dispatch) => {
    return requestFromServer
        .getPaymentDetails(headers)
        .then((response) => {
            dispatch(actions.paymentDetailsFetched(response.data.details))
        })
        .catch((error) => { })
}

export const updateDocuments = (docs) => (dispatch) => {
    dispatch(actions.documentUpdated(docs))
}

export const getJournal = (journalID, headers) => (dispatch) => {
    return requestFromServer
        .checkSignerinjournal(journalID, headers)
        .then((response) => {
            return response
        })
        .catch((error) => {
            error.clientMessage = "Can't find notaries"
        })
}

export const getLanguages = (headers) => (dispatch) => {
    return requestFromServer
        .getLanguages(headers)
        .then((response) => {
            dispatch(actions.getLanguagesDetail(response.data))
        })
        .catch((error) => {
            error.clientMessage = "Can't find notaries"
        })
}

export const getJournalbyID = (journalID, headers, isreturn = 0) => (
    dispatch
) => {
    return requestFromServer
        .checkSignerinjournal(journalID, headers)
        .then((response) => {
            if (isreturn == 1) return response.data
            else dispatch(actions.getSingleJournalDetail(response.data))
        })
        .catch((error) => {
            error.clientMessage = "Can't find notaries"
        })
}

export const checkJournal = (journalID, headers, forURL = '', onlyUpdateJournal = false) => (dispatch) => {
    let queryParams = forURL == '' ? '' : '?signerdata=true'; //if get journal by id is from signwitness page, add signerstatus query param. 
    return requestFromServer
        .checkSignerinjournal(journalID, headers, queryParams)
        .then((response) => {
            dispatch(actions.getAllJournalDetails(response.data))
            
            if(onlyUpdateJournal){
                
            }
            else if (!forURL){
                dispatch(actions.getDocuments(response.data.journal.journalDocs))
                dispatch(actions.getcurrentjournalstatus(response.data.journal))
            }
            else {
                dispatch(actions.getDocuments(response.data.journal.journalDocs))
                if (response.data.journal.agreementId) {
                    return requestFromServer
                        .doneWitness(headers, journalID)
                        .then((response) => {
                            let url
                            if (response.data.updatedUrl)
                                url = response.data.updatedUrl.agreementViewList.filter(
                                    (x) =>
                                        x.name.toLowerCase() == 'post_create' &&
                                        x.isCurrent
                                ).length
                                    ? response.data.updatedUrl.agreementViewList.filter(
                                        (x) =>
                                            x.name.toLowerCase() == 'manage'
                                    )[0].url
                                    : response.data.updatedUrl.agreementViewList.filter(
                                        (x) => x.isCurrent
                                    )[0].url
                            else url = response.data.agreementUrl

                            dispatch(actions.doneWitness(url))
                        })
                        .catch((error) => {
                            error.clientMessage = "Can't find notaries"
                        })
                } else dispatch(actions.doneWitness(''))
            }
        })
        .catch((error) => {
            error.clientMessage = "Can't find notaries"
        })
}

export const getJitsiMeetingObj = (journalID, headers) => (dispatch) => {
    return requestFromServer
    .getJitsiMeetingObj(journalID, headers)
    .then((response) => {
        let jwt = response.data;
        dispatch(actions.getJitsiMeetingJWT(jwt))

    })
    .catch((error) => {
        console.log({error});
        
    })
}

export const getJitsiMeetingLinks = (journalID, headers) => (dispatch) => {
    return requestFromServer
    .getJitsiMeetingLinks(journalID, headers)
    .then((response) => {
        let links = response.data.links;
        dispatch(actions.getJitsiMeetingLinks(links))

    })
    .catch((error) => {
        console.log({error});
        
    })
}

export const emailMeetingLink = (headers, journalID) => (dispatch) => {
    return requestFromServer
        .emailMeetingLink(journalID, headers)
        .then((response) => {
            if (response.data.meeting && response.data.meeting.jitsiUrl)
                return response.data.meeting.jitsiUrl
            else return 'invalid'
        })
        .catch((error) => {
            error.clientMessage = "Can't find notaries"
        })
}
export const checkSigner = (journalID, headers) => (dispatch) => {
    return requestFromServer
        .checkSignerinjournal(journalID, headers)
        .then((response) => {
            dispatch(actions.getcurrentsigners(response.data.journal.Signers))
        })
        .catch((error) => {
            error.clientMessage = "Can't find notaries"
        })
}

export const rejectNotarization = (headers, journalID) => (dispatch) => {
    return requestFromServer
        .rejectNotarization(journalID, headers)
        .then((response) => {
            return response
        })
        .catch((error) => {
            error.clientMessage = "Can't find notaries"
        })
}

export const acceptNotarization = (headers, journalID, ip) => (dispatch) => {
    return requestFromServer
        .acceptNotarization(journalID, headers, ip)
        .then((response) => {
            return response
        })
        .catch((error) => {
            error.clientMessage = "Can't find notaries"
        })
}

export const doneWitness = (
    headers,
    journalID,
    setNotification,
    enableLoading,
    disableLoading
) => (dispatch) => {
    //  enableLoading()
    return requestFromServer
        .doneWitness(headers, journalID)
        .then((response) => {
            dispatch(actions.doneWitness(response.data.agreementUrl))
            //    disableLoading();
        })
        .catch((error) => {
            let MSG = error.response.data.msg
            setNotification({ show: true, msg: MSG, variant: 'error' })
            // disableLoading();
        })
}

export const generateCertificateAction = (headers, forms, journalId) => (
    dispatch
) => {
    return requestFromServer
        .generateCertificateCall(headers, forms, journalId)
        .then((response) => {
            window.location.reload()
            return response.data
        })
        .catch((error) => {
            error.clientMessage = "Can't find notaries"
        })
}

export const fetchAuditTrail = (id, headers) => (dispatch) => {
    return requestFromServer
        .getAuditTrail(id, headers)
        .then((response) => {
            return response.data.auditTrail
        })
        .catch((error) => {
            error.clientMessage = "Can't find notaries"
        })
}

export const getTemplateAgreemURL = (userID, headers, agreementId) => (
    dispatch
) => {
    return requestFromServer
        .getTemplateAgreemURL(userID, headers, agreementId)
        .then((response) => {
            if (
                response.data &&
                response.data.userUrl &&
                response.data.userUrl.agreementViewList &&
                response.data.userUrl.agreementViewList[0] &&
                response.data.userUrl.agreementViewList[0].url
            ) {
                let url = response.data.userUrl.agreementViewList.filter(
                    (x) => x.name.toLowerCase() == 'post_create' && x.isCurrent
                ).length
                    ? response.data.userUrl.agreementViewList.filter(
                        (x) => x.name.toLowerCase() == 'manage'
                    )[0].url
                    : response.data.userUrl.agreementViewList.filter(
                        (x) => x.isCurrent
                    )[0].url

                /*	let ismanagefound = 0;
        for(var ch=0; ch < response.data.userUrl.agreementViewList.length; ch++)
        {
        	
            if(response.data.userUrl.agreementViewList[ch].name == "MANAGE")
            ismanagefound = ch;
            	
        }response.data.userUrl.agreementViewList[ismanagefound].url
	
        */

                dispatch(actions.doneAgreementTemplate(url))
            }
        })
        .catch((error) => {
            error.clientMessage = "Can't find URL"
        })
}

export const getTemplateURL = (userID, headers) => (dispatch) => {
    return requestFromServer
        .getTemplateURL(userID, headers)
        .then((response) => {
            if (
                response.data &&
                response.data.userUrl &&
                response.data.userUrl.userViewList &&
                response.data.userUrl.userViewList[0] &&
                response.data.userUrl.userViewList[0].url
            ) {
                dispatch(
                    actions.doneTemplate(
                        response.data.userUrl.userViewList[0].url
                    )
                )
            } else if (response.data && response.data.unauthorized) {
                dispatch(actions.doneTemplateunauthorized(response.data.url))
            }
        })
        .catch((error) => {
            error.clientMessage = "Can't find URL"
        })
}

export const deleteCertificateDocumentByID = (journalId, headers) => (
    dispatch
) => {
    return requestFromServer
        .deleteCertificateDocumentByID(journalId, headers)
        .then((response) => {
            window.location.reload()
        })
        .catch((error) => { })
}

export const deleteSoftNotarization = (checkarr, headers) => (dispatch) => {
    return requestFromServer
        .deleteSoftNotarization({ deleteIds: checkarr }, headers)
        .then((response) => {
            setTimeout(() => {
                window.location.reload()
            }, 2000)
        })
        .catch((error) => { })
}

export const deleteHardNotarization = (checkarr, headers) => (dispatch) => {
    return requestFromServer
        .deleteHardNotarization({ deleteIds: checkarr }, headers)
        .then((response) => {
            setTimeout(() => {
                window.location.reload()
            }, 2000)
        })
        .catch((error) => { })
}

export const restoreNotarization = (checkarr, headers) => (dispatch) => {
    return requestFromServer
        .restoreNotarization({ restoreIds: checkarr }, headers)
        .then((response) => {
            window.location.reload()
        })
        .catch((error) => { })
}

export const cancelNotarizationAction = (
    headers,
    value,
    journalId,
    setNotification,
    enableLoading,
    disableLoading,
    alreadyOnDashboard
) => (dispatch) => {
    enableLoading()
    return requestFromServer
        .cancelNotarizationCall(journalId, value, headers)
        .then((response) => {
            let SuccessMDG = response.data.message
            setNotification({ show: true, msg: SuccessMDG, variant: 'success' })
            if (alreadyOnDashboard) {
                window.location.reload()
            } else {
                window.location.href = '/#/dashboard'
            }
            disableLoading()
        })
        .catch((error) => {
            let MSG = error.response.data.message
            setNotification({ show: true, msg: MSG, variant: 'error' })
            disableLoading()
        })
}

export const deleteAdminUser = (id, headers) => (dispatch) => {
    return requestFromServer
        .deleteAdminUser(id, headers)
        .then((response) => {
            window.location.reload()
        })
        .catch((error) => {
            error.clientMessage = "Can't find document"
        })
}

export const enableStateBiometric = (id, biometricEnabled, headers) => (
    dispatch
) => {
    return requestFromServer
        .enableStateBiometric(id, biometricEnabled, headers)
        .then((response) => {
            window.location.reload()
        })
        .catch((error) => {
            error.clientMessage = "Can't find document"
        })
}

export const tranferNotarization = (id, headers) => (dispatch) => {
    return requestFromServer
        .tranferNotarization(id, headers)
        .then((response) => {
            return response
        })
        .catch((error) => { })
}

export const GetIdProofingDetail = (body) => (dispatch) => {
    return requestFromServer
        .GetIdProofingDetail(body)
        .then((response) => {
            dispatch(actions.signerIdProofingDetails(response.data))
        })
        .catch((error) => {
            dispatch(actions.signerIdProofingDetails(error.response.data))
        })
}

export const signerLinkVerification = (body) => (dispatch) => {
    return requestFromServer
        .signerLinkVerification(body)
        .then((response) => {
            return response
        })
}

export const sendLinksViaEmail = (body) => (dispatch) => {
    return requestFromServer
        .sendLinksViaEmail(body)
        .then((response) => {
            return response
        })
}

export const sendNotarizationPdf = (body, headers) => (dispatch) => {
    return requestFromServer
        .sendNotarizationPdf(body, headers)
        .then((response) => {
            return response
        })
}

export const sendAdminNotarizationPdf = (body, headers) => (dispatch) => {
    return requestFromServer
        .sendAdminNotarizationPdf(body, headers)
        .then((response) => {
            return response
        })
}

export const requestAccountAccess = (headers) => (dispatch) => {
    return requestFromServer
        .requestAccountAccess(headers)
        .then((response) => {
            return response
        })
}

export const overridePreferenceFilter = (body, headers) => (dispatch) => {
    return requestFromServer
        .overridePreferenceFilter(body, headers)
        .then((response) => {
            return response
        })
}

export const getNet30 = (id, headers) => (dispatch) => {
    return requestFromServer
        .getNet30(id, headers)
        .then((response) => {
            return response
        })
}

export const changeNet30Status = (body, headers) => (dispatch) => {
    return requestFromServer
        .changeNet30Status(body, headers)
        .then((response) => {
            return response
        })
}

export const saveIdologySettings = (body, headers) => (dispatch) => {
    return requestFromServer
        .saveIdologySettings(body, headers)
        .then((response) => {
            return response
        })
}

export const updateClaimJobBoardStatus = (id, body, headers, queryParams, defaultQuery) => (dispatch) => { 
    return requestFromServer
        .updateClaimJobBoardStatus(id, body, headers)
        .then((response) => {
            console.log(response)
            return requestFromServer
                .findAdminNotaries(headers, queryParams, defaultQuery)
                .then((response) => {
                    dispatch(
                        actions.notariesAdminFetched({
                            notaries: response.data.AllNotary,
                            queries: defaultQuery,
                            queryParams: queryParams,
                        })
                    )
                })
                .catch((error) => {
                    error.clientMessage = "Can't find notaries"
                })
        })
}

export const newJobPostedNotification = (body, headers) => (dispatch) => { 
    return requestFromServer
        .newJobPostedNotification(body, headers)
        .then((response) => {
           console.log(response);
        })
}