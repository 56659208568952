import { Divider, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../components/_redux/notarization_redux/notarizationActions";
import ShowNotification from "../../../components/new_notarization/ShowNotification";
import '../../../pages/CSS/Style.css'

const useStyles = makeStyles(() => ({
  draft: {
    minWidth: "110px",
    backgroundColor: "#524440",
    color: "#fff",
    textAlign: "center",
    borderRadius: "5px",
    padding: "9px 5px"
  },
  progress: {
    minWidth: "110px",
    backgroundColor: "#eee",
    color: "#000",
    textAlign: "center",
    borderRadius: "5px",
    padding: "9px 5px"
  },
  completed: {
    minWidth: "110px",
    backgroundColor: "#bef2de",
    color: "#000",
    textAlign: "center",
    borderRadius: "5px",
    padding: "9px 5px"
  },
  scheduled: {
    minWidth: "110px",
    backgroundColor: "#ff9a00",
    color: "#fff",
    textAlign: "center",
    borderRadius: "5px",
    padding: "9px 5px"
  },
  initiated: {
    minWidth: "110px",
    backgroundColor: "#ffff83",
    color: "#000",
    textAlign: "center",
    borderRadius: "5px",
    padding: "9px 5px"
  },
  pending: {
    minWidth: "110px",
    backgroundColor: "#4ABD54",
    color: "#fff",
    textAlign: "center",
    borderRadius: "5px",
    padding: "9px 5px"
  },
  paid: {
    minWidth: "110px",
    backgroundColor: "#4ABD54",
    color: "#fff",
    textAlign: "center",
    borderRadius: "5px",
    padding: "9px 5px"
  },
  failed: {
    minWidth: "110px",
    backgroundColor: "#EA001B",
    color: "#fff",
    textAlign: "center",
    borderRadius: "5px",
    padding: "9px 5px"
  },
  alignCenter: {
    textAlign: "center"
  },
  buttonStyle: {
    backgroundColor: "#1840d2",
    borderColor: "#1840d2",
    minWidth: "165px",
    float: "right",
    marginBottom: "10px"
  }
}));

export function CustomerEditForm({
  onHide,
  id,
  datam,
  completed,
  schedule,
  openClaimPopup,
  claimWitnessJob
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { socket, auth, notarization } = useSelector(state => state);
  const [signerLinks, setsignerLinks] = useState({});
  const [copyLinkURL, setcopyLinkURL] = useState("");
  const [bypassReason, setbypassReason] = useState("");
  const [data, setdata] = useState(null);
  const [isbypasskba, setisbypasskba] = useState(0);
  const {
    allAdminNotarization,
    paymentDetails,
    SingleJournalObj
  } = notarization;
  const [notification, setNotification] = useState({
    show: false,
    msg: "",
    variant: ""
  });

  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  useEffect(() => {
    if (SingleJournalObj != null) {
      setdata(SingleJournalObj);
    }
  }, [SingleJournalObj]);

  const prepareDate = dt => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    let date = new Date(dt);
    let time = formatAMPM(date);
    let mon = months[date.getMonth()];
    let curDate = date.getDate();
    let year = date.getFullYear();
    let timezone = seconds_with_leading_zeros(date);
    return `${mon} ${curDate}, ${year}`;
  };

  const prepareTime = dt => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    let date = new Date(dt);
    let time = formatAMPM(date);
    let mon = months[date.getMonth()];
    let curDate = date.getDate();
    let year = date.getFullYear();
    let timezone = seconds_with_leading_zeros(date);
    return `${time} (${timezone})`;
  };

  const formatAMPM = date => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  const seconds_with_leading_zeros = dt => {
    return /\((.*)\)/.exec(dt.toString())[1];
  };

  const copytheLink = id => {
    dispatch(actions.copyLink(id, headers)).then(data => {
      if ("error" == data.status) {
        setNotification({
          show: true,
          msg: "You cannot copy Link. Link is already Copied.",
          variant: "error"
        });
        setcopyLinkURL(document.getElementById("copy" + id).innerHTML);
      } else copyLink(id);
    });
  };
  const copyLink = id => {
    let el = document.createElement("textarea");

    let signLink = document.getElementById("copy" + id).innerHTML;
    el.value = signLink.replace(/&amp;/g, "&");
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    //	document.getElementById("copybtn"+id).innerHTML = "Copied";

    let tempstr = copyLinkURL;
    tempstr += "|" + el.value;
    setcopyLinkURL(tempstr);

    //setTimeout(function(){ document.getElementById("copybtn"+id).innerHTML = getTempLinkTitle(el.value); }, 3000);
  };

  const bypassKBAMode = id => {
    setisbypasskba(id);
  };

  const bypassKBAfinalize = () => {
    let body = {
      signerId: isbypasskba,
      bypassReason: bypassReason,
      journalId: data.id
    };

    const headers = {
      Authorization: `Bearer ${auth.authToken}`,
      "Content-Type": "application/json",
      Accept: "application/json"
    };

    let journalID = data.id;

    dispatch(actions.bypassKBA(body, headers)).then(data => {
      setisbypasskba(0);

      dispatch(actions.reloadcall(journalID));
    });
  };

  const setReason = e => {
    setbypassReason(e.target.value);
  };

  const getCurrentLink = obj => {
    let temporaryLink = obj.temporaryLink;
    if (
      socket.idologyLink != null &&
      socket.idologyLink["signerId"] == obj.id
    ) {
      if (!signerLinks[obj.id]) {
        let currentsigners = signerLinks;
        currentsigners[obj.id] = socket.idologyLink["link"];
        setsignerLinks(currentsigners);
      }

      temporaryLink = socket.idologyLink["link"];
    } else if (signerLinks[obj.id]) temporaryLink = signerLinks[obj.id];

    return temporaryLink;
  };

  const getTempLinkTitle = obj => {
    let linkhref = getCurrentLink(obj);
    if (obj.biometric) return "Copy Biometric Link";
    if (linkhref) {
      if (linkhref.indexOf("idologylive") > -1) return "Copy ID Upload Link";
      else return "Copy KBA Link";
    }
  };

  const setSignerLink = obj => {
    let signLink = getCurrentLink(obj);
    return signLink.replace(/&amp;/g, "&");
  };

  const proceedNotarization = journalID => {
    window.location.href = "#/signwitness/?id=" + journalID;
  };

  function GetFinalPayment(seals, witnessCount) {
    let total =
      seals === 0
        ? 0
        : paymentDetails.sealFirstFee + (seals - 1) * paymentDetails.sealSecFee;
    let notaryFee =
      seals === 0
        ? 0
        : paymentDetails.notaryFeePerFirstSeal +
          (seals - 1) * paymentDetails.notaryFeePerSecSeal;
    let platformFee =
      seals === 0
        ? 0
        : paymentDetails.platformPerFirstSeal +
          (seals - 1) * paymentDetails.platformPerSecSeal;
    let witnessFee =
      witnessCount === 0 ? 0 : witnessCount * paymentDetails.witnessFee;
    total += witnessFee;

    return {
      total: total,
      notaryFee: notaryFee,
      platformFee: platformFee,
      witnessFee: witnessFee
    };
  }

  let paymentBreakDown = null;
  let filteredWitnesses = [];
  if (data !== null) {
    filteredWitnesses =
      data.Witnesses.length > 0
        ? data.Witnesses.filter(el => el.status !== "rejected")
        : [];
    paymentBreakDown = GetFinalPayment(
      data.sealCount !== null ? data.sealCount : 0,
      filteredWitnesses.length
    );
    //paymentBreakDown = 0;
  }

  const joinMeeting = meetingURL => {
    window.top.location.href = meetingURL;
  };

  let rejectedDate = null;
  let nowDate = Date.parse(new Date());
  if (data && data.rejectedUntil !== null) {
    rejectedDate = Date.parse(data.rejectedUntil);
  }

  let totalPrice = "--";
  if (data !== null && data.Payments.length > 0) {
    totalPrice = 0;
    totalPrice += data.Payments[0].amount / 100;
    totalPrice += paymentBreakDown.total;
    totalPrice = "$" + totalPrice.toFixed(2);
  }

  const getRecordingName = recordingType => {
    let recordingTypestr = recordingType.replace(/_/g, " ");
    return recordingTypestr.toLowerCase().replace(/\b[a-z]/g, function(letter) {
      return letter.toUpperCase();
    });
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") return;
    setNotification({ show: false, msg: "", variant: "" });
  };

  const resumeNotarizationselectNotary = id => {
    window.location.href = "/#/new-notarization?preselectNotarization=" + id;
  };

  let jobType =
    data !== null &&
    data !== undefined &&
    "Awaiting Witnesses" == data.subStatus
      ? "witness"
      : "notarization";

  return (
    <>
      {notification.show && (
        <ShowNotification
          show={notification.show}
          msg={notification.msg}
          variant={notification.variant}
          close={handleCloseNotification}
        />
      )}
      {SingleJournalObj != null ? (
        <Modal.Body className="overlay overlay-block cursor-default">
          {data !== null && data !== undefined && (
            <>
              <Table responsive borderless={true}>
                <thead>
                  <tr>
                    <th className="text-center">NOTARIZATION&nbsp;DATE</th>
                    <th className="text-center">NOTARIZATION&nbsp;TIME</th>
                    <th className="text-center">&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center">
                      {data.scheduledAt !== null ? (
                        <p className="mb-0">{prepareDate(data.scheduledAt)}</p>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td className="text-center">
                      {data.scheduledAt !== null ? (
                        <p className="mb-0">{prepareTime(data.scheduledAt)}</p>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td>
                      { ( auth.user.Role.code !== "notary" || (auth.user.Role.code === "notary" && !auth.user.notaryProfile.isCancelled ) ) &&
                        <div>
                          
                          <a
                            className={`btn btn-primary ${(data.user.notaryProfile.claimJob) ? "" : "btn-claim-job-disabled"}`} 
                            onClick={(data.user.notaryProfile.claimJob) ? () => openClaimPopup(data.id, jobType) : () => {}}
                          >
                            
                            
                            Claim
                          </a>
                          </div>
                      }
                    </td>
                  </tr>
                </tbody>
              </Table>
              <Divider />
              <Table responsive borderless={true}>
                <thead>
                  <tr>
                    <th>City</th>
                    <th className="text-center">State</th>
                    <th className="text-center">Zip</th>
                    <th className="text-center">Language</th>
                    <th className="text-center">No. of Witnesses Required</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {data.Signers && data.Signers[0] && data.Signers[0].city}
                    </td>
                    <td className="text-center">
                      {data.Signers && data.Signers[0] && data.Signers[0].state}
                    </td>
                    <td className="text-center">
                      {data.Signers && data.Signers[0] && data.Signers[0].zip}
                    </td>

                    <td className="text-center">
                      {data.journalLangs &&
                        data.journalLangs.map(el => {
                          return (
                            <>
                              {el.Language ? el.Language.name : ""}
                              <br />
                            </>
                          );
                        })}
                    </td>
                    <td className="text-center">{data.witnessCount}</td>
                  </tr>
                </tbody>
              </Table>

              <Divider />

              <Table responsive borderless={true}>
                <thead>
                  <tr>
                    <th>DOCUMENT&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {(data.status == "rescheduled" ||
                      data.status == "scheduled") &&
                    auth.user.Role.code === "notary" ? (
                      <>
                        {data.journalDocs ? (
                          <td>
                            {data.journalDocs.map(el => (
                              <p className="wrapWord" key={el.id}>
                                <a
                                  href={el.File.baseUrl + "/" + el.File.path}
                                  target="_blank"
                                >
                                  {el.File.filename
                                    .substring(14)
                                    .split(" ")
                                    .map(el => (
                                      <>{el}&nbsp;</>
                                    ))}
                                </a>{" "}
                                <span>
                                  {el.docType ? <> {el.docType} </> : ""}
                                </span>{" "}
                              </p>
                            ))}
                          </td>
                        ) : (
                          <td>
                            {data.documents.map(el => (
                              <p className="wrapWord" key={el.id}>
                                {el.filename
                                  .substring(14)
                                  .split(" ")
                                  .map(el => (
                                    <>{el}&nbsp;</>
                                  ))}
                              </p>
                            ))}
                          </td>
                        )}
                      </>
                    ) : (
                      <>
                        {data.journalDocs ? (
                          <td>
                            {data.journalDocs.map(el => (
                              <p className="wrapWord" key={el.id}>
                                {el.File.filename
                                  .substring(14)
                                  .split(" ")
                                  .map(el => (
                                    <>{el}&nbsp;</>
                                  ))}{" "}
                                <span>
                                  {el.docType ? <>({el.docType})</> : ""}
                                </span>
                              </p>
                            ))}
                          </td>
                        ) : (
                          <td>
                            {data.documents.map(el => (
                              <p className="wrapWord" key={el.id}>
                                {el.filename
                                  .substring(14)
                                  .split(" ")
                                  .map(el => (
                                    <>{el}&nbsp;</>
                                  ))}
                              </p>
                            ))}
                          </td>
                        )}
                      </>
                    )}
                  </tr>
                </tbody>
              </Table>
            </>
          )}
        </Modal.Body>
      ) : (
        ""
      )}
    </>
  );
}
