import React, { useEffect, useState } from "react";
import requestPayoutAmount from "../../media/Group 76@2x.png";

import { useSelector } from "react-redux";

export function PaymentAmountCard({ type, heading, amount }) {
  const { auth } = useSelector(state => state);

  return (
    <div>
      <span className={type}>
        <h3>{heading}</h3>
        <p style={{ fontSize: "20px" }}>${amount}</p>
        <div className="main-request-total">
          <img
            src={requestPayoutAmount}
            className="requestPayoutTotal"
            alt=""
          />
        </div>
      </span>
    </div>
  );
}
