import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../_metronic/_partials/controls/WitnessingCard";
import { CustomChooseWitnessTable } from "../signwitnesss/table/CustomChooseWitnessTable";
import TextField from "@material-ui/core/TextField";

export function TableChooseWitnessCard() {
  return (
    <Card>
      <CardHeader title="Choose Witness" className="py-6">
        <CardHeaderToolbar>
          <TextField
            label="Search notary by name"
            id="outlined-size-small"
            variant="outlined"
            size="small"
            className="mr-3"
            style={{ width: 250 }}
            color={"secondary"}
          />
          <Link to="#" style={{ color: "white" }}>
            <button type="button" className="btn btn-primary">
              Search
            </button>
          </Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <CustomChooseWitnessTable />
        <Link to="#" style={{ float: "right", textDecoration: "underline" }}>
          View All
        </Link>
      </CardBody>
    </Card>
  );
}
