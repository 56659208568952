import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment';

export function RescheduleModal({
  heading,
  bodyText,
  image,
  btnTxt,
  btnHandler,
  show,
  hide,
  status,
  reschedule,
  setrescehduleDate,
  closebtnHandler
}) {
  const [dateTime, setDateTime] = React.useState(new Date());
  const [RescheduleMode, setRescheduleMode] = useState(false);
  const [notificationStart, setnotificationStart] = useState(false);

  const dateTimeHandler = e => {
    setrescehduleDate(new Date(e));
  };

  useEffect(() => {
    if (!notificationStart) {
      playSound();
      setnotificationStart(true);
    }
  }, []);

  const playSound = () => {
    const audio = new Audio(toAbsoluteUrl("/media/notification/sound.mp3"));
    audio.play();
  };

  const recheduleFinalize = () => {};

  const acceptFinalize = () => {};

  return (
    <Modal
      show={show}
      // onHide={hide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="justify-content-center pb-2">
        <Modal.Title id="contained-modal-title-vcenter">{heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ textAlign: "center" }}
        className="pl-2 pr-2 pt-3 pb3"
      >
        <img
          src={toAbsoluteUrl(image)}
          alt="success"
          style={{ width: "55px" }}
          className="mt-5 mb-5"
        />
        <p>{bodyText}</p>

        {status == "rescheduled" ? (
          <div>
            <div class="flex_class">
              <span>Do you wish to Reschedule Time Again.</span>
            </div>
            <div class="flex_class flex_class-alt">
              <MuiPickersUtilsProvider utils={MomentUtils }>
                <DateTimePicker 
                    label="Schedule Notarization"
                    initialFocusedDate={dateTime}
                    onChange={dateTimeHandler}
                    value={dateTime}
                    showTodayButton={true}
                    disablePast={true}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
        ) : (
          ""
        )}

        {/* <p style={{fontSize: '11px', color:'red'}}><strong>Disclaimer</strong>. Don't refresh/close your page. Might you will loss the further process. </p> */}

        {status == "rescheduled" ? (
          <>
            {" "}
            <Button
              style={{
                backgroundColor: "#1840d2",
                borderColor: "#1840d2",
                minWidth: "25%",
                marginTop: "20px"
              }}
              onClick={reschedule}
            >
              Reschedule
            </Button>{" "}
            &nbsp;
            <Button
              style={{
                backgroundColor: "#1840d2",
                borderColor: "#1840d2",
                minWidth: "25%",
                marginTop: "20px"
              }}
              onClick={btnHandler}
            >
              Accept
            </Button>{" "}
            &nbsp;{" "}
          </>
        ) : (
          ""
        )}
        <Button
          style={{
            backgroundColor: "#1840d2",
            borderColor: "#1840d2",
            minWidth: "25%",
            marginTop: "20px"
          }}
          onClick={closebtnHandler}
        >
          {btnTxt}
        </Button>
      </Modal.Body>
    </Modal>
  );
}
