import React, { useEffect } from "react";
import { TableUIProvider } from "../components/clients_listing/TableUIContext";
import { TableCard } from "../components/clients_listing/TableCard";
import { GenericZeroState } from "../components/zero_state_pages/GenericZeroState";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../components/_redux/notarization_redux/notarizationActions";
import { AddClient } from "../components/clients_listing/AddClient";

export function MyClientPage() {
  const { socket, auth, notarization } = useSelector(state => state);
  const { clients } = notarization;

  const customersUIEvents = {
    setcheckKBA: istoggle => {
      alert(istoggle);
    }
  };

  const dispatch = useDispatch();

  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  useEffect(() => {
    dispatch(
      actions.fetchClients(headers, {
        sortOrder: "asc",
        sortField: "firstName",
        pageNumber: 1,
        pageSize: 10
      })
    );
  }, []);

  useEffect(() => {
    if (null !== socket.primaryNotaryChanged) {
      window.location.reload();
    }
  }, [socket.primaryNotaryChanged]);

  return (
    <>
      {
      ( auth.user.Role.code !== "notary" || 
        (auth.user.Role.code === "notary" && !auth.user.notaryProfile.isCancelled ) ) &&  
        <AddClient />
      }
      {clients !== null && clients.count > 0 ? (
        <TableUIProvider customersUIEvents={customersUIEvents}>
          <TableCard />
        </TableUIProvider>
      ) : (
        <GenericZeroState
          status={clients}
          imageURL="/media/own/no-clients.png"
          heading=""
          body="Once a client connects to you, you will see their information here."
          btnTxt="false"
        />
      )}
    </>
  );
}
