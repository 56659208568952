import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Grid, TextField } from "@material-ui/core";
import { ViewImage } from "../ViewImage";

export const ViewAdditionalInformation = ({
  data,
  openModal,
  onHide,
  journalId,
  draftHandler
}) => {
  const [additionalDetail, setAdditionalDetail] = useState();

  useEffect(() => {
    let record = data.extendedJournal;
    record = JSON.parse(record);
    setAdditionalDetail(record);
  }, [data]);

  return (
    <Modal
      size='xl'
      show={openModal}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <div className="container mt-5 mb-5">
        <div
          style={{
            pointerEvents: "none"
          }}
        >
          <h3>Additional Information </h3>
          {additionalDetail?.journalDoc?.map((record, index) => {
            return (
              <Grid container item spacing={2} key={index}>
                <Grid item xs={5}>
                  <b>Document (s)</b>
                  <TextField
                    value={record.File?.filename ? record.File?.filename : ""}
                    disabled={true}
                    margin="dense"
                    variant="outlined"
                    inputProps={{
                      style: { height: "18px" }
                    }}
                    color="secondary"
                  />
                </Grid>
                <Grid item xs={5}>
                  <b>Adobe Transaction #</b>
                  <TextField
                    placeholder="Enter Transaction Number"
                    value={
                      record.File?.transactionNumber
                        ? record.File.transactionNumber
                        : ""
                    }
                    margin="dense"
                    variant="outlined"
                    inputProps={{
                      style: { height: "18px" }
                    }}
                    color="secondary"
                  />
                </Grid>
              </Grid>
            );
          })}
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <div>
                If you are storing the video outside the platform, detail the
                location here:
              </div>
            </Grid>
            <Grid item xs={5}>
              <TextField
                placeholder="Enter Video URL"
                value={
                  additionalDetail?.videoUrl ? additionalDetail.videoUrl : ""
                }
                margin="dense"
                autoComplete="new-password"
                variant="outlined"
                inputProps={{ style: { height: "18px" } }}
                color="secondary"
              />
            </Grid>
          </Grid>
          <br />
          <div>Upload an image of each signer's signature:</div>
          <br />
          <Grid container spacing={2} className="p-2">
            {additionalDetail?.Signers?.map((signer, index) => {
              return (
                <>
                  <Grid key={index} sm={3} spacing={3}>
                    <b>
                      {signer?.firstName ? signer.firstName : ""}{" "}
                      {signer?.lastName ? signer.lastName : ""}
                    </b>
                    <br />
                    <ViewImage index={index} signer={signer} />
                  </Grid>
                </>
              );
            })}
          </Grid>

          <Grid container spacing={2} className="p-2">
            <Grid item sm={5} spacing={5}>
              <p>Additional Notes about this Notarization:</p>
              <textarea
                value={
                  additionalDetail?.additionalNotes
                    ? additionalDetail.additionalNotes
                    : ""
                }
                placeholder="Enter Additional Notes"
                name="Additional Notes"
                style={{
                  width: "100%",
                  height: "150px"
                }}
              ></textarea>
            </Grid>
            <Grid item xs={5}>
              <p>If a POA was signed, List the Agent's Information</p>
              <p className="mb-0">Name</p>
              <TextField
                placeholder="Enter Name"
                value={
                  additionalDetail?.POA?.name ? additionalDetail?.POA.name : ""
                }
                margin="dense"
                variant="outlined"
                inputProps={{ style: { height: "18px" } }}
                color="secondary"
              />
              <p className="mb-0">Address</p>
              <TextField
                placeholder="Enter Address"
                value={
                  additionalDetail?.POA?.address
                    ? additionalDetail.POA.address
                    : ""
                }
                margin="dense"
                variant="outlined"
                inputProps={{ style: { height: "18px" } }}
                color="secondary"
              />
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={2} className="p-2">
          <Grid item sm={5}>
            <button
              type="button"
              className="btn"
              style={{
                backgroundColor: "red",
                color: "white"
              }}
              onClick={() => {
                onHide();
              }}
            >
              Close
            </button>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};
