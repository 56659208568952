import React from "react";
import { Form, Button } from "react-bootstrap";
import Grid from "@material-ui/core/Grid";
import Collapse from "@material-ui/core/Collapse";
// import { useDispatch, useSelector } from "react-redux";
// import * as actions from "../../_redux/notarization_redux/notarizationActions";

export function Filters({
  showFilter,
  setShowFilter,
  filterValues,
  setFilterValues,
  applyFilterHandler,
  isAdmin = false
}) {
  const options = [];

  const toggleFilter = () => setShowFilter(!showFilter);
  const filtersHandler = e =>
    setFilterValues({ ...filterValues, [e.target.name]: e.target.value });
  const clearFilter = e => {
    setFilterValues({ language: "", notaryType: "", state: "" });
    applyFilterHandler("clear");
  };
  const stateArray = [
    "Alabama",
    "Alaska",
    "American Samoa",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "District of Columbia",
    "Federated States of Micronesia",
    "Florida",
    "Georgia",
    "Guam",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Marshall Islands",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Northern Mariana Islands",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Palau",
    "Pennsylvania",
    "Puerto Rico",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virgin Island",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming"
  ];
  return (
    <>
      <Grid container spacing={2} style={{ marginBottom: "10px" }}>
        <Grid item xs={6}>
          {showFilter && <h5>Advance Filter</h5>}
        </Grid>
        <Grid item xs={6}>
          <p
            style={{
              color: "blue",
              textDecoration: "underline",
              float: "right",
              cursor: "pointer"
            }}
            onClick={toggleFilter}
          >
            {showFilter ? "Hide Filters" : "Advance Filters"}
          </p>
          {(filterValues.language !== "" ||
            filterValues.notaryType !== "" ||
            filterValues.state !== "") && (
            <p
              style={{
                color: "blue",
                textDecoration: "underline",
                float: "right",
                cursor: "pointer"
              }}
              onClick={clearFilter}
              className="mr-3"
            >
              Clear Filters
            </p>
          )}
        </Grid>
      </Grid>

      <Collapse in={showFilter}>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Grid container spacing={2}>
              <Grid item xs={isAdmin ? 3 : 4}>
                <Form.Group>
                  <Form.Control
                    as="select"
                    name="language"
                    value={filterValues.language}
                    onChange={filtersHandler}
                  >
                    <option disabled value="">
                      Select Language
                    </option>
                    <option value="Albanian">Albanian</option>
                    <option value="American Sign Language (ASL)">
                      American Sign Language (ASL)
                    </option>
                    <option value="Amharic">Amharic</option>
                    <option value="Arabic">Arabic</option>
                    <option value="Armenian">Armenian</option>
                    <option value="Azerbaijan">Azerbaijan</option>
                    <option value="Bengali">Bengali</option>
                    <option value="Bislama">Bislama</option>
                    <option value="Burmese">Burmese</option>
                    <option value="Catalan">Catalan</option>
                    <option value="Chichewa">Chichewa</option>
                    <option value="Chinese">Chinese</option>
                    <option value="Mandarin">Mandarin</option>
                    <option value="Czech">Czech</option>
                    <option value="Danish">Danish</option>
                    <option value="Dari">Dari</option>
                    <option value="Dzongkha">Dzongkha</option>
                    <option value="Dutch">Dutch</option>
                    <option value="Dhivehi">Dhivehi</option>
                    <option value="Estonian">Estonian</option>
                    <option value="English">English</option>
                    <option value="Frisian">Frisian</option>
                    <option value="French">French</option>
                    <option value="Finnish">Finnish</option>
                    <option value="Guaraní">Guaraní</option>
                    <option value="Greek">Greek</option>
                    <option value="German">German</option>
                    <option value="Georgian">Georgian</option>
                    <option value="Hungarian">Hungarian</option>
                    <option value="Hindi">Hindi</option>
                    <option value="Italian">Italian</option>
                    <option value="Irish">Irish</option>
                    <option value="Indonesian">Indonesian</option>
                    <option value="Icelandic">Icelandic</option>
                    <option value="Japanese">Japanese</option>
                    <option value="Kyrgyz">Kyrgyz</option>
                    <option value="Korean">Korean</option>
                    <option value="Kinyarwanda">Kinyarwanda</option>
                    <option value="Kikongo">Kikongo</option>
                    <option value="Khmer">Khmer</option>
                    <option value="Kazakh">Kazakh</option>
                    <option value="Luxembourgish">Luxembourgish</option>
                    <option value="Lithuanian">Lithuanian</option>
                    <option value="Latvian">Latvian</option>
                    <option value="Lao">Lao</option>
                    <option value="Montenegrin">Montenegrin</option>
                    <option value="Mongolia">Mongolia</option>
                    <option value="Moldovan">Moldovan</option>
                    <option value="Maltese">Maltese</option>
                    <option value="Malay">Malay</option>
                    <option value="Malagasy">Malagasy</option>
                    <option value="Macedonian">Macedonian</option>
                    <option value="Norwegian">Norwegian</option>
                    <option value="Nepali">Nepali</option>
                    <option value="Ndebele">Ndebele</option>
                    <option value="Portuguese">Portuguese</option>
                    <option value="Polish">Polish</option>
                    <option value="Persian">Persian</option>
                    <option value="Palauan">Palauan</option>
                    <option value="Quechua">Quechua</option>
                    <option value="Russian">Russian</option>
                    <option value="Romanian">Romanian</option>
                    <option value="Samoan">Samoan</option>
                    <option value="San Marino">San Marino</option>
                    <option value="Sango">Sango</option>
                    <option value="Serbian">Serbian</option>
                    <option value="Seychellois Creole">
                      Seychellois Creole
                    </option>
                    <option value="Sotho">Sotho</option>
                    <option value="Somali">Somali</option>
                    <option value="Slovene">Slovene</option>
                    <option value="Slovak">Slovak</option>
                    <option value="Sinhala">Sinhala</option>
                    <option value="Swati">Swati</option>
                    <option value="Swahili">Swahili</option>
                    <option value="Standard Chinese">Standard Chinese</option>
                    <option value="Spanish">Spanish</option>
                    <option value="Swedish">Swedish</option>
                    <option value="Tswana">Tswana</option>
                    <option value="Tok Pisin">Tok Pisin</option>
                    <option value="Tigrinya">Tigrinya</option>
                    <option value="Thai">Thai</option>
                    <option value="Tetum">Tetum</option>
                    <option value="Turkmen">Turkmen</option>
                    <option value="Turkish">Turkish</option>
                    <option value="Ukrainian">Ukrainian</option>
                    <option value="Uzbek">Uzbek</option>
                    <option value="Vietnamese">Vietnamese</option>
                    <option value="Yoruba">Yoruba</option>
                  </Form.Control>
                </Form.Group>
              </Grid>
              <Grid item xs={isAdmin ? 3 : 4}>
                <Form.Group>
                  <Form.Control
                    as="select"
                    name="notaryType"
                    value={filterValues.notaryType}
                    onChange={filtersHandler}
                  >
                    <option value="" disabled>
                      Select Notary Type
                    </option>
                    {/* <option value="notary">Notary</option> */}
                    <option value="commisioned notary">
                      Commisioned Notary
                    </option>
                    <option value="Attorney at Law">Attorney at Law</option>
                    <option value="loan signing agent">
                      Loan Signing Agent
                    </option>
                  </Form.Control>
                </Form.Group>
              </Grid>
              <Grid item xs={isAdmin ? 2 : 4}>
                <Form.Group>
                  <Form.Control
                    as="select"
                    name="state"
                    value={filterValues.state}
                    onChange={filtersHandler}
                  >
                    <option value="" disabled>
                      Select State
                    </option>
                    {stateArray.map(el => (
                      <option value={el}>{el}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Grid>
              { isAdmin && (
                <>
                  <Grid item xs={2}>
                    <Form.Group className="mb-0 mr-3">
                      <Form.Control
                        as="select"
                        name="status"
                        onChange={filtersHandler}
                        value={filterValues.status}
                      >
                        <option value="">All</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                        <option value="pending">Pending</option>
                        <option value="cancelled">Cancelled</option>
                      </Form.Control>
                    </Form.Group>
                  </Grid>
                  <Grid item xs={2}>
                    <Form.Group className="mb-0 mr-3">
                      <Form.Control
                        as="select"
                        name="mode"
                        onChange={filtersHandler}
                        value={filterValues.mode}
                      >
                        <option value="">All</option>
                        <option value="online">Online</option>
                      </Form.Control>
                    </Form.Group>
                  </Grid>
                </>)
              }
            </Grid>
          </Grid>

          <Grid item xs={2}>
            <Button
              style={{
                backgroundColor: "transparent",
                borderColor: "#1840d2",
                color: "#1840d2",
                minWidth: "100%"
              }}
              disabled={
                !(
                  filterValues.language !== "" ||
                  filterValues.notaryType !== "" ||
                  filterValues.state !== "" ||
                  filterValues.status !== "" ||
                  filterValues.mode !== ""
                )
              }
              onClick={e => applyFilterHandler("filters")}
            >
              Apply Filter
            </Button>
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
}
