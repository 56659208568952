import React, { useState } from 'react'
import { Grid, TextField } from '@material-ui/core'
import ShowNotification from '../../../components/new_notarization/ShowNotification'
import logo from '../media/LOGO.jpg'

export const TwoFactorAuth = ({ code, setCode, submit }) => {
    const [disable, setDisable] = useState(false)
    const [notification, setNotification] = useState({
        show: false,
        msg: '',
        variant: '',
    })

    const handleChange = () => (event) => {
        setCode(event.target.value)
    }
    const handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') return
        setNotification({ show: false, msg: '', variant: '' })
    }
    // function that send verification code on email
    const sendEmail = () => {
        //disable email button for 10 second
        setDisable(true)
        setTimeout(() => {
            setDisable(false)
        }, 10000)
        setNotification({
            show: true,
            msg: 'Verification code has been sent successfully to your Email.',
            variant: 'success',
        })
    }
    
    return (
        <>
            <div className='login-form login-signin' id='kt_login_signin_form'>
                <img
                    src={logo}
                    alt='Logo'
                    className='w-25 '
                    style={{ marginTop: -20 }}
                />
                <div className='text-start'>
                    <h3 className='font-size-h1'>Security Verification</h3>
                    <p className='' style={{ color: '#5c5c5c' }}>
                        To secure your account, please complete the email
                        verification.
                    </p>
                </div>
                <Grid>
                    <div>Email Verification Code</div>
                </Grid>
                <Grid container spacing={2} className='p-2'>
                    <TextField
                        required
                        label='Code'
                        value={code ? code : ''}
                        onChange={handleChange('code')}
                        margin='dense'
                        variant='outlined'
                        inputProps={{ style: { height: '18px' } }}
                        color='secondary'
                    />
                </Grid>
                The Verification Code was sent to your email address.
                <Grid item sm={12}>
                    {/* <button
                        disabled={disable}
                        type='submit'
                        onClick={sendEmail}
                        className={`btn btn-${
                            disable ? 'secondary' : 'primary'
                        } font-weight-bold px-9 py-3 my-3 w-40 `}>
                        <span>Get Code</span>
                    </button> */}
                    <button
                        disabled={code ? false : true}
                        type='submit'
                        onClick={() => submit()}
                        className={`btn btn-primary font-weight-bold px-9 py-3 mx-2 my-3 w-40 `}>
                        <span>Submit</span>
                    </button>
                </Grid>
                {notification.show && (
                    <ShowNotification
                        show={notification.show}
                        msg={notification.msg}
                        variant={notification.variant}
                        close={handleCloseNotification}
                    />
                )}
            </div>
        </>
    )
}
