import React, { Suspense, useEffect, useState } from "react";
import { TableUIWitnessingContext } from "../components/signwitnesss/TableUIWitnessingContext";
import { TableAdobeSignIframeCard } from "../components/signwitnesss/TableAdobeSignIframeCard";
import { NewNotarization } from "../pages/NewNotarization";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../components/_redux/notarization_redux/notarizationActions";
import { Redirect, useLocation } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";

export function AdobeSignIframe(props) {
  const [actionModal, setActionModal] = useState(false);
  const [notaryID, setNotaryID] = useState(null);
  const [journalID, setjournalID] = useState(0);
  const [isActive, setisActive] = useState(false);
  const [initiate, setinitiate] = useState(false);
  const customersUIEvents = {
    openEditCustomerDialog: id => {
      setActionModal(true);
      setNotaryID(id);
    }
  };
  const { auth, notarization } = useSelector(state => state);
  const location = useLocation();
  const dispatch = useDispatch();
  const { Clients, getAllJournal, adobeURL, jitsiMeetingJWT } = useSelector(
    state => state.notarization
  );
  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  useEffect(() => {
    let getidfromlocation = location.search;
    getidfromlocation = getidfromlocation.replace("?id=", "");
    setjournalID(getidfromlocation);

    if (getidfromlocation && !initiate) {
      dispatch(actions.checkJournal(getidfromlocation, headers, "setURL"));
      dispatch(actions.getJitsiMeetingObj(getidfromlocation, headers));
      dispatch(actions.fetchWitnessesforJournal(getidfromlocation, headers));
      setinitiate(true);
    }

    if (adobeURL === null) setisActive(true);
    else setisActive(false);

    // if(auth.user.id === journal.)
  }, [dispatch, adobeURL, jitsiMeetingJWT]);

  return (
    <span>
      {journalID && getAllJournal ? (
        auth.user.id === getAllJournal.journal.notaryId &&
        !(
          getAllJournal.journal.status === "completed" ||
          getAllJournal.journal.status === "rejected"
        ) ? (
          <LoadingOverlay active={isActive} spinner text="Loading...">
            {" "}
            <TableAdobeSignIframeCard journalID={journalID} />
          </LoadingOverlay>
        ) : (
          <Redirect to="/" />
        )
      ) : (
        ""
      )}
    </span>
  );
}
