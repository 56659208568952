import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../_metronic/_partials/controls";
import { NotaryListing } from "./card/NotaryListing";
import { Filters } from "./card/Filters";
import { Form } from "react-bootstrap";
import * as actions from "../_redux/notarization_redux/notarizationActions";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { GenericZeroState } from "../zero_state_pages/GenericZeroState";

export default function SearchNotaryContainer({
  setNotification,
  setSelectPrimaryNotary,
  selectedUser=null,
  selectedNotaryId=null
}) {
  // States
  const [search, setSearch] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [filterValues, setFilterValues] = useState({
    language: "",
    notaryType: "",
    state: ""
  });
  const [queries, setQueries] = useState({
    q: "",
    lang: "",
    type: "",
    state: "",
    page: 1,
    limit: 9
  });
  const { auth, notarization } = useSelector(state => state);
  const { filterNotaries } = notarization;

  // Handler Functions
  const handleKeypress = e => {
    if (e.which === 13) applyFilterHandler("search");
  };

  const applyFilterHandler = name => {
    if (name === "search") setQueries({ ...queries, q: search });
    else if (name === "filters")
      setQueries({
        ...queries,
        lang: filterValues.language,
        type: filterValues.notaryType,
        state: filterValues.state
      });
    else if (name === "clear") {
      setQueries({ q: "", lang: "", type: "", state: "", page: 1, limit: 9 });
      setSearch("");
      setFilterValues({ language: "", notaryType: "", state: "" });
      setShowFilter(false);
    } else setQueries({ ...queries, lang: "", type: "", state: "" });
  };

  const paginationHandleChange = (event, value) =>
    setQueries({ ...queries, page: value });

  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  const dispatch = useDispatch();

  const makePrimaryHandler = e => {
    dispatch(
      actions.setPrimaryNotary(
        { 
          notaryId: e.currentTarget.id,
          userId: selectedUser,
        },
        headers,
        setNotification,
        setSelectPrimaryNotary,
        auth.user.Role.code
      )
    );
  };

  useEffect(() => {
    dispatch(actions.fetchFilteredNotaries(queries, headers));
  }, [dispatch, queries]);

  const arrowStyle = {
    fontSize: "36px",
    color: "#909090",
    backgroundColor: "#fbfbfb",
    borderRadius: "50%",
    marginRight: "2px",
    padding: "7px"
  };

  return (
    <Card>
      <div className="pl-6 pt-7">
        <div
          onClick={() => setSelectPrimaryNotary(false)}
          style={{ cursor: "pointer", float: "left" }}
        >
          <ArrowBackIcon style={arrowStyle} />{" "}
          <span style={{ fontSize: "14px", color: "#909090" }}>Back</span>
        </div>
      </div>
      <CardHeader
        title={
          (auth.user.Role.code === "user" || auth.user.Role.code === "admin")
            ? "Search Notaries"
            : "Search Witnesses"
        }
        className="py-6"
      >
        <CardHeaderToolbar>
          <Form.Control
            type="text"
            className="mr-5"
            placeholder="Search notary by name"
            value={search}
            onKeyPress={handleKeypress}
            onChange={e => setSearch(e.target.value)}
          />
          <button
            type="button"
            className="btn btn-primary"
            onClick={e => applyFilterHandler("search")}
          >
            Search
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody style={{ minHeight: "55vh" }}>
        {filterNotaries !== null && filterNotaries.rows.length > 0 ? (
          <>
            <Filters
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              filterValues={filterValues}
              setFilterValues={setFilterValues}
              applyFilterHandler={applyFilterHandler}
            />
            <NotaryListing
              notaries={filterNotaries.rows}
              makePrimaryHandler={makePrimaryHandler}
              selectedNotaryId={selectedNotaryId}
            />
            <Pagination
              count={Math.ceil(filterNotaries.count / 9)}
              page={queries.page}
              onChange={paginationHandleChange}
              showFirstButton
              showLastButton
              className="mt-10"
              color="secondary"
              size="large"
            />
          </>
        ) : (
          <>
            <Filters
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              filterValues={filterValues}
              setFilterValues={setFilterValues}
              applyFilterHandler={applyFilterHandler}
            />
            <GenericZeroState
              status={filterNotaries}
              imageURL="/media/own/no-notary-online.png"
              heading=""
              body="Unfortunately, there are no Notaries which match the requirements you have selected."
              sub_body=""
              btnTxt="Start a New Search"
              btnHandler={e => applyFilterHandler("clear")}
            />
          </>
        )}
      </CardBody>
    </Card>
  );
}
