import React from "react";
import { Modal, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment';

export function DateTimePickerModal({
  show,
  schedulerNotarizationHandler,
  handleClose,
  isButtonClicked
}) {
  const [dateTime, setDateTime] = React.useState(new Date().toISOString());
  const dateTimeHandler = e => {
    setDateTime(new Date(e));
  };

  return (
    <Modal
      show={show}
      // onHide={hide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="justify-content-center pb-2">
        <Modal.Title id="contained-modal-title-vcenter">
          Schedule For Later
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ textAlign: "center" }}
        className="pl-2 pr-2 pt-3 pb3"
      >
        <p className="pb-3">
          Please select date and time when you want to do this notarization.
        </p>
        <MuiPickersUtilsProvider utils={MomentUtils }>
          <DateTimePicker 
              label="Schedule Notarization"
              initialFocusedDate={dateTime}
              value={dateTime}
              onChange={dateTimeHandler}
              showTodayButton={true}
              disablePast={true}
          />
        </MuiPickersUtilsProvider>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="success"
          onClick={() => schedulerNotarizationHandler(dateTime)}
          disabled={isButtonClicked}
        >
          Schedule
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
