import React from "react";
import { TextField, makeStyles} from '@material-ui/core'

const useStyles = makeStyles(() => ({
  cstm_padding: {
      paddingLeft: '30px',
      paddingRight: '30px',
      marginTop: '30px',
      marginBottom: '1rem',
  },
  cstm_field: {
      width: '100%',
      marginTop: 0,
  },
}))


export function Comment({comments, setComments}) {
  const updateComment = (e) => {
    setComments(e.target.value)
  }
  const classes = useStyles()
  return (
    <div className={`card card-custom`}>
      <div className="card-header border-0 pt-5">
        <h2
          className="card-title align-items-start flex-column"
          style={{ float: "left", width: "100%" }}
        >
          Comments
        </h2>
        <p style={{ color: "#ccc" }}>
          
        </p>
      </div>

      <div className="card-header border-0">
        <div className="align-items-start flex-column">
        <p style={{ float: "left", paddingTop: "7px", paddingRight: "20px" }}>
        Please provide any additional details you want the notary to be aware of for your session:
          </p>
          <TextField
            
            label='Comments'
            color='secondary'
            margin='dense'
            variant='outlined'
            className={classes.cstm_field}
            autoComplete='new-password'
            multiline
            rows={5}
           
            value={comments}
            onChange={updateComment}
            
        />
         
        </div>
      </div>
     
    </div>
  );
}
