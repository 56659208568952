/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSelector, shallowEqual, connect, useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import { ModalProgressBar } from '../../../_metronic/_partials/controls'
import * as auth from '../Auth'
import * as actions from '../Auth/_redux/AuthInit'
import { MultiSelect } from 'react-multi-select-component'
import Alert from '@material-ui/lab/Alert'
import { injectIntl } from 'react-intl'
import * as Yup from 'yup'
import { toAbsoluteUrl } from '../../../_metronic/_helpers'
import { Modal, Button } from 'react-bootstrap'
import { SearchNotaries } from '../../pages/SearchNotaries'
import ShowNotification from '../../components/new_notarization/ShowNotification'
import * as notarizeactions from '../../components/_redux/notarization_redux/notarizationActions'

function AccountInformation(props) {
    const options = [
        { label: 'Albanian', value: 'Albanian' },
        {
            label: 'American Sign Language (ASL)',
            value: 'American Sign Language (ASL)',
        },
        { label: 'Amharic', value: 'Amharic' },
        { label: 'Arabic', value: 'Arabic' },
        { label: 'Armenian', value: 'Armenian' },
        { label: 'Azerbaijan', value: 'Azerbaijan' },
        { label: 'Bengali', value: 'Bengali' },
        { label: 'Bislama', value: 'Bislama' },
        { label: 'Burmese', value: 'Burmese' },
        { label: 'Catalan', value: 'Catalan' },
        { label: 'Chichewa', value: 'Chichewa' },
        { label: 'Chinese,', value: 'Chinese,' },
        { label: 'Mandarin', value: 'Mandarin' },
        { label: 'Czech', value: 'Czech' },
        { label: 'Danish', value: 'Danish' },
        { label: 'Dari', value: 'Dari' },
        { label: 'Dhivehi', value: 'Dhivehi' },
        { label: 'Dutch', value: 'Dutch' },
        { label: 'Dzongkha', value: 'Dzongkha' },
        { label: 'English', value: 'English' },
        { label: 'Estonian', value: 'Estonian' },
        { label: 'Finnish', value: 'Finnish' },
        { label: 'French', value: 'French' },
        { label: 'Frisian', value: 'Frisian' },
        { label: 'Georgian', value: 'Georgian' },
        { label: 'German', value: 'German' },
        { label: 'Greek', value: 'Greek' },
        { label: 'Guaraní', value: 'Guaraní' },
        { label: 'Hindi', value: 'Hindi' },
        { label: 'Hungarian', value: 'Hungarian' },
        { label: 'Icelandic', value: 'Icelandic' },
        { label: 'Indonesian', value: 'Indonesian' },
        { label: 'Irish', value: 'Irish' },
        { label: 'Italian', value: 'Italian' },
        { label: 'Japanese', value: 'Japanese' },
        { label: 'Kazakh', value: 'Kazakh' },
        { label: 'Khmer', value: 'Khmer' },
        { label: 'Kikongo', value: 'Kikongo' },
        { label: 'Kinyarwanda', value: 'Kinyarwanda' },
        { label: 'Korean', value: 'Korean' },
        { label: 'Kyrgyz', value: 'Kyrgyz' },
        { label: 'Lao', value: 'Lao' },
        { label: 'Latvian', value: 'Latvian' },
        { label: 'Lithuanian', value: 'Lithuanian' },
        { label: 'Luxembourgish', value: 'Luxembourgish' },
        { label: 'Macedonian', value: 'Macedonian' },
        { label: 'Malagasy', value: 'Malagasy' },
        { label: 'Malay', value: 'Malay' },
        { label: 'Maltese', value: 'Maltese' },
        { label: 'Moldovan', value: 'Moldovan' },
        { label: 'Mongolia', value: 'Mongolia' },
        { label: 'Montenegrin', value: 'Montenegrin' },
        { label: 'Ndebele', value: 'Ndebele' },
        { label: 'Nepali', value: 'Nepali' },
        { label: 'Norwegian', value: 'Norwegian' },
        { label: 'Palauan', value: 'Palauan' },
        { label: 'Persian', value: 'Persian' },
        { label: 'Polish', value: 'Polish' },
        { label: 'Portuguese', value: 'Portuguese' },
        { label: 'Quechua', value: 'Quechua' },
        { label: 'Romanian', value: 'Romanian' },
        { label: 'Russian', value: 'Russian' },
        { label: 'Samoan', value: 'Samoan' },
        { label: 'San Marino', value: 'San Marino' },
        { label: 'Sango', value: 'Sango' },
        { label: 'Serbian', value: 'Serbian' },
        { label: 'Seychellois Creole', value: 'Seychellois Creole' },
        { label: 'Sinhala', value: 'Sinhala' },
        { label: 'Slovak', value: 'Slovak' },
        { label: 'Slovene', value: 'Slovene' },
        { label: 'Somali', value: 'Somali' },
        { label: 'Sotho', value: 'Sotho' },
        { label: 'Spanish', value: 'Spanish' },
        { label: 'Standard Chinese', value: 'Standard Chinese' },
        { label: 'Swahili', value: 'Swahili' },
        { label: 'Swahili', value: 'Swahili' },
        { label: 'Swati', value: 'Swati' },
        { label: 'Swedish', value: 'Swedish' },
        { label: 'Tetum', value: 'Tetum' },
        { label: 'Thai', value: 'Thai' },
        { label: 'Tigrinya', value: 'Tigrinya' },
        { label: 'Tok Pisin', value: 'Tok pisin' },
        { label: 'Tswana', value: 'Tswana' },
        { label: 'Turkish', value: 'Turkish' },
        { label: 'Turkmen', value: 'Turkmen' },
        { label: 'Ukrainian', value: 'Ukrainian' },
        { label: 'Uzbek', value: 'Uzbek' },
        { label: 'Vietnamese', value: 'Vietnamese' },
        { label: 'Yoruba', value: 'Yoruba' },
    ]
    // Fields

    const [KBASettingscheck, setKBASettingscheck] = useState(false)
    const [lang, setLang] = useState([])
    const [myPrimaryNotary, setMyPrimaryNotary] = useState('')
    const [KBASettingscheckdispatch, setKBASettingscheckdispatch] = useState(
        false
    )
    const [byPassKBAAllowed, setbyPassKBAAllowed] = useState(false)
    const [FormUpdateMSG, setFormUpdateMSG] = useState(false)
    const [notNumber, setNotNumber] = useState(false)
    const [profileUpdate, setProfileUpdate] = useState(false)
    const [showModal, setShow] = useState(false)
    const [selectPrimaryNotary, setSelectPrimaryNotary] = useState(false)
    const [notification, setNotification] = useState({
        show: false,
        msg: '',
        variant: '',
    })
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    const dispatch = useDispatch()
    const { socket, auth, notarization } = useSelector((state) => state)
    const { KBASettings, allAdminStates } = notarization
    const {
        user,
        authToken,
        userProfileAvatarID,
        uploadingImage,
    } = useSelector((state) => state.auth, shallowEqual)
    const { primaryNotary } = useSelector(
        (state) => state.notarization,
        shallowEqual
    )

    const headers = {
        Authorization: `Bearer ${auth.authToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
    }

    useEffect(() => {
        dispatch(
            notarizeactions.fetchNotaryStates(headers, [], {
                pageNumber: 1,
                pageSize: 52,
                sortBy: "asc",
                sortField: "state",
            })
        )
    }, [])

    useEffect(() => {
        const headers = {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }

        if (!KBASettingscheckdispatch) {
            dispatch(notarizeactions.fetchKBASettings(headers))

            setKBASettingscheckdispatch(true)
        }
        if (KBASettings && KBASettings.data && !KBASettingscheck) {
            setbyPassKBAAllowed(KBASettings.data.settings.bypassAllowed)
            setKBASettingscheckdispatch(true)
        }

        setLang(
            user.Languages.map((x) => {
                return { value: x.name, label: x.name }
            })
        )
    }, [user, KBASettings])
    // Methods
    const saveUser = (values, setStatus, setSubmitting) => {
        setloading(true)
        if (!/^[a-zA-Z0-9]*$/g.test(values.firstName)) {
            setNotification({
                show: true,
                msg: 'Please Provide Correct First Name',
                variant: 'error',
            })
            return false
        }
        if (!/^[a-zA-Z0-9]*$/g.test(values.lastName)) {
            setNotification({
                show: true,
                msg: 'Please Provide Correct Last Name',
                variant: 'error',
            })
            return false
        }
        if (!/^[a-zA-Z0-9]*$/g.test(values.middleName)) {
            setNotification({
                show: true,
                msg: 'Please Provide Correct Last Name',
                variant: 'error',
            })
            return false
        }

        let languages = []
        lang.map((data) => {
            languages.push(data.value)
        })
        const updatedUser = {
            user: {
                firstName: values.firstName,
                lastName: values.lastName,
                middleName: values.middleName,
                suffix: values.suffix,
                phone: values.phone,
                address: values.address,
                state: values.state,
                city: values.city,
                businessName: values.businessName ? values.businessName : '',
                zip: values.zip,
                notaryId: myPrimaryNotary,
                profile_pic: userProfileAvatarID
                    ? userProfileAvatarID[0].id
                    : null,
            },

            languages: languages,
        }

        if (!updatedUser.user.profile_pic) delete updatedUser.user.profile_pic

        // user for update preparation
        dispatch(
            actions.updateUserProfile(updatedUser, authToken, setFormUpdateMSG)
        )
        setTimeout(() => {
            setNotNumber(false)
            setloading(false)
            setSubmitting(false)
        }, 1000)
    }
    // UI Helpers
    const initialValues = {
        profile_picture: user.profile_picture,
        firstName: user.firstName,
        lastName: user.lastName,
        middleName: user.middleName,
        suffix: user.suffix,
        email: user.email,
        phone: user.phone,
        address: user.address,
        businessName: user.businessName ? user.businessName : '',
        city: user.city,
        state: user.state,
        zip: user.zip,
        Languages: user.Languages,
        notaryId:
            user.Role.code === 'user' && user['userProfile'] != null
                ? user['userProfile'].notaryId
                : '',
    }
    const { intl } = props
    const [loading, setloading] = useState(false)

    let isPsUserRole = user.Role.code === 'ps' ? true : false

    const UserProfileSchema = Yup.object().shape({
        pic: Yup.string(),
        firstName: Yup.string()
            .trim('cannot include leading and trailing spaces')
            .strict(true)
            .nullable()
            .required(
                intl.formatMessage({
                    id: 'AUTH.VALIDATION.REQUIRED_FIELD',
                })
            ),
        lastName: Yup.string()
            .trim('cannot include leading and trailing spaces')
            .strict(true)
            .nullable()
            .required(
                intl.formatMessage({
                    id: 'AUTH.VALIDATION.REQUIRED_FIELD',
                })
            ),
        suffix: Yup.string()
            .trim('cannot include leading and trailing spaces')
            .strict(true)
            .nullable()
            .matches(/^[A-Za-z.]+$/gi, 'Must be only characters')
            .max(5, 'Must not exceed 5 letters'),
        businessName: Yup.lazy((value) => {
            if (isPsUserRole) {
                return Yup.string()
                    .trim('cannot include leading and trailing spaces')
                    .strict(true)
                    .nullable()
                    .required(
                        intl.formatMessage({
                            id: 'AUTH.VALIDATION.REQUIRED_FIELD',
                        })
                    )
            }
            return Yup.string().notRequired()
        }),

        phone: Yup.string()
            .trim('cannot include leading and trailing spaces')
            .strict(true)
            .nullable()
            .required(
                intl.formatMessage({
                    id: 'AUTH.VALIDATION.REQUIRED_FIELD',
                })
            ),
        address: Yup.string()
            .trim('cannot include leading and trailing spaces')
            .strict(true)
            .nullable()
            .required(
                intl.formatMessage({
                    id: 'AUTH.VALIDATION.REQUIRED_FIELD',
                })
            ),
        city: Yup.string()
            .trim('cannot include leading and trailing spaces')
            .strict(true)
            .nullable()
            .required(
                intl.formatMessage({
                    id: 'AUTH.VALIDATION.REQUIRED_FIELD',
                })
            ),
        state: Yup.string()
            .trim('cannot include leading and trailing spaces')
            .strict(true)
            .nullable()
            .required(
                intl.formatMessage({
                    id: 'AUTH.VALIDATION.REQUIRED_FIELD',
                })
            ),
        zip: Yup.string()
            .trim('cannot include leading and trailing spaces')
            .strict(true)
            .nullable()
            .required(
                intl.formatMessage({
                    id: 'AUTH.VALIDATION.REQUIRED_FIELD',
                })
            ),
    })

    const showprofilePic = (e) => {
        var reader = new FileReader()
        reader.onload = function(e) {
            //user.profile_picture.baseUrl =e.target.result
            user.tempprofilepicture = e.target.result
            dispatch({
                type: 'temporary_profile',
                payload: user.tempprofilepicture,
            })
        }

        reader.readAsDataURL(
            document.querySelector("input[type='file']").files[0]
        )
    }

    const getAvatar = (file) => {
        let imageExtension = file.target.files[0].name.split('.')[1]

        if (
            imageExtension.toLowerCase() === 'jpg' ||
            imageExtension.toLowerCase() === 'png' ||
            imageExtension.toLowerCase() === 'jpeg' ||
            imageExtension.toLowerCase() === 'heic'
        ) {
            let formData = new FormData()
            formData.append('userId', user.id)
            formData.append('file', file.target.files[0])
            showprofilePic(file)
            dispatch(
                actions.updateUserProfileAvatar(
                    formData,
                    authToken,
                    setProfileUpdate
                )
            )
        } else {
            setNotification({
                show: true,
                msg: 'Only JPG, JPEG, PNG, Image format is acceptable!',
                variant: 'error',
            })
        }
    }
    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return 'is-invalid'
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return 'is-valid'
        }

        return ''
    }

    const handleChangeCheckBox = (event) => {
        setLang(event)
    }

    const formik = useFormik({
        initialValues,
        validationSchema: UserProfileSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            if (!isNaN(values.phone)) {
                saveUser(values, setStatus, setSubmitting)
            } else {
                setNotNumber(true)
            }
        },
        onReset: (values, { resetForm }) => {
            resetForm()
        },
    })

    const removePic = () => {
        let picID = user.profile_pic
        dispatch(actions.deleteUserProfile(picID, authToken))
        setShow(false)
    }

    const editPrimaryNotary = () => {
        setSelectPrimaryNotary(true)
    }

    const handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') return
        setNotification({ show: false, msg: '', variant: '' })
    }

    // const primaryNotarySelectedHandler = () => setSelectPrimaryNotary(false);

    return (
        <>
            {notification.show && (
                <ShowNotification
                    show={notification.show}
                    msg={notification.msg}
                    variant={notification.variant}
                    close={handleCloseNotification}
                />
            )}
            {selectPrimaryNotary ? (
                <SearchNotaries
                    setSelectPrimaryNotary={setSelectPrimaryNotary}
                />
            ) : (
                <form
                    id='user_profile_form'
                    className='card card-custom'
                    onSubmit={formik.handleSubmit}>
                    {loading && <ModalProgressBar />}
                    {/* begin::Header */}
                    <div className='card-header py-3'>
                        <div className='card-title align-items-start flex-column'>
                            <h3 className='card-label font-weight-bolder text-dark mt-3'>
                                My Profile
                            </h3>
                        </div>
                        <div className='card-toolbar'>
                            <button
                                type='submit'
                                className='btn mr-2'
                                style={{
                                    backgroundColor: '#4A7DFF',
                                    color: 'white',
                                }}>
                                Save Changes
                                {formik.isSubmitting}
                            </button>
                            <Link to='/dashboard' className='btn btn-secondary'>
                                Cancel
                            </Link>
                        </div>
                    </div>
                    {/* end::Header */}
                    {/* begin::Form */}
                    {FormUpdateMSG ? (
                        <Alert severity='success'>
                            Changes are Successful!
                        </Alert>
                    ) : (
                        ''
                    )}
                    {profileUpdate ? (
                        <Alert severity='success'>
                            Profile Image Update Successfully
                        </Alert>
                    ) : (
                        ''
                    )}
                    {notNumber ? (
                        <Alert
                            severity='error'
                            onClose={() => setNotNumber(false)}>
                            Accept only numbers
                        </Alert>
                    ) : (
                        ''
                    )}
                    <div className='form'>
                        <div className='card-body'>
                            <div className='form-group row'>
                                <label className='col-xl-3 col-lg-3 col-form-label'>
                                    Profile Picture
                                </label>
                                <div className='col-lg-9 col-xl-6'>
                                    <div
                                        className='image-input image-input-outline'
                                        id='kt_profile_avatar'
                                        style={{
                                            backgroundImage: `url(${
                                                uploadingImage
                                                    ? toAbsoluteUrl(
                                                          '/media/own/dp_loader.gif'
                                                      )
                                                    : user.profile_picture
                                                    ? `"${user.profile_picture.baseUrl}/${user.profile_picture.path}"`
                                                    : toAbsoluteUrl(
                                                          '/media/users/blank.png'
                                                      )
                                            })`,
                                        }}>
                                        <div
                                            className='image-input-wrapper'
                                            style={{
                                                backgroundImage: `url(${
                                                    uploadingImage
                                                        ? toAbsoluteUrl(
                                                              '/media/own/dp_loader.gif'
                                                          )
                                                        : user.profile_picture
                                                        ? `"${user.profile_picture.baseUrl}${user.profile_picture.path}"`
                                                        : toAbsoluteUrl(
                                                              '/media/users/blank.png'
                                                          )
                                                })`,
                                            }}
                                        />
                                        <label
                                            className='btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow'
                                            data-action='change'
                                            data-toggle='tooltip'
                                            title=''
                                            data-original-title='Change avatar'>
                                            <i className='fa fa-pen icon-sm text-muted'></i>
                                            <input
                                                type='file'
                                                name='profile_pic'
                                                accept='image/png, image/jpg, image/jpeg'
                                                onChange={getAvatar}
                                                // {...formik.getFieldProps("pic")}
                                            />
                                            <input
                                                type='hidden'
                                                name='profile_avatar_remove'
                                            />
                                        </label>
                                        <span
                                            className='btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow'
                                            data-action='cancel'
                                            data-toggle='tooltip'
                                            title=''
                                            data-original-title='Cancel avatar'>
                                            <i className='ki ki-bold-close icon-xs text-muted'></i>
                                        </span>

                                        {user.profile_picture ? (
                                            <span
                                                onClick={handleShow}
                                                className='btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow'
                                                data-action='remove'
                                                data-toggle='tooltip'
                                                title=''
                                                data-original-title='Remove avatar'>
                                                <i className='ki ki-bold-close icon-xs text-muted'></i>
                                            </span>
                                        ) : (
                                            ' '
                                        )}
                                        <Modal
                                            show={showModal}
                                            onHide={handleClose}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    Delete Profile Image
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                Are You Sure To Delete Profile
                                                Image
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button
                                                    variant='secondary'
                                                    onClick={handleClose}>
                                                    Close
                                                </Button>
                                                <Button
                                                    variant='danger'
                                                    onClick={removePic}>
                                                    Delete
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </div>
                                    <span className='form-text text-muted'>
                                        Allowed file types: png, jpg, jpeg.
                                    </span>
                                </div>
                            </div>

                            {/* begin::Form Group */}
                            <div className='form-group row'>
                                <label className='col-xl-3 col-lg-3 col-form-label'>
                                    First Name
                                </label>
                                <div className='col-lg-9 col-xl-6'>
                                    <div>
                                        <input
                                            type='text'
                                            placeholder='First Name'
                                            disabled={byPassKBAAllowed}
                                            className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                                'firstName'
                                            )}`}
                                            name='firstName'
                                            {...formik.getFieldProps(
                                                'firstName'
                                            )}
                                        />
                                        {formik.touched.firstName &&
                                        formik.errors.firstName ? (
                                            <div
                                                className='fv-plugins-message-container'
                                                style={{ color: 'red' }}>
                                                <div className='fv-help-block'>
                                                    {formik.errors.firstName}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className='form-group row'>
                                <label className='col-xl-3 col-lg-3 col-form-label'>
                                    Middle Name
                                </label>
                                <div className='col-lg-9 col-xl-6'>
                                    <div>
                                        <input
                                            type='text'
                                            placeholder='Middle Name'
                                            disabled={byPassKBAAllowed}
                                            className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                                'middleName'
                                            )}`}
                                            name='middleName'
                                            {...formik.getFieldProps(
                                                'middleName'
                                            )}
                                        />
                                        {formik.touched.middleName &&
                                        formik.errors.middleName ? (
                                            <div
                                                className='fv-plugins-message-container'
                                                style={{ color: 'red' }}>
                                                <div className='fv-help-block'>
                                                    {formik.errors.middleName}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className='form-group row'>
                                <label className='col-xl-3 col-lg-3 col-form-label'>
                                    Last Name
                                </label>
                                <div className='col-lg-9 col-xl-6'>
                                    <div>
                                        <input
                                            type='text'
                                            placeholder='Last Name'
                                            disabled={byPassKBAAllowed}
                                            className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                                'lastName'
                                            )}`}
                                            name='lastName'
                                            {...formik.getFieldProps(
                                                'lastName'
                                            )}
                                        />
                                        {formik.touched.lastName &&
                                        formik.errors.lastName ? (
                                            <div
                                                className='fv-plugins-message-container'
                                                style={{ color: 'red' }}>
                                                <div className='fv-help-block'>
                                                    {formik.errors.lastName}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className='form-group row'>
                                <label className='col-xl-3 col-lg-3 col-form-label'>
                                    Suffix
                                </label>
                                <div className='col-lg-9 col-xl-6'>
                                    <div>
                                        <input
                                            type='text'
                                            placeholder='Suffix'
                                            disabled={byPassKBAAllowed}
                                            className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                                'suffix'
                                            )}`}
                                            name='suffix'
                                            maxLength={5}
                                            {...formik.getFieldProps('suffix')}
                                        />
                                        {formik.touched.suffix &&
                                        formik.errors.suffix ? (
                                            <div
                                                className='fv-plugins-message-container'
                                                style={{ color: 'red' }}>
                                                <div className='fv-help-block'>
                                                    {formik.errors.suffix}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            {/* begin::Form Group */}
                            <div className='form-group row'>
                                <label className='col-xl-3 col-lg-3 col-form-label'>
                                    Email
                                </label>
                                <div className='col-lg-9 col-xl-6'>
                                    <div className='input-group input-group-lg input-group-solid'>
                                        <div className='input-group-prepend'>
                                            <span className='input-group-text'>
                                                <i className='fa fa-at'></i>
                                            </span>
                                        </div>
                                        <input
                                            type='text'
                                            placeholder='Email Address'
                                            className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                                'email'
                                            )}`}
                                            name='email'
                                            disabled={true}
                                            {...formik.getFieldProps('email')}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='form-group row'>
                                <label className='col-xl-3 col-lg-3 col-form-label'>
                                    Phone
                                </label>
                                <div className='col-lg-9 col-xl-6'>
                                    <div>
                                        <input
                                            type='text'
                                            placeholder='phone'
                                            className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                                'phone'
                                            )}`}
                                            name='address'
                                            {...formik.getFieldProps('phone')}
                                        />
                                        {formik.touched.phone &&
                                        formik.errors.phone ? (
                                            <div
                                                className='fv-plugins-message-container'
                                                style={{ color: 'red' }}>
                                                <div className='fv-help-block'>
                                                    {formik.errors.phone}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>

                            {isPsUserRole && (
                                <div className='form-group row'>
                                    <label className='col-xl-3 col-lg-3 col-form-label'>
                                        Business Name
                                    </label>
                                    <div className='col-lg-9 col-xl-6'>
                                        <div className='input-group input-group-lg input-group-solid'>
                                            <input
                                                type='text'
                                                placeholder='Business Name'
                                                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                                    'businessName'
                                                )}`}
                                                name='businessName'
                                                {...formik.getFieldProps(
                                                    'businessName'
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className='form-group row'>
                                <label className='col-xl-3 col-lg-3 col-form-label'>
                                    Address
                                </label>
                                <div className='col-lg-9 col-xl-6'>
                                    <div>
                                        <input
                                            type='text'
                                            placeholder='Address'
                                            className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                                'address'
                                            )}`}
                                            name='address'
                                            {...formik.getFieldProps('address')}
                                        />
                                        {formik.touched.address &&
                                        formik.errors.address ? (
                                            <div
                                                className='fv-plugins-message-container'
                                                style={{ color: 'red' }}>
                                                <div className='fv-help-block'>
                                                    {formik.errors.address}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className='form-group row'>
                                <label className='col-xl-3 col-lg-3 col-form-label'>
                                    City
                                </label>
                                <div className='col-lg-9 col-xl-6'>
                                    <div>
                                        <input
                                            type='text'
                                            placeholder='City'
                                            className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                                'city'
                                            )}`}
                                            name='city'
                                            {...formik.getFieldProps('city')}
                                        />
                                        {formik.touched.city &&
                                        formik.errors.city ? (
                                            <div
                                                className='fv-plugins-message-container'
                                                style={{ color: 'red' }}>
                                                <div className='fv-help-block'>
                                                    {formik.errors.city}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>

                            <div className='form-group row'>
                                <label className='col-xl-3 col-lg-3 col-form-label'>
                                    State
                                </label>
                                <div className='col-lg-9 col-xl-6'>
                                    <select
                                        placeholder='Select state'
                                        className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                            'state'
                                        )}`}
                                        name='state'
                                        value={user.state}
                                        {...formik.getFieldProps('state')}>
                                        {allAdminStates &&
                                            allAdminStates.rows.map((el) => (
                                                <option value={el.state}>
                                                    {el.state}
                                                </option>
                                            ))}
                                    </select>
                                    {formik.touched.state &&
                                    formik.errors.state ? (
                                        <div
                                            className='fv-plugins-message-container'
                                            style={{ color: 'red' }}>
                                            <div className='fv-help-block'>
                                                {formik.errors.state}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className='form-group row'>
                                <label className='col-xl-3 col-lg-3 col-form-label'>
                                    Zip
                                </label>
                                <div className='col-lg-9 col-xl-6'>
                                    <div>
                                        <input
                                            type='text'
                                            placeholder='Zip Code'
                                            className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                                'zip'
                                            )}`}
                                            name='zip'
                                            {...formik.getFieldProps('zip')}
                                        />
                                        {formik.touched.zip &&
                                        formik.errors.zip ? (
                                            <div
                                                className='fv-plugins-message-container'
                                                style={{ color: 'red' }}>
                                                <div className='fv-help-block'>
                                                    {formik.errors.zip}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            {/* begin::Form Group */}
                            <div className='form-group row'>
                                <label className='col-xl-3 col-lg-3 col-form-label'>
                                    Preferred Language
                                </label>
                                <div className='col-lg-9 col-xl-6'>
                                    <MultiSelect
                                        options={options}
                                        onChange={handleChangeCheckBox}
                                        style={{ width: '100%' }}
                                        className='form-control form-control-lg form-control-solid owais'
                                        name='Languages'
                                        value={lang}
                                        labelledBy='CheckList'
                                    />
                                </div>
                            </div>
                            {user.Role.code !== 'ps' && (
                                <div className='form-group row'>
                                    <label className='col-xl-3 col-lg-3 col-form-label'>
                                        My Primary Notary
                                    </label>
                                    {/* <div className="col-lg-9 col-xl-6">
                <div>
                  <input
                    type="text"
                    placeholder="phone"
                    className={`form-control form-control-lg form-control-solid ${getInputClasses(
                      "phone"
                    )}`}
                    name="address"
                    {...formik.getFieldProps("phone")}
                  />
                </div>
              </div> */}

                                    <div className='col-lg-9 col-xl-6'>
                                        <div className='input-group input-group-lg input-group-solid'>
                                            <input
                                                type='text'
                                                placeholder='Primary Notary'
                                                value={
                                                    primaryNotary !== null
                                                        ? `${primaryNotary.User.firstName} ${primaryNotary.User.lastName}`
                                                        : 'Primary notary not selected'
                                                }
                                                className={`form-control form-control-lg form-control-solid`}
                                                name='primary notary'
                                                disabled={true}
                                            />
                                            <div
                                                className='input-group-prepend'
                                                style={{ cursor: 'pointer' }}
                                                onClick={editPrimaryNotary}>
                                                <span className='input-group-text'>
                                                    <i className='fa fa-edit'></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* begin::Form Group */}
                        </div>
                    </div>
                    {/* end::Form */}
                </form>
            )}
        </>
    )
}
export default injectIntl(connect(null, auth.actions)(AccountInformation))
