import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../_metronic/_partials/controls/AdminCard";
import { CustomTable } from "./table/CustomTable";
import { Form } from "react-bootstrap";

export function TableCard() {
  const [keyword, setkeyword] = useState("");
  const [search, setSearch] = useState(false);
  const [searchClicked, setSearchClicked] = useState(false);

  // Handler Functions
  const handleKeypress = e => {
    if (e.which === 13) applyFilterHandler("search");
  };

  const applyFilterHandler = name => {
    if (name === "search") {
      setSearch(!search);
      setSearchClicked(true);
    } else if (name === "clear") {
      setkeyword("");
      setSearch(!search);
    }
  };

  return (
    <Card>
      <CardHeader title="Users" className="py-6">
        <CardHeaderToolbar style={{ minWidth: "570px" }}>
          <Form.Control
            type="text"
            className="mr-3"
            placeholder="Search User by name or email"
            value={keyword}
            onKeyPress={handleKeypress}
            onChange={e => setkeyword(e.target.value)}
          />

          <button
            type="button"
            className="btn btn-primary mr-3"
            onClick={e => applyFilterHandler("search")}
          >
            Search
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={e => applyFilterHandler("clear")}
          >
            Clear
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <CustomTable
          keyword={keyword}
          search={search}
          applyFilterHandler={applyFilterHandler}
          searchClicked={searchClicked}
          setSearchClicked={setSearchClicked}
        />
      </CardBody>
    </Card>
  );
}
