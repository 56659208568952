import React from "react";
import NoClientsImage from "../../../media/zeroState/Noclients.webp";

export function CustomNoClientsTable() {
  return (
    <>
      <div>
        <div>
          <img src={NoClientsImage} className="NoClientsImage" alt="" />
        </div>
        <div className="NoClients">
          <h3>No clients!</h3>
          <div className="no-clinet-text">
            <span>Opps, you don't have any clients right now.</span>
          </div>
        </div>
      </div>
    </>
  );
}
