import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as actions from "../../components/_redux/notarization_redux/notarizationActions";
import ShowNotification from "../../components/new_notarization/ShowNotification";
import { Link } from 'react-router-dom'

const MIDDLEWARE_URL = process.env.REACT_APP_MIDDLEWARE_URL;

export function KBAVerificationModal({
  heading,
  signers,
  show,
  hide,
  kbasigners
}) {
  const { socket, auth, notarization } = useSelector(state => state);
  const [copyLinkURL, setcopyLinkURL] = useState("");
  const dispatch = useDispatch();
  const [notification, setNotification] = useState({
    show: false,
    msg: "",
    variant: ""
  });
  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };
  const copytheLink = id => {
    dispatch(actions.copyLink(id, headers)).then(data => {
      if ("error" == data.status) {
        setNotification({
          show: true,
          msg: "You cannot copy Link. Link is already Copied.",
          variant: "error"
        });
        setcopyLinkURL(document.getElementById("copy" + id).innerHTML);
      } else copyLink(id);
    });
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") return;
    setNotification({ show: false, msg: "", variant: "" });
  };
  const copyLink = ( event, id) => {
    let el = document.createElement('textarea')
    let x = event.target;
    
    el.value = document.location.origin + `/#/id-proofing/?id=${id}`;
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    
    x.innerHTML = "Copied !";
    setTimeout(() => {
        x.innerHTML = "ID proofing"
    }, 2000, x);
  };

  const getTempLinkTitle = linkhref => {
    if (linkhref.indexOf("idologylive") > -1) return "Copy ID Upload Link";
    else return "Copy KBA Link";
  };

  const openkbscan = id => {
    setTimeout(() => {
      window.open(`${MIDDLEWARE_URL}/api/kba/scanned-doc/${id}/first`);
    })
  };

  return (
    <>
      {notification.show && (
        <ShowNotification
          show={notification.show}
          msg={notification.msg}
          variant={notification.variant}
          close={handleCloseNotification}
        />
      )}
      <Modal
        show={show}
        // onHide={hide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* <Modal.Header className="justify-content-center pb-2" closeButton> */}
        <Modal.Header className="justify-content-center pb-2">
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>{heading}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>DO NOT EXIT THIS BROWSER! CHECK YOUR EMAIL </h5>
          <p style={{ fontSize: "11px" }}>
            Each Signer Must Check Their Email! Each signer must pass the test
            that was sent via email from noreply@cyberizeit.com. If you do not
            see it in your inbox, check the spam or promotions folder.
          </p>
          {/* <p style={{ fontSize: '11px', color: 'red' }}><strong>Disclaimer</strong>. Don't refresh/close your page. Might you will loss the further verification process. </p> */}

          <h6 className="mb-0 mt-5">Signers</h6>
          <div className="tab-content">
            <div className="table-responsive">
              <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                <tbody>
                  {signers.map((el, i) =>
                    el.fname ? (
                      <tr>
                        <td style={{ padding: "6px 0 6px 0" }}>
                          {i + 1}. {el.fname} {el.lname}
                        </td>

                        {i < 1 ? (
                          <td style={{ padding: "6px 0 6px 0" }}>
                            {kbasigners[i] ? (
                              <>
                                <Link
                                  target={'_blank'}
                                  to={{
                                      pathname: `/id-proofing/?id=${kbasigners[i].id}`,
                                  }}>
                                  <Button variant='success'>
                                      ID proofing
                                  </Button>
                                </Link>
                              </>
                            ) : (
                              ""
                            )}
                          </td>
                        ) : (
                          <td style={{ padding: "6px 0 6px 0" }}> - </td>
                        )}
                        <td
                          style={{ float: "right", padding: "6px 6px 6px 0" }}
                        >
                        </td>
                      </tr>
                    ) : (
                      ""
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {/* <Button style={{ backgroundColor: '#1840d2', borderColor: '#1840d2', minWidth: '25%', marginTop: '20px' }} onClick={btnHandler}>{btnTxt}</Button> */}
        </Modal.Body>
      </Modal>
    </>
  );
}
