import React from "react";
import { Modal, Button } from "react-bootstrap";

export function CustomDeleteDialog({
  data,
  show,
  onHide,
  deleteUser,
  CancelDeleteCustomerDialog
}) {
  return (
    <>
      <Modal
        size="md"
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are You Sure To Delete User</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => CancelDeleteCustomerDialog()}
          >
            Close
          </Button>
          <Button variant="danger" onClick={() => deleteUser(data)}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
