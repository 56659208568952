import React from "react";
import { Modal } from "react-bootstrap";
import { CustomerEditForm } from "./CustomerEditForm";

export function CustomerEditDialog({
  show,
  onHide,
}) {
  return (
    <Modal
      size="xl"
      show={show}
      onHide={onHide}
      dialogClassName="modal-width"
      aria-labelledby="example-modal-sizes-title-xl"
    >
      <CustomerEditForm
        onHide={onHide}
      />
    </Modal>
  );
}
