import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import * as actions from "../../_redux/notarization_redux/notarizationActions";
import DeleteIcon from "../../../media/Delete Icon@3x.png";
import { Modal } from "react-bootstrap";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Tooltip } from "@material-ui/core";

export function CompleteNotarizationFlow(props) {
  const { auth, notarization } = useSelector(state => state);
  let journalId = props.journalID;

  return (
    <>
      <Card style={{ width: "100%", height: "108px" }} variant="outlined">
        <CardContent>
          <Tooltip
            placement="up"
            title={
              <h6 style={{ color: "white", width: "100%" }}>
                When all parties have finished signing the document, click this
                button to close the session out and release the document for
                download. Once you do this, it will clear this transaction and
                end the session.
              </h6>
            }
          >
            <h6>
              Complete Notarization
              <img
                src={`/media/QuestionMark/questionmarktooltip.png`}
                style={{
                  float: "right",
                  marginRight: "-13px",
                  marginTop: "-3px"
                }}
                alt="tooltip"
              />
            </h6>
          </Tooltip>
        </CardContent>
        <CardActions>
          <div className="text-center">
            <Button
              variant="contained"
              style={{
                backgroundColor: "#2d3fd3",
                color: "white",
                marginTop: -4
              }}
              onClick={props.completeNotarization}
            >
              Complete Notarization
            </Button>
          </div>
        </CardActions>
      </Card>
    </>
  );
}
