import React, { useMemo, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import * as actions from "../../../../app/components/_redux/notarization_redux/notarizationActions";
import objectPath from "object-path";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { Topbar } from "./Topbar";
import { HeaderMenuWrapper } from "./header-menu/HeaderMenuWrapper";

export function Header() {
  const uiService = useHtmlClassService();
  const dispatch = useDispatch();
  const location = useLocation();

  const { auth } = useSelector(state => state);
  const headers = {
    'Authorization': `Bearer ${auth.authToken}`,
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
  const { isProfileCompleted } = useSelector(
    ({ auth }) => ({
      isProfileCompleted: ((auth.user) && (auth.user.notaryProfile)) ? (auth.user.notaryProfile.profile_completed) ? auth.user.notaryProfile.profile_completed : false : true,

    }),
    shallowEqual
  );


  const { isApproved, isCancelled } = useSelector(
    ({ auth }) => ({
      isApproved: ((auth.user) && (auth.user.notaryProfile)) ? (auth.user.notaryProfile.isApproved) ? auth.user.notaryProfile.isApproved : false : true,
      isCancelled: auth.user && auth.user.notaryProfile
        ? auth.user.notaryProfile.isCancelled
          ? auth.user.notaryProfile.isCancelled
          : false
        : true  
    }),
    shallowEqual
  );
  const layoutProps = useMemo(() => {
    return {
      headerClasses: uiService.getClasses("header", true),
      headerAttributes: uiService.getAttributes("header"),
      headerContainerClasses: uiService.getClasses("header_container", true),
      menuHeaderDisplay: objectPath.get(
        uiService.config,
        "header.menu.self.display"
      ),
    };
  }, [uiService]);

  useEffect(() => {


    if (auth.user.Role.code !== 'admin') {

      dispatch(actions.fetchDefaultPaymentCard({ userID: auth.user.id }, headers));
    }

  }, [dispatch, location]);




  return (
    <>
      {/*begin::Header*/}
      <div
        className={`header ${layoutProps.headerClasses}`}
        id="kt_header"
        {...layoutProps.headerAttributes}
      >
        {/*begin::Container*/}
        <div
          className={` ${layoutProps.headerContainerClasses} d-flex align-items-stretch justify-content-between`}
        >
          {/* begin::Left */}
          <div className="d-flex align-items-stretch mr-3">
            {/* begin::Header Logo */}
            <div className="header-logo">
              <Link to="/">
                <img
                  className="logo-default max-h-85px"
                  alt="Logo"
                  src={toAbsoluteUrl("/media/logos/cyberizeit_logo.png")}
                />
                <img
                  className="logo-sticky max-h-85px"
                  alt="Logo"
                  src={toAbsoluteUrl("/media/logos/cyberizeit_logo.png")}
                />
              </Link>
            </div>
            {/* end::Header Logo */}
            {(isProfileCompleted) && ( isApproved || isCancelled ) && layoutProps.menuHeaderDisplay && <HeaderMenuWrapper />}
          </div>
          {/* end::Left */}

          {/*begin::Topbar*/}
          <Topbar />
          {/*end::Topbar*/}
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Header*/}
    </>
  );
}
