// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React, { useEffect, useMemo, useState } from "react";

import {
  CustomerTypeCssClasses,
  CustomerTypeTitles
} from "../../TableUIHelpers";

export function CheckallFormatter(cellContent, row, rowIndex, props) {
  let currentstatus = false;
  return (
    <>
      <span
        className={`label label-dot label-${
          CustomerTypeCssClasses[row.type]
        } mr-2`}
      >
        {props.ischeckall ? (
          <input
            type="checkbox"
            className="statuschecker"
            checked={props.ischeckall || currentstatus}
            id={"check" + row.id}
            value={row.id}
            name={"check" + row.id}
            disabled={row.status == 0 || row.status == 3 ? false : true}
          />
        ) : (
          <input
            type="checkbox"
            className="statuschecker"
            value={row.id}
            id={"check" + row.id}
            name={"check" + row.id}
            disabled={row.status == 0 || row.status == 3 ? false : true}
          />
        )}
      </span>
    </>
  );
}
