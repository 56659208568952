import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import IDQuiz from "../components/IDProofing/IDQuiz";
import IDUpload from "../components/IDProofing/IDUpload";
import Biometric from "../components/IDProofing/Biometric";
import ShowNotification from "../components/new_notarization/ShowNotification";

import { Divider } from "@material-ui/core";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { Row, Col } from "react-bootstrap";

import * as actions from "../components/_redux/notarization_redux/notarizationActions";
import "./../pages/CSS/Style.css"

export const IDProofingPage = () => {

  const dispatch = useDispatch();
  
  const { auth, socket, notarization } = useSelector(state => state);
  const buttonColours = ['red','green','blue','orange'];
  const buttonTexts = [ "Currently not available", "Click Here to Start your ID Quiz", "Please re-confirm your ID Quiz", "Please re-confirm your ID Quiz"];
  const { signerIdProofingDetails } = notarization;
  
  
  const [data, setData] = useState(null);
  const [link, setLink] = useState(null);
  const [kbaButtonDisabled, setKbaButtonDisabled] = useState(false);
  const [idUploadDisabled, setIdUploadDisabled] = useState(true);
  const [notification, setNotification] = useState({
    show: false,
    msg: "",
    variant: ""
  });

  /* to get signer id from params */
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const signerId = searchParams.get("id");
  
  useEffect(() => {
    dispatch(actions.GetIdProofingDetail( {signerId: signerId }))
  }, []);

  useEffect(()=> {
    let signerDetails = JSON.parse(JSON.stringify(signerIdProofingDetails)); 
    if( null !== signerDetails ) {
      let transactionType = ( signerDetails?.SignerAuths && signerDetails?.SignerAuths[signerDetails?.SignerAuths.length-1]?.type === "biometric" ) ? "biometric" : "kba";
      if (signerDetails.signer && (signerDetails?.signer?.biometric || transactionType === "biometric") ) {
        signerDetails.signer.type = "biometric";
      } else if( signerDetails.signer )  {
        signerDetails.signer.type = "kba";
      }
      for(let auth of signerDetails?.SignerAuths){
        if(auth.type != "kba"&& auth.link != null){
          signerDetails.idologyLink = auth.link;
        }
      }
    }
    setData(signerDetails);
  }, [signerIdProofingDetails])

  useEffect(() => {

    const updatedData = { ...data };
    if(
        ( socket.signerPageAttempts && socket.signerPageAttempts?.signerId == signerId && socket.signerPageAttempts?.signerId > data?.signer?.attempts ) ||
        ( socket.signerPageAttemptsExceeded && socket.signerPageAttemptsExceeded?.signerId == signerId ) ||
        ( socket.kbaVerificationRejected && socket.kbaVerificationRejected?.signerId == signerId ) ||
        ( socket.kbaVerificationRejectedNotary && socket.kbaVerificationRejectedNotary?.signerId == signerId )
      )
    {
      updatedData.status = "failed";
    }
    else if(socket.kbaVerificationSingle && socket.kbaVerificationSingle?.signerId == signerId){
      updatedData.completeVerification = true;
    }
    if(socket.biometricLink ) {
      updatedData.signer.type = 'biometric'
      updatedData.signer.attempts = socket.biometricLink.attempts
      updatedData.attempts = socket.biometricLink.attempts
      updatedData.idologyLink = socket.biometricLink.link;
    }
    if( socket.idologyLink ) {
      setIdUploadDisabled(false);
      updatedData.signer.type = 'idUpload'
      updatedData.signer.attempts = 1
      updatedData.idologyLink = socket.idologyLink.link;
    }
    if( socket.idScanLink ) {
      setIdUploadDisabled(false);
      updatedData.signer.type = 'idUpload'
      updatedData.signer.attempts = socket.idScanLink.attempts
      updatedData.idologyLink = socket.idScanLink.link;
    }
    setData({...updatedData});
  }, [
    socket.kbaVerificationSingle,
    socket.kbaVerificationRejectedNotary,
    socket.kbaVerificationRejected,
    socket.idologyLink,
    socket.idScanLink,
    socket.biometricLink,
    socket.signerPageAttemptsExceeded,
    socket.signerPageAttempts,
  ]);

  window.addEventListener("beforeunload", e => {
    e.preventDefault();
    e.returnValue = "";
  });

  const signerLinkVerification = () => {
    const updatedData = { ...data };
    setKbaButtonDisabled(true);
    dispatch(actions.signerLinkVerification({signerId: signerId }))
    .then((response)=>{
      let data = response.data;
      if( data.status === "success" ) {
        // updatedData.signer.attempts = (data.type === "biometric" || data.type === "idScan") ? data.attempts : (data.Signer.attempts+1);
        // updatedData.attempts = (data.type === "biometric" || data.type === "idScan") ? data.attempts : (data.Signer.attempts+1);
        updatedData.type = data.type
        setData({...updatedData})

        setLink(data.link)

        setTimeout(() => {
          // window.open(data.link, '_blank');
          document.getElementById("kbaLinkHref").click();
          setLink(null)
        })
      }
    })
    .catch((error)=>{
      let data = error.response.data;
      if (data.status === "failed") {
        dispatch(actions.GetIdProofingDetail( {signerId: signerId }))
        .then(()=>{
          setKbaButtonDisabled(false);
        })
        setNotification({
          show: true,
          msg: data.message,
          variant: "error"
        });
      }
    })
  }

  const sendEmail = async type => {
    dispatch(actions.sendLinksViaEmail( {
      signerId: signerId,
      type
    }))
    .then((response) => {
      if (response.data.status === "success") {
        setNotification({
          show: true,
          msg: "Email has been sent successfully",
          variant: "success"
        });
      }
    })
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") return;
    setNotification({ show: false, msg: "", variant: "" });
  };

  return (
    <div className="container p-5">
      <Divider
        style={{
          height: "3px",
          backgroundColor: "blue"
        }}
      />
      {(data?.action === "attemptsExceeded") ? (
        <div
          style={{
            display: "grid",
            justifyItems: "center",
            fontSize: "14px",
            color: "#0400F0"
          }}
        >
          You have exceeded your allotted attempts for ID Proofing, please
          contact {auth.user && auth.user.primaryNotary ? auth.user.primaryNotary : "your Primary Notary "} {auth.user && auth.user.primaryNotaryEmail ? "at " + auth.user.primaryNotaryEmail : "" } for further
          assistance.
        </div>
      ) : (
        <div className="card-custom idProofing">
          <div className="container">
            <Row>
              <Col xs={12} md={4} sm={12} lg={4} >
                <img
                  className="logo-sticky max-h-250px"
                  alt="Exclamation Mark"
                  src={toAbsoluteUrl(
                    "media/id-proofing/id-proofing-exclamation.webp"
                  )}
                />
              </Col>
              <Col xs={12} md={6} sm={12} lg={6}>
                <p>
                  <b
                    style={{
                      color: "#0400F0",
                      fontSize: "50px"
                    }}
                  >
                    You must confirm your identity using the links provided
                    below.
                  </b>
                </p>
                <b
                  style={{
                    color: "#E02B20",
                    fontSize: "25px"
                  }}
                >
                  DO NOT EXIT THIS BROWSER WINDOW UNTIL ALL STEPS HAVE BEEN
                  COMPLETED SUCESSFULLY!
                </b>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={3} md={3}></Col>
              <Col xs={12} sm={12} md={4} lg={4}>
                <div>
                  <div>
                    <h5 style={{ color: "#0400F0" }}>Questions or Issues?</h5>
                    <p style={{ fontSize: "14px" }}>
                      If you have a question or issue during this ID Proofing
                      process, please reach out to {data?.notaryDetail?.firstName && data.notaryDetail.firstName}{" "}
              {data?.notaryDetail?.lastName && data.notaryDetail.lastName} via email at {" "}
              {data?.notaryDetail?.email && data.notaryDetail.email} or by phone at {data?.notaryDetail?.phone ? data.notaryDetail.phone : "N/A"}.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <br />
          <br />
          <Divider
            style={{
              height: "3px",
              backgroundColor: "blue"
            }}
          />
          <br />
          <IDQuiz 
            data={data} 
            buttonText={ ( data?.signer && data?.signer?.attempts) ? buttonTexts[data?.signer?.attempts] : buttonTexts[0] }
            buttonColour={ ( data?.signer && data?.signer?.attempts) ? buttonColours[data?.signer?.attempts] : buttonColours[0] }
            signerLinkVerification={signerLinkVerification}
            buttonDisabled={kbaButtonDisabled} 
            link={link}
          />
          { 
            data?.signer && data?.signer?.type === 'biometric' ? (
              <Biometric
                data={data} 
                buttonText={ ( data?.signer && data?.signer?.attempts) ? buttonTexts[data?.signer?.attempts] : buttonTexts[0] }
                buttonColour={ data?.attempts ? buttonColours[data?.attempts] : buttonColours[0] }
                signerLinkVerification={signerLinkVerification}
                buttonDisabled={false} 
                sendEmail={sendEmail}
              />
            ) : (
              <IDUpload
              data={data} 
              buttonText={ ( data?.signer && data?.signer?.attempts) ? buttonTexts[data?.signer?.attempts] : buttonTexts[0] }
              buttonColour={ ( data?.signer && data?.signer?.attempts) ? buttonColours[data?.signer?.attempts] : buttonColours[0] }
              signerLinkVerification={signerLinkVerification}
              buttonDisabled={idUploadDisabled}
                sendEmail={sendEmail}
              />
            )
          }
        </div>
      )}
      {notification.show && (
        <ShowNotification
          show={notification.show}
          msg={notification.msg}
          variant={notification.variant}
          close={handleCloseNotification}
        />
      )}
    </div>
  );
};
