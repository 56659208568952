import React, { useEffect } from "react";
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import * as actions from "../components/_redux/notarization_redux/notarizationActions";

export function CustomModalForJoinZoomLink() {
  const { auth, notarization } = useSelector(state => state);
  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };
  const dispatch = useDispatch();
  const [errMSG, setErrMSG] = useState("");
  const [loading, setLoading] = useState(false);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const FinalizePaymentCall = () => {
    dispatch(
      actions.finalizePayment(
        {
          title: "Final Payment",
          currency: "usd",
          journalId: 294,
          sealCount: 4,
          notaryType: "Jurat"
        },
        headers,
        setErrMSG,
        enableLoading,
        disableLoading
      )
    );
  };

  const [showModal, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="secondary" onClick={handleShow}>
        Accept
      </Button>
      <Modal show={showModal} onHide={handleClose} dialogClassName="my-modal">
        <Alert severity="success">{errMSG}</Alert>
        <Modal.Header closeButton>
          <Modal.Title>Payment Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Grid container spacing={1}>
            <Grid item xs>
              <div>
                <div>
                  <h6>Payment Breakdown</h6>
                </div>
              </div>
            </Grid>
          </Grid>
          <div>
            <div class="d-flex justify-content-between">
              <div>Platform Fee</div>
              <div>
                <span
                  style={{
                    backgroundColor: "#4abd54",
                    color: "white",
                    borderRadius: "4px",
                    marginRight: "10px",
                    padding: "2px"
                  }}
                >
                  paid
                </span>
                <span>$ 9.99</span>
              </div>
            </div>
          </div>
         
          <div>
            <div className="d-flex justify-content-between mt-2">
              <div>
                <span className="mr-3">Number Of Seals</span>
                <span style={{ border: "1px solid #a09d9d" }}>01</span>
              </div>
              <div>$ 25</div>
            </div>
          </div>
          <div>
            <div className="d-flex justify-content-between mt-2">
              <div>
                <span className="mr-3">Type of documents</span>
                <span>
                  {" "}
                  <select>
                    <option value="">Select</option>
                    <option value="acknowledgement">Acknowledgement</option>
                    <option value="jurat">Jurat</option>
                    <option value="attesting-signature">
                      Attesting Signature
                    </option>
                  </select>
                </span>
              </div>
            </div>
          </div>
          <div>
            <div class="d-flex justify-content-between mt-4">
              <div>
                <h5>Total Amount</h5>
              </div>
              <div>$ 25</div>
            </div>
          </div>
          <Grid container spacing={1}>
            <Grid item xs>
              <div className="confirm_and_chargepay">
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#4abd54",
                    color: "white",
                    marginTop: "40px"
                  }}
                  onClick={FinalizePaymentCall}
                >
                  Confirm & Charge Pay
                </Button>
              </div>
            </Grid>
          </Grid>
        </Modal.Body>
      </Modal>
    </>
  );
}
