import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../../_metronic/_partials/controls";
import { CustomTable } from "./table/CustomTable";
import DownlaodIcon from "../../../media/zeroState/downloadicon.webp";
import { CSVLink } from "react-csv";
import { Button, Modal, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/notarization_redux/notarizationActions";

export function TableCard() {
  const MIDDLEWARE_URL = process.env.REACT_APP_MIDDLEWARE_URL;
  const { auth, notarization } = useSelector(state => state);
  const { allJournals, allCSVJournals, paymentDetails } = notarization;
  const [checkerID, setcheckerID] = useState([]);
  const [actionModal, setActionModal] = useState(false);
  const [showDialog, setshowDialog] = useState(false);
  const [showrestoreDialog, setshowrestoreDialog] = useState(false);

  const [csvData, setCsvData] = useState([
    [
      "Customer Name",
      "Notary",
      "startDate",
      "endDate",
      "Type of Notarization",
      "Price of Notarization",
      "Number of Seals (Stamps)",
      "scheduledAt",
      "Number of Signers",
      "status",
      "Documents",
      "Signer 1",
      "Signer 2",
      "Signer 3",
      "Signer 4",
      "Witnesses"
    ]
  ]);

  function GetFinalPayment(seals, witnessCount) {
    let total =
      seals === 0
        ? 0
        : paymentDetails?.sealFirstFee +
          (seals - 1) * paymentDetails?.sealSecFee;
    let notaryFee =
      seals === 0
        ? 0
        : paymentDetails?.notaryFeePerFirstSeal +
          (seals - 1) * paymentDetails?.notaryFeePerSecSeal;
    let platformFee =
      seals === 0
        ? 0
        : paymentDetails?.platformPerFirstSeal +
          (seals - 1) * paymentDetails?.platformPerSecSeal;
    let witnessFee =
      witnessCount === 0 ? 0 : witnessCount * paymentDetails?.witnessFee;
    total += witnessFee;

    return {
      total: total,
      notaryFee: notaryFee,
      platformFee: platformFee,
      witnessFee: witnessFee
    };
  }

  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };
  const dispatch = useDispatch();
  const deleteNotarization = () => {
    let checkerarr = [];
    var x = document.querySelectorAll(".statuschecker:checked:not(:disabled)");
    var i;
    for (i = 0; i < x.length; i++) {
      checkerarr.push(x[i].value);
    }

    setcheckerID(checkerarr);

    dispatch(actions.deleteHardNotarization(checkerarr, headers)).then(
      response => {
        console.log("response", response);
      }
    );
  };

  const restoreNotarization = () => {
    let checkerarr = [];
    var x = document.querySelectorAll(".statuschecker:checked:not(:disabled)");
    var i;
    for (i = 0; i < x.length; i++) {
      checkerarr.push(x[i].value);
    }

    setcheckerID(checkerarr);

    dispatch(actions.restoreNotarization(checkerarr, headers)).then(
      response => {
        console.log("response", response);
      }
    );
  };

  const showNotarizationPopup = (isdeleteID = 0) => {
    if (isdeleteID > 0) {
      document.getElementById("check" + isdeleteID).checked = true;
      setshowDialog(true);
    }

    if (
      document.querySelectorAll(".statuschecker:checked:not(:disabled)")
        .length > 0
    )
      setshowDialog(true);
  };

  const restore = (isdeleteID = 0) => {
    if (isdeleteID > 0) {
      document.getElementById("check" + isdeleteID).checked = true;
      setshowrestoreDialog(true);
    }
    if (
      document.querySelectorAll(".statuschecker:checked:not(:disabled)")
        .length > 0
    )
      setshowrestoreDialog(true);
  };

  const hideNotarizationPopup = () => {
    setshowDialog(false);
    setshowrestoreDialog(false);
  };

  useEffect(() => {
    let tmpdata = [];
    let journalinfo;
    if (csvData.length < 2 && allCSVJournals && allCSVJournals.rows) {
      for (let counter = 0; counter < allCSVJournals.rows.length; counter++) {
        let data = csvData;

        if (
          allCSVJournals.rows[counter].customer &&
          allCSVJournals.rows[counter].notary
        ) {
          let startdate, endDate;
          if (allCSVJournals.rows[counter].scheduledAt) {
            startdate = new Date(allCSVJournals.rows[counter].scheduledAt);
            if (startdate)
              startdate =
                startdate.toDateString() + " " + startdate.toTimeString();
            //startdate = startdate.toGMTString();
          }

          if (allCSVJournals.rows[counter].endDate) {
            endDate = new Date(allCSVJournals.rows[counter].endDate);
            if (endDate)
              endDate = endDate.toDateString() + " " + endDate.toTimeString();
          }

          let typeofnotarization = "";

          if (allCSVJournals.rows[counter].type)
            typeofnotarization = allCSVJournals.rows[counter].type;

          let paymentBreakDown = null;
          let filteredWitnesses = [];
          if (data !== undefined) {
            filteredWitnesses =
              allCSVJournals.rows[counter].Witnesses.length > 0
                ? allCSVJournals.rows[counter].Witnesses.filter(
                    el => el.status !== "rejected"
                  )
                : [];
            paymentBreakDown = GetFinalPayment(
              allCSVJournals.rows[counter].sealCount !== null
                ? allCSVJournals.rows[counter].sealCount
                : 0,
              filteredWitnesses.length
            );
          }

          let totalPrice = "--";
          if (
            data !== undefined &&
            allCSVJournals.rows[counter].Payments.length > 0
          ) {
            totalPrice = 0;
            totalPrice += allCSVJournals.rows[counter].Payments[0].amount / 100;
            totalPrice += paymentBreakDown.total;
            totalPrice = "$" + totalPrice.toFixed(2);
          }

          tmpdata = [
            allCSVJournals.rows[counter].customer.firstName +
              " " +
              allCSVJournals.rows[counter].customer.lastName,
            allCSVJournals.rows[counter].notary.firstName +
              " " +
              allCSVJournals.rows[counter].notary.lastName +
              " \r\n" +
              allCSVJournals.rows[counter].notary.email +
              " \r\n" +
              allCSVJournals.rows[counter].notary.phone,
            startdate,
            endDate,
            typeofnotarization,
            totalPrice,
            allCSVJournals.rows[counter].sealCount,
            startdate,
            allCSVJournals.rows[counter].Signers.length,
            allCSVJournals.rows[counter].status
          ];

          journalinfo = [];
          for (
            let icount = 0;
            icount < allCSVJournals.rows[counter].journalDocs.length;
            icount++
          ) {
            if (allCSVJournals.rows[counter].journalDocs[icount].File.filename)
              journalinfo.push(
                allCSVJournals.rows[counter].journalDocs[icount].File.baseUrl +
                  "/" +
                  allCSVJournals.rows[counter].journalDocs[icount].File.path
              );

            //data.push
          }

          tmpdata.push(journalinfo.join(" \r\n"));

          for (let icount = 0; icount < 4; icount++) {
            let signerinfo = "-";
            if (allCSVJournals.rows[counter].Signers[icount]) {
              signerinfo =
                allCSVJournals.rows[counter].Signers[icount].firstName +
                " " +
                allCSVJournals.rows[counter].Signers[icount].lastName +
                " \r\n" +
                allCSVJournals.rows[counter].Signers[icount].email +
                " \r\n" +
                allCSVJournals.rows[counter].Signers[icount].address +
                " \r\n" +
                allCSVJournals.rows[counter].Signers[icount].city +
                " " +
                allCSVJournals.rows[counter].Signers[icount].state +
                ", " +
                allCSVJournals.rows[counter].Signers[icount].zip;

              if (allCSVJournals.rows[counter].Signers[icount].IP)
                signerinfo +=
                  " \r\n" + allCSVJournals.rows[counter].Signers[icount].IP;

              if (allCSVJournals.rows[counter].Signers[icount].bypassKBA) {
                signerinfo +=
                  " \r\n BY PASS KBA Reason: " +
                  allCSVJournals.rows[counter].Signers[icount].bypassReason;
              }

              if (!allCSVJournals.rows[counter].Signers[icount].bypassKBA) {
                signerinfo +=
                  " \r\n " +
                  MIDDLEWARE_URL +
                  "/api/kba/scanned-doc/" +
                  allCSVJournals.rows[counter].Signers[icount].id +
                  "/first";
                signerinfo +=
                  " \r\n " +
                  MIDDLEWARE_URL +
                  "/api/kba/scanned-doc/" +
                  allCSVJournals.rows[counter].Signers[icount].id +
                  "/firstBack";
              }
            }
            tmpdata.push(signerinfo);
            //data.push
          }
          let witnessinfo = [];
          for (
            let icount = 0;
            icount < allCSVJournals.rows[counter].Witnesses.length;
            icount++
          ) {
            if (allCSVJournals.rows[counter].Witnesses[icount].User) {
              let signerinfo =
                allCSVJournals.rows[counter].Witnesses[icount].User.firstName +
                " " +
                allCSVJournals.rows[counter].Witnesses[icount].User.lastName +
                " \r\n" +
                allCSVJournals.rows[counter].Witnesses[icount].User.email;

              if (allCSVJournals.rows[counter].Witnesses[icount].IP)
                signerinfo +=
                  " \r\n" + allCSVJournals.rows[counter].Witnesses[icount].IP;

              witnessinfo.push(signerinfo);
            }
          }

          tmpdata.push(witnessinfo.join(" \r\n"));
          data.push(tmpdata);

          //data.push(allCSVJournals.rows[counter].type);

          setCsvData(data);
        }
      }
    }
  });

  return (
    <Card>
      <CardHeader title="Journals" className="py-6">
        <CardHeaderToolbar>
          {auth.user.Role.code === "notary" &&
          allCSVJournals &&
          allCSVJournals.rows ? (
            <CSVLink data={csvData} filename="Journals.csv">
              <button
                type="button"
                className="btn"
                style={{
                  backgroundColor: "#f7f7f7",
                  color: "#4abd54",
                  marginRight: "10px"
                }}
              >
                <img src={DownlaodIcon} className="downlaodIcon" alt="" />
                Download as CSV
              </button>
            </CSVLink>
          ) : (
            ""
          )}

          {auth.user.Role.code === "ps" && (
            <button
              type="button"
              className="btn btn-success"
              style={{ marginRight: "10px" }}
              onClick={restore}
            >
              Restore
            </button>
          )}

          {auth.user.Role.code === "ps" && (
            <button
              type="button"
              className="btn btn-danger"
              style={{ marginRight: "10px" }}
              onClick={showNotarizationPopup}
            >
              Delete Parmanently
            </button>
          )}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <CustomTable
          showNotarizationPopup={showNotarizationPopup}
          restore={restore}
        />
        <Modal
          size="lg"
          show={showDialog}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Body className="overlay overlay-block cursor-default">
            <h1>Confirmation</h1>
            <div className="modal-body-content">
              Are you sure you want to delete this notarization parmanently?{" "}
            </div>
            <div className="modal-body">
              <Button
                variant="secondary"
                className="acceptBtn"
                style={{ marginTop: "20px", float: "right" }}
                onClick={() => deleteNotarization()}
              >
                Submit
              </Button>

              <Button
                variant="secondary"
                className="cancelBtn"
                style={{
                  marginTop: "20px",
                  marginRight: "10px",
                  float: "right"
                }}
                onClick={() => hideNotarizationPopup()}
              >
                Cancel
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          show={showrestoreDialog}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Body className="overlay overlay-block cursor-default">
            <h1>Confirmation</h1>
            <div className="modal-body-content">
              Are you sure you want to restore this notarization?{" "}
            </div>
            <div className="modal-body">
              <Button
                variant="secondary"
                className="acceptBtn"
                style={{ marginTop: "20px", float: "right" }}
                onClick={() => restoreNotarization()}
              >
                Submit
              </Button>

              <Button
                variant="secondary"
                className="cancelBtn"
                style={{
                  marginTop: "20px",
                  marginRight: "10px",
                  float: "right"
                }}
                onClick={() => hideNotarizationPopup()}
              >
                Cancel
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </CardBody>
    </Card>
  );
}
