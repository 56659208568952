import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
// import { getUserByToken } from "./authCrud";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  NotaryRegistration: "[NotaryRegistration] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  UserProfileUpdate: "UPDATE_PROFILE",
  UserProfileUpdateUser: "UPDATE_USER_PROFILE",
  PassportImageUpdate: "UPDATE_PASSPORT_IMAGE",
  InsurancePolicyImageUpload: "Insurance_Policy_Image_Upload",
  MyPrimaryNotary: "My_Primary_Notary",
  profileAvatar: "profile_Avatar",
  userProfileAvatar: "user_Profile_Avatar",
  deleteProfile: "delete_Profile",
  deleteUserProfile: "delete_User_Profile",
  userRegistered: "user_registered",
  dp_uploading: "USER_PROFILE_UPLOADING",
  notary_dp_uploading: "NOTARY_PROFILE_UPLOADING",
  stripe_verification: "STRIPE_VERIFICATION"
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  passportID: null,
  insurancePolicyID: null,
  MyPrimaryNotaryID: null,
  notaryProfile: null,
  userProfile: null,
  profileAvatarID: null,
  userProfileAvatarID: null,
  isRegister: false,
  uploadingImage: false,
  uploadingImageNotary: false,
  stripeVerficationAccountStatus: null,
  stripeVerficationUrl: null
};

export const reducer = persistReducer(
  { storage, key: "v-706-demo2-auth", whitelist: ["user", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload;
        return { authToken: authToken.accessToken, user: authToken.user };
      }
      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }
      case actionTypes.NotaryRegistration: {
        const { authToken } = action.payload;
        return { authToken, user: undefined };
      }
      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }
      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }
      case actionTypes.UserProfileUpdate: {
        const user = action.payload;
        return { ...state, user };
      }
      case actionTypes.UserProfileUpdateUser: {
        const user = action.payload;
        return { ...state, user };
      }
      case actionTypes.PassportImageUpdate: {
        const passportID = action.payload;
        return { ...state, passportID };
      }
      case actionTypes.InsurancePolicyImageUpload: {
        const insurancePolicyID = action.payload;

        return { ...state, insurancePolicyID };
      }
      case actionTypes.MyPrimaryNotary: {
        const MyPrimaryNotaryID = action.payload;
        return { ...state, MyPrimaryNotaryID };
      }
      case actionTypes.profileAvatar: {
        const profileAvatarID = action.payload;
        return { ...state, profileAvatarID };
      }
      case actionTypes.userProfileAvatar: {
        const userProfileAvatarID = action.payload;
        return { ...state, userProfileAvatarID };
      }
      case actionTypes.deleteProfile: {
        const notaryProfile = action.payload;
        return { ...state, notaryProfile };
      }
      case actionTypes.deleteUserProfile: {
        const userProfile = action.payload;
        return { ...state, userProfile };
      }
      case actionTypes.userRegistered: {
        const isRegister = action.payload;
        return { ...state, isRegister };
      }
      case actionTypes.dp_uploading: {
        const uploadingImage = action.payload;
        return { ...state, uploadingImage };
      }
      case actionTypes.notary_dp_uploading: {
        const uploadingImageNotary = action.payload;
        return { ...state, uploadingImageNotary };
      }
      case actionTypes.stripe_verification: {
        const stripeVerficationAccountStatus = action.payload.accountStatus;
        const stripeVerficationUrl = action.payload.url.url;
        return {
          ...state,
          stripeVerficationAccountStatus,
          stripeVerficationUrl
        };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  login: authToken => ({ type: actionTypes.Login, payload: { authToken } }),
  register: authToken => ({
    type: actionTypes.Register,
    payload: { authToken }
  }),
  notaryregistration: authToken => ({
    type: actionTypes.NotaryRegistration,
    payload: { authToken }
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({
    type: actionTypes.UserRequested,
    payload: { user }
  }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } })
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });
  yield takeLatest(
    actionTypes.NotaryRegistration,
    function* notaryregisterSaga() {
      yield put(actions.requestUser());
    }
  );
  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    // const { data: user } = yield getUserByToken();
    // yield put(actions.fulfillUser(user));
  });
}
