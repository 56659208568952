import axios from 'axios'
const MIDDLEWARE_URL = process.env.REACT_APP_MIDDLEWARE_URL

export function uploadDocuments(documents, headers) {
    headers['Content-Type'] = 'multipart/form-data'
    return axios.post(`${MIDDLEWARE_URL}/api/upload/uploadDoc`, documents, {
        headers: headers,
    })
}

export function findNotaries(headers) {
    return axios.get(`${MIDDLEWARE_URL}/api/notary/all-notary?sortBy=ASC`, {
        headers: headers,
    })
}

export function findFilteredNotaries(queries, headers) {
    let url = `${MIDDLEWARE_URL}/api/notary/all-notary?sortBy=ASC&sortField=firstName&page=${queries.page}&limit=${queries.limit}`
    url = queries.q !== '' ? url + `&q=${queries.q}` : url
    url = queries.lang !== '' ? url + `&lang=${queries.lang}` : url
    url = queries.type !== '' ? url + `&notaryType=${queries.type}` : url
    url = queries.state !== '' ? url + `&state=${queries.state}` : url
    // url = online !== null ? url + `&status=online` : url;
    return axios.get(url, { headers: headers })
}

export function findFilteredOnlineNotaries(queries, headers) {
    let url = `${MIDDLEWARE_URL}/api/notary/all-notary?sortBy=ASC&sortField=firstName&page=${queries.page}&limit=${queries.limit}&status=online`
    url = queries.q !== '' ? url + `&q=${queries.q}` : url
    url = queries.lang !== '' ? url + `&lang=${queries.lang}` : url
    url = queries.type !== '' ? url + `&notaryType=${queries.type}` : url
    url = queries.state !== '' ? url + `&state=${queries.state}` : url
    return axios.get(url, { headers: headers })
}

export function findClients(headers, queries) {
    return axios.get(
        `${MIDDLEWARE_URL}/api/notary/clients?page=${queries.pageNumber}&limit=${queries.pageSize}&sortField=email&sortBy=asc`,
        { headers: headers }
    )
}

export function findAllClients(headers) {
    return axios.get(
        `${MIDDLEWARE_URL}/api/notary/clients?page=1&limit=-1&sortField=email&sortBy=asc`,
        { headers: headers }
    )
}

export function emailMeetingLink(journalID, headers) {
    return axios.post(
        `${MIDDLEWARE_URL}/api/journal/${journalID}/emailMeetingLink`,
        {},
        { headers: headers }
    )
}

export function getbankAccount(headers) {
    return axios.get(`${MIDDLEWARE_URL}/api/account/listAccounts`, {
        headers: headers,
    })
}

export function getNotificationCurrent(headers) {
    return axios.get(`${MIDDLEWARE_URL}/api/journal/notifications/getAll`, {
        headers: headers,
    })
}

export function getQuestions(headers) {
    return axios.get(`${MIDDLEWARE_URL}/api/account/listTransaction`, {
        headers: headers,
    })
}

export function getNotaryTypes(headers) {
    return axios.get(`${MIDDLEWARE_URL}/api/notary/notaryTypes`, {
        headers: headers,
    })
}

export function getLanguages(headers) {
    return axios.get(`${MIDDLEWARE_URL}/api/language/languages`, {
        headers: headers,
    })
}

export function getMyPayments(headers, queries) {
    return axios.get(
        `${MIDDLEWARE_URL}/api/account/listTransaction?page=${queries.pageNumber}&limit=${queries.pageSize}&sortField=${queries.sortField}&sortBy=${queries.sortOrder}`,
        { headers: headers }
    )
}

export function makePrimary(param, headers) {
    return axios.post(`${MIDDLEWARE_URL}/api/account/makeDefaultBank`, param, {
        headers: headers,
    })
}

export function AddWitness(param, headers) {
    return axios.post(`${MIDDLEWARE_URL}/api/witness/addWitness`, param, {
        headers: headers,
    })
}

export function PostWitnessJob(param, headers) {
    return axios.post(`${MIDDLEWARE_URL}/api/witness/postWitnessJob`, param, {
        headers: headers,
    })
}

export function claimWitnessJob(body, headers) {
    return axios.post(`${MIDDLEWARE_URL}/api/witness/claimWitnessJob`, body, {
        headers: headers,
    })
}

export function getWitnessesforJournal(journalID, headers) {
    return axios.get(
        `${MIDDLEWARE_URL}/api/witness/getWitness?journalId=${journalID}`,
        { headers: headers }
    )
}

export function AddBankAccount(queries, headers) {
    return axios.post(
        `${MIDDLEWARE_URL}/api/account/createNewAccount`,
        queries,
        {
            headers: headers,
        }
    )
}

export function deleteDocumentByID(id, headers) {
    return axios.delete(`${MIDDLEWARE_URL}/api/upload/removeJournalDoc/${id}`, {
        headers: headers,
    })
}
export function deleteCard(id, headers) {
    return axios.delete(`${MIDDLEWARE_URL}/api/payment/${id}`, {
        headers: headers,
    })
}

export function removeFromNotary(id, headers) {
    return axios.post(
        `${MIDDLEWARE_URL}/api/ps/removeNotary`,
        { notaryIds: [id] },
        { headers: headers }
    )
}

export function getAllJournals(defaultQuery, headers, queryParams) {
    console.log(queryParams);

let queryParamStr = "";
    if (queryParams?.customer) {
        queryParamStr += `&customer=${queryParams.customer}`
    }
    if (queryParams?.from) {
        queryParamStr += `&fromDate=${queryParams.from}`
    }
    if (queryParams?.to) {
        queryParamStr += `&toDate=${queryParams.to}`
    }
    if (queryParams?.status) {
        queryParamStr += `&status=${queryParams.status}`
    }
    if (queryParams?.name) {
        queryParamStr += `&search=${queryParams.name}`
    }

    let url = `${MIDDLEWARE_URL}/api/journal/all-journal?page=${defaultQuery.pageNumber}&limit=${defaultQuery.pageSize}&sortField=${defaultQuery.sortField}&sortBy=${defaultQuery.sortOrder}${queryParamStr}`

    return axios.get(
        url,
        { headers: headers }
    )
}

export function getAllDeletedJournals(queries, headers) {
    return axios.get(
        `${MIDDLEWARE_URL}/api/journal/deleted?page=${queries.pageNumber}&limit=${queries.pageSize}&sortField=${queries.sortField}&sortBy=${queries.sortOrder}`,
        { headers: headers }
    )
}

export function getAllJobpost(queries, headers) {
    return axios.get(
        `${MIDDLEWARE_URL}/api/journal/all-jobs?page=${queries.pageNumber}&limit=${queries.pageSize}&sortField=${queries.sortField}&sortBy=${queries.sortOrder}`,
        { headers: headers }
    )
}

export function approvedMyNotaries(params, headers) {
    return axios.post(`${MIDDLEWARE_URL}/api/ps/addNotary`, params, {
        headers: headers,
    })
}

export function getAllMyClients(headers) {
    return axios.get(
        `${MIDDLEWARE_URL}/api/ps/getApprovedClients?page=1&limit=10000`,
        { headers: headers }
    )
}

export function getAllMyNotaries(queries, headers) {
    let queryParamStr = "";
    if (queries.q) {
        queryParamStr += `&q=${queries.q}`
    }
    if (queries.lang) {
        queryParamStr += `&lang=${queries.lang}`
    }
    if (queries.type) {
        queryParamStr += `&type=${queries.type}`
    }
    if (queries.state) {
        queryParamStr += `&state=${queries.state}`
    }

    return axios.get(
        `${MIDDLEWARE_URL}/api/ps/getAllApprovedClients?page=${queries.pageNumber}&limit=${queries.pageSize}&sortField=${queries.sortField}&sortBy=${queries.sortOrder}${queryParamStr}`,
        { headers: headers }
    )
}

export function getTop5Journals(headers) {
    return axios.get(
        `${MIDDLEWARE_URL}/api/journal/all-journal?page=1&limit=5&sortField=id&sortBy=desc`,
        { headers: headers }
    )
}

export function getAllPaymentCards(queries, headers) {
    return axios.get(
        `${MIDDLEWARE_URL}/api/payment/getPaymentMethods?userId=${queries.userID}&limit=${queries.limit}`,
        { headers: headers }
    )
}

export function finalizePayment(params, headers) {
    return axios.post(`${MIDDLEWARE_URL}/api/payment/finalizePayment`, params, {
        headers: headers,
    })
}

export function getDefaultPaymentCard(queries, headers) {
    return axios.get(
        `${MIDDLEWARE_URL}/api/payment/getDefaultPayment?userId=${queries.userID}`,
        { headers: headers }
    )
}

export function makeDefaultPaymentCard(body, headers) {
    return axios.post(`${MIDDLEWARE_URL}/api/payment/setDefaultPayment`, body, {
        headers: headers,
    })
}

export function makeNetDefaultPaymentCard(body, headers) {
    return axios.put(`${MIDDLEWARE_URL}/api/payment/setNet30`, body, {
        headers: headers,
    })
}

export function deleteSoftNotarization(body, headers) {
    return axios.post(`${MIDDLEWARE_URL}/api/journal/remove`, body, {
        headers: headers,
    })
}

export function deleteHardNotarization(body, headers) {
    return axios.post(`${MIDDLEWARE_URL}/api/journal/delete`, body, {
        headers: headers,
    })
}

export function restoreNotarization(body, headers) {
    return axios.post(`${MIDDLEWARE_URL}/api/journal/restore`, body, {
        headers: headers,
    })
}

export function makePrimaryNotary(body, headers) {
    return axios.post(
        `${MIDDLEWARE_URL}/api/notary/selectPrimaryNotary`,
        body,
        {
            headers: headers,
        }
    )
}

export function getBankDetails(headers) {
    return axios.get(`${MIDDLEWARE_URL}/api/account/getBalanceDetails`, {
        headers: headers,
    })
}

export function updateNotaryStatus(body, headers) {
    if( body.status == "cancel") {
        return axios.post(`${MIDDLEWARE_URL}/api/admin/cancel-notary`, body, {
            headers: headers,
        })
    }
    else {
        return axios.post(`${MIDDLEWARE_URL}/api/admin/change-status`, body, {
            headers: headers,
        })
    }

}

export function findAdminNotaries(headers, queryParams, defaultQuery) {

    let queryParamStr = "";
    if (defaultQuery.lang) {
        queryParamStr += `&lang=${defaultQuery.lang}`
    }
    if (defaultQuery.type) {
        queryParamStr += `&type=${defaultQuery.type}`
    }
    if (defaultQuery.state) {
        queryParamStr += `&state=${defaultQuery.state}`
    }
    if (defaultQuery.status) {
        queryParamStr += `&status=${defaultQuery.status}`
    }
    if (defaultQuery.mode) {
        queryParamStr += `&mode=${defaultQuery.mode}`
    }

    let url =
        queryParams.length > 0
            ? `${MIDDLEWARE_URL}/api/admin/all-notary?page=${defaultQuery.pageNumber
            }&limit=${defaultQuery.pageSize}&sortField=${defaultQuery.sortField
            }&sortBy=${defaultQuery.sortOrder}${queryParamStr}&${queryParams.join('&')}`
            : `${MIDDLEWARE_URL}/api/admin/all-notary?page=${defaultQuery.pageNumber}&limit=${defaultQuery.pageSize}&sortField=${defaultQuery.sortField}&sortBy=${defaultQuery.sortOrder}${queryParamStr}`
    return axios.get(url, { headers: headers })
}

export function findAdminNotarization(
    headers,
    queryParams,
    defaultQuery,
    isexport = false
) {
    let url =
        queryParams.length > 0
            ? `${MIDDLEWARE_URL}/api/admin/get-journals?page=${defaultQuery.pageNumber
            }&export=${isexport}&limit=${defaultQuery.pageSize}&sortField=${defaultQuery.sortField
            }&sortBy=${defaultQuery.sortOrder}&${queryParams.join('&')}`
            : `${MIDDLEWARE_URL}/api/admin/get-journals?page=${defaultQuery.pageNumber}&export=${isexport}&limit=${defaultQuery.pageSize}&sortField=${defaultQuery.sortField}&sortBy=${defaultQuery.sortOrder}`

    return axios.post(url, queryParams, { headers: headers })
}

export function fetchAdminUser(headers, queryParams, defaultQuery) {
    let url =
        queryParams.length > 0
            ? `${MIDDLEWARE_URL}/api/admin/all-notary?page=${defaultQuery.pageNumber
            }&limit=${defaultQuery.pageSize}&sortField=${defaultQuery.sortField
            }&sortBy=${defaultQuery.sortOrder}&${queryParams.join('&')}`
            : `${MIDDLEWARE_URL}/api/admin/all-notary?page=${defaultQuery.pageNumber}&limit=${defaultQuery.pageSize}&sortField=${defaultQuery.sortField}&sortBy=${defaultQuery.sortOrder}`
    return axios.get(url, { headers: headers, params: { role: 'user' } })
}
export function fetchNotaryStates(headers, queryParams, defaultQuery) {

    let queryParamStr = "";
    if (defaultQuery.sortBy) {
        queryParamStr += `&sortBy=${defaultQuery.sortBy}`
    }
    if (defaultQuery.sortField) {
        queryParamStr += `&sortField=${defaultQuery.sortField}`
    }

    let url =
        queryParams.length > 0
            ? `${MIDDLEWARE_URL}/api/journal/get-notary-states?page=${defaultQuery.pageNumber
            }&limit=${defaultQuery.pageSize}${queryParamStr}&${queryParams.join('&')}`
            : `${MIDDLEWARE_URL}/api/journal/get-notary-states?page=${defaultQuery.pageNumber}&limit=${defaultQuery.pageSize}${queryParamStr}`
    return axios.get(url, { headers: headers })
}

export function fetchAllStates(headers) {
    let url = `${MIDDLEWARE_URL}/api/user/getAllStates`
    return axios.get(url, { headers: headers })
}

export function enableStateBiometric(id, biometricEnabled, headers) {
    return axios.put(
        `${MIDDLEWARE_URL}/api/admin/change-state-biometric/${id}/${biometricEnabled}`,
        {},
        { headers: headers }
    )
}

export function witnessGetJournals(headers, defaultQuery) {
    return axios.get(
        `${MIDDLEWARE_URL}/api/witness/getJournals?page=${defaultQuery.pageNumber}&limit=${defaultQuery.pageSize}&sortField=${defaultQuery.sortField}&sortBy=${defaultQuery.sortOrder}`,
        { headers: headers }
    )
}

export function acceptSchedule(headers, param, journalID) {
    return axios.post(
        `${MIDDLEWARE_URL}/api/journal/${journalID}/acceptSchedule`,
        param,
        { headers: headers }
    )
}

export function bypassKBA(param, headers) {
    return axios.post(`${MIDDLEWARE_URL}/api/kba/bypass`, param, {
        headers: headers,
    })
}

export function bypassKBAAlways(param, headers) {
    return axios.post(`${MIDDLEWARE_URL}/api/notary/allowBypass`, param, {
        headers: headers,
    })
}

export function fetchKBASettings(headers) {
    return axios.get(`${MIDDLEWARE_URL}/api/kba/getSetting`, {
        headers: headers,
    })
}

export function copylink(id, headers) {
    return axios.get(`${MIDDLEWARE_URL}/api/kba/copyLink/${id}`, {
        headers: headers,
    })
}

export function acceptWitnessing(headers, param) {
    return axios.post(`${MIDDLEWARE_URL}/api/witness/acceptWitnessing`, param, {
        headers: headers,
    })
}

export function requestPayoutCall(params, headers) {
    try {
        return axios.post(
            `${MIDDLEWARE_URL}/api/payment/sendManualPayout`,
            params,
            { headers: headers }
        )
    } catch (error) { }
}

export function makePaymentOfPlatformFee(body, headers) {
    return axios.post(`${MIDDLEWARE_URL}/api/payment/payPlatformFee`, body, {
        headers: headers,
    })
}

export function createNotarization(body, headers) {
    return axios.post(`${MIDDLEWARE_URL}/api/journal/addNew`, body, {
        headers: headers,
    })
}

export function updateNotarization(journalID, body, headers) {
    return axios.post(
        `${MIDDLEWARE_URL}/api/journal/${journalID}/updateJournal`,
        body,
        { headers: headers }
    )
}

export function claimNow(journalID, headers) {
    return axios.post(
        `${MIDDLEWARE_URL}/api/journal/${journalID}/claim`,
        {},
        { headers: headers }
    )
}

export function getPrimaryNotary(headers) {
    return axios.get(`${MIDDLEWARE_URL}/api/notary/getPrimaryNotary`, {
        headers: headers,
    })
}

export function addPaymentCardCall(body, headers) {
    return axios.post(`${MIDDLEWARE_URL}/api/payment/confirmAddCard`, body, {
        headers: headers,
    })
}

export function getPaymentDetails(headers) {
    return axios.get(`${MIDDLEWARE_URL}/api/payment/getNotarizationPrices`, {
        headers: headers,
    })
}

export function BankAccountTokens(body, headers) {
    return axios.post(`${MIDDLEWARE_URL}/api/account/generateBankToken`, body, {
        headers: headers,
    })
}

export function selectNotary(body, headers) {
    return axios.post(`${MIDDLEWARE_URL}/api/journal/selectNotary`, body, {
        headers: headers,
    })
}

export function checkSignerinjournal(journalID, headers, queryParams='') {
    return axios.get(`${MIDDLEWARE_URL}/api/journal/${journalID}${queryParams}`, {
        headers: headers,
    })
}

export function getJitsiMeetingObj(journalID, headers) {
    return axios.post(`${MIDDLEWARE_URL}/api/jitsi/${journalID}/createMeeting`, {}, {
        headers: headers,
    })
}

export function getJitsiMeetingLinks(journalID, headers) {
    return axios.get(`${MIDDLEWARE_URL}/api/jitsi/${journalID}/recordingUrls`, {
        headers: headers,
    })
}

export function getTemplateURL(userID, headers) {
    return axios.get(
        `${MIDDLEWARE_URL}/api/adobesign/getAdobeViewUrl/${userID}/users/me/true`,
        { headers: headers }
    )
}

export function getTemplateAgreemURL(userID, headers, agreementId) {
    return axios.get(
        `${MIDDLEWARE_URL}/api/adobesign/getAdobeViewUrl/${userID}/agreements/${agreementId}/true`,
        { headers: headers }
    )
}

export function acceptNotarization(journalID, headers, ip) {
    return axios.post(
        `${MIDDLEWARE_URL}/api/journal/${journalID}/accept`,
        { IP: ip },
        { headers: headers }
    )
}

export function rejectNotarization(journalID, headers) {
    return axios.post(
        `${MIDDLEWARE_URL}/api/journal/${journalID}/reject`,
        {},
        { headers: headers }
    )
}

export function doneWitness(headers, journalID) {
    return axios.post(
        `${MIDDLEWARE_URL}/api/journal/${journalID}/url`,
        {},
        { headers: headers }
    )
}

export function getPaymentforExistingJournal(journalID, headers) {
    return axios.get(`${MIDDLEWARE_URL}/api/payment/${journalID}`, {
        headers: headers,
    })
}

export function generateCertificateCall(headers, forms, journalId) {
    return axios.post(
        `${MIDDLEWARE_URL}/api/journal/addCertificateForm`,
        { forms, journalId },
        { headers: headers }
    )
}
export function getAuditTrail(journalID, headers) {
    return axios.get(`${MIDDLEWARE_URL}/api/journal/${journalID}/auditTrail`, {
        headers: headers,
    })
}

export function deleteCertificateDocumentByID(journalId, headers) {
    return axios.delete(
        `${MIDDLEWARE_URL}/api/upload/removeJournalDoc/${journalId}`,
        { headers: headers }
    )
}

export function downloadDocument(headers, journalId, ip) {
    return axios.post(
        `${MIDDLEWARE_URL}/api/journal/${journalId}/pdf`,
        {ip:ip},
        { headers: headers }
    )
}

export function cancelNotarizationCall(journalId, value, headers) {
    return axios.post(
        `${MIDDLEWARE_URL}/api/journal/cancelNotarization`,
        {
            journalId: journalId,
            reason: value,
        },
        { headers: headers }
    )
}

export function deleteAdminUser(id, headers) {
    return axios.post(
        `${MIDDLEWARE_URL}/api/admin/delete-user`,
        { users: [id] },
        { headers: headers }
    )
}

export function tranferNotarization(id, headers) {
    return axios.put(
        `${MIDDLEWARE_URL}/api/journal/${id}/transfer`,
        {},
        { headers: headers }
    )
}
export function addSigner(body, headers) {
    return axios.post(`${MIDDLEWARE_URL}/api/journal/addSigner`, body, {
        headers,
    })
}

export function sendLinksViaEmail(body) {
    return axios.post(`${MIDDLEWARE_URL}/api/kba/sendLinksViaEmail`, body, {})
}

export function GetIdProofingDetail(body) {
    return axios.post(`${MIDDLEWARE_URL}/api/kba/signerIdProofing`, body, {});
}

export function signerLinkVerification(body) {
    return axios.post(`${MIDDLEWARE_URL}/api/kba/signerLinkVerification`, body, {});
}

export function uploadDocEncrypted(body, headers) {
    return axios.post(`${MIDDLEWARE_URL}/api/upload/uploadDocEncrypted`, body, {
        headers,
    })
}

export function getDocEncrypted(filename, headers) {
    return axios.get(
        `${MIDDLEWARE_URL}/api/upload/getDocEncrypted?filename=${filename}`,
        {
            headers,
        }
    )
}

export function additionalInformation(body, headers) {
    return axios.post(
        `${MIDDLEWARE_URL}/api/journal/addExtendedJournal`,
        body,
        {
            headers,
        }
    )
}

export function sendNotarizationPdf(body, headers) {
    return axios.post( `${MIDDLEWARE_URL}/api/notary/send-pdf`, body,{headers})
}

export function sendAdminNotarizationPdf(body, headers) {
    return axios.post( `${MIDDLEWARE_URL}/api/admin/send-pdf`, body,{headers})
}

export function requestAccountAccess(headers) {
    return axios.post( `${MIDDLEWARE_URL}/api/notary/request-status-change`, {},{headers})
}

export function overridePreferenceFilter(body, headers) {
    return axios.post( `${MIDDLEWARE_URL}/api/admin/change-preference`, body,{headers})
}

export function getNet30(id, headers) {
    return axios.get( `${MIDDLEWARE_URL}/api/admin/get-net30/${id}`, {headers})
}

export function changeNet30Status(body, headers) {
    return axios.post( `${MIDDLEWARE_URL}/api/admin/change-net30-status`, body, {headers})
}

export function saveIdologySettings(body, headers) {
    return axios.post( `${MIDDLEWARE_URL}/api/admin/update-credentials`, body, {headers})
}

export function updateClaimJobBoardStatus(id, body, headers) {
    return axios.post( `${MIDDLEWARE_URL}/api/admin/change-job-board-status/${id}`, body, {headers})
}

export function newJobPostedNotification(headers) {
    return axios.post( `${MIDDLEWARE_URL}/api/admin/send-new-job-notification`, {headers})
}