import axios from "axios";
import React, { useEffect, useState } from "react";
import requestPayoutTotal from "../../media/Group 72@2x.png";

import { useSelector } from "react-redux";

export function PaymentStatisticsCard({ type, heading, totalEarned }) {
  const { auth } = useSelector(state => state);

  return (
    <div>
      <span className={type}>
        <h3>{heading}</h3>
        <p style={{ fontSize: "20px" }}>${totalEarned}</p>
        <div className="main-request-total">
          <img src={requestPayoutTotal} className="requestPayoutTotal" alt="" />
        </div>
      </span>
    </div>
  );
}
