import React, { useEffect, useState } from "react";
import { TableUIProvider } from "../components/adminstatelisting/TableUIContext";
import { TableCard } from "../components/adminstatelisting/TableCard";
import { GenericZeroState } from "../components/zero_state_pages/GenericZeroState";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../components/_redux/notarization_redux/notarizationActions";
import { Modal, Button } from "react-bootstrap";

export function AdminStateSetting() {
  const dispatch = useDispatch();
  const { auth, notarization } = useSelector(state => state);
  const { allAdminStates, allAdminStatesQuery } = notarization;
  const [actionBiometricModal, setActionBiometricModal] = useState(false);
  const [selectedStateId, setSelectedStateId] = useState(null);
  const [biometricEnabled, setBiometricEnabled] = useState(null);

  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  const enableStateBiometricUIEvents = {
    EnableStateBiometricDialog: (id, biometricEnabled) => {
      setActionBiometricModal(true);
      let row = allAdminStates.rows.filter(el => el.id === id);
      let stateID = row[0].id;
      setSelectedStateId(stateID);
      setBiometricEnabled(!biometricEnabled);
    }
  };

  const enableStateBiometric = (id, biometricEnabled) => {
    let row = allAdminStates.rows.filter(el => el.id === id);
    let stateID = row[0].id;
    setSelectedStateId(stateID);
    dispatch(actions.enableStateBiometric(stateID, biometricEnabled, headers));
  };

  const CancelStateBiometricDialog = biometricEnabled => {
    setActionBiometricModal(false);
    setBiometricEnabled(!biometricEnabled);
  };

  useEffect(() => {
    dispatch(
      actions.fetchNotaryStates(headers, [], 
      { 
        pageNumber: 1, 
        pageSize: 10,
        sortBy: "asc",
        sortField: "state" 
      })
    );
  }, []);

  return (
    <>
      {allAdminStates !== null &&
      (allAdminStates.rows.length > 0 || allAdminStatesQuery) ? (
        <TableUIProvider
          enableStateBiometricUIEvents={enableStateBiometricUIEvents}
        >
          <Modal
            size="md"
            show={actionBiometricModal}
            onHide={() => setActionBiometricModal(false)}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>State Biometric</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are You Sure To Change Biometric Settings On This State?
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => CancelStateBiometricDialog(biometricEnabled)}
              >
                Close
              </Button>
              <Button
                variant="success"
                onClick={() =>
                  enableStateBiometric(selectedStateId, biometricEnabled)
                }
              >
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>
          <TableCard />
        </TableUIProvider>
      ) : (
        <GenericZeroState
          status={allAdminStates}
          imageURL="/media/own/no-notary.png"
          heading="No Notary User Found!"
          body="It seems that no notary is applied for registration."
          sub_body="Once you accept the notary profile you can see details here!"
          btnTxt="false"
        />
      )}
    </>
  );
}
