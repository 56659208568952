/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React from "react";
import CancelIcon from "@material-ui/icons/Cancel";

export function FilePreviewTable({
  files,
  removeFile,
  disabled,
  typeFile,
  handleChangeFileType,
  resumeNotarization
}) {
  const fileType = [
    "Last Will & Testament and/or Estate Document(s)",
    "Election Document(s)",
    "Copy Certification Request",
    "Vehicle Title Transfer",
    "Power of Attorney",
    "Other Type of Document(s)",
    "Real Estate Documents"
  ];

  const checkFileexist = fileID => {
    let fileDetail = resumeNotarization.journalDocs.filter(function(fileObj) {
      return fileObj.fileId == fileID;
    });
    if (!fileDetail[0]) return false;
    else return fileDetail[0].docType;
  };

  return (
    <div className={`card card-custom`}>
      <div className="card-header border-0 py-5">
        <h2 className="card-title align-items-start flex-column">
          Uploaded Documents
        </h2>
        {/* <div className="card-toolbar">
          <a href="#" className="btn btn-light-primary font-weight-bolder font-size-sm">Add More</a>
        </div> */}
      </div>
      <div className="card-body pt-0 pb-3">
        <div className="tab-content">
          <div className="table-responsive">
            <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
              <tbody>
                {files.map(file => {
                  let extension = file.filename.split(".").pop();
                  return (
                    <tr key={file.id}>
                      <td className="pl-0 py-0">
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-50 symbol-light mr-4">
                            <span className="svg-icon h-75 align-self-end">
                              <img
                                src={`/media/svg/files/${extension}.svg`}
                                style={{ width: "40px" }}
                                alt="doc file"
                              />
                            </span>
                          </div>
                          <div>
                            <span className="text-muted font-weight-bold d-block">
                              {file.filename.substring(14)}
                            </span>
                          </div>
                        </div>
                      </td>

                      <td className="pr-0 text-right">
                        <div style={{ width: "100%", padding: "20px" }}>
                          {resumeNotarization &&
                          resumeNotarization.journalDocs &&
                          checkFileexist(file.id) ? (
                            checkFileexist(file.id)
                          ) : (
                            <select
                              placeholder="Select File Type"
                              className="form-control form-control-lg form-control-solid owais"
                              name={"file" + file.id}
                              onChange={handleChangeFileType}
                            >
                              <option value="" selected disabled>
                                Select File Type
                              </option>

                              {fileType.map(el => (
                                <option value={el}>{el}</option>
                              ))}
                            </select>
                          )}
                        </div>
                      </td>
                      <td className="pr-0 text-right">
                        <button
                          className="btn btn-light-danger font-weight-bolder font-size-sm"
                          onClick={removeFile}
                          id={file.id}
                          disabled={disabled}
                        >
                          <CancelIcon />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
