import { createSlice } from "@reduxjs/toolkit";

const initialNotarizationState = {
  allQuestions: []
};

export const callTypes = {
  list: "list",
  action: "action"
};

export const kbaSlice = createSlice({
  name: "notarization",
  initialState: initialNotarizationState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) state.listLoading = false;
      else state.actionsLoading = false;
    },
    startCall: (state, action) => {
      state.error = null;

      if (action.payload.callType === callTypes.list) state.listLoading = true;
      else state.actionsLoading = true;
    },

    questionsFetched: (state, action) => {
      state.allQuestions = action.payload;
    },

    clearAllState: (state, action) => {
      state.allQuestions = [];
    }
  }
});
