import React from "react";
import Grid from "@material-ui/core/Grid";
import PrimaryPSNotaryCard from "./PrimaryPSNotaryCard";

export function PSNotaryListing({
  notaries,
  setpsSelection,
  revealSelection,
  isreveal,
  hidephone
}) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <Grid container spacing={8}>
            {notaries.map(el => (
              <PrimaryPSNotaryCard
                key={el.id}
                notary={el}
                setpsSelection={setpsSelection}
                revealSelection={revealSelection}
                isreveal={isreveal}
                hidephone={hidephone}
              />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
