import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { toAbsoluteUrl } from '../../../_metronic/_helpers'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment';

export function ScheduleNotificationModal({
    heading,
    show,
    hide,
    detail,
    btnHandler,
    cancel,
    reschedule,
    setrescehduleDate,
}) {
    const [timer, setTimer] = useState(false)
    const [RescheduleMode, setRescheduleMode] = useState(false)
    const [dateTime, setDateTime] = React.useState(new Date().toISOString())
    const [notificationStart, setnotificationStart] = useState(false)
    const poptime = (filename) => {
        let filenamearr = filename.split('-')
        filenamearr.splice(filenamearr, 1)

        return filenamearr.join('-')
    }

    const playSound = () => {
        const audio = new Audio(toAbsoluteUrl('/media/notification/sound.mp3'))
        audio.play()
    }

    const rescheduleModeStart = () => {
        setRescheduleMode(true)
    }

    const dateTimeHandler = (e) => {
        setrescehduleDate(new Date(e))
    }

    const prepareTime = (dt) => {
        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ]
        let date = new Date(dt)
        let time = formatAMPM(date)
        let mon = months[date.getMonth()]
        let curDate = date.getDate()
        let year = date.getFullYear()
        let timezone = seconds_with_leading_zeros(date)
        return `${mon} ${curDate}, ${year} at ${time} (${timezone})`
    }

    const formatAMPM = (date) => {
        var hours = date.getHours()
        var minutes = date.getMinutes()
        var ampm = hours >= 12 ? 'PM' : 'AM'
        hours = hours % 12
        hours = hours ? hours : 12
        minutes = minutes < 10 ? '0' + minutes : minutes
        var strTime = hours + ':' + minutes + ' ' + ampm
        return strTime
    }

    const seconds_with_leading_zeros = (dt) => {
        return /\((.*)\)/.exec(dt.toString())[1]
    }

    useEffect(() => {
        if (!notificationStart) {
            playSound()
            setnotificationStart(true)
        }
    }, [])
    return (
        <>
            <Modal
                show={show}
                // onHide={hide}
                className='modal-size'
                dialogClassName='my-modal-Notification'>
                <Modal.Header>
                    <Modal.Title>{heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-body-main'>
                    <div className='modal-body-one'>
                        You have a new Scehdule request
                        {/* <p style={{fontSize: '11px', color:'red'}}><strong>Disclaimer</strong>. Don't refresh/close your page. Might you will loss the further verification process. </p> */}
                        <div class='add_new'>
                            <div className='main-category'>
                                <span className='category'>Additional Details about this Notarization:</span>
                            </div>
                            <div class='flex_class'>
                                <span>
                                    Election Doc:{' '}
                                    {detail.journal &&
                                    detail.journal.electionDoc
                                        ? 'Yes'
                                        : 'No'}{' '}
                                </span>
                                <span>
                                    {' '}
                                    Carries Will:{' '}
                                    {detail.journal && detail.journal.willDoc
                                        ? 'Yes'
                                        : 'No'}{' '}
                                </span>
                                <span>
                                    No. of signers:{' '}
                                    {detail.journal && detail.journal.Signers
                                        ? detail.journal.Signers.length
                                        : 0}
                                </span>
                                <span>
                                    No. of witness allow:{' '}
                                    {detail.journal &&
                                    detail.journal.witnessCount
                                        ? detail.journal.witnessCount
                                        : 0}
                                </span>
                                <span>
                                    Schedule Date/Time:{' '}
                                    {detail.journal &&
                                    detail.journal.scheduledAt
                                        ? prepareTime(
                                              detail.journal.scheduledAt
                                          )
                                        : '-'}
                                </span>
                            </div>
                        </div>
                        {RescheduleMode ? (
                            <div class='flex_class flex_class-alt'>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <DateTimePicker 
                                        label="Date and Time"
                                        initialFocusedDate={dateTime}
                                        value={dateTime}
                                        onChange={dateTimeHandler}
                                        showTodayButton={true}
                                        disablePast={true}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    {!RescheduleMode ? (
                        <div className='modal-body-two'>
                            <Button
                                variant='secondary'
                                className='acceptBtn'
                                onClick={btnHandler}>
                                Accept
                            </Button>{' '}
                            &nbsp;
                            <Button
                                variant='outlined'
                                color='secondary'
                                className='rejectBtn'
                                onClick={cancel}>
                                Reject
                            </Button>{' '}
                            &nbsp;
                            <Button
                                variant='outlined'
                                color='secondary'
                                className='rejectBtn'
                                onClick={rescheduleModeStart}>
                                Reschedule
                            </Button>{' '}
                            &nbsp;
                        </div>
                    ) : (
                        <div className='modal-body-two'>
                            <Button
                                variant='secondary'
                                className='acceptBtn'
                                onClick={reschedule}>
                                Submit
                            </Button>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        </>
    )
}
