import axios from "axios";

const MIDDLEWARE_URL = process.env.REACT_APP_MIDDLEWARE_URL;

export function getQuestions(param, headers) {
  return axios.post(`${MIDDLEWARE_URL}/api/kba/get-questions`, param, {
    headers: headers
  });
}

export function SubmitQuestions(param, headers) {
  return axios.post(`${MIDDLEWARE_URL}/api/kba/submit-questions`, param, {
    headers: headers
  });
}
