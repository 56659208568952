import React, { useEffect, useState } from "react";
import { TableUIProvider } from "../components/adminnotarizationlisting/TableUIContext";
import { TableCard } from "../components/adminnotarizationlisting/TableCard";
import { TableCardschedule } from "../components/adminnotarizationlisting/TableCardschedule";
import { TableCardcompleted } from "../components/adminnotarizationlisting/TableCardcompleted";
import { TableCardprogress } from "../components/adminnotarizationlisting/TableCardprogress";

import { GenericZeroState } from "../components/zero_state_pages/GenericZeroState";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../components/_redux/notarization_redux/notarizationActions";
import { CustomerEditDialog } from "../components/adminnotarizationlisting/edit-dialog/CustomerEditDialog";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

export function MyAdminNotarization() {
  const dispatch = useDispatch();
  const [notaryName, setnotaryName] = useState("");
  const [notaryEmail, setnotaryEmail] = useState("");
  const [client, setclient] = useState("");
  const [fromDate, setfromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [journalstatus, setjournalstatus] = useState();
  const [isload, setisload] = useState(false);

  const { auth, notarization } = useSelector(state => state);
  const {
    allAdminNotarization,
    allAdminNotarizationschedule,
    allAdminNotarizationcompleted,
    allAdminNotarizationQuery,
    SingleJournalObj,
    jitsiMeetingLinks,
    languages,
    notaryTypes,
    allAdminStates
  } = notarization;
  const { allAdminNotaries, allAdminNotariesQuery } = notarization;
  const [alreadyFetched, setalreadyFetched] = useState(false);
  const [actionModal, setActionModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };
  const [mainschedulefilter, setmainschedulefilter] = useState({
    status: {
      value: ["Scheduled"],
      matchType: "in"
    },
    include: [
      {
        model: "User",
        include: [],
        alias: "notary",
        attributes: ["firstName", "lastName", "email"]
      },
      {
        model: "User",
        include: [],
        attributes: ["firstName", "lastName"],
        alias: "customer"
      },
      { 
        model: "journalLanguage",
        include: [],
        attributes: ["languageId"],
        alias: "journalLangs",
      }
    ],
    attributes: [],
    alias: ""
  });

  const [maincompletedfilter, setmaincompletedfilter] = useState({
    status: {
      value: ["completed"],
      matchType: "in"
    },
    include: [
      {
        model: "User",
        include: [],
        alias: "notary",
        attributes: ["firstName", "lastName", "email"]
      },

      {
        model: "User",
        include: [],
        attributes: ["firstName", "lastName"],
        alias: "customer"
      },
      { model: "journalLanguage",
        include: [],
        attributes: ["languageId"],
        alias: "journalLangs",
      }
    ],
    attributes: [],
    alias: ""
  });

  const [maininprogressfilter, setmaininprogressfilter] = useState({
    status: {
      value: "progress"
    },
    include: [
      {
        model: "User",
        include: [],
        attributes: ["firstName", "lastName", "email"],
        alias: "notary"
      },
      {
        model: "User",
        include: [],
        attributes: ["firstName", "lastName"],
        alias: "customer"
      },
      { 
        model: "journalLanguage",
        include: [],
        attributes: ["languageId"],
        alias: "journalLangs",
      }
    ],
    attributes: [],
    alias: ""
  });

  const [mainfilter, setmainfilter] = useState({
    include: [
      {
        model: "User",
        include: [],
        attributes: ["firstName", "lastName", "email"],
        alias: "notary"
      },

      {
        model: "User",
        include: [],
        attributes: ["firstName", "lastName"],
        alias: "customer"
      },
      { 
        model: "journalLanguage",
        include: [],
        attributes: ["languageId"],
        alias: "journalLangs",
      }
    ],
    attributes: [],
    alias: ""
  });

  // Filter for Excel Export
  const [mainexcelfilter, setmainexcelfilter] = useState({
    include: [
      {
        model: "Witness",
        attributes: [],
        include: [
          {
            model: "User"
          }
        ]
      },
      {
        model: "Payment",
        attributes: [],
        include: []
      },
      {
        model: "Signer",
        attributes: [],
        include: []
      },
      {
        model: "journalDoc",
        attributes: [],
        include: [
          {
            model: "File"
          }
        ]
      },
      {
        model: "User",
        include: [],
        attributes: [],
        alias: "notary"
      },
      {
        model: "Recording",
        include: [],
        attributes: []
      },
      {
        model: "User",
        include: [],
        attributes: [],
        alias: "customer"
      }
    ],
    attributes: [],
    alias: ""
  });

  const customersUIEvents = {
    openEditCustomerDialog: id => {
      dispatch(actions.getJournalbyID(id, headers)).then(data => {
        setActionModal(true);
      });
      dispatch(actions.getJitsiMeetingLinks(id, headers))
    },

    openEditCustomerScheduleDialog: id => {
      dispatch(actions.getJournalbyID(id, headers)).then(data => {
        setActionModal(true);
      });
      dispatch(actions.getJitsiMeetingLinks(id, headers))
    },

    openEditCustomerCompletedDialog: id => {
      dispatch(actions.getJournalbyID(id, headers)).then(data => {
        setActionModal(true);
      });
      dispatch(actions.getJitsiMeetingLinks(id, headers))
    }
  };

  useEffect(() => {
    if (!alreadyFetched) {
      dispatch(
        actions.fetchAdminUser(headers, [], {
          sortOrder: "desc",
          sortField: "id",
          pageNumber: 1,
          pageSize: 100000
        })
      );
      dispatch(
        actions.fetchAdminNotarization(headers, mainfilter, {
          sortOrder: "desc",
          sortField: "id",
          pageNumber: 1,
          pageSize: 10
        })
      );
      //dispatch(actions.fetchAdminNotarizationForCSV(headers, mainfilter, { sortOrder: "desc", sortField: "id", pageNumber: 1, pageSize: 999999 }));
      setalreadyFetched(true);
    }
  });

  const applyFilter = () => {
    setisload(true);
    dispatch(
      actions.fetchAdminNotarization(headers, mainfilter, {
        sortOrder: "desc",
        sortField: "id",
        pageNumber: 1,
        pageSize: 10
      })
    ).then(data => {
      setisload(false);
    });
    //	dispatch(actions.fetchAdminNotarizationForCSV(headers, mainfilter, { sortOrder: "desc", sortField: "id", pageNumber: 1, pageSize: 999999 }));
  };

  return (
    <>
      {
        <>
          {isload ? (
            <div align={"center"}>
              <img src="/media/misc/inprogress.gif" class="img-witness" />
            </div>
          ) : (
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row>
                <Col sm={2}>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="first">All</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Scheduled</Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link eventKey="fourth">In Progress</Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link eventKey="third">Completed</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={10}>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <TableUIProvider customersUIEvents={customersUIEvents}>
                        <CustomerEditDialog
                          show={actionModal}
                          onHide={() => setActionModal(false)}
                        />
                        <TableCard
                          setmainfilter={setmainfilter}
                          mainfilter={mainfilter}
                          mainexcelfilter={mainexcelfilter}
                          setmainexcelfilter={setmainexcelfilter}
                          setnotaryName={setnotaryName}
                          notaryName={notaryName}
                          setnotaryEmail={setnotaryEmail}
                          notaryEmail={notaryEmail}
                          setclient={setclient}
                          client={client}
                          fromDate={fromDate}
                          toDate={toDate}
                          settoDate={settoDate}
                          setfromDate={setfromDate}
                          journalstatus={journalstatus}
                          setjournalstatus={setjournalstatus}
                          applyFilter={applyFilter}
                        />
                      </TableUIProvider>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <TableUIProvider
                        customersUIEvents={customersUIEvents}
                        schedule={"yes"}
                      >
                        <TableCardschedule
                          setmainfilter={setmainschedulefilter}
                          mainfilter={mainschedulefilter}
                          setnotaryName={setnotaryName}
                          notaryName={notaryName}
                          setnotaryEmail={setnotaryEmail}
                          notaryEmail={notaryEmail}
                          setclient={setclient}
                          client={client}
                          fromDate={fromDate}
                          toDate={toDate}
                          settoDate={settoDate}
                          setfromDate={setfromDate}
                          journalstatus={journalstatus}
                          setjournalstatus={setjournalstatus}
                          applyFilter={applyFilter}
                          schedule={"yes"}
                        />
                      </TableUIProvider>
                    </Tab.Pane>

                    <Tab.Pane eventKey="third">
                      <TableUIProvider
                        customersUIEvents={customersUIEvents}
                        completed={"yes"}
                      >
                        <TableCardcompleted
                          setmainfilter={setmaincompletedfilter}
                          mainfilter={maincompletedfilter}
                          setnotaryName={setnotaryName}
                          notaryName={notaryName}
                          setnotaryEmail={setnotaryEmail}
                          notaryEmail={notaryEmail}
                          setclient={setclient}
                          client={client}
                          fromDate={fromDate}
                          toDate={toDate}
                          settoDate={settoDate}
                          setfromDate={setfromDate}
                          journalstatus={journalstatus}
                          setjournalstatus={setjournalstatus}
                          applyFilter={applyFilter}
                          completed={"yes"}
                        />
                      </TableUIProvider>
                    </Tab.Pane>

                    <Tab.Pane eventKey="fourth">
                      <TableUIProvider
                        customersUIEvents={customersUIEvents}
                        progress={"yes"}
                      >
                        <TableCardprogress
                          setmainfilter={setmaininprogressfilter}
                          mainfilter={maininprogressfilter}
                          setnotaryName={setnotaryName}
                          notaryName={notaryName}
                          setnotaryEmail={setnotaryEmail}
                          notaryEmail={notaryEmail}
                          setclient={setclient}
                          client={client}
                          fromDate={fromDate}
                          toDate={toDate}
                          settoDate={settoDate}
                          setfromDate={setfromDate}
                          journalstatus={journalstatus}
                          setjournalstatus={setjournalstatus}
                          applyFilter={applyFilter}
                          progress={"yes"}
                        />
                      </TableUIProvider>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          )}
        </>
      }
    </>
  );
}
