import React from "react";
import { Form } from "react-bootstrap";
import { SignerListing } from "./SignerListing";

import { SignerListingPS } from "./SignerListingPS";
import { Modal, Button } from "react-bootstrap";

export function SignerInfo({
  singerInfo,
  setSingerInfo,
  noOfSigner,
  setNoOfSigner,
  disabled,
  user,
  draftHandler,
  selectedUser,
  ps,
  setsigneralready,
  iscontinue,
  initiatedByNotary
}) {
  const addNoOfSigners = e => {
    const signers = e.target.value;
    if (JSON.parse(signers) > 0 && JSON.parse(signers) < 11)
      setNoOfSigner({
        value: JSON.parse(signers),
        added: JSON.parse(signers),
        error: false
      });
    else if (signers === "") setNoOfSigner({ ...noOfSigner, error: false });
  };

  const increaseSigner = () => {
    if (noOfSigner.value && noOfSigner.value < 10) {
      setNoOfSigner({
        value: noOfSigner.value + 1,
        added: noOfSigner.value + 1,
        error: false
      });
    }
  };

  const decreaseSigner = () => {
    if (noOfSigner.value && noOfSigner.value > 1)
      setNoOfSigner({
        value: noOfSigner.value - 1,
        added: noOfSigner.value - 1,
        error: false
      });
  };

  return (
    <div className={`card card-custom`}>
      <div className="card-header border-0 pt-5">
        <h2
          className="card-title align-items-start flex-column"
          style={{ float: "left", width: "100%" }}
        >
          How many people are signing your document today?
        </h2>
        <p style={{ color: "#ccc" }}>
          Please enter as much information as you can about each person. If they
          do not currently live in the USA, please enter an address which they
          have lived, or are associated with within the USA. This information
          will be used to generate the automatic questions in the Identity Quiz.
          Failure to provide enough details could result in the inability to
          generate quiz questions and will prohibit this notarization from
          continuing.
        </p>
      </div>

      <div className="card-header border-0 py-5 pt-0">
        <div className="align-items-start flex-column">
          <p style={{ float: "left", paddingTop: "7px", paddingRight: "20px" }}>
            Define Number of signers
          </p>
          <Form.Control
            style={{ width: "30%" }}
            type="number"
            placeholder="e.g. 3"
            value={noOfSigner.added}
            min="1"
            max="10"
            onChange={addNoOfSigners}
            disabled={disabled}
          />
          <br />
          <Button
            variant="secondary"
            className={"mobilearrow"}
            onClick={increaseSigner}
          >
            +
          </Button>{" "}
          &nbsp;
          <Button
            variant="secondary"
            className={"mobilearrow"}
            onClick={decreaseSigner}
          >
            -
          </Button>
        </div>
      </div>
      {noOfSigner.error && (
        <p style={{ padding: "0 2.25rem", color: "red" }}>
          You can add minimun 1 and maximium 10. Please enter valid value!
        </p>
      )}

      {!ps ? (
        <SignerListing
          setsigneralready={setsigneralready}
          iscontinue={iscontinue}
          noOfSigner={noOfSigner.added}
          draftHandler={draftHandler}
          singerInfo={singerInfo}
          setSingerInfo={setSingerInfo}
          disabled={disabled}
          user={user}
          selectedUser={selectedUser}
          initiatedByNotary={initiatedByNotary}
        />
      ) : (
        <SignerListingPS
          noOfSigner={noOfSigner.added}
          draftHandler={draftHandler}
          singerInfo={singerInfo}
          setSingerInfo={setSingerInfo}
          disabled={disabled}
          user={user}
          selectedUser={selectedUser}
        />
      )}
    </div>
  );
}
