import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../../_metronic/_partials/controls";
import * as actions from "../../_redux/notarization_redux/notarizationActions";
import { CustomTable } from "./table/CustomTable";
import { saveAs } from "file-saver";

export function TableCard() {
  const MIDDLEWARE_URL = process.env.REACT_APP_MIDDLEWARE_URL;
  const { auth, notarization } = useSelector(state => state);
  const { auditTrailData } = notarization;

  const dispatch = useDispatch();
  const auditHandler = () => {
    const headers = {
      Authorization: `Bearer ${auth.authToken}`,
      "Content-Type": "application/json",
      Accept: "application/json"
    };
  };

  return (
    <Card>
      <CardHeader title="Journal Overview" className="py-6">
        <CardHeaderToolbar>
          {auth.user.Role.code === "ps" ? (
            <Link to="/new-notarizationPS" style={{ color: "white" }}>
              <button type="button" className="btn btn-primary">
                New Notarization
              </button>
            </Link>
          ) : ( 
            ( auth.user.Role.code === "user" || 
            ( auth.user.Role.code === "notary" && !auth.user.notaryProfile.isCancelled ) ) && 
            <Link to="/new-notarization" style={{ color: "white" }}>
              <button type="button" className="btn btn-primary">
                New Notarization
              </button>
            </Link>
          )}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <CustomTable />
        <Link
          to="/journals"
          style={{ float: "right", textDecoration: "underline" }}
        >
          View All
        </Link>
      </CardBody>
    </Card>
  );
}
