import React from "react";
import NoWitnessImage from "../../../media/zeroState/NoWitness.webp";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

export function CustomNoWitnessTable() {
  return (
    <>
      <div>
        <div>
          <img src={NoWitnessImage} className="NoWitnessImage" alt="" />
        </div>
        <div className="NoWitness">
          <h3>No Witness!</h3>
        </div>
        <div className="no-witness-text">
          <span>
            You haven't do any document witness. Once you witness any document
            you can see them here.
          </span>
        </div>
      </div>
    </>
  );
}
