import React from "react";
import NoNotaryisonlineImage from "../../../media/zeroState/NoNotaryisonline.webp";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

export function CustomNoNotaryIsOnlineTable() {
  return (
    <>
      <div>
        <div>
          <img
            src={NoNotaryisonlineImage}
            className="NoNotaryisonlineImage"
            alt=""
          />
        </div>
        <div className="NoNotaryIsOnline">
          <h3>No Notary is online!</h3>
        </div>
        <div className="no-notary_online-text">
          <span>
            We are sorry that no notary is online at the moment & your primary
            notary is also offline. Please search again after few moments.
          </span>
          <div>
            <Link to="#">
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#2d3fd3",
                  color: "#ffffff",
                  fontSize: "13px",
                  marginTop: "25px"
                }}
              >
                {" "}
                Search again{" "}
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
