import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { CustomerEditForm } from "../../journals/edit-dialog/CustomerEditForm";

export function CustomerEditDialog({
  id,
  show,
  onHide,
  resumeNotarization,
  addWitness,
  downloadDocument,
  auditReport,
  firstColumn
}) {
  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      dialogClassName="modal-width"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <CustomerEditForm
        onHide={onHide}
        id={id}
        resumeNotarization={resumeNotarization}
        addWitness={addWitness}
        downloadDocument={downloadDocument}
        auditReport={auditReport}
        firstColumn={firstColumn}
      />
    </Modal>
  );
}
