import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { MultiSelect } from 'react-multi-select-component'
import Checkbox from '@material-ui/core/Checkbox'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import validator from 'validator'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
// import Select from '@material-ui/core/Select';

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import Select from 'react-select'
import * as actions from '../_redux/notarization_redux/notarizationActions'

const useStyles = makeStyles(() => ({
    cstm_padding: {
        paddingLeft: '30px',
        paddingRight: '30px',
        marginTop: '30px',
        marginBottom: '1rem',
    },
    cstm_field: {
        width: '100%',
        marginTop: 0,
    },
}))

export function SignerListingPS({
    noOfSigner,
    singerInfo,
    setSingerInfo,
    disabled,
    user,
    draftHandler,
    selectedUser,
}) {
    const classes = useStyles()
    const dispatch = useDispatch()

    const { socket, auth, notarization } = useSelector((state) => state)
    const { KBASettings, allAdminStates } = notarization

    const headers = {
        Authorization: `Bearer ${auth.authToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
    }

    const options = [
        { label: 'Email', value: 'email' },
        { label: 'Smartphone', value: 'smartphone' },
        { label: 'Driving License', value: 'license' },
        { label: 'Passport', value: 'passport' },
        { label: 'Govt. ID', value: 'govtID' },
    ]

    useEffect(() => {
        dispatch(
            actions.fetchNotaryStates(headers, [], {
                pageNumber: 1,
                pageSize: 52,
                sortBy: "asc",
                sortField: "state",
            })
        )
    }, [])

    const handleChange = (name, index, fieldType = 'text') => (event) => {
        let copiedArr = [...singerInfo]
        if(name === 'phone') {
           
            copiedArr[index][name] = event == undefined ? '' : event;
        }
        else{
            if (name === 'notGovtID' || name === 'byPassKBA') {
                copiedArr[index][name] = event.target.checked
                if (name === 'notGovtID' && event.target.checked) {
                    let newChecklist = copiedArr[index].checklist.filter((data) =>
                        data.value !== 'license' &&
                        data.value !== 'passport' &&
                        data.value !== 'govtID'
                            ? data
                            : null
                    )
                    copiedArr[index].checklist = newChecklist
                }
            } else if (name === 'checklist') {
                copiedArr[index].checklist = event
                event.map((data) =>
                    data.value === 'license' ||
                    data.value === 'passport' ||
                    data.value === 'govtID'
                        ? (copiedArr[index].notGovtID = false)
                        : null
                )
            } else if (name === 'nonUS') {
                copiedArr[index]['biometric'] = true
            } else if (name === 'usCitizen') {
                copiedArr[index]['biometric'] = false
            } else {
                if (name === 'email') {
                    if (validator.isEmail(event.target.value)) {
                        copiedArr[index]['isEmailValid'] = true
                    } else {
                        copiedArr[index]['isEmailValid'] = false
                    }
                    copiedArr[index][name] = event.target.value
                } else if (name === 'state' && fieldType === 'dropdown') {
                    copiedArr[index][name] = event.value
                } else {
                    copiedArr[index][name] = event.target.value
                }
            }
        }

        setSingerInfo(copiedArr)
    }

    const signers = []
    for (let i = 1; i <= noOfSigner; i++) {
        signers.push(i)
    }

    return (
        <>
            {signers.map((signer, index) => {
                return (
                    <div
                        className={classes.cstm_padding}
                        key={index}
                        style={
                            singerInfo[index]['isError']
                                ? { borderLeft: '5px solid #EA001B' }
                                : {}
                        }>
                        <h6 className='pb-2'>
                            Signer Info {signer}:
                            {auth &&
                            auth.user &&
                            auth.user.Role &&
                            'ps' == auth.user.Role.code ? (
                                <>
                                    <span className='pl-5'>
                                        Are you a US Citizen?
                                    </span>
                                    <span className='p-2'>
                                        <input
                                            type='radio'
                                            id='usCitizen'
                                            disabled={disabled}
                                            checked={
                                                !singerInfo[index].biometric
                                            }
                                            onChange={handleChange(
                                                'usCitizen',
                                                index
                                            )}
                                            value='Yes'
                                            className='mr-1'
                                            name={'usCitizen[' + index + ']'}
                                        />
                                        YES
                                    </span>
                                    <span className='p-2'>
                                        <input
                                            type='radio'
                                            id='nonUS'
                                            disabled={disabled}
                                            checked={
                                                singerInfo[index].biometric
                                            }
                                            onChange={handleChange(
                                                'nonUS',
                                                index
                                            )}
                                            value='No'
                                            className='mr-1'
                                            name={'usCitizen[' + index + ']'}
                                        />
                                        NO
                                    </span>
                                </>
                            ) : (
                                <></>
                            )}
                        </h6>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <TextField
                                    required
                                    label='First Name'
                                    value={singerInfo[index].fname}
                                    onChange={handleChange('fname', index)}
                                    margin='dense'
                                    autoComplete='new-password'
                                    variant='outlined'
                                    className={classes.cstm_field}
                                    inputProps={{ style: { height: '18px' } }}
                                    color='secondary'
                                    InputLabelProps={{
                                        shrink: singerInfo[index].fname !== '',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    label='Middle Name'
                                    value={singerInfo[index].middleName}
                                    onChange={handleChange('middleName', index)}
                                    margin='dense'
                                    autoComplete='new-password'
                                    variant='outlined'
                                    className={classes.cstm_field}
                                    inputProps={{ style: { height: '18px' } }}
                                    color='secondary'
                                    InputLabelProps={{
                                        shrink:
                                            singerInfo[index].middleName !== '',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    required
                                    label='Last Name'
                                    value={singerInfo[index].lname}
                                    onChange={handleChange('lname', index)}
                                    margin='dense'
                                    autoComplete='new-password'
                                    variant='outlined'
                                    className={classes.cstm_field}
                                    inputProps={{ style: { height: '18px' } }}
                                    color='secondary'
                                    InputLabelProps={{
                                        shrink: singerInfo[index].lname !== '',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    label='Suffix'
                                    value={singerInfo[index].suffix}
                                    onChange={handleChange('suffix', index)}
                                    margin='dense'
                                    autoComplete='new-password'
                                    variant='outlined'
                                    className={classes.cstm_field}
                                    inputProps={{
                                        style: { height: '18px' },
                                        maxLength: 5,
                                    }}
                                    color='secondary'
                                    InputLabelProps={{
                                        shrink: singerInfo[index].suffix !== '',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    required
                                    label='Email'
                                    value={singerInfo[index].email}
                                    onChange={handleChange('email', index)}
                                    autoComplete='new-password'
                                    margin='dense'
                                    variant='outlined'
                                    className={classes.cstm_field}
                                    inputProps={{ style: { height: '18px' } }}
                                    color='secondary'
                                    InputLabelProps={{
                                        shrink: singerInfo[index].email !== '',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <PhoneInput
                                    defaultCountry="US"
                                    placeholder="Phone *"
                                    value={
                                        '' === singerInfo[index].phone ||
                                            null === singerInfo[index].phone
                                            ? ''
                                            : singerInfo[index].phone
                                    }
                                    onChange={handleChange('phone', index)}/>
                                
                                
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    required
                                    label='Address'
                                    value={singerInfo[index].address}
                                    onChange={handleChange('address', index)}
                                    autoComplete='new-password'
                                    margin='dense'
                                    variant='outlined'
                                    className={classes.cstm_field}
                                    inputProps={{ style: { height: '18px' } }}
                                    color='secondary'
                                    InputLabelProps={{
                                        shrink:
                                            singerInfo[index].address !== '',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    required
                                    label='City'
                                    value={singerInfo[index].city}
                                    onChange={handleChange('city', index)}
                                    autoComplete='new-password'
                                    margin='dense'
                                    variant='outlined'
                                    className={classes.cstm_field}
                                    inputProps={{ style: { height: '18px' } }}
                                    color='secondary'
                                    InputLabelProps={{
                                        shrink: singerInfo[index].city !== '',
                                    }}
                                />
                            </Grid>
                            {!singerInfo[index].biometric ? (
                                <Grid item xs={3}>
                                    <FormControl
                                        variant='outlined'
                                        className='w-100'>
                                        <Select
                                            required
                                            options={
                                                allAdminStates &&
                                                allAdminStates.rows.map(
                                                    (el) => ({
                                                        label: el.state,
                                                        value: el.state,
                                                    })
                                                )
                                            }
                                            onChange={handleChange(
                                                'state',
                                                index,
                                                'dropdown'
                                            )}
                                            value={
                                                '' ===
                                                    singerInfo[index].state ||
                                                null === singerInfo[index].state
                                                    ? ''
                                                    : {
                                                          label:
                                                              singerInfo[index]
                                                                  .state,
                                                          value:
                                                              singerInfo[index]
                                                                  .state,
                                                      }
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            ) : (
                                <Grid item xs={3}>
                                    <TextField
                                        required
                                        label='State'
                                        value={
                                            '' === singerInfo[index].state ||
                                            null === singerInfo[index].state
                                                ? ''
                                                : singerInfo[index].state
                                        }
                                        onChange={handleChange('state', index)}
                                        margin='dense'
                                        variant='outlined'
                                        className={classes.cstm_field}
                                        inputProps={{
                                            style: { height: '18px' },
                                        }}
                                        color='secondary'
                                        InputLabelProps={{
                                            shrink:
                                                singerInfo[index].state !== '',
                                        }}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={3}>
                                <TextField
                                    required
                                    label='Zip'
                                    value={singerInfo[index].zip}
                                    onChange={handleChange('zip', index)}
                                    autoComplete='new-password'
                                    margin='dense'
                                    variant='outlined'
                                    className={classes.cstm_field}
                                    inputProps={{ style: { height: '18px' } }}
                                    color='secondary'
                                    InputLabelProps={{
                                        shrink: singerInfo[index].zip !== '',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    label='Last 4 of SSN'
                                    value={singerInfo[index].ssn}
                                    onChange={handleChange('ssn', index)}
                                    autoComplete='new-password'
                                    margin='dense'
                                    variant='outlined'
                                    className={classes.cstm_field}
                                    inputProps={{
                                        style: { height: '18px' },
                                        maxLength: 4,
                                    }}
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(
                                            /[^0-9]/g,
                                            ''
                                        )
                                    }}
                                    color='secondary'
                                    InputLabelProps={{
                                        shrink: singerInfo[index].ssn !== '',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    required={!singerInfo[index].biometric}
                                    type='date'
                                    label='Date of Birth'
                                    value={
                                        '' === singerInfo[index].dob ||
                                        null === singerInfo[index].dob
                                            ? ''
                                            : singerInfo[index].dob
                                    }
                                    onChange={handleChange('dob', index)}
                                    autoComplete='new-password'
                                    margin='dense'
                                    variant='outlined'
                                    className={classes.cstm_field}
                                    inputProps={{
                                        max: new Date()
                                            .toISOString()
                                            .split('T')[0],
                                    }}
                                    color='secondary'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3} className='mb-5'>
                                {(auth &&
                                    auth.user &&
                                    auth.user.Role &&
                                    'user' == auth.user.Role.code) &
                                (singerInfo[index].byPassKBA ||
                                    ((index < 1) & KBASettings &&
                                        KBASettings.data &&
                                        KBASettings.data.settings
                                            .bypassAllowed)) ? (
                                    <TextField
                                        required
                                        label='Reason'
                                        color='secondary'
                                        margin='dense'
                                        variant='outlined'
                                        className={classes.cstm_field}
                                        autoComplete='new-password'
                                        inputProps={{
                                            style: { height: '18px' },
                                        }}
                                        multiline
                                        rows={1}
                                        disabled={user}
                                        value={
                                            KBASettings.data &&
                                            index < 1 &&
                                            KBASettings.data.settings
                                                .bypassAllowed
                                                ? KBASettings.data.settings
                                                      .reason
                                                : singerInfo[index].reason
                                        }
                                        onChange={handleChange('reason', index)}
                                        InputLabelProps={{
                                            shrink:
                                                singerInfo[index].reason !== '',
                                        }}
                                    />
                                ) : (
                                    <></>
                                )}

                                {auth &&
                                auth.user &&
                                auth.user.Role &&
                                'notary' == auth.user.Role.code &&
                                (singerInfo[index].byPassKBA ||
                                    (index < 1 &&
                                        selectedUser &&
                                        selectedUser.kbaSettings &&
                                        selectedUser.kbaSettings[0] &&
                                        selectedUser.kbaSettings[0]
                                            .bypassAllowed)) ? (
                                    <TextField
                                        required
                                        label='Reason'
                                        color='secondary'
                                        margin='dense'
                                        variant='outlined'
                                        className={classes.cstm_field}
                                        autoComplete='new-password'
                                        inputProps={{
                                            style: { height: '18px' },
                                        }}
                                        multiline
                                        rows={1}
                                        disabled={user}
                                        value={
                                            selectedUser &&
                                            index < 1 &&
                                            selectedUser.kbaSettings &&
                                            selectedUser.kbaSettings[0] &&
                                            selectedUser.kbaSettings[0]
                                                .bypassAllowed
                                                ? selectedUser.kbaSettings[0]
                                                      .reason
                                                : singerInfo[index].reason
                                        }
                                        onChange={handleChange('reason', index)}
                                        InputLabelProps={{
                                            shrink:
                                                singerInfo[index].reason !== '',
                                        }}
                                    />
                                ) : (
                                    <></>
                                )}
                            </Grid>
                        </Grid>
                        {user ? (
                            !singerInfo[index].byPassKBA && (
                                <p className='pb-0 mb-0'>
                                    {/*  /*<MultiSelect
                                                options={options}
                                                value={singerInfo[index].checklist}
                                                onChange={handleChange('checklist', index)}
                                                labelledBy="CheckList"
                                                label="CheckList"
                                            /> <Checkbox
                                        checked={singerInfo[index].notGovtID}
                                        onChange={handleChange('notGovtID', index)}
                                        value="notGovtID"
                                        inputProps={{
                                            'aria-label': 'primary checkbox',
                                        }}
                                    />
                                    Don't have a government ID? &nbsp; */}
                                    <span className='mt-5'>
                                        Each Signer will need to have a
                                        smartphone, a system, and a valid government ID. If
                                        you have questions about this, please
                                        contact your Primary Notary or{' '}
                                        <span
                                            style={{
                                                textDecoration: 'underline',
                                                color: '#1840d2',
                                                cursor:
                                                    disabled === ''
                                                        ? 'pointer'
                                                        : 'auto',
                                                pointerEvents:
                                                    disabled === ''
                                                        ? 'auto'
                                                        : 'none',
                                            }}
                                            onClick={() =>
                                                draftHandler('help')
                                            }>
                                            click here
                                        </span>{' '}
                                        for further assistance.
                                    </span>
                                </p>
                            )
                        ) : (
                            <p className='pb-0 mb-0'></p>
                        )}
                    </div>
                )
            })}
        </>
    )
}
