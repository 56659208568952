import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../_metronic/_partials/controls";
import * as actions from "../../components/_redux/notarization_redux/notarizationActions";
import { Link } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
export function AllWitnesses(props) {
  const dispatch = useDispatch();
  const { auth, notarization } = useSelector(state => state);
  const { witnesses, adobeURL, getAllJournal } = notarization;
  const [journalID, setjournalID] = useState(
    props.journalID ? props.journalID : 0
  );
  const [witnessstatusInterval, setwitnessstatusInterval] = useState("");
  const [alldone, setalldone] = useState(witnesses.length > 0 ? false : true);
  const [isdonewitness, setisdonewitness] = useState(false);
  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  useEffect(() => {
    if (witnesses.length > 0 && !witnessstatusInterval && !alldone)
      setwitnessstatusInterval(
        setInterval(function() {
          dispatch(actions.fetchWitnessesforJournal(journalID, headers));
        }, 6000)
      );

    if (checkwitnessstatus() && witnessstatusInterval) {
      setwitnessstatusInterval(clearInterval(witnessstatusInterval));
      setalldone(true);
    }
  }, [witnesses]);

  const doneWitness = () => {
    setisdonewitness(true);
    dispatch(actions.doneWitness(headers, journalID));
  };

  const callWitnessing = async (witnessID, journalID) => {
    const data = {
      accept: false,
      journalId: journalID,
      witnessID: witnessID
    };

    let witnessresponse = await dispatch(
      actions.acceptWitnessing(headers, data)
    );

    if (witnessresponse) {
      if (witnessresponse.data) {
        window.location.reload();
      }
    }
  };

  const checkwitnessstatus = () => {
    for (var iwitness = 0; iwitness < witnesses.length; iwitness++) {
      if ("accepted" !== witnesses[iwitness].status) {
        let lastTime = witnesses[iwitness].updatedAt;
        const date1 = new Date();
        const date2 = new Date(lastTime);
        let diffMs = date1 - date2;

        var diffDays = Math.floor(diffMs / 86400000); // days
        var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
        var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

        if (diffDays > 0 || diffHrs > 0 || diffMins == 1) {
          callWitnessing(witnesses[iwitness].id, journalID);
        }
        return false;
      }
    }

    return true;
  };

  return (
    <Card style={{ minWidth: 275 }} variant="outlined">
      <CardHeader>
        <h5>Witnesses</h5>
        {/* {(!adobeURL) ? <Link to={"/searchWitnesses/?id=" + journalID}>
					Add More
				</Link>:""} */}
      </CardHeader>
      <CardBody>
        {/* {(witness.user.profile_picture) ?
										<img src={witness.user.profile_picture ? witness.user.profile_picture.baseUrl + witness.user.profile_picture.path : "/media/users/blank.png"} alt="" /> :
										<img src="/media/users/blank.png" class="img-witness" />
									} */}
        {"Witnesses Ready" != getAllJournal.journal.subStatus &&
        getAllJournal.journal.witnessCount ? (
          <p className="p1">
            A total of <b>{getAllJournal.journal.witnessCount}</b> witnesses
            were requested for this notarization; once all spots have been
            claimed you will be able to move forward with the notarization
          </p>
        ) : (
          <></>
        )}

        {witnesses.length > 0 ? (
          <div className="witness_main_container">
            {witnesses.map((witness, i) => (
              <div className="witnessprofile">
                <span class="witnesspic">
                  <img src="/media/users/blank.png" class="img-witness" />
                </span>
                <span class="witnessname">
                  {witness.User
                    ? witness.User.firstName + " " + witness.User.lastName
                    : ""}
                </span>
                <span class="witnessstatus">
                  {witness.status == "pending" ? (
                    <img src="/media/misc/inprogress.gif" class="img-witness" />
                  ) : (
                    <img
                      src="/media/misc/successtick.png"
                      class="img-witness"
                    />
                  )}
                </span>
              </div>
            ))}
          </div>
        ) : (
          ""
        )}
        {(("Witnesses Ready" == getAllJournal.journal.subStatus &&
          getAllJournal.journal.witnessCount) ||
          !getAllJournal.journal.witnessCount) &&
        alldone &&
        !adobeURL ? (
          <div className="main_done_witness">
            <button
              type="button"
              disabled={isdonewitness}
              className="btn doneWitnessBtn"
              onClick={doneWitness}
            >
              {!isdonewitness ? (
                <>Next</>
              ) : (
                <CircularProgress classes={"colorPrimary"} size={15} />
              )}
            </button>
          </div>
        ) : (
          ""
        )}
      </CardBody>
    </Card>
  );
}
