import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReadyBankView from "./ReadyBankView";

export function AddBankModal({ openModal, setModal, setNotification }) {
  // Getting curret state from store (Redux)
  const { auth } = useSelector(state => state);
  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json"
  };

  return (
    <div>
      <ReadyBankView
        openModal={openModal}
        setModal={setModal}
        userID={auth.user.id}
        headers={headers}
        setNotification={setNotification}
      />
    </div>
  );
}
