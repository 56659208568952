import React, { Suspense, useEffect, useState } from "react";
import { TableUIWitnessingContext } from "../components/signwitnesss/TableUIWitnessingContext";
import { TableAdobeSignIframeCard } from "../components/signwitnesss/TableAdobeSignIframeCard";
import { NewNotarization } from "../pages/NewNotarization";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../components/_redux/notarization_redux/notarizationActions";
import { Redirect, useLocation } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";

export function TemplatePage(props) {
  const MIDDLEWARE_URL = process.env.REACT_APP_MIDDLEWARE_URL;
  const [actionModal, setActionModal] = useState(false);
  const [isActive, setisActive] = useState(false);
  const [initiate, setinitiate] = useState(false);
  const [istempurl, setistempurl] = useState(false);
  const [maintempURL, setmaintempURL] = useState("");
  const { auth, notarization } = useSelector(state => state);
  const location = useLocation();
  const dispatch = useDispatch();
  const { templateURL } = useSelector(state => state.notarization);
  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  const htmlize = () => {
    var e = document.createElement("div");
    e.innerHTML = document.getElementById("messageContent").innerHTML;
    document.getElementById("messageContent").innerHTML =
      e.childNodes[0].nodeValue;

    document.getElementById("messageContent").style.display = "block";

    //return htmlcontent.replace( /(<([^>]+)>)/ig, '');
  };

  useEffect(() => {
    if (
      auth &&
      auth.user &&
      auth.user.notaryEmail &&
      auth.user.notaryEmail.refreshToken &&
      !istempurl
    ) {
      dispatch(actions.getTemplateURL(auth.user.id, headers));
      setistempurl(true);
    } else if (localStorage.getItem("adobeCredentials") && !istempurl) {
      let adobeCredentials = localStorage.getItem("adobeCredentials");
      let adobeCredentialsarr = adobeCredentials.split("|");

      if (auth.user.id == adobeCredentialsarr[0]) {
        dispatch(actions.getTemplateURL(auth.user.id, headers));
        setistempurl(true);
      } else {
        window.location.href = "#/dashboard";
        window.location.reload();
      }
    } else if (!istempurl) {
      window.location.href = "#/dashboard";
      window.location.reload();
    }
    // if(auth.user.id === journal.)
  }, [dispatch, templateURL]);

  return (
    <>
      {maintempURL ? (
        <a target="_blank" href={maintempURL}>
          {" "}
          Connect with Adobe{" "}
        </a>
      ) : (
        <iframe
          src={templateURL}
          frameborder={0}
          style={{ width: "100%", height: "1000px" }}
        >
          {templateURL}
        </iframe>
      )}
    </>
  );
}
