import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, Row, Col } from 'react-bootstrap'
import { Grid, TextField } from '@material-ui/core'
import ShowNotification from '../new_notarization/ShowNotification'
import { ImageUpload } from '../ImageUpload'
import { additionalInformation } from '../_redux/notarization_redux/notarizationCrud'
import * as actions from '../../components/_redux/notarization_redux/notarizationActions'
import { ViewImage } from "../ViewImage";

export const AdditionalInformation = ({
    data,
    openModal,
    onHide,
    journalId,
    draftHandler,
}) => {
    const [additionalDetail, setAdditionalDetail] = useState(data?.extendedJournal !== null ? JSON.parse(data?.extendedJournal) : data)
    const [uploading, setUploading] = useState()
    const [notification, setNotification] = useState({
        show: false,
        msg: '',
        variant: '',
    })
    const dispatch = useDispatch()
    const { auth } = useSelector((state) => state)

    const headers = {
        Authorization: `Bearer ${auth.authToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
    }
    const handleChange = (fieldName, index) => (event) => {
        let info = JSON.parse(JSON.stringify({ ...additionalDetail }))

        if (!info.POA) {
            info = { ...info, POA: {} }
        }
        const value = event.target.value

        if (fieldName === 'transactionNumber') {
            info.journalDocs[index].File.transactionNumber = value
        }

        if (['name', 'address'].includes(fieldName)) {
            info.POA[fieldName] = value
        }

        if (['videoUrl', 'additionalNotes'].includes(fieldName)) {
            info[fieldName] = event.target.value
        }
        setAdditionalDetail(info)
    }

    const onSubmit = async () => {
        const { data } = await additionalInformation(additionalDetail, headers)
        if (data.status === 'success') {
            dispatch(
                actions.fetchJournals(
                    {
                        sortOrder: 'desc', // asc||desc
                        sortField: 'id',
                        pageNumber: 1,
                        pageSize: 10,
                    },
                    headers,
                    auth.user.Role.code
                )
            )
            dispatch(actions.fetchTop5Journals(headers, auth.user.Role.code))
            setNotification({
                show: true,
                msg: 'Additional Information has been added succussfully',
                variant: 'success',
            })
            onHide()
        }
    }
    const handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') return
        setNotification({ show: false, msg: '', variant: '' })
    }

    useEffect(()=> {
        setAdditionalDetail(data?.extendedJournal !== null ? JSON.parse(data?.extendedJournal) : data);
    }, []);

    return (
        <Modal
            size='xl'
            show={openModal}
            onHide={onHide}
            aria-labelledby='example-modal-sizes-title-xl'>
            {notification.show && (
                <ShowNotification
                    show={notification.show}
                    msg={notification.msg}
                    variant={notification.variant}
                    close={handleCloseNotification}
                />
            )}
            <div className='container mt-5 mb-5'>
                <h3>Additional Information </h3>
                {additionalDetail?.journalDocs?.map((record, index) => {
                    return (
                        <Grid container>
                            <Grid xs={6}>
                                <Col>
                                    {' '}
                                    <b>Document (s)</b>
                                </Col>
                            </Grid>
                            <Grid xs={6}>
                                <Col>
                                    {' '}
                                    <b>Adobe Transaction #</b>
                                </Col>
                            </Grid>
                            <Grid xs={6}>
                                <Col>
                                    {' '}
                                    <TextField
                                        value={
                                            record.File?.filename
                                                ? record.File?.filename
                                                : ''
                                        }
                                        disabled={true}
                                        margin='dense'
                                        variant='outlined'
                                        inputProps={{
                                            style: { height: '18px' },
                                        }}
                                        style={{ width: "100%" }}
                                        color='secondary'
                                    />
                                </Col>
                            </Grid>
                            <Grid xs={6}>
                                <Col>
                                    <TextField
                                        placeholder='Enter Transaction Number'
                                        value={
                                            record.File?.transactionNumber
                                                ? record.File.transactionNumber
                                                : ''
                                        }
                                        disabled={data?.extendedJournal !== null && record.File?.transactionNumber ? true : false }
                                        onChange={handleChange(
                                            'transactionNumber',
                                            index
                                        )}
                                        margin='dense'
                                        variant='outlined'
                                        inputProps={{
                                            style: { height: '18px' },
                                        }}
                                        style={{ width: "100%" }}
                                        color='secondary'
                                    />
                                </Col>
                            </Grid>
                        </Grid>
                    )
                })}
                <br />
                <Grid container>
                    <Grid xs={6}>
                        <Col>
                            <div>
                                If you are storing the video outside the platform,
                                detail the location here:
                            </div>
                        </Col>
                    </Grid>
                    <Grid xs={6}>
                        <Col>
                            <TextField
                                placeholder='Enter Storage Location Here'
                                value={
                                    additionalDetail?.videoUrl
                                        ? additionalDetail.videoUrl
                                        : ''
                                }
                                disabled={data?.extendedJournal !== null && additionalDetail?.videoUrl ? true : false }
                                onChange={handleChange('videoUrl')}
                                margin='dense'
                                autoComplete='new-password'
                                variant='outlined'
                                inputProps={{ style: { height: '18px' } }}
                                style={{ width: "100%" }}
                                color='secondary'
                            />
                        </Col>
                    </Grid>
                </Grid>
                <br />
                <Grid container>
                    <Grid xs={6}>
                        <Col>
                            <div>Upload an image of each signer's signature:</div>
                        </Col>
                    </Grid>
                </Grid>
                <br />
                <Grid container>
                    {additionalDetail?.Signers?.map((signer, index) => {
                        return (
                            <>
                                <Grid key={index}>
                                    <Col>
                                        <b>
                                            {signer?.firstName
                                                ? signer.firstName
                                                : ''}{' '}
                                            {signer?.lastName
                                                ? signer.lastName
                                                : ''}
                                        </b>
                                        {
                                            ( ! signer?.signatureImage || signer?.signatureImage === "") && 
                                            ( auth.user.Role.code !== "notary" || (auth.user.Role.code === "notary" && !auth.user.notaryProfile.isCancelled ) ) ? 
                                            <ImageUpload
                                                setNotification={setNotification}
                                                setUploading={setUploading}
                                                index={index}
                                                auth={auth}
                                                additionalDetail={additionalDetail}
                                                setAdditionalDetail={(val) => {
                                                    setAdditionalDetail(val)
                                                }}
                                                headers={headers}
                                            /> 
                                            :
                                            <>
                                                <br/>
                                                <ViewImage index={index} signer={signer} />
                                            </>
                                        }
                                    </Col>
                                </Grid>
                            </>
                        )
                    })}
                </Grid>

                <Grid container>
                    <Grid xs={6}>
                        <Col>
                            <p>Additional Notes about this Notarization:</p>
                            <textarea
                                placeholder='Enter Additional Notes'
                                name='Additional Notes'
                                onChange={handleChange('additionalNotes')}
                                disabled={data?.extendedJournal !== null && additionalDetail?.additionalNotes ? true : false }
                                style={{
                                    width: '100%',
                                    height: '150px',
                                }}>{additionalDetail?.additionalNotes ? additionalDetail?.additionalNotes : ""}</textarea>
                        </Col>
                    </Grid>
                    <Grid xs={6} className='pl-5'>
                        <Col>
                            <Row>
                                <p>
                                    If a POA was signed, List the Agent's
                                    Information
                                </p>
                            </Row>
                            <Row>
                                <b>Name</b>
                            </Row>
                            <Row>
                                <TextField
                                    placeholder='Enter Name'
                                    value={
                                        additionalDetail?.POA?.name
                                            ? additionalDetail?.POA.name
                                            : ''
                                    }
                                    disabled={data?.extendedJournal !== null && additionalDetail?.POA?.name ? true : false }
                                    margin='dense'
                                    variant='outlined'
                                    onChange={handleChange('name')}
                                    inputProps={{ style: { height: '18px' } }}
                                    color='secondary'
                                    style={{ width: "100%" }}
                                />
                            </Row>
                            <Row>
                                <b>Address</b>
                            </Row>
                            <Row>
                                <TextField
                                    placeholder='Enter Address'
                                    value={
                                        additionalDetail?.POA?.address
                                            ? additionalDetail.POA.address
                                            : ''
                                    }
                                    disabled={data?.extendedJournal !== null && additionalDetail?.POA?.address ? true : false }
                                    margin='dense'
                                    variant='outlined'
                                    onChange={handleChange('address')}
                                    inputProps={{ style: { height: '18px' } }}
                                    color='secondary'
                                    style={{ width: "100%" }}
                                />
                            </Row>
                        </Col>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid xs={6}>
                        <Col>
                            <div>
                            Once you save this information, you will no longer be able to edit or add to this journal entry. Ensure you have completed all fields prior to saving. 
                            </div>
                        </Col>
                    </Grid>
                    {( (data?.extendedJournal === null) && 
                    ( auth.user.Role.code !== "notary" || (auth.user.Role.code === "notary" && !auth.user.notaryProfile.isCancelled ) ) ) &&
                    (
                        <Grid xs={6}>
                            <Col className='pl-5'>
                                <button
                                    type='button'
                                    className='btn'
                                    style={{
                                        backgroundColor: '#2d3fd3',
                                        color: 'white',
                                        marginButtom: '10px',
                                        marginRight: '10px',
                                    }}
                                    onClick={onSubmit}>
                                    Save
                                </button>
                                <button
                                    type='button'
                                    className='btn'
                                    style={{
                                        backgroundColor: 'red',
                                        color: 'white',
                                    }}
                                    onClick={() => {
                                        onHide()
                                    }}>
                                    Close
                                </button>
                            </Col>
                        </Grid>
                    )}
                </Grid>
            </div>
        </Modal>
    )
}
