import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/notarization_redux/notarizationActions";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses
} from "../../../../_metronic/_helpers";
import * as uiHelpers from "../TableUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../_metronic/_partials/controls";
import { useTableUIContext } from "../TableUIContext";

export function CustomTable() {
  // Customers UI Context
  const customersUIContext = useTableUIContext();

  const dispatch = useDispatch();
  const { auth, notarization } = useSelector(state => state);
  const [isfetchjournals, setisfetchjournals] = useState(0);
  const [isfetchjournalssize, setisfetchjournalssize] = useState(0);
  const { MyNotaries, customizeJobs, listLoading } = notarization;
  const [checkKBA, setcheckKBA] = useState(0);
  const [listKBA, setlistKBA] = useState("");
  const [reason, setReason] = useState("");

  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      setIds: customersUIContext.setIds,
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
      removeFromNotary: customersUIContext.removeFromNotary,
      openClaimPopup: customersUIContext.openClaimPopup
    };
  }, [customersUIContext]);

  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  // Table columns
  const columns = [
    {
      dataField: "firstName",
      text: "First Name",
      formatter: columnFormatters.TitleColumnFormatter
    },
    {
      dataField: "lastName",
      text: "Last Name",
      formatter: columnFormatters.LastNameColumnFormatter
    },
    {
      dataField: "email",
      text: "Email",
      formatter: columnFormatters.DateColumnFormatter
    },
    {
      dataField: "state",
      text: "State",
      formatter: columnFormatters.StateColumnFormatter
    },
    {
      dataField: "phone",
      text: "Phone",
      formatter: columnFormatters.PhoneColumnFormatter
    },
    {
      dataField: "action",
      text: "Action",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        removeFromNotary: customersUIProps.removeFromNotary,
        openClaimPopup: customersUIProps.openClaimPopup
      },
      classes: "text-center pr-0",
      headerClasses: "text-center pr-3",
      style: {
        minWidth: "100px"
      }
    }
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: MyNotaries === null ? 0 : MyNotaries.count,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: customersUIProps.queryParams.pageSize,
    page: customersUIProps.queryParams.pageNumber
  };

  useEffect(() => {
    if (isfetchjournals != customersUIProps.queryParams.pageNumber) {
      dispatch(
        actions.fetchMyNotaries(
          customersUIProps.queryParams,
          headers,
          auth.user.Role.code
        )
      );
      setisfetchjournals(customersUIProps.queryParams.pageNumber);
    }

    if (isfetchjournalssize != customersUIProps.queryParams.pageSize) {
      dispatch(
        actions.fetchMyNotaries(
          customersUIProps.queryParams,
          headers,
          auth.user.Role.code
        )
      );
      setisfetchjournalssize(customersUIProps.queryParams.pageSize);
    }
  }, [customersUIProps.queryParams]);

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="id"
                data={MyNotaries === null ? [] : MyNotaries.rows}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  customersUIProps.setQueryParams
                )}
                {...paginationTableProps}
              >
                <PleaseWaitMessage
                  entities={MyNotaries === null ? [] : MyNotaries.rows}
                />
                <NoRecordsFoundMessage
                  entities={MyNotaries === null ? [] : MyNotaries.rows}
                />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
