import React, { useEffect, useState } from "react";
import requestPayoutAmount from "../../media/Group 114@3x.png";
import { Tooltip } from "@material-ui/core";
import { useSelector } from "react-redux";

export function PaymentPending({ type, heading, amount }) {
  const { auth } = useSelector(state => state);

  return (
    <div>
      <span className={type}>
        <Tooltip
          title={
            <h6 style={{ color: "white" }}>
              Your ${amount} amount is pending and will be released in 2
              business days.
            </h6>
          }
        >
          <div>
            <h3>{heading}</h3>
            <p style={{ fontSize: "20px" }}>${amount}</p>
          </div>
        </Tooltip>

        <div className="main-request-total">
          <img
            src={requestPayoutAmount}
            className="requestPayoutTotal"
            alt=""
          />
        </div>
      </span>
    </div>
  );
}
