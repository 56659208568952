// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";

export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  { statusswitcher }
) {
  const joinMeeting = meetingURL => {
    window.top.location.href = meetingURL;
  };

  return (
    <>
      {row.status == "pending" ? (
        <span>
          <a
            className="btn btn-light btn-primary"
            onClick={() => statusswitcher(row.obj.Journal.id, "accept")}
          >
            Accept
          </a>{" "}
          <a
            className="btn btn-light btn-danger"
            onClick={() => statusswitcher(row.obj.Journal.id, "reject")}
          >
            Reject
          </a>{" "}
        </span>
      ) : (
        <span>
          {" "}
          {row.status == "Accepted" &&
          row.obj.Journal.Meeting &&
          row.obj.Journal.status == "progress" ? (
            <a
              className="btn btn-light btn-danger"
              onClick={() => joinMeeting(row.obj.Journal.Meeting.jitsiUrl)}
            >
              Join Session
            </a>
          ) : (
            <span>
              {row.obj.Journal.status == "completed" ? "Session Completed" : ""}
            </span>
          )}
        </span>
      )}
    </>
  );
}
