import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./TableUIHelpers";

const TableUIContext = createContext();

export function useTableUIContext() {
  return useContext(TableUIContext);
}

export const TableUIConsumer = TableUIContext.Consumer;

export function TableUIProvider({
  customersUIEvents,
  children,
  schedule,
  completed
}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initCustomer = {
    id: undefined,
    notary_name: "",
    Sign_up_date: "",
    email_address: "",
    Phone_Number: "",
    notarizations: "",
    active_Inactive: ""
  };
  let value = {};
  if (completed) {
    value = {
      queryParams,
      setQueryParamsBase,
      ids,
      setIds,
      setQueryParams,
      initCustomer,
      openEditCustomerDialog: customersUIEvents.openEditCustomerCompletedDialog
    };
  } else if (schedule) {
    value = {
      queryParams,
      setQueryParamsBase,
      ids,
      setIds,
      setQueryParams,
      initCustomer,
      openEditCustomerDialog: customersUIEvents.openEditCustomerScheduleDialog
    };
  } else {
    value = {
      queryParams,
      setQueryParamsBase,
      ids,
      setIds,
      setQueryParams,
      initCustomer,
      openEditCustomerDialog: customersUIEvents.openEditCustomerDialog
    };
  }

  return (
    <TableUIContext.Provider value={value}>{children}</TableUIContext.Provider>
  );
}
