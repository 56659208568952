import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { connect, useDispatch, useSelector } from 'react-redux'
import { injectIntl } from 'react-intl'
import * as auth from '../_redux/authRedux'
import { login } from '../_redux/authCrud'
import './../CSS/Style.css'
import {
    Button,
    TextField,
    InputAdornment,
    IconButton,
} from '@material-ui/core'
import logo from './../media/LOGO.jpg'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Alert from '@material-ui/lab/Alert'
import { notarizationSlice } from '../../../components/_redux/notarization_redux/notarizationSlice'
import ShowNotification from '../../../components/new_notarization/ShowNotification'
import { TwoFactorAuth } from './TwoFactorAuth'

const { actions } = notarizationSlice

const initialValues = {
    email: '',
    password: '',
}

function Login(props) {
    const dispatch = useDispatch()
    localStorage.removeItem('adobeCredentials')
    const { auth } = useSelector((state) => state)

    const [values, setValues] = React.useState({
        password: '',
        email: '',
        showPassword: false,
    })

    const [hasCode, setHasCode] = useState(false)
    const [code, setCode] = useState('')

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword })
    }

    const { intl } = props
    const [loading, setLoading] = useState(false)
    const [adminMSG, setAdminMSG] = useState({
        show: false,
        msg: '',
        variant: '',
    })
    const LoginSchema = Yup.object().shape({
        email: Yup.string()
            .email('Wrong email format')
            .required('Enter Your Email'),
        password: Yup.string().required('Enter your password to continue'),
    })

    const goTONotary = () => {
        window.location.href = 'https://notary.cyberizeit.com/ic-agreement/'
    }

    const signUPProfessional = () => {
        window.location.href = '/#/auth/signupprofessional'
    }

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return 'is-invalid'
        }
        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return 'is-valid'
        }

        return ''
    }
    const enableLoading = () => {
        setLoading(true)
    }
    const disableLoading = () => {
        setLoading(false)
    }

    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setValues(values)

            submit(values.email, values.password, setStatus, setSubmitting)

            setValues({ ...values }, () => {})
        },
    })

    const submit = (
        email = values.email,
        password = values.password,
        setStatus,
        setSubmitting
    ) => {
        enableLoading()
        setTimeout(() => {
            login(email, password, code)
                .then(({ data }) => {
                    disableLoading()
                    if (data?.message === 'check your email') {
                        setHasCode(true)
                        setAdminMSG({
                            show: true,
                            msg: data?.message,
                            variant: 'success',
                        })
                    } else {
                        // saves
                        props.login(data)

                        window.location.href = '/#/dashboard'

                        dispatch({ type: 'user_registered', payload: false })
                    }
                })
                .catch((err) => {
                    let errorMSg =
                        err?.response?.data?.message || 'Error logging in'
                    setAdminMSG({
                        show: true,
                        msg: errorMSg,
                        variant: 'error',
                    })

                    disableLoading()
                    setSubmitting && setSubmitting(false)
                    // setStatus(
                    //   intl.formatMessage({
                    //     id: "email or password is incorrect",
                    //   })
                    // );
                })
        }, 1000)
    }

    useEffect(() => {
        if (auth.authToken === undefined) {
            dispatch(actions.clearAllState())
        }
    }, [auth.authToken])

    const handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') return
        setAdminMSG({ show: false, msg: '', variant: '' })
    }

    return (
        <>
            {adminMSG.show && (
                <ShowNotification
                    show={adminMSG.show}
                    msg={adminMSG.msg}
                    variant={adminMSG.variant}
                    close={handleCloseNotification}
                />
            )}
            {hasCode ? (
                <TwoFactorAuth code={code} setCode={setCode} submit={submit} />
            ) : (
                <div
                    className='login-form login-signin'
                    id='kt_login_signin_form'>
                    {/* begin::Head */}
                    <img
                        src={logo}
                        alt='Logo'
                        className='w-25 '
                        style={{ marginTop: -20 }}
                    />
                    <div className='text-start'>
                        <h3 className='font-size-h1'>Get started</h3>
                        <p className='' style={{ color: '#5c5c5c' }}>
                            Please login to continue
                        </p>
                    </div>
                    {/* end::Head */}
                    {auth.isRegister && (
                        <Alert severity='success' className='mb-3'>
                            You're successfully signed up!
                        </Alert>
                    )}
                    {/*begin::Form*/}
                    <form
                        onSubmit={formik.handleSubmit}
                        className='form fv-plugins-bootstrap fv-plugins-framework'>
                        {adminMSG.show && (
                            <ShowNotification
                                show={adminMSG.show}
                                msg={adminMSG.msg}
                                variant={adminMSG.variant}
                                close={handleCloseNotification}
                            />
                        )}

                        {/* {formik.status ? (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          ) : (
            ""
          )} */}

                        <div className='form-group fv-plugins-icon-container'>
                            <TextField
                                className={`w-100 ${getInputClasses('email')}`}
                                label='Email'
                                variant='outlined'
                                type='email'
                                name='email'
                                value={values.email}
                                color={'secondary'}
                                {...formik.getFieldProps('email')}
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <div
                                    className='fv-plugins-message-container'
                                    style={{ color: 'red' }}>
                                    <div className='fv-help-block'>
                                        {formik.errors.email}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div className='form-group fv-plugins-icon-container'>
                            <TextField
                                id='filled-adornment-password'
                                className='w-100'
                                variant='outlined'
                                type={values.showPassword ? 'text' : 'password'}
                                label='Password'
                                value={values.password}
                                color={'secondary'}
                                {...formik.getFieldProps('password')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton
                                                edge='end'
                                                aria-label='Toggle password visibility'
                                                onClick={
                                                    handleClickShowPassword
                                                }>
                                                {values.showPassword ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {formik.touched.password &&
                            formik.errors.password ? (
                                <div
                                    className='fv-plugins-message-container'
                                    style={{ color: 'red' }}>
                                    <div className='fv-help-block'>
                                        {formik.errors.password}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div className='form-group d-flex flex-wrap justify-content-between align-items-center'>
                            <Link
                                className='h-75'
                                to='/auth/forgot-password'
                                id='kt_login_forgot'
                                style={{ color: '#2d3fd3' }}>
                                Click here to reset your password.
                            </Link>
                            <button
                                id='kt_login_signin_submit'
                                type='submit'
                                // disabled={formik.isSubmitting}
                                className={`btn btn-primary font-weight-bold px-9 py-3 my-3 w-100 `}>
                                <span>Sign In</span>
                                {loading && (
                                    <span className='ml-3 spinner spinner-white'></span>
                                )}
                            </button>
                            <div>
                                <p
                                    className=''
                                    style={{ color: '#5c5c5c', float: 'left' }}>
                                    Don't have an account?
                                </p>
                                <Link
                                    className='px-9 py-3 my-3 ml-2'
                                    to='/auth/registration'
                                    id='kt_login_forgot'
                                    style={{ color: '#2d3fd3' }}>
                                    Sign Up Now
                                </Link>
                                <div className='separator'>OR</div>
                            </div>
                        </div>
                        <Button
                            variant='outlined'
                            color='secondary'
                            className='w-100 p-5'
                            onClick={goTONotary}>
                            Join our Notary Team
                        </Button>

                        <Button
                            variant='outlined'
                            color='secondary'
                            className='w-100 p-5'
                            onClick={signUPProfessional}
                            style={{ marginTop: '10px' }}>
                            Sign Up For Professional Services Account
                        </Button>
                    </form>

                    {/*end::Form*/}
                </div>
            )}
        </>
    )
}

export default injectIntl(connect(null, auth.actions)(Login))
