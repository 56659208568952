import React from "react";
import {
  Card,
  CardBody,
  CardHeader
} from "../../../_metronic/_partials/controls";
import { CustomTable } from "./table/CustomTable";

export function TableCard() {
  return (
    <Card>
      <CardHeader title="My Clients" className="py-6"></CardHeader>
      <CardBody>
        <CustomTable />
      </CardBody>
    </Card>
  );
}
