import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/notarization_redux/notarizationActions";
import {
  getHandlerTableChange,
  sortCaret,
  headerSortingClasses
} from "../../../../_metronic/_helpers";
import * as uiHelpers from "../TableUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../_metronic/_partials/controls";
import { useTableUIContext } from "../TableUIContext";
import { GenericZeroState } from "../../zero_state_pages/GenericZeroState";

export function CustomTable(props) {
  // Customers UI Context
  const customersUIContext = useTableUIContext();
  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      setIds: customersUIContext.setIds,
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
      openEditCustomerDialog: customersUIContext.openEditCustomerDialog
    };
  }, [customersUIContext]);

  const { auth, notarization } = useSelector(state => state);
  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  // Customers Redux state
  const dispatch = useDispatch();
  const queryParams = [];

  if (props.keyword) queryParams.push("q=" + props.keyword);

  if (props.statusfilter) queryParams.push("status=" + props.statusfilter);

  if ("online" == props.modefilter) queryParams.push("mode=true");

  const {
    allAdminNotaries,
    listLoading,
    customizeAllAdminNotaries,
    changeActiveStatusLoading
  } = notarization;

  const updateStatus = (id, status) => {
    dispatch(
      actions.updateNotaryStatus(
        { userId: id, status: status },
        headers,
        queryParams,
        customersUIProps.queryParams
      )
    );
  };

  const updateClaimJobBoardStatus = (id, status) => {
    
    dispatch(
      actions.updateClaimJobBoardStatus(
        id, 
        { claimJob: status},
        headers,        
        queryParams,
        customersUIProps.queryParams
      )
    );
  };

  useEffect(() => {
    if (props.searchClicked) {
      customersUIProps.queryParams.pageNumber = 1;
      dispatch(
        actions.fetchAdminNotaries(
          headers,
          queryParams,
          customersUIProps.queryParams
        )
      );
    }
    else {
      props.setQueries(prevQueryParams => {
        return {...prevQueryParams, ...customersUIProps.queryParams };
      })
    }
  }, [customersUIProps.queryParams, dispatch, props.search]);
  // Table columns
  const columns = [
    {
      dataField: "sno",
      text: "#"
    },
    {
      dataField: "name",
      text: "NOTARY NAME"
    },
    {
      dataField: "signup_date",
      text: "SIGN UP DATE"
    },
    {
      dataField: "email",
      text: "EMAIL ADDRESS"
    },
    {
      dataField: "state",
      text: "STATE",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: "notaryExpiry",
      text: "EXP DATE",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: "phone",
      text: "PHONE NUMBER"
    },
    {
      dataField: "notarizations",
      text: "NOTARIZATIONS"
    },
    {
      dataField: "notaryProfile.claimJob",
      text: "Job Board",
      formatter: columnFormatters.JobBoardColumnFormatterAdmin,
      formatExtraData: {
        updateClaimJobBoardStatus: updateClaimJobBoardStatus,
        updateStatus: updateStatus
      }
    },
    {
      dataField: "active_Inactive",
      text: "ACTION",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        updateStatus: updateStatus,
        openEditCustomerDialog: customersUIProps.openEditCustomerDialog,
        changeActiveStatusLoading: changeActiveStatusLoading
      }
    }
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: allAdminNotaries === null ? 0 : allAdminNotaries.count,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: customersUIProps.queryParams.pageSize,
    page: customersUIProps.queryParams.pageNumber
  };

  return (
    <>
      {allAdminNotaries !== null && allAdminNotaries.rows.length > 0 ? (
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => {
            return (
              <Pagination
                isLoading={listLoading}
                paginationProps={paginationProps}
              >
                <BootstrapTable
                  wrapperClasses="table-responsive"
                  bordered={false}
                  classes="table table-head-custom table-vertical-center overflow-hidden"
                  bootstrap4
                  remote
                  keyField="id"
                  data={
                    customizeAllAdminNotaries === null
                      ? []
                      : customizeAllAdminNotaries
                  }
                  columns={columns}
                  defaultSorted={uiHelpers.defaultSorted}
                  onTableChange={getHandlerTableChange(
                    customersUIProps.setQueryParams,
                    props.setSearchClicked
                  )}
                  {...paginationTableProps}
                ></BootstrapTable>
              </Pagination>
            );
          }}
        </PaginationProvider>
      ) : (
        <GenericZeroState
          status={allAdminNotaries}
          imageURL="/media/own/no-notary-online.png"
          heading=""
          body="Unfortunately, there are no Notaries which match the requirements you have selected."
          sub_body=""
          btnTxt="Start a New Search"
          btnHandler={e => props.applyFilterHandler("clear")}
        />
      )}
    </>
  );
}
