import { Divider, TextField, makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Button, Modal, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../components/_redux/notarization_redux/notarizationActions'
import ShowNotification from '../../components/new_notarization/ShowNotification'
import { AdditionalInformation } from './AdditionalInformation'
import { Grid } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
    draft: {
        minWidth: '110px',
        backgroundColor: '#524440',
        color: '#fff',
        textAlign: 'center',
        borderRadius: '5px',
        padding: '9px 5px',
    },
    progress: {
        minWidth: '110px',
        backgroundColor: '#eee',
        color: '#000',
        textAlign: 'center',
        borderRadius: '5px',
        padding: '9px 5px',
    },
    completed: {
        minWidth: '110px',
        backgroundColor: '#bef2de',
        color: '#000',
        textAlign: 'center',
        borderRadius: '5px',
        padding: '9px 5px',
    },
    scheduled: {
        minWidth: '110px',
        backgroundColor: '#ff9a00',
        color: '#fff',
        textAlign: 'center',
        borderRadius: '5px',
        padding: '9px 5px',
    },
    initiated: {
        minWidth: '110px',
        backgroundColor: '#ffff83',
        color: '#000',
        textAlign: 'center',
        borderRadius: '5px',
        padding: '9px 5px',
    },
    pending: {
        minWidth: '110px',
        backgroundColor: '#4ABD54',
        color: '#fff',
        textAlign: 'center',
        borderRadius: '5px',
        padding: '9px 5px',
    },
    paid: {
        minWidth: '110px',
        backgroundColor: '#4ABD54',
        color: '#fff',
        textAlign: 'center',
        borderRadius: '5px',
        padding: '9px 5px',
    },
    failed: {
        minWidth: '110px',
        backgroundColor: '#EA001B',
        color: '#fff',
        textAlign: 'center',
        borderRadius: '5px',
        padding: '9px 5px',
    },
    alignCenter: {
        textAlign: 'center',
    },
    buttonStyle: {
        backgroundColor: '#1840d2',
        borderColor: '#1840d2',
        minWidth: '165px',
        float: 'right',
        marginBottom: '10px',
    },
}))
export default function CompletedNotarization({
    onHide,
    id,
    resumeNotarization,
    addWitness,
    downloadDocument,
    auditReport,
    downloadJournal
}) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { auth, notarization } = useSelector((state) => state)

    const [KBASettingscheck, setKBASettingscheck] = useState(false)
    const [KBASettingscheckdispatch, setKBASettingscheckdispatch] = useState(
        false
    )
 
    const [issingleKBA, setissingleKBA] = useState(false)
    const {
        allJournals,
        paymentDetails,
        SingleJournalObj,
        jitsiMeetingLinks,
        KBASettings,
        top5Journals,
    } = notarization
    const [data, setdata] = useState(null)
    const [notification, setNotification] = useState({
        show: false,
        msg: '',
        variant: '',
    })
    const [showAdditionalInformation, setShowAdditionalInformation] = useState(
        false
    )
    const [payments, setPayments] = useState({})

    const headers = {
        Authorization: `Bearer ${auth.authToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
    }

    useEffect(() => {
        const jounrals = allJournals ? allJournals : top5Journals
        let datas =
            jounrals !== null
                ? jounrals.rows.find((data) => data.id === id)
                : null

        setdata(datas)
        
        if (!KBASettingscheckdispatch) {
            dispatch(actions.fetchKBASettings(headers))

            setKBASettingscheckdispatch(true)
        }

        if (KBASettings && KBASettings.data && !KBASettingscheck) {
            setKBASettingscheck(true)
        }
        if (datas && datas.Signers) {
            for (let isign = 0; isign < datas.Signers.length; isign++) {
                if (datas.Signers[isign].bypassKBA) setissingleKBA(true)
            }
        }
        
    }, [SingleJournalObj, allJournals, KBASettings])

    useEffect(() => {
        let filteredWitnesses = []
        if (data !== undefined && data != null) {
            filteredWitnesses =
                data.Witnesses.length > 0
                    ? data.Witnesses.filter((el) => el.status !== 'rejected')
                    : []

            const detail = GetFinalPayment()
            setPayments(detail)
        }
    }, [data])
    const prepareTime = (dt) => {
        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ]
        let date = new Date(dt)
        let time = formatAMPM(date)
        let mon = months[date.getMonth()]
        let curDate = date.getDate()
        let year = date.getFullYear()
        let timezone = seconds_with_leading_zeros(date)
        return `${mon} ${curDate}, ${year} at ${time} (${timezone})`
    }

    const formatAMPM = (date) => {
        var hours = date.getHours()
        var minutes = date.getMinutes()
        var ampm = hours >= 12 ? 'PM' : 'AM'
        hours = hours % 12
        hours = hours ? hours : 12
        minutes = minutes < 10 ? '0' + minutes : minutes
        var strTime = hours + ':' + minutes + ' ' + ampm
        return strTime
    }

    const seconds_with_leading_zeros = (dt) => {
        return /\((.*)\)/.exec(dt.toString())[1]
    }

    function GetFinalPayment() {
        let payments = {}
        if (data) {
            payments.platformFee = data.Payments[0].amount / 100
            payments.paidBy = data.Payments[0].userId

            if (
                data &&
                data.Payments &&
                data.Payments[0] &&
                data.Payments[0].userId == data.notaryId
            ) {
                payments.sealPayment = data.sealCount ? data.sealCount - 1 : 0
            }
            else {
                payments.sealPayment = data.sealCount ? (data.sealCount - 1) * 10 + 25 : 0
            }

            payments.withnessamount = data.Witnesses.length * 5

            payments.signeramount = (data.Signers.length - 1) * 3
        }

        return payments;
    }

    let paymentBreakDown = null
    let filteredWitnesses = []
    if (data !== undefined && data != null) {
        filteredWitnesses =
            data.Witnesses.length > 0
                ? data.Witnesses.filter((el) => el.status !== 'rejected')
                : []
    }

    let totalPrice = '--'
    if (data !== undefined && data != null && data.Payments.length > 0) {
        totalPrice = 0
        for (var i = 0; i < data.Payments.length; i++) {
            totalPrice += parseFloat(data.Payments[i].amount) / 100
        }
        totalPrice = '$' + totalPrice.toFixed(2)

        //   totalPrice += paymentBreakDown.total;
        //  totalPrice = '$' + totalPrice.toFixed(2);
    }

    const handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') return
        setNotification({ show: false, msg: '', variant: '' })
    }

    const getRecordingName = (recordingType) => {
        let recordingTypestr = recordingType.replace(/_/g, ' ')
        return recordingTypestr
            .toLowerCase()
            .replace(/\b[a-z]/g, function (letter) {
                return letter.toUpperCase()
            })
    }

    return (
        <>
            {notification.show && (
                <ShowNotification
                    show={notification.show}
                    msg={notification.msg}
                    variant={notification.variant}
                    close={handleCloseNotification}
                />
            )}

            <Modal.Body className='overlay overlay-block cursor-default'>
                {data !== null && data !== undefined && (
                    <>
                        <div className='container text-center'>
                            <h4><strong>Notarization: </strong> {data.id}</h4>
                            <hr />
                        </div>
                        <Table responsive borderless={true}>
                            <thead>
                                <tr>
                                    <th className='text-center'>
                                        START&nbsp;DATE&nbsp;&&nbsp;TIME
                                    </th>
                                    <th className='text-center'>
                                        END&nbsp;DATE&nbsp;&&nbsp;TIME
                                    </th>
                                    <th className='text-center'>STATUS</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td className='text-center'>
                                        {data.scheduledAt !== null ? (
                                            <p className='mb-0'>
                                                {prepareTime(data.scheduledAt)}
                                            </p>
                                        ) : (
                                            '-'
                                        )}
                                    </td>
                                    <td className='text-center'>
                                        {data.endDate !== null ? (
                                            <p className='mb-0'>
                                                {prepareTime(data.endDate)}
                                            </p>
                                        ) : (
                                            '-'
                                        )}
                                    </td>
                                    <td>
                                        <p className={classes[data.status]}>
                                            {data.status === 'progress' ||
                                                data.status === 'draft'
                                                ? data.subStatus === null
                                                    ? data.status
                                                    : data.subStatus
                                                : data.status
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                data.status.slice(1)}
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>

                        <Table responsive borderless={true}>
                            <thead>
                                <tr>
                                    <th>Document&nbsp;(s)</th>
                                    <th>Type of Notary Act Performed</th>
                                    <th> Total # of Seals/Acts</th>
                                </tr>
                            </thead>
                            {data?.journalDocs.map((record, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{record.File?.filename}</td>
                                        <td>{record.notaryType}</td>
                                        <td>
                                            {record.sealCount
                                                ? record.sealCount
                                                : '-'}
                                        </td>
                                    </tr>
                                )
                            })}
                        </Table>
                        <Divider />


                        <div>
                            <div className="comments-div-padding">
                                <span className="comments-heading">
                                    COMMENTS
                                </span>
                            </div>
                            <div className="comments-div-padding">
                                <span className="word-break">
                                    {data.comments}
                                </span>
                            </div>

                        </div>
                        <Divider />
                        
                        <Table responsive borderless={true}>
                            <thead>
                                <tr>
                                    <th>Signer's&nbsp;Name</th>
                                    <th>Type of ID Proofing Used</th>
                                    <th>Physical&nbsp;Location</th>
                                </tr>
                            </thead>

                            <tbody>
                                {data.Signers.map((el) => {
                                    return (
                                        <>
                                            <tr key={el.id}>
                                                <td>
                                                    {el.firstName} {el.lastName}
                                                </td>

                                                <td>
                                                    {el.bypassKBA ? (
                                                        <p className='wrapWord'>
                                                            {el.bypassReason}
                                                        </p>
                                                    ) : (
                                                        <>
                                                            {
                                                                el?.SignerAuths &&
                                                                    el?.SignerAuths.length &&
                                                                    el?.SignerAuths[el?.SignerAuths.length - 1]?.type == "idScan" ?
                                                                    (
                                                                        <p className='wrapWord'>
                                                                            KBA, Id Scan
                                                                        </p>
                                                                    )
                                                                    :
                                                                    <>
                                                                        {
                                                                            el?.SignerAuths &&
                                                                                el?.SignerAuths.length &&
                                                                                el?.SignerAuths[el?.SignerAuths.length - 1]?.type == "biometric" ? (
                                                                                <p className='wrapWord'>
                                                                                    Biometrics
                                                                                </p>
                                                                            )
                                                                                :
                                                                            <>-</>
                                                                        }
                                                                    </>
                                                            }
                                                        </>
                                                    )}
                                                </td>
                                                <td>
                                                    {el.location
                                                        ? el.location
                                                        : '-'}
                                                </td>
                                            </tr>
                                        </>
                                    )
                                })}
                            </tbody>
                        </Table>
                        <Divider />
                        {
                            (['notary', 'admin'].includes(auth?.user?.Role?.code) ||
                                ("user" === auth?.user?.Role?.code && payments.paidBy === auth?.user?.id)) &&
                            (
                                <Table responsive borderless={true}>
                                    <thead>
                                        <tr>
                                            <th>Platform&nbsp;Fee</th>
                                            <th>Final&nbsp;Payment</th>
                                            <th>Witness&nbsp;Fees</th>
                                            <th>Extra Signer's Fee</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>${payments?.platformFee}</td>
                                            <td>${payments?.sealPayment}</td>
                                            <td>${payments?.withnessamount}</td>
                                            <td>${payments?.signeramount}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            )}

                        {
                            (['notary', 'admin'].includes(auth?.user?.Role?.code) ||
                                ("user" === auth?.user?.Role?.code && payments.paidBy === auth?.user?.id)) &&
                            (
                                <div>
                                    <div>
                                        Total Amount Charged By Cyberize It : {totalPrice}
                                    </div>
                                    {
                                        "user" !== auth?.user?.Role?.code &&
                                        data?.fee && 
                                        <div>
                                            {' '}
                                            Total Amount Charged to Client : ${data?.fee?.toFixed(2)}
                                        </div>
                                    }
                                    <br />
                                    <Divider />
                                </div>
                            )}

                        <Table className="mt-5" responsive borderless={true}>
                            <tbody>
                                <tr>
                                    <td><strong>SESSION&nbsp;VIDEO</strong></td>
                                    <td>{jitsiMeetingLinks?.length > 0
                                        ? jitsiMeetingLinks.map(
                                            (el, i) => (
                                                
                                                <>
                                                    <a
                                                        href={
                                                            el.s3link
                                                        }
                                                        target='_blank'
                                                        >
                                                        Part - {++i}
                                                    </a>
                                                    <br />
                                                </>
                                            )
                                        )
                                        : '-'}
                                    </td>
                                    <td><span className="p-3"></span></td>
                                    <td><span className="p-3"></span></td>
                                    <td><span className="p-3"></span></td>
                                    <td><span className="p-3"></span></td>
                                    <td><span className="p-3"></span></td>
                                </tr>
                            </tbody>
                        </Table>

                        <Grid container spacing={2} className='p-2'>
                            <Grid item sm={3} spacing={3}>
                                {auth?.user?.Role?.code &&
                                    data.status === 'completed' && (
                                        <Button
                                            className={`mr-0 ml-0 pl-0 pr-0  mr-3 ${classes.buttonStyle}`}
                                            onClick={() =>
                                                downloadDocument(data.id)
                                            }>
                                            Download Document
                                        </Button>
                                    )}
                            </Grid>
                            <Grid item sm={3} spacing={3}>
                                {auth?.user &&
                                    data.agreementId &&
                                    data.status === 'completed' && (
                                        <Button
                                            className={`mr-0 ml-0 pl-0 pr-0 mr-3 ${classes.buttonStyle}`}
                                            onClick={() =>
                                                auditReport(data.id)
                                            }>
                                            Audit Report
                                        </Button>
                                    )}
                            </Grid>

                            <Grid item sm={3} spacing={3}>
                                {auth?.user &&
                                    auth?.user?.Role?.code === 'notary' &&
                                    data.agreementId &&
                                    data.status === 'completed' && (
                                        <Button
                                            className={`mr-0 ml-0 pl-0 pr-0 mr-3 ${classes.buttonStyle}`}
                                            onClick={() =>
                                                downloadJournal(data.id)
                                            }>
                                            Download Journal
                                        </Button>
                                    )}
                            </Grid>

                            <Grid item sm={3}>
                                {
                                    auth?.user?.Role?.code === 'notary' &&
                                    data?.notaryId === auth.user.id &&
                                    (
                                        <Button
                                            className={`mr-0 ml-0 pl-0 pr-0  mr-3 ${classes.buttonStyle}`}
                                            onClick={() =>
                                                setShowAdditionalInformation(true)
                                            }>
                                            Extended Journal Entry
                                        </Button>
                                    )}
                            </Grid>
                        </Grid>
                    </>
                )}

                {showAdditionalInformation && (
                    <AdditionalInformation
                        openModal={showAdditionalInformation}
                        onHide={onHide}
                        data={data}
                    />
                )}
            </Modal.Body>
        </>
    )
}
