import React from "react";
import Button from "@material-ui/core/Button";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import CircularProgress from "@material-ui/core/CircularProgress";

export function GenericZeroState({
  status,
  imageURL,
  heading,
  body,
  sub_body,
  btnTxt,
  btnHandler
}) {
  return (
    <div style={{ textAlign: "center" }}>
      {status === null ? (
        <div className="zero-state-loader">
          <CircularProgress color="secondary" size={55} />
        </div>
      ) : (
        <>
          <img
            src={toAbsoluteUrl(imageURL)}
            className="mt-30"
            alt="not found"
            style={{ width: "15%" }}
          />
          <h2 className="mt-10">{heading}</h2>
          <p style={{ fontSize: "15px", width: "37%", margin: "0 auto" }}>
            {body}
          </p>
          <p style={{ fontSize: "15px", width: "37%", margin: "0 auto" }}>
            {sub_body}
          </p>
          {btnTxt !== "false" && (
            <Button
              variant="contained"
              style={{
                minWidth: "13%",
                backgroundColor: "#2d3fd3",
                color: "#ffffff",
                fontSize: "13px",
                marginTop: "25px"
              }}
              onClick={btnHandler}
            >
              {btnTxt}
            </Button>
          )}
        </>
      )}
    </div>
  );
}
