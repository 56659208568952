import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody } from "../../../_metronic/_partials/controls";
import { CustomNoWitnessTable } from "../zero_state/table/CustomNoWitnessTable";
import TextField from "@material-ui/core/TextField";

export function TableNoWitnessCard() {
  return (
    <Card>
      <CardBody>
        <CustomNoWitnessTable />
      </CardBody>
    </Card>
  );
}
