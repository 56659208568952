import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Modal, Button } from 'react-bootstrap'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import * as actions from '../../components/_redux/notarization_redux/notarizationActions'
// import { AddNotary } from './AddNotary';
import { PaymentTable } from './PaymentTable'
import { NoCardAvailable } from './NoCardAvailable'
import { FilePreviewTable } from './FilePreviewTable'
import { SignerInfo } from './SignerInfo'
import { NoFileView } from './NoFileView'
import { AllButtons } from './AllButtons'
import { DocumentChecks } from './DocumentChecks'
import { PaynowModals } from './PaynowModals'
import { SuccessModal } from './modals/SuccessModal'
import { PrefenceModal } from '../modals/PrefenceModal'
import { Comment } from "./Comment";
import { DateTimePickerModal } from './modals/DateTimePickerModal'
import { AttentionModal } from './modals/AttentionModal'
import { AddCardModal } from '../new_card/AddCardModal'
import { GenericModal } from '../modals/GenericModal'
import { KBAVerificationModal } from '../modals/KBAVerificationModal'
import { NotaryWaiting } from '../modals/NotaryWaiting'
import { WitnessInfo } from './WitnessInfo'

export default function NewNotarizationRoot(props) {
    let {
        setChangeMethod,
        issingleKBA,
        selectedNotary,
        setSelectedNotary,
        singerInfo,
        setSingerInfo,
        setNoOfSigner,
        noOfSigner,
        willDoc,
        setWillDoc,
        electionDoc,
        setElectionDoc,
        paid,
        cpostModal,
        setcpostModal,
        setShowAttentionMessage,
        showAttentionMessage,
        fetchEnabledStates,
        allAdminStates,
        payNowHandler,
        payNowModal,
        setPayNowModal,
        payPlatformFeeHandler,
        notarizeNowHandler,
        jobPostHandler,
        confirmjobPostHandler,
        scheduleHandler,
        draftHandler,
        saveLaterSuccess,
        saveLaterSuccessHideHandler,
        scheduleLaterSuccess,
        scheduleLaterSuccessHideHandler,
        headers,
        cstmDisable,
        schedulerModal,
        schedulerCloseHandler,
        schedulerNotarizationHandler,
        notarizeNowSuccess,
        notarizeNowSuccessHideHandler,
        platformFee,
        setNotification,
        documents,
        scheduleLaterDateTime,
        showNoNotary,
        showNotaryWaiting,
        showReNotary,
        showKBAVerificationModal,
        showVerificationDoneModal,
        filteredSingers,
        selectNotary,
        setShowVerificationDoneModal,
        kbatimeout,
        reloadNotarize,
        noOfWitness,
        setNoOfWitness,
        JobPostDoneModal,
        typeFile,
        handleChangeFileType,
        prefstate,
        setprefstate,
        typeNotary,
        setTypeNotary,
        dateTime,
        setDateTime,
        lang,
        setLang,
        showPaymentModal,
        resumeNotarization,
        isButtonClicked,
        comments,
        setComments,
    } = props

    const [addCard, setAddCard] = useState(false)
    const [deleteModal, setDeleteModal] = useState({ show: false, id: null })
    const { auth, notarization } = useSelector((state) => state)
    const {
        defaultPaymentCard,
        primaryNotary,
        currentkbasigners,
        KBASettings,
        languages,
        notaryTypes,
        mypayment,
    } = notarization
    const [uploading, setUploading] = useState(false)

    const dispatch = useDispatch()

    const getFiles = (newFiles, rejectedFiles) => {
        setUploading(true)
        if (documents.length + newFiles.length > 20) {
            setNotification({
                show: true,
                msg: 'You can not upload more than 20 files',
                variant: 'error',
            })
            setUploading(false)
        } else {
            if (rejectedFiles.length > 0) {
                let errorCode = rejectedFiles[0].errors[0].code
                let errorMessage =
                    'Only PDF and Docs file format is acceptable!'
                if (errorCode === 'file-invalid-type')
                    errorMessage =
                        'Only JPG, JPEG, PNG, PDF and Docs file format is acceptable!'
                // else if(errorCode === 'file-too-small') errorMessage = 'Acceptable minimum file size is 70KB!';
                else if (errorCode === 'file-too-large')
                    errorMessage = 'Acceptable maximum file size is 10MB!'
                setNotification({
                    show: true,
                    msg: errorMessage,
                    variant: 'error',
                })
                setUploading(false)
            }

            if (newFiles.length > 0) {
                setUploading(true)
                let formData = new FormData()
                formData.append('userId', auth.user.id)
                formData.append('type', 'journalDoc')
                newFiles.map((file) => formData.append('file', file))
                dispatch(
                    actions.uploadDocuments(
                        formData,
                        headers,
                        setNotification,
                        setUploading,
                        null,
                        typeFile
                    )
                )
            }
        }
    }

    const removeFileAction = (e) => {
        setDeleteModal({ show: true, id: e.currentTarget.id })
    }
    const handleClose = () => setDeleteModal({ show: false, id: null })

    const removeFile = () => {
        handleClose()
        dispatch(
            actions.deleteDocument(deleteModal.id, headers, setNotification)
        )
    }

    const setdefaultpayment = () => {
        window.location.href = '#/payments'
    }

    const primaryNotaryHandler = (e) => {
        if (primaryNotary !== null) {
            setSelectedNotary({ ...selectedNotary, primary: e.target.checked })
        }
    }

    const viewinJournals = () => {
        setShowVerificationDoneModal(false)
        window.location.href = '/#/journals'
        window.location.reload()
    }

    const openPreferrenceModal = () => {
        setcpostModal(true)
        fetchEnabledStates(true)
    }

    const isBiometric = () => {
        let showattention = false;
        if (selectedNotary?.primaryNotary && !selectedNotary.primaryNotary?.biometricSettings?.biometricEnabled) {
            let copiedArr = [...singerInfo];
            for (let i = 0; i < noOfSigner.added; i++) {
                if (!i) {
                    if (
                        auth.user.kbaSettings &&
                        auth.user.kbaSettings[0] &&
                        !auth.user.kbaSettings[0].bypassAllowed
                    ) {
                        if (copiedArr[i].biometric) {
                            showattention = true;
                            break;
                        }
                    }
                } else if (copiedArr[i].biometric) {
                    showattention = true;
                    break;
                }
            }
        }
        return showattention;
    };

    const arrowStyle = {
        fontSize: '36px',
        color: '#909090',
        backgroundColor: '#fbfbfb',
        borderRadius: '50%',
        marginRight: '2px',
        padding: '7px',
    }

    return (
        <>
            <div className='card card-custom'>
                <div className='pl-6 pt-7'>
                    <Link to='/#/dashboard'>
                        <ArrowBackIcon style={arrowStyle} />{' '}
                        <span style={{ fontSize: '14px', color: '#909090' }}>
                            Back
                        </span>
                    </Link>
                </div>
                <div className='card-body d-flex align-items-center justify-content-between flex-wrap pt-3 pb-0'>
                    <div className='mr-2'>
                        <h3 className='font-weight-bolder'>New Notarization</h3>
                    </div>

                    <FormGroup>
                        {resumeNotarization ? (
                            <FormControlLabel
                                control={
                                    <>
                                        {!resumeNotarization.initiatedByNotary ? (
                                            <Switch
                                                checked={
                                                    resumeNotarization.notaryId &&
                                                    resumeNotarization.notaryId !==
                                                    null
                                                }
                                                onChange={primaryNotaryHandler}
                                                value='checked'
                                                disabled={true}
                                            />
                                        ) : (
                                            <Switch
                                                checked={
                                                    selectedNotary.primary &&
                                                    primaryNotary !== null
                                                }
                                                onChange={primaryNotaryHandler}
                                                value='checked'
                                                disabled={true}
                                            />
                                        )}
                                    </>
                                }
                                // label="Select your primary notary for this notarization?"
                                labelPlacement='start'
                            />
                        ) : (
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={
                                            selectedNotary.primary &&
                                            primaryNotary !== null
                                        }
                                        onChange={primaryNotaryHandler}
                                        value='checked'
                                        disabled={
                                            cstmDisable ||
                                            (KBASettings &&
                                                KBASettings.data &&
                                                KBASettings.data.settings
                                                    .bypassAllowed)
                                        }
                                    />
                                }
                                // label="Select your primary notary for this notarization?"
                                labelPlacement='start'
                            />
                        )}
                    </FormGroup>
                </div>
                <br />
                {defaultPaymentCard !== null ? (
                    <PaymentTable
                        setChangeMethod={setChangeMethod}
                        card={defaultPaymentCard}
                        disabled={cstmDisable}
                    />
                ) : (
                    <NoCardAvailable addCard={setAddCard} />
                )}
                {addCard && (
                    <AddCardModal
                        openModal={addCard}
                        setModal={setAddCard}
                        setNotification={setNotification}
                    />
                )}
                <br />
                {paid === false && (
                    <NoFileView
                        getFiles={getFiles}
                        disabled={cstmDisable}
                        uploading={uploading}
                    />
                )}
                <br />
                {documents.length > 0 && (
                    <>
                        <FilePreviewTable
                            files={documents}
                            removeFile={removeFileAction}
                            disabled={cstmDisable}
                            typeFile={typeFile}
                            handleChangeFileType={handleChangeFileType}
                            resumeNotarization={resumeNotarization}
                        />{' '}
                        <br />
                    </>
                )}
                {1 == 2 ? (
                    <>
                        {' '}
                        <DocumentChecks
                            willDoc={willDoc}
                            setWillDoc={setWillDoc}
                            electionDoc={electionDoc}
                            setElectionDoc={setElectionDoc}
                            disabled={cstmDisable}
                        />
                        <br />
                    </>
                ) : (
                    ''
                )}
                <SignerInfo
                    singerInfo={singerInfo}
                    draftHandler={draftHandler}
                    setSingerInfo={setSingerInfo}
                    setNoOfSigner={setNoOfSigner}
                    noOfSigner={noOfSigner}
                    disabled={cstmDisable}
                    user={true}
                    initiatedByNotary={resumeNotarization !== null ? resumeNotarization?.initiatedByNotary : false}
                />
                <br />
                <Comment
                    comments={comments}
                    setComments={setComments}
                />
                <br />
                <WitnessInfo
                    setNoOfWitness={setNoOfWitness}
                    noOfWitness={noOfWitness}
                    disabled={cstmDisable}
                />
                <br />
                <AllButtons
                    payNowModal={false}
                    paynow={payNowHandler}
                    paid={paid}
                    noOfSigner={noOfSigner}
                    auth={auth}
                    draftHandler={draftHandler}
                    scheduleHandler={scheduleHandler}
                    notarizeNowHandler={notarizeNowHandler}
                    user={true}
                    selectedNotary={selectedNotary}
                    confirmjobPostHandler={confirmjobPostHandler}
                    showReNotary={showReNotary}
                    singerInfo={singerInfo}
                    isBiometric={isBiometric}
                    isButtonClicked={isButtonClicked}
                />

                <Modal show={deleteModal.show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure?</Modal.Body>
                    <Modal.Footer>
                        <Button variant='secondary' onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant='danger' onClick={removeFile}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
                <AttentionModal
                    show={showAttentionMessage}
                    handleClose={setShowAttentionMessage}
                    draftHandler={draftHandler}
                    setcpostModal={setcpostModal}
                    showPaymentModal={showPaymentModal}
                />
                {schedulerModal && (
                    <DateTimePickerModal
                        show={schedulerModal}
                        handleClose={schedulerCloseHandler}
                        schedulerNotarizationHandler={
                            schedulerNotarizationHandler
                        }
                        isButtonClicked={isButtonClicked}
                    />
                )}
                {cpostModal && (
                    <PrefenceModal
                        allAdminStates={allAdminStates}
                        heading='Adding requirements will limit the notarization and could cause delays. Only complete these sections if you specifically require a restriction on the Notary who performs this session.'
                        show={cpostModal}
                        hide={setcpostModal}
                        lang={lang}
                        setLang={setLang}
                        typeNotary={typeNotary}
                        setTypeNotary={setTypeNotary}
                        prefstate={prefstate}
                        setprefstate={setprefstate}
                        btnHandler={jobPostHandler}
                        dateTime={dateTime}
                        setDateTime={setDateTime}
                        languages={languages}
                        notaryTypes={notaryTypes}
                        isButtonClicked={isButtonClicked}
                    />
                )}
                {saveLaterSuccess && (
                    <SuccessModal
                        heading='Save for later'
                        bodyText='This notarization has been successfully saved; it is located in your journal.'
                        show={saveLaterSuccess}
                        hide={saveLaterSuccessHideHandler}
                    />
                )}

                {JobPostDoneModal && (
                    <SuccessModal
                        heading='Job Post Submitted'
                        bodyText='Your Job Post Submitted Successfully.'
                        show={JobPostDoneModal}
                        hide={saveLaterSuccessHideHandler}
                    />
                )}
                {scheduleLaterSuccess && (
                    <SuccessModal
                        heading='Successfully set'
                        bodyText={`Your notarization has been successfully saved for ${scheduleLaterDateTime}. We have also sent an email to all users about the schedule to ensure availability.`}
                        show={scheduleLaterSuccess}
                        hide={scheduleLaterSuccessHideHandler}
                    />
                )}
                {notarizeNowSuccess && (
                    <SuccessModal
                        heading='Successfully set'
                        bodyText='Your notarization will begin in few minutes.'
                        show={notarizeNowSuccess}
                        hide={notarizeNowSuccessHideHandler}
                    />
                )}
                {payNowModal && (
                    <PaynowModals
                        platformFee={platformFee}
                        payNowModal={payNowModal}
                        setPayNowModal={setPayNowModal}
                        payPlatformFeeHandler={payPlatformFeeHandler}
                        KBASettings={KBASettings}
                        isButtonClicked={isButtonClicked}
                        isBiometric={isBiometric}
                        paynow={payNowHandler}
                        paid={paid}
                        noOfSigner={noOfSigner}
                        auth={auth}
                        draftHandler={draftHandler}
                        scheduleHandler={scheduleHandler}
                        notarizeNowHandler={notarizeNowHandler}
                        user={true}
                        selectedNotary={selectedNotary}
                        confirmjobPostHandler={confirmjobPostHandler}
                        showReNotary={showReNotary}
                        singerInfo={singerInfo}
                    />
                )}

                {showKBAVerificationModal && (
                    <KBAVerificationModal
                        heading='ID Proofing Process'
                        signers={filteredSingers}
                        show={showKBAVerificationModal}
                        hide={saveLaterSuccessHideHandler}
                        kbasigners={currentkbasigners}
                    />
                )}
                {showVerificationDoneModal && (
                    <GenericModal
                        heading='Verification Done'
                        bodyText='Bingo! your all signers are verified now for this notarization'
                        image='/media/own/success.png'
                        btnTxt='Next'
                        show={showKBAVerificationModal}
                        hide={saveLaterSuccessHideHandler}
                    />
                )}
                {showNoNotary && (
                    <GenericModal
                        heading='No Notary Selected'
                        bodyText='Opps! you are almost done only you have to select notary to complete your notarization.'
                        image='/media/own/alarm.png'
                        btnTxt='Select Notary'
                        btnHandler={selectNotary}
                        show={showNoNotary}
                        hide={saveLaterSuccessHideHandler}
                    />
                )}

                {showReNotary &&
                    KBASettings &&
                    KBASettings.data &&
                    !KBASettings.data.settings.bypassAllowed &&
                    !issingleKBA && (
                        <GenericModal
                            heading='Attention Action is Required'
                            bodyText='We are sorry for the inconvenience; however, it appears your Notary is not available at this time. Please reschedule or post to the Job Board for another notary to assist you.'
                            image='/media/own/alarm.png'
                            btnTxt='Post to Job Board'
                            disclaimer="Return to the Main Dashboard to Reschedule with your Primary Notary"
                            btnHandler={selectNotary}
                            show={showReNotary}
                            hide={saveLaterSuccessHideHandler}
                        />
                    )}

                {kbatimeout && (
                    <GenericModal
                        heading='Sorry!'
                        bodyText='Opps! Signers not approve KBA in Time.'
                        image='/media/own/alarm.png'
                        btnTxt='Notarize Again'
                        btnHandler={reloadNotarize}
                        show={kbatimeout}
                        hide={saveLaterSuccessHideHandler}
                    />
                )}

                {showVerificationDoneModal && (
                    <GenericModal
                        heading='Congratulations'
                        bodyText='Your notarization request has been accepted by the Notary. The Notary will now format the document and a meeting link will be arriving in your email shortly.'
                        image='/media/own/congrats.png'
                        btnTxt='View in journals'
                        btnHandler={viewinJournals}
                        show={showVerificationDoneModal}
                        hide={saveLaterSuccessHideHandler}
                    />
                )}
                {showNotaryWaiting && (
                    <NotaryWaiting
                        heading='Please Wait For Your Notary'
                        show={showNotaryWaiting}
                        hide={saveLaterSuccessHideHandler}
                    />
                )}
            </div>
        </>
    )
}
