import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../components/_redux/notarization_redux/notarizationActions";
import { AddCardModal } from "../../components/new_card/AddCardModal";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Button, Modal, Table } from "react-bootstrap";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { GenericZeroState } from "../../components/zero_state_pages/GenericZeroState";

export default function PaymentCardListing({
  setChangeMethod,
  setNotification,
  link
}) {
  const [addCard, setAddCard] = useState(false);
  const [showDialog, setshowDialog] = useState(0);
  const { auth, notarization } = useSelector(state => state);
  const {
    paymentCards,
    defaultPaymentCard,
    defaultPaymentCard2
  } = notarization;

  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  const dispatch = useDispatch();
  const makeDefaultHandler = e =>
    dispatch(
      actions.setDefaultPaymentCard(
        { userId: auth.user.id, cardId: e.target.id },
        headers,
        setNotification,
        setChangeMethod
      )
    ).then(data => {
      dispatch(
        actions.fetchDefaultPaymentCard({ userID: auth.user.id }, headers)
      );
    });
  const makeNetDefaultHandler = e =>
    dispatch(
      actions.setNetDefaultPaymentCard(
        { userId: auth.user.id, cardId: e.target.id },
        headers,
        setNotification,
        setChangeMethod
      )
    ).then(data => {
      dispatch(
        actions.fetchDefaultPaymentCard({ userID: auth.user.id }, headers)
      );
    });

  const deleteCard = () =>
    dispatch(
      actions.deleteCard(showDialog, headers, setNotification, setChangeMethod)
    );

  const hideCard = () => {
    setshowDialog(0);
  };
  const addNewHandler = () => {
    setAddCard(true);
  };

  useEffect(() => {
    dispatch(
      actions.fetchPaymentCards({ userID: auth.user.id, limit: -1 }, headers)
    );
  }, [dispatch]);

  const arrowStyle = {
    fontSize: "36px",
    color: "#909090",
    backgroundColor: "#fbfbfb",
    borderRadius: "50%",
    marginRight: "2px",
    padding: "7px"
  };

  return (
    <>
      {addCard && (
        <AddCardModal
          openModal={addCard}
          setModal={setAddCard}
          setNotification={setNotification}
        />
      )}
      {paymentCards !== null && paymentCards.length > 0 ? (
        <div className={`card card-custom`}>
          {link && (
            <div
              className="pl-6 pt-7"
              onClick={() => setChangeMethod(false)}
              style={{ cursor: "pointer" }}
            >
              <ArrowBackIcon style={arrowStyle} />{" "}
              <span style={{ fontSize: "14px", color: "#909090" }}>Back</span>
            </div>
          )}
          <div className="card-body d-flex align-items-center justify-content-between flex-wrap pt-3">
            <h3 className="font-weight-bolder">My Cards</h3>
            {
              ( auth.user.Role.code !== "notary" || (auth.user.Role.code === "notary" && !auth.user.notaryProfile.isCancelled ) ) &&
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => setAddCard(true)}
              >
                Add New Card
              </button>
            }
          </div>

          <div className="card-body pt-0 pb-3">
            <div className="tab-content">
              <div className="table-responsive">
                <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                  <tbody>
                    {paymentCards.map(data => {
                      return (
                        <tr key={data.id}>
                          <td className="pl-0 py-0">
                            <div className="d-flex align-items-center">
                              <div className="symbol symbol-50 symbol-light mr-4">
                                <span className="svg-icon h-75 align-self-end">
                                  <img
                                    src={toAbsoluteUrl(
                                      "/media/own/card-icon.png"
                                    )}
                                    alt="Credit Card"
                                    style={{ width: "75px" }}
                                  />
                                </span>
                              </div>
                              <div>
                                <span className="text-muted font-weight-bold mb-3 d-block">
                                  Card Number
                                </span>
                                <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                  xxxx-xxxx-xxxx-{data.lastFour}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td
                            style={{
                              maxWidth: "100% !important",
                              width: "55%"
                            }}
                          >
                            <span className="text-muted font-weight-bold mb-3 d-block">
                              Card Holder Name
                            </span>
                            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                              {data.name}
                            </span>
                          </td>
                          <td>
                            <span className="text-muted font-weight-bold mb-3 d-block">
                              Expiry Date
                            </span>
                            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                              {data.expiry}
                            </span>
                          </td>
                          <td>
                            <span className="text-muted font-weight-bold mb-3 d-block">
                              CVV
                            </span>
                            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                              ***
                            </span>
                          </td>
                          <td className="pr-0 text-right">
                            {data.default &&
                            ((defaultPaymentCard2 &&
                              defaultPaymentCard2.net30Setting &&
                              !defaultPaymentCard2.net30Setting.default &&
                              auth.user.Role.code == "ps") ||
                              auth.user.Role.code != "ps" ||
                              !defaultPaymentCard2.net30Setting) ? (
                              <Button
                                style={{
                                  backgroundColor: "#1840d2",
                                  borderColor: "#1840d2",
                                  marginBottom: "10px",
                                  minWidth: "135px"
                                }}
                                disabled
                              >
                                Default
                              </Button>
                            ) : (
                              <>
                                <Button
                                  id={data.id}
                                  style={{
                                    backgroundColor: "transparent",
                                    marginBottom: "10px",
                                    borderColor: "#1840d2",
                                    color: "#1840d2",
                                    minWidth: "135px"
                                  }}
                                  onClick={makeDefaultHandler}
                                >
                                  Make Default
                                </Button>{" "}
                                <Button
                                  id={data.id}
                                  style={{
                                    backgroundColor: "transparent",
                                    borderColor: "#1840d2",
                                    color: "#1840d2",
                                    minWidth: "135px"
                                  }}
                                  onClick={() => setshowDialog(data.id)}
                                >
                                  Delete
                                </Button>
                              </>
                            )}
                          </td>
                        </tr>
                      );
                    })}

                    {
                      (
                        auth.user.Role.code == "ps" &&
                        defaultPaymentCard2 &&
                        defaultPaymentCard2.net30Setting &&
                        defaultPaymentCard2.net30Setting.active
                      ) && (
                      <tr key="Net30">
                        <td className="pl-0 py-0" colspan="4">
                          <h2>Net 30</h2>
                          <div className="d-flex align-items-center">
                            <div className="symbol symbol-50 symbol-light mr-4">
                              <span className="svg-icon h-75 align-self-end">
                                <img
                                  src={toAbsoluteUrl(
                                    "/media/own/card-icon.png"
                                  )}
                                  alt="Credit Card"
                                  style={{ width: "75px" }}
                                />
                              </span>
                            </div>
                            <div>
                              <span className="text-muted font-weight-bold mb-3 d-block">
                                Net 30 Card
                              </span>
                              <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                Professional Services Card provided by Admin
                              </span>
                            </div>
                          </div>
                        </td>

                        <td className="pr-0 text-right">
                          {defaultPaymentCard2 &&
                          defaultPaymentCard2.net30Setting &&
                          defaultPaymentCard2.net30Setting.default ? (
                            <Button
                              style={{
                                backgroundColor: "#1840d2",
                                borderColor: "#1840d2",
                                marginBottom: "10px",
                                minWidth: "135px"
                              }}
                              disabled
                            >
                              Default
                            </Button>
                          ) : (
                            <>
                              <Button
                                style={{
                                  backgroundColor: "transparent",
                                  marginBottom: "10px",
                                  borderColor: "#1840d2",
                                  color: "#1840d2",
                                  minWidth: "135px"
                                }}
                                onClick={makeNetDefaultHandler}
                              >
                                Make Default
                              </Button>{" "}
                            </>
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

                <Modal
                  size="lg"
                  show={showDialog > 0}
                  aria-labelledby="example-modal-sizes-title-lg"
                >
                  <Modal.Body className="overlay overlay-block cursor-default">
                    <h1>Confirmation</h1>
                    <div className="modal-body-content">
                      Are you sure you want to delete this card?{" "}
                    </div>
                    <div className="modal-body">
                      <Button
                        variant="secondary"
                        className="acceptBtn"
                        style={{ marginTop: "20px", float: "right" }}
                        onClick={() => deleteCard()}
                      >
                        Submit
                      </Button>

                      <Button
                        variant="secondary"
                        className="cancelBtn"
                        style={{
                          marginTop: "20px",
                          marginRight: "10px",
                          float: "right"
                        }}
                        onClick={() => hideCard()}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {auth.user.Role.code == "ps" ? (
            <div className={`card card-custom`}>
              {link && (
                <div
                  className="pl-6 pt-7"
                  onClick={() => setChangeMethod(false)}
                  style={{ cursor: "pointer" }}
                >
                  <ArrowBackIcon style={arrowStyle} />{" "}
                  <span style={{ fontSize: "14px", color: "#909090" }}>
                    Back
                  </span>
                </div>
              )}
              <div className="card-body d-flex align-items-center justify-content-between flex-wrap pt-3">
                <h3 className="font-weight-bolder">My Cards</h3>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => setAddCard(true)}
                >
                  Add New Card
                </button>
              </div>

              <div className="card-body pt-0 pb-3">
                <div className="tab-content">
                  <div className="table-responsive">
                    <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                      <tbody>
                        {
                          (
                            auth.user.Role.code == "ps" && 
                            defaultPaymentCard2 &&
                            defaultPaymentCard2.net30Setting &&
                            defaultPaymentCard2.net30Setting.active
                          ) && (
                          <tr key="Net30">
                            <td className="pl-0 py-0" colspan="4">
                              <h2>Net 30</h2>
                              <div className="d-flex align-items-center">
                                <div className="symbol symbol-50 symbol-light mr-4">
                                  <span className="svg-icon h-75 align-self-end">
                                    <img
                                      src={toAbsoluteUrl(
                                        "/media/own/card-icon.png"
                                      )}
                                      alt="Credit Card"
                                      style={{ width: "75px" }}
                                    />
                                  </span>
                                </div>
                                <div>
                                  <span className="text-muted font-weight-bold mb-3 d-block">
                                    Net 30 Card
                                  </span>
                                  <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    Professional Services Card provided by Admin
                                  </span>
                                </div>
                              </div>
                            </td>

                            <td className="pr-0 text-right">
                              {defaultPaymentCard2 &&
                              defaultPaymentCard2.net30Setting &&
                              defaultPaymentCard2.net30Setting.default ? (
                                <Button
                                  style={{
                                    backgroundColor: "#1840d2",
                                    borderColor: "#1840d2",
                                    marginBottom: "10px",
                                    minWidth: "135px"
                                  }}
                                  disabled
                                >
                                  Default
                                </Button>
                              ) : (
                                <>
                                  <Button
                                    style={{
                                      backgroundColor: "transparent",
                                      marginBottom: "10px",
                                      borderColor: "#1840d2",
                                      color: "#1840d2",
                                      minWidth: "135px"
                                    }}
                                    onClick={makeNetDefaultHandler}
                                  >
                                    Make Default
                                  </Button>{" "}
                                </>
                              )}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>

                    <Modal
                      size="lg"
                      show={showDialog > 0}
                      aria-labelledby="example-modal-sizes-title-lg"
                    >
                      <Modal.Body className="overlay overlay-block cursor-default">
                        <h1>Confirmation</h1>
                        <div className="modal-body-content">
                          Are you sure you want to delete this card?{" "}
                        </div>
                        <div className="modal-body">
                          <Button
                            variant="secondary"
                            className="acceptBtn"
                            style={{ marginTop: "20px", float: "right" }}
                            onClick={() => deleteCard()}
                          >
                            Submit
                          </Button>

                          <Button
                            variant="secondary"
                            className="cancelBtn"
                            style={{
                              marginTop: "20px",
                              marginRight: "10px",
                              float: "right"
                            }}
                            onClick={() => hideCard()}
                          >
                            Cancel
                          </Button>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <GenericZeroState
              status={paymentCards}
              imageURL="/media/own/wallet.png"
              heading=""
              body="Please add your credit card to initiate this Notarization."
              btnTxt="Add Card"
              btnHandler={addNewHandler}
            />
          )}{" "}
        </>
      )}
    </>
  );
}
