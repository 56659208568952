import React, { useEffect, useState } from "react";
import { TableUIProvider } from "../components/notarization_listing/deletedjournals/TableUIContext";
import { TableCard } from "../components/notarization_listing/deletedjournals/TableCard";
import { CustomerEditDialog } from "../components/notarization_listing/deletedjournals/edit-dialog/CustomerEditDialog";
import { NewNotarization } from "../pages/NewNotarization";
import { NewNotarizationNotary } from "../pages/NewNotarizationNotary";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../components/_redux/notarization_redux/notarizationActions";
import { GenericZeroState } from "../components/zero_state_pages/GenericZeroState";
import { useHistory, useLocation } from "react-router-dom";
import { GenericModal } from "../components/modals/GenericModal";

export function DeletedJournals() {
  const MIDDLEWARE_URL = process.env.REACT_APP_MIDDLEWARE_URL;
  const [actionModal, setActionModal] = useState(false);
  const [notaryID, setNotaryID] = useState(null);
  const [resume, setResume] = useState({ status: false, id: null });
  const [iscancelNotarization, setiscancelNotarization] = useState(false);

  const customersUIEvents = {
    openEditCustomerDialog: id => {
      setActionModal(true);
      setNotaryID(id);
    }
  };
  const dispatch = useDispatch();
  const location = useLocation();
  const { auth, notarization } = useSelector(state => state);
  const { allJournals, deletedJournals } = notarization;

  const resumeNotarization = id => {
    setResume({ status: true, id: id });
    const resumeData = allJournals.rows.filter(data => data.id === id);
    if (resumeData !== null)
      dispatch(actions.updateDocuments(resumeData[0].documents));
  };

  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  const addWitness = id =>
    (window.location.href = "#/searchWitnesses/?id=" + id);
  const addNewHandler = () => (window.location.href = "/#/new-notarization");
  const downloadDocument = journalId => {
    let xhr = new XMLHttpRequest();
    xhr.open("POST", MIDDLEWARE_URL + "/api/journal/" + journalId + "/pdf");
    xhr.setRequestHeader("content-type", "application/json");
    xhr.setRequestHeader("authorization", "Bearer " + auth.authToken);
    xhr.responseType = "arraybuffer";
    xhr.onreadystatechange = e => {
      if (xhr.status == "200" && xhr.readyState == 4) {
        let data = xhr.response;
        let file = new Blob([data], { type: "application/pdf" });
        let a = document.createElement("a");
        a.href = URL.createObjectURL(file);
        a.download = journalId + "-document.pdf";
        a.click();
      }
    };
    xhr.send(JSON.stringify());
  };

  const auditReport = journalId => {
    let xhr = new XMLHttpRequest();
    xhr.open(
      "GET",
      MIDDLEWARE_URL + "/api/journal/" + journalId + "/auditTrail"
    );
    xhr.setRequestHeader("content-type", "application/json");
    xhr.setRequestHeader("authorization", "Bearer " + auth.authToken);
    xhr.responseType = "arraybuffer";
    xhr.onreadystatechange = e => {
      if (xhr.status == "200" && xhr.readyState == 4) {
        let data = xhr.response;
        let file = new Blob([data], { type: "application/pdf" });
        let a = document.createElement("a");
        a.href = URL.createObjectURL(file);
        a.download = journalId + "-auditTrail.pdf";
        a.click();
      }
    };
    xhr.send(JSON.stringify());
  };

  const downloadJournal = journalId => {
    let xhr = new XMLHttpRequest();
    let date = new Date();

    let timeString = date.toTimeString();
    let [time , ...timeZone] = timeString.split(" ")

    timeZone = timeZone.join(" ")
    xhr.open(
      "POST",
      MIDDLEWARE_URL + "/api/journal/" + journalId + "/create-pdf"
    );
    xhr.setRequestHeader("content-type", "application/json");
    xhr.setRequestHeader("authorization", "Bearer " + auth.authToken);
    xhr.responseType = "arraybuffer";
    xhr.onreadystatechange = e => {
      if (xhr.status == "200" && xhr.readyState == 4) {
        let data = xhr.response;
        let file = new Blob([data], { type: "application/pdf" });
        let a = document.createElement("a");
        a.href = URL.createObjectURL(file);
        a.download = journalId + "-journal.pdf";
        a.click();
      }
    };
    xhr.send(JSON.stringify({timeZone}));
  };

  const closeCancel = () => {
    window.location.href = "#/journals";
    window.location.reload();
  };

  useEffect(() => {
    let cancelNotarization = location.search;
    const urlParams = new URLSearchParams(location.search);
    cancelNotarization = urlParams.get("cancelNotarization");
    if (cancelNotarization) {
      setiscancelNotarization(true);
    }

    dispatch(
      actions.fetchDeletedJournals(
        {
          sortOrder: "desc", // asc||desc
          sortField: "id",
          pageNumber: 1,
          pageSize: 10
        },
        headers,
        auth.user.Role.code
      )
    );

    if (auth.user.Role.code === "notary" || auth.user.Role.code === "ps") {
      dispatch(
        actions.fetchCSVJournals(
          {
            sortOrder: "desc", // asc||desc
            sortField: "id",
            pageNumber: 1,
            pageSize: 1000
          },
          headers,
          auth.user.Role.code
        )
      );

      dispatch(
        actions.fetchClients(headers, {
          sortOrder: "asc",
          sortField: "firstName",
          pageNumber: 1,
          pageSize: 100000
        })
      );
    }
  }, []);

  return (
    <div>
      {resume.status ? (
        auth.user.Role.code === "notary" ? (
          <NewNotarizationNotary
            newNot={resume.status}
            id={resume.id}
            from="journal"
            resumeNotarization={resumeNotarization}
          />
        ) : (
          <NewNotarization
            newNot={resume.status}
            id={resume.id}
            from="journal"
            resumeNotarization={resumeNotarization}
          />
        )
      ) : deletedJournals !== null && deletedJournals.rows.length > 0 ? (
        <TableUIProvider customersUIEvents={customersUIEvents}>
          <CustomerEditDialog
            show={actionModal}
            id={notaryID}
            resumeNotarization={resumeNotarization}
            addWitness={addWitness}
            downloadDocument={downloadDocument}
            auditReport={auditReport}
            downloadJournal={downloadJournal}
            onHide={() => {
              setActionModal(false);
              setNotaryID(null);
            }}
          />
          <TableCard />
        </TableUIProvider>
      ) : (
        <GenericZeroState
          status={deletedJournals}
          imageURL="/media/own/notarization.png"
          heading=""
          body="Once you delete a Notarization, it will be listed here."
          btnTxt={"false"}
        />
      )}
    </div>
  );
}
